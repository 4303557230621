var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from '@angular/common/http';
import { DownloadService } from './download.service';
import { toPairs } from 'lodash-es';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
var CsvService = /** @class */ (function () {
    function CsvService(httpClient, downloadService, dialogsService, i18nService) {
        this.httpClient = httpClient;
        this.downloadService = downloadService;
        this.dialogsService = dialogsService;
        this.i18nService = i18nService;
    }
    CsvService.prototype.downloadCsv = function (_a, downloadFromCyber, duplicateKeyForArrayValue) {
        var tokenUrl = _a.tokenUrl, apiUrl = _a.apiUrl, params = _a.params;
        if (downloadFromCyber === void 0) { downloadFromCyber = false; }
        if (duplicateKeyForArrayValue === void 0) { duplicateKeyForArrayValue = false; }
        return __awaiter(this, void 0, void 0, function () {
            var requestParams, token;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.dialogsService.showSnackbar({
                            text: this.i18nService.get('grid.commands.exportToExcel.waitForDownload'),
                            icon: 'Info',
                        });
                        if (!downloadFromCyber) return [3 /*break*/, 1];
                        requestParams = Object.assign({}, params);
                        return [2 /*return*/, this.downloadService.downloadFromFromResponseUri(this.buildUrlWithParams(apiUrl, requestParams, duplicateKeyForArrayValue))];
                    case 1: return [4 /*yield*/, this.getCsvToken(tokenUrl)];
                    case 2:
                        token = _b.sent();
                        if (token.url) {
                            apiUrl = token.url;
                            delete token.url;
                        }
                        requestParams = Object.assign({}, token, params);
                        return [2 /*return*/, this.downloadService.downloadFromUrl(this.buildUrlWithParams(apiUrl, requestParams, duplicateKeyForArrayValue), { isAuthenticated: true })];
                }
            });
        });
    };
    CsvService.prototype.getCsvToken = function (tokenUrl) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.httpClient.get(tokenUrl).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    CsvService.prototype.buildUrlWithParams = function (baseUri, params, duplicateKeyForArrayValue) {
        if (duplicateKeyForArrayValue === void 0) { duplicateKeyForArrayValue = false; }
        var urlParams = toPairs(params)
            .map(function (_a) {
            var key = _a[0], value = _a[1];
            if (Array.isArray(value)) {
                if (duplicateKeyForArrayValue) {
                    // in this case - the value of the key is an array and we want to split it to - key=array[0]&key=array[1]...
                    var paramsArray = value.map(function (val) { return key + "=" + encodeURIComponent(String(val)); });
                    return paramsArray.join('&');
                }
                // in this case - the value of the key is an array and we will NOT split it - key=array[0],array[1]...
                return key + "=" + value.map(function (val) { return encodeURIComponent(val); }).join(',');
            }
            else {
                return key + "=" + encodeURIComponent(value);
            }
        })
            .join('&');
        return baseUri + "?" + urlParams;
    };
    return CsvService;
}());
export { CsvService };
