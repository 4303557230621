var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { DataQuerySortDirection, Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { isNumber, omit } from 'lodash-es';
import { MachineGroup } from '../rbac/machine-group.entity';
import { DefenderRunningMode } from './defender-running-mode.enum';
import { MachineHealthStatus } from './machine-health-status.entity';
import { getMachineIdType } from './machine-id-type.enum';
import { MachineRiskScore } from './machine-risk-score/machine-risk-score.entity';
import { OperatingSystem } from './os.value-object';
import { MachineExposureScore } from './machine-exposure-score/machine-exposure-score.entity';
import { DataSensitivity } from '../file/data-sensitivity.value-object';
import { DeviceResource } from './machine-itp-related-data/machine-resource.value-object';
import { DeviceGroupMembership } from './machine-itp-related-data/machine-group-membership.value-object';
import { DeviceUserAccountControlFlags } from './machine-itp-related-data/machine-uac-flags.value-object';
import { MachineExclusionState } from './machine-exclusion/machine-exclusion-state.enum';
import { MachineTags } from './machine-tags.value-object';
import { MachineValue } from './machine-value.enum';
import { DeviceType } from './device-type.enum';
import { DeviceCategory } from './device-category.enum';
import { OnboardingStatus } from './device-onboarding-status.enum';
import { riskScoreNumberToEntity } from './machine-risk-score/machine-risk-score.values';
import { exposureScoreNumberToEntity } from './machine-exposure-score/machine-exposure-score.values';
import { MachineDeviceManager } from './machine-mde-attach/machine-managed-by/machine-managed-by.entity';
import { MemEnrollmentStatus } from './machine-mde-attach/machine-mem-enrollment/machine-mem-enrollment.models';
import { MachineManagedByStatus } from './machine-mde-attach/machine-managed-by-status/machine-managed-by-status.entity';
var fieldIdOrderMap = {
    riskScores: 'riskscore',
    exposureScores: 'exposurescore',
    healthStatuses: 'healthstatus',
    osPlatforms: 'osplatform',
    deviceTypes: 'deviceType',
};
var securityPropertyToAntiVirusStatusConvertion = {
    AntiVirusNotReporting: 'Unknown',
    AntiVirusEnabled: 'Disabled',
    AntiVirusSignatureVersion: 'NotUpdated',
};
var ɵ0 = function (config, query) {
    if (query && query.where && query.where.machinesApiServiceMigration) {
        return 'machines';
    }
    return 'MachinesQueue';
}, ɵ1 = function (itemId) {
    return "machines?machineId=" + itemId + "&idType=" + getMachineIdType(String(itemId)) + "&lookingBackIndays=180";
}, ɵ2 = function (config, query) {
    if (query && query.where && query.where.machinesApiServiceMigration) {
        return config.data.serviceUrls.k8s;
    }
    return config.data.serviceUrls.threatIntel;
}, ɵ3 = function (dataQuery) {
    if (!dataQuery)
        return {};
    var useTvmMachinesAvStatus = (dataQuery.where && dataQuery.where['useTvmMachinesAvStatus']);
    var dataQueryOptions = Object.assign({}, omit(dataQuery.where, ['page', 'page_size', 'ordering', 'machinesApiServiceMigration', 'useTvmMachinesAvStatus']), {
        pageIndex: dataQuery.page || 1,
        pageSize: dataQuery.pageSize,
    }, 
    // todo: remove this default sort once the backend api is fixed (has 'Alerts' instead of 'alerts')
    {
        sortByField: 'alerts',
    }, dataQuery.sortBy && dataQuery.sortBy.length
        ? {
            sortByField: fieldIdOrderMap[dataQuery.sortBy[0].field] || dataQuery.sortBy[0].field,
            sortOrder: dataQuery.sortBy[0].direction === DataQuerySortDirection.ascending
                ? 'Ascending'
                : 'Descending',
        }
        : undefined);
    // We're transitioning to TVM AV Status, so if the feature is on we'll use TVM AV Status, if not we'll do it the old way
    // For TVM Av Status we introduced a new query parameter - avStatuses, we'll set it up and clear the current one - securityPropertiesRequiringAttention
    if (dataQueryOptions.securityPropertiesRequiringAttention && useTvmMachinesAvStatus) {
        dataQueryOptions.avStatuses = dataQueryOptions.securityPropertiesRequiringAttention.map(function (property) { return securityPropertyToAntiVirusStatusConvertion[property]; });
        delete dataQueryOptions.securityPropertiesRequiringAttention;
    }
    var hasOutbreakId = !!(dataQuery.where && dataQuery.where['outbreakId']);
    var hasMitigationTypes = !!(dataQuery.where && dataQuery.where['mitigationTypes']);
    if (hasOutbreakId !== hasMitigationTypes) {
        delete dataQueryOptions.outbreakId;
        delete dataQueryOptions.mitigationTypes;
    }
    return dataQueryOptions;
}, ɵ4 = function (senseMachineId) { return !!senseMachineId; }, ɵ5 = ['Id', 'MachineId', 'SenseMachineId', 'MachineGuid', 'ComputerDnsName'], ɵ6 = ['ComputerDnsName', 'Name'], ɵ7 = ['LastIpAddress', 'LastExternalIpAddress'], ɵ8 = ['LastEventTime', 'LastSeen'], ɵ9 = function (riskScore) { return isNumber(riskScore) ? riskScoreNumberToEntity[riskScore] : riskScore; }, ɵ10 = function (exposureScore) { return (isNumber(exposureScore) ? exposureScoreNumberToEntity[exposureScore] : exposureScore) || 'None'; }, ɵ11 = function (assetValue) { return assetValue || 'Normal'; }, ɵ12 = function (exclusionState) { return exclusionState || MachineExclusionState.Included; }, ɵ13 = function (tags) {
    if (typeof tags === 'string') {
        return tags.split(',');
    }
    else
        return null;
};
var Machine = /** @class */ (function (_super) {
    __extends(Machine, _super);
    function Machine() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Machine.prototype, "tags", {
        /**
         * Relevant only for device inventory call.
         * In device page / side pane call this data returns in ExtendedMachineTags property
         */
        get: function () {
            return this.builtInTag ? [this.builtInTag].concat((this.userDefinedTags || [])) : this.userDefinedTags;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Machine.prototype, "shortName", {
        get: function () {
            if (this._shortName === undefined) {
                if (!this.name)
                    this._shortName = '';
                else {
                    var shortNameMatch = this.name.match(/^([^\.]+)/);
                    this._shortName = shortNameMatch ? shortNameMatch[1] : '';
                }
            }
            return this._shortName;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({ data: 'SenseMachineId' }),
        __metadata("design:type", String)
    ], Machine.prototype, "senseMachineId", void 0);
    __decorate([
        EntityField({ data: 'SenseMachineId', parse: ɵ4 }),
        __metadata("design:type", Boolean)
    ], Machine.prototype, "isMdatp", void 0);
    __decorate([
        EntityField({ data: 'MachineId' }),
        __metadata("design:type", String)
    ], Machine.prototype, "machineId", void 0);
    __decorate([
        EntityField({ data: 'MachineGuid' }),
        __metadata("design:type", String)
    ], Machine.prototype, "machineGuid", void 0);
    __decorate([
        EntityField({
            data: ɵ5,
        }),
        __metadata("design:type", String)
    ], Machine.prototype, "id", void 0);
    __decorate([
        EntityField({ data: ɵ6 }),
        __metadata("design:type", String)
    ], Machine.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'IpAddress' }),
        __metadata("design:type", String)
    ], Machine.prototype, "ipAddress", void 0);
    __decorate([
        EntityField({ data: '__self', require: 'OsPlatform' }),
        __metadata("design:type", OperatingSystem)
    ], Machine.prototype, "os", void 0);
    __decorate([
        EntityField({ data: 'OsVersion' }),
        __metadata("design:type", String)
    ], Machine.prototype, "itpOsVersion", void 0);
    __decorate([
        EntityField({ data: 'HealthStatus' }),
        __metadata("design:type", MachineHealthStatus)
    ], Machine.prototype, "status", void 0);
    __decorate([
        EntityField({ data: 'MachineGroup' }),
        __metadata("design:type", String)
    ], Machine.prototype, "builtInTag", void 0);
    __decorate([
        EntityField({ data: 'RbacGroupId' }),
        __metadata("design:type", MachineGroup)
    ], Machine.prototype, "group", void 0);
    __decorate([
        EntityField({ data: 'Domain' }),
        __metadata("design:type", String)
    ], Machine.prototype, "domain", void 0);
    __decorate([
        EntityField({ data: 'LastIpAddress' }),
        __metadata("design:type", String)
    ], Machine.prototype, "lastIp", void 0);
    __decorate([
        EntityField({ data: 'LastExternalIpAddress' }),
        __metadata("design:type", String)
    ], Machine.prototype, "lastExternalIp", void 0);
    __decorate([
        EntityField({ data: ɵ7 }),
        __metadata("design:type", String)
    ], Machine.prototype, "lastCalculatedIp", void 0);
    __decorate([
        EntityField({ data: 'FirstSeen' }),
        __metadata("design:type", Date)
    ], Machine.prototype, "firstSeen", void 0);
    __decorate([
        EntityField({ data: 'LastSeen' }),
        __metadata("design:type", Date)
    ], Machine.prototype, "lastSeen", void 0);
    __decorate([
        EntityField({ data: 'FirstEventTime' }),
        __metadata("design:type", Date)
    ], Machine.prototype, "firstEventTime", void 0);
    __decorate([
        EntityField({ data: 'LastEventTime' }),
        __metadata("design:type", Date)
    ], Machine.prototype, "lastEventTime", void 0);
    __decorate([
        EntityField({ data: ɵ8 }),
        __metadata("design:type", String)
    ], Machine.prototype, "lastSeenId", void 0);
    __decorate([
        EntityField({ data: 'DefenderRunningMode' }),
        __metadata("design:type", String)
    ], Machine.prototype, "defenderRunningMode", void 0);
    __decorate([
        EntityField({ data: 'InternalMachineId' }),
        __metadata("design:type", String)
    ], Machine.prototype, "internalMachineId", void 0);
    __decorate([
        EntityField({ data: 'SenseClientVersion' }),
        __metadata("design:type", String)
    ], Machine.prototype, "senseClientVersion", void 0);
    __decorate([
        EntityField({ data: 'ThreatsCount' }),
        __metadata("design:type", Number)
    ], Machine.prototype, "threatsCount", void 0);
    __decorate([
        EntityField({ data: 'RiskScore', parse: ɵ9 }),
        __metadata("design:type", MachineRiskScore)
    ], Machine.prototype, "riskScore", void 0);
    __decorate([
        EntityField({ data: 'DeviceType' }),
        __metadata("design:type", String)
    ], Machine.prototype, "deviceType", void 0);
    __decorate([
        EntityField({ data: 'DeviceCategory' }),
        __metadata("design:type", String)
    ], Machine.prototype, "deviceCategory", void 0);
    __decorate([
        EntityField({ data: 'SystemManufacturer' }),
        __metadata("design:type", String)
    ], Machine.prototype, "vendor", void 0);
    __decorate([
        EntityField({ data: 'SystemProductName' }),
        __metadata("design:type", String)
    ], Machine.prototype, "model", void 0);
    __decorate([
        EntityField({ data: 'IsRiskScoreImpactedByNonMdatpAlerts' }),
        __metadata("design:type", Boolean)
    ], Machine.prototype, "isRiskScoreImpactedByNonMdatpAlerts", void 0);
    __decorate([
        EntityField({ data: 'ExposureScore', parse: ɵ10 }),
        __metadata("design:type", MachineExposureScore)
    ], Machine.prototype, "exposureScore", void 0);
    __decorate([
        EntityField({ data: 'AssetValue', parse: ɵ11 }),
        __metadata("design:type", String)
    ], Machine.prototype, "assetValue", void 0);
    __decorate([
        EntityField({ data: 'ExclusionState', parse: ɵ12 }),
        __metadata("design:type", String)
    ], Machine.prototype, "exclusionState", void 0);
    __decorate([
        EntityField({
            data: 'MachineTags',
            parse: ɵ13,
        }),
        __metadata("design:type", Array)
    ], Machine.prototype, "userDefinedTags", void 0);
    __decorate([
        EntityField({ data: 'DataSensitivity' }),
        __metadata("design:type", DataSensitivity)
    ], Machine.prototype, "sensitivity", void 0);
    __decorate([
        EntityField({ data: 'ExtendedMachineTags' }),
        __metadata("design:type", MachineTags)
    ], Machine.prototype, "extendedTags", void 0);
    __decorate([
        EntityField({ data: 'TotalAlerts' }),
        __metadata("design:type", Number)
    ], Machine.prototype, "totalAlerts", void 0);
    __decorate([
        EntityField({ data: 'ActiveAlerts' }),
        __metadata("design:type", Number)
    ], Machine.prototype, "activeAlerts", void 0);
    __decorate([
        EntityField({ data: 'ResourceAccesses', arrayOf: DeviceResource }),
        __metadata("design:type", Array)
    ], Machine.prototype, "resources", void 0);
    __decorate([
        EntityField({ data: 'Spns' }),
        __metadata("design:type", Array)
    ], Machine.prototype, "spns", void 0);
    __decorate([
        EntityField({ data: 'ParentGroups', arrayOf: DeviceGroupMembership }),
        __metadata("design:type", Array)
    ], Machine.prototype, "groupMembership", void 0);
    __decorate([
        EntityField({ data: 'UserAccountControlFlags' }),
        __metadata("design:type", DeviceUserAccountControlFlags)
    ], Machine.prototype, "uacFlags", void 0);
    __decorate([
        EntityField({ data: 'SamName' }),
        __metadata("design:type", String)
    ], Machine.prototype, "samName", void 0);
    __decorate([
        EntityField({ data: 'CanonicalName' }),
        __metadata("design:type", String)
    ], Machine.prototype, "canonicalName", void 0);
    __decorate([
        EntityField({ data: 'DistinguishedName' }),
        __metadata("design:type", String)
    ], Machine.prototype, "distinguishedName", void 0);
    __decorate([
        EntityField({ data: 'Sid' }),
        __metadata("design:type", String)
    ], Machine.prototype, "sid", void 0);
    __decorate([
        EntityField({ data: 'CreationTime' }),
        __metadata("design:type", Date)
    ], Machine.prototype, "creationTime", void 0);
    __decorate([
        EntityField({ data: 'IsManagedByMdatp' }),
        __metadata("design:type", Boolean)
    ], Machine.prototype, "isManagedByMdatp", void 0);
    __decorate([
        EntityField({ data: 'MemEnrollmentStatus' }),
        __metadata("design:type", Number)
    ], Machine.prototype, "memEnrollmentStatus", void 0);
    __decorate([
        EntityField({ data: 'OnboardingStatus' }),
        __metadata("design:type", String)
    ], Machine.prototype, "onboardingStatus", void 0);
    __decorate([
        EntityField({ data: 'LastIpV6Address' }),
        __metadata("design:type", String)
    ], Machine.prototype, "lastIpV6Address", void 0);
    __decorate([
        EntityField({ data: 'LastMacAddress' }),
        __metadata("design:type", String)
    ], Machine.prototype, "lastMacAddress", void 0);
    __decorate([
        EntityField({ data: 'AadDeviceId' }),
        __metadata("design:type", String)
    ], Machine.prototype, "aadDeviceId", void 0);
    __decorate([
        EntityField({ data: 'DeviceOwner' }),
        __metadata("design:type", String)
    ], Machine.prototype, "deviceOwner", void 0);
    __decorate([
        EntityField({ data: 'IsDeleted' }),
        __metadata("design:type", Boolean)
    ], Machine.prototype, "isDeleted", void 0);
    __decorate([
        EntityField({ data: 'MergedIntoMachineId' }),
        __metadata("design:type", String)
    ], Machine.prototype, "mergedIntoMachineId", void 0);
    __decorate([
        EntityField({ data: 'ManagedBy' }),
        __metadata("design:type", MachineDeviceManager)
    ], Machine.prototype, "managedBy", void 0);
    __decorate([
        EntityField({ data: 'ManagedByStatus' }),
        __metadata("design:type", MachineManagedByStatus)
    ], Machine.prototype, "managedByStatus", void 0);
    Machine = __decorate([
        Entity({
            singularName: 'Device',
            pluralName: 'Devices',
            endpoint: ɵ0,
            allItemsEndpointTrailingSlash: false,
            allItemsProperty: 'data',
            parseItemQuery: ɵ1,
            baseUrl: ɵ2,
            parseDataQuery: ɵ3,
            cache: {
                time: 1000 * 60,
                max: 10,
            },
        })
    ], Machine);
    return Machine;
}(EntityModelBase));
export { Machine };
// since sometimes we have a partial machine entity we used an external function
// instead of putting this function inside of the entity as a getter.
export function getMachineRequestIds(machine) {
    return {
        machineId: machine.id,
        senseMachineId: machine.senseMachineId || '',
        computerDnsName: machine.name || '',
    };
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13 };
