import { AdvancedFeatureSection } from './advanced-feature-config.interface';
import { Injectable } from '@angular/core';
import { OfficeIntegrationService } from '../services/office-integration.service';
import {
	AdvancedFeatures,
	AutomatedIrProperties,
	AutomatedIrStatus,
	ComplianceAlertsShareStatus,
	AllowNonAuthenticatedSenseStatus,
	EnvironmentName,
	IntuneIntegrationStatus,
	LiveResponseSettings,
	OfficeIntegrationSettings,
	PreviewExperienceSettings,
} from '@wcd/domain';
import { Observable, of, throwError, timer } from 'rxjs';
import { Paris } from '@microsoft/paris';
import { Feature, FeaturesService, FlavorService } from '@wcd/config';
import { AppConfigService } from '@wcd/app-config';
import {
	catchError,
	delay,
	delayWhen,
	map,
	mergeMap,
	retryWhen,
	scan,
	share,
	switchMap,
	tap,
} from 'rxjs/operators';
import { RbacMdeAllowedActions } from '../../rbac/enums/mde-allowed-actions.enum';
import { RbacControlState } from '../../rbac/models/rbac-control-settings.model';
import { omit } from 'lodash-es';
import { IsPublicEnvironment } from '@wcd/auth';
import { AppFlavorConfig } from '@wcd/scc-common';
import { sccHostService } from '@wcd/scc-interface';

@Injectable()
export class AdvancedFeaturesService {
	constructor(
		private officeIntegrationService: OfficeIntegrationService,
		private featuresService: FeaturesService,
		private appConfigService: AppConfigService,
		private paris: Paris,
		private flavorService: FlavorService
	) {
		this.isFairFax = appConfigService.environmentName === EnvironmentName.Fairfax;
	}

	private isFairFax: boolean;
	private _advancedFeatureSections: Array<AdvancedFeatureSection>;

	get advancedFeatureSections(): Array<AdvancedFeatureSection> {
		if (!this._advancedFeatureSections) {
			const previewEx$ = this.paris.getItemById(PreviewExperienceSettings, 1).pipe(
				delay(1),
				share()
			);
			const isLiveResponseForServersEnabled =
				this.featuresService.isAnyEnabled([
					Feature.Server2019BackportSupportToRS6,
					Feature.LiveResponseForWindowsServer2016,
					Feature.LiveResponseForWindowsServer2012R2,
				]) && !this.featuresService.isEnabled(Feature.LiveResponseForServerExcludeTenant);

			this._advancedFeatureSections = [
				...(this.flavorService.isEnabled(AppFlavorConfig.settings.autoIRMdeOnly)
					? [
							{
								name: 'Automated investigations',
								getValues: () =>
									of({
										automatedIr: this.appConfigService.isAutomatedIrEnabled,
									}),
								saveValues: (values: { automatedIr: boolean }) => {
									return previewEx$.pipe(
										switchMap(previewData =>
											this.paris
												.getRepository(AutomatedIrStatus)
												.save({
													isEnabled: values.automatedIr,
													sliceId: previewData.sliceId,
												})
												.pipe(
													mergeMap(() =>
														this.paris
															.getItemById(
																AutomatedIrStatus,
																1,
																{
																	allowCache: false,
																},
																{
																	sliceId: previewData.sliceId,
																}
															)
															.pipe(
																tap((res: AutomatedIrStatus) => {
																	if (
																		res.isEnabled === values.automatedIr
																	) {
																		this.appConfigService.updateChangeableProperties(
																			{
																				isAutomatedIrEnabled:
																					res.isEnabled,
																			}
																		);
																		this.appConfigService.isAutomatedIrEnabled$.next(
																			this.appConfigService
																				.isAutomatedIrEnabled
																		);
																	} else
																		throw `Failed to ${
																			values.automatedIr
																				? 'enable'
																				: 'disable'
																		} Automated Investigations`;
																}),
																retryWhen((errors: Observable<any>) => {
																	return errors.pipe(
																		scan((errorCount: number, err) => {
																			if (errorCount > 2) throw err;
																			return errorCount + 1;
																		}, 0),
																		delayWhen(() => timer(5000, 5000))
																	);
																})
															)
													)
												)
										)
									);
								},
								settings: [
									{
										id: 'automatedIr',
										requireLicense: false,
										priority: 0,
									},
								],
							},
					  ]
					: []),
				this.appConfigService.isAutomatedIrEnabled &&
				this.flavorService.isEnabled(AppFlavorConfig.settings.autoIRMdeOnly)
					? {
							name: 'Live Response',
							reevaluateOnSettingChange: true,
							getValues: changes => {
								if (!changes) {
									return this.paris.getItemById(LiveResponseSettings, 1, undefined, {
										useV2Api: this.featuresService.isEnabled(
											Feature.LiveResponseTransitionCodeSeparation
										),
									});
								}
								const { newValues, settingId } = changes;
								if (!isLiveResponseForServersEnabled) {
									return of(newValues);
								}
								if (settingId === 'liveResponseForServers') {
									return of(newValues);
								}
								return of({
									...newValues,
									liveResponseForServers: newValues.liveResponseEnabled,
								});
							},
							saveValues: (
								settings: LiveResponseSettings,
								originalSettings: LiveResponseSettings
							) => {
								const settingsObj: LiveResponseSettings = [
									'liveResponseEnabled',
									'unsignedScriptsEnabled',
									'liveResponseForServers',
								].reduce(
									(acc: LiveResponseSettings, prop: keyof LiveResponseSettings) => {
										if (settings[prop] !== originalSettings[prop]) {
											return { ...acc, [prop]: settings[prop] };
										}
										return acc;
									},
									{ id: undefined }
								);
								const repository = this.paris.getRepository(LiveResponseSettings);
								return repository
									.save(settingsObj, {
										params: {
											useV2Api: this.featuresService.isEnabled(
												Feature.LiveResponseTransitionCodeSeparation
											),
										},
									})
									.pipe(
										tap({
											next: () => {
												this.appConfigService.updateChangeableProperties({
													isLiveResponseEnabled: settingsObj.liveResponseEnabled,
												});
											},
											complete: () => {
												repository.clearCache();
											},
										})
									);
							},
							settings: [
								{
									id: 'liveResponseEnabled',
									isVisible: (sectionSettings: AdvancedFeatures) =>
										!this.isFairFax ||
										this.featuresService.isEnabled(Feature.FairfaxLiveResponse),
									requireLicense: false,
									priority: 30,
									rbacControlSettings: {
										permissions: [RbacMdeAllowedActions.admin],
										state: RbacControlState.disabled,
									},
								},
								isLiveResponseForServersEnabled
									? {
											id: 'liveResponseForServers',
											isVisible: (sectionSettings: AdvancedFeatures) =>
												!this.isFairFax ||
												this.featuresService.isEnabled(Feature.FairfaxLiveResponse),
											requireLicense: false,
											priority: 40,
											rbacControlSettings: {
												permissions: [RbacMdeAllowedActions.admin],
												state: RbacControlState.disabled,
											},
											isDisabled: (
												liveResponseForServers: boolean,
												sectionData: LiveResponseSettings
											) => {
												return of(!sectionData.liveResponseEnabled);
											},
											warningTooltip:
												'settings_advancedFeatures_features_liveResponseForServers_warningTooltip',
									  }
									: null,
								{
									id: 'unsignedScriptsEnabled',
									isVisible: (sectionSettings: AdvancedFeatures) =>
										!this.isFairFax ||
										this.featuresService.isEnabled(
											Feature.FairfaxLiveResponseUnsignedScripts
										),
									requireLicense: false,
									priority: 50,
									rbacControlSettings: {
										permissions: [RbacMdeAllowedActions.admin],
										state: RbacControlState.disabled,
									},
								},
							].filter(Boolean),
							features: [Feature.LiveResponse],
					  }
					: null,
				this.appConfigService.isAutomatedIrEnabled
					? {
						name: 'PUA remediation',
						getValues: () =>
							this.paris.getItemById(AutomatedIrProperties, 1).pipe(
								map(settings => {
									if (this.featuresService.isEnabled(Feature.PuaMachineConfiguration)) {
										return {
											...settings,
											automatedIrRemediatePuaMachine:
												settings.automatedIrRemediatePua,
										};
									}
									return settings;
								})
							),
						saveValues: (settings: AutomatedIrProperties) => {
							const remediatePua =
								(<any>settings).automatedIrRemediatePuaMachine === undefined
									? (<AutomatedIrProperties>settings).automatedIrRemediatePua
									: (<any>settings).automatedIrRemediatePuaMachine;
							const parsedSettings = {
								...settings,
								automatedIrRemediatePua: remediatePua,
							};
							return this.paris
								.getRepository(AutomatedIrProperties)
								.save(omit(parsedSettings, ['id', 'automatedIrRemediatePuaMachine']));
						},
						settings: [
							{
								id: this.featuresService.isEnabled(Feature.PuaMachineConfiguration)
									? 'automatedIrRemediatePuaMachine'
									: 'automatedIrRemediatePua',
								requireLicense: false,
								priority: 60,
								rbacControlSettings: {
									permissions: [RbacMdeAllowedActions.securitySettings],
									state: RbacControlState.disabled,
								},
							},
						],
						features: [Feature.PuaAsSuspicious],
					}
					: null,
				{
					name: 'Advanced features settings',
					getValues: () =>
						this.getAdvancedFeaturesSettings().pipe(
							map((values: AdvancedFeatures) => ({
								...values,
								deviceDiscoveryEnabled: !values.magellanOptOut,
							}))
						),
					saveValues: (values: AdvancedFeatures & { deviceDiscoveryEnabled }) =>
						this.paris
							.getRepository(AdvancedFeatures)
							.save({ ...values, magellanOptOut: !values.deviceDiscoveryEnabled }),
					settings: [
						{
							id: 'isolateIncidentsWithDifferentDeviceGroups',
							priority: 70,
							warning: () =>
								of(
									'settings_advancedFeatures_features_isolateIncidentsWithDifferentDeviceGroups_warning'
								),
							ignoreWarnings: true,
							features: [Feature.EnableControllingRbacConstraint],
							flavorConfig: AppFlavorConfig.settings.deviceGroups,
						},
						{
							id: 'enableWdavAuditMode',
							requireLicense: false,
							priority: 80,
							features: [Feature.EnableWdavAuditMode],
						},
						{
							id: 'enableWdavPassiveModeRemediation',
							requireLicense: false,
							priority: 90,
							features: [Feature.EnableWdavPassiveModeRemediation],
							flavorConfig: AppFlavorConfig.settings.tvm,
						},
						{
							id: 'autoResolveInvestigatedAlerts',
							requireLicense: false,
							priority: 100,
							features: [Feature.AutoResolveInvestigatedAlerts],
							flavorConfig: AppFlavorConfig.settings.investigation,
						},
						{
							id: 'blockListEnabled',
							requireLicense: false,
							priority: 110,
						},
						{
							id: 'allowWdavNetworkBlock',
							requireLicense: false,
							priority: 120,
							features: [Feature.BlockIpAndDomain],
						},
						{
							id: 'enableWdavAntiTampering',
							requireLicense: false,
							priority: 130,
							features: [Feature.WdavAntiTampering],
							warning: () =>
								of('settings_advancedFeatures_features_enableWdavAntiTampering_warning'),
							ignoreWarnings: true,
						},
						{
							id: 'showUserAadProfile',
							requireLicense: true,
							priority: 140,
						},
						{
							id: 'skypeIntegrationEnabled',
							isVisible: (sectionSettings: AdvancedFeatures) =>
								!this.isFairFax ||
								this.featuresService.isEnabled(Feature.FairfaxSkypeIntegration),
							requireLicense: true,
							priority: 150,
						},
						{
							id: 'endpointDlpEnabled',
							isVisible: (sectionSettings: AdvancedFeatures) =>
								this.featuresService.isEnabled(Feature.DlpOfficeAudit),
							requireLicense: false,
							priority: 160,
						},
						{
							id: 'aatpIntegrationEnabled',
							requireLicense: true,
							isVisible: () => {
								return this.featuresService.isEnabled(Feature.AatpIntegration);
							},
							warning: (value, advancedFeatures: AdvancedFeatures) => {
								const isAtpWarning: boolean =
									value &&
									advancedFeatures.aatpIntegrationEnabled &&
									!advancedFeatures.aatpWorkspaceExists;
								return of(
									isAtpWarning
										? 'settings.advancedFeatures.features.aatpIntegrationEnabled.warning'
										: null
								);
							},
							priority: 170,
							flavorConfig: AppFlavorConfig.settings.itpIntegration,
						},
						{
							id: 'officeIntegrationEnabled',
							isVisible: (sectionSettings: AdvancedFeatures) =>
								!this.isFairFax ||
								this.featuresService.isEnabled(Feature.FairfaxOfficeIntegration),
							requireLicense: false,
							badge: () =>
								this.officeIntegrationService.officeIntegrationWarning$.pipe(
									map((isWarning: boolean) => (isWarning ? 1 : null))
								),
							warningTooltip:
								'settings.advancedFeatures.features.officeIntegrationEnabled.warningTooltip',
							warning: () =>
								this.officeIntegrationService.officeIntegrationSettings$.pipe(
									map((officeIntegrationSettings: OfficeIntegrationSettings) => {
										let warningKey: string = null;

										if (
											officeIntegrationSettings &&
											officeIntegrationSettings.enableOffice365Integration
										) {
											if (!officeIntegrationSettings.isAuthorized)
												warningKey = 'integrationPermission';
											else if (!officeIntegrationSettings.officeLicenseEnabled)
												warningKey = 'license';
											else if (!officeIntegrationSettings.officeToAtpIntegrationEnabled)
												warningKey = 'optIn';
										}

										return (
											warningKey &&
											`settings.advancedFeatures.features.officeIntegrationEnabled.warnings.${warningKey}`
										);
									})
								),
							priority: 180,
							features: [Feature.OfficeAtpIntegration],
							flavorConfig: AppFlavorConfig.settings.officeIntegration,
						},
						{
							id: 'managedThreatHuntingEnabled',
							requireLicense: false,
							isVisible: (sectionSettings: AdvancedFeatures) =>
								!!sectionSettings.managedThreatHuntingApproved &&
								(!this.isFairFax ||
									this.featuresService.isEnabled(Feature.FairfaxThreatHunting)),
							features: [Feature.BilbaoRegistration],
							flavorConfig: AppFlavorConfig.threatExpert.mde,
							ignoreWarnings: true,
							priority: 190,
						},
						{
							id: 'enableMcasIntegration',
							isVisible: () => this.featuresService.isEnabled(Feature.McasIntegration),
							requireLicense: false,
							priority: 200,
							features: [Feature.McasIntegration],
							flavorConfig: AppFlavorConfig.settings.mcasIntegration,
						},
						{
							id: 'enableAipIntegration',
							isVisible: () => this.featuresService.isEnabled(Feature.AatpIntegration),
							requireLicense: false,
							priority: 210,
							features: [Feature.AipIntegration],
						},
						{
							id: 'webCategoriesEnabled',
							isVisible: (sectionSettings: AdvancedFeatures) =>
								!this.isFairFax ||
								this.featuresService.isEnabled(Feature.FairfaxWebCategories),
							requireLicense: false,
							priority: 230,
							features: [Feature.WebContentFilteringPolicy],
						},
						{
							id: 'enableAuditTrail',
							requireLicense: false,
							priority: 240,
							isVisible: (sectionSettings: AdvancedFeatures) =>
								IsPublicEnvironment(this.appConfigService),
							features: [Feature.MgmtAuditTrail],
							flavorConfig: AppFlavorConfig.settings.audit,
						},
						{
							id: 'deviceDiscoveryEnabled',
							requireLicense: false,
							priority: 285,
							features: [Feature.MagellanOptOut],
							flavorConfig: AppFlavorConfig.devices.iotDevices,
							ignoreWarnings: true,
						},
						{
							id: 'dartDataCollection',
							priority: 289,
							isVisible: (sectionSettings: AdvancedFeatures) =>
										this.featuresService.isEnabled(Feature.EnableDartDataCollection),
							requireLicense: false,
							warning: () => of('settings_advancedFeatures_features_dartDataCollection_warning'),
							ignoreWarnings: true,
							rbacControlSettings: {
								permissions: [RbacMdeAllowedActions.admin]
							},
							features: [Feature.EnableDartDataCollection],
						},
						{
							id: 'enableQuarantinedFileDownload',
							requireLicense: false,
							priority: 260,
							features: [Feature.QuarantinedFileDownload],
							flavorConfig: AppFlavorConfig.settings.autoIR,
						},
					].filter(item => !item.flavorConfig || this.flavorService.isEnabled(item.flavorConfig)),
				},
				{
					name: 'Compliance Alerts share',
					getValues: () =>
						this.paris.getItemById(ComplianceAlertsShareStatus, 1).pipe(
							catchError(err =>
								of({
									complianceAlertsShareStatus: false,
									failed: true,
								})
							)
						),
					saveValues: (complianceAlertsShareStatus: ComplianceAlertsShareStatus) => {
						return this.paris
							.getRepository(ComplianceAlertsShareStatus)
							.save(complianceAlertsShareStatus)
							.pipe(
								catchError(() =>
									throwError(
										`Failed to ${
											complianceAlertsShareStatus.complianceAlertsShareEnabled
												? 'enable'
												: 'disable'
										} compliance alerts share.`
									)
								)
							);
					},
					settings: [
						{
							id: 'complianceAlertsShareEnabled',
							priority: 270,
							isVisible: (sectionSettings: AdvancedFeatures) =>
								!this.isFairFax ||
								this.featuresService.isEnabled(Feature.ComplianceAlertSharing),
						},
					],
					features: [Feature.ComplianceAlertSharing],
					flavorConfig: AppFlavorConfig.settings.alertsShare,
				},
				{
					name: 'Intune integration settings',
					getValues: () =>
						this.paris.getItemById(IntuneIntegrationStatus, 1).pipe(
							catchError(err =>
								of({
									IntuneIntegrationStatus: false,
									licenseEnabled: false,
									failed: true,
								})
							)
						),
					saveValues: (values: IntuneIntegrationStatus) => {
						return this.paris
							.getRepository(IntuneIntegrationStatus)
							.save(values)
							.pipe(
								catchError(() =>
									throwError(
										`Failed to ${
											values.intuneIntegrationEnabled ? 'enable' : 'disable'
										} Intune integration.`
									)
								)
							);
					},
					settings: [
						{
							id: 'intuneIntegrationEnabled',
							requireLicense: true,
							warningTooltip:
								'settings.advancedFeatures.features.intuneIntegrationEnabled.warningTooltip',
							warning: (value, intuneIntegrationStatus: IntuneIntegrationStatus) =>
								of(
									intuneIntegrationStatus.licenseEnabled || intuneIntegrationStatus.failed
										? null
										: 'settings.advancedFeatures.features.intuneIntegrationEnabled.warning'
								),
							priority: 280,
							isVisible: (sectionSettings: AdvancedFeatures) =>
								!this.isFairFax ||
								this.featuresService.isEnabled(Feature.FairfaxIntuneIntegration),
						},
					],
					features: [Feature.IntuneIntegration],
				},
				{
					name: 'Preview experience settings',
					getValues: () => previewEx$,
					saveValues: (values: PreviewExperienceSettings) =>
						this.paris.getRepository(PreviewExperienceSettings).save(values),
					settings: [
						{
							id: 'previewExperienceEnabled',
							isDisabled: (value, data: PreviewExperienceSettings) => of(data.forcedEnabled),
							isVisible: (sectionSettings: AdvancedFeatures) =>
								!this.isFairFax ||
								this.featuresService.isEnabled(Feature.FairfaxPreviewExperience),
							warningTooltip:
								'settings.advancedFeatures.features.previewExperienceEnabled.warningTooltip',
							requireLicense: false,
							priority: 290,
						},
					],
				},
				{
					name: 'AllowNonAuthenticatedSense',
					getValues: () =>
						this.paris.getItemById(AllowNonAuthenticatedSenseStatus, 1).pipe(
							catchError(err =>
								of({
									allowNonAuthenticatedSense: false,
									failed: true,
								})
							)
						),
					saveValues: (allowNonAuthenticatedSenseStatus: AllowNonAuthenticatedSenseStatus) => {
						return this.paris
							.getRepository(AllowNonAuthenticatedSenseStatus)
							.save(allowNonAuthenticatedSenseStatus)
							.pipe(
								catchError(() =>
									throwError(
										`Failed to ${
											allowNonAuthenticatedSenseStatus.allowNonAuthenticatedSense
												? 'enable'
												: 'disable'
										} allow non authenticated Sense.`
									)
								)
							);
					},
					settings: [
						{
							id: 'allowNonAuthenticatedSense',
							priority: 270,
							features: [Feature.AllowNonAuthenticatedSense],
						},
					],
				},
			].filter(
				(section: AdvancedFeatureSection) =>
					section &&
					(!section.features ||
						(this.featuresService.isAnyEnabled(section.features) &&
							(!section.flavorConfig || this.flavorService.isEnabled(section.flavorConfig))))
			);
		}

		return this._advancedFeatureSections;
	}

	getAdvancedFeaturesSettings(): Observable<AdvancedFeatures> {
		return this.paris.getItemById(AdvancedFeatures, 1);
	}
}
