import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { FlavorService, TvmLicensesAngularService, Feature, FeaturesService } from '@wcd/config';

@Injectable()
export class TvmLicenseOrFlavorGuard implements CanActivate {
	constructor(
		private tvmLicensesService: TvmLicensesAngularService,
		private flavorService: FlavorService,
		private featuresService: FeaturesService
	) {}

	canActivate(route: ActivatedRouteSnapshot) {
		const data = route.routeConfig.data;
		const tvmLicensesEnabled = this.featuresService.isEnabled(Feature.TvmPremium);
		const isEnabledByFlavor = data && this.flavorService.isEnabled(data.flavorConfig);
		const isEnabledByTvmLicenses = data && this.tvmLicensesService.isEnabled(data.tvmLicenseType);
		return (tvmLicensesEnabled && isEnabledByTvmLicenses) || isEnabledByFlavor;
	}
}
