import { ModalContainer } from '../../dialogs/modals/models/modal-container.model';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AuthService } from '@wcd/auth';
import { Paris } from '@microsoft/paris';
import { BilbaoRegistrationApiCall, BilbaoRegistration } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { Component } from '@angular/core';

@Component({
	selector: 'bilbao-registration',
	templateUrl: './bilbao-registration.component.html',
})
export class BilbaoRegistrationComponent extends ModalContainer {
	registration: BilbaoRegistration = { email: '', name: '' };

	isSaving = false;

	constructor(
		router: Router,
		authService: AuthService,
		private paris: Paris,
		private i18nService: I18nService,
		private dialogsService: DialogsService
	) {
		super(router);
		this.registration.email = authService.currentUser.username;
	}

	submit() {
		this.isSaving = true;

		this.paris
			.apiCall(BilbaoRegistrationApiCall, this.registration)
			.pipe(
				finalize(() => {
					this.isSaving = false;
				})
			)
			.subscribe(
				() => {
					this.closeModal();
					this.dialogsService.confirm({
						title: '',
						text: this.i18nService.get(
							'settings.advancedFeatures.features.managedThreatHuntingRegistration.confirm.text'
						),
						showConfirm: false,
						cancelText: this.i18nService.get(
							'settings.advancedFeatures.features.managedThreatHuntingRegistration.confirm.close'
						),
					});
				},
				error => {
					this.dialogsService.showError({
						title: this.i18nService.get(
							'settings.advancedFeatures.features.managedThreatHuntingRegistration.error.title'
						),
						text: this.i18nService.get(
							'settings.advancedFeatures.features.managedThreatHuntingRegistration.error.text'
						),
						data: error,
					});
				}
			);
	}
}
