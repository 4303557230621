var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { SuppressionRuleConditionIocType } from './suppression-rule-condition-ioc-type.enum';
import { SuppressionRuleConditionIdType } from './suppression-rule-condition-id-type.enum';
import { SuppressionRuleType } from './suppression-rule-type.enum';
var ɵ0 = SuppressionRuleConditionIdType.FileName, ɵ1 = SuppressionRuleConditionIdType.FolderPath, ɵ2 = SuppressionRuleConditionIdType.Sha1, ɵ3 = SuppressionRuleConditionIdType.Ip, ɵ4 = SuppressionRuleConditionIdType.Url, ɵ5 = SuppressionRuleConditionIdType.CommandLine, ɵ6 = SuppressionRuleConditionIdType.UserSid;
var SuppressionRuleConditionType = /** @class */ (function (_super) {
    __extends(SuppressionRuleConditionType, _super);
    function SuppressionRuleConditionType() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SuppressionRuleConditionType.prototype, "name", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], SuppressionRuleConditionType.prototype, "type", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SuppressionRuleConditionType.prototype, "dataFieldName", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SuppressionRuleConditionType.prototype, "propertyName", void 0);
    __decorate([
        EntityField({ defaultValue: true }),
        __metadata("design:type", Boolean)
    ], SuppressionRuleConditionType.prototype, "allowEdit", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], SuppressionRuleConditionType.prototype, "allowWildCard", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SuppressionRuleConditionType.prototype, "pattern", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SuppressionRuleConditionType.prototype, "patternErrorText", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], SuppressionRuleConditionType.prototype, "supportRuleTypes", void 0);
    SuppressionRuleConditionType = __decorate([
        Entity({
            singularName: 'Suppression Rule Condition Type',
            pluralName: 'Suppression Rule Condition Types',
            values: [
                {
                    id: ɵ0,
                    name: 'File name',
                    type: SuppressionRuleConditionIocType.File,
                    dataFieldName: 'FileName',
                    propertyName: 'name',
                    allowEdit: true,
                    allowWildCard: true,
                    supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
                },
                {
                    id: ɵ1,
                    name: 'Folder path',
                    type: SuppressionRuleConditionIocType.File,
                    dataFieldName: 'FolderPath',
                    propertyName: 'path',
                    allowEdit: true,
                    allowWildCard: true,
                    pattern: 'folderPath',
                    patternErrorText: 'Folder path must end with \\, *, or /',
                    supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
                },
                {
                    id: ɵ2,
                    name: 'SHA1',
                    type: SuppressionRuleConditionIocType.File,
                    dataFieldName: 'Sha1',
                    propertyName: 'sha1',
                    allowEdit: false,
                    pattern: 'sha1',
                    patternErrorText: 'Sha1 must be hexadecimal number 40 digits long',
                    supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
                },
                {
                    id: ɵ3,
                    name: 'IP',
                    type: SuppressionRuleConditionIocType.Ip,
                    dataFieldName: 'Ip',
                    propertyName: 'name',
                    allowEdit: true,
                    allowWildCard: false,
                    pattern: 'ip',
                    patternErrorText: 'Ip address is not valid',
                    supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
                },
                {
                    id: ɵ4,
                    name: 'URL',
                    type: SuppressionRuleConditionIocType.Url,
                    dataFieldName: 'Url',
                    propertyName: 'name',
                    allowEdit: true,
                    allowWildCard: true,
                    patternErrorText: 'Url address is not valid',
                    supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
                },
                {
                    id: ɵ5,
                    name: 'Command line',
                    type: SuppressionRuleConditionIocType.File,
                    dataFieldName: 'CommandLine',
                    propertyName: 'command',
                    allowEdit: true,
                    allowWildCard: true,
                    patternErrorText: 'Command line is not valid',
                    supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
                },
                {
                    id: ɵ6,
                    name: 'User',
                    type: SuppressionRuleConditionIocType.File,
                    dataFieldName: 'UserSid',
                    propertyName: 'userSid',
                    allowEdit: true,
                    allowWildCard: false,
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
            ],
        })
    ], SuppressionRuleConditionType);
    return SuppressionRuleConditionType;
}(EntityModelBase));
export { SuppressionRuleConditionType };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
