import { Injectable, OnDestroy } from '@angular/core';
import { isObservable, ReplaySubject } from 'rxjs';
import { isEqual, isNil, omitBy } from 'lodash-es';
import { EnvironmentName, MtpWorkload, ServiceUrls } from '@wcd/domain';
import {
	AccountType,
	AppConfigData,
	AppConfigChangeableProperties,
	AccountMode,
	AdIotIntegrationStatus,
} from '@wcd/app-config';
import { tenantContextCache, RbacPermission, UserAuthEnforcementMode } from '@wcd/auth';
import { sccHostService } from '@wcd/scc-interface';
import { SccProxyUtils } from '../../scc-proxy-sevice/scc-proxy-utils';
import { ProductFlavor, TvmLicenseCollection } from '@wcd/scc-common';

const demoTenantDomains: ReadonlyArray<string> = [
	'winatpcontoso',
	'winatptestlic11',
	'windowsdefenderatp',
	'winatptestlic12',
	'wdatpcontoso',
	'contoso-us',
	'winatpdemo',
	'wdatpcontosofld',
	'wdatpcontosomtc',
];

export class AppConfigModel implements OnDestroy {
	accountType: AccountType;
	accountMode: AccountMode;
	appInsightsInstrumentationKey: string = window.config.appInsightsKey;
	appNavigateStartTime: Date;
	buildVersion: string =
		window.config.buildVersion || sccHostService.getPackageBasePathByPkg() || 'unknown';
	appInsightsEndpointUrl: string;
	dataCenter: string;
	environmentName: EnvironmentName;
	hotStorageInDays: number;
	isAscTenant: boolean;
	isAutomatedIrEnabled$ = new ReplaySubject<boolean>(1);
	isBilbaoEnabled: boolean;
	IsServiceNowIntegrationEnabled: boolean;
	tvmLicenses: TvmLicenseCollection;
	isCmsEnabled: boolean;
	isDeleted: boolean;
	isDemoTenant: boolean;
	isEvaluationEnabled: boolean;
	isExposedToAllMachineGroups: boolean;
	hasMachineGroups: boolean;
	isOutbreakContentManagementEnabled: boolean;
	isSuspended: boolean;
	onboardingTileDismissed: boolean;
	onboardingStartTime: Date;
	orgId: string;
	serviceUrls: ServiceUrls = <any>{};
	originalServiceUrls: ServiceUrls = <any>{};
	supportedGeoRegions: Array<string>;
	tenantId: string;
	tenantName: string;
	useAppInsights: boolean = window.config.useAppInsights;
	widgetLookback = 30;
	isMdatpActive: boolean;
	isItpActive: boolean;
	isOatpActive: boolean;
	isMapgActive: boolean;
	isAadIpActive: boolean;
	isDlpActive: boolean;
	isMdiActive: boolean;
	itpMtpPermissions: Array<RbacPermission>;
	oatpMtpPermissions: Array<RbacPermission>;
	mdatpMtpPermissions: Array<RbacPermission>;
	mapgMtpPermissions: Array<RbacPermission>;
	aadIpMtpPermissions: Array<RbacPermission>;
	dlpMtpPermissions: Array<RbacPermission>;
	mdiMtpPermissions: Array<RbacPermission>;
	activeMtpWorkloads: Array<MtpWorkload>;
	isMdatpLicenseExpired: boolean;
	mdeFlavor: ProductFlavor;
	magellanOptOut: boolean;
	adIotIntegrationStatus: AdIotIntegrationStatus;
	userAuthEnforcementMode: UserAuthEnforcementMode;
	IsSiemEnabled: boolean;
	isPortalRedirectionSettingsToggleDisabled: boolean;

	private _isAutomatedIrEnabled: boolean;
	private _isLiveResponseEnabled: boolean;
	private _mtpConsent: boolean;
	private _isNominated: boolean;
	private _isMtpEligible: boolean;
	private _isPermittedOnboarding: boolean;
	private _isOnboardingComplete: boolean;
	private _token: string;

	private changeableProperties: AppConfigChangeableProperties = {};

	setData(data: AppConfigData) {
		this.appNavigateStartTime = window.mdatp.pageNavigateStartTime;
		this.hotStorageInDays = data.KustoHotStorageInDays;
		this.useAppInsights = data.UseAppInsight;
		this.appInsightsInstrumentationKey = data.AppInsightInstrumentationKey;
		this.appInsightsEndpointUrl = data.AppInsightsEndpointUrl;
		this.onboardingTileDismissed = data.OnboardingTileDismissed;
		// the date returned from the server has strange formatting
		const tenantOnboardingStartTimeStr: RegExpMatchArray =
			data.TenantOnboardingStartTime && data.TenantOnboardingStartTime.match(/\d+/);
		this.onboardingStartTime =
			tenantOnboardingStartTimeStr && new Date(parseInt(tenantOnboardingStartTimeStr[0], 10));
		this._isOnboardingComplete = data.IsOnboardingComplete;
		this.serviceUrls = Object.freeze(
			SccProxyUtils.getServiceUrls(
				Object.assign({ automatedIr: '/api' }, data.ServiceUrls),
				data.Features
			)
		);
		this.originalServiceUrls = data.ServiceUrls;
		this.dataCenter =
			data.ServiceUrls &&
			data.ServiceUrls.threatIntel &&
			data.ServiceUrls.threatIntel.match(/-(.{3})/)[1];
		this.tenantId = data.AuthInfo && data.AuthInfo.TenantId;
		this.orgId = data.OrgId;
		this.tenantName = data.AuthInfo && data.AuthInfo.TenantName;
		this.isSuspended = data.IsSuspended;
		this.isDeleted = data.IsDeleted;
		this._isMtpEligible = data.IsMtpEligible;
		this._isNominated = data.IsNominated;
		this._isPermittedOnboarding = data.IsPermittedOnboarding;
		this.isAscTenant = !!data.IsAscTenant;
		this.accountType = AccountType[data.AccountType];
		this.accountMode = AccountMode[data.AccountMode];
		this.isCmsEnabled = data.IsCmsEnabled;
		this.isOutbreakContentManagementEnabled = data.IsOutbreakContentManagementEnabled;
		this.isExposedToAllMachineGroups = data.IsExposedToAllMachineGroups;
		this.hasMachineGroups = data.HasMachineGroups;
		this._isAutomatedIrEnabled = data.IsAutomatedIrEnabled;
		this.isAutomatedIrEnabled$.next(this.isAutomatedIrEnabled);
		this._isLiveResponseEnabled = data.AutomatedIrLiveResponse !== false;
		this.isDemoTenant =
			data.AuthInfo &&
			data.AuthInfo.UserName &&
			demoTenantDomains.some((tenantName: string) =>
				data.AuthInfo.UserName.toLowerCase().includes(tenantName)
			);
		if (this.isDemoTenant) this.widgetLookback = 180;

		this.isBilbaoEnabled = !!data.IsBilbaoTenant;
		this.IsServiceNowIntegrationEnabled = !!data.IsServiceNowIntegrationEnabled;
		this.isEvaluationEnabled = !!data.IsEvaluationEnabled;
		this.supportedGeoRegions = data.SupportedGeoRegions;
		this.environmentName = data.EnvironmentName;
		this._mtpConsent = !!data.MtpConsent;

		this.isMdatpActive = data.IsMdatpActive;
		this.isItpActive = data.IsItpActive;
		this.isOatpActive = data.IsOatpActive;
		this.isMapgActive = data.IsMapgActive;
		this.isAadIpActive = data.IsAadIpActive;
		this.isDlpActive = data.IsDlpActive;
		this.isMdiActive = data.IsMdiActive;
		this.mdatpMtpPermissions = data.MdatpMtpPermissions || [];
		this.itpMtpPermissions = data.ItpMtpPermissions || [];
		this.oatpMtpPermissions = data.OatpMtpPermissions || [];
		this.mapgMtpPermissions = data.MapgMtpPermissions || data.MapGMtpPermissions || [];
		this.aadIpMtpPermissions = data.AadIpMtpPermissions || [];
		this.dlpMtpPermissions = data.DlpMtpPermissions || [];
		this.mdiMtpPermissions = data.MdiMtpPermissions || [];
		this.activeMtpWorkloads = data.ActiveMtpWorkloads || [];
		this.isMdatpLicenseExpired = !!data.IsMdatpLicenseExpired;
		this.mdeFlavor = data.MdeFlavor;
		this.magellanOptOut = data.MagellanOptOut;
		this.adIotIntegrationStatus = data.AdIotIntegrationStatus;
		this.tvmLicenses = data.TvmLicenses;
		this.userAuthEnforcementMode = data.UserAuthEnforcementMode;
		this.IsSiemEnabled = data.IsSiemEnabled;
		this.isPortalRedirectionSettingsToggleDisabled = data.IsPortalRedirectionSettingsToggleDisabled;

		if (data.IsOnboardingComplete) {
			// data is updated during tenant onboarding, after provisioning
			Object.freeze(this);
		}
	}

	get token(): string {
		return !isNil(this.changeableProperties && this.changeableProperties.token)
			? this.changeableProperties.token
			: this._token;
	}

	get mtpConsent(): boolean {
		return !isNil(this.changeableProperties && this.changeableProperties.mtpConsent)
			? this.changeableProperties.mtpConsent
			: this._mtpConsent;
	}

	get isOnboardingComplete(): boolean {
		return !isNil(this.changeableProperties && this.changeableProperties.isOnboardingComplete)
			? this.changeableProperties.isOnboardingComplete
			: this._isOnboardingComplete;
	}

	get isNominated(): boolean {
		return !isNil(this.changeableProperties && this.changeableProperties.isNominated)
			? this.changeableProperties.isNominated
			: this._isNominated;
	}

	get isPermittedOnboarding(): boolean {
		return !isNil(this.changeableProperties && this.changeableProperties.isPermittedOnboarding)
			? this.changeableProperties.isPermittedOnboarding
			: this._isPermittedOnboarding;
	}

	get isMtpEligible(): boolean {
		return !isNil(this.changeableProperties && this.changeableProperties.isMtpEligible)
			? this.changeableProperties.isMtpEligible
			: this._isMtpEligible;
	}

	get isAutomatedIrEnabled(): boolean {
		return !isNil(this.changeableProperties && this.changeableProperties.isAutomatedIrEnabled)
			? this.changeableProperties.isAutomatedIrEnabled
			: this._isAutomatedIrEnabled;
	}

	get isLiveResponseEnabled(): boolean {
		return !isNil(this.changeableProperties && this.changeableProperties.isLiveResponseEnabled)
			? this.changeableProperties.isLiveResponseEnabled
			: this._isLiveResponseEnabled;
	}

	// Returns 'true' if the tenant has an active workload (has a license + provisioned + opted in). Check BE for updated meaning.
	// Will return 'false' for suspended \ opted out workloads
	hasActiveWorkload(mtpWorkload: MtpWorkload): boolean {
		return this.activeMtpWorkloads.includes(mtpWorkload);
	}

	updateChangeableProperties(properties: AppConfigChangeableProperties) {
		Object.assign(this.changeableProperties, properties);

		// Updating global authData properties manually for SCC portal
		// Should consider to improve infra to avoid updating the global authData property
		if (properties.hasOwnProperty('isOnboardingComplete'))
			tenantContextCache.appConfig.IsOnboardingComplete = properties.isOnboardingComplete;
		if (properties.hasOwnProperty('isNominated'))
			tenantContextCache.appConfig.IsNominated = properties.isNominated;
		if (properties.hasOwnProperty('isPermittedOnboarding'))
			tenantContextCache.appConfig.IsPermittedOnboarding = properties.isPermittedOnboarding;
		if (properties.hasOwnProperty('mtpConsent'))
			tenantContextCache.appConfig.MtpConsent = properties.mtpConsent;
	}

	equals(other: AppConfigModel): boolean {
		return isEqual(this.withoutObservables(), other.withoutObservables());
	}

	toJson(): Partial<this> {
		return this.withoutObservables();
	}

	private withoutObservables(): Partial<this> {
		// TODO fix this, I am not sure who thought Partial<this> is a good type here
		return omitBy(this, isObservable) as Partial<this>;
	}

	ngOnDestroy(): void {
		this.isAutomatedIrEnabled$.complete();
	}
}

@Injectable({ providedIn: 'root' })
export class AppConfigService extends AppConfigModel {}
