var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { filter, map, switchMap } from 'rxjs/operators';
import { File, FileVirusTotalFileReportRelationship } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { ReportWidgetComponent } from '../../../../../../reports/components/report-widget.component.base';
import { ReportsService } from '../../../../../../shared-reports/services/reports.service';
import { ActivatedEntity } from '../../../../../../global_entities/services/activated-entity.service';
import { I18nService } from '@wcd/i18n';
var VirusTotalWidgetComponent = /** @class */ (function (_super) {
    __extends(VirusTotalWidgetComponent, _super);
    function VirusTotalWidgetComponent(reportsService, activatedEntity, paris, i18nService) {
        var _this = _super.call(this, reportsService) || this;
        _this.activatedEntity = activatedEntity;
        _this.paris = paris;
        _this.i18nService = i18nService;
        return _this;
    }
    Object.defineProperty(VirusTotalWidgetComponent.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            return {
                id: 'fileMalwareDetection',
                name: this.i18nService.get('file.malwareDetection'),
                loadData: function () {
                    return _this.activatedEntity.currentEntity$.pipe(filter(function (currentEntity) { return currentEntity instanceof File; }), switchMap(function (file) {
                        return _this.paris
                            .getRelatedItem(FileVirusTotalFileReportRelationship, file)
                            .pipe(map(function (virusTotalReport) { return ({
                            virusTotalReport: virusTotalReport,
                            defenderThreat: file.prevalentThreatName,
                            file: file,
                            showFileVerdict: !!(file && file.sha1),
                        }); }));
                    }));
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    return VirusTotalWidgetComponent;
}(ReportWidgetComponent));
export { VirusTotalWidgetComponent };
