
		<div *ngIf="mdeConditions" class="wcd-scroll-horizontal">
			<ng-container
				*ngTemplateOutlet="mdeConditions.conditionType === conditionType.Complex ? complexCondition : singleIocCondition; context: {condition: mdeConditions}">
			</ng-container>
			<ng-template #complexCondition let-condition="condition" let-padding="padding">
				<div [class.wcd-padding-large-left]="padding">
					<ng-container *ngFor="let innerCondition of condition.conditions; let last = last">
						<div>(</div>
						<ng-container
							*ngTemplateOutlet="innerCondition.conditionType === conditionType.Complex ? complexCondition : singleIocCondition; context: {condition: innerCondition, padding: true}">
						</ng-container>
						<div>)</div>
						<div *ngIf="!last">{{ condition.logicalOperator.toUpperCase() }}</div>
					</ng-container>
				</div>
			</ng-template>
			<ng-template #singleIocCondition let-condition="condition" let-padding="padding">
				<div [class.wcd-padding-large-left]="padding">
					<div>{{ 'mdeConditions.textualView' | i18n: { iocRole: condition.entityRole, iocType: condition.iocType === iocType.Process ? iocType.File : condition.iocType } }}:</div>
					<ng-container
						*ngTemplateOutlet="condition.predicate.predicateType === predicateType.Complex ? complexPredicate : basePredicate; context: {predicate: condition.predicate}">
					</ng-container>
				</div>
				<ng-template #complexPredicate let-predicate="predicate">
					<ng-container *ngFor="let innerPredicate of predicate.predicates; let last = last">
						<div class="wcd-padding-large-left">
							<ng-container *ngTemplateOutlet="basePredicate; context: {predicate: innerPredicate}"></ng-container>
							<div *ngIf="!last">{{ predicate.logicalOperator.toUpperCase() }}</div>
						</div>
					</ng-container>
				</ng-template>
				<ng-template #basePredicate let-predicate="predicate">
					<div class="wcd-flex-horizontal">
						<div class="nowrap wcd-padding-xsmall-right">{{ predicate.valueType }}</div>
						<div class="nowrap wcd-padding-xsmall-right">{{ predicate.operator.toLowerCase() }}</div>
						<div *ngIf="predicate.predicateType === predicateType.Simple; else multiValuePredicate">
							"<span class="pre-wrap">{{ predicate.value }}</span>"
						</div>
						<ng-template #multiValuePredicate>
							<div>
								(<span class="pre-wrap">{{ predicate.values.join() }}</span>)
							</div>
						</ng-template>
					</div>
				</ng-template>
			</ng-template>
		</div>
	