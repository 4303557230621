import { IMachineManagedByStatus, MachineManagedByStatusType } from './machine-managed-by-status.models';

export const machineManagedByStatusValues: Array<IMachineManagedByStatus> = [
	MachineManagedByStatusType.Success,
	MachineManagedByStatusType.GeneralError,
	MachineManagedByStatusType.ConnectivityIssue,
	MachineManagedByStatusType.GeneralHybridJoinFailure,
	MachineManagedByStatusType.HybridErrorServiceConnectionPoint,
	MachineManagedByStatusType.DnsError,
	MachineManagedByStatusType.TenantMismatch,
	MachineManagedByStatusType.CertificateError,
	MachineManagedByStatusType.ClockSyncIssue,
	MachineManagedByStatusType.LdapApiError,
	MachineManagedByStatusType.OnPremiseSyncIssue,
	MachineManagedByStatusType.MemConfigurationIssue,
	MachineManagedByStatusType.Unknown,
].map(
	(id, index) => ({
		id: id,
		nameI18nKey: `machineManagedByStatus_${id}`,
		priority: index,
}));


