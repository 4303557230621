import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Route } from '@angular/router';
import { Feature, FeaturesService, FlavorService } from '@wcd/config';
import { LogicalOperator, NavItemModel } from '@wcd/shared';
import { AppConfigService } from '@wcd/app-config';
import { AuthService, IsServiceNowIntegrationEnabled, UserAuthEnforcementMode, TvmLicenseMigrationFlavorGuard } from '@wcd/auth';
import { I18nService } from '@wcd/i18n';
import { sccHostService, SccRoles } from '@wcd/scc-interface';
import { AppFlavorConfig } from '@wcd/scc-common';

@Injectable()
export class AdminService {
	constructor(
		private featuresService: FeaturesService,
		private appConfigService: AppConfigService,
		private readonly authService: AuthService,
		private i18nService: I18nService,
		private flavorService: FlavorService,
		private tvmLicenseMigrationFlavorGuard: TvmLicenseMigrationFlavorGuard
	) {}

	getSettingsNav(route: ActivatedRoute): Array<SettingsNavSection> {
		return this.getStaticSettingsNav(route.routeConfig);
	}

	getStaticSettingsNav(root: Route): Array<SettingsNavSection> {
		let settingNavRoutes: Array<Route> = root.children.filter(_route => {
			return _route.path !== '';
		});

		if (sccHostService.isSCC) {
			settingNavRoutes = settingNavRoutes.filter((child) => child.path != 'scheduled_hunting');
		}
		else {
			// Filter Configuration management from MDATP site
			settingNavRoutes = settingNavRoutes.filter((child) => child.path != 'configuration_management2');
		}

		const sections: Map<string, SettingsNavSection> = settingNavRoutes
			.filter((config: Route) => {
				if (config.data) {
					if (config.data.hidden) {
						return false;
					}

					if (config.path == 'portal_redirection' && this.appConfigService.isPortalRedirectionSettingsToggleDisabled) {
						return false;
					}

					// Will hide the route but won't guard it.
					// If Mde attach is enabled or SMB is enabled, show this route. Otherwise - hide it.
					if (config.path == 'configuration_management2') {
						return (this.featuresService.isEnabled(Feature.EndpointConfigManagement) &&
							this.featuresService.isEnabled(Feature.EndpointConfigManagementFe)) ||
							this.flavorService.isEnabled(AppFlavorConfig.settings.mdeAttach);
					}
					if (config.data.MdeAllowedActionsGuardConfig &&
						!this.authService.currentUser.hasMdeAllowedUserRoleAction(
							config.data.MdeAllowedActionsGuardConfig.AllowedActions,
						)
					) {
						return false;
					}
					if (this.appConfigService.userAuthEnforcementMode === UserAuthEnforcementMode.UnifiedRbac &&
						config.data.UnifiedRbacPermissionGuardConfig &&
						!this.authService.currentUser.hasRequiredMtpPermissionInWorkload(
							config.data.UnifiedRbacPermissionGuardConfig.UnifiedRbacPermission,
							config.data.UnifiedRbacPermissionGuardConfig.Workload)
					) {
						return false;
					}
					if (config.data.disableIfNoAccessToAllGroups) {
						return this.appConfigService.isExposedToAllMachineGroups;
					}
					if (config.data.appConfigValidator) {
						return (
							config.data.appConfigValidator(this.appConfigService) ||
							(config.data.appConfigValidator == IsServiceNowIntegrationEnabled &&
								this.featuresService.isEnabled(Feature.TvmServiceNowIntegration))
						);
					}
				}
				return true;
			})
			.map((config: Route) => this.routeConfigToNav(config))
			.filter(state => !!state)
			.filter(
				state => {
					// To keep the existing behavior - default features logical operator is OR (in case no logical operator is provided)
					const isFeatureAllowed = state.featuresLogicalOperator == LogicalOperator.And ?
						!state.features || this.featuresService.isEnabled(state.features) :
						!state.features || this.featuresService.isAnyEnabled(state.features);
					return isFeatureAllowed && (!state.disableFeatures || !this.featuresService.isAnyEnabled(state.disableFeatures));
				},
			)
			.reduce((sections: Map<string, SettingsNavSection>, state: NavItemModel) => {
				const section: SettingsNavSection = sections.get(state.section);
				if (!section) {
					sections.set(state.section, {
						name: state.section,
						states: [state],
					});
				} else section.states.push(state);

				return sections;
			}, new Map<string, SettingsNavSection>());

		return Array.from(sections.values());
	}

	private routeConfigToNav(routeConfig: Route): NavItemModel {
		const data = routeConfig.data;
		const isTvmLicenseMigrationFlavorGuarded =
			routeConfig.canActivate && routeConfig.canActivate.includes(TvmLicenseMigrationFlavorGuard);
		const isTvmLicenseMigrationFlavorGuardBlocked = isTvmLicenseMigrationFlavorGuarded && !this.tvmLicenseMigrationFlavorGuard.canActivate({routeConfig: { data }} as ActivatedRouteSnapshot);
		const isFlavorGuardBlocked = !isTvmLicenseMigrationFlavorGuarded && !!data.flavorConfig && !this.flavorService.isEnabled(data.flavorConfig);

		if (isTvmLicenseMigrationFlavorGuardBlocked || isFlavorGuardBlocked) {
			return null;
		}

		const localizedPageTitle = data.getPageTitleKey
			? this.i18nService.get(data.getPageTitleKey(this.featuresService))
			: data.pageTitleKey
				? this.i18nService.get(data.pageTitleKey)
				: data.pageTitle;
		return new NavItemModel({
			id: data.id || `settings_${routeConfig.path}`,
			route: !!data.isLegacy ? routeConfig.redirectTo : routeConfig.path,
			routeParams: data.routeParams,
			name: localizedPageTitle,
			section: this.i18nService.get(data.section),
			features: data.features,
			featuresLogicalOperator: data.featuresLogicalOperator,
			disableFeatures: data.disableFeatures,
			isLegacy: !!data.isLegacy,
			badgeId: data.badgeId,
		});
	}
}

export interface SettingsNavSection {
	name: string;
	states: Array<NavItemModel>;
}
