import { LiveResponseCommand, LiveResponseCommandOutput } from '@wcd/domain';
import { compact, padEnd } from 'lodash-es';

export class LiveResponseOutputParserService {
	static getCommandOutput(command: LiveResponseCommand): string {
		if (!(command.outputs || command.errors)) {
			return '';
		}
		const outputs: Array<string> = (command.outputs || []).map(
			(output: LiveResponseCommandOutput<object>) => {
				return this.getStringFromOutput(output, output.tableConfig && output.tableConfig.showHeader);
			}
		);

		const errors: Array<string> = (command.errors || []).map(err => {
			if (typeof err === 'string') {
				return err;
			}
			return err.message;
		});

		const outputStr: string = outputs.length ? outputs.join('\n\n') : '',
			errorsStr: string = errors.length ? 'Errors:\n' + errors.join('\n') : '';
		return compact([outputStr, errorsStr]).join('\n\n');
	}

	private static getStringFromOutput(
		output: LiveResponseCommandOutput<object>,
		showTableHeader?: boolean
	): string {
		switch (output.outputType) {
			case 'string':
				return <string>output.data;
			case 'table':
				return this.jsonToTable(<Array<object>>output.data, output.keys, showTableHeader);
			case 'object':
			default:
				if (typeof output.data === 'object') {
					return JSON.stringify(output.data, null, 2).replace(/\\\\/g, '\\');
				}

				return output.data;
		}
	}

	private static jsonToTable(
		obj: Array<object>,
		keys: Array<{ id: string; name?: string }>,
		includeKeys: boolean = true
	): string {
		const rows: Array<{ [index: string]: string }> = [],
			keyLengthObj: { [index: string]: number } = {};
		obj.forEach(result => {
			const row: { [index: string]: string } = {};
			keys.forEach(key => {
				const value: any = result[key.id],
					valueStr: string = value != null ? String(value) : '';
				row[key.id] = valueStr;
				keyLengthObj[key.id] = Math.max(keyLengthObj[key.id] || 0, valueStr.length);
			});
			rows.push(row);
		});

		if (includeKeys) {
			const row: { [index: string]: string } = {},
				row2: { [index: string]: string } = {};
			keys.forEach(key => {
				const name = key.name || key.id;
				keyLengthObj[key.id] = Math.max(keyLengthObj[key.id] || 0, name.length);
				row[key.id] = name;
				row2[key.id] = '='.repeat(keyLengthObj[key.id]);
			});
			rows.splice(0, 0, row, row2);
		}

		return rows
			.map(row => {
				return keys
					.map(key => {
						return padEnd(row[key.id] || '', keyLengthObj[key.id]);
					})
					.join('\t');
			})
			.join('\n');
	}
}
