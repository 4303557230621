import {
	FabButtonModule,
	FabCheckboxModule,
	FabChoiceGroupModule,
	FabIconModule,
	FabMessageBarModule,
	FabSpinnerModule,
} from '@angular-react/fabric';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DataViewsModule } from '../../dataviews/dataviews.module';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { SharedModule } from '../../shared/shared.module';
import { TagsModule } from '../../tags/tags.module';
import { RiskScoreService } from '../../_seville/app/threatintel/services/seville.threatintel.machine.riskscore.service';
import { RbacEntitiesModule } from '../rbac/rbac-entities.module';
import { MachineIpsComponent } from './components/machine-ips.component';
import { MachineIpsPanelComponent } from './components/machine-ips.panel.component';
import { MachineLoggedOnUsersComponent } from './components/machine-logged-on-users.component';
import { MachineLoggedOnUsersPanelComponent } from './components/machine-logged-on-users.panel.component';
import { MachineScorePieChartComponent } from './components/machine-score-pie.widget';
import { MachineSecurityStateComponent } from './components/machine-score.component';
import { MachineSecurityStatePanelComponent } from './components/machine-score.panel.component';
import { MachineCveComponent } from './components/machine-security-cve.component';
import { MachineSecurityCvePanelComponent } from './components/machine-security-cve.panel.component';
import { MachineEntityPanelComponent } from './components/machine.entity-panel.component';
import { IsolateModalComponent } from './components/modal/isolate-modal.component';
import { RunAntivirusScanModalComponent } from './components/modal/run-antivirus-scan-modal.component';
import { ActionCenterSectionComponent } from './components/panel/action-center/action-center-section.component';
import { MachineActionCenterPanelComponent } from './components/panel/action-center/action-center.component';
import { TroubleshootingModePanelComponent } from './components/panel/troubleshooting-mode/troubleshooting-mode.component';
import { LogonTypePipe } from './pipes/logon-type.pipe';
import { MachineRiskScoreTypeDisplayTextPipe } from './pipes/machine-risk-score-type-display-text.pipe';
import { MachineExposureLevelTypeDisplayTextPipe } from './pipes/machine-exposure-level-type-display-text.pipe';
import { ExposureLevelDisplayTextPipe } from './pipes/exposure-level-display-text.pipe';
import { MachineEntityTypeActionsService } from './services/machine.entity-type-actions.service';
import { MachineEntityTypeService } from './services/machine.entity-type.service';
import { MachinesActionsService } from './services/machines-actions.service';
import { MachineRequestsPermissionsService } from './services/machines-requests.permissions.service';
import { ActionabilityTypeColorService } from './services/actionability-type-color.service';
import { MachinesBackendService } from './services/machines.backend.service';
import { MachinesFieldsService } from './services/machines.fields';
import { MachinesFiltersService } from './services/machines.filters.service';
import { MachinesService } from './services/machines.service';
import { CommentsModule } from '../../comments/comments.module';
import { LogonTypeColorsService } from './services/logon-type-colors.service';
import { MachineLoggedOnUsersDataService } from './services/machine-logged-on-users-data.service';
import { MachineRiskScoreColorService } from './services/machine-risk-score-color.service';
import { MachineExposureLevelColorService } from './services/machine-exposure-level-color.service';
import { MachineRequestErrorCodePipe } from './pipes/machine-request-error-code.pipe';
import { IpWithAdapterEntityDetailsComponent } from './components/ip-with-adapter.entity-details.component';
import { IpAdapterTypePipe } from './pipes/ip-adapter-type.pipe';
import { PieChartModule } from '@wcd/charts';
import { PanelsModule } from '@wcd/panels';
import { TvmModule } from '../../tvm/tvm.module';
import { MachineEventMarkComponent } from './components/machine-event-mark.component';
import { MachineEntityStatusComponent } from './services/machine-entity-status.component';
import { MachineResourcesComponent } from './components/machine-itp-resources.component';
import { MachineResourcesPanelComponent } from './components/machine-itp-resources.panel.component';
import { DataTableModule } from '@wcd/datatable';
import { MachineDirectoryDataPanelComponent } from './components/machine-itp-directory-data.panel.component';
import { MachineGroupMembershipComponent } from './components/itp-directory-data/machine-itp-group-membership.component';
import { MachineDirectoryDataIdentifiersComponent } from './components/itp-directory-data/machine-itp-directory-data-identifiers.component';
import { MachineSpnsComponent } from './components/itp-directory-data/machine-itp-spns.component';
import { MachineUacFlagsComponent } from './components/itp-directory-data/machine-itp-uac-flags.component';
import { MachineValuePanelComponent } from './components/panel/machine-value/machine-value-panel.component';
import { MachinesDataviewTagsComponent } from './components/machines-dataview-tags/machines-dataview-tags.component';
import { GoHuntModule } from '../../hunting-go-hunt/hunting-go-hunt.module';
import { IotDevicesFieldsService } from './services/iot-fields-service';
import { RelationMachineService } from './services/relation-machine.service';
import { IsolationModalWarningsComponent } from './components/modal/isolate-modal-warnings.component';
import { MagellanIsolationStatusService } from './services/magellan-isolation.service';

@NgModule({
	imports: [
		SharedModule,
		DataViewsModule,
		DataTableModule,
		GlobalEntitiesModule,
		PieChartModule,
		TagsModule,
		RbacEntitiesModule,
		FabSpinnerModule,
		FabButtonModule,
		FabIconModule,
		FabCheckboxModule,
		FabChoiceGroupModule,
		FabMessageBarModule,
		CommentsModule,
		PanelsModule,
		TvmModule,
		GoHuntModule,
	],
	providers: [
		MachinesBackendService,
		MachinesService,
		MachinesFiltersService,
		RiskScoreService,
		MachineEntityTypeService,
		LogonTypePipe,
		MachineRequestErrorCodePipe,
		MachineRiskScoreTypeDisplayTextPipe,
		MachineExposureLevelTypeDisplayTextPipe,
		ExposureLevelDisplayTextPipe,
		MachinesFieldsService,
		IotDevicesFieldsService,
		MachineRequestsPermissionsService,
		ActionabilityTypeColorService,
		MachinesActionsService,
		MagellanIsolationStatusService,
		MachineEntityTypeActionsService,
		LogonTypeColorsService,
		MachineLoggedOnUsersDataService,
		MachineRiskScoreColorService,
		MachineExposureLevelColorService,
		RelationMachineService,
	],
	declarations: [
		LogonTypePipe,
		MachineResourcesComponent,
		MachineResourcesPanelComponent,
		MachineRequestErrorCodePipe,
		MachineCveComponent,
		MachineEntityPanelComponent,
		MachineIpsPanelComponent,
		MachineIpsComponent,
		MachineLoggedOnUsersComponent,
		MachineLoggedOnUsersPanelComponent,
		MachineSecurityStatePanelComponent,
		MachineSecurityStateComponent,
		MachineScorePieChartComponent,
		MachineSecurityCvePanelComponent,
		MachineActionCenterPanelComponent,
		TroubleshootingModePanelComponent,
		MachineValuePanelComponent,
		ActionCenterSectionComponent,
		RunAntivirusScanModalComponent,
		IsolateModalComponent,
		IsolationModalWarningsComponent,
		MachineRiskScoreTypeDisplayTextPipe,
		MachineExposureLevelTypeDisplayTextPipe,
		ExposureLevelDisplayTextPipe,
		IpWithAdapterEntityDetailsComponent,
		IpAdapterTypePipe,
		MachineEventMarkComponent,
		MachineEntityStatusComponent,
		MachineDirectoryDataPanelComponent,
		MachineGroupMembershipComponent,
		MachineDirectoryDataIdentifiersComponent,
		MachineSpnsComponent,
		MachineUacFlagsComponent,
		MachinesDataviewTagsComponent,
	],
	exports: [
		MachineIpsPanelComponent,
		MachineLoggedOnUsersPanelComponent,
		RouterModule,
		MachineRiskScoreTypeDisplayTextPipe,
		MachineExposureLevelTypeDisplayTextPipe,
		ExposureLevelDisplayTextPipe,
		MachineEventMarkComponent,
		MachineResourcesPanelComponent,
		MachineDirectoryDataPanelComponent,
		MachinesDataviewTagsComponent,
	],
	entryComponents: [
		MachineIpsPanelComponent,
		MachineLoggedOnUsersPanelComponent,
		MachineSecurityStateComponent,
		MachineScorePieChartComponent,
		MachineCveComponent,
		MachineEntityPanelComponent,
		MachineActionCenterPanelComponent,
		TroubleshootingModePanelComponent,
		MachineValuePanelComponent,
		RunAntivirusScanModalComponent,
		IsolationModalWarningsComponent,
		IsolateModalComponent,
		MachineEventMarkComponent,
		MachineEntityStatusComponent,
		MachineResourcesPanelComponent,
		MachineDirectoryDataPanelComponent,
		MachinesDataviewTagsComponent,
	],
})
export class MachineEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(MachineEntityTypeService);
	}
}
