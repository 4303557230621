import { combineLatest, of } from 'rxjs';
import { AlertCategory, AlertClassification, AlertClassificationId, AlertStatus, GetActorsApiCall, GetIncidentQueueFiltersApiCall, IncidentAssignmentFilterValues, IncidentSensitivityFilterValues, InvestigationStatus, Outbreak, Severity, Tag, TagType, } from '@wcd/domain';
import { AppContextService, Feature, FeaturesService } from '@wcd/config';
import { catchError, map, tap } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { AuthService } from '@wcd/auth';
import { SourceFilterService } from '../../common/services/source-filter.service';
import { MachinesFiltersService, } from '../../machines/services/machines.filters.service';
import { IncidentsBackendService } from './Incidents.backend.service';
import { isNil, values, omit } from 'lodash-es';
import { splitToSentenceCase } from '@wcd/shared';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { EntityTagsService } from '../../common/services/entity-tags.service';
export var FILTER_PRIORITIES = {
    status: 0,
    severity: 10,
    incidentAssignment: 20,
    multipleAlert: 30,
    multipleServiceSource: 40,
    serviceSource: 50,
    tags: 55,
    multipleCategory: 60,
    category: 70,
    sensitivity: 80,
    rbacGroup: 90,
    osPlatform: 100,
    legacyIncidentTags: 110,
    classification: 120,
    investigationStates: 130,
    associatedThreats: 140,
    actorName: 150,
};
var ASSOCIATED_THREATS_FILTER_NAME = 'IoaDefinitionIds';
var DYNAMIC_FILTERS_SELECTION = ['systemTag', 'customTag'];
var IncidentsFiltersService = /** @class */ (function () {
    function IncidentsFiltersService(paris, i18nService, authService, featuresService, appContextService, sourceFilterService, machinesFiltersService, incidentsBackendService, entityTagsService, appInsightsService) {
        this.paris = paris;
        this.i18nService = i18nService;
        this.authService = authService;
        this.featuresService = featuresService;
        this.appContextService = appContextService;
        this.sourceFilterService = sourceFilterService;
        this.machinesFiltersService = machinesFiltersService;
        this.incidentsBackendService = incidentsBackendService;
        this.entityTagsService = entityTagsService;
        this.appInsightsService = appInsightsService;
    }
    IncidentsFiltersService.prototype.clearCache = function () {
        this._incidentFilters = null;
    };
    IncidentsFiltersService.prototype.omitAssociatedThreatsFilter = function (filters) {
        return omit(filters, ASSOCIATED_THREATS_FILTER_NAME);
    };
    IncidentsFiltersService.prototype.getIncidentFilters = function (hideThreatsFilter) {
        var _this = this;
        if (hideThreatsFilter === void 0) { hideThreatsFilter = false; }
        return this.getIncidentFiltersInternal().pipe(map(function (filters) { return (hideThreatsFilter ? _this.omitAssociatedThreatsFilter(filters) : filters); }));
    };
    IncidentsFiltersService.prototype.getIncidentFiltersInternal = function () {
        var _this = this;
        if (this._incidentFilters)
            return of(this._incidentFilters);
        var filtersConfig = {
            severity: {
                count: null,
                values: Severity.entityConfig.values
                    .filter(function (severity) { return severity.isSelectable; })
                    .map(function (severity) {
                    return {
                        value: severity.id,
                        name: _this.i18nService.get(severity.nameI18nKey),
                        count: null,
                        priority: severity.priority,
                    };
                }),
            },
            serviceSource: {
                count: null,
                values: this.sourceFilterService.getServiceSourceTreeFilterValues(),
            },
            AlertStatus: {
                count: null,
                values: AlertStatus.entityConfig.values
                    .filter(function (status) { return status.isSelectable; })
                    .map(function (status) {
                    return {
                        value: status.type,
                        name: _this.i18nService.get(status.nameI18nKey),
                        count: null,
                        priority: status.priority,
                    };
                }),
            },
            classification: {
                count: null,
                values: AlertClassification.entityConfig.values
                    .filter(function (value) { return value.id != AlertClassificationId.BenignPositive; })
                    .map(function (classification) {
                    return {
                        value: classification.type,
                        name: _this.i18nService.get(classification.nameI18nKey),
                        count: null,
                    };
                }),
            },
            osPlatform: {
                count: null,
                values: values(this.machinesFiltersService.osPlatformCategoriesMap).map(function (osPlatform) {
                    return {
                        value: osPlatform.id,
                        count: null,
                    };
                }),
            },
            investigationStates: {
                count: null,
                values: InvestigationStatus.entityConfig.values
                    .filter(function (status) { return status.id >= 0; })
                    .filter(function (status) {
                    return _this.featuresService.isEnabled(Feature.AirsApiOffloading)
                        ? !isNil(status.sevilleStatusId)
                        : !status.isTempOffloadingStatus;
                })
                    .map(function (status) {
                    return {
                        value: status.id.toString(),
                        name: status.name,
                        count: null,
                        priority: status.priority,
                    };
                }),
            },
            incidentAssignment: {
                count: null,
                values: Object.keys(IncidentAssignmentFilterValues).map(function (incidentAssignmentKey) { return ({
                    value: incidentAssignmentKey,
                    name: _this.i18nService.get(IncidentAssignmentFilterValues[incidentAssignmentKey].nameI18nKey),
                    count: null,
                }); }),
            },
        };
        var tagsDynamicFilters$ = this.paris
            .apiCall(GetIncidentQueueFiltersApiCall, DYNAMIC_FILTERS_SELECTION)
            .pipe(map(function (incidentQueueFilters) {
            var systemTag = incidentQueueFilters.systemTag, customTag = incidentQueueFilters.customTag;
            return {
                tags: {
                    count: null,
                    values: _this.entityTagsService.getIncidentsQueueTagsFilterValues(systemTag.values.concat(customTag.values)),
                },
            };
        }), catchError(function (err) {
            _this.appInsightsService.trackException(err);
            return of(null);
        }));
        var associatedThreatFilter$ = this.convertToFilter({
            observableOrApiCall: this.paris.getRepository(Outbreak).allItems$.pipe(map(function (outbreaks) {
                return outbreaks
                    .filter(function (outbreak) { return outbreak.ioaIds && outbreak.ioaIds.length > 0; })
                    .map(function (outbreak) {
                    return {
                        value: outbreak.id,
                        name: outbreak.displayName,
                        count: null,
                    };
                });
            })),
            filterName: ASSOCIATED_THREATS_FILTER_NAME,
            feature: Feature.ThreatAnalytics2,
        });
        var isMtp = this.appContextService.isMtp;
        var hasUpdatedIncidentQueue = this.featuresService.isEnabled(Feature.UpdatedIncidentQueue);
        var hasMtpFeatures = isMtp || hasUpdatedIncidentQueue;
        if (hasMtpFeatures) {
            filtersConfig.category = {
                count: null,
                values: AlertCategory.entityConfig.values.map(function (category) {
                    return {
                        value: category.type,
                        name: splitToSentenceCase(category.name),
                        count: null,
                    };
                }),
            };
        }
        if (hasUpdatedIncidentQueue && !isMtp) {
            // detection source filters is based on UpdatedIncidentQueue feature flag or on unifiedIncidentAlertQueue
            filtersConfig.detectionSource = {
                count: null,
                values: this.sourceFilterService.getDetectionSourcesFilterValues(),
            };
        }
        var filters$ = of(filtersConfig);
        var actorNamesFilter$ = hasMtpFeatures
            ? this.convertToFilter({
                observableOrApiCall: GetActorsApiCall,
                filterName: 'actorName',
            })
            : of(null);
        var sensitivityFilter$ = this.convertToFilter({
            observableOrApiCall: IncidentSensitivityFilterValues,
            filterName: 'sensitivity',
            feature: Feature.IncidentSensitivity,
            valuesMap: function (sensitivityLabels) { return sensitivityLabels.map(function (s) { return s.label; }); },
        });
        var userExposedRbacGroupsFilter$ = this.convertToFilter({
            observableOrApiCall: this.machinesFiltersService.getUserExposedRbacGroups(),
            filterName: 'rbacGroup',
            feature: Feature.IncidentSensitivity,
        });
        var legacyIncidentTags$ = hasMtpFeatures && !this.featuresService.isEnabled(Feature.K8SMigrationIncidentQueue)
            ? this.convertToFilter({
                observableOrApiCall: this.incidentsBackendService
                    .getIncidentTags()
                    .pipe(map(function (data) {
                    return values(data.UserDefinedTags).map(function (tag) {
                        return new Tag({ id: tag, name: tag, type: TagType.user, value: tag });
                    });
                })),
                filterName: 'incidentTags',
            })
            : of(null);
        return combineLatest([
            filters$,
            tagsDynamicFilters$,
            legacyIncidentTags$,
            sensitivityFilter$,
            actorNamesFilter$,
            userExposedRbacGroupsFilter$,
            associatedThreatFilter$,
        ]).pipe(map(function (_a) {
            var filters = _a[0], dynamicFilters = _a[1], legacyIncidentTagFilters = _a[2], sensitivityFilter = _a[3], actorNamesFilter = _a[4], userExposedRbacGroupsFilter = _a[5], associatedThreatFilter = _a[6];
            return Object.assign({}, filters, dynamicFilters, legacyIncidentTagFilters, sensitivityFilter, userExposedRbacGroupsFilter, actorNamesFilter, associatedThreatFilter);
        }), tap(function (incidentFilters) { return (_this._incidentFilters = incidentFilters); }));
    };
    IncidentsFiltersService.prototype.convertToFilter = function (config) {
        var _this = this;
        var observable = typeof config.observableOrApiCall === 'function'
            ? this.paris.apiCall(config.observableOrApiCall)
            : config.observableOrApiCall;
        return !config.feature || this.featuresService.isEnabled(config.feature)
            ? observable.pipe(map(function (result) {
                var _a;
                return result && result.length
                    ? (_a = {},
                        _a[config.filterName] = {
                            count: null,
                            values: config.valuesMap ? config.valuesMap(result) : result,
                        },
                        _a) : null;
            }), catchError(function (err) {
                if (err.status !== 404) {
                    _this.appInsightsService.trackException(err);
                }
                return of(null);
            }))
            : of(null);
    };
    return IncidentsFiltersService;
}());
export { IncidentsFiltersService };
