import { EntityRelationship, RelationshipType, EntityRelationshipRepositoryType } from '@microsoft/paris';
import { NetworkScanSession } from './network-scan-session.entity';
import { NetworkScanResult } from './network-scan-result.entity';
import { TvmNetworkScanSharedEntityConfigurations } from '@wcd/domain';
import { NetworkScanResultCsv } from './network-scan-result-csv.entity';

@EntityRelationship({
	...TvmNetworkScanSharedEntityConfigurations,
	sourceEntity: NetworkScanSession,
	dataEntity: NetworkScanResult,
	endpoint: (_, query) =>
		`commands/liveResults?sessionId=${encodeURIComponent(query.where['id'])}&fromTimestamp=0`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (NetworkScanSession: NetworkScanSession) => {
		return { id: NetworkScanSession.id };
	},
})
export class NetworkScanSessionRelationship
	implements EntityRelationshipRepositoryType<NetworkScanSession, NetworkScanResult> {}

@EntityRelationship({
	...TvmNetworkScanSharedEntityConfigurations,
	sourceEntity: NetworkScanSession,
	dataEntity: NetworkScanResultCsv,
	endpoint: (_, query) =>
		`commands/liveResultsCsv?sessionId=${encodeURIComponent(query.where['id'])}&fromTimestamp=0`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (NetworkScanSession: NetworkScanSession) => {
		return { id: NetworkScanSession.id };
	},
})
export class NetworkScanSessionCsvRelationship
	implements EntityRelationshipRepositoryType<NetworkScanSession, NetworkScanResultCsv> {}
