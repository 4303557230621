import { Injectable } from '@angular/core';
import { EntityType, EntityDataViewOptions } from '../../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../../global_entities/models/entity-type-service.interface';
import { NetworkScanResult } from '@wcd/domain';

@Injectable()
export class NetworkScanResultEntityTypeService implements EntityTypeService<NetworkScanResult> {
	readonly entityType: EntityType<NetworkScanResult> = {
		id: 'network-scanV2',
		entity: NetworkScanResult,
		getEntityName: (networkScan: NetworkScanResult) => networkScan.ip,
		loadFullEntityInPanel: false,
		dataViewOptions: <EntityDataViewOptions<NetworkScanResult, {}>>{
			defaultSortFieldId: 'ip',
		},
	};
}
