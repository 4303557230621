var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
var ɵ0 = function (fieldData) { return parseInt(fieldData); }, ɵ1 = function (fieldData) { return parseInt(fieldData); };
var EntityStatistics = /** @class */ (function (_super) {
    __extends(EntityStatistics, _super);
    function EntityStatistics() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'OrgPrevalence', parse: ɵ0 }),
        __metadata("design:type", Number)
    ], EntityStatistics.prototype, "organizationPrevalence", void 0);
    __decorate([
        EntityField({ data: 'OrgFirstSeen' }),
        __metadata("design:type", Date)
    ], EntityStatistics.prototype, "organizationFirstSeen", void 0);
    __decorate([
        EntityField({ data: 'OrgLastSeen' }),
        __metadata("design:type", Date)
    ], EntityStatistics.prototype, "organizationLastSeen", void 0);
    __decorate([
        EntityField({ data: 'WorldwidePrevalence', parse: ɵ1 }),
        __metadata("design:type", Number)
    ], EntityStatistics.prototype, "worldwidePrevalence", void 0);
    __decorate([
        EntityField({ data: 'WorldwideFirstSeen' }),
        __metadata("design:type", Date)
    ], EntityStatistics.prototype, "worldwideFirstSeen", void 0);
    __decorate([
        EntityField({ data: 'WorldwideLastSeen' }),
        __metadata("design:type", Date)
    ], EntityStatistics.prototype, "worldwideLastSeen", void 0);
    __decorate([
        EntityField({ data: 'OrgLastSeenDeviceName' }),
        __metadata("design:type", String)
    ], EntityStatistics.prototype, "orgLastSeenDeviceName", void 0);
    __decorate([
        EntityField({ data: 'OrgLastSeenMachineId' }),
        __metadata("design:type", String)
    ], EntityStatistics.prototype, "orgLastSeenMachineId", void 0);
    EntityStatistics = __decorate([
        ValueObject({
            singularName: 'Entity statistics',
            pluralName: 'Entity statistics',
        })
    ], EntityStatistics);
    return EntityStatistics;
}(ModelBase));
export { EntityStatistics };
export { ɵ0, ɵ1 };
