var _a;
import { Incident, TagType } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { compact } from 'lodash';
import { FilterValuesChecklistComponent, } from '@wcd/ng-filters';
import { CsvUtils } from '@wcd/shared';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
var RANSOMWARE_TAG = 'ransomware_tag';
var SPOOFING_TAG = 'spoofing_tag';
var CED_TAG = 'ced_tag'; // Chain event detection
var MTE_TAG = 'mte_tag'; // MS Threat experts
var HVE_TAG = 'hve_tag'; // Priority account
var PREDEFINED_TAG_KEYS = [CED_TAG, MTE_TAG, HVE_TAG, RANSOMWARE_TAG, SPOOFING_TAG];
var PREDEFINED_TAGS_PRIORITIES = (_a = {},
    _a[RANSOMWARE_TAG] = 10,
    _a[SPOOFING_TAG] = 20,
    _a[HVE_TAG] = 30,
    _a[MTE_TAG] = 40,
    _a[CED_TAG] = 50,
    _a);
var EntityTagsService = /** @class */ (function () {
    function EntityTagsService(appInsightService, paris, i18nService) {
        this.appInsightService = appInsightService;
        this.paris = paris;
        this.i18nService = i18nService;
        this._builtInTags = new Map();
        this._incidentsQueueLocalizedTags = new Map();
    }
    EntityTagsService.prototype.getEntityTagsFieldProps = function (entity) {
        return {
            tags: entity instanceof Incident ? this.getIncidentTags(entity) : this.getAlertTags(entity),
            autoOverflow: true,
        };
    };
    EntityTagsService.prototype.getIncidentTags = function (incident) {
        return incident.incidentTags ? this.getEntityTags(incident.incidentTags) : [];
    };
    EntityTagsService.prototype.getAlertTags = function (alert) {
        return alert.alertTags ? this.getEntityTags(alert.alertTags) : [];
    };
    EntityTagsService.prototype.getEntityTags = function (_a) {
        var _this = this;
        var _b = _a.incidentUserTags, incidentUserTags = _b === void 0 ? [] : _b, actorNames = _a.actorNames, deviceTags = _a.deviceTags, userTags = _a.userTags, isRansomware = _a.isRansomware, isTelemetrySpoofing = _a.isTelemetrySpoofing, isThreatExpert = _a.isThreatExpert, isOfficeHVE = _a.isOfficeHVE, isChainEventDetection = _a.isChainEventDetection;
        var actors = Array.isArray(actorNames) ? actorNames : (actorNames && [actorNames]) || [];
        // The order of the tags matters
        return compact([
            isRansomware && this.getRansomwareTag(),
            isTelemetrySpoofing && this.getTelemetrySpoofingTag()
        ].concat(actors.map(function (tag) { return _this.createTagWithLabelAndType(tag, TagType.actor); }), [
            isOfficeHVE && this.getHveTag(),
            isThreatExpert && this.getMteTag(),
            isChainEventDetection && this.getChainEventDetectionTag()
        ], incidentUserTags.map(function (tag) { return _this.createTagWithLabelAndType(tag, TagType.user); }), deviceTags.map(function (tag) { return _this.createTagWithLabelAndType(tag, TagType.system); }), userTags.map(function (tag) { return _this.createTagWithLabelAndType(tag, TagType.system); })));
    };
    EntityTagsService.prototype.getIncidentQueueTagsFilterConfig = function (priority) {
        var _this = this;
        return {
            priority: priority,
            component: {
                config: {
                    allowSingleValueDeselection: true,
                    allowUnknownValues: false,
                },
                type: FilterValuesChecklistComponent,
            },
            serializeFilterValues: function (filterSelection) {
                return filterSelection && filterSelection.length > 0
                    ? {
                        tag: filterSelection
                            .map(function (filterValue) {
                            if (_this._incidentsQueueLocalizedTags.get(filterValue) &&
                                _this._incidentsQueueLocalizedTags.get(filterValue) !== filterValue) {
                                // Add the localized value as well to the filter query
                                var localizedFilterValue = CsvUtils.encodeCsv(_this._incidentsQueueLocalizedTags.get(filterValue));
                                return localizedFilterValue + "," + CsvUtils.encodeCsv(filterValue);
                            }
                            return CsvUtils.encodeCsv(filterValue);
                        })
                            .join(','),
                    }
                    : null;
            },
            deserializeFilterValues: function (serializedValues) {
                return serializedValues.tag && CsvUtils.decodeCsv(serializedValues.tag[0]);
            },
        };
    };
    EntityTagsService.prototype.getIncidentsQueueTagsFilterValues = function (tags) {
        var _this = this;
        var existingTags = new Set();
        return tags
            .map(function (tag) {
            if (PREDEFINED_TAG_KEYS.includes(tag.key)) {
                var tagLabel = _this.i18nService.strings["incident_tags_" + tag.key];
                existingTags.add(tagLabel);
                _this._incidentsQueueLocalizedTags.set(tag.key, tagLabel);
                return {
                    value: tag.key,
                    name: tagLabel,
                    priority: PREDEFINED_TAGS_PRIORITIES[tag.key],
                    count: null,
                };
            }
            else if (!existingTags.has(tag.key)) {
                // could be a user defined a tag like "Bedrohungsexperten" === this.i18nService.strings[`incident_tags_mte_tag`], need to dedup
                existingTags.add(tag.key);
                _this._incidentsQueueLocalizedTags.set(tag.key, tag.key);
                return {
                    value: tag.key,
                    name: tag.key,
                    priority: 0,
                    count: null,
                };
            }
        })
            .filter(Boolean);
    };
    EntityTagsService.prototype.getIncidentSensitivityTagFieldProps = function (entity) {
        return {
            tags: entity instanceof Incident ? [entity.sensitivity] : [],
        };
    };
    EntityTagsService.prototype.createTagWithLabelAndType = function (label, tagType) {
        return {
            id: label,
            name: label,
            ariaLabel: label,
            type: tagType,
        };
    };
    EntityTagsService.prototype.getRansomwareTag = function () {
        if (this._builtInTags.has(RANSOMWARE_TAG))
            return this._builtInTags.get(RANSOMWARE_TAG);
        var name = this.i18nService.strings.incident_tags_ransomware_tag;
        var ransomwareTag = {
            id: RANSOMWARE_TAG,
            name: name,
            type: TagType.ransomware,
            ariaLabel: name,
        };
        this._builtInTags.set(RANSOMWARE_TAG, ransomwareTag);
        return ransomwareTag;
    };
    EntityTagsService.prototype.getMteTag = function () {
        if (this._builtInTags.has(MTE_TAG))
            return this._builtInTags.get(MTE_TAG);
        var name = this.i18nService.strings.incident_tags_mte_tag;
        var mteTag = {
            id: MTE_TAG,
            name: name,
            type: TagType.threatExpert,
            ariaLabel: name,
        };
        this._builtInTags.set(MTE_TAG, mteTag);
        return mteTag;
    };
    // AKA Priority Account
    EntityTagsService.prototype.getHveTag = function () {
        if (this._builtInTags.has(HVE_TAG))
            return this._builtInTags.get(HVE_TAG);
        var name = this.i18nService.strings.incident_tags_hve_tag;
        var hveTag = {
            id: HVE_TAG,
            name: name,
            type: TagType.highValueAsset,
            ariaLabel: name,
        };
        this._builtInTags.set(HVE_TAG, hveTag);
        return hveTag;
    };
    EntityTagsService.prototype.getTelemetrySpoofingTag = function () {
        if (this._builtInTags.has(SPOOFING_TAG))
            return this._builtInTags.get(SPOOFING_TAG);
        var name = this.i18nService.strings.incident_tags_spoofing_tag;
        var spoofingTag = {
            id: SPOOFING_TAG,
            name: name,
            type: TagType.telemetrySpoofing,
            ariaLabel: name,
        };
        this._builtInTags.set(SPOOFING_TAG, spoofingTag);
        return spoofingTag;
    };
    // AKA Alert to Event
    EntityTagsService.prototype.getChainEventDetectionTag = function () {
        if (this._builtInTags.has(CED_TAG))
            return this._builtInTags.get(CED_TAG);
        var name = this.i18nService.strings.incident_tags_ced_tag;
        var cedTag = {
            id: CED_TAG,
            name: name,
            type: TagType.alertToEvent,
            ariaLabel: name,
        };
        this._builtInTags.set(CED_TAG, cedTag);
        return cedTag;
    };
    return EntityTagsService;
}());
export { EntityTagsService };
