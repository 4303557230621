import { DataviewField } from '@wcd/dataview';
import { I18nService } from '@wcd/i18n';
var AssessmentJobFieldsService = /** @class */ (function () {
    function AssessmentJobFieldsService(i18nService) {
        this.i18nService = i18nService;
    }
    Object.defineProperty(AssessmentJobFieldsService.prototype, "fields", {
        get: function () {
            var _this = this;
            if (!this._fields) {
                this._fields = DataviewField.fromList([
                    {
                        id: 'scanName',
                        name: this.i18nService.get('tvm.networkScan.jobName'),
                        sort: {
                            enabled: false,
                        },
                    },
                    {
                        id: 'agentName',
                        name: this.i18nService.get('tvm.networkGear.creationPanel.scanner'),
                        sort: {
                            enabled: false,
                        },
                    },
                    {
                        id: 'originalTargetRanges',
                        name: this.i18nService.get('tvm.networkScan.target'),
                        sort: {
                            enabled: false,
                        },
                    },
                    {
                        id: 'createdBy',
                        name: this.i18nService.get('tvm.common.createdBy'),
                        sort: {
                            enabled: false,
                        },
                    },
                    {
                        id: 'active',
                        name: this.i18nService.get('tvm.networkScan.status'),
                        getDisplay: function (assessmentJob) {
                            return assessmentJob.active
                                ? _this.i18nService.get('tvm.networkGear.dataview.jobStatus.active')
                                : _this.i18nService.get('tvm.networkGear.dataview.jobStatus.inactive');
                        },
                        sort: {
                            enabled: false,
                        },
                    },
                    {
                        id: 'lastScanStatus',
                        name: this.i18nService.get('tvm.networkScan.lastRun.status'),
                        sort: {
                            enabled: false,
                        },
                    },
                    {
                        id: 'lastScanDate',
                        name: this.i18nService.get('tvm.networkScan.lastRun.date'),
                        sort: {
                            enabled: false,
                        },
                    },
                ]);
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    return AssessmentJobFieldsService;
}());
export { AssessmentJobFieldsService };
