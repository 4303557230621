/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./machine.header-bottom.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i4 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i7 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i8 from "./machine.header-bottom.component";
import * as i9 from "../../services/global-entity-types.service";
var styles_MachineHeaderBottomComponent = [i0.styles];
var RenderType_MachineHeaderBottomComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MachineHeaderBottomComponent, data: {} });
export { RenderType_MachineHeaderBottomComponent as RenderType_MachineHeaderBottomComponent };
function View_MachineHeaderBottomComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "wcd-flex-horizontal wcd-padding-medium-right machine-risk"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "span", [["class", "wcd-severity"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(7, null, ["\n\t\t\t\t\t\t\t", "\n\t\t\t\t\t\t"])), i1.ɵppd(8, 1), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "wcd-severity"; var currVal_1 = ("wcd-severity-" + _co.entity.riskScore.id.toLocaleLowerCase()); _ck(_v, 6, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), ("machineRiskScore." + _co.entity.riskScore.id))); _ck(_v, 7, 0, currVal_2); }); }
function View_MachineHeaderBottomComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "machine-status-active"]], [[1, "aria-label", 0]], [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 147456, null, 0, i3.TooltipDirective, [i1.ElementRef, i4.TooltipsService, i5.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "machine-status"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.statusDescription; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.statusDescription; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.statusDescription; _ck(_v, 6, 0, currVal_2); }); }
export function View_MachineHeaderBottomComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.I18nPipe, [i7.I18nService]), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "relative wcd-flex-vertical"]], [[1, "aria-label", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "wcd-flex-horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MachineHeaderBottomComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MachineHeaderBottomComponent_2)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.entity.senseMachineId && _co.entity.riskScore); _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.isActive && _co.hasStatus); _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.description; _ck(_v, 2, 0, currVal_0); }); }
export function View_MachineHeaderBottomComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_MachineHeaderBottomComponent_0, RenderType_MachineHeaderBottomComponent)), i1.ɵdid(1, 114688, null, 0, i8.MachineHeaderBottomComponent, [i9.GlobalEntityTypesService, i7.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MachineHeaderBottomComponentNgFactory = i1.ɵccf("ng-component", i8.MachineHeaderBottomComponent, View_MachineHeaderBottomComponent_Host_0, { entity: "entity", entityType: "entityType" }, {}, []);
export { MachineHeaderBottomComponentNgFactory as MachineHeaderBottomComponentNgFactory };
