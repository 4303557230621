import { Injectable } from '@angular/core';
import { Alert, Incident, FilterItemValue, IncidentTags, Tag, TagType } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { compact } from 'lodash';
import {
	FiltersFieldConfig,
	FilterValuesChecklistComponent,
	FilterValuesChecklistValueData,
} from '@wcd/ng-filters';
import { CsvUtils } from '@wcd/shared';
import { AppInsightsService } from '../../../insights/services/app-insights.service';

const RANSOMWARE_TAG = 'ransomware_tag';
const SPOOFING_TAG = 'spoofing_tag';
const CED_TAG = 'ced_tag'; // Chain event detection
const MTE_TAG = 'mte_tag'; // MS Threat experts
const HVE_TAG = 'hve_tag'; // Priority account
const PREDEFINED_TAG_KEYS = [CED_TAG, MTE_TAG, HVE_TAG, RANSOMWARE_TAG, SPOOFING_TAG];
const PREDEFINED_TAGS_PRIORITIES = {
	[RANSOMWARE_TAG]: 10,
	[SPOOFING_TAG]: 20,
	[HVE_TAG]: 30,
	[MTE_TAG]: 40,
	[CED_TAG]: 50,
};

@Injectable()
export class EntityTagsService {
	_builtInTags = new Map<string, Tag>();
	_incidentsQueueLocalizedTags = new Map<string, string>();

	constructor(
		private readonly appInsightService: AppInsightsService,
		private readonly paris: Paris,
		private readonly i18nService: I18nService
	) {}

	getEntityTagsFieldProps(entity: Incident | Alert) {
		return {
			tags: entity instanceof Incident ? this.getIncidentTags(entity) : this.getAlertTags(entity),
			autoOverflow: true,
		};
	}

	getIncidentTags(incident: Incident): Tag[] {
		return incident.incidentTags ? this.getEntityTags(incident.incidentTags) : [];
	}

	private getAlertTags(alert: Alert): Tag[] {
		return alert.alertTags ? this.getEntityTags(alert.alertTags) : [];
	}

	private getEntityTags({
		incidentUserTags = [],
		actorNames,
		deviceTags,
		userTags,
		isRansomware,
		isTelemetrySpoofing,
		isThreatExpert,
		isOfficeHVE,
		isChainEventDetection,
	}: IncidentTags) {
		const actors = Array.isArray(actorNames) ? actorNames : (actorNames && [actorNames]) || [];

		// The order of the tags matters
		return compact([
			isRansomware && this.getRansomwareTag(),
			isTelemetrySpoofing && this.getTelemetrySpoofingTag(),
			...actors.map((tag) => this.createTagWithLabelAndType(tag, TagType.actor)),
			isOfficeHVE && this.getHveTag(),
			isThreatExpert && this.getMteTag(),
			isChainEventDetection && this.getChainEventDetectionTag(),
			...incidentUserTags.map((tag) => this.createTagWithLabelAndType(tag, TagType.user)),
			...deviceTags.map((tag) => this.createTagWithLabelAndType(tag, TagType.system)),
			...userTags.map((tag) => this.createTagWithLabelAndType(tag, TagType.system)),
		]);
	}

	getIncidentQueueTagsFilterConfig(
		priority: number
	): FiltersFieldConfig<any, string[], any, FilterItemValue> {
		return {
			priority,
			component: {
				config: {
					allowSingleValueDeselection: true,
					allowUnknownValues: false,
				},
				type: FilterValuesChecklistComponent,
			},
			serializeFilterValues: (filterSelection: string[]) => {
				return filterSelection && filterSelection.length > 0
					? {
							tag: filterSelection
								.map((filterValue) => {
									if (
										this._incidentsQueueLocalizedTags.get(filterValue) &&
										this._incidentsQueueLocalizedTags.get(filterValue) !== filterValue
									) {
										// Add the localized value as well to the filter query
										const localizedFilterValue = CsvUtils.encodeCsv(
											this._incidentsQueueLocalizedTags.get(filterValue)
										);
										return `${localizedFilterValue},${CsvUtils.encodeCsv(filterValue)}`;
									}
									return CsvUtils.encodeCsv(filterValue);
								})
								.join(','),
					  }
					: null;
			},
			deserializeFilterValues: (serializedValues: { tag?: string[] }) => {
				return serializedValues.tag && CsvUtils.decodeCsv(serializedValues.tag[0]);
			},
		};
	}

	getIncidentsQueueTagsFilterValues(
		tags: Array<FilterItemValue>
	): FilterValuesChecklistValueData<string>[] {
		const existingTags = new Set<string>();
		return tags
			.map((tag: FilterItemValue) => {
				if (PREDEFINED_TAG_KEYS.includes(tag.key)) {
					const tagLabel = this.i18nService.strings[`incident_tags_${tag.key}`];
					existingTags.add(tagLabel);
					this._incidentsQueueLocalizedTags.set(tag.key, tagLabel);
					return {
						value: tag.key,
						name: tagLabel,
						priority: PREDEFINED_TAGS_PRIORITIES[tag.key],
						count: null,
					};
				} else if (!existingTags.has(tag.key)) {
					// could be a user defined a tag like "Bedrohungsexperten" === this.i18nService.strings[`incident_tags_mte_tag`], need to dedup
					existingTags.add(tag.key);
					this._incidentsQueueLocalizedTags.set(tag.key, tag.key);
					return {
						value: tag.key,
						name: tag.key,
						priority: 0,
						count: null,
					};
				}
			})
			.filter(Boolean);
	}

	getIncidentSensitivityTagFieldProps(entity: Incident | Alert) {
		return {
			tags: entity instanceof Incident ? [entity.sensitivity] : [],
		};
	}

	private createTagWithLabelAndType(label: string, tagType: TagType) {
		return {
			id: label,
			name: label,
			ariaLabel: label,
			type: tagType,
		};
	}

	private getRansomwareTag() {
		if (this._builtInTags.has(RANSOMWARE_TAG)) return this._builtInTags.get(RANSOMWARE_TAG);
		const name = this.i18nService.strings.incident_tags_ransomware_tag;
		const ransomwareTag = {
			id: RANSOMWARE_TAG,
			name,
			type: TagType.ransomware,
			ariaLabel: name,
		};
		this._builtInTags.set(RANSOMWARE_TAG, ransomwareTag);
		return ransomwareTag;
	}

	private getMteTag() {
		if (this._builtInTags.has(MTE_TAG)) return this._builtInTags.get(MTE_TAG);
		const name = this.i18nService.strings.incident_tags_mte_tag;
		const mteTag = {
			id: MTE_TAG,
			name,
			type: TagType.threatExpert,
			ariaLabel: name,
		};
		this._builtInTags.set(MTE_TAG, mteTag);
		return mteTag;
	}

	// AKA Priority Account
	private getHveTag() {
		if (this._builtInTags.has(HVE_TAG)) return this._builtInTags.get(HVE_TAG);
		const name = this.i18nService.strings.incident_tags_hve_tag;
		const hveTag = {
			id: HVE_TAG,
			name,
			type: TagType.highValueAsset,
			ariaLabel: name,
		};
		this._builtInTags.set(HVE_TAG, hveTag);
		return hveTag;
	}

	private getTelemetrySpoofingTag() {
		if (this._builtInTags.has(SPOOFING_TAG)) return this._builtInTags.get(SPOOFING_TAG);
		const name = this.i18nService.strings.incident_tags_spoofing_tag;
		const spoofingTag = {
			id: SPOOFING_TAG,
			name,
			type: TagType.telemetrySpoofing,
			ariaLabel: name,
		};
		this._builtInTags.set(SPOOFING_TAG, spoofingTag);
		return spoofingTag;
	}

	// AKA Alert to Event
	private getChainEventDetectionTag() {
		if (this._builtInTags.has(CED_TAG)) return this._builtInTags.get(CED_TAG);
		const name = this.i18nService.strings.incident_tags_ced_tag;
		const cedTag = {
			id: CED_TAG,
			name,
			type: TagType.alertToEvent,
			ariaLabel: name,
		};
		this._builtInTags.set(CED_TAG, cedTag);
		return cedTag;
	}
}
