var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { ODataFilterQueryBuilder } from './oDataFilterQueryBuilder';
export var TvmEndPoint;
(function (TvmEndPoint) {
    TvmEndPoint["Analytics"] = "analytics";
    TvmEndPoint["RemediationTasks"] = "remediation-tasks";
    TvmEndPoint["ItsmProvisioning"] = "itsm";
    TvmEndPoint["NetworkScans"] = "networkscan";
    TvmEndPoint["OrgSettings"] = "orgsettings";
    TvmEndPoint["Manualcontentmanagement"] = "mcm";
    TvmEndPoint["Feedback"] = "feedback";
})(TvmEndPoint || (TvmEndPoint = {}));
export var ApiVersionHeaderName = 'api-version';
export var DefaultApiVersion = (_a = {}, _a[ApiVersionHeaderName] = '1.0', _a);
export var Cache = {
    time: 1000 * 60 * 5,
    max: 100,
};
export var AddMachineGroupsFilterQueryParam = function (queryParams) {
    var isMachinesPath = window.location.pathname.startsWith("/machines");
    //TODO: usage of the window object as a temporary bridge between TvmMachineGroupsFilterService and the domain (to be used by Entity's "parseDataQuery" to send the groups via query param). fix by extending Paris to pass ParisConfig.data as an arg to parseDataQuery => then we can assign this service instance to the data and use it on parseDataQuery.
    var tvm_machineGroupsFilterData = window._tvm_machineGroupsFilterData;
    if (tvm_machineGroupsFilterData && tvm_machineGroupsFilterData.isFiltering && !isMachinesPath) {
        var groupIds = tvm_machineGroupsFilterData.machineGroups
            .filter(function (mg) { return mg.isSelected; })
            .map(function (mg) { return mg.groupId; })
            .join();
        queryParams['rbacGroups'] = "groups in (" + groupIds + ")";
    }
};
/**
 * resolves the URL for a BE api based on the provided Paris Entity/Relationship config (entityModelBaseOrRelationship),
 * 	e.g. results for Entity:
 * 		'products'
 * 	e.g. results for Relationship:
 * 		products/434/recommendations
 */
export var ResolveEntityURL = function (config) {
    return ResolveEntityURLAndQueryParams(config).url;
};
/**
 * resolves the URL for a BE api based on the provided Paris Entity/Relationship config (entityModelBaseOrRelationship),
 * 	e.g. results for Entity:
 * 		'products'
 * 	e.g. results for Relationship:
 * 		products/434/recommendations
 */
export var ResolveEntityURLAndQueryParams = function (config) {
    var apiConfig = config.entityModelBaseOrRelationship['entityConfig'] ||
        config.entityModelBaseOrRelationship['relationshipConfig'];
    var relationshipData = {};
    if (config.sourceModel) {
        relationshipData = apiConfig.getRelationshipData(config.sourceModel);
    }
    var dataQuery = {
        where: __assign({ id: config.id, extraQueryData: config.extraQueryData }, relationshipData),
    };
    var apiComponent = typeof apiConfig.endpoint === 'function' ? apiConfig.endpoint(null, dataQuery) : apiConfig.endpoint;
    return {
        url: config.endPoint + "/" + apiComponent,
        queryParams: apiConfig.parseDataQuery ? apiConfig.parseDataQuery(dataQuery) : {},
    };
};
export var AddODataFilterQueryParam = function (dataQuery, queryParams, fieldFiltersMap, context) {
    if (dataQuery.where && typeof dataQuery.where === 'object') {
        var filters_1 = [];
        Object.keys(dataQuery.where)
            .filter(function (field) { return dataQuery.where[field] != undefined; })
            .map(function (field) {
            if (fieldFiltersMap[field]) {
                if (fieldFiltersMap[field] === true) {
                    filters_1.push(oDataFilterFormatter(dataQuery.where[field], field));
                }
                else if (Array.isArray(fieldFiltersMap[field])) {
                    var predicatesArray = fieldFiltersMap[field];
                    var matchedPredicate = context
                        ? predicatesArray.find(function (val) { return val.context === context; })
                        : predicatesArray.find(function (predicate) { return !predicate.context; });
                    if (matchedPredicate.predicate === true) {
                        filters_1.push(oDataFilterFormatter(dataQuery.where[field], field));
                    }
                    else {
                        var callback = matchedPredicate
                            ? matchedPredicate.predicate
                            : predicatesArray[0].predicate;
                        var filterValue = callback(dataQuery.where[field]);
                        if (filterValue !== null)
                            filters_1.push(filterValue);
                    }
                }
                else {
                    var callback = fieldFiltersMap[field];
                    var filterValue = callback(dataQuery.where[field]);
                    if (filterValue !== null)
                        filters_1.push(filterValue);
                }
            }
        });
        if (filters_1.length) {
            queryParams['$filter'] = filters_1.map(function (f) { return '(' + f + ')'; }).join(' and ');
        }
    }
};
var oDataFilterFormatter = function (filterBy, field) {
    var filter = filterBy instanceof Array ? filterBy : [filterBy];
    var queryBuilder = new ODataFilterQueryBuilder('or');
    filter.forEach(function (val) { return (queryBuilder = queryBuilder.equals(field, val)); });
    return queryBuilder.toQuery();
};
var ɵ0 = oDataFilterFormatter;
export { ɵ0 };
