import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { SuppressionRuleConditionIocType } from './suppression-rule-condition-ioc-type.enum';
import { SuppressionRuleConditionIdType } from './suppression-rule-condition-id-type.enum';
import { SuppressionRuleType } from './suppression-rule-type.enum';

@Entity({
	singularName: 'Suppression Rule Condition Type',
	pluralName: 'Suppression Rule Condition Types',
	values: [
		{
			id: SuppressionRuleConditionIdType.FileName,
			name: 'File name',
			type: SuppressionRuleConditionIocType.File,
			dataFieldName: 'FileName',
			propertyName: 'name',
			allowEdit: true,
			allowWildCard: true,
			supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.FolderPath,
			name: 'Folder path',
			type: SuppressionRuleConditionIocType.File,
			dataFieldName: 'FolderPath',
			propertyName: 'path',
			allowEdit: true,
			allowWildCard: true,
			pattern: 'folderPath',
			patternErrorText: 'Folder path must end with \\, *, or /',
			supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.Sha1,
			name: 'SHA1',
			type: SuppressionRuleConditionIocType.File,
			dataFieldName: 'Sha1',
			propertyName: 'sha1',
			allowEdit: false,
			pattern: 'sha1',
			patternErrorText: 'Sha1 must be hexadecimal number 40 digits long',
			supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.Ip,
			name: 'IP',
			type: SuppressionRuleConditionIocType.Ip,
			dataFieldName: 'Ip',
			propertyName: 'name',
			allowEdit: true,
			allowWildCard: false,
			pattern: 'ip',
			patternErrorText: 'Ip address is not valid',
			supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.Url,
			name: 'URL',
			type: SuppressionRuleConditionIocType.Url,
			dataFieldName: 'Url',
			propertyName: 'name',
			allowEdit: true,
			allowWildCard: true,
			patternErrorText: 'Url address is not valid',
			supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.CommandLine,
			name: 'Command line',
			type: SuppressionRuleConditionIocType.File,
			dataFieldName: 'CommandLine',
			propertyName: 'command',
			allowEdit: true,
			allowWildCard: true,
			patternErrorText: 'Command line is not valid',
			supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.UserSid,
			name: 'User',
			type: SuppressionRuleConditionIocType.File,
			dataFieldName: 'UserSid',
			propertyName: 'userSid',
			allowEdit: true,
			allowWildCard: false,
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
	],
})
export class SuppressionRuleConditionType extends EntityModelBase<number> {
	@EntityField() name: string;
	@EntityField() type: SuppressionRuleConditionIocType;
	@EntityField() dataFieldName: string;
	@EntityField() propertyName: string;
	@EntityField({ defaultValue: true })
	allowEdit: boolean;
	@EntityField({ defaultValue: false })
	allowWildCard: boolean;
	@EntityField() pattern?: string;
	@EntityField() patternErrorText?: string;
	@EntityField() supportRuleTypes?: SuppressionRuleType[];
}
