import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@ApiCall({
	name: 'Run scheduled hunting rules',
	endpoint: 'hunting/rules/run',
	method: 'POST',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	parseQuery: (data: ScheduledHuntingRunApiCallConfig) => {
		return {
			data: data,
		};
	},
})
export class ScheduledHuntingRunApiCall extends ApiCallModel<ScheduledHuntingRunApiCallConfig> {}

export interface ScheduledHuntingRunApiCallConfig {
	ids: Array<number>;
}


@ApiCall({
	name: 'Run scheduled hunting rules',
	endpoint: 'rules/run',
	method: 'POST',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.huntingService,
	parseQuery: (data: ScheduledHuntingRunApiCallConfig) => {
		return {
			data: data,
		};
	},
})
export class ScheduledHuntingRunApiCallVNext extends ApiCallModel<ScheduledHuntingRunApiCallConfig> {}
