var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ComponentFactoryResolver, ComponentRef, OnDestroy } from '@angular/core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { cloneDeep, get } from 'lodash-es';
import { ActionHistory, EvaluationIncidents, EvaluationLab, EvaluationMachine, EvaluationMachineStatusType, EvaluationSimulation, EvaluationSimulationCatalog, EvaluationSimulatorAgent, EvaluationSimulatorStatusType, EvaluationStepType, GetEvaluationIncidentsApiCall, GetEvaluationInvestigationsApiCall, GetEvaluationStepsApiCall, Machine, PatchEvaluationStatusApiCall, PostEvaluationStatusApiCall, UpdateSimulatorsSettings, } from '@wcd/domain';
import { EvaluationWizardSteps } from '../models/evaluation.wizard.steps';
import { EvaluationWizardStepModel } from '../models/evaluation-wizard-step.model';
import { Paris } from '@microsoft/paris';
import { catchError, distinctUntilChanged, filter, finalize, map, mergeMap, switchMap, take, tap, } from 'rxjs/operators';
import { KnownColorsService } from '@wcd/shared';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { TrackingEventType } from '@wcd/telemetry';
import { EvaluationPasswordDialogComponent } from '../components/evaluation-password-dialog.component';
import { Feature, FeaturesService } from '@wcd/config';
import { PanelType } from '@wcd/panels';
import { EvaluationAddMachinePanelComponent } from '../components/evaluation-add-machine-panel.component';
import { EvaluationLabSetupPanelComponent } from '../components/evaluation-lab-setup-panel.component';
import { PollingService } from '@wcd/config';
import { EvaluationSetupPanelComponent } from '../components/setup-wizard/evaluation-setup-panel.component';
import { EvaluationCreateSimulationPanelComponent } from '../components/evaluation-create-simulation-panel.component';
import { EditSimulatorAgentsPanelComponent } from '../components/setup-wizard/edit-simulator-agents-panel.component';
import { SimulatorAgentsConsentFormComponent } from '../components/setup-wizard/simulator-agents-consent-form.component';
import { SimulationDescriptionPanelComponent } from '../components/simulation-description-panel.component';
var EVALUATION_MACHINE_TAG = 'evaluation';
var EVALUATION_MACHINE_USER_NAME = 'Administrator1';
var LOWEST_EXPOSURE_AND_RISK_SCORES_PRIORITY = 100;
var EvaluationService = /** @class */ (function () {
    function EvaluationService(paris, knownColorsService, appInsightsService, dialogsService, i18nService, featuresService, pollingService) {
        var _this = this;
        this.paris = paris;
        this.knownColorsService = knownColorsService;
        this.appInsightsService = appInsightsService;
        this.dialogsService = dialogsService;
        this.i18nService = i18nService;
        this.featuresService = featuresService;
        this.pollingService = pollingService;
        this.evaluationIncidentsSubject$ = new BehaviorSubject(new EvaluationIncidents());
        this.evaluationMachinesSubject$ = new BehaviorSubject(null);
        this.machinesMapSubject$ = new BehaviorSubject(null);
        this.evaluationSimulationsSubject$ = new BehaviorSubject(null);
        // public observables
        this.evaluationMachineAlerts$ = this.evaluationIncidentsSubject$.asObservable().pipe(distinctUntilChanged(), map(function (report) {
            return report ? report.machineAlertCounts : null;
        }));
        this.evalMachineToMachineMap$ = this.machinesMapSubject$.asObservable();
        this.evaluationMachines$ = this.evaluationMachinesSubject$.asObservable().pipe(filter(function (machines) { return !!machines; }));
        this.addMachineApiInProgress$ = new BehaviorSubject(false);
        this.evaluationLabCreated$ = new BehaviorSubject(false);
        this.labReady$ = new BehaviorSubject(false);
        this.labUpdated$ = new Subject();
        this.steps = EvaluationWizardSteps.map(function (step) {
            if (_this.featuresService.isEnabled(Feature.WindowsDefenderAtpEvaluationSummary)) {
                return new EvaluationWizardStepModel(step);
            }
            else {
                // Remove 'view report summary' step is summary feature is not enabled
                step.subSteps = step.subSteps.filter(function (subStep) { return subStep.name !== EvaluationStepType.EvaluationEndSummary; });
                return new EvaluationWizardStepModel(step);
            }
        }); // all wizard steps
        this.repository = paris.getRepository(EvaluationMachine);
        this.simulationsRepository = this.paris.getRepository(EvaluationSimulation);
        this.machineSubscription = this.pollingService
            .poll(100, 60000) // update risk and exposure scores every minute
            .pipe(switchMap(function () {
            return paris.getRepository(Machine).query({
                where: {
                    machineGroups: EVALUATION_MACHINE_TAG,
                    lookingBackInDays: 180,
                    machinesApiServiceMigration: _this.featuresService.isEnabled(Feature.MachinesApiServiceMigration),
                    useTvmMachinesAvStatus: _this.featuresService.isEnabled(Feature.UseTvmMachinesAvStatus)
                },
            });
        }), map(function (result) { return result.items; }), map(function (machines) {
            return machines.reduce(function (dict, obj) {
                var _a;
                return (__assign({}, dict, (_a = {}, _a[obj.id] = obj, _a)));
            }, {});
        }))
            .subscribe(this.machinesMapSubject$);
        this.getSimulationsCatalog().subscribe();
    }
    EvaluationService.prototype.ngOnDestroy = function () {
        this.machineSubscription && this.machineSubscription.unsubscribe();
    };
    // lab operations
    EvaluationService.prototype.getEvaluationLab = function () {
        var _this = this;
        if (this.lab) {
            return of(this.lab);
        }
        return this.paris.getItemById(EvaluationLab, 1).pipe(catchError(function (error) {
            if (error && error.status === 404) {
                return of(null);
            }
            throw error;
        }), tap(function (lab) { return (_this.lab = lab); }));
    };
    EvaluationService.prototype.createEvaluationLab = function (evaluationLab) {
        var _this = this;
        var repo = this.paris.getRepository(EvaluationLab);
        return repo
            .save(evaluationLab
            ? {
                maxNumberOfMachines: evaluationLab.maxNumberOfMachines,
                machineExpirationInHours: evaluationLab.machineExpirationInHours,
            }
            : repo.createNewItem())
            .pipe(tap(function (lab) {
            _this.lab = lab;
            _this.evaluationLabCreated$.next(true);
        }), switchMap(function () { return _this.getEvaluationSteps(); }), tap(function (steps) { return (_this.currentSteps = steps); }));
    };
    EvaluationService.prototype.notifyLabReady = function () {
        this.labReady$.next(true);
    };
    EvaluationService.prototype.setSimulatorsConfigurations = function (additionalData, safebreachEnabled, aiqEnabled) {
        var _this = this;
        return this.getEvaluationLab().pipe(map(function (lab) {
            var updateRequired = false;
            var simulatorsSettings = cloneDeep(get(_this, 'lab.simulatorsSettings', {}));
            if (safebreachEnabled && !simulatorsSettings.SafeBreach.isEnabled) {
                simulatorsSettings.SafeBreach = {
                    type: EvaluationSimulatorAgent.SafeBreach,
                    isEnabled: true,
                    additionalInfo: __assign({}, additionalData),
                };
                updateRequired = true;
            }
            if (aiqEnabled && !simulatorsSettings.AttackIQ.isEnabled) {
                simulatorsSettings.AttackIQ = {
                    type: EvaluationSimulatorAgent.AttackIQ,
                    isEnabled: true,
                    additionalInfo: __assign({}, additionalData),
                };
                updateRequired = true;
            }
            return { lab: lab, updateRequired: updateRequired, simulatorsSettings: simulatorsSettings };
        }), switchMap(function (_a) {
            var lab = _a.lab, updateRequired = _a.updateRequired, simulatorsSettings = _a.simulatorsSettings;
            if (!updateRequired) {
                return of(lab);
            }
            return _this.paris.apiCall(UpdateSimulatorsSettings, { simulatorsSettings: simulatorsSettings });
        }), tap(function (lab) {
            _this.lab = lab;
            _this.labUpdated$.next();
        }));
    };
    EvaluationService.prototype.showLabSetupPanel = function (componentFactoryResolver) {
        return this.dialogsService.showPanel(EvaluationLabSetupPanelComponent, {
            id: 'lab-setup',
            type: PanelType.large,
            noBodyPadding: false,
            persistOnNavigate: false,
            disableOverlayClick: true,
            showOverlay: true,
        }, {}, componentFactoryResolver);
    };
    EvaluationService.prototype.showLabSetupWizard = function (componentFactoryResolver) {
        return this.dialogsService.showPanel(EvaluationSetupPanelComponent, {
            id: 'lab-setup-wizard',
            type: PanelType.wizard,
            showOverlay: false,
            hasCloseButton: false,
            noBodyPadding: true,
            disableOverlayClick: true,
        }, {}, componentFactoryResolver);
    };
    EvaluationService.prototype.showEditSimulatorAgentsPanel = function (componentFactoryResolver) {
        return this.dialogsService.showPanel(EditSimulatorAgentsPanelComponent, {
            id: 'simulator-agent-setup-panel',
            type: PanelType.large,
            showOverlay: true,
            hasCloseButton: true,
        }, {}, componentFactoryResolver);
    };
    EvaluationService.prototype.showConsentFormPanel = function (componentFactoryResolver, consentType) {
        return this.dialogsService.showPanel(SimulatorAgentsConsentFormComponent, {
            id: 'simulator-agent-consent-form',
            type: PanelType.large,
            showOverlay: true,
            hasCloseButton: true,
        }, {
            consentType: consentType,
        }, componentFactoryResolver);
    };
    // machine operations
    EvaluationService.prototype.refreshEvaluationMachines = function (forceRefresh) {
        var _this = this;
        if (forceRefresh === void 0) { forceRefresh = false; }
        if (this.evaluationMachinesSubject$.value || forceRefresh) {
            // if there is already a value in the observable, update it and return the original observable
            return this.repository.allItems$.pipe(tap(function (machines) {
                _this.evaluationMachinesSubject$.next(machines);
                //machine(s) have been (just) created and setup is done, updating the UX steps
                //TODO: refactor a bit to avoid nested subscribe
                if (_this.evaluationMachinesSubject$.value.find(function (m) { return m.status.id === EvaluationMachineStatusType.Created; })) {
                    _this.getEvaluationSteps().subscribe();
                }
            }, function (error) {
                _this.appInsightsService.trackEvent('Evaluation', {
                    type: TrackingEventType.Action,
                    id: 'getEvaluationMachines',
                    error: error,
                });
            }), mergeMap(function () { return _this.evaluationMachinesSubject$.asObservable(); }));
        }
        else {
            return this.evaluationMachinesSubject$.asObservable();
        }
    };
    EvaluationService.prototype.getTotalMachines = function () {
        return this.evaluationMachinesSubject$ && this.evaluationMachinesSubject$.value
            ? this.evaluationMachinesSubject$.value.length
            : 0;
    };
    EvaluationService.prototype.getActiveMachines = function () {
        return this.filterAndSortMachines(this.machineHasNoStatus([
            EvaluationMachineStatusType.PendingCreation,
            EvaluationMachineStatusType.Creating,
            EvaluationMachineStatusType.ProvisionFailure,
            EvaluationMachineStatusType.PendingDeletion,
            EvaluationMachineStatusType.Deleting,
            EvaluationMachineStatusType.Deleted,
        ]));
    };
    EvaluationService.prototype.getSetupInProgressMachines = function () {
        return this.filterAndSortMachines(this.machineHasStatus([
            EvaluationMachineStatusType.PendingCreation,
            EvaluationMachineStatusType.Creating,
        ]));
    };
    EvaluationService.prototype.getProvisionedMachines = function () {
        return this.filterAndSortMachines(this.machineHasNoStatus(EvaluationMachineStatusType.ProvisionFailure));
    };
    EvaluationService.prototype.getMachineUserName = function () {
        return EVALUATION_MACHINE_USER_NAME;
    };
    EvaluationService.prototype.machineHasStatus = function (statuses) {
        if (!(statuses instanceof Array)) {
            statuses = [statuses];
        }
        return function (machine) { return statuses.includes(machine.status.type); };
    };
    EvaluationService.prototype.machineHasNoStatus = function (statuses) {
        if (!(statuses instanceof Array)) {
            statuses = [statuses];
        }
        return function (machine) { return !statuses.includes(machine.status.type); };
    };
    EvaluationService.prototype.filterAndSortMachines = function (filterFunc) {
        if (!this.evaluationMachinesSubject$ || !this.evaluationMachinesSubject$.value)
            return [];
        return this.evaluationMachinesSubject$.value
            .filter(function (m) { return filterFunc(m); })
            .sort(function (m1, m2) { return (m1.name > m2.name ? 1 : -1); });
    };
    EvaluationService.prototype.setEvaluationMachines = function (machines) {
        this.evaluationMachinesSubject$.next(machines);
    };
    EvaluationService.prototype.setAddMachineApiStatus = function (inProgress) {
        this.addMachineApiInProgress$.next(inProgress);
    };
    EvaluationService.prototype.addEvaluationMachine = function (componentFactoryResolver) {
        var _this = this;
        this.setAddMachineApiStatus(true);
        this.repository
            .save({ index: this.getTotalMachines() + 1 })
            .pipe(mergeMap(function (evaluationMachine) {
            return _this.showMachinePasswordDialog(evaluationMachine, componentFactoryResolver);
        }), finalize(function () { return _this.setAddMachineApiStatus(false); }), mergeMap(function () { return _this.refreshEvaluationMachines(); }))
            .subscribe(function () { }, function (error) {
            _this.dialogsService.showError({
                title: _this.i18nService.get('evaluation.dashboard.dataView.commandBar.addMachine.error'),
                data: error,
            });
        });
    };
    EvaluationService.prototype.addEvaluationMachineByOs = function (machineOs, machineIndex) {
        var _this = this;
        this.setAddMachineApiStatus(true);
        return this.repository
            .save({ index: (machineIndex || this.getTotalMachines()) + 1, machineOs: machineOs })
            .pipe(finalize(function () { return _this.setAddMachineApiStatus(false); }), tap(function () { return _this.refreshEvaluationMachines().subscribe(); }));
    };
    EvaluationService.prototype.showAddMachinePanel = function (componentFactoryResolver) {
        return this.dialogsService.showPanel(EvaluationAddMachinePanelComponent, {
            id: 'add-machine',
            type: PanelType.medium,
            noBodyPadding: true,
            persistOnNavigate: false,
            disableOverlayClick: true,
            showOverlay: true,
        }, {
            lab: this.lab,
            provisionedMachines: this.getProvisionedMachines().length,
            totalMachines: this.getTotalMachines(),
        }, componentFactoryResolver);
    };
    EvaluationService.prototype.showMachinePasswordDialog = function (evaluationPassword, componentFactoryResolver, showDisclaimer) {
        if (showDisclaimer === void 0) { showDisclaimer = true; }
        return this.dialogsService.showModal(EvaluationPasswordDialogComponent, {
            id: 'evaluation-password-dialog',
            title: this.i18nService.get('evaluation.dashboard.dataView.commandBar.addMachine.passwordModalTitle'),
            enableOverlay: false,
        }, {
            password: evaluationPassword.password,
            machineName: evaluationPassword.name,
            lab: showDisclaimer ? this.lab : null,
            provisionedMachines: showDisclaimer ? this.getProvisionedMachines().length : null,
        }, componentFactoryResolver);
    };
    // simulations operations
    EvaluationService.prototype.showCreateSimulationPanel = function (componentFactoryResolver, simulationId) {
        if (simulationId === void 0) { simulationId = null; }
        return this.dialogsService.showPanel(EvaluationCreateSimulationPanelComponent, {
            id: 'create-simulation',
            type: PanelType.medium,
            noBodyPadding: true,
            persistOnNavigate: false,
            disableOverlayClick: true,
            showOverlay: true,
        }, {
            simulationId: simulationId,
            machinesWithSimulatorsReady: this.getMachinesWithSimulatorsReady(),
        }, componentFactoryResolver);
    };
    EvaluationService.prototype.showSimulationDescriptionPanel = function (componentFactoryResolver, simulationId) {
        return this.dialogsService.showPanel(SimulationDescriptionPanelComponent, {
            id: 'simulation-learn-more',
            type: PanelType.large,
            noBodyPadding: true,
            persistOnNavigate: false,
            showOverlay: false,
            isBlocking: false,
        }, { simulationId: simulationId }, componentFactoryResolver);
    };
    EvaluationService.prototype.getMachinesWithSimulatorsReady = function () {
        return this.getActiveMachines().filter(function (m) {
            return ((m.simulatorsStatus.attackIQ &&
                m.simulatorsStatus.attackIQ.type === EvaluationSimulatorStatusType.Completed) ||
                (m.simulatorsStatus.safeBreach &&
                    m.simulatorsStatus.safeBreach.type === EvaluationSimulatorStatusType.Completed));
        });
    };
    EvaluationService.prototype.getAllSimulationsCatalog = function () {
        var _this = this;
        return this.paris.getRepository(EvaluationSimulationCatalog).allItems$.pipe(map(function (simulations) {
            return simulations.map(function (simulation) {
                simulation.name = _this.i18nService.get('evaluation.simulationCatalog.' +
                    simulation.simulator +
                    '.' +
                    simulation.simulationId +
                    '.name', null, true);
                simulation.description = _this.i18nService.get('evaluation.simulationCatalog.' +
                    simulation.simulator +
                    '.' +
                    simulation.simulationId +
                    '.description', null, true);
                return simulation;
            });
        }), map(function (simulations) {
            return simulations.filter(function (simulation) { return simulation.name !== '' && simulation.description !== ''; });
        }));
    };
    EvaluationService.prototype.getSimulationCatalogById = function (simulationId) {
        return this.getAllSimulationsCatalog().pipe(map(function (catalog) { return catalog.find(function (simulation) { return simulation.simulationId === simulationId; }); }));
    };
    EvaluationService.prototype.getSimulationsCatalog = function () {
        return this.getAllSimulationsCatalog().pipe(map(function (catalog) { return catalog.filter(function (simulation) { return !simulation.isDeprecated; }); }));
    };
    EvaluationService.prototype.createSimulation = function (simulationId, machineIndex) {
        var _this = this;
        return this.simulationsRepository
            .save({
            simulationId: simulationId,
            machineIndex: machineIndex,
        })
            .pipe(switchMap(function () { return _this.refreshSimulations(); }));
    };
    EvaluationService.prototype.refreshSimulations = function () {
        var _this = this;
        return this.paris.getRepository(EvaluationSimulation).allItems$.pipe(tap(function (simulations) {
            // Do not publish new identical data
            if (JSON.stringify(_this.evaluationSimulationsSubject$.value) !==
                JSON.stringify(simulations)) {
                _this.evaluationSimulationsSubject$.next(simulations);
            }
        }, function (error) {
            _this.appInsightsService.trackEvent('Evaluation', {
                type: TrackingEventType.Action,
                id: 'refreshSimulations',
                error: error,
            });
        }), mergeMap(function () { return _this.evaluationSimulationsSubject$.asObservable(); }));
    };
    // step operations
    EvaluationService.prototype.getEvaluationSteps = function () {
        var _this = this;
        return this.paris
            .apiCall(GetEvaluationStepsApiCall)
            .pipe(tap(function (steps) { return (_this.currentSteps = steps); }));
    };
    EvaluationService.prototype.patchEvaluationStatus = function (step) {
        return this.paris.apiCall(PatchEvaluationStatusApiCall, step);
    };
    EvaluationService.prototype.postEvaluationStatus = function (step) {
        var _this = this;
        return this.paris
            .apiCall(PostEvaluationStatusApiCall, step)
            .pipe(tap(function () { return _this.currentSteps.push(step); }));
    };
    // report operations
    EvaluationService.prototype.getIncidentsReport = function () {
        var _this = this;
        return this.paris
            .apiCall(GetEvaluationIncidentsApiCall)
            .pipe(tap(function (val) { return _this.evaluationIncidentsSubject$.next(val); }));
    };
    EvaluationService.prototype.getInvestigationsReport = function () {
        return this.paris.apiCall(GetEvaluationInvestigationsApiCall);
    };
    EvaluationService.prototype.getResponseActions = function () {
        var _this = this;
        return this.evaluationMachines$.pipe(take(1), mergeMap(function (machines) {
            if (!machines || machines.every(function (m) { return !m.senseMachineId; })) {
                return of(new Array());
            }
            var minFromDate = moment().subtract(180, 'days');
            return _this.paris
                .getRepository(ActionHistory)
                .query({
                where: {
                    MachineId: machines.filter(function (m) { return m.senseMachineId; }).map(function (m) { return m.senseMachineId; }),
                    fromDate: machines
                        .filter(function (m) { return m.provisioningTime; })
                        .map(function (m) { return m.provisioningTime; })
                        .reduce(function (d1, d2) {
                        var minProvisioningTime = d1 < d2 ? d1 : d2;
                        return minFromDate.isAfter(minProvisioningTime)
                            ? minFromDate.toDate()
                            : minProvisioningTime;
                    }, new Date())
                        .toJSON(),
                    toDate: new Date().toJSON(),
                },
            })
                .pipe(map(function (dataset) { return dataset.items; }));
        }));
    };
    // dataview operations
    EvaluationService.prototype.getMachinesByEvaluationMachines = function (evaluationMachines) {
        return Object.entries(this.machinesMapSubject$.value)
            .map(function (_a) {
            var key = _a[0], value = _a[1];
            return (evaluationMachines.find(function (m) { return m.senseMachineId === key; }) ? value : null);
        })
            .filter(function (m) { return m; });
    };
    EvaluationService.prototype.getMachineAlertCount = function (machineId) {
        return this.evaluationIncidentsSubject$.value &&
            this.evaluationIncidentsSubject$.value.machineAlertCounts[machineId]
            ? this.evaluationIncidentsSubject$.value.machineAlertCounts[machineId]
            : 0;
    };
    EvaluationService.prototype.getMachineRiskLevel = function (machine) {
        return this.machinesMapSubject$.value && this.machinesMapSubject$.value[machine.senseMachineId]
            ? this.machinesMapSubject$.value[machine.senseMachineId].riskScore.priority
            : LOWEST_EXPOSURE_AND_RISK_SCORES_PRIORITY; // this value represents low priority when there is no risk score
    };
    EvaluationService.prototype.getMachineExposureLevel = function (machine) {
        return this.machinesMapSubject$.value && this.machinesMapSubject$.value[machine.senseMachineId]
            ? this.machinesMapSubject$.value[machine.senseMachineId].exposureScore.priority
            : LOWEST_EXPOSURE_AND_RISK_SCORES_PRIORITY; // this value represents low priority when there is no exposure level
    };
    EvaluationService.prototype.getMachineUpTimeInHours = function (machine) {
        var timeDiff = new Date().getTime() - machine.provisioningTime.getTime();
        var diffMin = Math.ceil(timeDiff / (1000 * 60 * 60));
        return Math.min(this.lab.machineExpirationInHours, diffMin);
    };
    // machine status tile operations
    EvaluationService.prototype.getMachinesBarsData = function () {
        var _this = this;
        return this.getActiveMachines().map(function (m) { return ({
            id: m.senseMachineId,
            title: m.name,
            value: _this.getMachineTimeLeftInHours(m),
            total: _this.lab.machineExpirationInHours,
            unit: 'h',
            valueColor: _this.knownColorsService.knownColorsMap['greenLight'],
            totalColor: _this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'],
            width: '100%',
            height: 8,
            disableClick: !m.senseMachineId,
        }); });
    };
    EvaluationService.prototype.getMachineTimeLeftInHours = function (machine) {
        if (!machine.provisioningTime) {
            return 0;
        }
        return this.lab.machineExpirationInHours - this.getMachineUpTimeInHours(machine);
    };
    return EvaluationService;
}());
export { EvaluationService };
export var ConsentType;
(function (ConsentType) {
    ConsentType[ConsentType["MsInformation"] = 0] = "MsInformation";
    ConsentType[ConsentType["MsInformationSharing"] = 1] = "MsInformationSharing";
    ConsentType[ConsentType["AttackIQ"] = 2] = "AttackIQ";
})(ConsentType || (ConsentType = {}));
