/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../dataviews/components/dataview.component.ngfactory";
import * as i2 from "../../../dataviews/components/dataview.component";
import * as i3 from "../../../../../../../projects/shared/src/lib/services/wicd-sanitizer.service";
import * as i4 from "../../../dialogs/services/dialogs.service";
import * as i5 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i6 from "../../../../../../../projects/config/src/lib/services/preferences.service";
import * as i7 from "@angular/router";
import * as i8 from "../../../dataviews/services/dataview-repository.service";
import * as i9 from "../../../insights/services/app-insights.service";
import * as i10 from "../../../dialogs/services/errors-dialog.service";
import * as i11 from "../../services/entity-panels.service";
import * as i12 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i13 from "../../../../../../../projects/config/src/lib/services/app-context.service";
import * as i14 from "@microsoft/paris/dist/lib/paris";
import * as i15 from "@angular/cdk/a11y";
import * as i16 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i17 from "./recommendation-context-properties.entity-details.component";
import * as i18 from "../../../tvm/services/tvm-texts.service";
import * as i19 from "../../../@entities/@tvm/security-recommendations/services/recommendation-context.fields.service";
var styles_RecommendationContextPropertiesEntityDetailsComponent = [];
var RenderType_RecommendationContextPropertiesEntityDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RecommendationContextPropertiesEntityDetailsComponent, data: {} });
export { RenderType_RecommendationContextPropertiesEntityDetailsComponent as RenderType_RecommendationContextPropertiesEntityDetailsComponent };
export function View_RecommendationContextPropertiesEntityDetailsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "dataview", [], null, [[null, "onItemClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onItemClick" === en)) {
        var pd_0 = (_co.openMachinePage($event.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DataViewComponent_0, i1.RenderType_DataViewComponent)), i0.ɵdid(1, 245760, null, 0, i2.DataViewComponent, [i3.WicdSanitizerService, i4.DialogsService, i5.PanelService, i6.PreferencesService, i7.ActivatedRoute, i8.DataviewRepositoryService, i7.Router, i0.ChangeDetectorRef, i9.AppInsightsService, i10.ErrorsDialogService, i11.EntityPanelsService, i12.FeaturesService, i13.AppContextService, i14.Paris, i15.LiveAnnouncer, i16.I18nService], { allowFilters: [0, "allowFilters"], allowPaging: [1, "allowPaging"], disableSelection: [2, "disableSelection"], navigateOnChange: [3, "navigateOnChange"], padLeft: [4, "padLeft"], searchEnabled: [5, "searchEnabled"], loadingMessageText: [6, "loadingMessageText"], allowColumnCustomization: [7, "allowColumnCustomization"], ignoreQueryParams: [8, "ignoreQueryParams"], dataViewConfig: [9, "dataViewConfig"], fields: [10, "fields"], repository: [11, "repository"] }, { onItemClick: "onItemClick" }), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = true; var currVal_2 = true; var currVal_3 = false; var currVal_4 = true; var currVal_5 = false; var currVal_6 = _co.loadingMessageText; var currVal_7 = false; var currVal_8 = true; var currVal_9 = _co.dataViewConfig; var currVal_10 = _co.fields; var currVal_11 = _co.repository; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
export function View_RecommendationContextPropertiesEntityDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "recommendation-context-properties-entity-details", [], null, null, null, View_RecommendationContextPropertiesEntityDetailsComponent_0, RenderType_RecommendationContextPropertiesEntityDetailsComponent)), i0.ɵdid(1, 114688, null, 0, i17.RecommendationContextPropertiesEntityDetailsComponent, [i18.TvmTextsService, i12.FeaturesService, i19.RecommendationContextFieldsService, i0.Injector, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RecommendationContextPropertiesEntityDetailsComponentNgFactory = i0.ɵccf("recommendation-context-properties-entity-details", i17.RecommendationContextPropertiesEntityDetailsComponent, View_RecommendationContextPropertiesEntityDetailsComponent_Host_0, { entity: "entity", dataEnriched: "dataEnriched", asKeyValueList: "asKeyValueList", showEntityPanelLink: "showEntityPanelLink", mode: "mode", entityPageViewMode: "entityPageViewMode", repository: "repository", contextOptions: "contextOptions", recommendationContextKey: "recommendationContextKey", recommendationContextType: "recommendationContextType" }, {}, []);
export { RecommendationContextPropertiesEntityDetailsComponentNgFactory as RecommendationContextPropertiesEntityDetailsComponentNgFactory };
