import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { EntityEvent, Paris, RelationshipRepository } from '@microsoft/paris';
import { FabricIconNames } from '@wcd/scc-common';
import { DataViewConfig, DataviewField } from '@wcd/dataview';
import {
	AdvancedFeatures,
	CustomTiIndicator,
	CustomTiIndicatorsCapacityApiCall,
	CustomTiIndicatorsType,
	CustomTiIndicatorsTypes,
	CustomTiIndicatorTypeExclusionsRelationship,
	MachineGroup,
	TiIndicatorsCapacity,
	MdeUserRoleActionEnum,
} from '@wcd/domain';
import { combineLatest, merge, Observable, of, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AuthService } from '@wcd/auth';
import { Feature, FeaturesService } from '@wcd/config';
import {
	EntityPanelActionEvent,
	EntityPanelsService,
} from '../../../global_entities/services/entity-panels.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { I18nService } from '@wcd/i18n';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { MachinesService } from '../../machines/services/machines.service';
import { CustomTiIndicatorsFields } from '../services/custom-ti-indicators.fields';
import { CustomTiIndicatorsService } from '../services/custom-ti-indicators.service';
import { AdvancedFeaturesService } from '../../../admin/integration-settings/advanced-features.service';
import { MessageBarType } from 'office-ui-fabric-react';
import { CapacityState } from '@wcd/domain';
import { DataviewActionButtonConfig } from '../../../dataviews/components/actions-components/dataview-action-button.component';
import { DataviewActionTypes } from '../../../dataviews/components/actions-components/dataview-actions.model';
import { sccHostService } from '@wcd/scc-interface';
import { PanelSettings } from '@wcd/panels';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';


export interface CapacityOptions {
	state: CapacityState;
	total?: string;
	outOflimit?: string;
	tooltip: string;
	color: string;
}

@Component({
	selector: 'custom-ti-indicator-dataview',
	template: `
		<div class="wcd-full-height wcd-flex-vertical" *ngIf="tabsData">
			<div class="wcd-flex-vertical wcd-flex-items-start wcd-padding-xxLarge-horizontal wcd-padding-bottom wcd-padding-none-horizontal-left-important" [class.wcd-padding-none-horizontal-right-important]="isScc">
				<h3 class="wcd-margin-bottom">{{ 'customTiIndicator_common_title' | i18n }}</h3>
				<div>
					{{ 'customTiIndicator.dataview.actions.capacity.status.available.title' | i18n }}
					<ng-container
						*ngIf="currentCapacityOptions.state !== CapacityState.Unavailable; else unavailable"
					>
						<span [ngClass]="currentCapacityOptions.color" class="wcd-margin-xsmall-left">{{
							currentCapacityOptions.total
						}}</span
						>{{ currentCapacityOptions.outOflimit }} {{'customTiIndicator_common_plural_indicators' | i18n }}
					</ng-container>
					<ng-template #unavailable>
						<span [ngClass]="currentCapacityOptions.color"
							>{{
								'customTiIndicator.dataview.actions.capacity.status.unavailable.title' | i18n
							}}
						</span>
					</ng-template>
				</div>
			</div>
			<div class="wcd-flex-none wcd-padding-bottom"
				 [class.wcd-padding-none-horizontal-right-important]="isScc">
				<tabs [tabsData]="tabsData" [currentTab]="currentTabId"></tabs>
			</div>
			<dataview
				class="wcd-flex-1"
				*ngIf="currentCustomTiIndicatorsType"
				[entityType]="globalEntityType"
				[fields]="fields"
				[id]="'customTiIndicators_' + currentCustomTiIndicatorsType.id"
				[repository]="customTiIndicatorsRepository"
				[disableSelection]="!isUserAllowedActions"
				[dataViewConfig]="dataViewConfig"
				[refreshOn]="lastUpdate"
				[isItemClickable]="isItemClickable"
				[allowAdd]="
					isUserAllowedActions && !currentCustomTiIndicatorsType.readonly && capacityAvailable
				"
				(onNewItem)="showCustomTiIndicatorNewDialog(currentCustomTiIndicatorsType)"
				(onTableRenderComplete)="onTableRenderComplete()"
				[queueHeader]="true"
				[assetCommandBar]="true"
				[padLeft]="false"
				commandBarNoPadding="true"
				responsiveActionBar="true"
				responsiveLayout="true"
				[customActionsLeft]="getCustomButtonConfig()"
				[removePaddingRight]="isScc"
				[entitySidePanelSettings]="sidePanelSettings"
				[label]="tabNames[currentTabId]"
				selectAllEnabled="true"
				[searchEnabled]="true"
				[searchCommandTitle]="this.i18nService.strings.indicators_search_command_text"
			>
				<p *ngIf="warningHelpKey" dataview-header class="wcd-full-width">
					<fab-message-bar
						[messageBarType]="MessageBarType.warning"
						[styles]="{
							text: { marginBottom: 10, marginTop: 10 },
							root: { height: '10px' },
							iconContainer: { marginBottom: 10, marginTop: 10 }
						}"
					>
						<markdown [data]="warningHelpKey"></markdown>
					</fab-message-bar>
				</p>
			</dataview>
		</div>
	`,
})
export class CustomTiIndicatorsDataviewComponent implements OnInit, OnDestroy {
	readonly CapacityState = CapacityState;
	readonly MessageBarType = MessageBarType;
	readonly CustomTiIndicatorsTypes = CustomTiIndicatorsTypes;

	tabsData: Array<TabModel>;
	currentTabId: string;
	currentCustomTiIndicatorsType: CustomTiIndicatorsType;
	fields: Array<DataviewField>;
	dataViewConfig: DataViewConfig;
	customTiIndicatorsRepository: RelationshipRepository<CustomTiIndicatorsType, CustomTiIndicator>;
	machineGroups: Array<MachineGroup>;
	globalEntityType = this.globalEntityTypesService.getEntityType(CustomTiIndicator);
	currentTiIndicatorValue: string;
	lastUpdate: any;
	capacityAvailable: boolean;
	currentCapacityOptions: CapacityOptions;
	isScc = sccHostService.isSCC;
	sidePanelSettings: PanelSettings = { role: 'none'}

	private customTiIndicatorsTypeSubscription: Subscription;
	private _allCustomTiIndicatorsTypes: Array<CustomTiIndicatorsType>;
	private _customTiIndicatorsChangesSubscription: Subscription;
	private _loadDataSubscription: Subscription;
	private _routeParamsSubscription: Subscription;
	private _loadCapacitySubscription: Subscription;
	private _onActionSubscription: Subscription;
	private _newDialogSubscription: Subscription;
	private _importDialogSubscription: Subscription;
	private _blockIpAndDomainEnabled: boolean;
	private _warningHelpKey: string;

	private _entityCommonFields: Array<DataviewField<CustomTiIndicator>>;

	private _capacityOptions: Record<CapacityState, CapacityOptions> = {
		[CapacityState.Available]: {
			state: CapacityState.Available,
			tooltip: this.i18nService.get(
				'customTiIndicator.dataview.actions.capacity.status.available.tooltip'
			),
			color: 'ms-color-green',
		},
		[CapacityState.Unavailable]: {
			state: CapacityState.Unavailable,
			total: this.i18nService.get(
				'customTiIndicator.dataview.actions.capacity.status.unavailable.title'
			),
			tooltip: this.i18nService.get(
				'customTiIndicator.dataview.actions.capacity.status.unavailable.tooltip'
			),
			color: 'ms-color-red',
		},
		[CapacityState.Exceeded]: {
			state: CapacityState.Exceeded,
			tooltip: this.i18nService.get(
				'customTiIndicator.dataview.actions.capacity.status.exceeded.tooltip'
			),
			color: 'ms-color-red',
		},
	};

	tabNames: Record<CustomTiIndicatorsTypes, string> = {
		[CustomTiIndicatorsTypes.Files]: this.i18nService.strings
			.customTiIndicator_dataview_entity_names_file_pluralName,
		[CustomTiIndicatorsTypes.Ip]: this.i18nService.strings
			.customTiIndicator_dataview_entity_names_ip_pluralName,
		[CustomTiIndicatorsTypes.Url]: this.i18nService.strings
			.customTiIndicator_dataview_entity_names_url_domain_pluralName,
		[CustomTiIndicatorsTypes.Certificate]: this.i18nService.strings
			.customTiIndicator_dataview_entity_names_certificate_pluralName,
		[CustomTiIndicatorsTypes.MemoryContent]: this.i18nService.strings
			.customTiIndicator_dataview_entity_names_process_memory_name,
	};

	constructor(
		private route: ActivatedRoute,
		private paris: Paris,
		public customTiIndicatorsService: CustomTiIndicatorsService,
		public authService: AuthService,
		public machinesService: MachinesService,
		private customTiFields: CustomTiIndicatorsFields,
		private globalEntityTypesService: GlobalEntityTypesService,
		private i18nService: I18nService,
		private featureService: FeaturesService,
		private entityPanelsService: EntityPanelsService,
		private advancedFeaturesService: AdvancedFeaturesService,
		private performanceSccService: PerformanceSccService
	) {
		this._allCustomTiIndicatorsTypes = this.paris.getRepository(CustomTiIndicatorsType).entity.values;
		this.customTiIndicatorsRepository = paris.getRelationshipRepository(
			CustomTiIndicatorTypeExclusionsRelationship
		);

		this._blockIpAndDomainEnabled = this.featureService.isEnabled(Feature.BlockIpAndDomain);
	}

	get isUserAllowedActions() {
		return (
			this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.securitySettings) ||
			this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.remediationActions)
		);
	}

	get warningHelpKey() {
		return this._warningHelpKey ? this._warningHelpKey.replace('\n', '') : null;
	}

	ngOnInit(): void {
		this._routeParamsSubscription = this.route.params.subscribe((params: Params) => {
			const currentCustomTiIndicatorTypeId: CustomTiIndicatorsTypes =
				params.customTiIndicatorType || this._allCustomTiIndicatorsTypes[0].id;

			this.currentTiIndicatorValue =
				params.id && currentCustomTiIndicatorTypeId == CustomTiIndicatorsTypes.Files
					? params.id
					: null;

			const advancedFeaturesObservable: Observable<AdvancedFeatures> = this._blockIpAndDomainEnabled
				? this.advancedFeaturesService.getAdvancedFeaturesSettings()
				: of(null);

			this._loadDataSubscription && this._loadDataSubscription.unsubscribe();
			this._loadDataSubscription = combineLatest(
				this.machinesService.getFullUserExposedMachineGroups(),
				this.customTiFields.getFields(currentCustomTiIndicatorTypeId),
				this.loadCapacity(),
				advancedFeaturesObservable,
				(
					machineGroups: Array<MachineGroup>,
					fields: Array<DataviewField<CustomTiIndicator>>,
					capacity: TiIndicatorsCapacity,
					advancedFeatures: AdvancedFeatures
				) => {
					this.machineGroups = machineGroups;
					this._entityCommonFields = fields;
					this.setCapacityDetails(capacity);
					this.setActionInfoMessage(advancedFeatures, currentCustomTiIndicatorTypeId);
				}
			).subscribe(() => {
				this.selectCustomTiIndicatorType(currentCustomTiIndicatorTypeId);
				this._customTiIndicatorsChangesSubscription = merge(this.paris.save$, this.paris.remove$)
					.pipe(
						filter((event: EntityEvent) => event.entity === CustomTiIndicator),
						// change dataview options so the data and filters are reloaded
						tap(() => this.setDataviewOptions())
					)
					.subscribe(() => this.setTabs());
			});

			this._onActionSubscription = this.entityPanelsService.onAction.subscribe(
				($event: EntityPanelActionEvent) => {
					if ($event.action.id === 'delete') {
						this.refreshCapacity();
					}
				}
			);

		});
	}

	ngOnDestroy(): void {
		this.customTiIndicatorsTypeSubscription && this.customTiIndicatorsTypeSubscription.unsubscribe();
		this._customTiIndicatorsChangesSubscription &&
			this._customTiIndicatorsChangesSubscription.unsubscribe();
		this._routeParamsSubscription && this._routeParamsSubscription.unsubscribe();
		this._loadDataSubscription && this._loadDataSubscription.unsubscribe();
		this._loadCapacitySubscription && this._loadCapacitySubscription.unsubscribe();
		this._onActionSubscription && this._onActionSubscription.unsubscribe();
		this._newDialogSubscription && this._newDialogSubscription.unsubscribe();
		this._importDialogSubscription && this._importDialogSubscription.unsubscribe();
	}


	onTableRenderComplete(){
		this.performanceSccService.endNgPageLoadPerfSession('indicators.' + this.currentTabId);
	}

	getCustomButtonConfig(): DataviewActionButtonConfig[] {
		const importBtn =
			this.isUserAllowedActions &&
			!this.currentCustomTiIndicatorsType.readonly &&
			this.currentCustomTiIndicatorsType.id !== CustomTiIndicatorsTypes.Certificate &&
			this.capacityAvailable
				? [
						{
							localizedTooltip: this.i18nService.get(
								'customTiIndicator_dataview_actions_import_title'
							),
							onClickCallback: () => this.showCustomTiIndicatorImportDialog(),
							icon: 'BulkUpload',
							localizedLabel: this.i18nService.get(
								'customTiIndicator_dataview_actions_import_title'
							),
							elementId: 'import-custom-ti-btn',
							actionType: DataviewActionTypes.Button,
							dataTrackId: 'ImportBtn_' + this.currentCustomTiIndicatorsType.id,
							dataTrackType: 'Button',
						},
				  ]
				: [];

		return importBtn;
	}

	showCustomTiIndicatorImportDialog() {
		this._importDialogSubscription = this.customTiIndicatorsService
			.showCustomTiIndicatorImportDialog(this.currentCustomTiIndicatorsType)
			.subscribe(panel => {
				panel.instance.done.subscribe((importSucceeded: boolean) => {
					if (importSucceeded) {
						this.lastUpdate = new Date();
						this.refreshCapacity();
					}
				});
			});
	}

	showCustomTiIndicatorNewDialog(currentCustomTiIndicatorsType) {
		this._newDialogSubscription = this.customTiIndicatorsService
			.showCustomTiIndicatorNewDialog(currentCustomTiIndicatorsType)
			.subscribe(panel => panel.instance.save.subscribe(() => this.refreshCapacity()));
	}

	private refreshCapacity() {
		this._loadCapacitySubscription = this.loadCapacity().subscribe((capacity: TiIndicatorsCapacity) => {
			this.setCapacityDetails(capacity);
		});
	}

	private selectCustomTiIndicatorType(customTiIndicatorTypeId: CustomTiIndicatorsTypes) {
		this.customTiIndicatorsTypeSubscription && this.customTiIndicatorsTypeSubscription.unsubscribe();

		this.customTiIndicatorsTypeSubscription = this.paris
			.getItemById(CustomTiIndicatorsType, customTiIndicatorTypeId)
			.subscribe((customTiIndicatorType: CustomTiIndicatorsType) => {
				if ((this.currentCustomTiIndicatorsType = customTiIndicatorType)) {
					this.customTiIndicatorsRepository.sourceItem = this.currentCustomTiIndicatorsType;
					this.currentTabId = customTiIndicatorTypeId;
					this.setDataviewOptions();
					this.setTabs();
				}
			});
	}

	private setCapacityDetails(capacity: TiIndicatorsCapacity) {
		this.capacityAvailable = capacity.capacityAvailable;
		this.currentCapacityOptions = this._capacityOptions[capacity.capacityState];

		if (capacity.capacityState !== CapacityState.Unavailable) {
			this.currentCapacityOptions.total = capacity.total.toString();
			this.currentCapacityOptions.outOflimit = this.i18nService.get(
				'customTiIndicator.dataview.actions.capacity.status.available.outOfLimit',
				{ limit: capacity.limit }
			);
		}
	}

	private setDataviewOptions() {
		this.fields = this.currentCustomTiIndicatorsType.properties
			.map(property => {
				property.name = this.i18nService.get(
					`customTiIndicator.dataview.entity.fields.${this.currentCustomTiIndicatorsType.id}.${
						property.id
					}`
				);

				return new DataviewField(
					Object.assign(
						{
							getDisplay: (item: CustomTiIndicator) =>
								this.customTiIndicatorsService.getCustomTiDisplay(item, property),
							className: 'nowrap wcd-text-overflow-medium-large',
							filter: this.customTiIndicatorsService.getCustomTiFilters(
								property.id,
								this.currentCustomTiIndicatorsType.id
							),
						},
						property
					)
				);
			})
			.concat(this._entityCommonFields);

		this.dataViewConfig = {
			id: 'customTiIndicators_' + this.currentTabId,
			fixedFilterValues: { type: [this.currentCustomTiIndicatorsType.id] },
			showModalOnExport: false,
			exportResults: (options: { [index: string]: any }, format: string) => {
				return this.customTiIndicatorsService.downloadCsv({
					type: this.currentCustomTiIndicatorsType.id,
					downloadImportCsvSample: false,
				});
			},
		};

		if (this.currentTiIndicatorValue) {
			this.dataViewConfig.fixedOptions = { tiIndicator: this.currentTiIndicatorValue };
		}
	}

	private setTabs() {
		this.tabsData = this._allCustomTiIndicatorsTypes.map(customTiIndicatorType => {
			return new TabModel({
				id: customTiIndicatorType.id.toString(),
				name: this.tabNames[customTiIndicatorType.id],
				routerLink: `/preferences2/custom_ti_indicators/${customTiIndicatorType.id}`,
				show: () => {
					switch (customTiIndicatorType.id) {
						case CustomTiIndicatorsTypes.Certificate: {
							return this.featureService.isEnabled(Feature.CertificateBlock);
						}
						case CustomTiIndicatorsTypes.MemoryContent: {
							return false;
						}
						default: {
							return true;
						}
					}
				},
			});
		});
	}

	private loadCapacity(): Observable<TiIndicatorsCapacity> {
		return this.paris.apiCall<TiIndicatorsCapacity>(CustomTiIndicatorsCapacityApiCall);
	}

	private setActionInfoMessage(
		advancedFeatures: AdvancedFeatures,
		currentCustomTiIndicatorTypeId: CustomTiIndicatorsTypes
	) {
		switch (currentCustomTiIndicatorTypeId) {
			case CustomTiIndicatorsTypes.Ip:
			case CustomTiIndicatorsTypes.Url: {
				if (!this._blockIpAndDomainEnabled) {
					this._warningHelpKey = this.i18nService.get(
						'customTiIndicator.dataview.entity.fields.action.values.alertandblock.info.network.notExposed'
					);
				} else if (!advancedFeatures || !advancedFeatures.allowWdavNetworkBlock) {
					this._warningHelpKey = this.i18nService.get(
						'customTiIndicator.dataview.entity.fields.action.values.alertandblock.info.network.turnedOff'
					);
				} else {
					this._warningHelpKey = null;
				}

				break;
			}
			default: {
				this._warningHelpKey = null;
				break;
			}
		}
	}
}
