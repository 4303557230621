import { EntityField, ModelBase, Entity } from '@microsoft/paris';

@Entity({
	singularName: 'Network Scan Session',
	pluralName: 'Network Scan Sessions',
	readonly: true,
	endpoint: 'network-scan-session',
})
export class NetworkScanSession extends ModelBase {
	@EntityField()
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;
}