var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { FabricIconNames } from '@wcd/scc-common';
import { AuthService } from '@wcd/auth';
import { Feature, FeaturesService, FlavorService, PollingService } from '@wcd/config';
import { CustomTiIndicatorsType, CustomTiIndicatorsTypes, CustomTiIndicatorTypeExclusionsRelationship, File, FileActionType, FileFileProtectionInfoRelationship, FileFileStatsRelationship, FileSearchCriterion, SampleDisabledReason, MdeUserRoleActionEnum, ServiceSourceType, } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { PanelType } from '@wcd/panels';
import { Lazy } from '@wcd/utils';
import { compact } from 'lodash-es';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { catchError, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { EntityCustomTiActionsConfigService } from '../../../@entities/common/services/entity.custom-ti-actionconfig.service';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { AskThreatExpertService } from '../../../feedback/services/ask-threat-expert.service';
import { FileEntityDetailsComponent } from '../../../global_entities/components/entity-details/file.entity-details.component';
import { EntityMinimizedDetailsComponentBase } from '../../../global_entities/components/entity-minimized/entity-minimized-details.component.base';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';
import { EntityViewType } from '../../../global_entities/models/entity-view-type.enum';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { TagsService } from '../../../tags/services/tags.service';
import { RegExpService } from '@wcd/shared';
import { FileActionCenterPanelComponent } from '../components/file.action-center/file.action-center.panel.component';
import { FileCollectSamplePanelComponent } from '../components/file.collect-sample/file.collect-sample.panel.component';
import { FileDownloadSamplePanelComponent } from '../components/file.download-sample/file.download-sample.panel.component';
import { FileEntityPanelComponent } from '../components/file.entity-panel/file.entity-panel.component';
import { FileEntityComponent } from '../components/file.entity.component';
import { FilesBackendService } from '../services/files.backend.service';
import { FileActionsService } from './file.actions.service';
import { FilesFieldsService } from './files.fields.service';
import { FilesService } from './files.service';
import { AppContextService } from '@wcd/config';
import { AppFlavorConfig } from '@wcd/scc-common';
var pollingIntervalMilliseconds = 5 * 60 * 1000; // 5 minutes
var actionsOrderWeights = {
    quarantine: 10,
    editIndicator: 20,
    collectSample: 30,
    downloadSample: 40,
    consultThreatExpert: 50,
    actionCenter: 60,
};
var ActionRefreshReason;
(function (ActionRefreshReason) {
    ActionRefreshReason[ActionRefreshReason["OnStart"] = 0] = "OnStart";
    ActionRefreshReason[ActionRefreshReason["OnPollingInterval"] = 1] = "OnPollingInterval";
    ActionRefreshReason[ActionRefreshReason["OnQuarantine"] = 2] = "OnQuarantine";
    ActionRefreshReason[ActionRefreshReason["OnQuarantineStatistics"] = 3] = "OnQuarantineStatistics";
    ActionRefreshReason[ActionRefreshReason["OnQuarantinePermissionsReceived"] = 4] = "OnQuarantinePermissionsReceived";
    ActionRefreshReason[ActionRefreshReason["OnIndicatorAdded"] = 5] = "OnIndicatorAdded";
    ActionRefreshReason[ActionRefreshReason["OnStartedCollecting"] = 6] = "OnStartedCollecting";
    ActionRefreshReason[ActionRefreshReason["OnCollectSampleStatistics"] = 7] = "OnCollectSampleStatistics";
    ActionRefreshReason[ActionRefreshReason["OnDownloadSampleStatistics"] = 8] = "OnDownloadSampleStatistics";
})(ActionRefreshReason || (ActionRefreshReason = {}));
var FileEntityTypeService = /** @class */ (function () {
    function FileEntityTypeService(filesService, timeRangesService, paris, tagsService, featuresService, i18nService, dialogsService, authService, fileActionsService, fileBackendService, entityCustomTiActionsConfigService, askThreadExpertService, pollingService, appContextService, flavorService) {
        var _this = this;
        this.filesService = filesService;
        this.timeRangesService = timeRangesService;
        this.paris = paris;
        this.tagsService = tagsService;
        this.featuresService = featuresService;
        this.i18nService = i18nService;
        this.dialogsService = dialogsService;
        this.authService = authService;
        this.fileActionsService = fileActionsService;
        this.fileBackendService = fileBackendService;
        this.entityCustomTiActionsConfigService = entityCustomTiActionsConfigService;
        this.askThreadExpertService = askThreadExpertService;
        this.pollingService = pollingService;
        this.appContextService = appContextService;
        this.flavorService = flavorService;
        this.timeRanges = new Lazy(function () {
            return _this.timeRangesService.pick([
                "day" /* day */,
                "3days" /* '3days' */,
                "week" /* week */,
                "month" /* month */,
                "6months" /* '6months' */,
            ]);
        });
        this._onActionStatusRefresh$ = new BehaviorSubject(ActionRefreshReason.OnStart);
        this._downloadSampleAction = {
            id: 'downloadSample',
            name: this.i18nService.get('file.sample.download.title'),
            icon: FabricIconNames.Download,
            refreshOnResolve: false,
            method: function (_a) {
                var file = _a[0];
                return __awaiter(_this, void 0, void 0, function () {
                    var topFilenameObservable$, sha;
                    var _this = this;
                    return __generator(this, function (_b) {
                        this._sampleStateSub && this._sampleStateSub.unsubscribe();
                        this._onActionStatusRefresh$.next(ActionRefreshReason.OnDownloadSampleStatistics);
                        topFilenameObservable$ = (file.name ? of(file.name) : this.getTopFilename(file)).pipe(map(function (filename) { return filename || file.sha1; }));
                        sha = file.sha1 || file.sha256;
                        this._sampleStateSub = combineLatest(this.fileBackendService.getSampleState(sha, true), topFilenameObservable$).subscribe(function (_a) {
                            var sampleState = _a[0], filename = _a[1];
                            if (sampleState.isEnabled) {
                                _this.dialogsService
                                    .showPanel(FileDownloadSamplePanelComponent, {
                                    headerText: _this.i18nService.get('file.sample.download.header'),
                                    type: PanelType.large,
                                }, {
                                    file: file,
                                })
                                    .subscribe(function (panel) {
                                    panel.instance.onDownloadSample.subscribe(function (params) {
                                        _this.fileBackendService
                                            .downloadFileNew(sha, file.name ? file.name : filename, params.downloadReason, params.password)
                                            .subscribe(function (error) {
                                            return _this._onActionStatusRefresh$.next(ActionRefreshReason.OnPollingInterval);
                                        });
                                    }, function (error) {
                                        return _this._onActionStatusRefresh$.next(ActionRefreshReason.OnPollingInterval);
                                    });
                                    panel.instance.onDestroyPanel.subscribe(function (confirmed) {
                                        if (!confirmed) {
                                            _this._onActionStatusRefresh$.next(ActionRefreshReason.OnPollingInterval);
                                        }
                                    });
                                }, function (error) {
                                    return _this._onActionStatusRefresh$.next(ActionRefreshReason.OnPollingInterval);
                                });
                            }
                            else {
                                _this.dialogsService.showSnackbar({
                                    text: _this.i18nService.get('file.sample.collect.fileWasNotObserved'),
                                    icon: 'warningSolid',
                                    iconClassName: 'color-text-warning-dark',
                                    className: 'color-box-warning-light',
                                });
                                _this._onActionStatusRefresh$.next(ActionRefreshReason.OnPollingInterval);
                            }
                        }, function (error) { return _this._onActionStatusRefresh$.next(ActionRefreshReason.OnPollingInterval); });
                        return [2 /*return*/];
                    });
                });
            },
        };
        this._collectSampleAction = {
            id: 'collectSample',
            name: this.i18nService.get('file.sample.collect.title'),
            icon: FabricIconNames.SearchAndApps,
            refreshOnResolve: false,
            method: function (_a) {
                var file = _a[0];
                return __awaiter(_this, void 0, void 0, function () {
                    var sha;
                    var _this = this;
                    return __generator(this, function (_b) {
                        this._sampleStateSub && this._sampleStateSub.unsubscribe();
                        this._onActionStatusRefresh$.next(ActionRefreshReason.OnCollectSampleStatistics);
                        sha = file.sha1 || file.sha256;
                        this._sampleStateSub = this.fileBackendService
                            .getSampleState(sha, true)
                            .subscribe(function (sampleState) {
                            if (sampleState.isEnabled) {
                                _this.dialogsService
                                    .showPanel(FileCollectSamplePanelComponent, {
                                    headerText: _this.i18nService.get('file.sample.collect.header'),
                                    type: PanelType.large,
                                }, {
                                    file: file,
                                })
                                    .subscribe(function (panel) {
                                    panel.instance.onCollectSample.subscribe(function () {
                                        _this.fileBackendService
                                            .collectSample(sha, panel.instance.sampleCollectionReason)
                                            .subscribe(function (error) {
                                            return _this._onActionStatusRefresh$.next(ActionRefreshReason.OnPollingInterval);
                                        });
                                        _this._onActionStatusRefresh$.next(ActionRefreshReason.OnStartedCollecting);
                                    });
                                    panel.instance.onDestroyPanel.subscribe(function (confirmed) {
                                        if (!confirmed) {
                                            _this._onActionStatusRefresh$.next(ActionRefreshReason.OnPollingInterval);
                                        }
                                    });
                                }, function (error) {
                                    _this._onActionStatusRefresh$.next(ActionRefreshReason.OnPollingInterval);
                                });
                            }
                            else {
                                _this.dialogsService.showSnackbar({
                                    text: _this.i18nService.get('file.sample.collect.fileWasNotObserved'),
                                    icon: 'warningSolid',
                                    iconClassName: 'color-text-warning-dark',
                                    className: 'color-box-warning-light',
                                });
                                _this._onActionStatusRefresh$.next(ActionRefreshReason.OnPollingInterval);
                            }
                        });
                        return [2 /*return*/];
                    });
                });
            },
        };
        this.entityType = {
            entity: File,
            id: 'file',
            getItem: function (entity, loadedEntity$) {
                return loadedEntity$.pipe(map(function (loadedEntity) {
                    return new File(__assign({}, loadedEntity, { 
                        // take file name and type from the original entity, because the loaded entity represents a general file, which doesn't have a specific name
                        fileName: entity.fileName, appType: entity.appType }));
                }));
            },
            getIcon: function () { return FabricIconNames.Page; },
            getImage: function (entities) {
                var folder = '/assets/images/icons/entities/';
                var fileName = 'file';
                var firstEntityType = entities[0].appType;
                if (firstEntityType) {
                    var allSameType = entities.every(function (entity) { return entity.appType === firstEntityType; });
                    if (allSameType) {
                        fileName += "_" + firstEntityType.id;
                    }
                }
                return "" + folder + fileName + ".svg";
            },
            icon: FabricIconNames.Page,
            entityContentsComponentType: FileEntityComponent,
            entityDetailsComponentType: FileEntityDetailsComponent,
            entityMinimizedComponentType: EntityMinimizedDetailsComponentBase,
            singleEntityPanelComponentType: FileEntityPanelComponent,
            getEntityName: function (file) { return file.fileName; },
            entityPluralNameKey: 'file_entityType_pluralName',
            entitySingularNameKey: 'file_entityType_singularName',
            getNavigationModel: function (file, serviceSource) {
                return (file.externalFilePageUrl || file.id) && {
                    externalLink: file.externalFilePageUrl,
                    routerLink: !file.externalFilePageUrl
                        ? _this.featuresService.isEnabled(Feature.PortedSccPages) ||
                            !serviceSource ||
                            serviceSource === ServiceSourceType.Wdatp
                            ? [_this.filesService.getFileLink(file.id)]
                            : null
                        : null,
                    queryParams: file.fileName && _this.filesService.getFileLinkParams(file.fileName),
                };
            },
            getEntitiesLink: function (files, options) {
                if (_this.flavorService.isEnabled(AppFlavorConfig.routes.files) && files && files.length === 1)
                    if (files[0].externalFilePageUrl) {
                        return files[0].externalFilePageUrl;
                    }
                    else if (files[0].id) {
                        var fileLink = _this.filesService.getFileLink(files[0].id, null, files[0].fileName);
                        if (options && fileLink) {
                            fileLink = fileLink.indexOf('/overview') > -1 ? fileLink.replace('/overview', "/" + options.tab)
                                : fileLink + "/" + options.tab;
                        }
                        return fileLink;
                    }
                    else {
                        return null;
                    }
            },
            getUseExternalRouting: function (files) {
                return files.length === 1 && Boolean(files[0].externalFilePageUrl);
            },
            getTags: function (entities) {
                return _this.featuresService.isEnabled(Feature.FileSensitivity) && entities.length === 1
                    ? _this.paris
                        .getRelatedItem(FileFileProtectionInfoRelationship, entities[0])
                        .pipe(filter(function (protectionInfo) { return protectionInfo.sensitivity != null; }), map(function (protectionInfo) { return [
                        _this.tagsService.getDataSensitivityTag(protectionInfo.sensitivity, _this.i18nService.get('files.sensitivity.tag.tooltip'), false),
                    ]; }))
                    : of([]);
            },
            dataViewOptions: {
                fields: FilesFieldsService,
                dateRangeOptions: {
                    defaultTimeRangeId: 'month',
                    supportedRanges: this.timeRanges.value,
                },
                fixedOptions: function (currentRange, searchTerm) {
                    var baseOptions = {
                        lookingBackIndays: currentRange.value.toString(),
                    };
                    return searchTerm
                        ? __assign({}, baseOptions, { searchTerm: searchTerm, searchBy: FileSearchCriterion.TopFileNames, pageSize: 100 }) : baseOptions;
                },
                defaultSortFieldId: 'matchType',
                exportOptions: {
                    showModalOnExport: false,
                    exportResults: function (options, fixedOptions, searchTerm) {
                        return _this.filesService.downloadCsv({
                            searchTerm: searchTerm,
                            lookingBackInDays: fixedOptions.lookingBackIndays,
                        });
                    },
                },
                dataViewConfig: {
                    allowFilters: true,
                    allowPaging: false,
                    getFiltersData: function (fixedOptions) { return _this.filesService.getFilesFilters(); },
                },
            },
            searchOptions: {
                displayName: this.i18nService.strings.entity_type_display_name_file,
                resolveTypeFromSearchTerm: function (searchTerm) {
                    return (RegExpService.md5.test(searchTerm) ||
                        RegExpService.sha1.test(searchTerm) ||
                        RegExpService.sha256.test(searchTerm) ||
                        searchTerm.includes('.exe') ||
                        searchTerm.includes('.bat') ||
                        searchTerm.includes('.dll'));
                },
                getSearchParams: function (searchTerm) {
                    if (RegExpService.md5.test(searchTerm) ||
                        RegExpService.sha1.test(searchTerm) ||
                        RegExpService.sha256.test(searchTerm)) {
                        return { url: _this.filesService.getFileLink(searchTerm) };
                    }
                    else {
                        var resultsPageName = _this.appContextService.isSCC ||
                            _this.featuresService.isEnabled(Feature.UpgradeSearchFiles)
                            ? 'searchResults'
                            : 'search';
                        return { url: "/" + resultsPageName + "/files/" + searchTerm };
                    }
                },
                searchDropdownOrder: 0,
                requiredAllowedActions: MdeUserRoleActionEnum.viewData,
                flavorConfig: AppFlavorConfig.search.commonEntities,
            },
            getItemParams: function (file, options) {
                var searchBy = _this.filesService.getFileSearchCriterion(options && options.routeId);
                return { searchBy: searchBy, newFilesApi: _this.featuresService.isEnabled(Feature.K8SMigrationFileProfileKW) };
            },
            getActions: function (files, options, entityViewType) {
                if (files.length !== 1 || !files[0].id) {
                    return [];
                }
                var file = files[0];
                _this._pollSub && _this._pollSub.unsubscribe();
                _this._pollSub = _this.pollingService
                    .poll(pollingIntervalMilliseconds, pollingIntervalMilliseconds)
                    .pipe(tap(function () {
                    _this._onActionStatusRefresh$.next(ActionRefreshReason.OnPollingInterval);
                }))
                    .subscribe();
                return combineLatest(_this._onActionStatusRefresh$).pipe(switchMap(function (_a) {
                    var refreshReason = _a[0];
                    return _this.getAllActions(file, refreshReason, entityViewType);
                }));
            },
            entityPageViewMode: EntityPageViewMode.Asset,
            hideTagsInEntityComponent: true,
        };
        this._customIndicatorsRepo = this.paris.getRelationshipRepository(CustomTiIndicatorTypeExclusionsRelationship);
        this._customIndicatorsRepo.sourceItem = this.paris.getValue(CustomTiIndicatorsType, CustomTiIndicatorsTypes.Files);
    }
    FileEntityTypeService.prototype.ngOnDestroy = function () {
        this._onActionStatusRefresh$ && this._onActionStatusRefresh$.unsubscribe();
        this._pollSub && this._pollSub.unsubscribe();
        this._quarantineSub && this._quarantineSub.unsubscribe();
        this._sampleStateSub && this._sampleStateSub.unsubscribe();
    };
    FileEntityTypeService.prototype.getExistentCustomIndicators = function (file, indicatorType) {
        return this.entityCustomTiActionsConfigService.getCustomIndicators([file.sha1, file.sha256, file.md5], indicatorType);
    };
    FileEntityTypeService.prototype.getTopFilename = function (file) {
        return this.paris
            .getRelatedItem(FileFileStatsRelationship, file)
            .pipe(map(function (fileStats) { return fileStats.topFileNames[0]; }));
    };
    FileEntityTypeService.prototype.getAllActions = function (file, refreshReason, entityViewType) {
        return combineLatest(entityViewType === EntityViewType.EntityPage
            ? this.createQuarantineActionConfig(file, refreshReason)
            : of(null), this.entityCustomTiActionsConfigService.getCommandBarActions(file.sha1, CustomTiIndicatorsTypes.Files, function (file) { return file.sha256 || file.sha1; }, [file.sha1, file.sha256, file.md5]), this.createSampleActionConfig(file, refreshReason), this.createAskThreatExpertConfig(), this.createActionCenterActionConfig()).pipe(map(function (actions) {
            var getWeight = function (id) { return actionsOrderWeights[id] || 999; };
            actions.filter(Boolean).sort(function (a, b) { return getWeight(a.id) - getWeight(b.id); });
            return compact(actions).map(function (actionConfig) { return new ItemActionModel(actionConfig); });
        }));
    };
    FileEntityTypeService.prototype.createAskThreatExpertConfig = function () {
        return of(this.askThreadExpertService.shouldShowThreatExpertPanel()
            ? this.askThreadExpertService.getAskTheExpertCommandConfig('fileEntityCommandBar')
            : null);
    };
    FileEntityTypeService.prototype.createSampleActionConfig = function (file, refreshReason) {
        var _this = this;
        // Shortcut for collecting state as it doesn't require communication with the server side
        if (refreshReason === ActionRefreshReason.OnStartedCollecting) {
            return of(__assign({}, this._collectSampleAction, { disabled: true, tooltip: this.i18nService.get('file.sample.collect.ongoingCollection') }));
        }
        if (refreshReason === ActionRefreshReason.OnCollectSampleStatistics ||
            refreshReason === ActionRefreshReason.OnDownloadSampleStatistics) {
            return of(__assign({}, (refreshReason === ActionRefreshReason.OnCollectSampleStatistics
                ? this._collectSampleAction
                : this._downloadSampleAction), { disabled: true, tooltip: this.i18nService.get('file.quarantine.analyzingPermissions') }));
        }
        return this.fileBackendService.getSampleState(file.sha1 || file.sha256, false).pipe(map(function (sampleState) {
            var disabled = !sampleState.isEnabled;
            var tooltip;
            var buttonAction;
            if (sampleState.actionType === FileActionType.Collect) {
                buttonAction = _this._collectSampleAction;
                if (sampleState.isEnabled) {
                    tooltip = _this.i18nService.get('file.sample.collect.tooltip');
                }
                else {
                    switch (sampleState.sampleDisabledReason) {
                        case SampleDisabledReason.CollectionInProgress:
                            tooltip = _this.i18nService.get('file.sample.collect.ongoingCollection');
                            break;
                        case SampleDisabledReason.UserRbacUnauthorized:
                            tooltip = _this.i18nService.get('file.sample.collect.noPermissions');
                        default:
                            break;
                    }
                }
            }
            else {
                buttonAction = _this._downloadSampleAction;
                if (sampleState.isEnabled) {
                    tooltip = _this.i18nService.get('file.sample.download.tooltip');
                }
                else {
                    if (sampleState.sampleDisabledReason === SampleDisabledReason.FileNotObservedInOrg) {
                        tooltip = _this.i18nService.get('file.sample.download.fileWasNotObserved');
                    }
                    else {
                        tooltip = _this.i18nService.get('file.sample.download.noPermissions');
                    }
                }
            }
            return __assign({}, buttonAction, { disabled: disabled, tooltip: tooltip });
        }), catchError(function (err) { return of(null); }));
    };
    FileEntityTypeService.prototype.quarantineFile = function (file) {
        return __awaiter(this, void 0, void 0, function () {
            var confirmed;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this._quarantineStatus.disabled === false)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.fileActionsService.quarantineFile(file, true)];
                    case 1:
                        confirmed = (_a.sent()).confirmed;
                        if (confirmed) {
                            this._onActionStatusRefresh$.next(ActionRefreshReason.OnQuarantine);
                            return [2 /*return*/];
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        this.dialogsService.showSnackbar({
                            text: this._quarantineStatus.reason,
                            icon: 'warningSolid',
                            iconClassName: 'color-text-warning-dark',
                            className: 'color-box-warning-light',
                        });
                        _a.label = 3;
                    case 3:
                        this._onActionStatusRefresh$.next(ActionRefreshReason.OnPollingInterval);
                        return [2 /*return*/];
                }
            });
        });
    };
    FileEntityTypeService.prototype.createQuarantineActionConfig = function (file, refreshReason) {
        var _this = this;
        if (!this._quarantineAction) {
            this._quarantineAction = {
                id: 'quarantine',
                name: this.i18nService.get('file.quarantine.title'),
                icon: FabricIconNames.BlockedSite,
                rbac: [RbacMdeAllowedActions.remediationActions],
                rbacState: RbacControlState.disabled,
                refreshOnResolve: false,
                method: function (items) { return __awaiter(_this, void 0, void 0, function () {
                    var _this = this;
                    return __generator(this, function (_a) {
                        this._onActionStatusRefresh$.next(ActionRefreshReason.OnQuarantineStatistics);
                        this._quarantineSub && this._quarantineSub.unsubscribe();
                        if (this._quarantineStatus) {
                            this.quarantineFile(items[0]);
                            return [2 /*return*/];
                        }
                        this._quarantineSub = this.fileActionsService
                            .getQuarantineStatus(file)
                            .subscribe(function (quarantineStatus) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                this._quarantineStatus = quarantineStatus;
                                this.quarantineFile(items[0]);
                                return [2 /*return*/];
                            });
                        }); });
                        return [2 /*return*/];
                    });
                }); },
            };
        }
        var fileActionPermissions$ = of({
            disabled: true,
            reason: this.i18nService.get('file.quarantine.tooltips.pending'),
        });
        if (refreshReason !== ActionRefreshReason.OnQuarantine &&
            refreshReason !== ActionRefreshReason.OnQuarantineStatistics) {
            if (this._quarantinePermissions) {
                fileActionPermissions$ = of(this._quarantinePermissions);
            }
            else {
                fileActionPermissions$ = this.fileActionsService.getQuarantinePermissions(file).pipe(startWith({
                    disabled: true,
                    reason: this.i18nService.get('file.quarantine.analyzingPermissions'),
                }), tap(function (permission) {
                    _this._quarantinePermissions = permission;
                    _this._onActionStatusRefresh$.next(ActionRefreshReason.OnQuarantinePermissionsReceived);
                }));
            }
        }
        return fileActionPermissions$.pipe(map(function (quarantineStatus) { return (__assign({}, _this._quarantineAction, { tooltip: quarantineStatus.reason, disabled: quarantineStatus.disabled })); }));
    };
    FileEntityTypeService.prototype.createActionCenterActionConfig = function () {
        var _this = this;
        return of({
            id: 'actionCenter',
            name: this.featuresService.isEnabled(Feature.NewFilePage) ?
                this.i18nService.get('file_manualActions_title') : this.i18nService.get('file.actionCenter.title'),
            icon: FabricIconNames.F12DevTools,
            rbac: [RbacMdeAllowedActions.remediationActions, RbacMdeAllowedActions.alertsInvestigation],
            rbacState: RbacControlState.disabled,
            refreshOnResolve: false,
            method: function (items) {
                return _this.dialogsService
                    .showPanel(FileActionCenterPanelComponent, {
                    headerIcon: FabricIconNames.F12DevTools,
                    headerText: _this.featuresService.isEnabled(Feature.NewFilePage) ?
                        _this.i18nService.get('file_manualActions_title') : _this.i18nService.get('file.actionCenter.title'),
                    type: PanelType.large,
                }, { file: items[0] })
                    .toPromise();
            },
        });
    };
    return FileEntityTypeService;
}());
export { FileEntityTypeService };
