import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { LiveResponseSession } from '../entities/live-response-session.entity';

@ApiCall({
	name: 'Close Live Response Session',
	endpoint: (config, query) =>
		query && query.where && query.where['useV2Api']
			? 'v2/live_response/close_session'
			: 'live_response/close_session',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.automatedIr,
	method: 'POST',
	parseQuery: (session: LiveResponseSession) => ({
		params: {
			useV2Api: session.useV2Api,
		},
		data: {
			session_id: session.id,
		},
	}),
})
export class CloseSessionApiCall extends ApiCallModel<void, LiveResponseSession> {}
