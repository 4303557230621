import { ChangeDetectionStrategy, Component, Input, OnInit, OnDestroy, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BaselineConfiguration } from '@wcd/domain';
import { ModelBase } from '@microsoft/paris';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { ActivatedEntity } from '../../services/activated-entity.service';
import { I18nService } from '@wcd/i18n';
import { BaselineDetectedValuesService } from '../../../tvm/services/baseline-detected-value.service';
import { FabricIconNames } from '@wcd/scc-common';

@Component({
	selector: 'baseline-configuration-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './baseline-configuration.entity-details.component.html',
	styles: [
		`
			.non-compliant-icon {
				color: #990000;
				font-size: 13px;
				margin-right: 1px;
			}
		`,
	],
})
export class BaselineConfigurationEntityDetailsComponent
	extends EntityDetailsComponentBase<BaselineConfiguration>
	implements OnInit, OnDestroy {
	private _subscription: Subscription;
	singleAsset: boolean;
	isCompliant: boolean;
	compliantIconClass: string;
	compliantIconName: string;
	complianceStatus: string;
	detectedValueString: string;

	sourceValues: string[];
	isDetectedValueEnabled: boolean;
	noDataAvailable: string;

	@Input() configuration?: BaselineConfiguration;
	constructor(
		private activatedEntity: ActivatedEntity,
		private detectedValuesService: BaselineDetectedValuesService,
		public i18nService: I18nService,
		injector: Injector
	) {
		super(injector);
		this.noDataAvailable = i18nService.strings.notAvailable_short;
	}

	ngOnInit() {
		super.ngOnInit();
		if (!this.configuration) {
			this._subscription = this.activatedEntity.currentEntity$
				.pipe(filter((entity: ModelBase) => entity instanceof BaselineConfiguration))
				.subscribe((configuration: BaselineConfiguration) => {
					this.configuration = configuration;
				});
		}
		this.singleAsset = this.configuration.applicableDevices === 1;
		this.isCompliant = this.configuration.compliantDevices === 1;
		this.complianceStatus = this.isCompliant
			? this.i18nService.strings.tvm_baseline_compliant_enum_compliant
			: this.i18nService.strings.tvm_baseline_compliant_enum_notCompliant;

		this.compliantIconName = this.isCompliant
			? FabricIconNames.StatusCircleInner
			: FabricIconNames.AlertSolid;
		this.isDetectedValueEnabled = this.detectedValuesService.isConfigurationSupported(this.configuration);
		this.detectedValueString = this.detectedValuesService.getConfigurationDetectedValuesString(
			this.configuration
		);
		this.sourceValues = this.detectedValuesService.getConfigurationUniqueSources(this.configuration);
	}

	ngOnDestroy() {
		this._subscription && this._subscription.unsubscribe();
	}
}
