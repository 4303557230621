export enum VerdictStatusType {
	Malicious = 'malicious',
	Suspicious = 'suspicious',
	Verified = 'verified',
	Clean = 'clean',
	NoThreatsFound = 'clean',
	Unknown = 'unknown',
}

export enum RemediationStatusType {
	Remediated = 'remediated',
	NotFound = 'doesnt_exist',
	Unremediated = 'unremediated',
	Failed = 'failed',
	Active = 'active',
	Running = 'running',
	Declined = 'declined',
	PendingApproval = 'pending_approval',
	Prevented = 'prevented',
	Blocked = 'blocked',
	PartiallyRemediated = 'partially_remediated',
}

export enum RemediationStatusId {
	Remediated = 0,
	NotFound = 6,
	Failed = 7,
	Unremediated = -1,
	Prevented = -2,
	PendingApproval = -3,
	Declined = -4,
	Running = -5,
	Active = -6,
	Blocked = -8,
	PartiallyRemediated = -7,
}
