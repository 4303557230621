import { SccResourceService } from './resource.svc';
import { SccAuthService } from './auth.svc';
import { SccStateSvc } from './state.svc';
import { SccUiService } from './ui.svc';
import { SccAjaxService } from './ajax.svc';
import { SccConfigService } from './config.svc';
import { SccMockService } from './mock.svc';
import { SccMTPService } from './mtp.svc';
import { SccI18nService } from './i18n.svc';
import { SccRolesService, SccRoles } from './scc-roles.svc';
import { SccLogService } from './log.svc';
import { SccPerfService } from './perf.svc';
import { ImportableSccTypes } from './package.types';
import { AnnouncerService } from './announcer.svc';
import { AutoFocusService } from './auto-focus.svc';

type PackageType<T extends string, TResource> =
	TResource extends 'main' ?
		T extends keyof ImportableSccTypes ? ImportableSccTypes[T] : never
	: never;

function inferIsPortalMtoFromUrl() {
	const mtoParam = new URL(location.href).searchParams.get('mto');
	return mtoParam && mtoParam !== 'false';
}
class SccHostService {
	// When not in SCC default to null, stub for unit tests
	constructor(private sccHost: IHost = (({ loginUser: {} } as Partial<IHost>) as unknown) as IHost) {}
	loginUser = this.sccHost.loginUser;
	// TODO(bgruenbaum) remove once we have support from scc
	portalType = inferIsPortalMtoFromUrl() ? 'mto' : this.sccHost.portalType;
	auth: SccAuthService = new SccAuthService(this.sccHost.auth);
	resource: SccResourceService = new SccResourceService(this.sccHost.resource);
	state: SccStateSvc = new SccStateSvc(this.sccHost.state);
	announcer: AnnouncerService = new AnnouncerService();
	autoFocus: AutoFocusService = new AutoFocusService();
	ui: SccUiService = new SccUiService(this.sccHost.ui,this.announcer);
	config: SccConfigService = new SccConfigService(this.sccHost.config);
	mock: SccMockService = new SccMockService(this.sccHost.config, this.loginUser);
	log: SccLogService = new SccLogService(this.sccHost.log);
	ajax: SccAjaxService = new SccAjaxService(
		this.sccHost.ajax,
		this.loginUser.tenantId,
		this.mock,
		this.sccHost.i18n,
		this.sccHost.ui,
	);
	i18n: SccI18nService = new SccI18nService(this.sccHost.i18n);
	mtp: SccMTPService = new SccMTPService(this.resource);
	roles: SccRolesService = new SccRolesService(this.sccHost.auth ? this.auth : null, this.sccHost.resource ? this.resource : null);
	perf: SccPerfService = new SccPerfService(this.sccHost.perf)
	appLoadedOnce:boolean = false;

	get isSCC() {
		return !this.mtpGlobal.wdatpMode;
	}

	get isMTO() {
		return this.portalType === "mto";
	}

	get mtpGlobal(): MtpGlobal {
		if(!window.mdatp){
			window.mdatp = {}
		}
		return window.mdatp;
	}

	get endpoints() {
		return window.$host.endpoints;
	}

	inSccRole(role: SccRoles): boolean {
		return this.roles.inRole(role);
	}

	async inSccRoleAsync(role: SccRoles) {
		return this.roles.inRoleAsync(role);
	}

	getPackageBasePathByPkg(pkg: string = 'wdatp') {
		return window.require.toUrl(pkg);
	}

	getPackageVersionByPkg(pkg: string = 'wdatp') {
		return window.$host.resource.version(pkg);
	}
	getWicdResource<TPkg extends string, TResource extends string>(
		pkgName: TPkg,
		packageResource:TResource
	): Promise<PackageType<TPkg, TResource>> {
		return this.resource.load(`wicd-${pkgName}/${packageResource}`);
	}
}

export const sccHostService = new SccHostService(window.$host);
