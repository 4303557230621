var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { HuntingUserQuery } from '../hunting-user-query.entity';
var ɵ0 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ1 = function (query) { return ({ data: { QueryText: query.text } }); };
var UrlEncodeQueryApiCall = /** @class */ (function (_super) {
    __extends(UrlEncodeQueryApiCall, _super);
    function UrlEncodeQueryApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UrlEncodeQueryApiCall = __decorate([
        ApiCall({
            name: 'URL-encode hunting query text',
            endpoint: 'hunting/query/deeplink',
            method: 'POST',
            baseUrl: ɵ0,
            parseQuery: ɵ1,
        })
    ], UrlEncodeQueryApiCall);
    return UrlEncodeQueryApiCall;
}(ApiCallModel));
export { UrlEncodeQueryApiCall };
var ɵ2 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ3 = function (encodedQuery) { return ({ params: { query: encodedQuery } }); }, ɵ4 = function (data) { return new HuntingUserQuery({ id: undefined, text: data }); };
var UrlDecodeQueryApiCall = /** @class */ (function (_super) {
    __extends(UrlDecodeQueryApiCall, _super);
    function UrlDecodeQueryApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UrlDecodeQueryApiCall = __decorate([
        ApiCall({
            name: 'URL-decode hunting query text',
            endpoint: 'hunting/query/deeplink',
            method: 'GET',
            baseUrl: ɵ2,
            parseQuery: ɵ3,
            parseData: ɵ4,
        })
    ], UrlDecodeQueryApiCall);
    return UrlDecodeQueryApiCall;
}(ApiCallModel));
export { UrlDecodeQueryApiCall };
var ɵ5 = function (config) { return config.data.serviceUrls.huntingService; }, ɵ6 = function (queryText) { return ({ data: { QueryText: queryText } }); };
/* vNext api's
============================= */
/**
 * returns a URL-friendly base 64 encoded query
 * Can be used to generate share URL
 */
var EncodeQueryTextApiCall = /** @class */ (function (_super) {
    __extends(EncodeQueryTextApiCall, _super);
    function EncodeQueryTextApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EncodeQueryTextApiCall = __decorate([
        ApiCall({
            name: 'URL-encode hunting query text',
            endpoint: 'queries/encode',
            method: 'POST',
            baseUrl: ɵ5,
            parseQuery: ɵ6,
        })
    ], EncodeQueryTextApiCall);
    return EncodeQueryTextApiCall;
}(ApiCallModel));
export { EncodeQueryTextApiCall };
var ɵ7 = function (config) { return config.data.serviceUrls.huntingService; }, ɵ8 = function (encodedQuery) { return ({ params: { query: encodedQuery } }); }, ɵ9 = function (data) { return data; };
/**
 *  Returns decoded query string
 *  Can be used to parse query from share URL
 */
var DecodeQueryTextApiCall = /** @class */ (function (_super) {
    __extends(DecodeQueryTextApiCall, _super);
    function DecodeQueryTextApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DecodeQueryTextApiCall = __decorate([
        ApiCall({
            name: 'URL-decode hunting query text',
            endpoint: 'queries/decode',
            method: 'GET',
            baseUrl: ɵ7,
            parseQuery: ɵ8,
            parseData: ɵ9,
        })
    ], DecodeQueryTextApiCall);
    return DecodeQueryTextApiCall;
}(ApiCallModel));
export { DecodeQueryTextApiCall };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
