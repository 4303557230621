import { DataviewField } from '@wcd/dataview';
import { Injectable } from '@angular/core';
import { AssessmentJob } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class AssessmentJobFieldsService {
	private _fields: Array<DataviewField<AssessmentJob>>;

	constructor(private i18nService: I18nService) { }

	get fields(): Array<DataviewField<AssessmentJob>> {
		if (!this._fields) {
			this._fields = DataviewField.fromList<AssessmentJob>([
				{
					id: 'scanName',
					name: this.i18nService.get('tvm.networkScan.jobName'),
					sort: {
						enabled: false,
					},
				},
				{
					id: 'agentName',
					name: this.i18nService.get('tvm.networkGear.creationPanel.scanner'),
					sort: {
						enabled: false,
					},
				},
				{
					id: 'originalTargetRanges',
					name: this.i18nService.get('tvm.networkScan.target'),
					sort: {
						enabled: false,
					},
				},
				{
					id: 'createdBy',
					name: this.i18nService.get('tvm.common.createdBy'),
					sort: {
						enabled: false,
					},
				},
				{
					id: 'active',
					name: this.i18nService.get('tvm.networkScan.status'),
					getDisplay: (assessmentJob: AssessmentJob) =>
					assessmentJob.active
							? this.i18nService.get('tvm.networkGear.dataview.jobStatus.active')
							: this.i18nService.get('tvm.networkGear.dataview.jobStatus.inactive'),
					sort: {
						enabled: false,
					},
				},
				{
					id: 'lastScanStatus',
					name: this.i18nService.get('tvm.networkScan.lastRun.status'),
					sort: {
						enabled: false,
					},
				},
				{
					id: 'lastScanDate',
					name: this.i18nService.get('tvm.networkScan.lastRun.date'),
					sort: {
						enabled: false,
					},
				},
			]);
		}

		return this._fields;
	}
}
