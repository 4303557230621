var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { PanelContainer } from '@wcd/panels';
import { from, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { AlertLinksApiCall, IncidentsSearchApiCall } from '@wcd/domain';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { MtpPermission } from '@wcd/auth';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { flatMap, uniq } from 'lodash-es';
var SUGGESTIONS_COUNT = 6;
var AlertLinkToIncidentPanelComponent = /** @class */ (function (_super) {
    __extends(AlertLinkToIncidentPanelComponent, _super);
    function AlertLinkToIncidentPanelComponent(router, i18nService, dialogsService, paris) {
        var _this = _super.call(this, router) || this;
        _this.router = router;
        _this.i18nService = i18nService;
        _this.dialogsService = dialogsService;
        _this.paris = paris;
        _this.isSaving = false;
        _this.selectableActions = [
            { id: 'new', name: _this.i18nService.get('alerts.linkToIncident.actions.new') },
            { id: 'existing', name: _this.i18nService.get('alerts.linkToIncident.actions.existing') },
        ];
        _this.action = _this.selectableActions[0];
        _this.searchSettings = {
            showSuggestions: true,
            showValueOnSelect: true,
            highlightTermInResults: true,
        };
        _this.canDeactivate = _this.canDeactivate.bind(_this);
        _this.getSearchIncidentsMethod = _this.searchIncidents.bind(_this);
        return _this;
    }
    Object.defineProperty(AlertLinkToIncidentPanelComponent.prototype, "isValid", {
        get: function () {
            return (this.isNewAlert() ||
                (this.selectedIncidentId && !this.isCurrentIncidentSelected() && Boolean(this.comment)));
        },
        enumerable: true,
        configurable: true
    });
    AlertLinkToIncidentPanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        if (this.alerts && this.alerts[0] && this.alerts[0].incidentId) {
            this.currentIncidentId = this.alerts[0].incidentId;
            this.areLinkedToSameIncident = this.alerts.every(function (alert) { return alert.incidentId === _this.currentIncidentId; });
        }
        else
            this.areLinkedToSameIncident = false;
        this.currentLinkedMessage = this.i18nService.get(this.alerts && this.alerts.length > 1
            ? 'alerts.linkToIncident.currentLinkPlural'
            : 'alerts.linkToIncident.currentLinkSingle');
        var workloads = uniq(flatMap(this.alerts.map(function (alert) { return alert.mtpWorkloads; })));
        this.rbacSettings = {
            mtpPermissions: [MtpPermission.SecurityData_Manage],
            mtpWorkloads: workloads,
            requireAllPermissions: true,
            state: RbacControlState.disabled,
        };
    };
    AlertLinkToIncidentPanelComponent.prototype.setIncident = function (event) {
        this.selectedIncidentId = event.value;
    };
    AlertLinkToIncidentPanelComponent.prototype.clearIncident = function () {
        this.selectedIncidentId = null;
    };
    AlertLinkToIncidentPanelComponent.prototype.searchIncidents = function (term) {
        var _this = this;
        return this.paris.apiCall(IncidentsSearchApiCall, { term: term, pageSize: SUGGESTIONS_COUNT }).pipe(map(function (data) {
            return data &&
                data
                    .filter(function (incident) { return incident.incidentId !== _this.currentIncidentId; })
                    .map(function (incident) {
                    return {
                        value: incident.incidentId,
                        label: incident.name,
                    };
                });
        }));
    };
    AlertLinkToIncidentPanelComponent.prototype.submit = function () {
        var _this = this;
        this.isSaving = true;
        var isNew = this.isNewAlert();
        this.paris
            .apiCall(AlertLinksApiCall, {
            alertIds: this.alerts.map(function (alert) { return alert.id; }),
            incidentId: isNew ? null : this.selectedIncidentId,
            comment: this.comment,
        })
            .subscribe(function () {
            _this.isSaving = false;
            _this.destroy();
            _this.dialogsService.showSuccessSnackbar({
                text: _this.i18nService.get(isNew
                    ? 'alerts.linkToIncident.results.new'
                    : 'alerts.linkToIncident.results.existing'),
            });
        }, function (error) {
            _this.isSaving = false;
            _this.dialogsService.showError({
                title: _this.i18nService.get('alerts.linkToIncident.failure'),
                data: error,
            });
        });
    };
    AlertLinkToIncidentPanelComponent.prototype.canDeactivate = function () {
        if (this.linkForm.form.dirty) {
            return from(this.dialogsService.confirm({
                title: this.i18nService.get('discardChanges'),
                text: this.i18nService.get('discardMessage.singular', {
                    itemName: 'alert',
                }),
                confirmText: this.i18nService.get('buttons.discard'),
            })).pipe(map(function (e) { return e.confirmed; }));
        }
        else {
            return of(true);
        }
    };
    AlertLinkToIncidentPanelComponent.prototype.isNewAlert = function () {
        return this.action.id === 'new';
    };
    AlertLinkToIncidentPanelComponent.prototype.isCurrentIncidentSelected = function () {
        return this.selectedIncidentId && this.currentIncidentId === this.selectedIncidentId;
    };
    return AlertLinkToIncidentPanelComponent;
}(PanelContainer));
export { AlertLinkToIncidentPanelComponent };
