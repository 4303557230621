import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { I18nModule } from '@wcd/i18n';
import { FabMessageBarModule, FabSpinnerModule } from '@angular-react/fabric';
import { NetworkScansDataviewComponent } from './components/network-scans.dataview.component';
import { DataViewsModule } from '../../../dataviews/dataviews.module';
import { NetworkScansEntityTypeModule } from './network-scans.entity-type.module';
import { NetworkDevicesEntityTypeModule } from '../network-devices/network-devices.entity-type.module';

@NgModule({
	imports: [
		SharedModule,
		I18nModule,
		FabSpinnerModule,
		DataViewsModule,
		NetworkScansEntityTypeModule,
		NetworkDevicesEntityTypeModule,
		FabMessageBarModule,
	],
	declarations: [NetworkScansDataviewComponent],
	exports: [],
})
export class NetworkScansModule {}
