import { Injectable } from '@angular/core';
import { FeaturesService, Feature } from '@wcd/config';
import { BaselineConfiguration, BaselineConfigurationDevice } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { sccHostService } from '@wcd/scc-interface';

@Injectable()
export class BaselineDetectedValuesService {
	private isInternalValidationTenant: boolean;
	private isDetectedValueEnabled: boolean;
	private validationTenants = new Set([
		"f839b112-d9d7-4d27-9bf6-94542403f21c", // WcdTestPrd
		"1994c9e0-8d9e-48c9-94c8-02fef5d62a37", // Timna Canary
		"35d268b0-681b-499c-bf74-616b722eb9e1", // Igal's validation tenant
		"f686d426-8d16-42db-81b7-ab578e110ccd", // Microsoft-Staging
	]);
	constructor(
		featuresService: FeaturesService,
		private i18nService: I18nService,
	) {
		this.isDetectedValueEnabled = featuresService.isEnabled(Feature.TvmBaselineDetectedValue);
		this.isInternalValidationTenant = this.validationTenants.has(sccHostService.loginUser.tenantId);
	}

	isConfigurationSupported = (configuration: BaselineConfiguration | BaselineConfigurationDevice) =>
		(configuration.sourceValues && configuration.sourceValues.length && configuration.sourceValues[0] !== "Unsupported") && (this.isInternalValidationTenant || this.isDetectedValueEnabled);

	getConfigurationDetectedValuesString(configuration: BaselineConfiguration | BaselineConfigurationDevice): string {
		if (!this.isConfigurationSupported(configuration)) {
			return null;
		}
		if (!configuration.detectedValues || configuration.detectedValues.length === 0) {
			return this.i18nService.strings.notAvailable_long;
		}

		if (configuration.detectedValues.length === 1 || !configuration.sourceValues || configuration.sourceValues.length === 0) {
			return configuration.detectedValues[0];
		}

		const firstSource = configuration.sourceValues[0];
		const allTheSame = configuration.sourceValues.every(s => s === firstSource);

		return allTheSame ? configuration.detectedValues[0] : configuration.detectedValues.join(" | ");
	}

	getConfigurationUniqueSources(configuration: BaselineConfiguration | BaselineConfigurationDevice): string[] {
		if (!this.isConfigurationSupported(configuration)) {
			return null;
		}
		if (!configuration.sourceValues || configuration.sourceValues.length === 0) {
			return [this.i18nService.strings.notAvailable_long];
		}
		if (configuration.sourceValues.length === 1) {
			return [configuration.sourceValues[0]];
		}
		const set = new Set();
		const res = [];

		configuration.sourceValues.forEach(s => !set.has(s) && set.add(s) && res.push(s));
		return res
	}
}
