var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType } from '@microsoft/paris';
import { File } from '../file.entity';
import { FileStats } from '../file-stats.value-object';
var ɵ0 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ1 = function (file) { return (file.sha1 ? { sha1: file.sha1 } : { sha256: file.sha256 }); };
var FileFileStatsRelationship = /** @class */ (function () {
    function FileFileStatsRelationship() {
    }
    FileFileStatsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: File,
            dataEntity: FileStats,
            allowedTypes: [RelationshipType.OneToOne],
            baseUrl: ɵ0,
            endpoint: 'FileStats',
            getRelationshipData: ɵ1,
            cache: {
                max: 5,
                time: 5 * 60 * 1000,
            },
            timeout: 2 * 60 * 1000,
        })
    ], FileFileStatsRelationship);
    return FileFileStatsRelationship;
}());
export { FileFileStatsRelationship };
export { ɵ0, ɵ1 };
