var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { I18nService } from '@wcd/i18n';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { AccessTokenModificationDetails, CommandLine, CyberEventActionTypeName, CyberEventEntityRelationTypeEnum, Email, File, LegacyUser, NetworkEndpoint, Process, Registry, RegistryModificationDetails, ScheduledTask, Script, TagType, UsbEvent, } from '@wcd/domain';
import { BrowserFreeKnownProcessesService } from './browser-free-known-processes.service';
import { CyberEventsTagsService } from './cyber-events-tags.service';
import { LogonTypePipe } from '../../machines/pipes/logon-type.pipe';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { IconsService } from '@wcd/icons';
import { memoize } from 'lodash-es';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { Feature, FeaturesService } from '@wcd/config';
import { Paris } from '@microsoft/paris';
import { tap } from 'rxjs/operators';
import { MdatpMitreService } from '@wcd/scc-common';
var CyberEventsActionTypesService = /** @class */ (function () {
    function CyberEventsActionTypesService(globalEntityTypesService, cyberEventsTagsService, i18nService, appInsightsService, logonTypePipe, iconsService, entityPanelsService, featuresService, paris) {
        var _this = this;
        this.globalEntityTypesService = globalEntityTypesService;
        this.cyberEventsTagsService = cyberEventsTagsService;
        this.i18nService = i18nService;
        this.appInsightsService = appInsightsService;
        this.logonTypePipe = logonTypePipe;
        this.iconsService = iconsService;
        this.entityPanelsService = entityPanelsService;
        this.featuresService = featuresService;
        this.paris = paris;
        /**
         * contains the list of available event action types and action groups configs.
         * the keys should be action type name or action type group name
         * each action type has different description, entities list, icon, etc.
         * default is to get the details for an event by its action type. if action type is not found, get the details by its group type.
         * @private
         */
        this._eventActionTypes = {
            alert: this.alertConfig(),
            allocateMemoryGroup: this.allocateMemoryGroupConfig(),
            amsiScriptDetection: this.amsiScriptDetectionConfig(),
            antivirusDetection: this.antivirusDetectionConfig(),
            antivirusEventGroup: this.antivirusEventGroupConfig(),
            appControlGroup: this.appControlGroupConfig(),
            appGuardContainerGroup: this.appGuardContainerGroupConfig(),
            appGuardLaunchedWithUrl: this.appGuardLaunchedWithUrlConfig(),
            browserLaunchedToOpenUrl: this.browserLaunchedToOpenUrlConfig(),
            connectionEventGroup: this.connectionEventGroupConfig(),
            exploitGuardAsrGroup: this.exploitGuardAsrGroupConfig(),
            exploitGuardGroup: this.exploitGuardGroupConfig(),
            exploitGuardNetworkProtectionGroup: this.exploitGuardNetworkProtectionGroupConfig(),
            fileCreated: this.fileCreatedConfig(),
            fileEventGroup: this.fileEventGroupConfig(),
            firewallConnectionBlockedGroup: this.firewallConnectionBlockedGroupConfig(),
            firewallInboundConnectionToAppBlocked: this.firewallInboundConnectionToAppBlockedConfig(),
            huntingRecordGroup: this.huntingRecordGroupConfig(),
            imageLoaded: this.imageLoadedConfig(),
            logonEventGroup: this.logonEventGroupConfig(),
            oneCyber: this.oneCyberConfig(),
            openProcessApiCall: this.openProcessApiCallConfig(),
            otherAlertRelatedActivity: this.otherAlertRelatedActivityConfig(),
            passwordChangeAttempt: this.passwordChangeAttemptConfig(),
            powerShellCommand: this.powerShellCommandConfig(),
            processCreatedGroup: this.processCreatedGroupConfig(),
            processInjectionGroup: this.processInjectionGroupConfig(),
            processPrimaryTokenModified: this.processPrimaryTokenModifiedConfig(),
            registryEventGroup: this.registryEventGroupConfig(),
            scheduledTaskGroup: this.scheduledTaskGroupConfig(),
            screenshotTaken: this.screenshotTakenConfig(),
            shellLinkCreateFileEvent: this.shellLinkCreateFileEventConfig(),
            smartScreenExploitWarning: this.smartScreenExploitWarningConfig(),
            smartScreenGroup: this.smartScreenGroupConfig(),
            usbDriveDriveLetterChanged: this.usbDriveDriveLetterChangedConfig(),
            usbDriveMount: this.usbDriveMountConfig(),
            usbDriveUnmount: this.usbDriveUnmountConfig(),
            usbDriveMounted: this.usbDriveMountedConfig(),
            usbDriveUnmounted: this.usbDriveUnmountedConfig(),
            usbEventGroup: this.usbEventGroupConfig(),
            userAccountCreated: this.userAccountCreatedConfig(),
            userToUserActivityGroup: this.userToUserActivityGroupConfig(),
        };
        this.isCyberEventSupportedByProcessTree = function (cyberEvent) {
            return Boolean(cyberEvent &&
                cyberEvent.actionType &&
                (_this._eventActionTypes[cyberEvent.actionType.typeName] ||
                    _this._eventActionTypes[cyberEvent.actionType.groupTypeName]));
        };
        this.isOneCyberEvent = function (cyberEvent) {
            return Boolean(cyberEvent &&
                cyberEvent.actionType &&
                cyberEvent.actionType.id === CyberEventActionTypeName.OneCyber);
        };
        var methods = [
            this.getEventDescription,
            this.getSourceName,
            this.getTargetName,
            this.getEventEntities,
            this.getDefaultSourceEntities,
            this.getEventEntityRelationDescription,
            this.getEventIcon,
            this.getEventTags,
        ];
        methods.map(function (method) { return memoize(method); });
    }
    /**
     * get action type of event.
     * if the event is oneCyber event, take the action type from CyberEventEntity, otherwise return the actionType
     * @param event
     */
    CyberEventsActionTypesService.prototype.getActionType = function (event) {
        if (event.actionType) {
            if (this.isOneCyberEvent(event) && event.cyberActionType) {
                // New flow - one cyber data with a new format
                return event.cyberActionType.typeName;
            }
            return event.actionType.id;
        }
        return event.rawActionType;
    };
    /**
     * get text description of event.
     * default: get text by event action type. if not found, get text by event group type. if not found, get string template for the event default entities.
     * @param {CyberEvent} event
     * @returns {string} event description text
     */
    CyberEventsActionTypesService.prototype.getEventDescription = function (event) {
        if (!event)
            throw new Error("Can't get event description. Event details are missing.");
        if (!event.actionType) {
            return this.handleMissingText(event);
        }
        if (this._eventActionTypes[event.actionType.typeName] &&
            this._eventActionTypes[event.actionType.typeName].getDescription)
            return this._eventActionTypes[event.actionType.typeName].getDescription(event);
        if (this._eventActionTypes[event.actionType.groupTypeName] &&
            this._eventActionTypes[event.actionType.groupTypeName].getDescription)
            return this._eventActionTypes[event.actionType.groupTypeName].getDescription(event);
        return (this.i18nService.get("events.actionTypes." + event.actionType.typeName + ".description", {
            source: this.getSourceName(event),
            target: this.getTargetName(event),
        }, true) || this.handleMissingText(event));
    };
    /**
     * gets default source Process entity name. if not found returns 'Unknown process' string.
     * @param {CyberEvent} event
     * @returns {string} initiating process name
     */
    CyberEventsActionTypesService.prototype.getSourceName = function (event) {
        if (!event)
            throw new Error("Can't get event source name. Event details are missing.");
        return event.initiatingProcess
            ? this.globalEntityTypesService.getEntityType(Process).getEntityName(event.initiatingProcess)
            : this.i18nService.get('events.entities.unknownProcess');
    };
    /**
     * gets default target Process\File entity name. if not found returns null.
     * @param {CyberEvent} event
     * @returns {string} target process\file name
     */
    CyberEventsActionTypesService.prototype.getTargetName = function (event) {
        if (!event)
            throw new Error("Can't get event target name. Event details are missing.");
        if (event.process)
            return this.globalEntityTypesService.getEntityType(Process).getEntityName(event.process);
        if (event.file)
            return this.globalEntityTypesService.getEntityType(File).getEntityName(event.file);
        return null;
    };
    /**
     * gets event's entities array by action type or action group type.
     * default: ["sourceParentParent", "sourceParent", "source", "target"].
     * @param {CyberEvent} event
     * @returns {Array<CyberEventEntityDisplay>} event entities with extended display details
     */
    CyberEventsActionTypesService.prototype.getEventEntities = function (event) {
        var _this = this;
        return this.getEntities(event).map(function (eventEntity) {
            var entityType = _this.globalEntityTypesService.getEntityType(eventEntity.entityType);
            var icon = entityType
                ? entityType.getIcon
                    ? entityType.getIcon([eventEntity.item])
                    : entityType.icon
                        ? entityType.icon
                        : null
                : null;
            var name = entityType
                ? entityType.getEntityName
                    ? entityType.getEntityName(eventEntity.item)
                    : eventEntity.item['name'] || entityType.entity.singularName
                : eventEntity.item['name'] || eventEntity.item.id || eventEntity.entityType.singularName;
            return __assign({}, eventEntity, { icon: icon,
                name: name });
        });
    };
    /**
     * gets initiating process and initiating process parent process if exists.
     * @param {CyberEvent} event
     * @returns {Array<CyberEventEntity>} event source parent and event source
     */
    CyberEventsActionTypesService.prototype.getDefaultSourceEntities = function (event) {
        return [event.sourceParentParent, event.sourceParent, event.source].filter(Boolean);
    };
    /**
     * gets a relation description text for an entity and its preceding entity in an event.
     * @param {CyberEvent} event
     * @param {CyberEventEntityDisplay} entity
     * @returns {string} entity relation text (for example, "failed to connect to: ")
     */
    CyberEventsActionTypesService.prototype.getEventEntityRelationDescription = function (event, entity) {
        if (!event || !entity)
            throw new Error("Can't get entity description. Details are missing.");
        if (!event.actionType)
            return '[Unknown action type]';
        if (this._eventActionTypes[event.actionType.typeName] &&
            this._eventActionTypes[event.actionType.typeName].getEntityRelationDescription)
            return this._eventActionTypes[event.actionType.typeName].getEntityRelationDescription(event, entity);
        if (this._eventActionTypes[event.actionType.groupTypeName] &&
            this._eventActionTypes[event.actionType.groupTypeName].getEntityRelationDescription)
            return this._eventActionTypes[event.actionType.groupTypeName].getEntityRelationDescription(event, entity);
        return null;
    };
    /**
     * gets event's icon name by action type or action group type.
     * @param {CyberEvent} event
     * @returns {string} event icon name
     */
    CyberEventsActionTypesService.prototype.getEventIcon = function (event) {
        if (!event || !event.actionType)
            return null;
        var iconName = null;
        if (this._eventActionTypes[event.actionType.typeName] &&
            this._eventActionTypes[event.actionType.typeName].getIcon)
            iconName = this._eventActionTypes[event.actionType.typeName].getIcon(event);
        else if (this._eventActionTypes[event.actionType.groupTypeName] &&
            this._eventActionTypes[event.actionType.groupTypeName].getIcon)
            iconName = this._eventActionTypes[event.actionType.groupTypeName].getIcon(event);
        if (iconName) {
            var icon = this.iconsService.getIcon(iconName);
            if (icon)
                iconName = icon.name;
        }
        return iconName;
    };
    /**
     * gets event's tags using backend tags and client side logic tags
     * @param {CyberEvent} event
     * @returns (Array<Tag>) event's tags
     */
    CyberEventsActionTypesService.prototype.getEventTags = function (event) {
        var tags = event.tags;
        tags.map(function (tag) {
            if (tag.type && tag.type == TagType.mitre) {
                var mitreTagName = MdatpMitreService.getMitreTechniqueName(tag.id);
                tag.name = mitreTagName ? mitreTagName : tag.id;
            }
            return tag;
        });
        return this.cyberEventsTagsService.getEventAdditionslTags(event).concat(tags);
    };
    /**
     * gets actions available for the event
     * @param {CyberEvent} event
     * @returns {Array<ItemActionModel>} the available actions
     */
    CyberEventsActionTypesService.prototype.getEventActions = function (event) {
        if (!event || !event.actionType)
            return null;
        if (this._eventActionTypes[event.actionType.typeName] &&
            this._eventActionTypes[event.actionType.typeName].getActions) {
            return this._eventActionTypes[event.actionType.typeName].getActions(event);
        }
        else if (this._eventActionTypes[event.actionType.groupTypeName] &&
            this._eventActionTypes[event.actionType.groupTypeName].getActions) {
            return this._eventActionTypes[event.actionType.groupTypeName].getActions(event);
        }
        return null;
    };
    /**
     * gets action name out of action type
     * @param {CyberEventActionType} actionType
     * @returns {string} action name ('audited' or 'blocked')
     * @private
     */
    CyberEventsActionTypesService.prototype.getAuditOrBlocked = function (actionType) {
        return this.i18nService.get("events.actions." + (actionType.id.includes('Audit') ? 'audited' : 'blocked'));
    };
    /**
     * gets event's entities by action type or action group type
     * @private
     */
    CyberEventsActionTypesService.prototype.getEntities = function (event) {
        if (!event || !event.actionType)
            return [];
        if (this._eventActionTypes[event.actionType.typeName] &&
            this._eventActionTypes[event.actionType.typeName].getEntities)
            return this._eventActionTypes[event.actionType.typeName].getEntities(event);
        if (this._eventActionTypes[event.actionType.groupTypeName] &&
            this._eventActionTypes[event.actionType.groupTypeName].getEntities)
            return this._eventActionTypes[event.actionType.groupTypeName].getEntities(event);
        else
            return this.getDefaultSourceEntities(event).concat([event.target]).filter(Boolean);
    };
    /**
     * tracks missing action types and returns a default text
     * @param {CyberEvent} event
     * @returns {string}
     */
    CyberEventsActionTypesService.prototype.handleMissingText = function (event) {
        return this.i18nService.get('events.entities.unknownActionType', { actionType: event.rawActionType });
    };
    // action types and groups configs
    CyberEventsActionTypesService.prototype.alertConfig = function () {
        return {
            getDescription: function (event) {
                return event.relatedAlert && event.relatedAlert.name;
            },
            getEntities: function (event) { return []; },
            getIcon: function (event) { return 'alert'; },
        };
    };
    CyberEventsActionTypesService.prototype.allocateMemoryGroupConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var source = _this.getSourceName(event);
                return _this.i18nService.get('events.actionTypesGroups.allocateMemoryGroup.description', {
                    source: source,
                });
            },
            getIcon: function (event) { return 'memory'; },
        };
    };
    CyberEventsActionTypesService.prototype.amsiScriptDetectionConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var source = _this.getSourceName(event);
                return _this.i18nService.get('events.actionTypes.amsiScriptDetection.description', { source: source });
            },
            getEntities: function (event) { return _this.getDefaultSourceEntities(event); },
            getActions: function (event) {
                return _this.featuresService.isEnabled(Feature.AmsiScriptDetection)
                    ? [
                        new ItemActionModel({
                            id: 'viewScript',
                            nameKey: 'events.actionTypes.amsiScriptDetection.viewScript',
                            refreshOnResolve: false,
                            method: function (events) {
                                return _this.paris
                                    .createItem(Script, {
                                    Sha1: event.file.sha1,
                                    Sha256: event.file.sha256,
                                })
                                    .pipe(tap(function (script) {
                                    return _this.entityPanelsService.showEntity(Script, script, {
                                        actionTime: event.actionTime,
                                    });
                                }))
                                    .toPromise();
                            },
                        }),
                    ]
                    : [];
            },
        };
    };
    CyberEventsActionTypesService.prototype.antivirusDetectionConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var target = _this.getTargetName(event), threat = (event.additionalFields && event.additionalFields.threatName) ||
                    _this.i18nService.get('events.entities.unknownThreat').toLowerCase(), detectionSource = (event.additionalFields &&
                    event.additionalFields.detectionSource &&
                    _this.i18nService.get(event.additionalFields.detectionSource.nameI18nKey)) ||
                    _this.i18nService.get('events.entities.defaultDetectionSource');
                if (target)
                    return _this.i18nService.get('events.actionTypes.antivirusDetection.description', {
                        threat: threat,
                        target: target,
                        detectionSource: detectionSource,
                    });
                else
                    return _this.i18nService.get('events.actionTypes.antivirusDetection.descriptionNoFile', {
                        threat: threat,
                        detectionSource: detectionSource,
                    });
            },
        };
    };
    CyberEventsActionTypesService.prototype.antivirusEventGroupConfig = function () {
        return {
            getIcon: function (event) { return 'malwareOutline'; },
        };
    };
    CyberEventsActionTypesService.prototype.appControlGroupConfig = function () {
        return {
            getIcon: function (event) { return 'events.mixedSource'; },
        };
    };
    CyberEventsActionTypesService.prototype.appGuardContainerGroupConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var containerId = event.containerId, action = _this.i18nService.get("events.actionTypesGroups.appGuardContainerGroup.actions." + event.actionType.typeName);
                return _this.i18nService.get('events.actionTypesGroups.appGuardContainerGroup.description', {
                    containerId: containerId,
                    action: action,
                });
            },
            getIcon: function (event) { return 'events.mixedSource'; },
        };
    };
    CyberEventsActionTypesService.prototype.appGuardLaunchedWithUrlConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var containerId = event.containerId;
                return _this.i18nService.get('events.actionTypes.appGuardLaunchedWithUrl.description', {
                    containerId: containerId,
                });
            },
            getIcon: function (event) { return 'events.mixedSource'; },
        };
    };
    CyberEventsActionTypesService.prototype.browserLaunchedToOpenUrlConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                if (event.initiatingProcess) {
                    if (BrowserFreeKnownProcessesService.isKnownProcess(event.initiatingProcess.name)) {
                        // These known processes don't open links in the browser, so we know it was clicked by the user
                        return _this.i18nService.get('events.actionTypes.browserLaunchedToOpenUrl.description');
                    }
                    // Unknown process - we don't know if url was opened due to a user click or not, so use more vague terms
                    else
                        return _this.i18nService.get('events.actionTypes.browserLaunchedToOpenUrl.descriptionProcess', {
                            source: _this.getSourceName(event),
                        });
                }
                // With lack of process details, default to a user-friendly description, even if not 100% accurate.
                return _this.i18nService.get('events.actionTypes.browserLaunchedToOpenUrl.description');
            },
            getEntities: function (event) {
                var entities = _this.getDefaultSourceEntities(event);
                return entities.concat((event.remoteEndpoint
                    ? [
                        {
                            id: event.id || '',
                            item: event.remoteEndpoint,
                            entityType: NetworkEndpoint,
                            relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
                            depth: entities.length - 1,
                        },
                    ]
                    : []));
            },
            getEntityRelationDescription: function (event, entity) {
                var isProcess = !event.initiatingProcess ||
                    BrowserFreeKnownProcessesService.isKnownProcess(event.initiatingProcess.name);
                return _this.i18nService.get("events.actionTypes.browserLaunchedToOpenUrl.entityRelationText" + (isProcess ? 'Process' : ''));
            },
            getIcon: function (event) { return 'link'; },
        };
    };
    CyberEventsActionTypesService.prototype.connectionEventGroupConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var source = _this.getSourceName(event), remote = event.remoteEndpoint && event.remoteEndpoint.name;
                return _this.i18nService.get("events.actionTypes." + event.actionType.typeName + ".description", {
                    source: source,
                    remote: remote,
                });
            },
            getEntities: function (event) {
                var entities = _this.getDefaultSourceEntities(event);
                return entities.concat((event.remoteEndpoint
                    ? [
                        {
                            id: event.id || '',
                            item: event.remoteEndpoint,
                            entityType: NetworkEndpoint,
                            relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
                            depth: entities.length - 1,
                        },
                    ]
                    : []));
            },
            getEntityRelationDescription: function (event, entity) {
                return _this.i18nService.get("events.actionTypes." + event.actionType.typeName + ".entityRelationText");
            },
            getIcon: function (event) { return 'entities.ipaddress'; },
        };
    };
    CyberEventsActionTypesService.prototype.exploitGuardAsrGroupConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var source = _this.getSourceName(event), target = _this.getTargetName(event), action = _this.getAuditOrBlocked(event.actionType);
                if (event.additionalFields && event.additionalFields.exploitGuardInnerRule) {
                    var text = _this.i18nService.get("events.actionTypesGroups.exploitGuardAsrGroup.enforcedActions." + event.additionalFields.exploitGuardInnerRule.name, { source: source, target: target, action: action }, true);
                    if (!text) {
                        console.warn('Exploit guard ASR inner rule has no text description', event);
                        var ruleId = event.additionalFields.exploitGuardInnerRule.id, item = target || source;
                        text = _this.i18nService.get('events.actionTypesGroups.exploitGuardAsrGroup.description', {
                            item: item,
                            action: action,
                            ruleId: ruleId,
                        });
                    }
                    return text;
                }
                else
                    throw new Error('Exploit guard ASR event is missing inner rule id');
            },
            getIcon: function (event) { return 'events.mixedSource'; },
        };
    };
    CyberEventsActionTypesService.prototype.exploitGuardGroupConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var target = _this.getTargetName(event) || _this.i18nService.get('events.entities.unknownProcess'), action = _this.getAuditOrBlocked(event.actionType), enforcedAction = _this.i18nService.get("events.actionTypesGroups.exploitGuardGroup.enforcedActions." + event.actionType.subGroupTypeName);
                return _this.i18nService.get('events.actionTypesGroups.exploitGuardGroup.description', {
                    target: target,
                    action: action,
                    enforcedAction: enforcedAction,
                });
            },
            getIcon: function (event) { return 'events.mixedSource'; },
        };
    };
    CyberEventsActionTypesService.prototype.exploitGuardNetworkProtectionGroupConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var remote = event.remoteEndpoint && event.remoteEndpoint.name, action = _this.getAuditOrBlocked(event.actionType), category = event.additionalFields.responseCategory;
                return category
                    ? _this.i18nService.get('events.actionTypesGroups.exploitGuardNetworkProtectionGroup.descriptionWithCategory', {
                        remote: remote,
                        action: action,
                        category: category,
                    })
                    : _this.i18nService.get('events.actionTypesGroups.exploitGuardNetworkProtectionGroup.description', {
                        remote: remote,
                        action: action,
                    });
            },
            getIcon: function (event) { return 'events.mixedSource'; },
        };
    };
    CyberEventsActionTypesService.prototype.fileCreatedConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var source = _this.getSourceName(event), target = _this.getTargetName(event);
                if (event.email) {
                    return _this.i18nService.get("events.actionTypes.fileCreated.outlookSavedAttachment.description", {
                        source: source,
                    });
                }
                else if (event.fileOrigin) {
                    var downloadAddress = event.fileOrigin.name;
                    return _this.i18nService.get('events.actionTypes.fileCreated.descriptionDownloaded', {
                        source: source,
                        target: target,
                        downloadAddress: downloadAddress,
                    });
                }
                else {
                    return _this.i18nService.get("events.actionTypes.fileCreated.description" + (event.fileOriginReferer ? 'Extracted' : 'Created'), { source: source, target: target });
                }
            },
            getEntities: function (event) {
                var entities = _this.getDefaultSourceEntities(event);
                if (event.email) {
                    return entities.concat((event.email
                        ? [
                            {
                                id: event.id || '',
                                item: event.email,
                                entityType: Email,
                                relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Child,
                                depth: entities.length,
                            },
                        ]
                        : []), (event.file
                        ? [
                            {
                                id: event.id || '',
                                item: event.file,
                                entityType: File,
                                relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
                                depth: entities.length,
                            },
                        ]
                        : []));
                }
                else {
                    return entities.concat((event.file
                        ? [
                            {
                                id: event.id || '',
                                item: event.file,
                                entityType: File,
                                relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
                                depth: entities.length - 1,
                            },
                        ]
                        : []), (event.fileOrigin
                        ? [
                            {
                                id: event.id || '',
                                item: event.fileOrigin,
                                entityType: NetworkEndpoint,
                                relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
                                depth: entities.length - 1,
                            },
                        ]
                        : []));
                }
            },
            getEntityRelationDescription: function (event, entity) {
                if (event.email) {
                    if (event.file) {
                        return _this.i18nService.get('events.actionTypes.fileCreated.outlookSavedAttachment.entityRelationText');
                    }
                    else {
                        return undefined;
                    }
                }
                else {
                    return _this.i18nService.get("events.actionTypes.fileCreated.entityRelationText" + (entity.entityType === File ? 'File' : 'Downloaded'));
                }
            },
            getIcon: function (event) {
                if (event.email) {
                    return 'email';
                }
                else {
                    return 'events.fileCreated';
                }
            },
        };
    };
    CyberEventsActionTypesService.prototype.fileEventGroupConfig = function () {
        return {
            getIcon: function (event) { return 'entities.file'; },
        };
    };
    CyberEventsActionTypesService.prototype.firewallConnectionBlockedGroupConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var source = _this.getSourceName(event);
                var remoteAddress = event.remoteEndpoint && event.remoteEndpoint.ip && event.remoteEndpoint.ip.id;
                if (remoteAddress) {
                    var remotePort = event.remoteEndpoint && event.remoteEndpoint.port;
                    if (remotePort) {
                        remoteAddress += ":" + remotePort;
                    }
                    return _this.i18nService.get('events.actionTypesGroups.firewallConnectionBlockedGroup.description', {
                        source: source,
                        remote: remoteAddress,
                    });
                }
                else
                    return _this.i18nService.get('events.actionTypesGroups.firewallConnectionBlockedGroup.descriptionNoRemote', {
                        source: source,
                    });
            },
            getIcon: function (event) { return 'services.windowsfirewall'; },
        };
    };
    CyberEventsActionTypesService.prototype.firewallInboundConnectionToAppBlockedConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var source = _this.getSourceName(event);
                var profiles;
                var isMultiple;
                if (event.additionalFields && event.additionalFields.firewallProfiles) {
                    profiles = event.additionalFields.firewallProfiles.join(', ');
                    isMultiple = event.additionalFields.firewallProfiles.length > 1;
                }
                var key = 'events.actionTypes.firewallInboundConnectionToAppBlocked.description' + isMultiple
                    ? '_multiple'
                    : '';
                return _this.i18nService.get(key, {
                    source: source,
                    profiles: profiles,
                });
            },
            getIcon: function (event) { return 'services.windowsfirewall'; },
        };
    };
    CyberEventsActionTypesService.prototype.huntingRecordGroupConfig = function () {
        return {};
    };
    CyberEventsActionTypesService.prototype.imageLoadedConfig = function () {
        var _this = this;
        return {
            getEntities: function (event) {
                var entities = _this.getDefaultSourceEntities(event);
                return entities.concat((event.file
                    ? [
                        {
                            id: event.id || '',
                            item: event.file,
                            entityType: File,
                            relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
                            depth: entities.length - 1,
                        },
                    ]
                    : []));
            },
            getEntityRelationDescription: function (event, entity) {
                return _this.i18nService.get('events.actionTypes.imageLoaded.entityRelationText');
            },
            getIcon: function (event) { return 'entities.module'; },
        };
    };
    CyberEventsActionTypesService.prototype.logonEventGroupConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var user = event.user && event.user.fullName, remote = (event.remoteMachine && event.remoteMachine.name) ||
                    (event.remoteEndpoint && event.remoteEndpoint.name), logon = _this.logonTypePipe.transform(event.logonType, true), status = _this.i18nService.get("events.actionTypesGroups.logonEventGroup." + (event.actionType.id === CyberEventActionTypeName.LogonSuccess ? 'success' : 'fail'));
                if (user && remote)
                    return _this.i18nService.get('events.actionTypesGroups.logonEventGroup.descriptionUserRemote', {
                        logon: logon,
                        user: user,
                        remote: remote,
                        status: status,
                    });
                else if (user)
                    return _this.i18nService.get('events.actionTypesGroups.logonEventGroup.descriptionUser', {
                        logon: logon,
                        user: user,
                        status: status,
                    });
                else if (remote) {
                    return _this.i18nService.get('events.actionTypesGroups.logonEventGroup.descriptionRemote', {
                        logon: logon,
                        remote: remote,
                        status: status,
                    });
                }
                else
                    return _this.i18nService.get('events.actionTypesGroups.logonEventGroup.description', {
                        logon: logon,
                        status: status,
                    });
            },
            getEntities: function (event) {
                return (event.user
                    ? [
                        {
                            id: event.id || '',
                            item: event.user,
                            entityType: LegacyUser,
                            relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
                            depth: 0,
                        },
                    ]
                    : []).concat((event.remoteEndpoint
                    ? [
                        {
                            id: event.id || '',
                            item: event.remoteEndpoint,
                            entityType: NetworkEndpoint,
                            relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
                            depth: 0,
                        },
                    ]
                    : []));
            },
            getEntityRelationDescription: function (event, entity) {
                return entity.entityType === LegacyUser
                    ? _this.i18nService.get("events.actionTypesGroups.logonEventGroup.entityRelationText" + (event.actionType.id === CyberEventActionTypeName.LogonSuccess
                        ? 'Success'
                        : 'Fail'))
                    : _this.i18nService.get("events.actionTypesGroups.logonEventGroup.entityRelationTextRemote");
            },
            getIcon: function (event) { return 'events.logon'; },
        };
    };
    CyberEventsActionTypesService.prototype.oneCyberConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) { return event.description; },
            getEntities: function (event) {
                var entities = new Array();
                var _loop_1 = function (entity) {
                    var entityType = _this.globalEntityTypesService.getEntityType(entity.entityType).entity;
                    if (entityType === Registry) {
                        _this.paris
                            .createItem(RegistryModificationDetails, { Registry: entity.item })
                            .subscribe(function (item) {
                            return _this.pushCyberEventItemToEntities(RegistryModificationDetails, entity, item, entities);
                        });
                    }
                    else {
                        _this.paris
                            .createItem(entityType, entity.item)
                            .subscribe(function (item) {
                            return _this.pushCyberEventItemToEntities(entityType, entity, item, entities);
                        });
                    }
                };
                for (var _i = 0, _a = event.entities; _i < _a.length; _i++) {
                    var entity = _a[_i];
                    _loop_1(entity);
                }
                return entities;
            },
            getEntityRelationDescription: function (event, entity) {
                return entity.relationDescription;
            },
            getIcon: function (event) { return event.icon; },
        };
    };
    CyberEventsActionTypesService.prototype.pushCyberEventItemToEntities = function (entityType, entity, parisItem, entities) {
        entities.push({
            id: parisItem.id || '',
            item: parisItem,
            entityType: entityType,
            relationToPrecedingEntity: entity.relationToPrecedingEntity,
            relationDescription: entity.relationDescription,
            depth: entity.depth,
            isCollapsed: entity.isCollapsed,
            genericFields: entity.genericFields,
        });
    };
    CyberEventsActionTypesService.prototype.openProcessApiCallConfig = function () {
        var _this = this;
        return {
            getEntities: function (event) {
                var entities = _this.getDefaultSourceEntities(event);
                return (entities && entities.length
                    ? entities
                    : [
                        {
                            id: '',
                            item: null,
                            entityType: Process,
                            depth: 0,
                        },
                    ]).concat((event.process
                    ? [
                        {
                            id: event.id || '',
                            item: event.process,
                            entityType: Process,
                            relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
                            depth: entities && entities.length ? entities.length - 1 : 0,
                        },
                    ]
                    : []));
            },
            getEntityRelationDescription: function (event, entity) {
                return _this.i18nService.get('events.actionTypes.openProcessApiCall.entityRelationText');
            },
            getIcon: function (event) { return 'provisioningPackage'; },
        };
    };
    CyberEventsActionTypesService.prototype.otherAlertRelatedActivityConfig = function () {
        return {
            getDescription: function (event) {
                return event.additionalFields && event.additionalFields.description;
            },
            getIcon: function (event) { return 'events.alertRelatedEvent'; },
        };
    };
    CyberEventsActionTypesService.prototype.passwordChangeAttemptConfig = function () {
        return {
            getIcon: function (event) { return 'permissions'; },
        };
    };
    CyberEventsActionTypesService.prototype.powerShellCommandConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var source = _this.getSourceName(event), command = event.additionalFields && event.additionalFields.command.commandText;
                return _this.i18nService.get('events.actionTypes.powerShellCommand.description', {
                    source: source,
                    command: command,
                });
            },
            getEntities: function (event) {
                var entities = _this.getDefaultSourceEntities(event);
                return entities.concat((event.additionalFields && event.additionalFields.command
                    ? [
                        {
                            id: event.id || '',
                            item: event.additionalFields.command,
                            entityType: CommandLine,
                            relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
                            depth: entities.length - 1,
                        },
                    ]
                    : []));
            },
            getEntityRelationDescription: function (event, entity) {
                return _this.i18nService.get('events.actionTypes.powerShellCommand.entityRelationText');
            },
            getIcon: function (event) { return 'entities.commandPrompt'; },
        };
    };
    CyberEventsActionTypesService.prototype.processCreatedGroupConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var source = _this.getSourceName(event), target = _this.getTargetName(event);
                if (event.actionType.id === CyberEventActionTypeName.ProcessCreatedUsingWmiQuery) {
                    if (event.remoteMachine && event.remoteMachine.name) {
                        var remoteMachine = event.remoteMachine.name;
                        return _this.i18nService.get('events.actionTypesGroups.processCreatedGroup.descriptionLogicalWmiRemote', {
                            target: target,
                            remoteMachine: remoteMachine,
                        });
                    }
                    else
                        return _this.i18nService.get('events.actionTypesGroups.processCreatedGroup.descriptionLogicalWmi', {
                            source: source,
                            target: target,
                        });
                }
                return _this.i18nService.get('events.actionTypesGroups.processCreatedGroup.description', {
                    source: source,
                    target: target,
                });
            },
            getIcon: function (event) { return 'entities.process'; },
        };
    };
    CyberEventsActionTypesService.prototype.processInjectionGroupConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var source = _this.getSourceName(event), target = _this.getTargetName(event);
                return _this.i18nService.get('events.actionTypesGroups.processInjectionGroup.description', {
                    source: source,
                    target: target,
                });
            },
            getEntities: function (event) {
                var entities = _this.getDefaultSourceEntities(event);
                return (entities && entities.length
                    ? entities
                    : [
                        {
                            id: '',
                            item: null,
                            entityType: Process,
                            depth: 0,
                        },
                    ]).concat((event.process
                    ? [
                        {
                            id: event.id || '',
                            item: event.process,
                            entityType: Process,
                            relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
                            depth: entities && entities.length ? entities.length - 1 : 0,
                        },
                    ]
                    : []));
            },
            getEntityRelationDescription: function (event, entity) {
                return _this.i18nService.get('events.actionTypesGroups.processInjectionGroup.entityRelationText');
            },
            getIcon: function (event) { return 'syringe'; },
        };
    };
    CyberEventsActionTypesService.prototype.processPrimaryTokenModifiedConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var source = _this.getSourceName(event), isChangedToSystem = event.additionalFields &&
                    event.additionalFields.accessTokenDetails &&
                    event.additionalFields.accessTokenDetails.isChangedToSystemToken;
                return _this.i18nService.get("events.actionTypes.processPrimaryTokenModified.description" + (isChangedToSystem ? 'System' : ''), { source: source });
            },
            getEntities: function (event) {
                var entities = _this.getDefaultSourceEntities(event);
                return entities.concat((event.additionalFields && event.additionalFields.accessTokenDetails
                    ? [
                        {
                            id: event.id || '',
                            item: event.additionalFields.accessTokenDetails,
                            entityType: AccessTokenModificationDetails,
                            relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
                            depth: entities.length - 1,
                        },
                    ]
                    : []));
            },
            getEntityRelationDescription: function (event, entity) {
                var isChangedToSystem = event.additionalFields &&
                    event.additionalFields.accessTokenDetails &&
                    event.additionalFields.accessTokenDetails.isChangedToSystemToken;
                return _this.i18nService.get("events.actionTypes.processPrimaryTokenModified.entityRelationText" + (isChangedToSystem ? 'System' : ''));
            },
            getIcon: function (event) { return 'permissions'; },
        };
    };
    CyberEventsActionTypesService.prototype.registryEventGroupConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var source = _this.getSourceName(event), registryKey = event.registryModificationDetails &&
                    event.registryModificationDetails.current &&
                    event.registryModificationDetails.current.key, previousKey = event.registryModificationDetails &&
                    event.registryModificationDetails.previous &&
                    event.registryModificationDetails.previous.key;
                return _this.i18nService.get("events.actionTypes." + event.actionType.typeName + ".description", {
                    source: source,
                    previousKey: previousKey,
                    registryKey: registryKey,
                });
            },
            getEntities: function (event) {
                var entities = _this.getDefaultSourceEntities(event);
                return entities.concat((event.registryModificationDetails
                    ? [
                        {
                            id: event.id || '',
                            item: event.registryModificationDetails,
                            entityType: RegistryModificationDetails,
                            relationToPrecedingEntity: event.initiatingProcess
                                ? CyberEventEntityRelationTypeEnum.Outer
                                : CyberEventEntityRelationTypeEnum.Empty,
                            depth: entities.length - 1,
                        },
                    ]
                    : []));
            },
            getEntityRelationDescription: function (event, entity) {
                return _this.i18nService.get("events.actionTypes." + event.actionType.typeName + ".entityRelationText");
            },
            getIcon: function (event) { return 'entities.registry'; },
        };
    };
    CyberEventsActionTypesService.prototype.scheduledTaskGroupConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var taskName = event.additionalFields &&
                    event.additionalFields.scheduledTask &&
                    event.additionalFields.scheduledTask.taskName, action = _this.i18nService.get("events.actionTypesGroups.scheduledTaskGroup.actions." + event.actionType.typeName);
                return _this.i18nService.get('events.actionTypesGroups.scheduledTaskGroup.' +
                    (taskName ? 'description' : 'defaultDescription'), {
                    taskName: taskName,
                    action: action,
                });
            },
            getEntities: function (event) {
                return (event.initiatingUser
                    ? [
                        {
                            id: event.id || '',
                            item: event.initiatingUser,
                            entityType: LegacyUser,
                            depth: 0,
                        },
                    ]
                    : []).concat([
                    {
                        id: event.id || '',
                        item: event.additionalFields.scheduledTask,
                        entityType: ScheduledTask,
                        depth: 0,
                        relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
                    },
                ]);
            },
            getEntityRelationDescription: function (event, entity) {
                return entity.entityType === ScheduledTask && event.initiatingUser
                    ? _this.i18nService.get("events.actionTypesGroups.scheduledTaskGroup.actions." + event.actionType.typeName)
                    : null;
            },
            getIcon: function (event) { return 'entities.module'; },
        };
    };
    CyberEventsActionTypesService.prototype.screenshotTakenConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var username = (event.initiatingUser && event.initiatingUser.fullName) || 'User';
                return _this.i18nService.get("events.actionTypes." + event.actionType.typeName + ".description", {
                    username: username,
                });
            },
            getEntities: function (event) {
                return (event.initiatingUser
                    ? [
                        {
                            id: event.id || '',
                            item: event.initiatingUser,
                            entityType: LegacyUser,
                            relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
                            depth: 0,
                        },
                    ]
                    : []).slice();
            },
            getEntityRelationDescription: function (event, entity) {
                return _this.i18nService.get("events.actionTypes." + event.actionType.typeName + ".entityRelationText");
            },
            getIcon: function (event) { return 'screenshot'; },
        };
    };
    CyberEventsActionTypesService.prototype.shellLinkCreateFileEventConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var process = _this.getSourceName(event);
                var linkFileName;
                if (event.file) {
                    linkFileName = event.file.fileName;
                }
                else {
                    throw new Error("Can't get event file. Event details are missing.");
                }
                return _this.i18nService.get('events.actionTypes.shellLinkCreateFileEvent.description', {
                    process: process,
                    linkFileName: linkFileName,
                });
            },
            getIcon: function (event) { return 'events.fileCreated'; },
        };
    };
    CyberEventsActionTypesService.prototype.smartScreenExploitWarningConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var remote = event.remoteEndpoint && event.remoteEndpoint.name;
                return _this.i18nService.get("events.actionTypes.smartScreenExploitWarning.description" + (remote ? 'Remote' : ''), { remote: remote });
            },
        };
    };
    CyberEventsActionTypesService.prototype.smartScreenGroupConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                if (event.actionType.id === CyberEventActionTypeName.SmartScreenUserOverride) {
                    // "user override event" is mostly empty.
                    // On "GetItemById" on the event, backend will return the details
                    // (which will be the details of some other smart screen event)
                    return _this.i18nService.get('events.actionTypes.smartScreenUserOverride.description');
                }
                else {
                    var experience = event.additionalFields && event.additionalFields.experience;
                    var target = _this.getTargetName(event);
                    if (event.actionType.id === CyberEventActionTypeName.SmartScreenUrlWarning)
                        target = event.remoteEndpoint && event.remoteEndpoint.name;
                    return _this.i18nService.get("events.actionTypesGroups.smartScreenGroup.description" + (experience ? 'Experience' : ''), { target: target, experience: experience });
                }
            },
            getEntities: function (event) {
                var entities = _this.getDefaultSourceEntities(event);
                return entities.concat((event.remoteEndpoint
                    ? [
                        {
                            id: event.id || '',
                            item: event.remoteEndpoint,
                            entityType: NetworkEndpoint,
                            relationToPrecedingEntity: event.initiatingProcess
                                ? CyberEventEntityRelationTypeEnum.Outer
                                : CyberEventEntityRelationTypeEnum.Empty,
                            depth: entities.length - 1,
                        },
                    ]
                    : (event.target
                        ? [
                            __assign({}, event.target, { relationToPrecedingEntity: event.initiatingProcess
                                    ? CyberEventEntityRelationTypeEnum.Outer
                                    : CyberEventEntityRelationTypeEnum.Empty, depth: entities.length - 1 }),
                        ]
                        : []).slice()));
            },
            getEntityRelationDescription: function (event, entity) {
                var experience = event.additionalFields && event.additionalFields.experience;
                return _this.i18nService.get("events.actionTypesGroups.smartScreenGroup.entityRelationText" + (experience ? 'Experience' : ''), { experience: experience });
            },
            getIcon: function (event) { return 'shield'; },
        };
    };
    CyberEventsActionTypesService.prototype.usbDriveDriveLetterChangedConfig = function () {
        return {
            getIcon: function () { return 'plug'; },
        };
    };
    CyberEventsActionTypesService.prototype.usbDriveMountConfig = function () {
        return {
            getIcon: function () { return 'connection'; },
        };
    };
    CyberEventsActionTypesService.prototype.usbDriveUnmountConfig = function () {
        return {
            getIcon: function () { return 'disconnect'; },
        };
    };
    CyberEventsActionTypesService.prototype.usbDriveMountedConfig = function () {
        return {
            getIcon: function () { return 'connection'; },
        };
    };
    CyberEventsActionTypesService.prototype.usbDriveUnmountedConfig = function () {
        return {
            getIcon: function () { return 'disconnect'; },
        };
    };
    CyberEventsActionTypesService.prototype.usbEventGroupConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var _a = event.additionalFields.usbEvent || {}, _b = _a.driveLetter, driveLetter = _b === void 0 ? '' : _b, _c = _a.productName, productName = _c === void 0 ? '' : _c;
                return _this.i18nService.get("events.actionTypes." + event.actionType.typeName + ".description", {
                    driveLetter: driveLetter,
                    productName: productName,
                });
            },
            getEntities: function (event) { return [
                {
                    id: event.id || '',
                    item: event.additionalFields.usbEvent,
                    entityType: UsbEvent,
                    relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Outer,
                    depth: 0,
                },
            ]; },
        };
    };
    CyberEventsActionTypesService.prototype.userAccountCreatedConfig = function () {
        return {
            getIcon: function (event) { return 'users.user_add'; },
        };
    };
    CyberEventsActionTypesService.prototype.userToUserActivityGroupConfig = function () {
        var _this = this;
        return {
            getDescription: function (event) {
                var target = event.user && event.user.fullName, initiator = event.initiatingUser && event.initiatingUser.fullName;
                return _this.i18nService.get("events.actionTypesGroups.userToUserActivityGroup." + event.actionType.typeName + ".description", {
                    target: target,
                    initiator: initiator,
                });
            },
            getEntities: function (event) {
                return event.user
                    ? (event.initiatingUser
                        ? [
                            {
                                id: event.id || '',
                                item: event.initiatingUser,
                                entityType: LegacyUser,
                                depth: 0,
                            },
                        ]
                        : []).concat([
                        {
                            id: event.id || '',
                            item: event.user,
                            entityType: LegacyUser,
                            depth: event.initiatingUser ? 1 : 0,
                            relationToPrecedingEntity: event.initiatingUser
                                ? CyberEventEntityRelationTypeEnum.Outer
                                : CyberEventEntityRelationTypeEnum.Empty,
                        },
                    ]) : [];
            },
            getEntityRelationDescription: function (event, entity) {
                return entity.relationToPrecedingEntity === CyberEventEntityRelationTypeEnum.Outer
                    ? _this.i18nService.get("events.actionTypesGroups.userToUserActivityGroup." + event.actionType.typeName + ".entityRelationText")
                    : null;
            },
            getIcon: function (event) { return 'users.user'; },
        };
    };
    return CyberEventsActionTypesService;
}());
export { CyberEventsActionTypesService };
