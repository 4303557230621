/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../i18n/src/lib/pipes/i18n.pipe";
import * as i2 from "../../../../i18n/src/lib/services/i18n.service";
import * as i3 from "../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i4 from "@angular-react/fabric";
import * as i5 from "./accessible-spinner";
import * as i6 from "../services/a11y-announcer.service";
var styles_AccessibleSpinnerComponent = [];
var RenderType_AccessibleSpinnerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AccessibleSpinnerComponent, data: {} });
export { RenderType_AccessibleSpinnerComponent as RenderType_AccessibleSpinnerComponent };
export function View_AccessibleSpinnerComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.I18nPipe, [i2.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 3, "fab-spinner", [], null, null, null, i3.View_FabSpinnerComponent_0, i3.RenderType_FabSpinnerComponent)), i0.ɵdid(3, 5816320, null, 0, i4.FabSpinnerComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { size: [0, "size"], label: [1, "label"], labelPosition: [2, "labelPosition"] }, null), i0.ɵpod(4, { entityName: 0 }), i0.ɵppd(5, 2), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SpinnerSize[_co.spinnerSize]; var currVal_1 = (_co.customLabel || i0.ɵunv(_v, 3, 1, _ck(_v, 5, 0, i0.ɵnov(_v, 0), "accessibleSpinner.loading", _ck(_v, 4, 0, _co.loadingEntityName)))); var currVal_2 = _co.labelPosition; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AccessibleSpinnerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "accessible-spinner", [], null, null, null, View_AccessibleSpinnerComponent_0, RenderType_AccessibleSpinnerComponent)), i0.ɵdid(1, 4374528, null, 0, i5.AccessibleSpinnerComponent, [i2.I18nService, i6.A11yAnnouncerService], null, null)], null, null); }
var AccessibleSpinnerComponentNgFactory = i0.ɵccf("accessible-spinner", i5.AccessibleSpinnerComponent, View_AccessibleSpinnerComponent_Host_0, { loadingEntityName: "loadingEntityName", customLabel: "customLabel", politeness: "politeness", labelPosition: "labelPosition", spinnerSize: "spinnerSize" }, {}, []);
export { AccessibleSpinnerComponentNgFactory as AccessibleSpinnerComponentNgFactory };
