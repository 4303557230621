var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-click-events-have-key-events */
import { OnInit } from '@angular/core';
import { MessageBarType } from 'office-ui-fabric-react';
import { WizardBaseStep } from '@wcd/wizard';
import { SnmpAuth, GetNetworkScanAgentsList } from '@wcd/domain';
import { NgForm } from '@angular/forms';
import { RegExpService } from '@wcd/shared';
import { Paris } from '@microsoft/paris';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AssessmentJobService } from '../../services/assessment-job.service';
var AssessmentJobJobDetailsStepComponent = /** @class */ (function (_super) {
    __extends(AssessmentJobJobDetailsStepComponent, _super);
    function AssessmentJobJobDetailsStepComponent(paris, assessmentJobService) {
        var _this = _super.call(this) || this;
        _this.paris = paris;
        _this.assessmentJobService = assessmentJobService;
        _this.snmpAuthTypeForm = new NgForm([], []);
        _this.MessageBarType = MessageBarType;
        _this.agentNamesList = [];
        _this.agentsList = [];
        _this.RegExpService = RegExpService;
        _this.isSnmpEditMode = false;
        _this.targetChanged$ = new Subject();
        return _this;
    }
    AssessmentJobJobDetailsStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setStepValidation(true);
        this.paris.apiCall(GetNetworkScanAgentsList).subscribe(function (agents) {
            agents.map(function (agent) {
                _this.agentsList.push({ id: agent.id, machineName: agent.machineName });
                _this.agentNamesList.push(agent.machineName);
            });
        });
        this.isSnmpEditMode = this.data.isNewDraft || this.data.overrideAuth;
        // New scan, first time arriving to this step
        if (this.data.isNewDraft && !this.data.assessmentJob.agentId) {
            this.setStepValidation(false);
            this.data.assessmentJob.snmpAuth = new SnmpAuth();
        }
        this.targetChanged$.pipe(debounceTime(50)).subscribe(function () {
            var targetAddresses = _this.data.assessmentJob.originalTargetRanges.split(/,\s*|\s+/);
            _this.setStepValidation(_this.data.assessmentJob.snmpAuth.type &&
                targetAddresses.every(function (address) { return _this.RegExpService.ipv4OrRangeCidr.test(address); }));
        });
    };
    AssessmentJobJobDetailsStepComponent.prototype.onAgentNameChange = function (agentName) {
        this.data.assessmentJob.agentId = this.getAgentIdByAgentName(agentName);
        this.data.requiredNewScan = true;
    };
    AssessmentJobJobDetailsStepComponent.prototype.editExistingSnmpAuth = function () {
        this.data.assessmentJob.snmpAuth = new SnmpAuth();
        this.isSnmpEditMode = true;
        this.data.overrideAuth = true;
        this.setStepValidation(false);
    };
    AssessmentJobJobDetailsStepComponent.prototype.authTypeChanged = function (snmpAuth) {
        this.data.requiredNewScan = true;
        // in case the form just init it will be marked as valid
        // validating out self that it has any value
        var formHasValues = snmpAuth.username != null || snmpAuth.communityString != null;
        this.setStepValidation(formHasValues && !this.snmpAuthTypeForm.form.invalid);
    };
    AssessmentJobJobDetailsStepComponent.prototype.getAgentIdByAgentName = function (name) {
        var agent = this.agentsList.filter(function (x) { return x.machineName === name; });
        if (agent) {
            return agent[0].id;
        }
        else {
            return null;
        }
    };
    AssessmentJobJobDetailsStepComponent.prototype.onAssessmentJobChange = function () {
        this.data.requiredNewScan = true;
        this.targetChanged$.next();
    };
    return AssessmentJobJobDetailsStepComponent;
}(WizardBaseStep));
export { AssessmentJobJobDetailsStepComponent };
