var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* tslint:disable:template-accessibility-label-for */
import { AppContextService, Feature, FeaturesService } from '@wcd/config';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { catchError, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges, } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { HuntingCustomAction, HuntingQueryMtpWorkloadsApiCall, HuntingQueryMtpWorkloadsApiCallVNext, HuntingQuerySupportedAction, HuntingQuerySupportedActionsApiCall, HuntingQuerySupportedActionsApiCallVNext, HuntingQuerySupportedImpactedEntitiesApiCall, HuntingQuerySupportedImpactedEntitiesApiCallV2, HuntingRule, HuntingRuleAlertCategory, HuntingRuleEntityType, HuntingRuleImpactedEntity, HuntingRuleQueryIsMdatpApiCall, HuntingRuleQueryIsMdatpVNextApiCall, MachineGroup, MtpWorkload, Outbreak, Severity, } from '@wcd/domain';
import { NgForm } from '@angular/forms';
import { BehaviorSubject, of, ReplaySubject, throwError } from 'rxjs';
import { I18nService } from '@wcd/i18n';
import { AuthService, MtpPermission, tenantContextCache } from '@wcd/auth';
import { ScheduledHuntingErrorParsingService } from '@wcd/hunting';
import { groupBy, isEqual, lowerFirst, orderBy, sortBy } from 'lodash-es';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { WizardStepModel } from '../../../shared/components/wizard/wizard-step.model';
import { DataTableField } from '@wcd/datatable';
import { MitreTechniqueDisplayPipe } from '../../../shared/pipes/mitre-technique-display.pipe';
import { HuntingRuleScope } from '../models/hunting-rule-scope.model';
import { RbacService } from '../../../rbac/services/rbac.service';
import { ConfirmationDialogService } from '@wcd/dialogs';
import { CATEGORY_TO_MITRE_TECHNIQUES_MAPPING } from '@wcd/scc-common';
import { AppFlavorConfig } from '@wcd/scc-common';
var HUNTING_RULE_FREQUENCIES = [24, 12, 3, 1];
var WizardStep = /** @class */ (function (_super) {
    __extends(WizardStep, _super);
    function WizardStep(config) {
        var _this = _super.call(this, config) || this;
        _this.validation = config.validation;
        return _this;
    }
    Object.defineProperty(WizardStep.prototype, "valid", {
        get: function () {
            return this.validation ? this.validation() : true;
        },
        enumerable: true,
        configurable: true
    });
    return WizardStep;
}(WizardStepModel));
var WizardStepId;
(function (WizardStepId) {
    WizardStepId[WizardStepId["AlertDetails"] = 1] = "AlertDetails";
    WizardStepId[WizardStepId["ImpactedEntities"] = 2] = "ImpactedEntities";
    WizardStepId[WizardStepId["CustomActions"] = 3] = "CustomActions";
    WizardStepId[WizardStepId["Scope"] = 4] = "Scope";
    WizardStepId[WizardStepId["Summary"] = 5] = "Summary";
})(WizardStepId || (WizardStepId = {}));
var ScheduledHuntingRuleEditComponent = /** @class */ (function (_super) {
    __extends(ScheduledHuntingRuleEditComponent, _super);
    function ScheduledHuntingRuleEditComponent(paris, i18nService, errorParsingService, featuresService, rbacService, authService, appInsightsService, appContextService, changeDetector, mitreTechniqueDisplayPipe, confirmationDialogService, router) {
        var _this = _super.call(this, router) || this;
        _this.paris = paris;
        _this.i18nService = i18nService;
        _this.errorParsingService = errorParsingService;
        _this.featuresService = featuresService;
        _this.rbacService = rbacService;
        _this.authService = authService;
        _this.appInsightsService = appInsightsService;
        _this.appContextService = appContextService;
        _this.changeDetector = changeDetector;
        _this.mitreTechniqueDisplayPipe = mitreTechniqueDisplayPipe;
        _this.confirmationDialogService = confirmationDialogService;
        _this.itemSaved = new EventEmitter();
        _this.ALL_SCOPES = HuntingRuleScope.all.valueOf();
        _this.isSaving = false;
        _this.createMonitorMode = true;
        _this.destroyed$ = new ReplaySubject();
        // Machine groups support
        // tenant/user state
        _this.isRbacFilteringNecessary$ = new BehaviorSubject(false);
        _this.machineGroups$ = new BehaviorSubject([]);
        // groups data
        _this.allSelectableScopes = [
            {
                id: HuntingRuleScope.all,
                name: _this.i18nService.get('hunting.scheduledMonitorSidePane.fields.machineGroups.all'),
            },
            {
                id: HuntingRuleScope.specific,
                name: _this.i18nService.get('hunting.scheduledMonitorSidePane.fields.machineGroups.specific'),
            },
        ];
        _this.selectableScopes = _this.allSelectableScopes;
        _this.WizardStepId = WizardStepId;
        _this.isLoadingMachinesScopes = true;
        _this.currentSelectedMachineGroupsIds = [];
        _this.disableGroupsDropdown = true;
        _this.fixDataClassListener = function (e) {
            e.matches ? _this.fixDataClass('wcd-padding-small-top') : _this.fixDataClass('wcd-padding-large-top');
        };
        var tenantContext = tenantContextCache.appConfig;
        _this.isCustomActionsFeatureEnabled = _this.featuresService.isEnabled(Feature.HuntingCustomActions);
        _this.huntingRuleFrequencyEnabled = _this.featuresService.isEnabled('HuntingRuleFrequency');
        _this.huntingCustomDetectionMitreDropDownEnabled = _this.featuresService.isEnabled(Feature.HuntingCustomDetectionMitreDropdown);
        _this.showImpactedEntities =
            appContextService.isSCC && featuresService.isEnabled(Feature.HuntingCustomDetectionUsingEvidence);
        _this.threatAnalyticsCustomDetectionFeatureEnabled =
            featuresService.isEnabled(Feature.ThreatAnalyticsCustomDetection) &&
                ((tenantContext.IsMdatpActive &&
                    (AppFlavorConfig.threatAnalytics.mde.mdeFlavors || []).includes(tenantContext.MdeFlavor)) ||
                    tenantContext.IsOatpActive);
        _this.shouldDropdownBeActive =
            (tenantContext.MdatpMtpPermissions || []).includes(MtpPermission.SecurityData_Read) ||
                (tenantContext.OatpMtpPermissions || []).includes(MtpPermission.SecurityData_Read);
        _this.huntingRuleRepo = _this.paris.getRepository(HuntingRule);
        return _this;
    }
    ScheduledHuntingRuleEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.createMonitorMode = !this.huntingRule;
        this.availableAlertSeverities = this.getAvailableAlertSeverities();
        if (this.huntingRuleFrequencyEnabled) {
            this.huntingRuleFrequencies = this.getRuleFrequencies();
            this.selectedFrequency =
                this.huntingRule &&
                    this.huntingRuleFrequencies.find(function (freq) { return freq.id === _this.huntingRule.intervalHours; });
        }
        this.supportedAlertCategories = this.getSupportedAlertCategories();
        this.getAlertCategoryLabel = this.getAlertCategoryLabel.bind(this);
        // edit mode - load rule from BE based on query id
        if (this.createMonitorMode) {
            this.huntingRule = this.createNewHuntingRule();
        }
        else {
            this.huntingRule = __assign({}, this.huntingRule); // create a copy, so the wizard won't change the real object
            this.alertCategory = this.huntingRule.alertCategory;
            //we have to remove undefined machine groups because of a server bug that
            //returns deleted machine group ids in the hunting rule
            this.currentSelectedMachineGroupsIds = this.huntingRule.machineGroups
                .filter(Boolean)
                .map(function (a) { return a.id; });
            this.selectableMitreTechniques = this.getSelectableMitreTechniques(this.alertCategory);
        }
        this.setupWizard();
        this.loadMachineGroups();
        this.setMachineGroupScope();
        this.initializeCustomActions();
        this.initializeImpactedEntities();
        this.setSummarizedActions();
        if (this.threatAnalyticsCustomDetectionFeatureEnabled) {
            this.loadThreats();
            this.loadRuleThreat();
        }
    };
    ScheduledHuntingRuleEditComponent.prototype.ngOnChanges = function (changes) {
        if (changes.huntingRule) {
            this.setSummarizedActions();
        }
    };
    ScheduledHuntingRuleEditComponent.prototype.ngOnDestroy = function () {
        this.destroyed$.next();
        this.destroyed$.complete();
    };
    ScheduledHuntingRuleEditComponent.prototype.setSummarizedActions = function () {
        this.summarizedActions = this.huntingRule.actions || [];
    };
    ScheduledHuntingRuleEditComponent.prototype.getSupportedAlertCategories = function () {
        // list enum values
        return Object.keys(HuntingRuleAlertCategory)
            .map(function (k) { return HuntingRuleAlertCategory[k]; })
            .sort();
    };
    ScheduledHuntingRuleEditComponent.prototype.getAvailableAlertSeverities = function () {
        return this.paris
            .getRepository(Severity)
            .entity.values.filter(function (alertSeverity) { return alertSeverity.isSelectable; })
            .sort(function (a, b) { return a.priority - b.priority; });
    };
    ScheduledHuntingRuleEditComponent.prototype.fixDataClass = function (className) {
        this.stepsData = this.stepsData.map(function (item) {
            item.className = className;
            return item;
        });
    };
    ScheduledHuntingRuleEditComponent.prototype.setupWizard = function () {
        var _this = this;
        var showCustomActions = this.featuresService.isEnabled(Feature.HuntingCustomActions) &&
            (!this.appContextService.isSCC ||
                this.featuresService.isEnabled(Feature.HuntingCustomActionsMTP));
        this.stepsData = [
            new WizardStep({
                id: WizardStepId.AlertDetails,
                subText: this.i18nService.get('hunting.scheduledMonitorSidePane.wizardSections.alertDetails.title'),
                show: true,
                validation: function () { return _this.alertDetailsStepForm && _this.alertDetailsStepForm.form.valid; },
            }),
            this.showImpactedEntities
                ? new WizardStep({
                    id: WizardStepId.ImpactedEntities,
                    subText: this.i18nService.get('hunting.scheduledMonitorSidePane.wizardSections.entities.title'),
                    show: true,
                    validation: function () {
                        return _this.entitiesStepForm &&
                            _this.entitiesStepForm.form.valid &&
                            _this.huntingRule.impactedEntities.length > 0;
                    },
                })
                : null,
            showCustomActions
                ? new WizardStep({
                    id: WizardStepId.CustomActions,
                    subText: this.i18nService.get('hunting.scheduledMonitorSidePane.wizardSections.actions.title'),
                    show: false,
                    validation: function () { return _this.actionsStepForm && _this.actionsStepForm.form.valid; },
                })
                : null,
            new WizardStep({
                id: WizardStepId.Scope,
                subText: this.i18nService.get('hunting.scheduledMonitorSidePane.wizardSections.scope.title'),
                show: false,
                validation: function () { return _this.scopeStepForm && _this.scopeStepForm.form.valid; },
            }),
            new WizardStep({
                id: WizardStepId.Summary,
                subText: this.i18nService.get('hunting.scheduledMonitorSidePane.wizardSections.summary.title'),
                actionButtonSettings: {
                    label: this.i18nService.get('hunting.scheduledMonitorSidePane.buttons.' +
                        (this.createMonitorMode ? 'create' : 'save')),
                    onActionButtonClick: function () { return _this.saveScheduledQuery().then(function () { return _this.closePanel(); }); },
                },
                show: true,
            }),
        ].filter(Boolean);
        this.currentStep = this.stepsData.find(function (step) { return step.show; });
        this.summarizedActionsColumns = DataTableField.fromList([
            {
                id: 'action',
                name: this.i18nService.get('hunting.scheduledMonitorSidePane.wizardSections.summary.actionsTable.action'),
                getDisplay: function (item) {
                    return _this.i18nService.get("hunting.customDetections.actions." + item.actionType);
                },
                fluidWidth: 0.5,
                sort: { enabled: false },
            },
            {
                id: 'asset',
                name: this.i18nService.get('hunting.scheduledMonitorSidePane.wizardSections.summary.actionsTable.asset'),
                getDisplay: function (item) {
                    return item.entities &&
                        item.entities
                            .map(function (entity) {
                            return _this.i18nService.get('hunting.scheduledMonitorSidePane.wizardSections.entities.' +
                                lowerFirst(entity.entityType));
                        })
                            .join(', ');
                },
                fluidWidth: 0.5,
                sort: { enabled: false },
            },
        ]);
    };
    ScheduledHuntingRuleEditComponent.prototype.showOrHideSteps = function (changes) {
        if (changes) {
            for (var _i = 0, _a = this.stepsData; _i < _a.length; _i++) {
                var step = _a[_i];
                if (changes[step.id] != null) {
                    step.show = changes[step.id];
                }
            }
            this.stepsData = this.stepsData.slice();
        }
    };
    ScheduledHuntingRuleEditComponent.prototype.onClosePanel = function (e) {
        event.preventDefault();
        event.stopPropagation();
        this.closePanel();
    };
    ScheduledHuntingRuleEditComponent.prototype.createNewHuntingRule = function () {
        var huntingRule = this.huntingRuleRepo.createNewItem();
        huntingRule.isEnabled = true;
        return huntingRule;
    };
    ScheduledHuntingRuleEditComponent.prototype.onCategoryChanged = function ($event) {
        if (this.huntingRule.alertCategory == $event) {
            return;
        }
        this.huntingRule.alertCategory = $event;
        this.huntingRule.mitreTechniques = null; // on category change, reset the MITRE techniques selection
        this.selectableMitreTechniques = this.getSelectableMitreTechniques($event);
    };
    ScheduledHuntingRuleEditComponent.prototype.onThreatChanged = function ($event) {
        this.huntingRule.threatId = $event.id;
        this.threat = $event;
    };
    ScheduledHuntingRuleEditComponent.prototype.getSelectableMitreTechniques = function (alertCategory) {
        var _this = this;
        return (alertCategory &&
            CATEGORY_TO_MITRE_TECHNIQUES_MAPPING[alertCategory] &&
            sortBy(CATEGORY_TO_MITRE_TECHNIQUES_MAPPING[alertCategory].map(function (technique) { return ({
                id: technique,
                name: _this.mitreTechniqueDisplayPipe.transform(technique, true),
            }); }), function (techniqueField) { return _this.mitreTechniqueDisplayPipe.transform(techniqueField.id, false); }));
    };
    ScheduledHuntingRuleEditComponent.prototype.getMitreTechniqueDropDownPlaceHolder = function () {
        if (!this.huntingRule.mitreTechniques || !this.huntingRule.mitreTechniques.length) {
            return this.i18nService.get('hunting.scheduledMonitorSidePane.fields.alertMitreTechniques.placeholder');
        }
        return this.huntingRule.mitreTechniques.length > 1
            ? this.i18nService.get('hunting.scheduledMonitorSidePane.fields.alertMitreTechniques.placeholderWithSelection.plural', { count: this.huntingRule.mitreTechniques.length })
            : this.i18nService.get('hunting.scheduledMonitorSidePane.fields.alertMitreTechniques.placeholderWithSelection.singular');
    };
    ScheduledHuntingRuleEditComponent.prototype.saveScheduledQuery = function () {
        return __awaiter(this, void 0, void 0, function () {
            var machineGroups, doesUserApproveChange, err_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isSaving = true;
                        this.fieldsAreMissing = false;
                        if (!this.isRbacFilteringNecessary$.value || this.huntingRuleScope.id === HuntingRuleScope.all) {
                            machineGroups = []; // no groups specified == all machine groups
                        }
                        else if (this.currentSelectedMachineGroupsIds.length) {
                            machineGroups = this.machineGroups$.value.filter(function (group) {
                                return _this.currentSelectedMachineGroupsIds.includes(group.id);
                            });
                        }
                        else {
                            this.appInsightsService.trackException(new Error('No device groups were selected for Hunting rule scope while user is expected to select specific groups.'));
                        }
                        this.huntingRule.machineGroups = machineGroups;
                        this.huntingRule.intervalHours = this.huntingRule.intervalHours || 24;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, this.shouldMigrateRuleToMtp()];
                    case 2:
                        if (!_a.sent()) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.showMtpEditRuleDialog(this.huntingRule)];
                    case 3:
                        doesUserApproveChange = _a.sent();
                        if (!doesUserApproveChange) {
                            this.closePanel();
                            return [2 /*return*/];
                        }
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        err_1 = _a.sent();
                        this.parseError(err_1);
                        return [2 /*return*/];
                    case 6: return [4 /*yield*/, this.huntingRuleRepo
                            .save(this.huntingRule, null, { queryText: this.queryText })
                            .pipe(catchError(function (err) {
                            _this.parseError(err);
                            return throwError(err);
                        }))
                            .toPromise()
                            .then(function (savedRule) {
                            _this.itemSaved.emit(savedRule);
                            _this.itemSaved.complete();
                        })
                            .catch(function (error) { })];
                    case 7:
                        _a.sent();
                        this.huntingRuleRepo.clearCache();
                        return [2 /*return*/];
                }
            });
        });
    };
    ScheduledHuntingRuleEditComponent.prototype.loadMachineGroups = function () {
        var _this = this;
        var apiCall = this.shouldMigrateQueriesApiToVNext
            ? HuntingQueryMtpWorkloadsApiCallVNext
            : HuntingQueryMtpWorkloadsApiCall;
        var mtpWorkloads$ = this.queryText
            ? this.paris.apiCall(apiCall, this.queryText).pipe(take(1))
            : of(this.huntingRule.mtpWorkloads);
        mtpWorkloads$
            .pipe(switchMap(function (mtpWorkloads) {
            return mtpWorkloads.includes(MtpWorkload.Mdatp)
                ? _this.rbacService.isFilteringNecessary$
                : of(false); // machines group filtering isn't relevant if the rule's query doesn't include any device table
        }), takeUntil(this.destroyed$))
            .subscribe(this.isRbacFilteringNecessary$);
        this.rbacService.userExposedRbacGroups$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(this.machineGroups$);
        this.machineGroupsChecklistValues$ = this.machineGroups$.pipe(map(function (machineGroups) {
            var checklistValues = machineGroups.map(function (group) { return ({
                id: group.id,
                name: group.isUnassignedMachineGroup
                    ? _this.i18nService.get('machineGroup.unassignedGroup.name')
                    : group.name,
            }); });
            return orderBy(checklistValues, [function (group) { return group.name.toLowerCase(); }]);
        }));
        this.isRbacFilteringNecessary$.subscribe(function (isRbacFilteringNecessary) {
            var _a;
            return _this.showOrHideSteps((_a = {}, _a[WizardStepId.Scope] = isRbacFilteringNecessary, _a));
        });
        this.machineGroups$.subscribe(function () {
            _this.setSelectableScopes();
            _this.isLoadingMachinesScopes = false;
        }, function (error) {
            _this.isLoadingMachinesScopes = false;
        });
    };
    ScheduledHuntingRuleEditComponent.prototype.loadThreats = function () {
        var _this = this;
        this.paris.getRepository(Outbreak).allItems$.subscribe(function (outbreaks) {
            var nullableOutbreak = new Outbreak({ id: null });
            nullableOutbreak.displayName = 'None';
            var currentThreats = [nullableOutbreak].concat(outbreaks);
            _this.threats = currentThreats.sort(function (a, b) { return a.displayName.localeCompare(b.displayName); });
        });
    };
    ScheduledHuntingRuleEditComponent.prototype.loadRuleThreat = function () {
        var _this = this;
        if (this.huntingRule.threatId) {
            this.paris
                .getItemById(Outbreak, this.huntingRule.threatId)
                .subscribe(function (outbreak) { return (_this.threat = outbreak); });
        }
    };
    ScheduledHuntingRuleEditComponent.prototype.getRuleFrequencies = function () {
        var _this = this;
        return HUNTING_RULE_FREQUENCIES.map(function (freq) { return ({
            name: _this.i18nService.get("hunting.scheduledMonitorSidePane.fields.huntingRuleFrequency.everyFrequencyHours." + (+freq > 1 ? 'plural' : 'singular'), { freq: freq }),
            id: freq,
        }); });
    };
    ScheduledHuntingRuleEditComponent.prototype.parseError = function (err) {
        this.error = this.i18nService.get('hunting.scheduledMonitorSidePane.errors.generic');
        if (err.status === 409) {
            this.error = this.i18nService.get('hunting.scheduledMonitorSidePane.errors.duplicateMonitor');
        }
        else if (err.status === 400 && err.message && err.message.Type) {
            try {
                var scheduledHuntingError = this.errorParsingService.parseError(err);
                this.error = scheduledHuntingError.errorMessage;
                if (scheduledHuntingError.type === 'InvalidSchema') {
                    var invalidSchemaError = scheduledHuntingError;
                    this.queryFields = invalidSchemaError.requiredQueryFields;
                    this.queryFieldsNames = invalidSchemaError.requiredQueryFieldNames;
                    this.fieldsAreMissing = invalidSchemaError.areFieldsMissing;
                }
            }
            catch (e) {
                this.error = this.error || err.error;
            }
        }
        else {
            this.error = this.error || err.error;
        }
    };
    ScheduledHuntingRuleEditComponent.prototype.setSelectableScopes = function () {
        if (!this.authService.currentUser.isMdeAdmin) {
            this.selectableScopes = this.allSelectableScopes.filter(function (item) { return item.id === HuntingRuleScope.specific; });
        }
        this.setMachineGroupScope();
    };
    ScheduledHuntingRuleEditComponent.prototype.onRuleScopeChange = function (selectedScope) {
        this.disableGroupsDropdown = selectedScope.id === HuntingRuleScope.all;
        this.changeDetector.detectChanges();
    };
    ScheduledHuntingRuleEditComponent.prototype.setMachineGroupScope = function () {
        var scopeId = this.huntingRule.machineGroups && this.huntingRule.machineGroups.length > 0;
        this.huntingRuleScope =
            this.selectableScopes.length === 1 ? this.selectableScopes[0] : this.selectableScopes[+scopeId];
        this.onRuleScopeChange(this.huntingRuleScope);
    };
    ScheduledHuntingRuleEditComponent.prototype.onMachineGroupsChange = function (selectedGroupIds) {
        var _this = this;
        this.currentSelectedMachineGroupsIds = selectedGroupIds.slice();
        this.changeDetector.detectChanges();
        setTimeout(function () {
            _this.changeDetector.detectChanges();
        });
    };
    ScheduledHuntingRuleEditComponent.prototype.getGroupsDropdownPlaceholder = function (selectedGroupIds) {
        var numSelectedGroups = selectedGroupIds ? selectedGroupIds.length : 0;
        return numSelectedGroups > 0
            ? this.i18nService.get("hunting.scheduledMonitorSidePane.fields.machineGroups.dropdown.placeholderWithInfo." + (numSelectedGroups === 1 ? 'singular' : 'plural'), {
                numGroups: numSelectedGroups,
            })
            : this.i18nService.get('hunting.scheduledMonitorSidePane.fields.machineGroups.dropdown.placeholder');
    };
    ScheduledHuntingRuleEditComponent.prototype.getAlertCategoryLabel = function (alertCategory) {
        return this.i18nService.get("reporting.alertsByCategory." + lowerFirst(alertCategory));
    };
    ScheduledHuntingRuleEditComponent.prototype.getThreatAnalyticsLabel = function (threat) {
        return threat.displayName;
    };
    ScheduledHuntingRuleEditComponent.prototype.initializeCustomActions = function () {
        var _this = this;
        var customActionsCall = this.shouldMigrateRulesApiToVNext
            ? this.paris.apiCall(HuntingQuerySupportedActionsApiCallVNext, {
                Id: this.huntingRule.queryId,
                QueryText: this.queryText,
            })
            : this.paris.apiCall(HuntingQuerySupportedActionsApiCall, {
                Id: this.huntingRule.queryId,
                QueryText: this.queryText,
            });
        customActionsCall.pipe(take(1)).subscribe(function (supportedCustomActions) {
            var _a;
            _this.supportedCustomActions = supportedCustomActions;
            var showActionsStep = supportedCustomActions &&
                supportedCustomActions.some(function (action) { return action.isEnabled; }) &&
                _this.authService.currentUser.hasRequiredMtpPermission(MtpPermission.SecurityData_Remediate);
            _this.showOrHideSteps((_a = {}, _a[WizardStepId.CustomActions] = showActionsStep, _a));
        });
    };
    ScheduledHuntingRuleEditComponent.prototype.onCustomActionsChange = function (actions) {
        this.setSummarizedActions();
        this.changeDetector.detectChanges();
    };
    ScheduledHuntingRuleEditComponent.prototype.initializeImpactedEntities = function () {
        var _this = this;
        // get possible selections of impacted entities based on query text
        var supportedImpactedEntitiesCall = this.shouldMigrateRulesApiToVNext
            ? this.paris
                .apiCall(HuntingQuerySupportedImpactedEntitiesApiCallV2, {
                Id: this.huntingRule.queryId,
                QueryText: this.queryText,
            })
                .pipe(map(function (possibleImpactedEntities) {
                var huntingRuleImpactedEntity = [];
                Object.keys(possibleImpactedEntities).forEach(function (entityType) {
                    possibleImpactedEntities[entityType].forEach(function (possibleValue) {
                        huntingRuleImpactedEntity.push({
                            entityType: HuntingRuleEntityType[entityType],
                            entityIdFields: [possibleValue],
                        });
                    });
                });
                return huntingRuleImpactedEntity;
            }))
            : this.paris.apiCall(HuntingQuerySupportedImpactedEntitiesApiCall, {
                Id: this.huntingRule.queryId,
                QueryText: this.queryText,
            });
        supportedImpactedEntitiesCall
            .pipe(take(1), tap(function (possibleImpactedEntities) {
            // restrict selected impacted entities to a subset of possible entities
            _this.huntingRule.impactedEntities = (_this.huntingRule.impactedEntities || []).filter(function (entity) { return possibleImpactedEntities.some(function (pe) { return isEqual(entity, pe); }); });
            // set up the impacted entities selection options
            var possibleEntitiesByType = groupBy(possibleImpactedEntities, 'entityType');
            var possibleEntityTypes = [
                HuntingRuleEntityType.Machine,
                HuntingRuleEntityType.Mailbox,
                HuntingRuleEntityType.User,
            ];
            _this.impactedEntityCategories = possibleEntityTypes.map(function (entityType) {
                var selectedEntity = _this.huntingRule.impactedEntities.find(function (entity) { return entity.entityType === entityType; });
                return {
                    entityType: entityType,
                    entities: sortBy(possibleEntitiesByType[entityType] || [], function (entity) { return entity.entityIdFields[0]; }),
                    checked: !!selectedEntity,
                    selectedEntity: selectedEntity &&
                        possibleEntitiesByType[entityType].find(function (entity) {
                            return isEqual(entity, selectedEntity);
                        }),
                    name: _this.i18nService.get('hunting.scheduledMonitorSidePane.wizardSections.entities.' +
                        lowerFirst(entityType)),
                };
            });
        }))
            .subscribe();
        this.impactedEntitiesColumns = DataTableField.fromList([
            {
                id: 'entityType',
                name: this.i18nService.get('hunting.scheduledMonitorSidePane.wizardSections.summary.impactedEntitiesTable.entityType'),
                getDisplay: function (entity) {
                    return _this.i18nService.get('hunting.scheduledMonitorSidePane.wizardSections.entities.' +
                        lowerFirst(entity.entityType));
                },
                fluidWidth: 0.5,
                sort: { enabled: false },
            },
            {
                id: 'entityIdFields',
                name: this.i18nService.get('hunting.scheduledMonitorSidePane.wizardSections.summary.impactedEntitiesTable.entityIdFields'),
                getDisplay: this.getImpactedEntitiesLabel,
                fluidWidth: 0.5,
                sort: { enabled: false },
            },
        ]);
    };
    ScheduledHuntingRuleEditComponent.prototype.onImpactedEntitiesSelectionChange = function ($event) {
        // apply impacted entities selection
        this.huntingRule.impactedEntities = Object.values(this.impactedEntityCategories)
            .filter(function (category) { return category.checked && category.selectedEntity; })
            .map(function (category) { return (__assign({}, category.selectedEntity)); });
        this.changeDetector.detectChanges();
    };
    ScheduledHuntingRuleEditComponent.prototype.getImpactedEntitiesLabel = function (entity) {
        return entity.entityIdFields.join(', ');
    };
    ScheduledHuntingRuleEditComponent.prototype.previousStep = function () {
        var _this = this;
        var previousSteps = this.stepsData.filter(function (s) { return s.show && s.id < _this.currentStep.id; });
        if (previousSteps.length) {
            this.currentStep = previousSteps[previousSteps.length - 1];
            this.changeDetector.detectChanges();
            // re-trigger change detection to update ngModels inside the current step form
            setTimeout(function () {
                _this.changeDetector.detectChanges();
            });
        }
    };
    ScheduledHuntingRuleEditComponent.prototype.nextStep = function () {
        var _this = this;
        var nextStep = this.stepsData.find(function (s) { return s.show && s.id > _this.currentStep.id; });
        if (nextStep) {
            this.currentStep = nextStep;
            this.changeDetector.detectChanges();
            setTimeout(function () {
                _this.changeDetector.detectChanges();
            });
        }
    };
    ScheduledHuntingRuleEditComponent.prototype.machineGroupsSummaryDisplay = function (groupIds, isAllMachinesToggleSelected) {
        if (isAllMachinesToggleSelected === void 0) { isAllMachinesToggleSelected = false; }
        if (isAllMachinesToggleSelected || !groupIds || !groupIds.length) {
            return this.i18nService.get('hunting.scheduledMonitorSidePane.fields.machineGroups.all');
        }
        if (groupIds.length === 1) {
            var group = this.machineGroups$.value.find(function (group) { return group.id === groupIds[0]; });
            return group && group.name;
        }
        else {
            return this.i18nService.get('hunting.scheduledMonitorSidePane.fields.machineGroups.dropdown.placeholderWithInfo.plural', {
                numGroups: groupIds.length,
            });
        }
    };
    Object.defineProperty(ScheduledHuntingRuleEditComponent.prototype, "isNextButtonEnabled", {
        get: function () {
            var _this = this;
            return this.stepsData.find(function (step) { return step.id === _this.currentStep.id; }).valid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduledHuntingRuleEditComponent.prototype, "selectedFrequency", {
        get: function () {
            return this._selectedFrequency;
        },
        set: function (frequency) {
            this._selectedFrequency = frequency;
            if (this.huntingRule) {
                this.huntingRule.intervalHours = frequency && frequency.id;
            }
        },
        enumerable: true,
        configurable: true
    });
    ScheduledHuntingRuleEditComponent.prototype.shouldMigrateRuleToMtp = function () {
        return __awaiter(this, void 0, void 0, function () {
            var apiCall, isMdatpQuery;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.queryText && this.huntingRule.isMdatp && this.huntingRule.id)) return [3 /*break*/, 2];
                        apiCall = this.shouldMigrateQueriesApiToVNext
                            ? HuntingRuleQueryIsMdatpVNextApiCall
                            : HuntingRuleQueryIsMdatpApiCall;
                        return [4 /*yield*/, this.paris.apiCall(apiCall, this.queryText).pipe(take(1)).toPromise()];
                    case 1:
                        isMdatpQuery = _a.sent();
                        return [2 /*return*/, !isMdatpQuery];
                    case 2: return [2 /*return*/, false];
                }
            });
        });
    };
    ScheduledHuntingRuleEditComponent.prototype.showMtpEditRuleDialog = function (huntingRule) {
        return __awaiter(this, void 0, void 0, function () {
            var confirmed;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.confirmationDialogService.showDialog({
                            title: this.i18nService.get('scheduledHunting.rules.editMdatpInMtp.dialogTitle'),
                            body: this.i18nService.get('scheduledHunting_rules_editMdatpInMtp_dialogBody'),
                            confirmButton: {
                                text: this.i18nService.get('scheduledHunting_rules_editMdatpInMtp_dialogConfirmButton'),
                            },
                        })];
                    case 1:
                        confirmed = (_a.sent()).confirmed;
                        this.appInsightsService.trackEvent('EditMdatpRuleInMtp', {
                            ruleId: huntingRule.id,
                            confirmed: confirmed,
                        });
                        return [2 /*return*/, confirmed];
                }
            });
        });
    };
    ScheduledHuntingRuleEditComponent.prototype.onAnyExpandedChange = function (state) {
        this.changeDetector.detectChanges();
    };
    Object.defineProperty(ScheduledHuntingRuleEditComponent.prototype, "shouldMigrateRulesApiToVNext", {
        get: function () {
            return this.featuresService.isAnyEnabled([
                Feature.HuntingEndpointMigrationRulesController,
                Feature.HuntingEndpointMigrationRulesControllerMtp,
            ]);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduledHuntingRuleEditComponent.prototype, "shouldMigrateQueriesApiToVNext", {
        get: function () {
            return this.featuresService.isAnyEnabled([
                Feature.HuntingEndpointMigrationQueriesController,
                Feature.HuntingEndpointMigrationQueriesControllerMtp,
            ]);
        },
        enumerable: true,
        configurable: true
    });
    return ScheduledHuntingRuleEditComponent;
}(PanelContainer));
export { ScheduledHuntingRuleEditComponent };
