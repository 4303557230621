import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { DataviewField } from '@wcd/dataview';
import { CustomTiIndicator, CustomTiIndicatorsCapacityApiCall, CustomTiIndicatorsType, CustomTiIndicatorsTypes, CustomTiIndicatorTypeExclusionsRelationship, MdeUserRoleActionEnum, } from '@wcd/domain';
import { combineLatest, merge, of } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AuthService } from '@wcd/auth';
import { Feature, FeaturesService } from '@wcd/config';
import { EntityPanelsService, } from '../../../global_entities/services/entity-panels.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { I18nService } from '@wcd/i18n';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { MachinesService } from '../../machines/services/machines.service';
import { CustomTiIndicatorsFields } from '../services/custom-ti-indicators.fields';
import { CustomTiIndicatorsService } from '../services/custom-ti-indicators.service';
import { AdvancedFeaturesService } from '../../../admin/integration-settings/advanced-features.service';
import { MessageBarType } from 'office-ui-fabric-react';
import { CapacityState } from '@wcd/domain';
import { DataviewActionTypes } from '../../../dataviews/components/actions-components/dataview-actions.model';
import { sccHostService } from '@wcd/scc-interface';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';
var CustomTiIndicatorsDataviewComponent = /** @class */ (function () {
    function CustomTiIndicatorsDataviewComponent(route, paris, customTiIndicatorsService, authService, machinesService, customTiFields, globalEntityTypesService, i18nService, featureService, entityPanelsService, advancedFeaturesService, performanceSccService) {
        var _a, _b;
        this.route = route;
        this.paris = paris;
        this.customTiIndicatorsService = customTiIndicatorsService;
        this.authService = authService;
        this.machinesService = machinesService;
        this.customTiFields = customTiFields;
        this.globalEntityTypesService = globalEntityTypesService;
        this.i18nService = i18nService;
        this.featureService = featureService;
        this.entityPanelsService = entityPanelsService;
        this.advancedFeaturesService = advancedFeaturesService;
        this.performanceSccService = performanceSccService;
        this.CapacityState = CapacityState;
        this.MessageBarType = MessageBarType;
        this.CustomTiIndicatorsTypes = CustomTiIndicatorsTypes;
        this.globalEntityType = this.globalEntityTypesService.getEntityType(CustomTiIndicator);
        this.isScc = sccHostService.isSCC;
        this.sidePanelSettings = { role: 'none' };
        this._capacityOptions = (_a = {},
            _a[CapacityState.Available] = {
                state: CapacityState.Available,
                tooltip: this.i18nService.get('customTiIndicator.dataview.actions.capacity.status.available.tooltip'),
                color: 'ms-color-green',
            },
            _a[CapacityState.Unavailable] = {
                state: CapacityState.Unavailable,
                total: this.i18nService.get('customTiIndicator.dataview.actions.capacity.status.unavailable.title'),
                tooltip: this.i18nService.get('customTiIndicator.dataview.actions.capacity.status.unavailable.tooltip'),
                color: 'ms-color-red',
            },
            _a[CapacityState.Exceeded] = {
                state: CapacityState.Exceeded,
                tooltip: this.i18nService.get('customTiIndicator.dataview.actions.capacity.status.exceeded.tooltip'),
                color: 'ms-color-red',
            },
            _a);
        this.tabNames = (_b = {},
            _b[CustomTiIndicatorsTypes.Files] = this.i18nService.strings
                .customTiIndicator_dataview_entity_names_file_pluralName,
            _b[CustomTiIndicatorsTypes.Ip] = this.i18nService.strings
                .customTiIndicator_dataview_entity_names_ip_pluralName,
            _b[CustomTiIndicatorsTypes.Url] = this.i18nService.strings
                .customTiIndicator_dataview_entity_names_url_domain_pluralName,
            _b[CustomTiIndicatorsTypes.Certificate] = this.i18nService.strings
                .customTiIndicator_dataview_entity_names_certificate_pluralName,
            _b[CustomTiIndicatorsTypes.MemoryContent] = this.i18nService.strings
                .customTiIndicator_dataview_entity_names_process_memory_name,
            _b);
        this._allCustomTiIndicatorsTypes = this.paris.getRepository(CustomTiIndicatorsType).entity.values;
        this.customTiIndicatorsRepository = paris.getRelationshipRepository(CustomTiIndicatorTypeExclusionsRelationship);
        this._blockIpAndDomainEnabled = this.featureService.isEnabled(Feature.BlockIpAndDomain);
    }
    Object.defineProperty(CustomTiIndicatorsDataviewComponent.prototype, "isUserAllowedActions", {
        get: function () {
            return (this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.securitySettings) ||
                this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.remediationActions));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomTiIndicatorsDataviewComponent.prototype, "warningHelpKey", {
        get: function () {
            return this._warningHelpKey ? this._warningHelpKey.replace('\n', '') : null;
        },
        enumerable: true,
        configurable: true
    });
    CustomTiIndicatorsDataviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._routeParamsSubscription = this.route.params.subscribe(function (params) {
            var currentCustomTiIndicatorTypeId = params.customTiIndicatorType || _this._allCustomTiIndicatorsTypes[0].id;
            _this.currentTiIndicatorValue =
                params.id && currentCustomTiIndicatorTypeId == CustomTiIndicatorsTypes.Files
                    ? params.id
                    : null;
            var advancedFeaturesObservable = _this._blockIpAndDomainEnabled
                ? _this.advancedFeaturesService.getAdvancedFeaturesSettings()
                : of(null);
            _this._loadDataSubscription && _this._loadDataSubscription.unsubscribe();
            _this._loadDataSubscription = combineLatest(_this.machinesService.getFullUserExposedMachineGroups(), _this.customTiFields.getFields(currentCustomTiIndicatorTypeId), _this.loadCapacity(), advancedFeaturesObservable, function (machineGroups, fields, capacity, advancedFeatures) {
                _this.machineGroups = machineGroups;
                _this._entityCommonFields = fields;
                _this.setCapacityDetails(capacity);
                _this.setActionInfoMessage(advancedFeatures, currentCustomTiIndicatorTypeId);
            }).subscribe(function () {
                _this.selectCustomTiIndicatorType(currentCustomTiIndicatorTypeId);
                _this._customTiIndicatorsChangesSubscription = merge(_this.paris.save$, _this.paris.remove$)
                    .pipe(filter(function (event) { return event.entity === CustomTiIndicator; }), 
                // change dataview options so the data and filters are reloaded
                tap(function () { return _this.setDataviewOptions(); }))
                    .subscribe(function () { return _this.setTabs(); });
            });
            _this._onActionSubscription = _this.entityPanelsService.onAction.subscribe(function ($event) {
                if ($event.action.id === 'delete') {
                    _this.refreshCapacity();
                }
            });
        });
    };
    CustomTiIndicatorsDataviewComponent.prototype.ngOnDestroy = function () {
        this.customTiIndicatorsTypeSubscription && this.customTiIndicatorsTypeSubscription.unsubscribe();
        this._customTiIndicatorsChangesSubscription &&
            this._customTiIndicatorsChangesSubscription.unsubscribe();
        this._routeParamsSubscription && this._routeParamsSubscription.unsubscribe();
        this._loadDataSubscription && this._loadDataSubscription.unsubscribe();
        this._loadCapacitySubscription && this._loadCapacitySubscription.unsubscribe();
        this._onActionSubscription && this._onActionSubscription.unsubscribe();
        this._newDialogSubscription && this._newDialogSubscription.unsubscribe();
        this._importDialogSubscription && this._importDialogSubscription.unsubscribe();
    };
    CustomTiIndicatorsDataviewComponent.prototype.onTableRenderComplete = function () {
        this.performanceSccService.endNgPageLoadPerfSession('indicators.' + this.currentTabId);
    };
    CustomTiIndicatorsDataviewComponent.prototype.getCustomButtonConfig = function () {
        var _this = this;
        var importBtn = this.isUserAllowedActions &&
            !this.currentCustomTiIndicatorsType.readonly &&
            this.currentCustomTiIndicatorsType.id !== CustomTiIndicatorsTypes.Certificate &&
            this.capacityAvailable
            ? [
                {
                    localizedTooltip: this.i18nService.get('customTiIndicator_dataview_actions_import_title'),
                    onClickCallback: function () { return _this.showCustomTiIndicatorImportDialog(); },
                    icon: 'BulkUpload',
                    localizedLabel: this.i18nService.get('customTiIndicator_dataview_actions_import_title'),
                    elementId: 'import-custom-ti-btn',
                    actionType: DataviewActionTypes.Button,
                    dataTrackId: 'ImportBtn_' + this.currentCustomTiIndicatorsType.id,
                    dataTrackType: 'Button',
                },
            ]
            : [];
        return importBtn;
    };
    CustomTiIndicatorsDataviewComponent.prototype.showCustomTiIndicatorImportDialog = function () {
        var _this = this;
        this._importDialogSubscription = this.customTiIndicatorsService
            .showCustomTiIndicatorImportDialog(this.currentCustomTiIndicatorsType)
            .subscribe(function (panel) {
            panel.instance.done.subscribe(function (importSucceeded) {
                if (importSucceeded) {
                    _this.lastUpdate = new Date();
                    _this.refreshCapacity();
                }
            });
        });
    };
    CustomTiIndicatorsDataviewComponent.prototype.showCustomTiIndicatorNewDialog = function (currentCustomTiIndicatorsType) {
        var _this = this;
        this._newDialogSubscription = this.customTiIndicatorsService
            .showCustomTiIndicatorNewDialog(currentCustomTiIndicatorsType)
            .subscribe(function (panel) { return panel.instance.save.subscribe(function () { return _this.refreshCapacity(); }); });
    };
    CustomTiIndicatorsDataviewComponent.prototype.refreshCapacity = function () {
        var _this = this;
        this._loadCapacitySubscription = this.loadCapacity().subscribe(function (capacity) {
            _this.setCapacityDetails(capacity);
        });
    };
    CustomTiIndicatorsDataviewComponent.prototype.selectCustomTiIndicatorType = function (customTiIndicatorTypeId) {
        var _this = this;
        this.customTiIndicatorsTypeSubscription && this.customTiIndicatorsTypeSubscription.unsubscribe();
        this.customTiIndicatorsTypeSubscription = this.paris
            .getItemById(CustomTiIndicatorsType, customTiIndicatorTypeId)
            .subscribe(function (customTiIndicatorType) {
            if ((_this.currentCustomTiIndicatorsType = customTiIndicatorType)) {
                _this.customTiIndicatorsRepository.sourceItem = _this.currentCustomTiIndicatorsType;
                _this.currentTabId = customTiIndicatorTypeId;
                _this.setDataviewOptions();
                _this.setTabs();
            }
        });
    };
    CustomTiIndicatorsDataviewComponent.prototype.setCapacityDetails = function (capacity) {
        this.capacityAvailable = capacity.capacityAvailable;
        this.currentCapacityOptions = this._capacityOptions[capacity.capacityState];
        if (capacity.capacityState !== CapacityState.Unavailable) {
            this.currentCapacityOptions.total = capacity.total.toString();
            this.currentCapacityOptions.outOflimit = this.i18nService.get('customTiIndicator.dataview.actions.capacity.status.available.outOfLimit', { limit: capacity.limit });
        }
    };
    CustomTiIndicatorsDataviewComponent.prototype.setDataviewOptions = function () {
        var _this = this;
        this.fields = this.currentCustomTiIndicatorsType.properties
            .map(function (property) {
            property.name = _this.i18nService.get("customTiIndicator.dataview.entity.fields." + _this.currentCustomTiIndicatorsType.id + "." + property.id);
            return new DataviewField(Object.assign({
                getDisplay: function (item) {
                    return _this.customTiIndicatorsService.getCustomTiDisplay(item, property);
                },
                className: 'nowrap wcd-text-overflow-medium-large',
                filter: _this.customTiIndicatorsService.getCustomTiFilters(property.id, _this.currentCustomTiIndicatorsType.id),
            }, property));
        })
            .concat(this._entityCommonFields);
        this.dataViewConfig = {
            id: 'customTiIndicators_' + this.currentTabId,
            fixedFilterValues: { type: [this.currentCustomTiIndicatorsType.id] },
            showModalOnExport: false,
            exportResults: function (options, format) {
                return _this.customTiIndicatorsService.downloadCsv({
                    type: _this.currentCustomTiIndicatorsType.id,
                    downloadImportCsvSample: false,
                });
            },
        };
        if (this.currentTiIndicatorValue) {
            this.dataViewConfig.fixedOptions = { tiIndicator: this.currentTiIndicatorValue };
        }
    };
    CustomTiIndicatorsDataviewComponent.prototype.setTabs = function () {
        var _this = this;
        this.tabsData = this._allCustomTiIndicatorsTypes.map(function (customTiIndicatorType) {
            return new TabModel({
                id: customTiIndicatorType.id.toString(),
                name: _this.tabNames[customTiIndicatorType.id],
                routerLink: "/preferences2/custom_ti_indicators/" + customTiIndicatorType.id,
                show: function () {
                    switch (customTiIndicatorType.id) {
                        case CustomTiIndicatorsTypes.Certificate: {
                            return _this.featureService.isEnabled(Feature.CertificateBlock);
                        }
                        case CustomTiIndicatorsTypes.MemoryContent: {
                            return false;
                        }
                        default: {
                            return true;
                        }
                    }
                },
            });
        });
    };
    CustomTiIndicatorsDataviewComponent.prototype.loadCapacity = function () {
        return this.paris.apiCall(CustomTiIndicatorsCapacityApiCall);
    };
    CustomTiIndicatorsDataviewComponent.prototype.setActionInfoMessage = function (advancedFeatures, currentCustomTiIndicatorTypeId) {
        switch (currentCustomTiIndicatorTypeId) {
            case CustomTiIndicatorsTypes.Ip:
            case CustomTiIndicatorsTypes.Url: {
                if (!this._blockIpAndDomainEnabled) {
                    this._warningHelpKey = this.i18nService.get('customTiIndicator.dataview.entity.fields.action.values.alertandblock.info.network.notExposed');
                }
                else if (!advancedFeatures || !advancedFeatures.allowWdavNetworkBlock) {
                    this._warningHelpKey = this.i18nService.get('customTiIndicator.dataview.entity.fields.action.values.alertandblock.info.network.turnedOff');
                }
                else {
                    this._warningHelpKey = null;
                }
                break;
            }
            default: {
                this._warningHelpKey = null;
                break;
            }
        }
    };
    return CustomTiIndicatorsDataviewComponent;
}());
export { CustomTiIndicatorsDataviewComponent };
