
		<dl>
			<ng-container *ngIf="includeMachineName">
				<dt>{{ i18nService.strings.machines_entityDetails_fields_name_title }}</dt>
				<dd>
					<machine-name
						[machine]="machine"
						[showIcon]="true"
						[showLink]="true"
						iconCssClass="small-icon"
					>
					</machine-name>
				</dd>
			</ng-container>

			<dt>{{ 'machines.entityDetails.fields.domain.title' | i18n }}</dt>
			<dd [class.subtle]="!machine.domain">
				{{ machine.domain || ('notAvailable.short' | i18n) }}
			</dd>

			<dt>{{ 'machines.entityDetails.fields.operatingSystem.title' | i18n }}</dt>
			<dd>
				<ng-container *ngIf="machine.os?.platform || machine.itpOsVersion; else unknownOs">
					<ng-container *ngIf="machine.os?.platform; else itpOsVersion">
						{{ machine.os.platform.name }}
						{{ machine.os.processor || '' }}
						<span
							*ngIf="machine.os.fullBuild || machine.os.osVersionFriendlyName"
							[ngClass]="isEntityPage ? 'wcd-flex-vertical' : ''"
						>
							<ng-container *ngIf="isEntityPage; else osDetailsNotEntityPage">
								<ng-container *ngIf="machine.os.osVersionFriendlyName">
									{{ 'machines.entityDetails.fields.operatingSystem.osVersion' | i18n }}
									{{ machine.os.osVersionFriendlyName }}
								</ng-container>
								<span></span>
								<ng-container *ngIf="machine.os.fullBuild">
									{{ 'machines.entityDetails.fields.operatingSystem.build' | i18n }}
									{{ machine.os.fullBuild }}
								</ng-container>
							</ng-container>
							<ng-template #osDetailsNotEntityPage>
								{{ osDetailsForSidePane }}
							</ng-template>
						</span>
					</ng-container>
					<ng-template #itpOsVersion>
						{{ machine.itpOsVersion }}
					</ng-template>
				</ng-container>
				<ng-template #unknownOs>
					<span class="disabled">{{ 'notAvailable.short' | i18n }}</span>
				</ng-template>
			</dd>

			<ng-container *ngIf="machine.samName">
				<dt>{{ 'machines.entityDetails.fields.samName.title' | i18n }}</dt>
				<dd>
					{{ machine.samName }}
				</dd>
			</ng-container>

			<ng-container *ngIf="machine.group?.name">
				<dt>{{ 'machines.entityDetails.fields.assetGroup.title' | i18n }}</dt>
				<dd>
					{{ machine.group.name }}
				</dd>
			</ng-container>

			<ng-container *ngIf="machine.isMdatp && machine.isManagedByMdatp">
				<dt>{{ 'machines.entityDetails.fields.healthState.title' | i18n }}</dt>
				<dd>{{ (machine.status ? machine.status.nameI18nKey : 'unknown') | i18n }}</dd>
			</ng-container>

			<ng-container *ngIf="machine.creationTime">
				<dt>{{ 'machines.entityDetails.fields.creationTime.title' | i18n }}</dt>
				<dd>{{ machine.creationTime | date: 'medium' }}</dd>
			</ng-container>

			<ng-container *ngIf="withDataSensitivity && machine.isMdatp && machine.isManagedByMdatp">
				<dt>{{ 'machines.entityDetails.fields.dataSensitivity.title' | i18n }}</dt>
				<dd *ngLet="dataSensitivity$ | async as dataSensitivity">
					{{
						dataSensitivity
							? dataSensitivity.displayText
							: ('machines.entityDetails.fields.dataSensitivity.noData' | i18n)
					}}
				</dd>
			</ng-container>

			<ng-container *ngLet="ipAddresses$ | async as ipAddresses">
				<ng-container *ngIf="withIpAddresses && !loadingIp">
					<dt>{{ 'machines.entityDetails.fields.ipAddresses.title' | i18n }}</dt>
					<dd *ngIf="machine.lastIp">{{ machine.lastIp }}</dd>
					<dd>
						<a
							*ngIf="ipAddresses"
							(click)="machinesService.showMachineIpsPanel(machine)"
							(keydown.enter)="machinesService.showMachineIpsPanel(machine)"
							data-track-id="ShowMachineIpsPanel"
							data-track-type="Button"
							tabindex="0"
						>
							{{ 'machines.entityDetails.fields.ipAddresses.moreInfo' | i18n }}
						</a>
						<ng-container *ngIf="!ipAddresses">
							{{ 'machine.details.device_details.no.ip.addresses.found' | i18n }}
						</ng-container>
					</dd>
				</ng-container>
			</ng-container>

			<ng-container *ngIf="withIotDevices && machine.deviceType">
				<dt>{{ 'machines.dataView.fields.deviceType' | i18n }}</dt>
				<dd>
					{{ deviceTypesMap[machine.deviceType]?.i18nNameKey || 'common.unknown' | i18n }}
				</dd>
			</ng-container>

			<ng-container *ngIf="withIotDevices && machine.onboardingStatus">
				<dt>{{ 'machines.dataView.fields.onboardingState.field.name' | i18n }}</dt>
				<dd>
					{{ onboardingStatusesMap[machine.onboardingStatus].i18nNameKey | i18n }}
				</dd>
			</ng-container>
			<ng-container *ngIf="!isEntityPage">
				<dt>{{ 'machine_details_fields_lastSeen_field_name' | i18n }}</dt>
				<dd>
					<tz-date [date]="machine.lastSeen" [dateFormat]="'medium'"></tz-date>
				</dd>
			</ng-container>

			<ng-container *ngIf="withIotDevices && machine.vendor">
				<dt>{{ 'tvm.common.vendor' | i18n }}</dt>
				<dd>{{ machine.vendor }}</dd>
			</ng-container>

			<ng-container *ngIf="withIotDevices && machine.model">
				<dt>{{ 'machines.dataView.fields.model' | i18n }}</dt>
				<dd>{{ machine.model }}</dd>
			</ng-container>
			<ng-container *ngIf="isMtp">
				<dt>{{ resourcesItpProperty.title }}</dt>
				<dd>
					<ng-container *ngIf="resourcesItpProperty.exposeProperty(machine)">
						<a
							(click)="resourcesItpProperty.seeAllOnClick(machine)"
							(keydown.enter)="resourcesItpProperty.seeAllOnClick(machine)"
							data-track-id="resourcesItpProperty.dataTrackId"
							data-track-type="Button"
							class="btn-inline btn-link no-padding"
							tabindex="0"
							role="link"
						>
							{{ resourcesItpProperty.seeAllText(machine) }}
						</a>
					</ng-container>
					<ng-container *ngIf="!resourcesItpProperty.exposeProperty(machine)">
						{{ 'machine.details.resources.noResourcesFound' | i18n }}
					</ng-container>
				</dd>
			</ng-container>

			<ng-container *ngIf="machine.aadDeviceId">
				<dt>{{ 'machines.entityDetails.fields.deviceAadId' | i18n }}</dt>
				<dd>{{ machine.aadDeviceId }}</dd>
			</ng-container>

			<ng-container *ngIf="machine.deviceOwner">
				<dt>{{ 'machines.entityDetails.fields.deviceOwner' | i18n }}</dt>
				<dd>{{ machine.deviceOwner }}</dd>
			</ng-container>
		</dl>
	