var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { Paris } from '@microsoft/paris';
import { ServiceUrlsService } from '@wcd/app-config';
import { of } from 'rxjs';
import { catchError, map, take, switchMap } from 'rxjs/operators';
import { FileFileDownloadLinkRelationship, FileFileProtectionInfoRelationship, GetSampleStateApiCall, GenerateDownloadUriApiCall, CollectSampleApiCall, } from '@wcd/domain';
import { StoreBackendBase } from '../../../data/models/store.backend.base';
import { CsvService } from '../../../shared/services/csv.service';
import { DownloadService } from '../../../shared/services/download.service';
import { RegExpService } from '@wcd/shared';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
var EXPORT_TO_CSV_TOKEN_URL = 'GetAuthToken';
var EXPORT_TO_CSV_URL = 'DownloadFileSearchResults';
var EXPORT_FILE_OBSERVED_MACHINES_TO_CSV_URL = 'DownloadFileObservedMachinesCsv';
/// Quarantine
var EXPORT_FILE_QUARANTINE_RESULT_TO_CSV_TOKEN_URL = 'remediation/authtoken';
var EXPORT_FILE_QUARANTINE_RESULT_TO_CSV_URL = 'remediation/downloadRemediationStatusCsv';
var FilesBackendService = /** @class */ (function (_super) {
    __extends(FilesBackendService, _super);
    function FilesBackendService(http, downloadService, serviceUrlsService, csvService, appInsightsService, paris) {
        var _this = _super.call(this, http, downloadService) || this;
        _this.serviceUrlsService = serviceUrlsService;
        _this.csvService = csvService;
        _this.appInsightsService = appInsightsService;
        _this.paris = paris;
        return _this;
    }
    Object.defineProperty(FilesBackendService.prototype, "apiUrl", {
        get: function () {
            return this.serviceUrlsService.threatIntel;
        },
        enumerable: true,
        configurable: true
    });
    FilesBackendService.prototype.downloadCsv = function (params) {
        return this.csvService.downloadCsv({
            tokenUrl: this.apiUrl + "/" + EXPORT_TO_CSV_TOKEN_URL + "?exportUrl=" + EXPORT_TO_CSV_URL,
            apiUrl: this.apiUrl + "/" + EXPORT_TO_CSV_URL,
            params: {
                searchTerm: params.searchTerm,
                lookingBackInDays: params.lookingBackInDays,
            },
        });
    };
    FilesBackendService.prototype.downloadFileObservedMachinesCsv = function (sha1) {
        return this.csvService.downloadCsv({
            tokenUrl: this.apiUrl + "/" + EXPORT_TO_CSV_TOKEN_URL + "?exportUrl=" + EXPORT_FILE_OBSERVED_MACHINES_TO_CSV_URL,
            apiUrl: this.apiUrl + "/" + EXPORT_FILE_OBSERVED_MACHINES_TO_CSV_URL,
            params: { sha1: sha1 },
        });
    };
    FilesBackendService.prototype.getFileProtectionInfo = function (fileId) {
        if (!RegExpService.sha1.test(fileId) && !RegExpService.sha256.test(fileId)) {
            return Promise.reject("'" + fileId + "' is not valid SHA1 or SHA256.");
        }
        // initialize a file object with the relevant fields in order to use paris
        var file = {
            id: fileId,
            name: fileId,
            sha1: null,
            sha256: null,
            md5: null,
            firstSeen: new Date(),
            lastSeen: new Date(),
            appType: null,
            fileId: fileId,
        };
        return this.paris
            .getRelatedItem(FileFileProtectionInfoRelationship, file)
            .pipe(take(1))
            .toPromise();
    };
    FilesBackendService.prototype.generateFileDownloadLink = function (fileId) {
        var file = {
            id: fileId,
            name: fileId,
            sha1: null,
            sha256: null,
            md5: null,
            firstSeen: new Date(),
            lastSeen: new Date(),
            appType: null,
            fileId: fileId,
        };
        return this.paris.getRelatedItem(FileFileDownloadLinkRelationship, file);
    };
    FilesBackendService.prototype.generateFileDownloadLinkNew = function (sha, filename, requestorComment, password) {
        var args = {
            sha: sha,
            filename: filename,
            requestorComment: requestorComment,
            zipPassword: password,
        };
        return this.paris
            .apiCall(GenerateDownloadUriApiCall, args)
            .pipe(map(function (downloadResponse) { return downloadResponse.sasUri; }));
    };
    FilesBackendService.prototype.canDownloadFile = function (fileId) {
        var _this = this;
        return this.generateFileDownloadLink(fileId).pipe(map(function (link) { return !!(link && link.downloadUrl); }), catchError(function (error) {
            if (!error.status || (error.status !== 404 && error.status !== 401)) {
                _this.appInsightsService.appInsights.trackException(error);
            }
            return of(false);
        }));
    };
    FilesBackendService.prototype.getSampleState = function (sha, checkPrevalence) {
        return this.paris.apiCall(GetSampleStateApiCall, { sha: sha, checkPrevalence: checkPrevalence });
    };
    FilesBackendService.prototype.downloadFile = function (fileId) {
        var downloadUrl$ = this.generateFileDownloadLink(fileId).pipe(map(function (link) { return link && link.downloadUrl; }));
        return this.downloadService.downloadFromUrl(downloadUrl$, { isAuthenticated: true });
    };
    FilesBackendService.prototype.exportQuarantineActionsResults = function (sha1) {
        this.csvService.downloadCsv({
            tokenUrl: this.serviceUrlsService.userRequests + "/" + EXPORT_FILE_QUARANTINE_RESULT_TO_CSV_TOKEN_URL,
            apiUrl: this.serviceUrlsService.userRequests + "/" + EXPORT_FILE_QUARANTINE_RESULT_TO_CSV_URL,
            params: { FileIdentifier: sha1, FileIdentifierType: 'Sha1' },
        });
    };
    FilesBackendService.prototype.downloadFileNew = function (sha, filename, requestorComment, password) {
        var _this = this;
        return this.generateFileDownloadLinkNew(sha, filename, requestorComment, password).pipe(switchMap(function (url) { return _this.downloadService.downloadFromUrl(url, { isAuthenticated: true }); }));
    };
    FilesBackendService.prototype.collectSample = function (sha, reason) {
        return this.paris.apiCall(CollectSampleApiCall, {
            sha: sha,
            requestorComment: reason,
        });
    };
    return FilesBackendService;
}(StoreBackendBase));
export { FilesBackendService };
