import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter, ViewChild } from '@angular/core';
import { SnmpAuth, SnmpAuthType, SnmpPrivProtocol, SnmpAuthProtocol } from '@wcd/domain';
import { FormControl, NgForm } from '@angular/forms';

@Component({
	selector: 'snmp-auth-type',
	templateUrl: './snmp-auth-type.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'snmpAuthType',
})
export class SnmpAuthTypeComponent {
	@Input() parentForm?: FormControl;
	@Input() label?: string;
	@Input() snmpAuth?: SnmpAuth = new SnmpAuth();
	@Output() snmpAuthChange: EventEmitter<SnmpAuth> = new EventEmitter<SnmpAuth>();
	@ViewChild('snmpAuthTypeForm', { static: false }) public form: NgForm;

	connectionProtocolList = Object.values(SnmpAuthType);
	snmpAuthProtocolList = Object.values(SnmpAuthProtocol);
	snmpPrivProtocolList = Object.values(SnmpPrivProtocol);

	//clear input fields when auth type change
	snmpTypeChange(snmpAuthType: SnmpAuthType) {
		this.snmpAuth = new SnmpAuth();
		this.snmpAuth.type = snmpAuthType;
		this.snmpAuthChanged();
	}

	snmpAuthChanged() {
		this.snmpAuthChange.emit(this.snmpAuth);
	}
}
