var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { StoreBackendBase } from '../../../data/models/store.backend.base';
import { DownloadService } from '../../../shared/services/download.service';
import { HttpClient } from '@angular/common/http';
import { ServiceUrlsService } from '@wcd/app-config';
import { CsvService } from '../../../shared/services/csv.service';
import { FeaturesService, Feature } from '@wcd/config';
var EDIT_MACHINE_TAGS_API_ENDPOINT = 'EditMachineTags';
var EDIT_MACHINES_VALUE_API_ENDPOINT = 'AssetValues';
var ALL_MACHINE_TAGS_URL = 'AllMachinesTags';
var ALL_MACHINE_TAGS_K8S_URL = 'machines/allMachinesTags';
var RBAC_GROUPS_URL = 'UserExposedRbacGroups';
var EXPORT_TO_CSV_TOKEN_URL = 'ExportMachinesQueueToCsv';
var EXPORT_TO_CSV_TOKEN_K8S_URL = 'machines/getExportToken';
var EXPORT_TO_CSV_URL = 'DownloadMachinesQueueCsv';
var EXPORT_TO_CSV_K8S_URL = 'machines/exportToFile';
var securityPropertyToAntiVirusStatusConvertion = {
    AntiVirusNotReporting: 'Unknown',
    AntiVirusEnabled: 'Disabled',
    AntiVirusSignatureVersion: 'NotUpdated',
};
var MachinesBackendService = /** @class */ (function (_super) {
    __extends(MachinesBackendService, _super);
    function MachinesBackendService(http, downloadService, csvService, serviceUrlsService, featuresService) {
        var _this = _super.call(this, http, downloadService) || this;
        _this.csvService = csvService;
        _this.serviceUrlsService = serviceUrlsService;
        _this.featuresService = featuresService;
        return _this;
    }
    Object.defineProperty(MachinesBackendService.prototype, "apiUrl", {
        get: function () {
            return this.serviceUrlsService.threatIntel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MachinesBackendService.prototype, "getExportTokenApiUrl", {
        get: function () {
            var baseUrl = this.apiUrl;
            var path = EXPORT_TO_CSV_TOKEN_URL;
            if (this.featuresService.isEnabled(Feature.MachinesApiServiceMigration)) {
                baseUrl = this.serviceUrlsService.k8s;
                path = EXPORT_TO_CSV_TOKEN_K8S_URL;
            }
            return baseUrl + "/" + path;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MachinesBackendService.prototype, "exportToFileApiUrl", {
        get: function () {
            var baseUrl = this.apiUrl;
            var path = EXPORT_TO_CSV_URL;
            if (this.featuresService.isEnabled(Feature.MachinesApiServiceMigration)) {
                baseUrl = this.serviceUrlsService.k8s;
                path = EXPORT_TO_CSV_K8S_URL;
            }
            return baseUrl + "/" + path;
        },
        enumerable: true,
        configurable: true
    });
    MachinesBackendService.prototype.getMachineGroups = function () {
        var baseUrl = this.apiUrl;
        var endpoint = ALL_MACHINE_TAGS_URL;
        if (this.featuresService.isEnabled(Feature.MachinesControllerMigrationGetTags)) {
            baseUrl = this.serviceUrlsService.k8s;
            endpoint = ALL_MACHINE_TAGS_K8S_URL;
        }
        return this.http.get(baseUrl + '/' + endpoint);
    };
    MachinesBackendService.prototype.getUserExposedRbacGroups = function () {
        return this.get(RBAC_GROUPS_URL);
    };
    MachinesBackendService.prototype.downloadCsv = function (options) {
        // We're transitioning to TVM AV Status, so if the feature is on we'll use TVM AV Status, if not we'll do it the old way
        // For TVM Av Status we introduced a new query parameter - avStatuses, we'll set it up and clear the current one - securityPropertiesRequiringAttention
        var useTvmMachinesAvStatus = (options && options['useTvmMachinesAvStatus']);
        if (useTvmMachinesAvStatus && options.securityPropertiesRequiringAttention) {
            options.avStatuses = Array.from(options.securityPropertiesRequiringAttention).map(function (property) { return securityPropertyToAntiVirusStatusConvertion[property]; }).join(',');
            delete options.securityPropertiesRequiringAttention;
            delete options.useTvmMachinesAvStatus;
        }
        return this.csvService.downloadCsv({
            tokenUrl: "" + this.getExportTokenApiUrl,
            apiUrl: "" + this.exportToFileApiUrl,
            params: options,
        });
    };
    MachinesBackendService.prototype.updateMachinesTags = function (internalMachineIds, tags) {
        var userDefinedTags = tags.filter(function (tag) { return tag.isEditable !== false; });
        return this.post(EDIT_MACHINE_TAGS_API_ENDPOINT, {
            InternalMachineIds: internalMachineIds,
            MachineTags: JSON.stringify(userDefinedTags.map(function (tag) { return tag.name.trim(); })),
        });
    };
    MachinesBackendService.prototype.updateMachinesValues = function (senseMachineIds, value) {
        return this.post(EDIT_MACHINES_VALUE_API_ENDPOINT, {
            SenseMachineIds: senseMachineIds,
            AssetValue: value,
        });
    };
    return MachinesBackendService;
}(StoreBackendBase));
export { MachinesBackendService };
