import { Component, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { SpinnerSize } from 'office-ui-fabric-react';
import { A11yAnnouncerService } from '@wcd/shared';

const REPEAT_ANNOUNCEMENT_INTERVAL = 5000;
enum LoadingStatusKeys {
	loading = 'loading',
	stillLoading = 'stillLoading',
	loadingComplete = 'loadingComplete',
}

@Component({
	selector: 'accessible-spinner',
	template: `
		<fab-spinner
			[label]="customLabel || ('accessibleSpinner.loading' | i18n: { entityName: loadingEntityName })"
			[size]="SpinnerSize[spinnerSize]"
			[labelPosition]="labelPosition"
		></fab-spinner>
	`,
})
export class AccessibleSpinnerComponent implements AfterViewInit, OnDestroy {
	@Input() loadingEntityName?: string = '';
	@Input() customLabel?: string;
	@Input() politeness: 'assertive' | 'polite' = 'assertive';
	@Input() labelPosition: "top" | "right" | "bottom" | "left" = "bottom";
	@Input() spinnerSize: "xSmall" | "small" | "medium" | "large" = "large";

	private loadingTimer = null;
	private stillLoading: boolean = false;
	SpinnerSize = SpinnerSize;

	constructor(private i18nService: I18nService, private liveAnnouncer: A11yAnnouncerService) {}

	ngAfterViewInit(){
		this.announce(true);
	}

	private announce(stillLoading: boolean) {
		this.stillLoading = stillLoading;
		let announcementMessage = this.getMessage(LoadingStatusKeys.loading);
		if (this.stillLoading) {
			this.announcementRepeater();
		} else {
			clearTimeout(this.loadingTimer);
			announcementMessage = this.getMessage(LoadingStatusKeys.loadingComplete);
		}
		this.liveAnnouncer.announce(announcementMessage, this.politeness);
	}

	private announcementRepeater() {
		this.loadingTimer = setTimeout(() => {
			if (this.stillLoading) {
				this.liveAnnouncer.announce(this.getMessage(LoadingStatusKeys.stillLoading));
				this.announcementRepeater();
			}
		}, REPEAT_ANNOUNCEMENT_INTERVAL);
	}

	private getMessage(status: LoadingStatusKeys) {
		if (this.customLabel) {
			return this.customLabel;
		}
		const key = `accessibleSpinner.${status}`;
		return this.i18nService.get(key, {
			entityName: this.loadingEntityName,
		});
	}

	ngOnDestroy() {
		this.announce(false);
		clearTimeout(this.loadingTimer);
	}
}
