var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, EntityModelBase, ValueObject } from '@microsoft/paris';
import { CyberEventEntityRelationTypeEnum } from './cyber-event-entity-relation-type-enum';
export var parseEntityData = function (entity, rawData) {
    entity && (entity["deviceName"] = rawData.deviceName || (rawData.Machine && rawData.Machine.Name));
    entity && (entity["deviceId"] = rawData.deviceId || (rawData.Machine && rawData.Machine.MachineId));
    entity && (entity["relatedAlertIds"] = rawData.alertIds || rawData.relatedAlertIds);
    return entity;
};
var ɵ0 = function (genericFields) { return genericFields ? genericFields.filter(function (d) { return !d.isHidden; }) : null; };
var CyberEventEntity = /** @class */ (function (_super) {
    __extends(CyberEventEntity, _super);
    function CyberEventEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ defaultValue: '' }),
        __metadata("design:type", Object)
    ], CyberEventEntity.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'Item', parse: parseEntityData }),
        __metadata("design:type", EntityModelBase)
    ], CyberEventEntity.prototype, "item", void 0);
    __decorate([
        EntityField({ data: 'EntityType' }),
        __metadata("design:type", Object)
    ], CyberEventEntity.prototype, "entityType", void 0);
    __decorate([
        EntityField({ data: 'RelationToPrecedingEntity' }),
        __metadata("design:type", Number)
    ], CyberEventEntity.prototype, "relationToPrecedingEntity", void 0);
    __decorate([
        EntityField({ data: 'RelationDescription' }),
        __metadata("design:type", String)
    ], CyberEventEntity.prototype, "relationDescription", void 0);
    __decorate([
        EntityField({ data: 'Depth' }),
        __metadata("design:type", Number)
    ], CyberEventEntity.prototype, "depth", void 0);
    __decorate([
        EntityField({ data: 'IsCollapsed' }),
        __metadata("design:type", Boolean)
    ], CyberEventEntity.prototype, "isCollapsed", void 0);
    __decorate([
        EntityField({ data: 'TypedDetails', parse: ɵ0 }),
        __metadata("design:type", Array)
    ], CyberEventEntity.prototype, "genericFields", void 0);
    CyberEventEntity = __decorate([
        ValueObject({
            singularName: 'Cyber event entity',
            pluralName: 'Cyber event entities',
            readonly: true,
        })
    ], CyberEventEntity);
    return CyberEventEntity;
}(EntityModelBase));
export { CyberEventEntity };
export { ɵ0 };
