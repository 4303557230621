var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ApiCall, ApiCallModel, EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { IncidentLinkedByEntity } from '@wcd/domain';
var RelatedIncident = /** @class */ (function (_super) {
    __extends(RelatedIncident, _super);
    function RelatedIncident() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({
            data: 'Category',
        }),
        __metadata("design:type", String)
    ], RelatedIncident.prototype, "category", void 0);
    __decorate([
        EntityField({
            data: 'TargetIncidentId',
        }),
        __metadata("design:type", Number)
    ], RelatedIncident.prototype, "targetIncidentId", void 0);
    __decorate([
        EntityField({
            data: 'Entity',
        }),
        __metadata("design:type", IncidentLinkedByEntity)
    ], RelatedIncident.prototype, "entity", void 0);
    RelatedIncident = __decorate([
        ValueObject({
            singularName: 'Related incident',
            pluralName: 'Related incidents',
            readonly: true,
        })
    ], RelatedIncident);
    return RelatedIncident;
}(ModelBase));
export { RelatedIncident };
var AssociatedIncidentResponse = /** @class */ (function (_super) {
    __extends(AssociatedIncidentResponse, _super);
    function AssociatedIncidentResponse() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({
            data: 'RelatedIncidents',
            arrayOf: RelatedIncident,
        }),
        __metadata("design:type", Array)
    ], AssociatedIncidentResponse.prototype, "relatedIncidents", void 0);
    __decorate([
        EntityField({
            data: 'HasMore',
        }),
        __metadata("design:type", Boolean)
    ], AssociatedIncidentResponse.prototype, "hasMore", void 0);
    AssociatedIncidentResponse = __decorate([
        ValueObject({
            singularName: 'Associated incidents response',
            pluralName: 'Associated incidents responses',
            readonly: true,
        })
    ], AssociatedIncidentResponse);
    return AssociatedIncidentResponse;
}(ModelBase));
export { AssociatedIncidentResponse };
var ɵ0 = function (_, query) {
    return "incidents/" + query.where['id'] + "/relatedIncidentsSample";
}, ɵ1 = function (config) { return config.data.serviceUrls.threatIntel; };
var IncidentAssociatedIncidentsApiCallSample = /** @class */ (function (_super) {
    __extends(IncidentAssociatedIncidentsApiCallSample, _super);
    function IncidentAssociatedIncidentsApiCallSample() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IncidentAssociatedIncidentsApiCallSample = __decorate([
        ApiCall({
            name: 'Incident sample associated incidents',
            endpoint: ɵ0,
            baseUrl: ɵ1,
            method: 'GET',
            type: AssociatedIncidentResponse,
        })
    ], IncidentAssociatedIncidentsApiCallSample);
    return IncidentAssociatedIncidentsApiCallSample;
}(ApiCallModel));
export { IncidentAssociatedIncidentsApiCallSample };
var ɵ2 = function (_, query) {
    return "incidents/" + query.where['id'] + "/relatedIncidents";
}, ɵ3 = function (config) { return config.data.serviceUrls.threatIntel; };
var IncidentAssociatedIncidentsApiCall = /** @class */ (function (_super) {
    __extends(IncidentAssociatedIncidentsApiCall, _super);
    function IncidentAssociatedIncidentsApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IncidentAssociatedIncidentsApiCall = __decorate([
        ApiCall({
            name: 'Incident associated incidents',
            endpoint: ɵ2,
            baseUrl: ɵ3,
            method: 'GET',
            type: AssociatedIncidentResponse,
            cache: true,
        })
    ], IncidentAssociatedIncidentsApiCall);
    return IncidentAssociatedIncidentsApiCall;
}(ApiCallModel));
export { IncidentAssociatedIncidentsApiCall };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
