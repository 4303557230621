import { ApiCallModel, ApiCall } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { ThreatExpertRequest } from './threat-expert.value-object';

@ApiCall({
	name: 'Threat expert request',
	endpoint: 'PostMteRequest',
	method: 'POST',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.mte,
})
export class PostThreatExpertRequestApiCall extends ApiCallModel<void, ThreatExpertRequest> {}
