import { NgModule } from '@angular/core';
import { ActionHistoryEntityTypeService } from './services/action-history.entity-type.service';
import { ActionHistoryEntityPanelComponent } from './components/action-history.entity-panel.component';
import { FabSpinnerModule } from '@angular-react/fabric';
import { SharedModule } from '../../shared/shared.module';
import { GlobalEntityPanelsModule } from '../../global_entities/global-entity-panels.module';
import { RemediationModule } from '../remediation/remediation.module';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { ActionFinderService } from './services/pending-action-finder.service';
import { PendingActionHistoryEntityPanelComponent } from './components/pending-action-history.entity-panel.component';
import { ActionHistoryDetailsFieldComponent } from './components/action-history-details.field.component';
import { ActionHistoryBaseEntityPanelComponent } from './components/action-history-base.entity-panel.component';
import { ActionHistoryDetailsComponent } from './components/action-history-details.component';
import { MailboxEntityTypeModule } from '../mailboxes/mailbox.entity-type.module';
import { ActionCenterService } from './services/action-center.service';
import { FabMessageBarModule } from '@angular-react/fabric';

@NgModule({
	imports: [
		SharedModule,
		GlobalEntitiesModule,
		GlobalEntityPanelsModule,
		RemediationModule,
		FabSpinnerModule,
		MailboxEntityTypeModule,
		FabMessageBarModule,
	],
	declarations: [
		ActionHistoryBaseEntityPanelComponent,
		ActionHistoryEntityPanelComponent,
		PendingActionHistoryEntityPanelComponent,
		ActionHistoryDetailsFieldComponent,
		ActionHistoryDetailsComponent,
	],
	entryComponents: [
		ActionHistoryEntityPanelComponent,
		PendingActionHistoryEntityPanelComponent,
		ActionHistoryDetailsFieldComponent,
	],
	providers: [ActionHistoryEntityTypeService, ActionFinderService, ActionCenterService],
})
export class ActionHistoryEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(ActionHistoryEntityTypeService);
	}
}
