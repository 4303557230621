var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { NO_SID_FALLBACK as ENTITY_NO_SID_FALLBACK } from '@wcd/domain';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { ServiceUrlsService } from '@wcd/app-config';
import { CsvService } from '../../../shared/services/csv.service';
import { compact } from 'lodash-es';
import { RoutesService } from '@wcd/shared';
var EXPORT_TO_CSV_TOKEN_URL = 'GetAuthToken';
var EXPORT_TO_CSV_URL = 'DownloadUserSearchResults';
export var NO_SID_FALLBACK = ENTITY_NO_SID_FALLBACK;
export function getLegacyUserLink(accountName, accountDomainName, sid, alertTime) {
    if (sid === void 0) { sid = NO_SID_FALLBACK; }
    // The AngularJS user page expects a `nosid` string as the `sid` part, if none exists.
    var baseUrl = "/user/" + accountName + "/" + accountDomainName + "/" + sid;
    var time = getIsoAlertTime(alertTime);
    if (time) {
        return baseUrl + "/" + time;
    }
    return baseUrl;
}
export function getUserUrl(_a) {
    var accountName = _a.accountName, accountDomainName = _a.accountDomainName, sid = _a.sid, alertTime = _a.alertTime;
    var url = "/users/" + (sid || NO_SID_FALLBACK);
    var queryParams = {
        accountName: accountName,
        accountDomainName: accountDomainName,
        alertTime: alertTime,
    };
    if (sid) {
        return url;
    }
    var params = compact(Object.keys(queryParams).map(function (key) { return (queryParams[key] ? key + "=" + queryParams[key] : undefined); }));
    return url + "?" + params.join('&');
}
function getIsoAlertTime(alertTime) {
    return alertTime instanceof Date ? alertTime.toISOString() : alertTime;
}
var UsersService = /** @class */ (function () {
    function UsersService(appInsightsService, serviceUrlsService, routesService, csvService) {
        this.appInsightsService = appInsightsService;
        this.serviceUrlsService = serviceUrlsService;
        this.routesService = routesService;
        this.csvService = csvService;
    }
    Object.defineProperty(UsersService.prototype, "apiUrl", {
        get: function () {
            return this.serviceUrlsService.threatIntel;
        },
        enumerable: true,
        configurable: true
    });
    UsersService.prototype.downloadCsv = function (params) {
        this.appInsightsService.trackEvent('UsageTrack', {
            ButtonType: 'ExportToCsv',
            Page: 'User',
            Component: 'UserSearchExportToCsv',
        });
        return this.csvService.downloadCsv({
            tokenUrl: this.apiUrl + "/" + EXPORT_TO_CSV_TOKEN_URL + "?exportUrl=" + EXPORT_TO_CSV_URL,
            apiUrl: this.apiUrl + "/" + EXPORT_TO_CSV_URL,
            params: __assign({}, params),
        });
    };
    UsersService.prototype.getUserLink = function (_a) {
        var accountName = _a.accountName, accountDomainName = _a.accountDomainName, sid = _a.sid, alertTime = _a.alertTime;
        return this.getNewUserLink({ accountName: accountName, accountDomainName: accountDomainName, sid: sid, alertTime: alertTime });
    };
    UsersService.prototype.getNewUserLink = function (_a) {
        var accountName = _a.accountName, accountDomainName = _a.accountDomainName, sid = _a.sid, alertTime = _a.alertTime;
        var isoAlertTime = getIsoAlertTime(alertTime);
        if (this.routesService.unifiedUserPageEnabled) {
            var sccLink = this.routesService.getMdatpFromSccLinkConfig('/user/');
            if (sccLink) {
                return {
                    commands: [sccLink.url],
                    queryParams: __assign({}, ((sid && sid !== NO_SID_FALLBACK) ? { sid: sid } : { accountName: accountName, accountDomainName: accountDomainName }), (isoAlertTime && { alertTime: isoAlertTime })),
                };
            }
        }
        return {
            commands: compact(['/users', sid || NO_SID_FALLBACK, alertTime && 'alerts']),
            queryParams: __assign({}, ((!sid || sid === NO_SID_FALLBACK) && { accountName: accountName, accountDomainName: accountDomainName }), (isoAlertTime && { alertTime: isoAlertTime })),
        };
    };
    UsersService.prototype.getUserUrl = function (userLinkArgs) {
        return getUserUrl(userLinkArgs);
    };
    return UsersService;
}());
export { UsersService };
