import { Injectable } from '@angular/core';
import { NO_SID_FALLBACK as ENTITY_NO_SID_FALLBACK } from '@wcd/domain';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { ServiceUrlsService } from '@wcd/app-config';
import { CsvService } from '../../../shared/services/csv.service';
import { compact } from 'lodash-es';
import { RoutesService } from '@wcd/shared';

const EXPORT_TO_CSV_TOKEN_URL = 'GetAuthToken';
const EXPORT_TO_CSV_URL = 'DownloadUserSearchResults';

export const NO_SID_FALLBACK = ENTITY_NO_SID_FALLBACK;


export interface DownloadUsersSearchCsvParams {
	readonly lookingBackIndays: string;
	readonly userAccount: string;
	readonly userAccountDomain?: string;
}

export interface UserLinkArgs {
	accountName: string;
	accountDomainName: string;
	sid?: string;
	alertTime?: Date | string;
}

export interface UserLinkReturns {
	commands: string[];
	queryParams?: object;
}

export function getLegacyUserLink(
	accountName: string,
	accountDomainName: string,
	sid: string = NO_SID_FALLBACK,
	alertTime?: Date | string
) {
	// The AngularJS user page expects a `nosid` string as the `sid` part, if none exists.
	const baseUrl = `/user/${accountName}/${accountDomainName}/${sid}`;
	const time = getIsoAlertTime(alertTime);
	if (time) {
		return `${baseUrl}/${time}`;
	}

	return baseUrl;
}

export function getUserUrl({ accountName, accountDomainName, sid, alertTime }: UserLinkArgs) {
	const url = `/users/${sid || NO_SID_FALLBACK}`;
	const queryParams = {
		accountName,
		accountDomainName,
		alertTime,
	};

	if (sid) {
		return url;
	}

	const params = compact(
		Object.keys(queryParams).map(key => (queryParams[key] ? `${key}=${queryParams[key]}` : undefined))
	);
	return `${url}?${params.join('&')}`;
}

function getIsoAlertTime(alertTime: Date | string): string {
	return alertTime instanceof Date ? alertTime.toISOString() : alertTime;
}

@Injectable()
export class UsersService {
	constructor(
		private readonly appInsightsService: AppInsightsService,
		private readonly serviceUrlsService: ServiceUrlsService,
		private readonly routesService: RoutesService,
		private readonly csvService: CsvService
	) {}

	get apiUrl(): string {
		return this.serviceUrlsService.threatIntel;
	}

	downloadCsv(params: DownloadUsersSearchCsvParams): Promise<void> {
		this.appInsightsService.trackEvent('UsageTrack', {
			ButtonType: 'ExportToCsv',
			Page: 'User',
			Component: 'UserSearchExportToCsv',
		});

		return this.csvService.downloadCsv({
			tokenUrl: `${this.apiUrl}/${EXPORT_TO_CSV_TOKEN_URL}?exportUrl=${EXPORT_TO_CSV_URL}`,
			apiUrl: `${this.apiUrl}/${EXPORT_TO_CSV_URL}`,
			params: { ...params },
		});
	}

	getUserLink({ accountName, accountDomainName, sid, alertTime }: UserLinkArgs): UserLinkReturns {
		return this.getNewUserLink({ accountName, accountDomainName, sid, alertTime });
	}

	getNewUserLink({ accountName, accountDomainName, sid, alertTime }: UserLinkArgs): UserLinkReturns {
		const isoAlertTime = getIsoAlertTime(alertTime);
		if (this.routesService.unifiedUserPageEnabled) {
			const sccLink = this.routesService.getMdatpFromSccLinkConfig('/user/');
			if (sccLink) {
				return {
					commands: [sccLink.url],
					queryParams: {
						...((sid && sid !== NO_SID_FALLBACK) ? { sid } : { accountName, accountDomainName }),
						...(isoAlertTime && { alertTime: isoAlertTime }),
					},
				}
			}
		}
		return {
			commands: compact(['/users', sid || NO_SID_FALLBACK, alertTime && 'alerts']),
			queryParams: {
				...((!sid || sid === NO_SID_FALLBACK) && { accountName, accountDomainName }),
				...(isoAlertTime && { alertTime: isoAlertTime }),
			},
		};
	}

	getUserUrl(userLinkArgs: UserLinkArgs) {
		return getUserUrl(userLinkArgs);
	}

}
