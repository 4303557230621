import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ChartComponent, ChartSettings } from '../chart.component.base';
import { I18nService } from '@wcd/i18n';

import { format } from 'd3-format';
import * as c3 from 'c3';
import * as d3 from 'd3';
import { TzDateService } from '@wcd/localization';

@Component({
	selector: 'wcd-time-series-chart',
	template: `
		<div class="chart" #timeSeriesChart [attr.aria-label]="'charts.timeSeriesChart' | i18n"
			[ngClass]="className"></div>
	`,
})
export class TimeSeriesChartComponent extends ChartComponent {
	@ViewChild('timeSeriesChart', { static: false }) public timeSeriesChart: HTMLDivElement;
	@Input()
	className: string;

	constructor(
		elementRef: ElementRef,
		public i18nService: I18nService,
		private tzDateService: TzDateService
	) {
		super(elementRef);
	}

	protected chartSettings: ChartSettings = {
		useValues: true,
		xProperty: 'label',
		setColors: true,
		data: {
			order: 'desc',
		},
		options: {
			padding: {
				right: 20,
				top: 1,
			},
			data: {},
			axis: {
				x: {
					tick: {
						format: '%Y-%m-%d',
					},
					type: 'timeseries',
				},
				y: {
					tick: {
						format: format('d'),
					},
					min: 0,
					padding: {
						bottom: 0,
					},
				},
			},
			grid: {
				y: {
					show: true,
				},
			},
			oninit: this.onInit.bind(this),
		},
	};

	onInit() {
		setTimeout( () => {
			const options: c3.ChartConfiguration = this.getChartOptions();
			options.bindto = this.el;

			this.setKeyboardNavigationSupport();
			this.setScreenReaderSupport();
			this.setTooltipKeyboardSupport();
		}, 0);
	}

	private setKeyboardNavigationSupport(){
		d3.selectAll(this.el.querySelectorAll(`circle.c3-circle`))
			.attr('tabindex', '0')
			.attr('focusable', 'true');
	}

	private setScreenReaderSupport(){
		const options: c3.ChartConfiguration = this.getChartOptions();

		d3.select(this.el)
			.attr('role', 'document')
			.attr('aria-label', this.i18nService.get('charts.timeSeriesChart'));

		d3.selectAll(this.el.querySelectorAll(`circle.c3-circle`))
			.each((dataPoint: any, index: number, nodesList: SVGElement[]) => {
				d3.select(nodesList[index]).attr(
					'aria-label',
					`${dataPoint.id},
					${this.i18nService.get('charts_x_axis')} ${options.axis.x.categories[dataPoint.x]},
					${this.i18nService.get('charts_y_axis')} ${dataPoint.value}, `
				);
			});
	}

	private setTooltipKeyboardSupport(){
		d3.selectAll(this.el.querySelectorAll(`circle.c3-circle`))
			.on('keydown',(item)=>{
				if(!d3.event)
					return;
				if (d3.event.code === 'Space'){
					this.showTooltip(item);
					d3.event.preventDefault();
					d3.event.stopPropagation();
				}
				if (d3.event.code === 'Escape'){
					this.hideTooltip();
				}
			})
			.on('focus',(item)=>{
				this.showTooltip(item);
			})
			.on('blur',()=>{
				this.hideTooltip();
			});
	}
}
