import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { Incident } from '../incident/incident.entity';

@ApiCall({
	name: 'Get all incident related machine groups',
	parseQuery: (incident: Incident) => {
		return {
			params: {
				id: incident.id,
			},
		};
	},
	endpoint: (_config, query) => {
		return `incidents/${query.where['id']}/machineGroups`;
	},
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	cache: {
		time: 1000 * 60,
	},
})
export class GetIncidentMachineGroupsApiCall extends ApiCallModel<Array<string>> {}
