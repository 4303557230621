var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { sccHostService } from '@wcd/scc-interface';
export var APC_HEADER_KEY = 'apc';
//@ts-ignore
window.mdatp = window.mdatp || {};
var TenantContextCache = /** @class */ (function () {
    function TenantContextCache() {
        //Use app config for legacy support in MDATP, need to rename to tenantContext
        this._appConfig = {};
        // Do not initiate module in non scc (legacy MDATP)
        if (!sccHostService.isSCC) {
            return;
        }
        this.getTenantContext();
    }
    Object.defineProperty(TenantContextCache.prototype, "apcToken", {
        get: function () {
            return sccHostService.ajax.apcToken;
        },
        set: function (token) {
            sccHostService.ajax.apcToken = token;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TenantContextCache.prototype, "appConfig", {
        // For legacy reasons we still support appConfig
        get: function () {
            return window.mdatp._appConfig || {};
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TenantContextCache.prototype, "hasMtpConsent", {
        get: function () {
            return this.appConfig.MtpConsent;
        },
        enumerable: true,
        configurable: true
    });
    TenantContextCache.prototype.getTenantContext = function (realTime) {
        if (realTime === void 0) { realTime = false; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (window.mdatp._tenantContextPromise) {
                    return [2 /*return*/, window.mdatp._tenantContextPromise];
                }
                return [2 /*return*/, this.loadTenantContext(realTime)];
            });
        });
    };
    TenantContextCache.prototype.nominateTenant = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, sccHostService.ajax.post(this.getTenantNominationUrl()).then()];
            });
        });
    };
    TenantContextCache.prototype.invalidateCache = function () {
        window.mdatp._tenantContextPromise = null;
    };
    TenantContextCache.prototype.loadTenantContext = function (realTime) {
        var _this = this;
        if (realTime === void 0) { realTime = false; }
        return (window.mdatp._tenantContextPromise = sccHostService.ajax
            .get(this.getTenantContextURL(), { params: { realTime: realTime } })
            .catch(function (err) {
            // Bypass the BE tenantContext with a stub context to allow the app to run when BE is not ready
            if (sccHostService.isSCC &&
                err &&
                err.response &&
                err.response.status === 400 &&
                err.response.data &&
                err.response.data.error &&
                err.response.data.error.includes('MtpIsNotEligible')) {
                return { data: { IsStubData: true } };
            }
            // Retry on failed tenantContext requests
            return sccHostService.ajax.get(_this.getTenantContextURL(), { params: { realTime: realTime } });
        })
            .then(function (res) {
            try {
                var apc = res && res.headers && res.headers.get('apc');
                if (apc)
                    _this.apcToken = apc;
                return res.data;
            }
            catch (_a) {
                return res.data;
            }
        })
            .then(function (tc) { return _this.onTCLoaded(tc); })
            .catch(function (err) {
            _this.invalidateCache();
            return Promise.reject(err);
        }));
    };
    TenantContextCache.prototype.getTenantContextURL = function () {
        return sccHostService.mock.isMockMode
            ? sccHostService.mock.mockHost + "/TenantContext"
            : '<mtp>/k8s/mgmt/TenantContext';
    };
    TenantContextCache.prototype.getTenantNominationUrl = function () {
        return sccHostService.mock.isMockMode
            ? sccHostService.mock.mockHost + "/tenantNomination"
            : '<mtp>/k8s/mtp/nomination';
    };
    TenantContextCache.prototype.onTCLoaded = function (tc) {
        if (tc.IsStubData)
            return tc;
        if (tc.AuthInfo) {
            // We shouldn't be storing access token as it gets refreshed every API call, here for MDATP legacy reasons
            tc.AuthInfo.AccessToken = '';
        }
        else {
            var authInfo = {
                UserName: sccHostService.loginUser.displayName,
                AccessToken: '',
            };
            tc.AuthInfo = authInfo;
        }
        if (tc.ServiceUrls) {
            tc.ServiceUrls = sccHostService.mock.getMockHostServiceUrls(tc.ServiceUrls);
        }
        return (window.mdatp._appConfig = tc);
    };
    return TenantContextCache;
}());
export var tenantContextCache = new TenantContextCache();
