<h2 class="wcd-font-weight-bold">{{ 'tvm_networkGear_wizard_scan_step_title' | i18n }}</h2>

<div class="wcd-margin-large-top tight-content">
	<div class="wcd-margin-xLarge-top wcd-margin-xLarge-bottom">
		{{ 'tvm_networkGear_wizard_scan_description' | i18n }}
	</div>

	<wcd-info-box class="wcd-margin-medium-bottom" *ngIf="!isTestScanAllowed">
		{{ 'tvm_networkGear_wizard_review_test_limit' | i18n : { allowedAddressesCount: assessmentJobService.maxAllowedIpAddressesForScan } }}
	</wcd-info-box>

	<fab-primary-button *ngIf="scanButtonState === ScanButtonState.Start" [disabled]="!isTestScanAllowed || data.readOnly" contentClass="wcd-margin-small-top" (onClick)="testConnection() ">
		{{ 'tvm_networkGear_wizard_start_scan_button' | i18n }}
	</fab-primary-button>

	<fab-default-button *ngIf="scanButtonState === ScanButtonState.Stop"
		contentClass="btn-secondary wcd-margin-small-top" (onClick)="onStopClick()">
		{{ 'tvm_networkGear_wizard_stop_scan_button' | i18n }}
	</fab-default-button>

	<fab-default-button *ngIf="scanButtonState === ScanButtonState.Restart"
		contentClass="btn-secondary wcd-margin-small-top" (onClick)="testConnection()">
		{{ 'tvm_networkGear_wizard_restart_scan_button' | i18n }}
	</fab-default-button>
</div>

<br>

<div *ngIf="scanButtonState === ScanButtonState.Stop" class="fab-spinner-margin">
	<progress-indicator *ngIf="isWithProgressBar" [percent]="scanProgressPercentage" [label]="progressBarLabel"></progress-indicator>
	<div *ngIf="isWithProgressBar && sampledScanIPSuccess !== '' && isSampledScanSuccess">{{ sampledScanIPSuccess }} {{ 'tvm_networkGear_wizard_scan_step_progress_bar_confirmed' | i18n }}</div>
	<div *ngIf="isWithProgressBar && sampledScanIPFailure !== '' && !isSampledScanSuccess">{{ sampledScanIPFailure }} {{ 'tvm_networkGear_wizard_scan_step_progress_bar_failed' | i18n }}</div>
	<fab-spinner *ngIf="!isWithProgressBar" [size]="SpinnerSize.large" [label]="'tvm_networkGear_wizard_review_test_loading' | i18n"></fab-spinner>
</div>

<ng-container *ngIf="!data.requiredNewScan && data.canShowScans">
	<div class="dataview">
		<div class="command-bar">
		</div>
	</div>
	<div class="wcd-margin-small-top wcd-font-weight-bold">
		{{ 'tvm_networkGear_wizard_scan_step_results_title' | i18n }} ({{ data.startScanTime }})
	</div>
	<div class="wcd-margin-small-top wcd-margin-medium-bottom wcd-margin-small-bottom">
		{{ 'tvm_networkGear_wizard_scan_step_results_description' | i18n }}
	</div>
	<div>
		<dataview [repository]="repository"
				  [entityType]="entityType"
				  [fields]="fieldsService.fields" [allowFilters]="false" [allowPaging]="false"
				  [allowColumnCustomization]="false"
				  [removePadding]="true"
				  [showDialogOnError]="false" [m365DataError]="true" [label]="'tvm.network.devices.label' | i18n"
				  [padLeft]="false" [isItemSelected]="shouldSelectIpAddress" [options]="dataViewOptions"
				  [dataViewConfig]="dataViewConfig" [shouldShowEntityPanel]="false" [selectAllEnabled]="true"
				  (select)="onItemSelect($event)">
		</dataview>
	</div>
</ng-container>
