import { MdeUserRoleAction } from './user-role-action.entity';

export enum MdeUserRoleActionEnum {
	admin = -1,
	viewData = 1,
	alertsInvestigation = 2,
	remediationActions = 4,
	systemSettings = 8,
	securitySettings = 16,
	liveResponseBasic = 32,
	liveResponseAdvanced = 64,
	tvmViewData = 128,
	tvmRemediationHandling = 256,
	tvmExceptionHandling = 512,
	configurationManagement = 1024,
	tvmApplicationControl = 2048,
}

export const userRoleActionValues: MdeUserRoleAction[] = [
	{
		id: MdeUserRoleActionEnum.admin,
		name: 'admin',
		displayNameKey: 'userRoles_action_admin',
		disabled: true,
		hidden: true,
		isAdmin: true,
		helpKey: '',
	},
	{
		id: MdeUserRoleActionEnum.viewData | MdeUserRoleActionEnum.tvmViewData,
		name: 'viewDataSection',
		displayNameKey: 'userRoles_action_viewData',
		mandatory: true,
		helpKey: '',
		children: [
			{
				id: MdeUserRoleActionEnum.viewData,
				name: 'viewData',
				displayNameKey: 'userRoles_action_securityOperations',
				helpKey: '',
			},
			{
				id: MdeUserRoleActionEnum.tvmViewData,
				name: 'tvmViewData',
				displayNameKey: 'userRoles_action_tvm',
				helpKey: '',
			},
		],
	},
	{
		id:
			MdeUserRoleActionEnum.remediationActions |
			MdeUserRoleActionEnum.tvmRemediationHandling |
			MdeUserRoleActionEnum.tvmExceptionHandling |
			MdeUserRoleActionEnum.tvmApplicationControl,
		name: 'remediationActionsSection',
		displayNameKey: 'userRoles_action_activeRemediationActions',
		helpKey: '',
		children: [
			{
				id: MdeUserRoleActionEnum.remediationActions,
				name: 'remediationActions',
				displayNameKey: 'userRoles_action_securityOperations',
				additionalImplicitAllowedActions: MdeUserRoleActionEnum.viewData,
				helpKey: '',
			},
			{
				id: MdeUserRoleActionEnum.tvmExceptionHandling,
				name: 'tvmExceptionHandling',
				displayNameKey: 'userRoles_action_tvmExceptionHandling',
				additionalImplicitAllowedActions: MdeUserRoleActionEnum.tvmViewData,
				helpKey: '',
			},
			{
				id: MdeUserRoleActionEnum.tvmRemediationHandling,
				name: 'tvmRemediationHandling',
				displayNameKey: 'userRoles_action_tvmRemediationHandling',
				additionalImplicitAllowedActions: MdeUserRoleActionEnum.tvmViewData,
				helpKey: '',
			},
			{
				id: MdeUserRoleActionEnum.tvmApplicationControl,
				name: 'tvmApplicationControl',
				displayNameKey: 'userRoles_action_tvmApplicationHandling',
				additionalImplicitAllowedActions:
					MdeUserRoleActionEnum.tvmViewData | MdeUserRoleActionEnum.tvmRemediationHandling,
				helpKey: '',
				featureFlags: ['TvmApplicationControl', 'TVMApplicationBlock'],
			},
		],
	},
	{
		id: MdeUserRoleActionEnum.alertsInvestigation,
		name: 'alertsInvestigation',
		displayNameKey: 'userRoles_action_alertsInvestigation',
		additionalImplicitAllowedActions: MdeUserRoleActionEnum.viewData,
		helpKey: '',
	},
	{
		id: MdeUserRoleActionEnum.systemSettings,
		name: 'systemSettings',
		displayNameKey: 'userRoles_action_systemSettings',
		additionalImplicitAllowedActions: MdeUserRoleActionEnum.viewData,
		hidden: true,
		helpKey: '',
	},
	{
		id: MdeUserRoleActionEnum.securitySettings,
		name: 'securitySettings',
		displayNameKey: 'userRoles_action_securitySettings',
		additionalImplicitAllowedActions: MdeUserRoleActionEnum.viewData,
		helpKey: '',
	},
	{
		id: MdeUserRoleActionEnum.configurationManagement,
		name: 'configurationManagement',
		displayNameKey: 'userRoles_action_configurationManagement',
		helpKey: '',
		featureFlags: 'EndpointConfigManagement',
	},
	{
		id: MdeUserRoleActionEnum.liveResponseBasic,
		name: 'liveResponseBasic',
		displayNameKey: 'userRoles_action_liveResponseBasic',
		isLiveResponseAction: true,
		additionalImplicitAllowedActions: MdeUserRoleActionEnum.viewData,
		helpKey: '',
	},
	{
		id: MdeUserRoleActionEnum.liveResponseAdvanced,
		name: 'liveResponseAdvanced',
		displayNameKey: 'userRoles_action_liveResponseAdvanced',
		isLiveResponseAction: true,
		additionalImplicitAllowedActions: MdeUserRoleActionEnum.liveResponseBasic | MdeUserRoleActionEnum.viewData,
		helpKey: '',
	},
];

// Pre-tvm roles permissions model
export const deprecatedUserRoleActionValues: MdeUserRoleAction[] = [
	{
		id: MdeUserRoleActionEnum.admin,
		name: 'admin',
		displayNameKey: 'userRoles_action_admin',
		disabled: true,
		hidden: true,
		isAdmin: true,
		helpKey: '',
	},
	{
		id: MdeUserRoleActionEnum.viewData,
		name: 'viewData',
		displayNameKey: 'userRoles_action_viewData',
		disabled: true,
		additionalImplicitAllowedActions: MdeUserRoleActionEnum.tvmViewData,
		helpKey: '',
	},
	{
		id: MdeUserRoleActionEnum.alertsInvestigation,
		name: 'alertsInvestigation',
		displayNameKey: 'userRoles_action_alertsInvestigation',
		additionalImplicitAllowedActions:
			MdeUserRoleActionEnum.tvmExceptionHandling + MdeUserRoleActionEnum.tvmRemediationHandling,
		helpKey: '',
	},
	{
		id: MdeUserRoleActionEnum.remediationActions,
		name: 'remediationActions',
		displayNameKey: 'userRoles_action_activeRemediationActions',
		additionalImplicitAllowedActions:
			MdeUserRoleActionEnum.tvmExceptionHandling + MdeUserRoleActionEnum.tvmRemediationHandling,
		helpKey: '',
	},
	{
		id: MdeUserRoleActionEnum.systemSettings,
		name: 'systemSettings',
		displayNameKey: 'userRoles_action_systemSettings',
		hidden: true,
		additionalImplicitAllowedActions:
			MdeUserRoleActionEnum.tvmExceptionHandling + MdeUserRoleActionEnum.tvmRemediationHandling,
		helpKey: '',
	},
	{
		id: MdeUserRoleActionEnum.securitySettings,
		name: 'securitySettings',
		displayNameKey: 'userRoles_action_securitySettings',
		additionalImplicitAllowedActions:
			MdeUserRoleActionEnum.tvmExceptionHandling + MdeUserRoleActionEnum.tvmRemediationHandling,
		helpKey: '',
	},
	{
		id: MdeUserRoleActionEnum.liveResponseBasic,
		name: 'liveResponseBasic',
		displayNameKey: 'userRoles_action_liveResponseBasic',
		isLiveResponseAction: true,
		additionalImplicitAllowedActions:
			MdeUserRoleActionEnum.tvmExceptionHandling + MdeUserRoleActionEnum.tvmRemediationHandling,
		helpKey: '',
	},
	{
		id: MdeUserRoleActionEnum.liveResponseAdvanced,
		name: 'liveResponseAdvanced',
		displayNameKey: 'userRoles_action_liveResponseAdvanced',
		isLiveResponseAction: true,
		additionalImplicitAllowedActions:
			MdeUserRoleActionEnum.liveResponseBasic +
			MdeUserRoleActionEnum.tvmExceptionHandling +
			MdeUserRoleActionEnum.tvmRemediationHandling,
		helpKey: '',
	},
];
