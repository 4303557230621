var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship } from '@microsoft/paris';
import { File } from '../file.entity';
import { FileProtectionInfo } from '../file-protection-info.value-object';
var ɵ0 = function (file) { return ({ id: file.id }); }, ɵ1 = function (_, query) { return "files/" + query.where['id'] + "/protection"; }, ɵ2 = function (config) { return config.data.serviceUrls.threatIntel; };
var FileFileProtectionInfoRelationship = /** @class */ (function () {
    function FileFileProtectionInfoRelationship() {
    }
    FileFileProtectionInfoRelationship = __decorate([
        EntityRelationship({
            sourceEntity: File,
            dataEntity: FileProtectionInfo,
            getRelationshipData: ɵ0,
            endpoint: ɵ1,
            baseUrl: ɵ2,
            cache: {
                max: 10,
                time: 1000 * 60,
            },
        })
    ], FileFileProtectionInfoRelationship);
    return FileFileProtectionInfoRelationship;
}());
export { FileFileProtectionInfoRelationship };
export { ɵ0, ɵ1, ɵ2 };
