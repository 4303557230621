import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import {
	Alert,
	AlertClassification,
	AlertStatus,
	Incident,
	IncidentAssignmentFilterValues,
	InvestigationStatus,
	MachineGroup,
	Outbreak,
	Severity,
	SeverityId,
	TagType,
} from '@wcd/domain';
import { AuthService } from '@wcd/auth';
import { DataviewField, DataviewFieldConfig } from '@wcd/dataview';
import { compact, lowerFirst } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import {
	FilterValuesChecklistComponent,
	FilterValuesChecklistValueData,
	FilterValuesToggleComponent,
	ListFilterValueConfig,
	SerializedFilters,
} from '@wcd/ng-filters';
import { IconsService, WcdIconNames } from '@wcd/icons';
import { AppConfigService } from '@wcd/app-config';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { ImpactedEntitiesCounterComponent } from '../../../impacted-entities/components/impacted-entities-counter/impacted-entities-counter.component';
import { AppContextService, Feature, FeaturesService, FlavorService } from '@wcd/config';
import { TagsFieldComponent } from '../../../tags/components/tags-field/tags-field.component';
import { EntityNameComponent } from '../../../global_entities/components/entity-name/entity-name.component';
import { IncidentEntityTypeService } from './incident.entity-type.service';
import { getServiceSourceFilterConfig } from '../../common/helpers/service-source-filters.helpers';
import { MachinesFiltersService } from '../../machines/services/machines.filters.service';
import { map } from 'rxjs/operators';
import { FILTER_PRIORITIES } from './incidents.filters.service';
import { OVERFLOW_TAGS_CONTAINER_CLASS } from '../../../tags/components/tags-list/overflow-tags-list.component';
import { EntityTagsService } from '../../common/services/entity-tags.service';
import { AppFlavorConfig } from '@wcd/scc-common';
import { sccHostService } from '@wcd/scc-interface';
export type IncidentOrAlert = Incident | Alert;
type IncidentDataviewFieldConfig = DataviewFieldConfig<IncidentOrAlert>;
type PartialDataviewFiled = Partial<DataviewField>;

export function isIncident(entity: any) {
	return entity instanceof Incident;
}

export interface InvestigationExtraData {
	enabled: PartialDataviewFiled;
	disabled: PartialDataviewFiled;
}

@Injectable()
export class IncidentFields {
	readonly INVESTIGATION_STATE_EXTRA_DATA: InvestigationExtraData;
	private _fields: Array<DataviewField<IncidentOrAlert>>;
	private _fieldsDashboardWidget: Array<DataviewField<IncidentOrAlert>>;
	private readonly showUpdatedIncidentQueue =
		this.featuresService.isEnabled(Feature.UpdatedIncidentQueue) || this.appContextService.isMtp;
	private getShorthandTranslation(key) {
		return this.i18nService.strings[`shorthand_${key}`] || this.i18nService.get(key);
	}

	private readonly fieldsConfig: { [key: string]: IncidentDataviewFieldConfig } = {
		expand: {
			id: 'expand',
			name: '',
			enabledByDefault: true,
			alwaysDisplay: true,
			allowResize: false,
			getFieldCssClass: (entity: IncidentOrAlert) => `${isIncident(entity) ? 'datatable-expand' : ''}`,
			sort: { enabled: false },
			isTabbale: true,
		},
		name: {
			id: 'name',
			name: this.i18nService.strings.incident_fields_name_title,
			enabledByDefault: true,
			className: EntityNameComponent.entityNameDefaultCssClass,
			getFieldCssClass: () => `datatable-group-item-indent-left title-field`,
			component: {
				type: EntityNameComponent,
				getProps: (entity: IncidentOrAlert) => {
					const entityTypeId = isIncident(entity)
						? this.globalEntityTypesService.getEntityType(Incident).id
						: this.globalEntityTypesService.getEntityType(Alert).id;
					return {
						entity,
						entityTypeId,
						entityName: entity.name,
						hideIcon: true,
					};
				},
			},
		},
		tags: {
			id: 'tags',
			name: this.i18nService.strings.incident_fields_tags_title,
			enabledByDefault: true,
			maxWidth: 272,
			minWidth: 56,
			component: {
				type: TagsFieldComponent,
				getProps: (entity) => this.entityTagsService.getEntityTagsFieldProps(entity),
			},
			filter: this.entityTagsService.getIncidentQueueTagsFilterConfig(FILTER_PRIORITIES.tags),
			sort: { enabled: false },
			className: 'nowrap',
			getFieldCssClass: () => `${OVERFLOW_TAGS_CONTAINER_CLASS} wcd-text-overflow-clip`,
			custom: {
				allowFilterValueTracking: true,
			},
		},
		severity: {
			id: 'severity',
			name: this.i18nService.strings.incident_fields_severity_title,
			enabledByDefault: true,
			getDisplay: (entity: IncidentOrAlert) => this.i18nService.get(entity.severity.nameI18nKey),
			getCssClass: (entity: IncidentOrAlert) => `wcd-severity wcd-severity-${entity.severity.type}`,
			custom: {
				allowFilterValueTracking: true,
			},
			sort: {
				sortDescendingByDefault: true,
			},
			filter: {
				priority: FILTER_PRIORITIES.severity,
				component: {
					type: FilterValuesChecklistComponent,
					config: {
						mapFilterValue: (severityId: SeverityId): ListFilterValueConfig<SeverityId> => {
							const severity = this.paris.getValue<Severity, SeverityId>(
								Severity,
								(_severity) => _severity.isSelectable && _severity.id === severityId
							);
							return {
								id: severity.id,
								name: this.i18nService.get(`incident.severity.values.${severity.name}`),
								priority: severity.priority,
								nameClass: `wcd-severity wcd-severity-${severity.type}`,
							};
						},
					},
				},
			},
		},
		id: {
			id: 'id',
			name: this.i18nService.strings.incident_fields_id_title,
			enabledByDefault: false,
			className: EntityNameComponent.entityNameDefaultCssClass,
			sort: { enabled: false },
			getDisplay: (entity: IncidentOrAlert) => {
				if (isIncident(entity)) {
					return entity.id;
				}
				return '-';
			},
		},
		status: {
			id: 'status',
			name: this.i18nService.strings.incident_fields_status_title,
			enabledByDefault: false,
			getDisplay: (entity: IncidentOrAlert) =>
				entity.status
					? entity.status.nameI18nKey
						? this.i18nService.get(entity.status.nameI18nKey)
						: entity.status.name
					: null,
			className: 'nowrap',
			custom: {
				allowFilterValueTracking: true,
			},
		},
		investigationStates: {
			id: 'investigationStates',
			sort: { enabled: false },
			name: this.i18nService.get('alerts.fields.investigationState.title'),
			description: this.appConfigService.isAutomatedIrEnabled
				? null
				: this.i18nService.get('enableAirs.description'),
			headerClass: this.appConfigService.isAutomatedIrEnabled ? null : 'disabled',
			className: 'nowrap',
			custom: {
				allowFilterValueTracking: true,
			},
			filter: {
				priority: FILTER_PRIORITIES.investigationStates,
				component: {
					type: FilterValuesChecklistComponent,
					config: {
						mapFilterValue: (statusId) => {
							const statusIdInt = parseInt(statusId, 10);
							if (!isNaN(statusIdInt)) {
								const status: InvestigationStatus = this.paris.getValue(
									InvestigationStatus,
									statusIdInt
								);
								if (status) {
									return {
										icon: status.iconName && {
											wcdIconName: this.iconsService.getIcon(status.iconName).name,
											className: `color-text-${status.className}`,
										},
										image: status.image,
										name:
											this.i18nService.strings[
												'investigations_status_' + status.type + '_title'
											] || status.name,
										id: status.id,
										priority: status.priority,
									};
								}
							}

							return null;
						},
					},
				},
			},
		},
		classification: {
			id: 'classification',
			name: this.i18nService.strings.incident_fields_classification_title,
			enabledByDefault: true,
			getDisplay: (entity: IncidentOrAlert) =>
				entity.classification
					? this.i18nService.get(entity.classification.nameI18nKey)
					: this.i18nService.strings.common_unclassified,
			className: 'nowrap',
			filter: {
				priority: FILTER_PRIORITIES.classification,
				component: {
					type: FilterValuesChecklistComponent,
					config: {
						mapFilterValue: (classificationType: string) => {
							const classification: AlertClassification = this.paris.getValue(
								AlertClassification,
								(_classification) => _classification.type === classificationType
							);

							return {
								id: classification.type,
								rawValue: classification.type,
								name: this.i18nService.get(classification.nameI18nKey),
								priority: classification.priority,
							};
						},
					},
				},
			},
		},
		determination: {
			id: 'determination',
			name: this.i18nService.strings.incident_fields_determination_title,
			enabledByDefault: false,
			getDisplay: (entity: IncidentOrAlert) =>
				entity.determination
					? entity.determination.nameI18nKey
						? this.i18nService.get(entity.determination.nameI18nKey)
						: entity.determination.name
					: this.i18nService.strings.common_unclassified,
			className: 'nowrap',
			custom: {
				allowFilterValueTracking: true,
			},
		},
		category: {
			id: 'category',
			name: this.i18nService.strings.incident_fields_category_title,
			enabledByDefault: true,
			className: 'nowrap',
			getDisplay: (entity: IncidentOrAlert) => {
				return (
					entity.categories &&
					entity.categories
						.map((x) => this.i18nService.get('reporting_alertsByCategory_' + lowerFirst(x)))
						.join(', ')
				);
			},
			maxWidth: 200,
			sort: { enabled: false },
			filter: {
				priority: FILTER_PRIORITIES.category,
			},
		},
		impactedEntities: {
			id: 'impactedEntities',
			name: this.i18nService.strings.incident_fields_impactedEntities_title,
			enabledByDefault: true,
			className: 'wcd-text-overflow-medium',
			component: {
				type: ImpactedEntitiesCounterComponent,
				getProps: (entity: IncidentOrAlert) => {
					let machine = 0;
					let aaduser = 0;
					let mailbox = 0;
					let app = 0;
					if (entity.impactedEntities) {
						machine =
							entity.impactedEntities.machineCount ||
							(entity.impactedEntities.machines && entity.impactedEntities.machines.length) ||
							0;
						aaduser =
							entity.impactedEntities.userCount ||
							(entity.impactedEntities.users && entity.impactedEntities.users.length) ||
							0;
						mailbox =
							entity.impactedEntities.mailboxCount ||
							(entity.impactedEntities.mailboxes && entity.impactedEntities.mailboxes.length) ||
							0;
						app =
							entity.impactedEntities.appCount ||
							(entity.impactedEntities.apps && entity.impactedEntities.apps.length) ||
							0;
					}
					return {
						entities: entity.impactedEntities,
						entitiesCount: { machine, aaduser, mailbox, app },
					};
				},
			},
			sort: { enabled: true },
		},
		serviceSources: {
			id: 'serviceSource',
			name: this.i18nService.strings.incident_fields_serviceSources_title,
			enabledByDefault: true,
			getDisplay: (entity: IncidentOrAlert) => {
				if (isIncident(entity)) {
					return (
						(entity as Incident).serviceSources &&
						(entity as Incident).serviceSources
							.filter(Boolean)
							.map((source) => this.getShorthandTranslation(source.nameI18nKey))
							.join(', ')
					);
				}
				const serviceSource = (entity as Alert).serviceSource;
				return serviceSource ? this.getShorthandTranslation(serviceSource.nameI18nKey) : null;
			},
			sort: { enabled: false },
			filter: getServiceSourceFilterConfig(FILTER_PRIORITIES.serviceSource),
		},
		detectionSources: {
			id: 'detectionSource',
			name: this.i18nService.strings.incident_fields_detectionSources_title,
			enabledByDefault: false,
			getDisplay: (entity: IncidentOrAlert) => {
				if (isIncident(entity)) {
					return (
						(entity as Incident).detectionSources &&
						(entity as Incident).detectionSources
							.filter(Boolean)
							.map((source) => this.getShorthandTranslation(source.nameI18nKey))
							.join(', ')
					);
				}

				const key = (entity as Alert).detectionSource.nameI18nKey;
				return this.getShorthandTranslation(key);
			},
			filter: !this.shouldShowServiceSources() && {
				priority: FILTER_PRIORITIES.serviceSource,
				component: { type: FilterValuesChecklistComponent },
				requiresData: true,
			},
			sort: { enabled: false },
		},
		activeAlerts: {
			id: 'alertCount',
			name: this.i18nService.strings.incident_fields_alertCount_title,
			enabledByDefault: true,
			getDisplay: (entity: IncidentOrAlert) => {
				if (isIncident(entity)) {
					return `${(entity as Incident).activeAlertCount}/${(entity as Incident).alertCount}`;
				}
				return '-';
			},
			sort: { enabled: true },
		},
		machineGroups: {
			id: 'rbacGroup',
			name: this.i18nService.strings.incident_fields_machineGroups_title,
			enabledByDefault: true,
			className: 'nowrap',
			getDisplay: (entity: IncidentOrAlert) => {
				const getMachineGroupName = (machineGroup: MachineGroup): string => {
					return machineGroup.isUnassignedMachineGroup
						? this.i18nService.strings.machineGroup_unassignedGroup_groupName
						: machineGroup.name;
				};

				if (isIncident(entity)) {
					return (
						(entity as Incident).machineGroups &&
						(entity as Incident).machineGroups
							.filter(Boolean)
							.map((group) => getMachineGroupName(group))
							.join(', ')
					);
				} else {
					if ((entity as Alert).machineGroup) {
						return getMachineGroupName((entity as Alert).machineGroup);
					}
				}
			},
			filter: {
				priority: FILTER_PRIORITIES.rbacGroup,
				component: {
					config: {
						allowSingleValueDeselection: true,
					},
					type: FilterValuesChecklistComponent,
				},
			},
			sort: { enabled: false },
		},
		systemTags: {
			id: 'systemTags',
			name: this.i18nService.strings.incident_fields_systemTags_title,
			enabledByDefault: false,
			component: {
				type: TagsFieldComponent,
				getProps: (entity: IncidentOrAlert) => {
					let tags;
					if (isIncident(entity)) {
						tags = [
							(entity as Incident).sensitivity,
							(entity as Incident).threatExpertI18nNameKey
								? {
										name: this.i18nService.get(
											(entity as Incident).threatExpertI18nNameKey
										),
										type: TagType.threatExpert,
								  }
								: null,
							...(entity as Incident).actors.map((actor) => ({
								name: actor,
								type: TagType.behavior,
							})),
						];
					} else {
						tags = (entity as Alert).systemTags || [];
					}
					return {
						tags: tags.filter(Boolean),
						allowEmptyState: false,
					};
				},
			},
			sort: { enabled: false },
		},
		customTags: {
			id: 'incidentTags',
			name: this.i18nService.strings.incident_fields_incidentTags_title,
			enabledByDefault: false,
			component: {
				type: TagsFieldComponent,
				getProps: (entity: IncidentOrAlert) => {
					if (isIncident(entity)) {
						return {
							tags: (entity as Incident).tags.filter(Boolean).map((tag) => ({
								name: tag,
								type: TagType.user,
							})),
							allowEmptyState: false,
						};
					}
				},
			},
			getIsEmptyField: (entity: IncidentOrAlert) => !isIncident(entity),
			sort: { enabled: false },
			className: 'nowrap',
			custom: {
				allowFilterValueTracking: true,
			},
			filter: {
				priority: FILTER_PRIORITIES.legacyIncidentTags,
				component: {
					type: FilterValuesChecklistComponent,
					config: {
						allowSingleValueDeselection: true,
						mapFilterValue: (
							tag: string,
							value: FilterValuesChecklistValueData<
								string,
								{ isUserDefined?: boolean; isBuiltIn?: boolean }
							>
						): ListFilterValueConfig<string> => ({
							id: tag,
							nameClass: tag === null ? 'subtle' : 'tag',
							name: tag || this.i18nService.strings.incident_fields_incidentTags_untagged,
							priority: tag ? 2 : 1,
							data: value.custom,
							rawValue: 'false',
						}),
					},
				},
			},
		},
		dataSensitivity: {
			// Mutually exclusive with dataSensitivityFilter column
			id: 'sensitivity',
			name: this.i18nService.strings.incident_fields_sensitivity_title,
			enabledByDefault: true,
			maxWidth: 272,
			component: {
				type: TagsFieldComponent,
				getProps: (entity) => this.entityTagsService.getIncidentSensitivityTagFieldProps(entity),
			},
			filter: {
				priority: FILTER_PRIORITIES.sensitivity,
				component: { type: FilterValuesChecklistComponent },
			},
			sort: { enabled: false },
			className: 'nowrap',
			custom: {
				allowFilterValueTracking: true,
			},
		},
		firstActivity: {
			id: 'firstEventTime',
			name: this.i18nService.strings.incident_fields_firstEventTime_title,
			enabledByDefault: false,
			className: 'nowrap',
			component: {
				type: TzDateComponent,
				getProps: (entity: IncidentOrAlert) => {
					const props: any = { date: entity.firstEventTime };
					if (sccHostService.isSCC && this.featuresService.isEnabled(Feature.SccDateFormat)) {
						props.dateFormat = 'fullDateTime';
					}
					return props;
				},
			},
			sort: {
				sortDescendingByDefault: true,
			},
		},
		lastActivity: {
			id: 'lastEventTime',
			name: this.i18nService.strings.incident_fields_lastEventTime_title,
			enabledByDefault: true,
			className: 'nowrap',
			sort: {
				sortDescendingByDefault: true,
			},
			component: {
				type: TzDateComponent,
				getProps: (entity: IncidentOrAlert) => {
					const props: any = { date: entity.lastEventTime };
					if (sccHostService.isSCC && this.featuresService.isEnabled(Feature.SccDateFormat)) {
						props.dateFormat = 'fullDateTime';
					}
					return props;
				},
			},
		},

		// faux columns used only for filters in the updated incidents queue
		dataSensitivityFilter: {
			// Mutually exclusive with dataSensitivity column
			id: 'sensitivity',
			name: this.i18nService.strings.incident_fields_sensitivity_title,
			filter: {
				priority: FILTER_PRIORITIES.sensitivity,
				component: { type: FilterValuesChecklistComponent },
			},
			filterOnly: true,
		},
		actors: {
			id: 'actorName',
			name: this.i18nService.strings.incident_fields_actorName_title,
			filter: {
				priority: FILTER_PRIORITIES.actorName,
				component: {
					config: {
						allowSingleValueDeselection: true,
					},
					type: FilterValuesChecklistComponent,
				},
			},
			filterOnly: true,
		},
		multipleServiceSource: {
			id: 'multipleProductSource',
			name: this.i18nService.strings.incident_fields_multipleServiceSource_title,
			filter: {
				priority: FILTER_PRIORITIES.multipleServiceSource,
				component: {
					type: FilterValuesToggleComponent,
					config: {
						trueLabel: this.i18nService.strings.common_yes,
						falseLabel: this.i18nService.strings.common_no,
						ariaLabel: this.i18nService.strings.incident_fields_multipleServiceSource_title,
					},
				},
				requiresData: false,
			},
			filterOnly: true,
		},
		multipleDetectionSource: {
			id: 'multipleDetectionSource',
			name: this.i18nService.strings.incident_fields_multipleDetectionSource_title,
			filter: {
				priority: FILTER_PRIORITIES.multipleServiceSource,
				component: {
					type: FilterValuesToggleComponent,
					config: {
						trueLabel: this.i18nService.strings.common_yes,
						falseLabel: this.i18nService.strings.common_no,
						ariaLabel: this.i18nService.strings.incident_fields_multipleDetectionSource_title,
					},
				},
				requiresData: false,
			},
			filterOnly: true,
		},
		multipleCategory: {
			id: 'multipleCategory',
			name: this.i18nService.strings.incident_fields_multipleCategory_title,
			filter: {
				priority: FILTER_PRIORITIES.multipleCategory,
				component: {
					type: FilterValuesToggleComponent,
					config: {
						trueLabel: this.i18nService.strings.common_yes,
						falseLabel: this.i18nService.strings.common_no,
						ariaLabel: this.i18nService.strings.incident_fields_multipleCategory_title,
					},
				},
				requiresData: false,
			},
			filterOnly: true,
		},
		osPlatform: {
			id: 'osPlatform',
			name: this.i18nService.strings.alerts_fields_osPlatform_title,
			custom: {
				allowFilterValueTracking: true,
			},
			filter: {
				priority: FILTER_PRIORITIES.osPlatform,
				component: {
					type: FilterValuesChecklistComponent,
					config: {
						mapFilterValue: (category: string) => {
							const osPlatform = this.machinesFiltersService.osPlatformCategoriesMap[category];
							return {
								id: osPlatform.values.map((value) => value.id).join(','),
								name: osPlatform.name,
								priority: osPlatform.priority,
							};
						},
					},
				},
			},
			filterOnly: true,
		},
		incidentAssignment: {
			id: 'incidentAssignment',
			name: this.i18nService.strings.incident_fields_assignedTo_title,
			enabledByDefault: true,
			getDisplay: (entity: IncidentOrAlert) =>
				entity.assignedTo
					? entity.assignedTo.split('@')[0]
					: this.i18nService.strings.assigned_unassigned,
			getCssClass: (entity: IncidentOrAlert) => (entity.assignedTo ? '' : 'subtle'),
			custom: {
				allowFilterValueTracking: true,
			},
			filterName: this.i18nService.strings.incident_fields_incidentAssignment_title,
			sort: { enabled: false },
			filter: {
				requiresData: false,
				priority: FILTER_PRIORITIES.incidentAssignment,
				component: {
					type: FilterValuesChecklistComponent,
					config: {
						mapFilterValue: (incidentAssignment: string) => {
							return {
								id: incidentAssignment,
								name: this.i18nService.get(
									IncidentAssignmentFilterValues[incidentAssignment].nameI18nKey
								),
							};
						},
					},
				},
				serializeFilterValues: (incidentAssignment) =>
					incidentAssignment && !!incidentAssignment.length
						? {
								AssignedTo: this.authService.currentUser.username.toLowerCase(),
								incidentAssignment,
						  }
						: null,
				deserializeFilterValues: (serializedValues: SerializedFilters) => {
					if (serializedValues.incidentAssignment === null) {
						return null;
					}
					return serializedValues.incidentAssignment;
				},
			},
		},
		IoaDefinitionIds: {
			id: 'IoaDefinitionIds',
			name: this.i18nService.strings.alerts_fields_associatedThreat_title,
			className: 'nowrap',
			filterOnly: true,
			filter: {
				priority: FILTER_PRIORITIES.associatedThreats,
				component: {
					type: FilterValuesChecklistComponent,
					config: {
						mapFilterValue: (outbreakId: string) => {
							return this.paris.getItemById(Outbreak, outbreakId).pipe(
								map((outbreak) => {
									return outbreak
										? {
												id: outbreak.id,
												name: outbreak.displayName,
										  }
										: null;
								})
							);
						},
					},
				},
			},
		},
		alertStatus: {
			id: 'AlertStatus',
			name: this.i18nService.strings.status,
			className: 'nowrap',
			filterOnly: true,
			filter: {
				priority: FILTER_PRIORITIES.status,
				component: {
					type: FilterValuesChecklistComponent,
					config: {
						mapFilterValue: (statusType: string): ListFilterValueConfig<string> => {
							const status: AlertStatus = this.paris.getValue(
								AlertStatus,
								(_status) => _status.type === statusType
							);

							return {
								id: status.type,
								rawValue: status.type,
								name: this.i18nService.get(status.nameI18nKey),
								priority: status.priority,
							};
						},
					},
				},
			},
		},
	};

	constructor(
		private authService: AuthService,
		private paris: Paris,
		private globalEntityTypesService: GlobalEntityTypesService,
		private i18nService: I18nService,
		private appContextService: AppContextService,
		private featuresService: FeaturesService,
		private incidentEntityTypeService: IncidentEntityTypeService,
		private entityTagsService: EntityTagsService,
		private machinesFiltersService: MachinesFiltersService,
		private iconsService: IconsService,
		private appConfigService: AppConfigService,
		private flavorService: FlavorService
	) {
		this.INVESTIGATION_STATE_EXTRA_DATA = this.getInvestigationExtraData();
	}

	get fields(): Array<DataviewField<IncidentOrAlert>> {
		if (this._fields) {
			return this._fields;
		}

		this._fields = DataviewField.fromList<IncidentOrAlert>(this.getFieldsConfig());
		return this._fields;
	}

	get dashboardWidgetFields() {
		if (this._fieldsDashboardWidget) {
			return this._fieldsDashboardWidget;
		}
		const fieldsConfig = this.fieldsConfig;
		this._fieldsDashboardWidget = DataviewField.fromList<IncidentOrAlert>([
			fieldsConfig.name,
			fieldsConfig.tags,
			fieldsConfig.severity,
			fieldsConfig.alertCount,
			fieldsConfig.lastActivity,
			fieldsConfig.impactedEntities,
		]);
		return this._fieldsDashboardWidget;
	}

	private getInvestigationExtraData(): InvestigationExtraData {
		return {
			enabled: {
				getImage: (item: IncidentOrAlert) =>
					item.investigationStatus && item.investigationStatus.image,
				icon: {
					wcdIcon: (entity: IncidentOrAlert) => {
						const iconName =
							entity.investigationStatus &&
							entity.investigationStatus.investigationQueued &&
							entity.investigationStatus.iconName;

						return iconName && WcdIconNames[this.iconsService.getIcon(iconName).name];
					},
					className: (item: IncidentOrAlert) =>
						((item as Alert).investigationId ||
							((item as Incident).investigationIds &&
								(item as Incident).investigationIds.length === 1)) &&
						item.investigationStatus
							? `color-text-${item.investigationStatus.className}`
							: null,
				},
				getDisplay: (item: IncidentOrAlert) => {
					if (isIncident(item)) {
						if (
							(item as Incident).investigationStatesCount &&
							(item as Incident).investigationStatesCount > 1
						) {
							return this.i18nService.get('incident_fields_incidentInvestigationStatesCount', {
								count: (item as Incident).investigationStatesCount,
							});
						}
					} else if (item.investigationCount > 1) {
						return this.i18nService.get('incident_fields_alertInvestigationsCount', {
							count: item.investigationCount,
						});
					}

					if (item.investigationStatus) {
						return item.investigationStatus.name;
					}

					return this.i18nService.strings.notAvailable_short;
				},

				getLink: (item: IncidentOrAlert) =>
					!isIncident(item)
						? (item as Alert).investigationId &&
						  `/investigation/${(item as Alert).investigationId}`
						: null,
			},
			disabled: {
				getDisplay: () => this.i18nService.strings.notAvailable_short,
				tooltip: this.i18nService.strings.enableAirs_description,
				className: 'nowrap',
			},
		};
	}

	private getFieldsConfig(): IncidentDataviewFieldConfig[] {
		const fieldsConfig = this.fieldsConfig;
		const showServiceSources = this.shouldShowServiceSources();
		const showJointTagsColumn = this.shouldShowJointTagsColumn();

		return compact([
			fieldsConfig.expand,
			fieldsConfig.name,
			showJointTagsColumn && fieldsConfig.tags,
			fieldsConfig.severity,
			fieldsConfig.id,
			fieldsConfig.alertStatus, //filter only
			fieldsConfig.multipleCategory, //filter only
			this.shouldShowInvestigationStates() ? this.getInvestigationStateDefinision(fieldsConfig) : null,
			fieldsConfig.category,
			fieldsConfig.impactedEntities,
			fieldsConfig.activeAlerts,
			showServiceSources && fieldsConfig.multipleServiceSource, //filter only
			showServiceSources && fieldsConfig.serviceSources,
			!showServiceSources && fieldsConfig.multipleDetectionSource, //filter only
			fieldsConfig.detectionSources,
			!showJointTagsColumn && fieldsConfig.systemTags,
			!showJointTagsColumn && fieldsConfig.customTags,
			fieldsConfig.firstActivity,
			fieldsConfig.lastActivity,
			showJointTagsColumn && fieldsConfig.dataSensitivity,
			fieldsConfig.status,
			fieldsConfig.incidentAssignment,
			fieldsConfig.classification,
			fieldsConfig.determination,
			!showJointTagsColumn && fieldsConfig.dataSensitivityFilter, //filter only
			fieldsConfig.osPlatform, //filter only
			fieldsConfig.machineGroups,
			this.shouldShowActorsFilter() ? fieldsConfig.actors : null, //filter only
			this.shouldShowAssociatedThreatFilter() ? fieldsConfig.IoaDefinitionIds : null, //filter only
		]);
	}

	private shouldShowServiceSources() {
		return this.appContextService.isMtp;
	}

	private shouldShowInvestigationStates() {
		return this.flavorService.isEnabled(AppFlavorConfig.incidents.autoIr);
	}

	private shouldShowActorsFilter() {
		return this.flavorService.isEnabled(AppFlavorConfig.incidents.actors);
	}

	private shouldShowAssociatedThreatFilter() {
		return this.flavorService.isEnabled(AppFlavorConfig.incidents.associatedThreat);
	}

	private shouldShowJointTagsColumn() {
		return this.featuresService.isEnabled(Feature.K8SMigrationIncidentQueue);
	}

	private getInvestigationStateDefinision(fieldsConfig) {
		return Object.assign(
			{ ...fieldsConfig.investigationStates },
			this.appConfigService.isAutomatedIrEnabled
				? this.INVESTIGATION_STATE_EXTRA_DATA.enabled
				: this.INVESTIGATION_STATE_EXTRA_DATA.disabled
		);
	}
}
