import { AxiosRequestConfig, AxiosPromise, AxiosResponse } from 'axios-types';
import { assignIn } from 'lodash-es';
import { SccI18nService } from './i18n.svc';
import { SccMockService } from './mock.svc';
import { SccUiService } from './ui.svc';
/**
 * SCC Http client facade, leverages Axios:
 * API calls with with bearer token for free
 */

export class SccAjaxService {
	private static readonly APC_HEADER: string = 'apc';

	get apcToken() {
		try {
			return sessionStorage.getItem(SccAjaxService.APC_HEADER);
		} catch {
			console.warn('Failed to access session storage');
			return null;
		}
	}

	set apcToken(token: string) {
		try {
			sessionStorage.setItem(SccAjaxService.APC_HEADER, token);
		} catch {
			console.warn('Failed to access session storage');
		}
	}

	private get defaultConfig() {
		const config = {
			withCredentials: true,
			customError: true,
			headers: {
				// TODO(benjamingr) need to pass in tenantId
				// 'MTO-Context': JSON object with tenantIds
				// e.g. { tenantIds: ['<tenantId>', '<tenantId2']>
				// Need to pass this header in MTO anyway for loggin in SCC in addition to MTO-Context
				'Tenant-Id': this.tenantId,
				// For scc ajax to work, we must support a service, in mock, we use MATP as default(
				// dosnt really matter, this just tells the proxy what token/audinace to use, mock server dont care about such things only real server do
				'X-ServiceType': this.mock.isMockMode ? 'MATP' : '',
				'accept-language': this.i18n.language,
			},
		};

		if (this.apcToken) {
			config.headers[SccAjaxService.APC_HEADER] = this.apcToken;
		}

		return config;
	}

	constructor(
		private sccAjax,
		private tenantId: string,
		private mock: SccMockService,
		private i18n: SccI18nService,
		private ui: SccUiService
	) {}

	private setRequestConfig(config: AxiosRequestConfig) {
		const conf = config || {};
		const headers = assignIn({}, this.defaultConfig.headers, conf.headers);
		if (config && config.url) {
			config.url = this.transformUrl(config.url);
		}
		return assignIn({}, this.defaultConfig, conf, { headers });
	}
	private transformUrl(url: string) {
		if (this.mock.isMockMode && !url.startsWith(this.mock.mockHost)) {
			url = url.replace('<msgraph>/beta', '/api');
			url = url.replace(/(?=<)(.*?)(?=\/)/, '');
			return this.mock.mockHost + url;
		}
		return url;
	}

	private afterRequest(options: { showFullResponse: boolean }, res: AxiosResponse) {
		try {
			const apc = res && res.headers ? res.headers[SccAjaxService.APC_HEADER] : null;
			if (apc) this.apcToken = apc;
			if (!res) {
				return;
			}
			const isMto = res.data && res.data.metadata && res.data.result && Object.keys(res.data).length === 2;
			const metadata = res.data.metadata;
			//TODO(benji): log these errors better
			if (isMto && Object.values(metadata).some((x: any) => x.status > 299)) {
				this.ui.showError(metadata.map(x => x.message).join('\n'));
			}
			if (isMto && !options.showFullResponse) {
				res.data = res.data.result; // remove metadata
			}
			// if (isMto && Array.isArray(res.data) && res.data.length === 1) {
			// 	return { ...res, data: res.data[0]};
			// }
			return res;
		} catch (e) {
			console.error('error unwrapping mto response', e);
			return res;
		}
	}

	request<T = any>(config: AxiosRequestConfig): AxiosPromise<T> {
		return this.sccAjax.request(this.setRequestConfig(config)).then(this.afterRequest.bind(this, {}));
	}

	get<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
		return this.sccAjax
			.get(this.transformUrl(url), this.setRequestConfig(config))
			.then(this.afterRequest.bind(this, {}));
	}

	getFull<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<{ results: T, metadata: any }> {
		return this.sccAjax
			.get(this.transformUrl(url), this.setRequestConfig(config))
			.then(this.afterRequest.bind(this, { showFullResponse: true }));
	}

	delete<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
		return this.sccAjax
			.delete(this.transformUrl(url), this.setRequestConfig(config))
			.then(this.afterRequest.bind(this, {}));
	}

	post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
		return this.sccAjax
			.post(this.transformUrl(url), data, this.setRequestConfig(config))
			.then(this.afterRequest.bind(this, {}));
	}

	patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
		return this.sccAjax
			.patch(this.transformUrl(url), data, this.setRequestConfig(config))
			.then(this.afterRequest.bind(this));
	}

	put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
		return this.sccAjax
			.put(this.transformUrl(url), data, this.setRequestConfig(config))
			.then(this.afterRequest.bind(this, {}));
	}
}
