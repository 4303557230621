/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../projects/datatable/src/lib/components/datatable.component.ngfactory";
import * as i2 from "../../../../../../../../../projects/datatable/src/lib/components/datatable.component";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "../../../../../../../../../projects/shared/src/lib/components/accessible-spinner.ngfactory";
import * as i6 from "../../../../../../../../../projects/shared/src/lib/components/accessible-spinner";
import * as i7 from "../../../../../../../../../projects/shared/src/lib/services/a11y-announcer.service";
import * as i8 from "../../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i9 from "../../../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i10 from "../../../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i11 from "../../../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i12 from "@angular/common";
import * as i13 from "../incident.associated-incidents.fields";
import * as i14 from "../../../../../global_entities/services/global-entity-types.service";
import * as i15 from "../../../../alerts/services/alert-incident.service";
import * as i16 from "./associated-incidents-panel.component";
import * as i17 from "@microsoft/paris/dist/lib/paris";
import * as i18 from "@angular/router";
var styles_AssociatedIncidentsPanelComponent = [];
var RenderType_AssociatedIncidentsPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AssociatedIncidentsPanelComponent, data: {} });
export { RenderType_AssociatedIncidentsPanelComponent as RenderType_AssociatedIncidentsPanelComponent };
function View_AssociatedIncidentsPanelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "wcd-datatable", [["class", "wcd-margin-bottom"]], null, null, null, i1.View_DataTableComponent_0, i1.RenderType_DataTableComponent)), i0.ɵdid(1, 13352960, null, 0, i2.DataTableComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.LiveAnnouncer, i4.I18nService, i0.ComponentFactoryResolver, i0.NgZone, i0.Renderer2], { items: [0, "items"], selectEnabled: [1, "selectEnabled"], columns: [2, "columns"], fixedTable: [3, "fixedTable"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf; var currVal_1 = false; var currVal_2 = _co.associatedIncidentsFields.fields; var currVal_3 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_AssociatedIncidentsPanelComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "wcd-full-height wcd-full-height wcd-flex wcd-flex-center-all"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "accessible-spinner", [], null, null, null, i5.View_AccessibleSpinnerComponent_0, i5.RenderType_AccessibleSpinnerComponent)), i0.ɵdid(4, 4374528, null, 0, i6.AccessibleSpinnerComponent, [i4.I18nService, i7.A11yAnnouncerService], { customLabel: [0, "customLabel"] }, null), i0.ɵppd(5, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), "incident_associatedIncidents_loadingPanel")); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_AssociatedIncidentsPanelComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i8.I18nPipe, [i4.I18nService]), (_l()(), i0.ɵeld(1, 0, null, null, 13, "wcd-panel", [], null, [[null, "close"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_PanelComponent_0, i9.RenderType_PanelComponent)), i0.ɵdid(2, 114688, null, 1, i10.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i11.PanelService], { settings: [0, "settings"] }, { close: "close" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t"])), (_l()(), i0.ɵeld(5, 0, null, 1, 2, "h3", [["class", "side-panel-title wcd-margin-bottom wcd-margin-left"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵppd(7, 1), (_l()(), i0.ɵted(-1, 1, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, 1, 2, null, View_AssociatedIncidentsPanelComponent_1)), i0.ɵdid(10, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i12.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, 1, ["\n\t"])), (_l()(), i0.ɵand(0, [["loading", 2]], 1, 0, null, View_AssociatedIncidentsPanelComponent_2)), (_l()(), i0.ɵted(-1, 1, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); var currVal_2 = i0.ɵunv(_v, 10, 0, i0.ɵnov(_v, 11).transform(_co.associatedIncidents$)); var currVal_3 = i0.ɵnov(_v, 13); _ck(_v, 10, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_1 = i0.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i0.ɵnov(_v, 0), "incident.associatedIncidents.title")); _ck(_v, 6, 0, currVal_1); }); }
export function View_AssociatedIncidentsPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "associated-incidents-panel", [], null, null, null, View_AssociatedIncidentsPanelComponent_0, RenderType_AssociatedIncidentsPanelComponent)), i0.ɵprd(512, null, i13.AssociatedIncidentsFields, i13.AssociatedIncidentsFields, [i4.I18nService, i14.GlobalEntityTypesService, i15.AlertIncidentService]), i0.ɵdid(2, 245760, null, 0, i16.AssociatedIncidentsPanelComponent, [i17.Paris, i18.Router, i13.AssociatedIncidentsFields], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var AssociatedIncidentsPanelComponentNgFactory = i0.ɵccf("associated-incidents-panel", i16.AssociatedIncidentsPanelComponent, View_AssociatedIncidentsPanelComponent_Host_0, { settings: "settings", sourceValue: "sourceValue" }, {}, []);
export { AssociatedIncidentsPanelComponentNgFactory as AssociatedIncidentsPanelComponentNgFactory };
