/* tslint:disable:template-click-events-have-key-events */
import { Component, Input, ChangeDetectionStrategy, OnInit, Output, EventEmitter } from '@angular/core';
import { AssessmentJob } from '@wcd/domain';
import { WizardStepConfig } from '@wcd/wizard';
import { AssessmentJobJobDetailsStepComponent } from './wizard-steps/assessment-job-job-details-step.component';
import { AssessmentJobScanStepComponent } from './wizard-steps/assessment-job-scan-step.component';
import { AssessmentJobReviewStepComponent } from './wizard-steps/assessment-job-review-step.component';
import { AssessmentJobModel } from '../models/assessment-job.model';

@Component({
	selector: 'assessment-job-edit',
	templateUrl: './assessment-job-edit.component.html',
	styleUrls: ['./assessment-job-edit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentJobEditComponent implements OnInit {
	@Input() assessmentJob?: AssessmentJob;
	@Input() readOnly?: boolean;

	@Output() close: EventEmitter<void> = new EventEmitter<void>();

	assessmentJobModel: AssessmentJobModel;
	wizardTitle: string;

	ngOnInit() {
		this.assessmentJobModel = new AssessmentJobModel(this.assessmentJob, this.readOnly);
		this.wizardTitle = this.assessmentJobModel.isNewDraft
			? 'tvm_networkGear_wizard_create_title'
			: 'tvm_networkGear_wizard_edit_title';
	}

	closePanel() {
		this.close.emit();
	}

	steps: WizardStepConfig<AssessmentJobModel>[] = [
		{
			id: 1,
			name: 'JobDetails',
			verticalStepKeyName: 'tvm_networkGear_wizard_job_details_step_title',
			component: AssessmentJobJobDetailsStepComponent,
		},
		{
			id: 2,
			name: 'Scan',
			verticalStepKeyName: 'tvm_networkGear_wizard_scan_step_title',
			component: AssessmentJobScanStepComponent,
			onNext: true,
			wideStep: true,
		},
		{
			id: 3,
			name: 'Review',
			verticalStepKeyName: 'tvm_networkGear_wizard_review_step_title',
			component: AssessmentJobReviewStepComponent,
			onNext: true,
			nextButtonText: 'tvm_networkGear_wizard_review_step_next_button_text',
		},
	];
}
