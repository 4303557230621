import { DataviewField } from '@wcd/dataview';
import { Injectable } from '@angular/core';
import { NetworkScanResult } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { TvmColorService, TvmColor } from '../../../../tvm/services/tvm-color.service';
import { FabricIconNames } from '@wcd/scc-common';

@Injectable()
export class NetworkScanResultFieldsService {
    private _fields: Array<DataviewField<NetworkScanResult>>;

    constructor(
        private i18nService: I18nService,
        private tvmColorService: TvmColorService,
    ) { }

    get fields(): Array<DataviewField<NetworkScanResult>> {
        if (!this._fields) {
            // We set always display to all of them as we didn't allow columns customization
            this._fields = DataviewField.fromList<NetworkScanResult>([
                {
                    id: 'ip',
                    name: this.i18nService.get('tvm_networkGear_wizard_fields_device_ip'),
                    alwaysDisplay: true,
                },
                {
                    id: 'scanSuccess',
                    name: this.i18nService.get('tvm_networkGear_wizard_fields_status'),
                    getDisplay: (networkDevice: NetworkScanResult) => {
                        if (networkDevice.scanSuccess) {
                            return this.i18nService.get('tvm_networkGear_wizard_fields_status_success');
                        }
                        return this.i18nService.get('tvm_networkGear_wizard_fields_status_fail');
                    },
                    icon: {
                        fabricIcon: FabricIconNames.StatusCircleInner,
                        className: (networkDevice: NetworkScanResult) => `ms-fontColor-${networkDevice.scanSuccess ? this.tvmColorService.colorsMap.get(TvmColor.Green).name : this.tvmColorService.colorsMap.get(TvmColor.HighRisk).name}`,
                    },
                    alwaysDisplay: true,
                },
                {
                    id: 'errorMessage',
                    name: this.i18nService.get('tvm_networkGear_wizard_fields_failure_reason'),
                    alwaysDisplay: true,
                },
                {
                    id: 'hostName',
                    name: this.i18nService.get('tvm_networkGear_wizard_fields_device_name'),
                    getDisplay: (networkScan: NetworkScanResult) => networkScan.hostName || this.i18nService.get('tvm_network_devices_unknown_device_name'),
                    alwaysDisplay: true,
                },
                {
                    id: 'description',
                    name: this.i18nService.get('tvm_networkGear_wizard_fields_device_description'),
                    alwaysDisplay: true,
                },
            ]);
        }

        return this._fields;
    }
}
