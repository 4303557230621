/* tslint:disable:template-accessibility-alt-text */
import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { attempt, forEach, get } from 'lodash-es';
import {
	ComponentLoading,
	LoadingComponentConfig,
	MainAppState,
	MainAppStateService,
} from './shared/main/services/main-app-state.service';
import { PreloadService } from './preloader/services/preload.service';
import { PendingActionsService } from './@entities/remediation/services/pending-actions.service';
import { AuthService } from '@wcd/auth';
import { ShortcutEvent, ShortcutsService } from './dialogs/shortcuts/shortcuts.service';
import { BehaviorSubject, fromEvent, merge, Subscription } from 'rxjs';
import { Panel, PanelService } from '@wcd/panels';
import { Breadcrumb, breadcrumbsStateService, config, PreloadError } from '@wcd/shared';
import {
	ActivatedRoute,
	ActivatedRouteSnapshot,
	NavigationEnd,
	NavigationStart,
	RouteConfigLoadEnd,
	RouteConfigLoadStart,
	Router,
	RouterEvent,
} from '@angular/router';
import { Location } from '@angular/common';
import { FeaturesService, PreferencesService } from '@wcd/config';
import { AppInsightsService } from './insights/services/app-insights.service';
import { debounceTime, filter } from 'rxjs/operators';
import { ErrorsService } from './shared/services/errors.service';
import {
	EntityErrorEvent,
	EntityErrorTypes,
	Paris,
	RemoveEntitiesEvent,
	SaveEntityEvent,
} from '@microsoft/paris';
import { TrackingEventType } from './insights/models/tracking-event-type.enum';
import { DialogsService } from './dialogs/services/dialogs.service';
import {
	AdvancedFeatures,
	AutomatedIrStatus,
	ComplianceAlertsShareStatus,
	AllowNonAuthenticatedSenseStatus,
	CustomTiIndicator,
	EnvironmentName,
	IntuneIntegrationStatus,
	ItsmProvisioning,
	LiveResponseCommand,
	LiveResponseSession,
	LiveResponseSettings,
	PreviewExperienceSettings,
	SecurityAnalyticsSettings,
	TenantSettings,
	HuntingSharedQuery,
	HuntingUserQuery,
	HuntingScheduledQuery,
	AssessmentJob,
	VulnerabilityNotificationRule,
} from '@wcd/domain';
import { BreadcrumbsService } from './breadcrumbs/services/breadcrumbs.service';
import { AppConfigService } from '@wcd/app-config';
import { QueryParamsCacheService } from './shared/services/query-params-cache.service';
import { EntityPageViewMode } from './global_entities/models/entity-page-view-mode.enum';
import { FLAGS_URL } from './app-routing.module';
import { LocaleConfigService } from '@wcd/localization';
import { SELECTED_LOCALE_KEY, SELECTED_TIMEZONE_KEY } from './shared/main/components/main-header.component';
import { HttpInterceptorService } from './shared/services/http-interceptor.service';
import { wcdInitializeFocusRects } from './utils/wcdInitializeFocusRects/wcdInitializeFocusRects';
import { getPortalLanguage, I18nService } from '@wcd/i18n';
import { SupportCentralService } from './support-central/support-central.service';
import { Prettify } from './utils/services/prettify.service';
import { GlobalEntityTypesService } from './global_entities/services/global-entity-types.service';

declare const moment: typeof import('moment');

const GLOBAL_APP_LOADER: string = '#global-app-loader';

const ENTITIES_WITHOUT_SAVE_SNACKBAR = [
	AdvancedFeatures,
	IntuneIntegrationStatus,
	PreviewExperienceSettings,
	AutomatedIrStatus,
	SecurityAnalyticsSettings,
	TenantSettings,
	CustomTiIndicator,
	LiveResponseCommand,
	LiveResponseSession,
	LiveResponseSettings,
	ItsmProvisioning,
	ComplianceAlertsShareStatus,
	AllowNonAuthenticatedSenseStatus,
	HuntingSharedQuery,
	HuntingUserQuery,
	HuntingScheduledQuery,
	AssessmentJob,
	VulnerabilityNotificationRule,
];

@Component({
	selector: 'app',
	templateUrl: './app.component.html',
})
export class AppComponent implements AfterViewInit, OnDestroy {
	mainAppState: MainAppState;
	panelsWidth: number = 0;
	loaderEl: HTMLDivElement;
	featureFlagToggleValue: boolean;
	featureFlagToggleFeatureId: string;
	EnvironmentName = EnvironmentName;
	entityPageViewMode = EntityPageViewMode;
	subscriptions: Subscription[] = [];
	// Expose breadcrumbs$ to view
	readonly breadcrumbs$: BehaviorSubject<Array<Breadcrumb>> = breadcrumbsStateService.breadcrumbs$;

	private _pendingActionsSubscription: Subscription;
	private _shortcutsSubscription: Subscription;
	private _onResizeSubscription: Subscription;

	constructor(
		public mainAppStateService: MainAppStateService,
		private preloadService: PreloadService,
		private pendingActionsService: PendingActionsService,
		private shortcutsService: ShortcutsService,
		private panelsService: PanelService,
		public route: ActivatedRoute,
		public router: Router,
		private location: Location,
		public authService: AuthService,
		private appInsightsService: AppInsightsService,
		private featuresService: FeaturesService,
		public supportCentralService: SupportCentralService,
		public appConfigService: AppConfigService,
		private dialogsService: DialogsService,
		public breadcrumbsService: BreadcrumbsService,
		private paris: Paris,
		private readonly queryParamsCacheService: QueryParamsCacheService,
		private readonly preferencesService: PreferencesService,
		private readonly localeConfigService: LocaleConfigService,
		private readonly i18nService: I18nService,
		private httpInterceptorService: HttpInterceptorService,
		private globalEntityTypesService: GlobalEntityTypesService,
	) {
		this.router.events.subscribe((event: RouterEvent) => {
			if (event instanceof NavigationStart) {
				// Updates redirectUrl on every route change in order to maintain state on auth refresh
				this.authService.redirectUrl$.next((<NavigationStart>event).url);
			} else if (event instanceof RouteConfigLoadStart || event instanceof RouteConfigLoadEnd) {
				// Listens to component loading start and end in order to show loader
				this.listenToComponentLoadingChanges(event);
			} else if (event instanceof NavigationEnd) {
				this.appInsightsService.trackPageView();
			}

		});

		const lang = getPortalLanguage();
		moment.locale(lang);

		// Listens to route change and shows\hides feature flag toggle
		this.listenToFeatureToggleChanges();

		// Registers breadcrumbs list items change
		this.breadcrumbsService.register();

		// Listens to route change and shows\hides breadcrumbs
		this.listenToBreadcrumbsShowChanges();

		this.loaderEl = <HTMLDivElement>document.querySelector(GLOBAL_APP_LOADER);

		mainAppStateService.state$.subscribe((mainAppState: MainAppState) => {
			this.mainAppState = mainAppState;
		});

		Prettify.init(this.i18nService);

		this.preload();

		this.featuresService.featureChanged$.subscribe((change: { featureId: string; value: boolean }) => {
			const { featureId, value } = change;
			this.trackFeatureChange(featureId, value);
		});

		authService.logout$.subscribe(this.onLogout.bind(this));
		panelsService.activePanels$.subscribe(this.setActivePanels.bind(this));

		this.router.navigateByUrl(location.path());

		// Registers to handle paris global events
		this.subscribeToParisEvents();

		this.queryParamsCacheService.useCachedQueryParams();
		wcdInitializeFocusRects(window);
	}

	onFeatureToggleChange(featureId: string, value: boolean) {
		this.trackFeatureChange(featureId, value, 'Feature flag Opt-in toggle');

		this.featuresService.setLocalFeatureValue(featureId, value);
	}

	ngAfterViewInit(): void {
		// Blur elements on focus when elements loads
		setTimeout(() => {
			(document.activeElement as HTMLElement).blur();
		});
		this.initOnResizeSubscription();
	}

	private initOnResizeSubscription() {
		// Sort the breakpoints once in ascending order
		const breakpoints = Object.entries(config.msScreenSizeBreakpoints).sort((a, b) => a[1] - b[1]);
		const mainContainer = document.querySelector('#app-contents');
		this._onResizeSubscription = fromEvent(window, 'resize')
			.pipe(debounceTime(500))
			.subscribe(() => this.updateScreenMaxWidthBreakpoint(breakpoints, mainContainer));
		this.updateScreenMaxWidthBreakpoint(breakpoints, mainContainer);
		this.subscriptions.push(this._onResizeSubscription);
	}

	private updateScreenMaxWidthBreakpoint(breakpoints: [string, number][], mainContainer: Element) {
		const screenWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
		const mainContainerRect = mainContainer && mainContainer.getBoundingClientRect();
		const mainContainerWidth = (mainContainerRect && mainContainerRect.width > 0 ? mainContainerRect.width : screen.width);

		this.mainAppStateService.toggleStateProperties([
			{
				property: 'screenMaxWidthBreakpoint',
				value: config.getMaxBreakpoint(screenWidth, breakpoints)
			},
			{
				property: 'pageContentMaxWidthBreakpoint',
				value: config.getMaxBreakpoint(mainContainerWidth, breakpoints)
			}
		]);
	}

	private trackFeatureChange(featureId: string, value: boolean, origin?: string) {
		this.appInsightsService.trackEvent(origin || 'Global feature flag change', {
			featureId: featureId,
			enabled: value,
		});
	}

	/**
	 * Listens to Paris global events (save\error\delete) and handles errors, snackbars and tracking
	 */
	private subscribeToParisEvents() {
		this.paris.save$.subscribe((saveEvent: SaveEntityEvent) => {
			this.appInsightsService.track({
				type: TrackingEventType.EntitySave,
				id: saveEvent.entity.entityConfig.singularName,
			});

			const entityType = this.globalEntityTypesService.getEntityType(saveEvent.entity);//new EntityType(saveEvent.entity.entityConfig);

			const singularName =  entityType && entityType.entitySingularNameKey
				? this.i18nService.get(entityType.entitySingularNameKey)
				: saveEvent.entity.entityConfig.singularName;

			if (!ENTITIES_WITHOUT_SAVE_SNACKBAR.includes(saveEvent.entity)) {
				const textMessage = this.i18nService.get(
					saveEvent.isNew ? 'paris_created_message' : 'paris_updated_message',
				);
				this.dialogsService.showSuccessSnackbar({
					text: `${saveEvent.entity.entityConfig.singularName} ${
						saveEvent.newValue && singularName
							? '"' + singularName + '" '
							: ''
					}${textMessage}`,
				});
			}
		});

		this.paris.remove$.subscribe((removeEvent: RemoveEntitiesEvent) => {
			this.appInsightsService.track({
				type: TrackingEventType.EntitiesDelete,
				id: removeEvent.entity.entityConfig.singularName,
				value: removeEvent.items.length,
			});
		});

		this.subscriptions = [
			this.paris.error$
				.pipe(
					filter((errorEvent: EntityErrorEvent) => errorEvent.type === EntityErrorTypes.HttpError),
					filter(
						(errorEvent: EntityErrorEvent) =>
							errorEvent.originalError && errorEvent.originalError.status === 401,
					),
				)
				.subscribe((_: EntityErrorEvent) => this.authService.handleAuthError()),
			this.paris.error$
				.pipe(
					filter((errorEvent: EntityErrorEvent) => errorEvent.type === EntityErrorTypes.HttpError),
					filter(
						(errorEvent: EntityErrorEvent) =>
							errorEvent.originalError && errorEvent.originalError.status === 429,
					),
				)
				.subscribe(_ => this.httpInterceptorService.showHttp429Error()),
		];
	}

	/**
	 * Listens to component loading start and end in order to show loader
	 */
	private listenToComponentLoadingChanges(event: RouterEvent) {
		if (event instanceof RouteConfigLoadStart) {
			const loadingConfig = this.createLoadingConfig(event);
			this.mainAppStateService.toggleStateProperty(
				'loadingComponent',
				Object.assign({ id: ComponentLoading.global }, loadingConfig),
			);
		} else if (event instanceof RouteConfigLoadEnd) {
			this.mainAppStateService.toggleStateProperty('loadingComponent', false);
		}
	}

	private createLoadingConfig(event: RouterEvent & RouteConfigLoadStart) {
		const loadingConfig = get(event, 'route.data.loadingComponentConfig', {}) as LoadingComponentConfig;
		if (loadingConfig.entityTypeNameKey) {
			loadingConfig.description = this.i18nService.get('loading_entity', {
				itemType: this.i18nService.get(loadingConfig.entityTypeNameKey).toLowerCase(),
			});
		} else {
			loadingConfig.description = this.i18nService.get(
				loadingConfig.descriptionKey ? loadingConfig.descriptionKey : 'common_loading',
			);
		}
		return loadingConfig;
	}

	/**
	 * Listens to route change and shows\hides breadcrumbs
	 */
	private listenToBreadcrumbsShowChanges() {
		merge(
			this.router.events.pipe(filter(event => event instanceof NavigationEnd)),
			this.breadcrumbsService.legacyShow$, // can be removed when angularJs router is removed. TODO: remove when angularJs router is removed
		).subscribe((value: RouterEvent | boolean) => {
			let showBreadcrumbs: boolean;
			if (value instanceof RouterEvent) {
				const routeWithBreadcrumbsConfig: ActivatedRouteSnapshot = this.breadcrumbsService.getRouteWithBreadcrumbsConfig(
					this.route.snapshot,
				);
				const routeWithBreadcrumbsConfigData =
					routeWithBreadcrumbsConfig && routeWithBreadcrumbsConfig.data['breadcrumbsConfig'];
				showBreadcrumbs =
					routeWithBreadcrumbsConfigData && routeWithBreadcrumbsConfigData.show !== false;

				if (routeWithBreadcrumbsConfigData && routeWithBreadcrumbsConfigData.pageMode) {
					if (
						routeWithBreadcrumbsConfigData.pageMode === EntityPageViewMode.Modern ||
						routeWithBreadcrumbsConfigData.pageMode === EntityPageViewMode.Asset
					) {
						this.mainAppStateService.toggleStateProperty(
							'pageMode',
							routeWithBreadcrumbsConfigData.pageMode,
						);
					}
				} else {
					this.mainAppStateService.toggleStateProperty('pageMode', EntityPageViewMode.Default);
				}
			} else {
				showBreadcrumbs = value;
				this.mainAppStateService.toggleStateProperty('pageMode', EntityPageViewMode.Default);
			}

			if (this.mainAppState.showBreadcrumbs !== !!showBreadcrumbs) {
				this.mainAppStateService.toggleStateProperty('showBreadcrumbs', showBreadcrumbs);
			}
		});
	}

	/**
	 * Listens to route change and shows\hides feature flag toggle
	 */
	private listenToFeatureToggleChanges() {
		merge(
			this.router.events.pipe(filter(event => event instanceof NavigationEnd)),
			this.featuresService.legacyShowFeatureToggle$, // can be removed when angularJs router is removed. TODO: remove when angularJs router is removed
		).subscribe((value: RouterEvent | string) => {
			let featureFlagId: string;

			if (value instanceof NavigationEnd) {
				const routeWithFeatureFlagConfig: ActivatedRouteSnapshot = this.featuresService.getRouteWithFeatureFlagConfig(
					this.route.snapshot,
				);
				featureFlagId =
					routeWithFeatureFlagConfig &&
					routeWithFeatureFlagConfig.data['featureFlagToggleFeatureId'];
				if (
					featureFlagId &&
					!this.featuresService.isEnabled(routeWithFeatureFlagConfig.data['showToggleFeatureId'])
				) {
					this.featureFlagToggleFeatureId = undefined;
					return;
				}
			} else featureFlagId = <string>value;

			this.featureFlagToggleFeatureId = featureFlagId;
			this.featureFlagToggleValue = featureFlagId && this.featuresService.isEnabled(featureFlagId);
		});
	}

	private preload() {
		this.preloadService.preload$.subscribe(
			() => {
			},
			this.onAppLoadError.bind(this),
			this.onPreload.bind(this),
		);
	}

	private onPreload() {
		this.loaderEl.parentElement.removeChild(this.loaderEl);
		this.mainAppStateService.toggleStateProperty('isInit', true);
		this.setUserPreferencesDefaults();

		if (
			this.appConfigService.isOnboardingComplete &&
			!this.appConfigService.isSuspended
		)
			this.appConfigService.isAutomatedIrEnabled$.subscribe((isAutomatedIrEnabled: boolean) => {
				this._pendingActionsSubscription && this._pendingActionsSubscription.unsubscribe();
				if (isAutomatedIrEnabled && this.pendingActionsService.shouldDisplayNotifications())
					this._pendingActionsSubscription = this.pendingActionsService.pendingActions$.subscribe();
			});

		this.shortcutsService.init();
		this._shortcutsSubscription = this.shortcutsService.events$
			.pipe(filter((event: ShortcutEvent) => event === ShortcutEvent.flags))
			.subscribe(() => {
				this.router.navigate([FLAGS_URL]);
			});

		this.authService.setRefreshToken();
		if (this.appConfigService.appNavigateStartTime)
			this.appInsightsService.trackEvent(
				'UI Init static',
				{ init: true },
				{
					time:
						this.preloadService.preloadStartTime.valueOf() -
						this.appConfigService.appNavigateStartTime.valueOf(),
				},
			);

		const clientRect = document.querySelector('body').getBoundingClientRect();
		this.appInsightsService.trackEvent(
			'UI Preload data',
			{
				init: true,
				displayWidth: window.screen.width,
				displayHeight: window.screen.height,
				browserWidth: clientRect.width,
				browserHeight: clientRect.height,
			},
			{ time: this.preloadService.preloadTime },
		);
	}

	private onLogout() {
		this._pendingActionsSubscription && this._pendingActionsSubscription.unsubscribe();
		this._shortcutsSubscription && this._shortcutsSubscription.unsubscribe();
	}

	private onAppLoadError(error: PreloadError | Error | Response) {
		this.loaderEl.parentElement.removeChild(this.loaderEl);
		const originalError: Error | Response = (<PreloadError>error).error || <Error | Response>error;
		const message: string =
			(<PreloadError>error).message || (<Response>originalError).status === 404
				? 'Couldn\'t reach API.'
				: (<Error>error).stack || (<Response>error).statusText;

		const appLoadError: AppLoadError = {
			text: message,
			api: (<Response>originalError).url,
			description: ErrorsService.getErrorMessage(originalError),
			status: (<Response>originalError).status,
		};

		this.appInsightsService.trackException(new Error(appLoadError.description), 'Preload', appLoadError, {
			preloadTime: this.preloadService.preloadTime,
		});

		setTimeout(() => {
			if (!this.authService.loginError)
				this.router.navigate(['/Error']).then((navigated: boolean) => {
					if (navigated) this.mainAppStateService.toggleStateProperty('isInit', true);
				});
		});
	}

	private setActivePanels(panels: Array<Panel>) {
		this.panelsWidth = panels.reduce((totalWidth: number, panel) => {
			if (panel.isModal) return totalWidth;

			return totalWidth + PanelService.getPanelTypeWidth(panel.type);
		}, 0);
	}

	private setUserPreferencesDefaults() {
		const userLocalePreference = this.preferencesService.getPreference(SELECTED_LOCALE_KEY);
		if (userLocalePreference) this.localeConfigService.setSelectedLocale(userLocalePreference);
		const userTimezonePreference = this.preferencesService.getPreference(SELECTED_TIMEZONE_KEY);
		if (userTimezonePreference) this.localeConfigService.setSelectedTimezone(userTimezonePreference);
	}

	ngOnDestroy() {
		forEach(this.subscriptions, sub => attempt(() => sub.unsubscribe()));
	}
}

export interface AppLoadError {
	text: string;
	api: string;
	description: string;
	status: number;
}
