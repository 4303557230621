import { Injectable } from '@angular/core';
import { EntityModelBase, Paris } from '@microsoft/paris';
import { FabricIconNames } from '@wcd/scc-common';
import {
	CustomTiIndicatorsType,
	CustomTiIndicatorsTypes,
	CustomTiIndicatorTypeExclusionsRelationship,
} from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { FeaturesService, Feature } from '@wcd/config';
import { ItemActionModelConfig } from '../../../dataviews/models/item-action.model';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { CustomTiIndicatorsService } from '../../custom_ti_indicators/services/custom-ti-indicators.service';

@Injectable()
export class EntityCustomTiActionsConfigService {
	// notification source for triggering an action refresh after an "Add" side-panel was closed
	private readonly _onActionsRefresh$ = new BehaviorSubject<boolean>(true);

	constructor(
		private readonly paris: Paris,
		private readonly customTiIndicatorsService: CustomTiIndicatorsService,
		private readonly i18nService: I18nService,
		private featureService: FeaturesService
	) {}

	getCommandBarActions<T extends EntityModelBase<string | number>>(
		indicatorValue: string,
		indicatorTypeId: CustomTiIndicatorsTypes,
		entityToIndicatorValueConverter: (entity: T) => string,
		indicatorFilters?: string[]
	): Observable<ItemActionModelConfig> {
		if (!this.featureService.isEnabled(Feature.TiIndicators)) {
			return of(null);
		}

		indicatorFilters = indicatorFilters || [indicatorValue];

		const customIndicatorsRepo = this.paris.getRelationshipRepository(
			CustomTiIndicatorTypeExclusionsRelationship
		);

		const indicatorType = this.paris.getValue(CustomTiIndicatorsType, indicatorTypeId);
		customIndicatorsRepo.sourceItem = indicatorType;

		const addIndicatorActionConfig: ItemActionModelConfig = {
			id: 'addIndicator',
			name: this.i18nService.get('entityCommon.commandBar.indicator.add.title', {
				type: indicatorType.name,
			}),
			tooltip: this.i18nService.get('entityCommon.commandBar.indicator.add.tooltip', {
				type: indicatorType.name,
			}),
			icon: FabricIconNames.Add,
			refreshOnResolve: false,
			rbac: [RbacMdeAllowedActions.remediationActions, RbacMdeAllowedActions.securitySettings],
			rbacState: RbacControlState.disabled,
			method: async ([entity]) => {
				this.customTiIndicatorsService
					.showCustomTiIndicatorNewDialog(indicatorType, entityToIndicatorValueConverter(entity))
					.subscribe(panel => {
						panel.onDestroy(() => {
							this._onActionsRefresh$.next(true);
						});
					});
			},
		};

		const editIndicatorActionConfig: ItemActionModelConfig = {
			id: 'editIndicator',
			name: this.i18nService.get('entityCommon.commandBar.indicator.edit.title', {
				type: indicatorType.name,
			}),
			tooltip: this.i18nService.get('entityCommon.commandBar.indicator.edit.tooltip', {
				type: indicatorType.name,
			}),
			icon: FabricIconNames.Edit,
			href:
				`/preferences2/custom_ti_indicators/${indicatorType.id}?filters=` +
				encodeURI('tiIndicator=' + indicatorFilters.join('|')),
			rbac: [RbacMdeAllowedActions.remediationActions],
			rbacState: RbacControlState.disabled,
		};

		return this._onActionsRefresh$.pipe(
			switchMap(() =>
				this.customIndicatorExists(indicatorFilters, indicatorType).pipe(
					map(result => (result ? editIndicatorActionConfig : addIndicatorActionConfig))
				)
			),
			catchError(err => of(null))
		);
	}

	public customIndicatorExists(
		indicatorFilters: string[],
		indicatorType: CustomTiIndicatorsType
	): Observable<boolean | {}[]> {
		const customIndicatorsRepo = this.paris.getRelationshipRepository(
			CustomTiIndicatorTypeExclusionsRelationship
		);

		customIndicatorsRepo.sourceItem = this.paris.getValue(CustomTiIndicatorsType, indicatorType.id);

		// Search if a File based indicator exists for current File
		return customIndicatorsRepo
			.query({
				where: {
					tiIndicator: indicatorFilters,
				},
			})
			.pipe(map(dataSet => dataSet.items.length > 0));
	}

	public getCustomIndicators(
		indicatorFilters: string[],
		indicatorType: CustomTiIndicatorsType,
	): Observable<boolean | {}[]> {
		const customIndicatorsRepo = this.paris.getRelationshipRepository(
			CustomTiIndicatorTypeExclusionsRelationship
		);
		customIndicatorsRepo.sourceItem = this.paris.getValue(CustomTiIndicatorsType, indicatorType.id);
		return customIndicatorsRepo
			.query({
				where: {
					tiIndicator: indicatorFilters,
				},
			})
			.pipe(map(dataSet => dataSet.items));
	}
}
