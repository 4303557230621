var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-accessibility-alt-text */
/* tslint:disable:template-accessibility-label-for */
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { AuthService } from '@wcd/auth';
import { I18nService } from '@wcd/i18n';
import { PostThreatExpertRequestApiCall, ThreatExpertRequest, BilbaoLicenseApiCall, } from '@wcd/domain';
import { ContentState } from '@wcd/contents-state';
import { Paris } from '@microsoft/paris';
import { MessageBarType, SpinnerSize } from 'office-ui-fabric-react';
import { RbacControlService } from '../../rbac/services/rbac-control.service';
import { RbacMdeAllowedActions } from '../../rbac/enums/mde-allowed-actions.enum';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { config } from '@wcd/shared';
import { MainAppStateService } from '../../shared/main/services/main-app-state.service';
import { filter } from 'rxjs/operators';
var BANNER_TITLE_PREFIX = 'threatExpert.banner';
var AskTheExpertPanelComponent = /** @class */ (function (_super) {
    __extends(AskTheExpertPanelComponent, _super);
    function AskTheExpertPanelComponent(router, authService, i18nService, paris, changeDetectorRef, rbacControlService, dialogsService, mainAppStateService) {
        var _this = _super.call(this, router) || this;
        _this.authService = authService;
        _this.i18nService = i18nService;
        _this.paris = paris;
        _this.changeDetectorRef = changeDetectorRef;
        _this.rbacControlService = rbacControlService;
        _this.dialogsService = dialogsService;
        _this.isSaving = false;
        _this.ContentStates = ContentState;
        _this.bilbaoLicenseState = ContentState.Loading;
        _this.isFormDisabled = false;
        _this.showBanner = true;
        _this.SpinnerSize = SpinnerSize;
        _this.isNarrowLayout = false;
        _this.cause = window.location.href;
        _this.setEmail();
        mainAppStateService.state$
            .pipe(filter(function (state) {
            return (_this.isNarrowLayout !==
                config.widthBreakpointSmallerThan(state.screenMaxWidthBreakpoint, config.msScreenSizeBreakpoints.small));
        }))
            .subscribe(function (state) {
            _this.isNarrowLayout = config.widthBreakpointSmallerThan(state.screenMaxWidthBreakpoint, config.msScreenSizeBreakpoints.small);
            _this.changeDetectorRef.markForCheck();
        });
        return _this;
    }
    AskTheExpertPanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.paris.apiCall(BilbaoLicenseApiCall).subscribe(function (bilbaoData) {
            _this.bilbaoLicenseState = ContentState.Complete;
            _this.setBannerTitle(bilbaoData);
            _this.changeDetectorRef.detectChanges();
        }, function () {
            _this.bilbaoLicenseState = ContentState.Error;
            _this.changeDetectorRef.detectChanges();
        });
    };
    AskTheExpertPanelComponent.prototype.setEmail = function () {
        this.email = this.authService.currentUser.name;
    };
    AskTheExpertPanelComponent.prototype.submitRequest = function () {
        var _this = this;
        if (this.isSaving) {
            return;
        }
        this.isSaving = true;
        this.isFormDisabled = true;
        this.messageBarType = MessageBarType.info;
        this.messageBarText = this.i18nService.get('threatExpert.submitting');
        this.paris
            .apiCall(PostThreatExpertRequestApiCall, new ThreatExpertRequest({
            questionArea: this.cause,
            questionBody: this.message,
            sourceUrl: window.location.href,
            userEmail: this.email,
            language: 'en-us',
            requestId: crypto.getRandomValues(new Uint32Array(4)).join('-'),
        }))
            .subscribe(function () {
            _this.dialogsService.showSuccessSnackbar({
                text: _this.i18nService.get('threatExpert.submitSuccessMsg'),
            });
            _this.closePanel();
        }, function () {
            _this.messageBarType = MessageBarType.error;
            _this.messageBarText = _this.i18nService.get('threatExpert.submitErrorMsg');
            _this.isFormDisabled = false;
        }, function () {
            _this.hasSubmitted = true;
            _this.isSaving = false;
            _this.changeDetectorRef.detectChanges();
        });
    };
    AskTheExpertPanelComponent.prototype.setBannerTitle = function (licenseData) {
        var hadTrial = licenseData.inTrial || licenseData.daysLeft === 0; // based on backend design
        if (licenseData.enabled || hadTrial) {
            if (!this.rbacControlService.hasRequiredMdePermission([RbacMdeAllowedActions.securitySettings])) {
                // Check permissions before allowing the user to submit EOD request
                this.isFormDisabled = true;
                this.bannerTitle = this.i18nService.get(BANNER_TITLE_PREFIX + ".titleNoPermissions");
                this.bannerDescription = this.i18nService.get(BANNER_TITLE_PREFIX + ".descriptionNoPermissions");
            }
            else if (licenseData.enabled) {
                // Valid EOD subscription
                this.showBanner = false;
            }
            else if (licenseData.inTrial) {
                // Trial mode
                this.bannerTitle = this.i18nService.get(BANNER_TITLE_PREFIX + ".titleTrial");
                this.bannerDescription = this.i18nService.get(BANNER_TITLE_PREFIX + ".descriptionTrial", {
                    days: licenseData.daysLeft,
                });
            }
            else {
                // Expired trial
                this.isFormDisabled = true;
                this.bannerTitle = this.i18nService.get(BANNER_TITLE_PREFIX + ".titleTrialExpired");
                this.bannerDescription = this.i18nService.get(BANNER_TITLE_PREFIX + ".descriptionTrialExpired");
            }
        }
        else {
            // Not enrolled state
            this.isFormDisabled = true;
            this.bannerTitle = this.i18nService.get(BANNER_TITLE_PREFIX + ".titleNotEnrolled");
            this.bannerDescription = this.i18nService.get(BANNER_TITLE_PREFIX + ".descriptionNotEnrolled");
        }
        if (licenseData.enabled || licenseData.inTrial) {
            this.formBodyPlaceholder = this.i18nService.get("threatExpert.form.body.placeholder");
        }
        else {
            this.formBodyPlaceholder = this.i18nService.get("threatExpert.form.body.disabledPlaceholder");
        }
    };
    return AskTheExpertPanelComponent;
}(PanelContainer));
export { AskTheExpertPanelComponent };
