import { EntityRelationship, EntityRelationshipRepositoryType, RelationshipType } from '@microsoft/paris';
import { File } from '../file.entity';
import { FileStats } from '../file-stats.value-object';

@EntityRelationship({
	sourceEntity: File,
	dataEntity: FileStats,
	allowedTypes: [RelationshipType.OneToOne],
	baseUrl: config => config.data.serviceUrls.threatIntel,
	endpoint: 'FileStats',
	getRelationshipData: (file: File) => (file.sha1 ? { sha1: file.sha1 } : { sha256: file.sha256 }),
	cache: {
		max: 5,
		time: 5 * 60 * 1000,
	},
	timeout: 2 * 60 * 1000, // This request takes a long (l-o-n-g) time on average!
})
export class FileFileStatsRelationship implements EntityRelationshipRepositoryType<File, FileStats> {}
