import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthBackendService } from './services/auth.backend.service';
import { AuthenticatedGuard } from './guards/authenticated-guard.service';
import { OnboardGuard } from './guards/onboard-guard.service';
import { FeatureEnabledGuard } from './guards/feature-enabled-guard';
import { AuthService } from './services/auth.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ExposedToAllMachineGroupsGuard } from './guards/exposed-all-machine-groups-guard';
import { MachineGroupAwareFeatureGuard } from './guards/machine-group-aware-feature-guard';
import { NotOnboardGuard } from './guards/not-onboard-guard.service';
import { AppConfigValueGuard } from './guards/app-config-value-guard';
import { EvaluationEnabledGuard } from './guards/evaluation-enabled-guard.service';
import { MdeAllowedActionsGuard } from './guards/mde-allowed-actions-guard.service';
import { SccRolesGuard } from './guards/scc-roles-guard';
import { MtpPermissionsGuard } from './guards/mtp-permissions-guard.service';
import { MdatpGuard } from './guards/mdatp-guard.service';
import { UnifiedRbacGuard } from './guards/unified-rbac-guard.service'
import { OatpGuard } from './guards/oatp-guard.service';
import { ThreatAnalyticsMtpGuard } from './guards/threat-analytics-mtp-guard.service';
import { MtpRbacPermissionsGuard } from './guards/mtp-rbac-permissions-guard.service';
import { FlavorGuard } from './guards/flavor-guard.service';
import { TvmLicensesGuard } from './guards/tvm-licenses-guard';
import { TvmLicenseMigrationFlavorGuard } from './guards/tvm-licenses-migration-flavor-guard';
import { TvmLicenseOrFlavorGuard } from './guards/tvm-licenses-or-flavor-guard';

@NgModule({
	imports: [HttpClientModule],
	providers: [
		AuthService,
		AuthBackendService,
		AuthenticatedGuard,
		OnboardGuard,
		NotOnboardGuard,
		FeatureEnabledGuard,
		AppConfigValueGuard,
		ExposedToAllMachineGroupsGuard,
		MachineGroupAwareFeatureGuard,
		EvaluationEnabledGuard,
		MdeAllowedActionsGuard,
		SccRolesGuard,
		MtpPermissionsGuard,
		MtpRbacPermissionsGuard,
		UnifiedRbacGuard,
		MdatpGuard,
		OatpGuard,
		ThreatAnalyticsMtpGuard,
		FlavorGuard,
		TvmLicensesGuard,
		TvmLicenseMigrationFlavorGuard,
		TvmLicenseOrFlavorGuard,
	],
})
export class AuthModule {
	constructor(
		@Optional()
		@SkipSelf()
		parentModule: AuthModule
	) {
		if (parentModule) {
			throw new Error('AuthModule is already loaded. Import it in the AppModule only');
		}
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: AuthModule,
			providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
		};
	}
}
