import { Injectable } from '@angular/core';
import { HttpOptions, Paris, Repository } from '@microsoft/paris';
import { LiveResponseScript, ServiceUrls } from '@wcd/domain';
import { defer, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { Feature, FeaturesService } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';

@Injectable({
	providedIn: 'root',
})
export class LiveResponseScriptService {
	constructor(private paris: Paris, private featuresService: FeaturesService) {}

	private cachedScripts: Array<LiveResponseScript> = undefined;
	private isTransitionFeatureEnabled = this.featuresService.isEnabled(
		Feature.LiveResponseTransitionCodeSeparation
	);
	private liveResponseScriptsRepo: Repository<LiveResponseScript> = this.paris.getRepository(
		LiveResponseScript
	);
	scripts$ = defer(async () => {
		if (!this.cachedScripts) {
			this.cachedScripts = await this.liveResponseScriptsRepo
				.query({
					where: {
						useV2Api: this.isTransitionFeatureEnabled,
					},
				})
				.pipe(
					take(1),
					map(ds => ds.items)
				)
				.toPromise();
		}
		return this.cachedScripts;
	});

	save(
		script: LiveResponseScript,
		serializationData: { overwriteExisting?: boolean }
	): Observable<LiveResponseScript> {
		return defer(async () => {
			let httpOptions: HttpOptions = {
				params: {
					useV2Api: this.isTransitionFeatureEnabled,
				},
			};
			// TODO: this is a hack to go around SCC proxy because it doesn't support large requests.
			// It is a livesite mitigation and a proper solution should be developed in the very near future.
			// task: https://microsoft.visualstudio.com/DefaultCollection/OS/_workitems/edit/34270741
			if (
				sccHostService.isSCC &&
				this.featuresService.isEnabled(Feature.LiveResponseDirectFileUpload)
			) {
				const [token, serviceUrls]: [string, ServiceUrls] = await Promise.all([
					sccHostService.auth.getToken(),
					sccHostService.ajax.get('<mtp>/k8s/mgmt/ServicesUrls').then(r => r.data.ServiceUrls),
				]);
				if (!(token && serviceUrls && serviceUrls.automatedIr)) {
					throw new Error('An unexpected error has occurred');
				}
				httpOptions = {
					customHeaders: {
						authorization: `Bearer ${token}`,
					},
					params: {
						automatedIrBaseUrl: serviceUrls.automatedIr,
						useV2Api: this.isTransitionFeatureEnabled,
					},
				};
			}
			const overwriteExisting = serializationData && serializationData.overwriteExisting;
			return this.liveResponseScriptsRepo
				.save(script, httpOptions, {
					overwriteExisting: overwriteExisting,
				})
				.pipe(
					take(1),
					tap(async s => {
						// make sure the value is populated
						await this.scripts$.toPromise();
						// Need to remove overwritten files from cache to avoid duplicate entries upon subsequent `library` commands
						if (overwriteExisting) {
							this.cachedScripts = this.cachedScripts.filter(f => f.fileName !== s.fileName);
						}
						this.cachedScripts = [...(this.cachedScripts || []), s];
					})
				)
				.toPromise();
		});
	}

	removeItem(script: LiveResponseScript): Observable<LiveResponseScript> {
		return this.liveResponseScriptsRepo
			.removeItem(script, {
				params: {
					useV2Api: this.isTransitionFeatureEnabled,
				},
			})
			.pipe(
				tap(async () => {
					// make sure the value is populated
					await this.scripts$.toPromise();
					const itemIndex = this.cachedScripts.findIndex(item => item.fileName === script.fileName);
					if (~itemIndex) {
						this.cachedScripts.splice(itemIndex, 1);
					}
				})
			);
	}
}
