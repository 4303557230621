import { Entity, EntityField, ModelBase } from '@microsoft/paris';
import { TvmNetworkScanSharedEntityConfigurations } from '../networkScanEndPointUtil';
import { SnmpAuth } from '../common/snmp-auth.value-object';

@Entity({
	...TvmNetworkScanSharedEntityConfigurations,
	singularName: 'Network assessment job',
	pluralName: 'Network assessment jobs',
	endpoint: 'scanDefinition',
})
export class AssessmentJob extends ModelBase {
	@EntityField({ required: true })
	scanName: string;

	@EntityField({ required: true })
	agentId: string;

	@EntityField()
	agentName: string;

	@EntityField()
	orgId: string;

	@EntityField({ required: true })
	target: string;

	@EntityField()
	originalTargetRanges: string;

	@EntityField()
	snmpAuth: SnmpAuth;

	@EntityField({
		defaultValue: 'Pending',
		serialize: false,
	})
	lastScanStatus: string;

	@EntityField({
		parse: (date: string) => (date ? new Date(date).toLocaleString() : '-'),
		serialize: false,
	})
	lastScanDate: string;

	@EntityField({
		parse: (date: string) => (date ? new Date(date).toLocaleString() : '-'),
		serialize: false,
	})
	lastScanTriggerDate: string;

	@EntityField()
	createdBy: string;

	@EntityField()
	active: boolean;
}
