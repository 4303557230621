import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppConfigService } from '@wcd/app-config';
import { AuthService } from '../services/auth.service';
import { FeaturesService, Feature, AppContextService } from '@wcd/config';

@Injectable()
export class OnboardGuard implements CanActivate {
	constructor(
		private authService: AuthService,
		private appConfigService: AppConfigService,
		private router: Router,
		private featuresService: FeaturesService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if (this.appConfigService.isOnboardingComplete) return true;

		if (
			this.authService.isLoggedIn &&
			!this.appConfigService.isSuspended &&
			!this.appConfigService.isDeleted &&
			this.featuresService.isEnabled(Feature.OnboardingWizardUpgrade)
		) {
			this.router.navigate(['/onboarding2/permissions']);
		}

		return false;
	}
}
