import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CyberEvent, Machine, MdeUserRoleActionEnum, MachineTimelinePrefetchApiCall } from '@wcd/domain';
import { EntityComponentBase } from '../../../global_entities/components/entity-contents.component.base';
import { I18nService } from '@wcd/i18n';
import { ReportModel } from '../../../reports/models/report.model';
import { TabModel, TabModelConfig } from '../../../shared/components/tabs/tab.model';
import { Feature, FeaturesService, FlavorService, TvmLicensesAngularService } from '@wcd/config';
import { ActivatedRoute, Router } from '@angular/router';
import { MachinesService } from '../services/machines.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { subDays } from 'date-fns';
import { Paris, ReadonlyRepository } from '@microsoft/paris';
import { PreferencesService } from '@wcd/config';
import { TIME_RANGE_DEFAULT_PREFERENCE_ID } from '../../../entity-events-timeline/components/entity-events-timeline/entity-events-timeline.component';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { isCustomTimeRangeValue } from '@wcd/date-time-picker';
import { AuthService } from '@wcd/auth';
import { OperatingSystemPlatformCategories, ExpeditePollingMachineApiCall } from '@wcd/domain';
import { MachineReportService } from '../services/machine.report.service';
import { TabsComponent } from '../../../shared/components/tabs/tabs.component';
import { CyberEventsUtilsService } from '../../cyber_events/services/cyber-events-utils.service';
import { AppInsightsService } from 'app/insights/services/app-insights.service';
import { sccHostService } from '@wcd/scc-interface';
import { AppFlavorConfig } from '@wcd/scc-common';
import { TvmLicenseType } from '@wcd/scc-common';

declare const moment: typeof import('moment');

@Component({
	styles: [
		`
			report {
				min-height: 185px;
			}
			@media all and (max-width: 1024px) {
				report {
					overflow-y: auto;
					overflow-x: hidden;
					height: 200px;
				}
			}
			.tabs-z-index {
				z-index: 2;
			}
		`,
	],
	template: `
		<div class="wcd-full-height wcd-flex-vertical">
			<div
				class="wcd-flex-0 wcd-margin-xxLarge-left wcd-margin-mediumSmall-bottom wcd-padding-none-left-sm-screens tabs-z-index"
				[class.wcd-margin-xxLarge-right]="!isScc"
			>
				<tabs [tabsData]="tabs" [currentRouteIsActive]="true"></tabs>
			</div>
			<div class="wcd-flex-1" [class.wcd-overflow-scroll]="shouldAddOverflowScroll">
				<router-outlet (activate)="onActivate()"></router-outlet>
			</div>
		</div>
	`,
})
export class MachineEntityComponent extends EntityComponentBase<Machine> implements OnInit, OnDestroy {
	report: ReportModel;
	tabs: ReadonlyArray<TabModel> = [];
	machineEventsRepo: ReadonlyRepository<CyberEvent>;
	featuresChangeSubscription: Subscription;
	isScc = sccHostService.isSCC;
	extraDeviceDetailsTabs: boolean;
	isTimelineEnabled: boolean;
	isPolicyTroubleshootingForDeviceEnable: boolean;
	shouldAddOverflowScroll: boolean;

	@ViewChild(TabsComponent, { static: false }) private tabsComponent: TabsComponent;

	constructor(
		private readonly i18nService: I18nService,
		private readonly machinesService: MachinesService,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private preferencesService: PreferencesService,
		private readonly timeRangesService: TimeRangesService,
		private readonly paris: Paris,
		private readonly featuresService: FeaturesService,
		private readonly authService: AuthService,
		private readonly machineReportService: MachineReportService,
		private readonly appInsightsService: AppInsightsService,
		private tvmLicensesService: TvmLicensesAngularService,
		flavorService: FlavorService
	) {
		super();
		this.extraDeviceDetailsTabs = flavorService.isEnabled(AppFlavorConfig.devices.extraDeviceDetailsTabs);
		this.isTimelineEnabled = flavorService.isEnabled(AppFlavorConfig.devices.isTimelineEnabled);
		this.isPolicyTroubleshootingForDeviceEnable = featuresService.isEnabled(Feature.EnablePolicyTroubleshootingForDevice) && flavorService.isEnabled(AppFlavorConfig.routes.policyManagement);
		this.machineEventsRepo = this.paris.getRepository(CyberEvent);
		this.featuresChangeSubscription = this.featuresService.featureChanged$
			.pipe(filter(({ featureId }) => featureId === Feature.UpgradeMachinePage))
			.subscribe(() => {
				if (!featuresService.isEnabled(Feature.UpgradeMachinePage)) {
					const params = this.route.snapshot.queryParams;
					this.router.navigateByUrl(
						this.machinesService.getLegacyMachineLink(
							this.machine.machineId,
							new Date((params && params['to']) || Date.now())
						)
					);
				}
			});
	}

	get machine(): Machine {
		return this.entity;
	}

	ngOnInit() {
		this.tabs = this.getTabs();
		this.warmUpBackend();
		this.report = this.machineReportService.getModel(this.entity);

		// fire a resize event in order to inform tabs content (specifically timeline that contains timebar picker) they need to be full screened
		if (this.isFullScreen)
			setTimeout(() => {
				window.dispatchEvent(new Event('resize'));
			}, 100);

		//Adding overflow scroll for Scc pages
		const currentTab = this.router.url
			.split('?')[0]
			.split('/')
			.pop();
		this.shouldAddOverflowScroll = this.checkIfShouldAddOverflowScroll(currentTab);
	}

	ngAfterViewInit() {
		this.appInsightsService.trackEvent('device-page-load', {
			onboardingStatus: this.machine ? this.machine.onboardingStatus : '',
		});
	}

	ngOnDestroy() {
		this.featuresChangeSubscription && this.featuresChangeSubscription.unsubscribe();
	}

	onActivate() {
		/*
		Select the right tab, when navigating
		*/
		if (this.tabsComponent && this.tabs) {
			const id = this.router.url
				.split('?')[0]
				.split('/')
				.pop();
			this.tabsComponent.selectTab(this.tabs.find(x => x.id.toLowerCase() === id || x.id === id));
			this.shouldAddOverflowScroll = this.checkIfShouldAddOverflowScroll(id);
		}
	}

	private getTabs(): Array<TabModel> {
		const tabs: Array<TabModelConfig> = [
			{
				id: 'main',
				name: this.i18nService.get(
					this.featuresService.isEnabled(Feature.IncidentsPhase2)
						? 'incidents.title'
						: 'machines.tabs.alerts'
				),
				routerLink: './main',
				disabled: !this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.viewData),
			},
		];
		if (this.machine.aadDeviceId && this.isPolicyTroubleshootingForDeviceEnable) {
			tabs.push({
				id: 'machine-configuration-troubleshooting',
				name: this.i18nService.get('machines.tabs.configuration'),
				routerLink: './configuration',
			});
		}
		if (this.machine.senseMachineId) {
			if (this.isTimelineEnabled) {
				tabs.push({
					id: 'timeline',
					name: this.i18nService.get('machines.tabs.timeline'),
					routerLink: './timeline',
					disabled: !this.authService.currentUser.hasMdeAllowedUserRoleAction(
						MdeUserRoleActionEnum.viewData
					),
				});
			}
			if (
				(this.extraDeviceDetailsTabs || this.shouldShowTvmBasicTab()) &&
				this.featuresService.isEnabled(Feature.TvmPages) &&
				this.machinesService.supportTvmTabs(this.entity)
			) {
				tabs.push(
					{
						id: 'recommendations',
						name: this.i18nService.get('machines.tabs.recommendations'),
						routerLink: './recommendations',
						routerLinkQueryParams: {
							filters: 'status=Active',
						},
						shouldForceActive: () => {
							return (
								this.router.url
									.split('?')[0]
									.split('/')
									.pop() === 'recommendations'
							);
						},
					},
					{
						id: 'software-inventory',
						name: this.i18nService.get('machines.tabs.softwareInventory'),
						routerLink: './software-inventory',
						routerLinkQueryParams: {
							ordering: '-isNormalized',
						},
					}
				);
				if (this.shouldShowTvmPremiumTab(Feature.TvmBrowserExtensions)) {
					tabs.push({
						id: 'extensions',
						name: this.i18nService.strings.machines_tabs_extensionsInventory,
						routerLink: './extensions',
					});
				}
				tabs.push({
					id: 'vulnerabilities',
					name: this.i18nService.get('machines.tabs.vulnerabilities'),
					routerLink: './vulnerabilities',
				});
				if (this.shouldShowMissingKbsTab()) {
					tabs.push({
						id: 'missingkbs',
						name:
							this.machine.os.platform.category === OperatingSystemPlatformCategories.Linux
								? this.i18nService.strings.tvm_common_securityBulletins
								: this.i18nService.strings.machines_tabs_missingKbs,
						routerLink: './missingKbs',
					});
				}
				if (this.shouldShowBaselineTab()) {
					tabs.push({
						id: 'baseline-compliance',
						name: this.i18nService.strings.tvm_baseline_title,
						routerLink: './baseline-compliance',
					});
				}
				if (this.shouldShowTvmPremiumTab(Feature.TvmCertificateAssessment)) {
					tabs.push({
						id: 'certificate-inventory',
						name: this.i18nService.strings.machines_tabs_certificateInventory,
						routerLink: './certificate-inventory',
					});
				}
			}
		}

		tabs.unshift({
			id: 'overview',
			name: this.i18nService.get('machines.tabs.overview'),
			routerLink: './overview',
		});

		return tabs.map(tab => new TabModel(tab));
	}

	// Send a dummy call to make sure that this machine is fetched to backend cache, in order to speed up timeline first load.
	private warmUpBackend() {
		const rangeId = this.preferencesService.getPreference(TIME_RANGE_DEFAULT_PREFERENCE_ID) || 'month';
		const duration = this.timeRangesService.all.find(range => range.id === rangeId);
		const to = moment()
			.utc()
			.toDate();
		const from = moment()
			.utc()
			.subtract((duration && !isCustomTimeRangeValue(duration) && duration.value) || 30, 'days')
			.toDate();
		const dataQuery = {
			where: {
				machineId: this.machine.machineId,
				machineDnsName: this.machine.name,
				entityType: 'machines',
				entityId: this.machine.id,
				lastSeen: this.machine.lastSeen.toISOString(),
				SenseClientVersion: this.machine.senseClientVersion,
				fromDate: from.toISOString(),
				toDate: to.toISOString(),
				pageSize: 200,
				useCyberData: CyberEventsUtilsService.shouldTimelineUseOneCyber(this.featuresService),
				generateIdentityEvents: this.featuresService.isEnabled(
					Feature.MachineTimelineGenerateMdiEvents
				),
			},
		};

		if (this.isTimelineEnabled)
		{
			if (CyberEventsUtilsService.shouldPrefetchTimeline(this.featuresService)) {
				this.paris.apiCall(MachineTimelinePrefetchApiCall, dataQuery).subscribe();
			} else {
				this.machineEventsRepo.query(dataQuery).subscribe();
			}
		}

		if (CyberEventsUtilsService.shouldWarmUpResponse(this.featuresService)) {
			setTimeout(() => {
				try {
					this.paris.apiCall(ExpeditePollingMachineApiCall, this.machine).subscribe();
				} catch {
					// handle case where user left machine page
				}
			}, 60000);
		}
	}

	// Missing KB's should be displayed for windows machines or Linux machines (will be called Security Bulletins in that case)
	private shouldShowMissingKbsTab(): boolean {
		return (
			((!this.featuresService.isEnabled(Feature.TvmPremium) || this.shouldShowTvmBasicTab()) &&
				((this.machine.isManagedByMdatp || !this.machine.isMdatp) &&
					this.featuresService.isEnabled(Feature.TvmMachineMissingKbs) &&
					this.machine.os.platform.id !== OperatingSystemPlatformCategories.macOS &&
					this.machine.os.platform.category !== OperatingSystemPlatformCategories.Android &&
					this.machine.os.platform.category !== OperatingSystemPlatformCategories.iOS)) ||
			this.machine.os.platform.category === OperatingSystemPlatformCategories.Linux
		);
	}

	private shouldShowBaselineTab(): boolean {
		return (
			this.shouldShowTvmPremiumTab(Feature.TvmBaselineCompliance) &&
			(this.machine.isManagedByMdatp || !this.machine.isMdatp) &&
			([
				OperatingSystemPlatformCategories.Windows11,
				OperatingSystemPlatformCategories.Windows10,
				OperatingSystemPlatformCategories.WindowsServer2022,
				OperatingSystemPlatformCategories.WindowsServer2019,
				OperatingSystemPlatformCategories.WindowsServer2016,
				OperatingSystemPlatformCategories.WindowsServer2012R2,
				OperatingSystemPlatformCategories.WindowsServer2008R2,
			].includes(this.machine.os.platform.id as any) || (
				this.shouldShowTvmPremiumTab(Feature.TvmBaselineComplianceForLinux) &&
				this.machine.os.platform.category === OperatingSystemPlatformCategories.Linux
			))
		);
	}

	private shouldShowTvmPremiumTab(feature: Feature) {
		return (
			this.isScc &&
			this.featuresService.isEnabled(feature) &&
			this.tvmLicensesService.isEnabled(TvmLicenseType.TvmPremium)
		);
	}

	private shouldShowTvmBasicTab() {
		return (
			this.featuresService.isEnabled(Feature.TvmPremium) &&
			this.tvmLicensesService.isEnabled(TvmLicenseType.TvmBasic)
		);
	}

	private checkIfShouldAddOverflowScroll(currentTab: string): boolean {
		return (
			this.isScc &&
			(currentTab === 'recommendations' ||
				currentTab === 'software-inventory' ||
				currentTab === 'vulnerabilities' ||
				currentTab === 'missingKbs' ||
				currentTab === 'certificate-inventory' ||
				currentTab === 'extensions')
		);
	}
}
