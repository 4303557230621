<!-- tslint:disable:template-click-events-have-key-events -->
<div class="wcd-full-height dataview wcd-flex-vertical dataview-in-responsive-entity-page"
	 [focusOnFirst]="focusOnFirstElement"
	 [class.dataview-responsive-layout]="responsiveLayout"
	 *ngIf="dataViewModel"
	 data-track-component-type="DataView"
	 [ngClass]="className"
	 [attr.data-track-component]="id"
	 [attr.data-track-id]="'DataView_' + id"
	 [class.wcd-padding-none-horizontal-right-important]="removePaddingRight">
	<div class="wcd-flex-vertical responsive-horizontal-padding dataview-header-container"
		[class.wcd-padding-none-horizontal-important]="removePadding"
		[class.wcd-padding-none-horizontal-right-important]="removePaddingRight"
		[class.wcd-padding-none-left-sm-screens]="removePaddingLeftOnResize">
		<div class="wcd-flex-1 wcd-flex-horizontal">
			<div class="wcd-flex-1 wcd-flex-vertical">
				<ng-container *ngIf="queueHeader">
					<ng-container *ngTemplateOutlet="header">
					</ng-container>
				</ng-container>
				<div
					class="wcd-flex-none wcd-flex-horizontal command-bar dataview-header dataview-header-controls dataview-controls responsive-bottom-padding"
					[hidden]="selectEnabled && selectedItems?.length && !entityType"
					[class.command-bar-no-padding]="commandBarNoPadding"
					[ngClass]="commandBarClassName"
					*ngIf="!hideControls && (!hideCommandBarOnEmptyData || dataSet?.items?.length)"
					[class.wcd-padding-bottom]="queueHeader">
					<ng-container *ngIf="!responsiveActionBar; else responsiveActionBarTemplate">
						<!--START - NOT RESPONSIVE ACTION BAR-->
						<ng-container *ngIf="assetCommandBar">
							<ng-container *ngTemplateOutlet="exportButton"></ng-container>
							<ng-container *ngTemplateOutlet="searchText"></ng-container>
						</ng-container>
						<ng-content select="[dataview-controls]"></ng-content>
						<button id="{{itemName}}-add-btn"
								class="btn btn-link command-bar-item-button"
								*ngIf="allowAdd"
								[attr.data-track-id]="'addNew_' + dataViewModel.id"
								data-track-type="Button"
								[wcdTooltip]="disableAddTooltip ? disabledAddTooltip : 'dataview_add_new_item' | i18n: {itemType: itemNameLowercase}"
								[disabled]="disableAddButton"
								(click)="onNewItem.emit()"
								#focusable
								>
							<wcd-shared-icon iconName="Add" [ariaLabel]="''"> </wcd-shared-icon >
							{{'dataview_add_item' | i18n:{itemType: itemNameLowercase} }}
						</button>
						<ng-container *ngIf="!assetCommandBar">
							<ng-container *ngTemplateOutlet="searchText"></ng-container>
						</ng-container>
						<!-- Dataview controls -->
						<!-- "space-filler" is for separating "left actions" and "right actions" commands (using flex) -->
						<div class="space-filler" role="none" aria-hidden="true"></div>
						<ng-content select="[dataview-controls-right]"></ng-content>
						<span *ngFor="let customControl of customControls" class="control">
							<button class="btn btn-link command-bar-item-button" (click)="customControl.method()"
									[wcdTooltip]="customControl.tooltip" #focusable >
								 <wcd-shared-icon [iconName]="customControl.icon"> </wcd-shared-icon >
							</button>
						</span>
						<wcd-checklist-dropdown
							*ngIf="dataViewModel && allowColumnCustomization"
							buttonIcon="ColumnOptions"
							[buttonText]="'dataview.customizeColumns' | i18n"
							class="command-bar-item-dropdown"
							[ngModel]="visibleFieldIds$ | async"
							name="dataview-fields"
							[isBordered]="false"
							[disableEmptySelection]="true"
							[secondButton]="'reset' | i18n"
							(secondButtonClick)="resetVisibleFields(true)"
							(ngModelChange)="onColumnsChange($event)"
							[values]="dataViewModel.dataTableFields | selectableDataviewFields: selectableFieldIds"
							[navigateUsingArrowKeysOnly]="true"
							[disableSecondButton]="disableReset"
							#focusable
							></wcd-checklist-dropdown>

						<ng-container *ngIf="!assetCommandBar">
							<ng-container *ngTemplateOutlet="exportButton"></ng-container>
						</ng-container>
						<ng-container *ngIf="infiniteScrolling; else noInfiniteScrollingControls">
							<ng-container *ngIf="(dataViewModel.dataSet$ | async)?.count as total">
								<!-- Total: {{total}} (not ready yet) -->
							</ng-container>
						</ng-container>
						<ng-template #noInfiniteScrollingControls>
							<ng-container *ngIf="dataViewModel.allowPaging">
								<fancy-select [ngModel]="dataViewModel.pageSize$ | async"
											  name="dataview-pagesize"
											  class="command-bar-item-dropdown"
											  [formatLabel]="getBoundPageSizeLabel"
											  (ngModelChange)="onPageSizeChange($event)"
											  [values]="pageSizes"
											  [ariaLabel]="'dataview.itemsPerPage' | i18n"
											  [navigateUsingArrowKeysOnly]="true"
											  #focusable
											  ></fancy-select>
								<wcd-simple-pagination
									*ngIf="dataSet"
									[total]="dataSet.count"
									[pageSize]="dataViewModel.pageSize$ | async"
									class="control command-bar-item-pagination"
									[currentPageCount]="dataSet.items?.length"
									[page]="dataViewModel.page$ | async"
									(pageChange)="onPageNumberChange($event.page)" #focusable></wcd-simple-pagination>
							</ng-container>
						</ng-template>
						<ng-template #exportButton>
							<button class="btn btn-link command-bar-item-button wcd-flex-center-vertical"
									[attr.data-track-id]="'export_' + dataViewModel.id"
									data-track-type="Export"
									*ngIf="dataViewModel.exportEnabled"
									(click)="exportData()"
									[wcdTooltip]="exportTooltipText || 'dataview_export_to_csv' | i18n : {  entityName: itemNamePluralLowercase   }"
									[attr.aria-label]="exportTooltipText"
									[includeWcdTooltipAnnouncer]="true"
									[class.control]="!assetCommandBar"
									#focusable >
								<wcd-shared-icon iconName="Download"></wcd-shared-icon>
								<span>{{i18nService.strings.dataview_export}}</span>
							</button>
						</ng-template>
						<ng-template #searchText>
							<div class="wcd-margin-horizontal wcd-flex-center-all dataview-search-form"
								 *ngIf="searchEnabled"
								 [ngClass]="giveSearchBoxMoreSpace ? 'wcd-flex-3' : 'wcd-flex-1'">
								<div class="wcd-full-width" [ngClass]="{ searching: searching }">
									<button
										class="btn btn-link search-input__btn"
										type="button"
										[disabled]="!searchTerm || searchTerm === lastSearched"
										(click)="search(searchTerm)"
										[attr.aria-label]="'dataview.search' | i18n"
										#focusable>
										<wcd-shared-icon iconColor="black" aria-hidden="true"
														 iconName="search"></wcd-shared-icon>
									</button>
									<input class="search-input"
										   [(ngModel)]="searchTerm"
										   name="dataview-search"
										   [minlength]="searchMinLength"
										   (keydown.enter)="search(searchTerm)"
										   (keydown.escape)="cancelSearch()"
										   (blur)="onSearchBlur()"
										   [placeholder]="'dataview.search.item' | i18n: { itemType: itemNamePluralLowercase}"
										   [attr.size]="('dataview.search.item' | i18n: { itemType: itemNamePluralLowercase}).length+SearchSafePadding"
										   [attr.aria-label]="'dataview.search.item' | i18n: { itemType: itemNamePluralLowercase}"
										   #focusable>
									<button class="btn btn-link clear-search" type="button"
											(click)="cancelSearch()"
											[disabled]="!searchTerm"
											[attr.aria-label]="'select.clear' | i18n"
											[wcdTooltip]="'select.clear' | i18n">
										<wcd-shared-icon iconColor="black" iconName="cancel"></wcd-shared-icon>
									</button>
								</div>
							</div>
						</ng-template>
						<button [attr.id]="showFilterButtonId"
								class="btn btn-link control command-bar-item-button icon-only command-bar-filter"
								[class.checked]="showFilters"
								(click)="toggleFilters()"
								data-track-id="toggleFilters"
								data-track-type="Button"
								*ngIf="dataViewModel.allowFilters"
								[attr.aria-label]="'grid.commands.filters.text' | i18n"
								[wcdTooltip]="(showFilters ? 'grid.commands.filters.hide' : 'grid.commands.filters.show') | i18n"
								#filterButton
								#focusable
								>
							<fab-icon iconName="Filter"></fab-icon>
							<span *ngIf="assetCommandBar">{{ 'grid.commands.filters.text' | i18n }}</span>
						</button>
					<!--END - NOT RESPONSIVE ACTION BAR-->
					</ng-container>
					<!--START - RESPONSIVE ACTION BAR-->
					<ng-template #responsiveActionBarTemplate>
						<dataview-actions class="dataview-actions" [actions]="commandBarLeft" [dataViewModel]="dataViewModel" [isRightSideActionBar]="false"></dataview-actions>
						<dataview-actions class="dataview-actions dataview-actions-right wcd-padding-none-left-sm-screens"
							[shouldShowPageNumbersOnPagination] ="shouldShowPageNumbersOnPagination"
							[actions]="commandBarRight"
							[dataViewModel]="dataViewModel"
							[isRightSideActionBar]="true">
						</dataview-actions>
					</ng-template>
					<!--END - RESPONSIVE ACTION BAR-->
				</div>
				<div class="wcd-flex-none wcd-flex-horizontal command-bar"
					 [class.wcd-padding-bottom]="queueHeader"
					 [hidden]="!selectedItems.length"
					 *ngIf="selectEnabled && dataSet && !entityType">
					<div class="wcd-flex-1">
						<ng-container *ngFor="let action of itemActions; trackBy:trackById">
							<ng-container
								*ngTemplateOutlet="itemAction;context:{ itemAction: action, dataViewId: id }"></ng-container>
						</ng-container>
					</div>
					<div class="wcd-flex-none wcd-flex-center-vertical" [style.margin-right]="modalsWidth + 'px'">
						<button class="btn btn-link control command-bar-item-button"
								[wcdTooltip]="'select.clear' | i18n"
								(click)="unSelectAllItems()"
								#focusable>{{
									"dataview_selected" | i18n: {
										count :  allItemsSelected ? dataSet && dataSet.items.length : selectedItems.length,
										itemName: selectedItemsCount === 1 ? itemNameSingular : itemNamePluralLowercase
									}
								}}
							 <wcd-shared-icon [iconName]="'close'"> </wcd-shared-icon >
						</button>
					</div>
				</div>
	<!--			<div class="wcd-flex-none dataview-selection-all" *ngIf="allPageItemsSelected && selectEnabled && dataSet">-->
	<!--				All {{itemNamePluralLowercase}} {{!allItemsSelected ? 'on this page' : ''}} selected.-->
	<!--				<a (click)="selectAllItems(false)"-->
	<!--				   data-track-id="DataviewSelectAllItems"-->
	<!--				   data-track-type="Button"-->
	<!--				   [hidden]="allItemsSelected">Select all {{dataSet.count || ''}}-->
	<!--					{{itemNamePluralLowercase}}</a>-->
	<!--				<a (click)="unSelectAllItems()"-->
	<!--				   data-track-id="DataviewUnSelectAllItems"-->
	<!--				   data-track-type="Button"-->
	<!--				   [hidden]="!allItemsSelected">Clear selection</a>-->
	<!--			</div>-->
			</div>
		</div>
	</div>
	<div class="dataview-list-container wcd-flex-1"
		 [class.responsive-horizontal-padding]="responsiveLayout"
		 [class.wcd-padding-none-horizontal-important]="removePadding"
		 [class.wcd-padding-none-horizontal-right-important]="removePaddingRight"
		 [class.overflow-right]="isScc"
		 [ngClass]="className"
		 [class.wcd-padding-none-left-sm-screens]="removePaddingLeftOnResize">
		<div class="wcd-flex-1 wcd-flex-horizontal wcd-full-height">
			<div class="wcd-flex-1 wcd-flex-vertical wcd-full-height">
				<ng-container *ngIf="!queueHeader">
					<ng-container *ngTemplateOutlet="header">
					</ng-container>
				</ng-container>
				<ng-template #header>
					<div class="wcd-flex-none dataview-header"
						 *ngIf="showHeader && (!hideHeaderOnEmptyData || dataSet?.items?.length)">
						<div class="wcd-flex-1 dataview-description wcd-flex-center-vertical"
							 *ngIf="descriptionHtml; else headerContents">
							<span [innerHTML]="descriptionHtml"></span>
						</div>
						<ng-template #headerContents>
							<div class="wcd-flex-1 wcd-flex-center-vertical" *ngIf="!descriptionHtml">
								<ng-content select="[dataview-header]"></ng-content>
							</div>
							<div class="wcd-flex-1">
								<ng-content select='[dataview-secondary-header]'></ng-content>
							</div>
						</ng-template>
					</div>
				</ng-template>
				<div class="wcd-flex-1 relative wcd-full-height">
					<div class="wcd-full-height loading-overlay" *ngIf="error; else noError">
						<div class="error-message" *ngIf="!m365DataError; else m365DataErrorTemplate">
							<wcd-shared-icon [iconName]="'error'"> </wcd-shared-icon >
							{{"dataview_loading_error" | i18n : {itemType : itemNamePluralLowercase} }}
						</div>
						<ng-template #m365DataErrorTemplate>
							<div class="wcd-flex-vertical wcd-flex-center-vertical color-text-gray-700">
								<div
									class="wcd-margin-bottom wcd-font-weight-bold">{{'m365.common.errorMessages.cantGetThisInfoRightNow' | i18n}}</div>
								<div class="wcd-font-size-base" style="cursor: pointer" (click)="reloadData()"
									 data-track-id="DataviewReloadData" data-track-type="Button">
									<fab-icon iconName="Refresh"></fab-icon>
									{{'m365.common.errorMessages.tryAgain' | i18n}}</div>
							</div>
						</ng-template>
					</div>
					<ng-template #noError>
						<div class="wcd-full-height relative datatable-container"
							 [class.wcd-padding-small-top]="datatablePadTop"
							 [class.fixed-table]="fixedTable" *ngIf="dataSet">
							<wcd-datatable
								*ngIf="!hideTable"
								[items]="(dataViewModel.dataSet$ | async)?.items | safeSlice:0:maxItems"
								[columns]="dataViewModel.visibleFields$ | async"
								[fieldWidths]="columnWidths"
								[allowResize]="allowResize"
								[fixedTable]="fixedTable"
								[sortField]="dataViewModel.sortField$ | async"
								[sortDescending]="dataViewModel.sortDescending$ | async"
								[refreshOn]="localRefreshOn"
								[hidden]="!dataSet.items.length"
								[isSmallPadding]="isSmallPadding"
								[loadMoreOnEmpty]="loadMoreOnEmpty"
								class="wcd-full-height"
								[update]="showFilters"
								[infiniteScrolling]="infiniteScrolling"
								[loadItemsOnTableTop]="loadItemsOnTableTop"
								[itemsSelected]="allItemsSelected"
								[itemUniqueKey]="itemUniqueKey"
								[isItemSelectable]="boundIsItemSelectable"
								[sortableFieldIds]="dataViewModel.sortableFieldIds"
								[isItemGroup]="isItemGroup"
								[isItemClickable]="isItemClickable || store && store.options && store.options.dataViewOptions && store.options.dataViewOptions.isItemClickable"
								[hasNestedContent]="hasNestedContent"
								[highlightedItems]="highlightedItems"
								[permanentHighlightedItems]="permanentHighlightedItems"
								(select)="onSelectItems($event.items, $event.previous, $event.next)"
								(onRenderComplete)="onDataTableRenderComplete()"
								(groupExpand)="onGroupExpand($event)"
								(itemClick)="itemClick($event.item, $event.previous, $event.next)"
								(sortFieldChanged)="onSortChange($event.field, null, true)"
								(columnResize)="onResizeColumn($event)"
								(scroll)="loadNextResults()"
								(loadTopItemsClick)="loadPreviousResults()"
								[selectEnabled]="selectEnabled"
								[selectAllEnabled]="selectAllEnabled"
								[allowMultipleSelection]="allowMultipleSelection"
								[allowGroupItems]="allowGroupItems"
								[allowParentSelectionWithoutSelectingNestedItems]="allowParentSelectionWithoutSelectingNestedItems"
								[getGroupItems]="dataTableGetGroupedItems"
								[loadGroupItemsOnLoad]="loadGroupItemsOnLoad"
								[isGroupExpandedOnInit]="isGroupExpandedOnInit"
								[nestedComponentType]="nestedComponentType"
								[label]="label"
								[focusOnFirstCell]="focusOnFirstCell"
								[focusOnTable]="focusOnTable"
								[tabIndex]="tabIndex"
								[fullHeight]="fullHeight">
							</wcd-datatable>
							<div class="dataview-infinite-scrolling-loader"
								 *ngIf="dataViewModel.isLoadingNext$ | async">
								<fab-spinner></fab-spinner>
							</div>
						</div>
					</ng-template>
					<div class="overlay loading-overlay"
						 *ngIf="(dataViewModel.isLoading$ | async) && !error">
						<div class="wcd-margin-large-top">
							<accessible-spinner
								[customLabel]="loadingMessageText"
								[politeness]="'polite'"
								[loadingEntityName]="loadingMessageText ? '' : itemNamePluralLowercase"
							></accessible-spinner>
						</div>
					</div>
					<div class="dataview-nodata wcd-flex-center-all"
						 *ngIf="dataSet && !dataSet.items.length && !error">
						<div class="wcd-margin-large-top widget-nodata-message custom-icon"
							 *ngIf="dataViewModel.isSearchOnly && !dataViewModel.currentSearchTerm">
							{{searchRequestText || 'dataview_enter_search_term' | i18n}}
						</div>
						<div class="wcd-margin-large-top widget-nodata-message custom-icon"
							 *ngIf="showEmptyMessage !== false && (!dataViewModel.isSearchOnly || dataViewModel.currentSearchTerm)">
							<div *ngIf="!emptyDataMarkdown">
								{{emptyDataMessage || ('dataview_empty_data_message' | i18n: {itemType: itemNamePluralLowercase} )}}
							</div>
							<markdown style="text-align: center" *ngIf="!!emptyDataMarkdown" [data]="emptyDataMarkdown">
							</markdown>
						</div>
						<ng-content select="[dataview-empty]"></ng-content>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #itemAction let-itemAction="itemAction" let-dataViewId="dataViewId">
	<ng-container *ngIf="itemAction.values; else buttonAction">
		<fancy-select [values]="itemAction.values"
					  [placeholder]="itemAction.nameKey ? (itemAction.nameKey | i18n) : itemAction.name"
					  [buttonIcon]="itemAction.icon"
					  [wcdTooltip]="itemAction.tooltip"
					  [(ngModel)]="itemAction.selectedValue"
					  class="command-bar-item-dropdown"
					  [name]="itemAction.id + '_dropdown'"
					  [useValueAsButtonText]="false"
					  label="name"
					  [ariaLabel] = "itemAction.nameKey ? (itemAction.nameKey | i18n) : itemAction.name"
					  (ngModelChange)="runAction(itemAction, itemAction.selectedValue)"
					  [navigateUsingArrowKeysOnly]="true"></fancy-select>
	</ng-container>
	<ng-template #buttonAction>
		<button (click)="runAction(itemAction)"
				[disabled]=" runningAction || itemAction.disabled"
				class="btn btn-link control command-bar-item-button"
				[ngClass]="itemAction.buttonClass"
				[wcdTooltip]="itemAction.tooltip">
			 <wcd-shared-icon [iconName]="itemAction.icon" *ngIf="itemAction.icon"
				  [ngClass]="{ 'small-icon': itemAction.name }"> </wcd-shared-icon >
			<span
				*ngIf="itemAction.name || itemAction.nameKey">{{itemAction.nameKey ? (itemAction.nameKey | i18n) : itemAction.name}}</span>
		</button>
	</ng-template>
</ng-template>
<ng-container *ngIf="!responsiveActionBar">
	<wcd-panel (close)="toggleFilters(false)"
				class="responsive-panel"
				*ngIf="dataViewModel?.allowFilters && showFilters"
				[disableAutoFocus]="disableFilterPanelAutoFocus"
				[class.wcd-padding-large-top]="datatablePadTop"
				[settings]="filtersPanelSettings">
		<div class="wcd-full-height wcd-flex-center-all" *ngIf="dataViewModel.filtersError; else filters">
			<span class="error-message">
				{{i18nService.strings.dataview_error_loading_filters }}
			</span>
		</div>
		<ng-template #filters>
			<ng-container *ngIf="dataViewModel.filtersData$ | async as filtersData; else loadingFilters">
				<wcd-filters
					[data]="filtersData"
					[fields]="dataViewModel.filterFields"
					[fixedSelectedValues]="dataViewModel.fixedFilterValues"
					[serializedFilters]="dataViewModel.filtersState?.serialized"
					class="wcd-full-height"
					(filtersChange)="onFiltersChanged($event)"
					[allowFocusOnFirstElement]="!disableFilterPanelAutoFocus" >
				</wcd-filters>
			</ng-container>
			<ng-template #loadingFilters>
				<div class="wcd-full-height wcd-flex-center-all">
					<fab-spinner [label]="'filters.loading' | i18n"></fab-spinner>
				</div>
			</ng-template>
		</ng-template>
	</wcd-panel>
</ng-container>
