import { ChangeDetectionStrategy, Component } from '@angular/core';
import { filter, map, switchMap } from 'rxjs/operators';
import { File, FileVirusTotalFileReportRelationship, VirusTotalFileReport } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { ReportWidgetComponent } from '../../../../../../reports/components/report-widget.component.base';
import { ReportsService } from '../../../../../../shared-reports/services/reports.service';
import { ActivatedEntity } from '../../../../../../global_entities/services/activated-entity.service';
import { I18nService } from '@wcd/i18n';
import { ReportWidgetConfig } from '../../../../../../reports/models/report-widget.model';

@Component({
	selector: 'virus-total-widget',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './virus.total.widget.html',
	styleUrls: ['./file.reputation.widget.scss'],
})
export class VirusTotalWidgetComponent extends ReportWidgetComponent<FileReputationData> {
	constructor(
		reportsService: ReportsService,
		private readonly activatedEntity: ActivatedEntity,
		private readonly paris: Paris,
		private readonly i18nService: I18nService
	) {
		super(reportsService);
	}

	get widgetConfig(): ReportWidgetConfig<FileReputationData> {
		return {
			id: 'fileMalwareDetection',
			name: this.i18nService.get('file.malwareDetection'),
			loadData: () =>
				this.activatedEntity.currentEntity$.pipe(
					filter(currentEntity => currentEntity instanceof File),
					switchMap((file: File) =>
						this.paris
							.getRelatedItem<File, VirusTotalFileReport>(
								FileVirusTotalFileReportRelationship,
								file
							)
							.pipe(
								map((virusTotalReport: VirusTotalFileReport) => ({
									virusTotalReport,
									defenderThreat: file.prevalentThreatName,
									file: file,
									showFileVerdict: !!(file && file.sha1), // File verdict doesn't support SHA256
								}))
							)
					)
				),
		};
	}
}

interface FileReputationData {
	virusTotalReport: VirusTotalFileReport;
	defenderThreat?: string;
	file: File;
	showFileVerdict: boolean;
}
