var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OfficeIntegrationService } from '../services/office-integration.service';
import { AdvancedFeatures, AutomatedIrProperties, AutomatedIrStatus, ComplianceAlertsShareStatus, AllowNonAuthenticatedSenseStatus, EnvironmentName, IntuneIntegrationStatus, LiveResponseSettings, PreviewExperienceSettings, } from '@wcd/domain';
import { of, throwError, timer } from 'rxjs';
import { Paris } from '@microsoft/paris';
import { Feature, FeaturesService, FlavorService } from '@wcd/config';
import { AppConfigService } from '@wcd/app-config';
import { catchError, delay, delayWhen, map, mergeMap, retryWhen, scan, share, switchMap, tap, } from 'rxjs/operators';
import { RbacMdeAllowedActions } from '../../rbac/enums/mde-allowed-actions.enum';
import { RbacControlState } from '../../rbac/models/rbac-control-settings.model';
import { omit } from 'lodash-es';
import { IsPublicEnvironment } from '@wcd/auth';
import { AppFlavorConfig } from '@wcd/scc-common';
var AdvancedFeaturesService = /** @class */ (function () {
    function AdvancedFeaturesService(officeIntegrationService, featuresService, appConfigService, paris, flavorService) {
        this.officeIntegrationService = officeIntegrationService;
        this.featuresService = featuresService;
        this.appConfigService = appConfigService;
        this.paris = paris;
        this.flavorService = flavorService;
        this.isFairFax = appConfigService.environmentName === EnvironmentName.Fairfax;
    }
    Object.defineProperty(AdvancedFeaturesService.prototype, "advancedFeatureSections", {
        get: function () {
            var _this = this;
            if (!this._advancedFeatureSections) {
                var previewEx$_1 = this.paris.getItemById(PreviewExperienceSettings, 1).pipe(delay(1), share());
                var isLiveResponseForServersEnabled_1 = this.featuresService.isAnyEnabled([
                    Feature.Server2019BackportSupportToRS6,
                    Feature.LiveResponseForWindowsServer2016,
                    Feature.LiveResponseForWindowsServer2012R2,
                ]) && !this.featuresService.isEnabled(Feature.LiveResponseForServerExcludeTenant);
                this._advancedFeatureSections = (this.flavorService.isEnabled(AppFlavorConfig.settings.autoIRMdeOnly)
                    ? [
                        {
                            name: 'Automated investigations',
                            getValues: function () {
                                return of({
                                    automatedIr: _this.appConfigService.isAutomatedIrEnabled,
                                });
                            },
                            saveValues: function (values) {
                                return previewEx$_1.pipe(switchMap(function (previewData) {
                                    return _this.paris
                                        .getRepository(AutomatedIrStatus)
                                        .save({
                                        isEnabled: values.automatedIr,
                                        sliceId: previewData.sliceId,
                                    })
                                        .pipe(mergeMap(function () {
                                        return _this.paris
                                            .getItemById(AutomatedIrStatus, 1, {
                                            allowCache: false,
                                        }, {
                                            sliceId: previewData.sliceId,
                                        })
                                            .pipe(tap(function (res) {
                                            if (res.isEnabled === values.automatedIr) {
                                                _this.appConfigService.updateChangeableProperties({
                                                    isAutomatedIrEnabled: res.isEnabled,
                                                });
                                                _this.appConfigService.isAutomatedIrEnabled$.next(_this.appConfigService
                                                    .isAutomatedIrEnabled);
                                            }
                                            else
                                                throw "Failed to " + (values.automatedIr
                                                    ? 'enable'
                                                    : 'disable') + " Automated Investigations";
                                        }), retryWhen(function (errors) {
                                            return errors.pipe(scan(function (errorCount, err) {
                                                if (errorCount > 2)
                                                    throw err;
                                                return errorCount + 1;
                                            }, 0), delayWhen(function () { return timer(5000, 5000); }));
                                        }));
                                    }));
                                }));
                            },
                            settings: [
                                {
                                    id: 'automatedIr',
                                    requireLicense: false,
                                    priority: 0,
                                },
                            ],
                        },
                    ]
                    : []).concat([
                    this.appConfigService.isAutomatedIrEnabled &&
                        this.flavorService.isEnabled(AppFlavorConfig.settings.autoIRMdeOnly)
                        ? {
                            name: 'Live Response',
                            reevaluateOnSettingChange: true,
                            getValues: function (changes) {
                                if (!changes) {
                                    return _this.paris.getItemById(LiveResponseSettings, 1, undefined, {
                                        useV2Api: _this.featuresService.isEnabled(Feature.LiveResponseTransitionCodeSeparation),
                                    });
                                }
                                var newValues = changes.newValues, settingId = changes.settingId;
                                if (!isLiveResponseForServersEnabled_1) {
                                    return of(newValues);
                                }
                                if (settingId === 'liveResponseForServers') {
                                    return of(newValues);
                                }
                                return of(__assign({}, newValues, { liveResponseForServers: newValues.liveResponseEnabled }));
                            },
                            saveValues: function (settings, originalSettings) {
                                var settingsObj = [
                                    'liveResponseEnabled',
                                    'unsignedScriptsEnabled',
                                    'liveResponseForServers',
                                ].reduce(function (acc, prop) {
                                    var _a;
                                    if (settings[prop] !== originalSettings[prop]) {
                                        return __assign({}, acc, (_a = {}, _a[prop] = settings[prop], _a));
                                    }
                                    return acc;
                                }, { id: undefined });
                                var repository = _this.paris.getRepository(LiveResponseSettings);
                                return repository
                                    .save(settingsObj, {
                                    params: {
                                        useV2Api: _this.featuresService.isEnabled(Feature.LiveResponseTransitionCodeSeparation),
                                    },
                                })
                                    .pipe(tap({
                                    next: function () {
                                        _this.appConfigService.updateChangeableProperties({
                                            isLiveResponseEnabled: settingsObj.liveResponseEnabled,
                                        });
                                    },
                                    complete: function () {
                                        repository.clearCache();
                                    },
                                }));
                            },
                            settings: [
                                {
                                    id: 'liveResponseEnabled',
                                    isVisible: function (sectionSettings) {
                                        return !_this.isFairFax ||
                                            _this.featuresService.isEnabled(Feature.FairfaxLiveResponse);
                                    },
                                    requireLicense: false,
                                    priority: 30,
                                    rbacControlSettings: {
                                        permissions: [RbacMdeAllowedActions.admin],
                                        state: RbacControlState.disabled,
                                    },
                                },
                                isLiveResponseForServersEnabled_1
                                    ? {
                                        id: 'liveResponseForServers',
                                        isVisible: function (sectionSettings) {
                                            return !_this.isFairFax ||
                                                _this.featuresService.isEnabled(Feature.FairfaxLiveResponse);
                                        },
                                        requireLicense: false,
                                        priority: 40,
                                        rbacControlSettings: {
                                            permissions: [RbacMdeAllowedActions.admin],
                                            state: RbacControlState.disabled,
                                        },
                                        isDisabled: function (liveResponseForServers, sectionData) {
                                            return of(!sectionData.liveResponseEnabled);
                                        },
                                        warningTooltip: 'settings_advancedFeatures_features_liveResponseForServers_warningTooltip',
                                    }
                                    : null,
                                {
                                    id: 'unsignedScriptsEnabled',
                                    isVisible: function (sectionSettings) {
                                        return !_this.isFairFax ||
                                            _this.featuresService.isEnabled(Feature.FairfaxLiveResponseUnsignedScripts);
                                    },
                                    requireLicense: false,
                                    priority: 50,
                                    rbacControlSettings: {
                                        permissions: [RbacMdeAllowedActions.admin],
                                        state: RbacControlState.disabled,
                                    },
                                },
                            ].filter(Boolean),
                            features: [Feature.LiveResponse],
                        }
                        : null,
                    this.appConfigService.isAutomatedIrEnabled
                        ? {
                            name: 'PUA remediation',
                            getValues: function () {
                                return _this.paris.getItemById(AutomatedIrProperties, 1).pipe(map(function (settings) {
                                    if (_this.featuresService.isEnabled(Feature.PuaMachineConfiguration)) {
                                        return __assign({}, settings, { automatedIrRemediatePuaMachine: settings.automatedIrRemediatePua });
                                    }
                                    return settings;
                                }));
                            },
                            saveValues: function (settings) {
                                var remediatePua = settings.automatedIrRemediatePuaMachine === undefined
                                    ? settings.automatedIrRemediatePua
                                    : settings.automatedIrRemediatePuaMachine;
                                var parsedSettings = __assign({}, settings, { automatedIrRemediatePua: remediatePua });
                                return _this.paris
                                    .getRepository(AutomatedIrProperties)
                                    .save(omit(parsedSettings, ['id', 'automatedIrRemediatePuaMachine']));
                            },
                            settings: [
                                {
                                    id: this.featuresService.isEnabled(Feature.PuaMachineConfiguration)
                                        ? 'automatedIrRemediatePuaMachine'
                                        : 'automatedIrRemediatePua',
                                    requireLicense: false,
                                    priority: 60,
                                    rbacControlSettings: {
                                        permissions: [RbacMdeAllowedActions.securitySettings],
                                        state: RbacControlState.disabled,
                                    },
                                },
                            ],
                            features: [Feature.PuaAsSuspicious],
                        }
                        : null,
                    {
                        name: 'Advanced features settings',
                        getValues: function () {
                            return _this.getAdvancedFeaturesSettings().pipe(map(function (values) { return (__assign({}, values, { deviceDiscoveryEnabled: !values.magellanOptOut })); }));
                        },
                        saveValues: function (values) {
                            return _this.paris
                                .getRepository(AdvancedFeatures)
                                .save(__assign({}, values, { magellanOptOut: !values.deviceDiscoveryEnabled }));
                        },
                        settings: [
                            {
                                id: 'isolateIncidentsWithDifferentDeviceGroups',
                                priority: 70,
                                warning: function () {
                                    return of('settings_advancedFeatures_features_isolateIncidentsWithDifferentDeviceGroups_warning');
                                },
                                ignoreWarnings: true,
                                features: [Feature.EnableControllingRbacConstraint],
                                flavorConfig: AppFlavorConfig.settings.deviceGroups,
                            },
                            {
                                id: 'enableWdavAuditMode',
                                requireLicense: false,
                                priority: 80,
                                features: [Feature.EnableWdavAuditMode],
                            },
                            {
                                id: 'enableWdavPassiveModeRemediation',
                                requireLicense: false,
                                priority: 90,
                                features: [Feature.EnableWdavPassiveModeRemediation],
                                flavorConfig: AppFlavorConfig.settings.tvm,
                            },
                            {
                                id: 'autoResolveInvestigatedAlerts',
                                requireLicense: false,
                                priority: 100,
                                features: [Feature.AutoResolveInvestigatedAlerts],
                                flavorConfig: AppFlavorConfig.settings.investigation,
                            },
                            {
                                id: 'blockListEnabled',
                                requireLicense: false,
                                priority: 110,
                            },
                            {
                                id: 'allowWdavNetworkBlock',
                                requireLicense: false,
                                priority: 120,
                                features: [Feature.BlockIpAndDomain],
                            },
                            {
                                id: 'enableWdavAntiTampering',
                                requireLicense: false,
                                priority: 130,
                                features: [Feature.WdavAntiTampering],
                                warning: function () {
                                    return of('settings_advancedFeatures_features_enableWdavAntiTampering_warning');
                                },
                                ignoreWarnings: true,
                            },
                            {
                                id: 'showUserAadProfile',
                                requireLicense: true,
                                priority: 140,
                            },
                            {
                                id: 'skypeIntegrationEnabled',
                                isVisible: function (sectionSettings) {
                                    return !_this.isFairFax ||
                                        _this.featuresService.isEnabled(Feature.FairfaxSkypeIntegration);
                                },
                                requireLicense: true,
                                priority: 150,
                            },
                            {
                                id: 'endpointDlpEnabled',
                                isVisible: function (sectionSettings) {
                                    return _this.featuresService.isEnabled(Feature.DlpOfficeAudit);
                                },
                                requireLicense: false,
                                priority: 160,
                            },
                            {
                                id: 'aatpIntegrationEnabled',
                                requireLicense: true,
                                isVisible: function () {
                                    return _this.featuresService.isEnabled(Feature.AatpIntegration);
                                },
                                warning: function (value, advancedFeatures) {
                                    var isAtpWarning = value &&
                                        advancedFeatures.aatpIntegrationEnabled &&
                                        !advancedFeatures.aatpWorkspaceExists;
                                    return of(isAtpWarning
                                        ? 'settings.advancedFeatures.features.aatpIntegrationEnabled.warning'
                                        : null);
                                },
                                priority: 170,
                                flavorConfig: AppFlavorConfig.settings.itpIntegration,
                            },
                            {
                                id: 'officeIntegrationEnabled',
                                isVisible: function (sectionSettings) {
                                    return !_this.isFairFax ||
                                        _this.featuresService.isEnabled(Feature.FairfaxOfficeIntegration);
                                },
                                requireLicense: false,
                                badge: function () {
                                    return _this.officeIntegrationService.officeIntegrationWarning$.pipe(map(function (isWarning) { return (isWarning ? 1 : null); }));
                                },
                                warningTooltip: 'settings.advancedFeatures.features.officeIntegrationEnabled.warningTooltip',
                                warning: function () {
                                    return _this.officeIntegrationService.officeIntegrationSettings$.pipe(map(function (officeIntegrationSettings) {
                                        var warningKey = null;
                                        if (officeIntegrationSettings &&
                                            officeIntegrationSettings.enableOffice365Integration) {
                                            if (!officeIntegrationSettings.isAuthorized)
                                                warningKey = 'integrationPermission';
                                            else if (!officeIntegrationSettings.officeLicenseEnabled)
                                                warningKey = 'license';
                                            else if (!officeIntegrationSettings.officeToAtpIntegrationEnabled)
                                                warningKey = 'optIn';
                                        }
                                        return (warningKey &&
                                            "settings.advancedFeatures.features.officeIntegrationEnabled.warnings." + warningKey);
                                    }));
                                },
                                priority: 180,
                                features: [Feature.OfficeAtpIntegration],
                                flavorConfig: AppFlavorConfig.settings.officeIntegration,
                            },
                            {
                                id: 'managedThreatHuntingEnabled',
                                requireLicense: false,
                                isVisible: function (sectionSettings) {
                                    return !!sectionSettings.managedThreatHuntingApproved &&
                                        (!_this.isFairFax ||
                                            _this.featuresService.isEnabled(Feature.FairfaxThreatHunting));
                                },
                                features: [Feature.BilbaoRegistration],
                                flavorConfig: AppFlavorConfig.threatExpert.mde,
                                ignoreWarnings: true,
                                priority: 190,
                            },
                            {
                                id: 'enableMcasIntegration',
                                isVisible: function () { return _this.featuresService.isEnabled(Feature.McasIntegration); },
                                requireLicense: false,
                                priority: 200,
                                features: [Feature.McasIntegration],
                                flavorConfig: AppFlavorConfig.settings.mcasIntegration,
                            },
                            {
                                id: 'enableAipIntegration',
                                isVisible: function () { return _this.featuresService.isEnabled(Feature.AatpIntegration); },
                                requireLicense: false,
                                priority: 210,
                                features: [Feature.AipIntegration],
                            },
                            {
                                id: 'webCategoriesEnabled',
                                isVisible: function (sectionSettings) {
                                    return !_this.isFairFax ||
                                        _this.featuresService.isEnabled(Feature.FairfaxWebCategories);
                                },
                                requireLicense: false,
                                priority: 230,
                                features: [Feature.WebContentFilteringPolicy],
                            },
                            {
                                id: 'enableAuditTrail',
                                requireLicense: false,
                                priority: 240,
                                isVisible: function (sectionSettings) {
                                    return IsPublicEnvironment(_this.appConfigService);
                                },
                                features: [Feature.MgmtAuditTrail],
                                flavorConfig: AppFlavorConfig.settings.audit,
                            },
                            {
                                id: 'deviceDiscoveryEnabled',
                                requireLicense: false,
                                priority: 285,
                                features: [Feature.MagellanOptOut],
                                flavorConfig: AppFlavorConfig.devices.iotDevices,
                                ignoreWarnings: true,
                            },
                            {
                                id: 'dartDataCollection',
                                priority: 289,
                                isVisible: function (sectionSettings) {
                                    return _this.featuresService.isEnabled(Feature.EnableDartDataCollection);
                                },
                                requireLicense: false,
                                warning: function () { return of('settings_advancedFeatures_features_dartDataCollection_warning'); },
                                ignoreWarnings: true,
                                rbacControlSettings: {
                                    permissions: [RbacMdeAllowedActions.admin]
                                },
                                features: [Feature.EnableDartDataCollection],
                            },
                            {
                                id: 'enableQuarantinedFileDownload',
                                requireLicense: false,
                                priority: 260,
                                features: [Feature.QuarantinedFileDownload],
                                flavorConfig: AppFlavorConfig.settings.autoIR,
                            },
                        ].filter(function (item) { return !item.flavorConfig || _this.flavorService.isEnabled(item.flavorConfig); }),
                    },
                    {
                        name: 'Compliance Alerts share',
                        getValues: function () {
                            return _this.paris.getItemById(ComplianceAlertsShareStatus, 1).pipe(catchError(function (err) {
                                return of({
                                    complianceAlertsShareStatus: false,
                                    failed: true,
                                });
                            }));
                        },
                        saveValues: function (complianceAlertsShareStatus) {
                            return _this.paris
                                .getRepository(ComplianceAlertsShareStatus)
                                .save(complianceAlertsShareStatus)
                                .pipe(catchError(function () {
                                return throwError("Failed to " + (complianceAlertsShareStatus.complianceAlertsShareEnabled
                                    ? 'enable'
                                    : 'disable') + " compliance alerts share.");
                            }));
                        },
                        settings: [
                            {
                                id: 'complianceAlertsShareEnabled',
                                priority: 270,
                                isVisible: function (sectionSettings) {
                                    return !_this.isFairFax ||
                                        _this.featuresService.isEnabled(Feature.ComplianceAlertSharing);
                                },
                            },
                        ],
                        features: [Feature.ComplianceAlertSharing],
                        flavorConfig: AppFlavorConfig.settings.alertsShare,
                    },
                    {
                        name: 'Intune integration settings',
                        getValues: function () {
                            return _this.paris.getItemById(IntuneIntegrationStatus, 1).pipe(catchError(function (err) {
                                return of({
                                    IntuneIntegrationStatus: false,
                                    licenseEnabled: false,
                                    failed: true,
                                });
                            }));
                        },
                        saveValues: function (values) {
                            return _this.paris
                                .getRepository(IntuneIntegrationStatus)
                                .save(values)
                                .pipe(catchError(function () {
                                return throwError("Failed to " + (values.intuneIntegrationEnabled ? 'enable' : 'disable') + " Intune integration.");
                            }));
                        },
                        settings: [
                            {
                                id: 'intuneIntegrationEnabled',
                                requireLicense: true,
                                warningTooltip: 'settings.advancedFeatures.features.intuneIntegrationEnabled.warningTooltip',
                                warning: function (value, intuneIntegrationStatus) {
                                    return of(intuneIntegrationStatus.licenseEnabled || intuneIntegrationStatus.failed
                                        ? null
                                        : 'settings.advancedFeatures.features.intuneIntegrationEnabled.warning');
                                },
                                priority: 280,
                                isVisible: function (sectionSettings) {
                                    return !_this.isFairFax ||
                                        _this.featuresService.isEnabled(Feature.FairfaxIntuneIntegration);
                                },
                            },
                        ],
                        features: [Feature.IntuneIntegration],
                    },
                    {
                        name: 'Preview experience settings',
                        getValues: function () { return previewEx$_1; },
                        saveValues: function (values) {
                            return _this.paris.getRepository(PreviewExperienceSettings).save(values);
                        },
                        settings: [
                            {
                                id: 'previewExperienceEnabled',
                                isDisabled: function (value, data) { return of(data.forcedEnabled); },
                                isVisible: function (sectionSettings) {
                                    return !_this.isFairFax ||
                                        _this.featuresService.isEnabled(Feature.FairfaxPreviewExperience);
                                },
                                warningTooltip: 'settings.advancedFeatures.features.previewExperienceEnabled.warningTooltip',
                                requireLicense: false,
                                priority: 290,
                            },
                        ],
                    },
                    {
                        name: 'AllowNonAuthenticatedSense',
                        getValues: function () {
                            return _this.paris.getItemById(AllowNonAuthenticatedSenseStatus, 1).pipe(catchError(function (err) {
                                return of({
                                    allowNonAuthenticatedSense: false,
                                    failed: true,
                                });
                            }));
                        },
                        saveValues: function (allowNonAuthenticatedSenseStatus) {
                            return _this.paris
                                .getRepository(AllowNonAuthenticatedSenseStatus)
                                .save(allowNonAuthenticatedSenseStatus)
                                .pipe(catchError(function () {
                                return throwError("Failed to " + (allowNonAuthenticatedSenseStatus.allowNonAuthenticatedSense
                                    ? 'enable'
                                    : 'disable') + " allow non authenticated Sense.");
                            }));
                        },
                        settings: [
                            {
                                id: 'allowNonAuthenticatedSense',
                                priority: 270,
                                features: [Feature.AllowNonAuthenticatedSense],
                            },
                        ],
                    },
                ]).filter(function (section) {
                    return section &&
                        (!section.features ||
                            (_this.featuresService.isAnyEnabled(section.features) &&
                                (!section.flavorConfig || _this.flavorService.isEnabled(section.flavorConfig))));
                });
            }
            return this._advancedFeatureSections;
        },
        enumerable: true,
        configurable: true
    });
    AdvancedFeaturesService.prototype.getAdvancedFeaturesSettings = function () {
        return this.paris.getItemById(AdvancedFeatures, 1);
    };
    return AdvancedFeaturesService;
}());
export { AdvancedFeaturesService };
