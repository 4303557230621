var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, OnDestroy, ChangeDetectorRef, } from '@angular/core';
import { SpinnerSize } from 'office-ui-fabric-react';
import { AssessmentJob, TvmEndPoint, MdeUserRoleActionEnum } from '@wcd/domain';
import { TitleService } from '../../../../shared/services/title.service';
import { GlobalEntityTypesService } from '../../../../global_entities/services/global-entity-types.service';
import { DataViewComponent } from '../../../../dataviews/components/dataview.component';
import { AssessmentJobFieldsService } from '../services/assessment-job.fields.service';
import { SidePanelService } from '../../common/side-panel.service';
import { ItemActionModel } from '../../../../dataviews/models/item-action.model';
import { I18nService } from '@wcd/i18n';
import { TvmDownloadService } from '../../../../tvm/services/tvm-download.service';
import { AssessmentJobService } from '../services/assessment-job.service';
import { Router } from '@angular/router';
import { DataviewActionTypes } from '../../../../dataviews/components/actions-components/dataview-actions.model';
import { FabricIconNames } from '@wcd/scc-common';
import { sccHostService } from '@wcd/scc-interface';
import { AuthService } from '@wcd/auth';
import { AdvancedFeaturesService } from '../../../../admin/integration-settings/advanced-features.service';
import { Subscription } from 'rxjs';
import { MessageBarType } from 'office-ui-fabric-react';
import { PerformanceSccService } from '../../../../insights/services/performance.scc.service';
import { AppConfigService } from '@wcd/app-config';
var NetworkScansDataviewComponent = /** @class */ (function () {
    function NetworkScansDataviewComponent(i18nService, tvmDownloadService, assessmentJobService, globalEntityTypesService, router, authService, advancedFeaturesService, changeDetectorRef, fieldsService, titleService, sidePanelService, performanceSccService, appConfigService) {
        this.i18nService = i18nService;
        this.tvmDownloadService = tvmDownloadService;
        this.assessmentJobService = assessmentJobService;
        this.globalEntityTypesService = globalEntityTypesService;
        this.router = router;
        this.authService = authService;
        this.advancedFeaturesService = advancedFeaturesService;
        this.changeDetectorRef = changeDetectorRef;
        this.fieldsService = fieldsService;
        this.titleService = titleService;
        this.sidePanelService = sidePanelService;
        this.performanceSccService = performanceSccService;
        this.appConfigService = appConfigService;
        this.SpinnerSize = SpinnerSize;
        this.isScc = sccHostService.isSCC;
        this.MessageBarType = MessageBarType;
        this.customButtonConfig = [];
        this.repository = this.assessmentJobService.getRepository();
        this.fields = this.fieldsService.fields;
    }
    NetworkScansDataviewComponent.prototype.ngOnInit = function () {
        this.magellanOptOut = this.appConfigService.magellanOptOut;
        this.setDataViewConfig(this.magellanOptOut);
    };
    NetworkScansDataviewComponent.prototype.setDataViewConfig = function (magellanOptOut) {
        var _this = this;
        var userHavePermissions = this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.securitySettings);
        this.readOnlyMode = magellanOptOut || !userHavePermissions;
        if (!this.readOnlyMode) {
            this.itemActions = [
                {
                    id: 'delete',
                    name: this.i18nService.get('delete'),
                    icon: 'delete',
                    tooltip: this.i18nService.get('deleteSelectedItems', {
                        itemsName: this.repository.entity.pluralName,
                    }),
                    method: function (items) { return _this.deleteItems(items); },
                    refreshOnResolve: true,
                },
            ].map(function (itemActionConfig) { return new ItemActionModel(itemActionConfig); });
            this.customButtonConfig = [
                {
                    localizedTooltip: this.i18nService.get('tvm_networkGear_dataview_downloadAgent'),
                    onClickCallback: function () { return _this.downloadAgentInstallation(); },
                    icon: FabricIconNames.Download,
                    localizedLabel: this.i18nService.get('tvm_networkGear_dataview_downloadAgent'),
                    elementId: 'download-agent-btn',
                    actionType: DataviewActionTypes.Button,
                },
                {
                    localizedTooltip: this.i18nService.get('tvm_networkGear_dataview_addNetworkAssessmentJob'),
                    onClickCallback: function () { return _this.addOrEditNetworkAssessmentJob(); },
                    icon: FabricIconNames.Add,
                    localizedLabel: this.i18nService.get('tvm_networkGear_dataview_addNetworkAssessmentJob'),
                    elementId: 'add-network-assessment-job-btn',
                    actionType: DataviewActionTypes.Button,
                },
            ];
        }
        this.boundSetItemActions = this.setItemActions.bind(this);
        this.entityType = this.globalEntityTypesService.getEntityType(AssessmentJob);
        this.dataViewOptions = __assign({}, this.entityType.dataViewOptions);
        this.dataViewConfig = {
            showModalOnExport: this.dataViewOptions.exportOptions.showModalOnExport,
            exportResults: function (options, format, dataQuery) {
                return _this.dataViewOptions.exportOptions.exportResults(options, null, null, dataQuery);
            },
        };
        this.loaded = true;
        this.changeDetectorRef.markForCheck();
    };
    NetworkScansDataviewComponent.prototype.ngOnDestroy = function () {
        this.subscription && this.subscription.unsubscribe();
    };
    NetworkScansDataviewComponent.prototype.setItemActions = function (assessmentJobs) {
        return !assessmentJobs || !assessmentJobs.length ? [] : this.itemActions;
    };
    NetworkScansDataviewComponent.prototype.deleteItems = function (items) {
        return this.assessmentJobService.deleteItems(items);
    };
    NetworkScansDataviewComponent.prototype.downloadAgentInstallation = function () {
        var url = TvmEndPoint.NetworkScans + "/agents/setup";
        this.tvmDownloadService.downloadFile({ url: url, fileName: 'MdatpScanAgentSetup.msi' });
    };
    NetworkScansDataviewComponent.prototype.navigateToDeviceInventory = function () {
        this.router.navigateByUrl('machines?category=network-devices');
    };
    NetworkScansDataviewComponent.prototype.addOrEditNetworkAssessmentJob = function (assessmentJob) {
        if (assessmentJob) {
            this.sidePanelService.showNetworkAssessmentJobWizard(assessmentJob, this.readOnlyMode);
        }
        else {
            var newAssessmentJob = new AssessmentJob();
            newAssessmentJob.active = true;
            this.sidePanelService.showNetworkAssessmentJobWizard(newAssessmentJob, this.readOnlyMode);
        }
    };
    NetworkScansDataviewComponent.prototype.onTableRenderComplete = function () {
        this.performanceSccService.endNgPageLoadPerfSession('network-scans-dataview');
    };
    return NetworkScansDataviewComponent;
}());
export { NetworkScansDataviewComponent };
