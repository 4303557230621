import { Entity } from '@microsoft/paris';
import { NetworkScanResult, TvmNetworkScanSharedEntityConfigurations } from '@wcd/domain';

//Adding this entity because we use in this case another endpoint for csv as we cover our results with {output: "", endOfFile:""}
// in the original one for the polling mechanism
@Entity({
	...TvmNetworkScanSharedEntityConfigurations,
	singularName: 'Network scan result as csv',
	pluralName: 'Network scan results as csv',
	endpoint: 'commands/liveResultsCsv',
})
export class NetworkScanResultCsv extends NetworkScanResult {}
