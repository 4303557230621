import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { OutbreakMitigationType } from './mitigations/outbreak-mitigation-type.values';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { ThreatReportType } from './threat-report-type.values';

@Entity({
	singularName: 'Threat',
	pluralName: 'Threats',
	readonly: true,
	endpoint: 'outbreaks',
	loadAll: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatAnalytics,
})
export class Outbreak extends EntityModelBase<string> {
	@EntityField({ data: 'Id' })
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'DisplayName' })
	displayName: string;

	@EntityField({ data: 'CreatedOn' })
	createdOn: Date;

	@EntityField({ data: 'StartedOn' })
	startedOn: Date;

	@EntityField({ data: 'LastUpdatedOn' })
	lastUpdatedOn: Date;

	@EntityField({ data: 'Severity' })
	severity: number;

	@EntityField({ data: 'Keywords' })
	keywords: Array<string>;

	@EntityField({ data: 'Actors' })
	actors: Array<string>;

	@EntityField({ data: 'TargetIndustries' })
	targetIndustries: Array<string>;

	@EntityField({ data: 'Tags' })
	tags: Array<string>;

	@EntityField({ data: 'References' })
	references: Array<string>;

	@EntityField({ data: 'IOAIds' })
	ioaIds: Array<string>;

	@EntityField({ data: 'MitigationTypes' })
	mitigationTypes: Array<OutbreakMitigationType>;

	@EntityField({ data: 'LastVisitTime' })
	lastVisitTime?: Date;

	@EntityField({ data: 'ReportType' })
	reportType: ThreatReportType;
}
