var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AppContextService, FeaturesService } from '@wcd/config';
import { sccHostService, SccRoles } from '@wcd/scc-interface';
import { isUnifiedAlert } from '../shared-utils';
var MdatpToSCCUrlMap = {
    'localhost:4200': 'https://security.officeppe.com',
    'stg.localhost:4200': 'https://security.officeppe.com',
    'master.staging.securitycenter.microsoft.com': 'https://security.officeppe.com',
    'staging.securitycenter.microsoft.com': 'https://security.officeppe.com',
    'df.securitycenter.microsoft.com': 'https://df.security.microsoft.com',
    'df.securitycenter.windows.com': 'https://df.security.microsoft.com',
    'securitycenter.microsoft.com': 'https://security.microsoft.com',
    'securitycenter.windows.com': 'https://security.microsoft.com',
};
var SccToMdatpUrlMap = {
    'protection.office.com': 'https://df.securitycenter.microsoft.com',
    'security.officeppe.com': 'https://master.staging.securitycenter.microsoft.com',
    'dev.protection.office.com': 'https://df.securitycenter.microsoft.com',
    'df.security.microsoft.com': 'https://df.securitycenter.microsoft.com',
    'security.microsoft.com': 'https://securitycenter.microsoft.com',
};
var defaultMdatpUrlRoot = 'https://securitycenter.windows.com';
var devMdatpUrl = 'http://localhost:4200';
var InnerMdatpPageToInnerSccPagelMap = {
    '/users': '/mdatp-users',
    '/alertsQueue': '/endpoints/alertsQueue',
    '/searchResults': './endpoints/search/input',
    '/suppressionRule': '/preferences2/suppressionRule'
};
// path mapping for backend errors
export var ERROR_PAGE_MAPPING = {
    LIVE_RESPONSE_SESSION: '/live-response/',
    INVESTIGATION_PAGE: '/investigation/',
    DEVICE_PROFILE: '/machines/',
};
// Routes defined in SCC app we wish to be able to navigate to
var ExternalSccRoutes = ['/securitysettings', '/preferences2/suppressionRule', '/user'];
var RoutesService = /** @class */ (function () {
    function RoutesService(sccRoutes, appContextService, featuresService) {
        this.sccRoutes = sccRoutes;
        this.appContextService = appContextService;
        this.featuresService = featuresService;
        this.sccRoutes = sccRoutes.concat(ExternalSccRoutes);
    }
    RoutesService.prototype.getMdatpFromSccUrl = function (innerMdatpPath) {
        var sccLinkConfig = this.getMdatpFromSccLinkConfig(innerMdatpPath);
        return (sccLinkConfig && sccLinkConfig.url) || innerMdatpPath;
    };
    // This function converts the given inner MDATP url to an MDATP in SCC url.
    // The new url is used to navigate either out of SCC portal to an MDATP page or inside SCC portal to an MDATP page.
    RoutesService.prototype.getMdatpFromSccLinkConfig = function (innerMdatpPath) {
        if (this.appContextService.isSCC && innerMdatpPath) {
            if (innerMdatpPath.startsWith('.')) {
                return { url: innerMdatpPath, isExternal: false };
            }
            var pageNameIndexInPath = 0;
            var mdatpPage = RoutesService.getSegmentFromUrl(innerMdatpPath, pageNameIndexInPath);
            if (mdatpPage === "/alerts" && isUnifiedAlert(RoutesService.getSegmentFromUrl(innerMdatpPath, pageNameIndexInPath + 1, false), this.featuresService)) {
                return {
                    url: innerMdatpPath,
                    isExternal: false
                };
            }
            // If we navigate to MDATP page inside SCC we might need to rename the page
            // for example: user page inside SCC should be renamed to mdatp-users to avoid confusion with MCAS user's page
            var mdatpInSccPage_1 = InnerMdatpPageToInnerSccPagelMap[mdatpPage] || mdatpPage;
            var hasSccEq = mdatpInSccPage_1.startsWith('.') ||
                this.sccRoutes.some(function (sccRoutePage) { return mdatpInSccPage_1 === sccRoutePage; });
            if (hasSccEq) {
                // We navigate to the page inside SCC
                return {
                    url: RoutesService.replaceSegmentInUrl(innerMdatpPath, mdatpInSccPage_1.replace(/^\./, ''), pageNameIndexInPath),
                    isExternal: false,
                };
            }
            // We navigate out of SCC to MDATP portal
            return { url: RoutesService.getMdatpRootUrl() + innerMdatpPath, isExternal: true };
        }
        return null;
    };
    RoutesService.prototype.shouldOpenExternally = function (linkConfig) {
        return linkConfig && linkConfig.isExternal;
    };
    Object.defineProperty(RoutesService.prototype, "unifiedUserPageEnabled", {
        get: function () {
            return sccHostService.inSccRole(SccRoles.hasUnifiedUserPage);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoutesService.prototype, "unifiedUserPaneEnabled", {
        get: function () {
            return sccHostService.inSccRole(SccRoles.hasUnifiedUserPane);
        },
        enumerable: true,
        configurable: true
    });
    RoutesService.prototype.getUnifiedUserPaneEnabledAsync = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, sccHostService.inSccRoleAsync(SccRoles.hasUnifiedUserPane)];
            });
        });
    };
    RoutesService.getMdatpRootUrl = function () {
        var sccRootUrl = window.location.host;
        var wdatpRootUrl;
        if (sccHostService.mock && sccHostService.mock.isMockMode) {
            wdatpRootUrl = devMdatpUrl;
        }
        else {
            wdatpRootUrl = SccToMdatpUrlMap[sccRootUrl] || defaultMdatpUrlRoot;
        }
        return wdatpRootUrl;
    };
    RoutesService.getSCCRootUrl = function () {
        var rootUrl = window.location.host;
        return MdatpToSCCUrlMap[rootUrl];
    };
    RoutesService.getSegmentFromUrl = function (url, segmentIndex, addSlashPrefix) {
        if (addSlashPrefix === void 0) { addSlashPrefix = true; }
        if (url) {
            var separateOptionalParameters = RoutesService.splitUrlToOptionalAndRequiredParts(url);
            var urlSegments = RoutesService.splitUrlToSegments(separateOptionalParameters[0]);
            if (urlSegments.length > segmentIndex) {
                return addSlashPrefix ? '/' + urlSegments[segmentIndex] : urlSegments[segmentIndex];
            }
        }
        return '';
    };
    RoutesService.replaceSegmentInUrl = function (url, newSegment, segmentIndex) {
        if (!url) {
            return '';
        }
        var separateOptionalParameters = RoutesService.splitUrlToOptionalAndRequiredParts(url);
        var urlSegments = RoutesService.splitUrlToSegments(separateOptionalParameters[0]);
        urlSegments[0] = newSegment;
        separateOptionalParameters[0] = urlSegments.join('/');
        return separateOptionalParameters.join('?');
    };
    RoutesService.splitUrlToOptionalAndRequiredParts = function (url) {
        return url.split('?');
    };
    RoutesService.splitUrlToSegments = function (url) {
        var urlSections = url.split('/');
        urlSections.splice(0, 1); // Use splice to remove leading ''
        return urlSections;
    };
    return RoutesService;
}());
export { RoutesService };
