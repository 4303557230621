import { EntityRelationship, RelationshipType, EntityRelationshipRepositoryType } from '@microsoft/paris';
import { File } from '../file.entity';
import { FileProtectionInfo } from '../file-protection-info.value-object';

@EntityRelationship({
	sourceEntity: File,
	dataEntity: FileProtectionInfo,
	getRelationshipData: (file: File) => ({ id: file.id }),
	endpoint: (_, query) => `files/${query.where['id']}/protection`,
	baseUrl: config => config.data.serviceUrls.threatIntel,
	cache: {
		max: 10,
		time: 1000 * 60,
	},
})
export class FileFileProtectionInfoRelationship
	implements EntityRelationshipRepositoryType<File, FileProtectionInfo> {}
