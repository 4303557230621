import {
	EntityRelationship,
	EntityRelationshipRepositoryType,
	RelationshipType,
	DataQuery,
} from '@microsoft/paris';
import { Url } from '../url.entity';
import { EntityStatistics } from '../../entity/entity-statistics.value-object';

@EntityRelationship({
	sourceEntity: Url,
	dataEntity: EntityStatistics,
	allowedTypes: [RelationshipType.OneToOne],
	cache: {
		time: 1000 * 60 * 5 , // 5 minutes
		max: 10,
	},
	baseUrl: config => config.data.serviceUrls.threatIntel,
	endpoint: 'UrlStats',
	allItemsEndpointTrailingSlash: false,
	getRelationshipData: (url: Url) => ({
		url: url.id,
	}),
	parseDataQuery: (dataQuery: DataQuery) => {
		const where: Record<string, string> =
			dataQuery && dataQuery.where ? (dataQuery.where as Record<string, string>) : {};

		return {
			lookingBackInDays: 30,
			...where,
		};
	},
})
export class UrlUrlStatsRelationship implements EntityRelationshipRepositoryType<Url, EntityStatistics> {}
