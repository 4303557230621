var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ComponentRef } from '@angular/core';
import { PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { Paris } from '@microsoft/paris';
import { Vulnerability, MissingKbExposedAssetsRelationship, MissingKb, MissingKbRelatedVulnerabilitiesRelationship, SecurityRecommendation, RecommendationExposedAssetsRelationship, RecommendationRelatedVulnerabilitiesRelationship, VulnerabilityExposedAssetsRelationship, AssetRecommendation, AssetMissingKb, AssetMissingKbVulnerabilityRelationship, AssessmentJob, RecommendationInstalledAssetsRelationship, ChangeEvent, ChangeEventRelatedVulnerabilitiesRelationship, RecommendationContextType, AssetRecommendationContextRelationship, RecommendationContextKeyAndExposedMachinesCountRelationship, RecommendationContextKeyPanelType, MachineGroup, NetworkDevice, NetworkDeviceRelatedVulnerabilitiesRelationship, SoftwareVersion, SoftwareVersionInstalledAssetsRelationship, } from '@wcd/domain';
import { TvmTextsService, TextToken } from '../../../tvm/services/tvm-texts.service';
import { RelatedCvesPanelComponent } from '../../../global_entities/components/entity-panels/related-cves.panel.component';
import { ExposedAssetsPanelComponent } from '../../../global_entities/components/entity-panels/exposed-assets.panel.component';
import { AssessmentJobEditPanelComponent } from '../network-scans/components/assessment-job-edit-panel.component';
import { InstalledAssetsPanelComponent } from '../../../global_entities/components/entity-panels/installed-assets.panel.component';
import { RecommendationContextPropertiesPanelComponent } from '../../../global_entities/components/entity-panels/recommendation-context-properties.panel.component';
import { Router } from '@angular/router';
import { RoutesService } from '@wcd/shared';
import { RecommendationContextKeyPanelComponent } from '../../../global_entities/components/entity-panels/recommendation-context-key.panel.component';
import { RelatedExceptionsPanelComponent } from '../../../global_entities/components/entity-panels/related-exceptions.panel.component';
import { MachineGroupDataviewPanelComponent } from '../../../global_entities/components/entity-panels/machine-group-dataview.panel.component';
import { I18nService } from '@wcd/i18n';
import { RecommendationWorkaroundsPanelComponent } from '../../../global_entities/components/entity-panels/recommendation-workarounds.panel.component';
import { DisputeCategoryPanelComponent } from '../../../global_entities/components/entity-panels/dispute-category.panel.component';
var SidePanelService = /** @class */ (function () {
    function SidePanelService(dialogsService, tvmTextsService, paris, router, routesService, i18nService) {
        this.dialogsService = dialogsService;
        this.tvmTextsService = tvmTextsService;
        this.paris = paris;
        this.router = router;
        this.routesService = routesService;
        this.i18nService = i18nService;
        this.commonPanelConfig = {
            isModal: true,
            showOverlay: false,
            hasCloseButton: true,
            type: PanelType.large,
            noBodyPadding: true,
            scrollBody: true,
            isBlocking: false,
        };
        this.securityRecommendationRelationshipMap = new Map([
            ['Assets', RecommendationExposedAssetsRelationship],
            ['Installations', RecommendationInstalledAssetsRelationship],
        ]);
    }
    SidePanelService.prototype.getEntitiesMapping = function (entity, panel) {
        if (entity instanceof AssetMissingKb) {
            if (panel === 'Vulnerabilities') {
                return {
                    title: entity.vendorBulletin,
                    relationshipRepository: AssetMissingKbVulnerabilityRelationship,
                };
            }
        }
        else if (entity instanceof SecurityRecommendation || entity instanceof AssetRecommendation) {
            var title = this.tvmTextsService.getText(TextToken.SecurityRecommendationTitle, entity);
            return {
                title: title,
                relationshipRepository: this.securityRecommendationRelationshipMap.get(panel) ||
                    RecommendationRelatedVulnerabilitiesRelationship,
            };
        }
        else if (entity instanceof Vulnerability) {
            if (panel === 'Assets') {
                return {
                    title: entity.id,
                    relationshipRepository: VulnerabilityExposedAssetsRelationship,
                };
            }
        }
        else if (entity instanceof MissingKb) {
            return {
                title: entity.vendorBulletin,
                relationshipRepository: panel === 'Assets'
                    ? MissingKbExposedAssetsRelationship
                    : MissingKbRelatedVulnerabilitiesRelationship,
            };
        }
        else if (entity instanceof ChangeEvent) {
            return {
                title: this.tvmTextsService.getText(TextToken.ChangeEventActivity, entity),
                relationshipRepository: panel === 'Assets' ? null : ChangeEventRelatedVulnerabilitiesRelationship,
            };
        }
        else if (entity instanceof NetworkDevice) {
            return {
                title: entity.ipAddress,
                relationshipRepository: NetworkDeviceRelatedVulnerabilitiesRelationship,
            };
        }
        else if (entity instanceof SoftwareVersion) {
            return {
                title: entity.version,
                relationshipRepository: SoftwareVersionInstalledAssetsRelationship,
            };
        }
    };
    SidePanelService.prototype.showAllExposedAssetsPanel = function (entity, isNetworkGear, recommendationContextType) {
        var _this = this;
        var exposedAssetsTitle = this.getEntitiesMapping(entity, 'Assets').title;
        this.dialogsService
            .showPanel(ExposedAssetsPanelComponent, __assign({ id: 'exposed-assets-panel', headerText: exposedAssetsTitle }, this.commonPanelConfig, { back: { onClick: function () { return _this._exposedAssetsPanel && _this._exposedAssetsPanel.destroy(); } } }), {
            sourceValue: entity,
            exposedAssetsTitle: exposedAssetsTitle,
            repository: this.getEntitiesMapping(entity, 'Assets').relationshipRepository,
            isNetworkGear: isNetworkGear,
            recommendationContextType: recommendationContextType,
        })
            .subscribe(function (panel) {
            _this._exposedAssetsPanel = panel;
            panel.onDestroy(function () {
                _this._exposedAssetsPanel = null;
            });
        });
    };
    SidePanelService.prototype.showAllInstalledAssetsPanel = function (entity) {
        var _this = this;
        var installedAssetsTitle = this.getEntitiesMapping(entity, 'Installations').title;
        this.dialogsService
            .showPanel(InstalledAssetsPanelComponent, __assign({ id: 'installed-assets-panel', headerText: installedAssetsTitle }, this.commonPanelConfig, { back: {
                onClick: function () { return _this._installedAssetsPanel && _this._installedAssetsPanel.destroy(); },
            } }), {
            installedAssetsTitle: installedAssetsTitle,
            sourceValue: entity,
            repository: this.getEntitiesMapping(entity, 'Installations').relationshipRepository,
        })
            .subscribe(function (panel) {
            _this._installedAssetsPanel = panel;
            panel.onDestroy(function () {
                _this._installedAssetsPanel = null;
            });
        });
    };
    SidePanelService.prototype.navigateToUrlInContext = function (url, queryParams) {
        if (queryParams === void 0) { queryParams = {}; }
        var mdatpFromSccUrl = this.routesService.getMdatpFromSccLinkConfig(url);
        var updatedUrl = mdatpFromSccUrl ? mdatpFromSccUrl.url : url;
        if (this.routesService.shouldOpenExternally(mdatpFromSccUrl)) {
            window.open(updatedUrl);
        }
        else {
            return this.router.navigate([updatedUrl], { queryParams: queryParams });
        }
    };
    SidePanelService.prototype.showAllRelatedCvesPanel = function (entity, includeExposedMachinesComponent, isNetworkGearCve) {
        var _this = this;
        if (includeExposedMachinesComponent === void 0) { includeExposedMachinesComponent = true; }
        var repository = this.paris.getRelationshipRepository(this.getEntitiesMapping(entity, 'Vulnerabilities').relationshipRepository);
        var relatedCvesTitle = this.getEntitiesMapping(entity, 'Vulnerabilities').title;
        repository.sourceItem = entity;
        this.dialogsService
            .showPanel(RelatedCvesPanelComponent, __assign({ id: 'related-cves-panel', headerText: relatedCvesTitle }, this.commonPanelConfig, { back: { onClick: function () { return _this._relatedCvesPanel && _this._relatedCvesPanel.destroy(); } } }), {
            sourceValue: entity,
            relatedCvesTitle: relatedCvesTitle,
            repository: repository,
            includeExposedMachinesComponent: includeExposedMachinesComponent,
            isNetworkGearCve: isNetworkGearCve,
        })
            .subscribe(function (panel) {
            _this._relatedCvesPanel = panel;
            panel.onDestroy(function () {
                _this._relatedCvesPanel = null;
            });
        });
    };
    SidePanelService.prototype.showAllRelatedExceptionsPanel = function (entity) {
        var _this = this;
        this.dialogsService
            .showPanel(RelatedExceptionsPanelComponent, __assign({ id: 'related-exceptions-panel' }, this.commonPanelConfig, { back: {
                onClick: function () { return _this._relatedExceptionsPanel && _this._relatedExceptionsPanel.destroy(); },
            } }), {
            sourceItem: entity,
        })
            .subscribe(function (panel) {
            _this._relatedExceptionsPanel = panel;
            panel.onDestroy(function () {
                _this._relatedExceptionsPanel = null;
            });
        });
    };
    SidePanelService.prototype.showRecommendationContextKeyAndExposedMachinesCountPanel = function (entity, recommendationContextType) {
        var _this = this;
        var recommendationCtxKeyTitle = this.getEntitiesMapping(entity, 'Assets').title;
        this.dialogsService
            .showPanel(RecommendationContextKeyPanelComponent, __assign({ id: 'recommendation-context-key-and-exposed-machines-panel', headerText: recommendationCtxKeyTitle }, this.commonPanelConfig, { back: {
                onClick: function () {
                    return _this._recommendationContextKeyAndExposedMachinesPanel &&
                        _this._recommendationContextKeyAndExposedMachinesPanel.destroy();
                },
            } }), {
            panelType: RecommendationContextKeyPanelType.EXPOSED_MACHINES_COUNT,
            securityRecommendation: entity,
            recommendationCtxKeyTitle: recommendationCtxKeyTitle,
            panelSubTitle: this.tvmTextsService.recommendationContextToExposedSectionTitle[recommendationContextType],
            repository: this.paris.getRelationshipRepository(RecommendationContextKeyAndExposedMachinesCountRelationship),
            recommendationContextType: recommendationContextType,
        })
            .subscribe(function (panel) {
            _this._recommendationContextKeyAndExposedMachinesPanel = panel;
            panel.onDestroy(function () {
                _this._recommendationContextKeyAndExposedMachinesPanel = null;
            });
        });
    };
    SidePanelService.prototype.showRecommendationContextKeyAndPropertiesPanel = function (entity, recommendationContextType) {
        var _this = this;
        var recommendationCtxKeyTitle = this.getEntitiesMapping(entity, 'Assets').title;
        this.dialogsService
            .showPanel(RecommendationContextKeyPanelComponent, __assign({ id: 'recommendation-context-key-and-properties-panel', headerText: recommendationCtxKeyTitle }, this.commonPanelConfig, { back: {
                onClick: function () {
                    return _this._recommendationContextKeyAndPropertiesPanel &&
                        _this._recommendationContextKeyAndPropertiesPanel.destroy();
                },
            } }), {
            panelType: RecommendationContextKeyPanelType.RECOMMENDATION_CONTEXT_PROPERTIES,
            recommendationCtxKeyTitle: recommendationCtxKeyTitle,
            panelSubTitle: this.tvmTextsService.recommendationContextToExposedSectionTitle[recommendationContextType],
            repository: this.paris.getRelationshipRepository(AssetRecommendationContextRelationship),
            recommendationContextType: recommendationContextType,
            securityRecommendation: entity,
        })
            .subscribe(function (panel) {
            _this._recommendationContextKeyAndPropertiesPanel = panel;
            panel.onDestroy(function () {
                _this._recommendationContextKeyAndPropertiesPanel = null;
            });
        });
    };
    SidePanelService.prototype.showRecommendationContextPropertiesPanel = function (securityRecommendation, recommendationContextKey, recommendationContextType) {
        var _this = this;
        this.dialogsService
            .showPanel(RecommendationContextPropertiesPanelComponent, __assign({ id: 'recommendation-context-properties-panel' }, this.commonPanelConfig, { back: {
                onClick: function () {
                    return _this._recommendationContextPropertiesPanel &&
                        _this._recommendationContextPropertiesPanel.destroy();
                },
            } }), {
            securityRecommendation: securityRecommendation,
            recommendationContextKey: recommendationContextKey,
            recommendationContextType: recommendationContextType,
        })
            .subscribe(function (panel) {
            _this._recommendationContextPropertiesPanel = panel;
            panel.onDestroy(function () {
                _this._recommendationContextPropertiesPanel = null;
            });
        });
    };
    SidePanelService.prototype.showNetworkAssessmentJobWizard = function (assessmentJob, readOnly) {
        this.dialogsService.showPanel(AssessmentJobEditPanelComponent, __assign({ id: 'network-scan-add-panel' }, this.commonPanelConfig, { isBlocking: true, showOverlay: true, disableOverlayClick: true, hasCloseButton: false, type: PanelType.wizard }), {
            assessmentJob: assessmentJob,
            readOnly: readOnly,
        });
    };
    //TODO: (by: jomok) look into making the doneCallBack (that sends the machine groups back to the caller) be passed to the panel itself, and let him handle it.
    SidePanelService.prototype.showRbacGroupsDataviewPanel = function (preselectedMachineGroups, manuallySelectedMachineGroups, hideMachineGroups, dataviewTitle, containsFilteredGroups, doneCallBack) {
        var _this = this;
        return this.dialogsService
            .showPanel(MachineGroupDataviewPanelComponent, __assign({ id: 'machine-group-dataview-panel' }, this.commonPanelConfig, { back: {
                onClick: function () {
                    return _this._machineGroupDataviewPanel && _this._machineGroupDataviewPanel.destroy();
                },
            } }), {
            preselectedMachineGroups: preselectedMachineGroups,
            manuallySelectedMachineGroups: manuallySelectedMachineGroups,
            hideMachineGroups: hideMachineGroups,
            dataviewTitle: dataviewTitle,
            containsFilteredGroups: containsFilteredGroups,
        })
            .subscribe(function (panel) {
            _this._machineGroupDataviewPanel = panel;
            panel.instance.onSaveGroups.subscribe(function (machineGroups) {
                _this._machineGroupDataviewPanel.instance.closePanel();
                doneCallBack(machineGroups);
            });
            panel.onDestroy(function () {
                _this._machineGroupDataviewPanel = null;
            });
        });
    };
    SidePanelService.prototype.showWorkaroundSidePanel = function (securityRecommendation, softwareName) {
        var _this = this;
        this.dialogsService
            .showPanel(RecommendationWorkaroundsPanelComponent, __assign({ id: 'recommendation-workarounds-panel' }, this.commonPanelConfig, { back: {
                onClick: function () { return _this._workaroundPanel && _this._workaroundPanel.destroy(); },
            }, hasCloseButton: false }), {
            securityRecommendation: securityRecommendation,
            programName: softwareName,
        })
            .subscribe(function (panel) {
            _this._workaroundPanel = panel;
            panel.onDestroy(function () {
                _this._workaroundPanel = null;
            });
        });
    };
    SidePanelService.prototype.showDisputeCategorySidePanel = function (domainName, categoryId, category) {
        var _this = this;
        this.dialogsService
            .showPanel(DisputeCategoryPanelComponent, __assign({ id: 'dispute-category-panel' }, this.commonPanelConfig, { hasCloseButton: true }), {
            domainName: domainName,
            categoryIdAsInput: categoryId,
            categoryNameAsInput: category
        })
            .subscribe(function (panel) {
            _this._disputeCategoryPanel = panel;
            panel.onDestroy(function () {
                _this._disputeCategoryPanel = null;
            });
        });
    };
    return SidePanelService;
}());
export { SidePanelService };
