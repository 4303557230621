<div class="large-form wcd-full-height">
	<form *ngIf="editedUserRole" #userRoleForm="ngForm" (keydown.enter)="$event.preventDefault()" (submit)="saveUserRole()" class="wcd-full-height wcd-flex-vertical" data-track-component="UserRoleEdit">
		<header class="wcd-flex-none wcd-padding-all">
			<tabs [tabsData]="tabs"
				  [currentTab]="currentTab.id"
				  (select)="currentTab = $event.tab"></tabs>
		</header>
		<div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-all">
			<fab-message-bar *ngIf="isUrbacEnabled"
				className="wcd-margin-bottom"
				[messageBarType]="MessageBarType.info"
				[isMultiline]="true"
				[truncated]="false"
				[dismissButtonAriaLabel]="'dismiss' | i18n"
			>
				{{ 'userRoles.urbacMessageBarText' | i18n }}
				<span *ngIf="isScc">
					{{ 'userRoles_urbacMessageBarRollbackText' | i18n}}
					<a  href="/settings/mtp_settings/mtp_roles">
						{{ 'userRoles.urbacMessageBarLink' | i18n }}
					</a>
				</span>
			</fab-message-bar>
			<div [hidden]="currentTab.id !== 'details'">
				<section class="wcd-margin-large-bottom">
					<div class="form-group wcd-input-placeholder">
						<label for="user-role-name">{{'userRoles_form_name' | i18n}}</label>
						<input type="text"
							   id="user-role-name"
							   [placeholder]="('userRoles.namePlaceholder' | i18n)"
							   [(ngModel)]="editedUserRole.name"
						       [disabled]="editedUserRole.isGlobalAdmin || isUrbacEnabled"
						       [rbac]="{ permissions: ['systemSettings'], state: 'disabled' }"
							   name="user-role-name"
							   class="form-control"
							   maxlength="100"
							   autofocus
							   required/>
					</div>
					<div class="form-group wcd-textarea-placeholder">
						<label for="user-role-description">{{'userRoles_form_description' | i18n}}</label>
						<textarea
							id="user-role-description"
							class="form-control wcd-full-width"
							[disabled]="editedUserRole.isGlobalAdmin || isUrbacEnabled"
							[placeholder]="('userRoles.descriptionPlaceholder' | i18n)"
							[rbac]="{ permissions: ['systemSettings'], state: 'disabled' }"
							name="description"
							maxlength="1000"
							rows="4"
							[(ngModel)]="editedUserRole.description"></textarea>
					</div>
				</section>
				<section class="wcd-margin-large-bottom">
					<h4>{{'settings_section_permissions' | i18n}}</h4>
					<user-role-permissions [allowedActionsModel]="editedUserRole.isGlobalAdmin ? displayedUserRoleActionsForGlobalAdmin : displayedUserRoleActions"
							   [isDisabled]="editedUserRole.isGlobalAdmin || isUrbacEnabled"
							   [rbac]="{ permissions: ['systemSettings'], state: 'disabled' }"
							   name="user-role-actions"
							   [isLiveResponseSelected] = "selectedLiveResponsePermission ? true : false"
							   [(ngModel)]="editedUserRole.isGlobalAdmin ? userRoleActionsRepository.entity.values : editedUserRole.allowedActions">
					</user-role-permissions>
					<div *ngIf="featuresService.isEnabled(liveResponseFeatureName)" class="wcd-flex-vertical wcd-margin-top">
						<wcd-checkbox
								[ngModel]="editedUserRole.isGlobalAdmin || selectedLiveResponsePermission"
								(ngModelChange)="selectedLiveResponsePermission = $event ? liveResponseUserRoleActions[0] : null"
								[isDisabled]="editedUserRole.isGlobalAdmin || isUrbacEnabled"
								[ngClass]="{ 'user-role-edit-live-response-checkbox-disabled': editedUserRole.isGlobalAdmin }"
								name="user-roles-live-response-action"
								[label]="'userRoles.permissions.liveResponse' | i18n"
								[rbac]="{ permissions: ['systemSettings'], state: 'disabled' }"
								[wcdTooltip]="'userRoles.permissions.liveResponse' | i18n">
						</wcd-checkbox>
						<wcd-radiolist
							[values]="liveResponseUserRoleActions"
							[(ngModel)]="editedUserRole.isGlobalAdmin ? liveResponseUserRoleActions[1]  : selectedLiveResponsePermission"
							[isDisabled]="!selectedLiveResponsePermission || editedUserRole.isGlobalAdmin || isUrbacEnabled"
							name="user-role-live-response-actions"
							labelFieldName="displayName"
							class="wcd-margin-medium-left"
							wcdTooltipAllowHtmlRendering="true"
							[rbac]="{ permissions: ['systemSettings'], state: 'disabled' }"
							[ariaLabel]="'userRoles.permissions.liveResponse' | i18n">
						</wcd-radiolist>
					</div>
				</section>
			</div>
			<div [hidden]="currentTab.id !== 'assignment'" class="wcd-full-height wcd-flex-vertical">
				<ng-container *ngIf="!RbacMachineGroupsForMtpEnabled; else RbacMachineGroupsForMtp">
					<p *ngIf="editedUserRole.isGlobalAdmin" class="form-section-description">
						<wcd-shared-icon iconName="Info" class="ms-icon-top-2-fix"> </wcd-shared-icon >
						{{'userRoles.globalAdminAccess' | i18n}}
					</p>
					<multi-select
						class="wcd-full-height wcd-flex-vertical"
						selectedDescription="userRoles.aadGroupsWithRole"
						[selected]="assignedAadGroups"
						[available]="availableAadGroups"
						[requiredAllowedActions]="['systemSettings']"
						[(chosenFromSelected)]="selectedAssignedAadGroups"
						[(chosenFromAvailable)]="selectedAadGroups"
						[canEdit]="authService.currentUser.hasMdeAllowedUserRoleAction(userRoleActionsService.userRoleActionsMap.get('systemSettings'))"
						[isDisabled]="isUrbacEnabled"
						[isLoadingAvailableItems]="isLoadingAadGroups"
						[hasError]="isAadGroupsError"
						removeButtonText="userRoles.removeSelectedGroups"
						addButtonText="userRoles.addSelectedGroups"
						filterPlaceholder="userRoles.filterUserGroups"
						noSelectedItemsPlaceholder="userRoles.emptyAssignments"
						notFoundText="userRoles.emptyAadGroups"
						notFoundForFilterText="userRoles.emptyAadGroupsForFilter"
						(onRemoveChosen)="removeSelectedGroups()"
						(onAddChosen)="addSelectedGroups()"
						(onSetAvailableItems)="setAvailableAadGroups($event)">
					</multi-select>
				</ng-container>
				<ng-template #RbacMachineGroupsForMtp>
					<aad-groups-assignment-tab
						class="wcd-full-height"
						[hostRbacComponentType]="RbacComponentType.UserRole"
						[isGlobalAdmin]="editedUserRole.isGlobalAdmin"
						[isDisabled]="isUrbacEnabled"
						[assignedAadGroups]="assignedAadGroups"
						(applySelectedAadGroups)="applySelectedAadGroups($event)">
					</aad-groups-assignment-tab>
				</ng-template>
			</div>
		</div>
		<div class="wcd-padding-all" *ngIf="isError">
			<div class="alert alert-error">Error saving role</div>
		</div>
		<div class="wcd-flex-none wcd-flex-horizontal wcd-padding-all">
			<div class="wcd-flex-1">
				<fab-default-button contentClass="btn-secondary wcd-width-xs-small"
						(onClick)="currentTab = currentTab === tabs[0] ? tabs[1] : tabs[0]"
						type="button">
					{{ 'buttons.' + ( currentTab === tabs[0] ? 'next' : 'previous') | i18n}}
				</fab-default-button>
				<fab-primary-button
						[rbac]="{ permissions: ['systemSettings'], state: 'hidden' }"
						[disabled]="userRoleForm.form.pristine || !userRoleForm.form.valid || isSaving || isUrbacEnabled"
						data-track-id="Save"
						data-track-type="Button"
						type="submit">
					<i  role="alert" class="loader-icon" [hidden]="!isSaving" [attr.aria-label]="'buttons.saving' | i18n"></i>
					{{ 'buttons.' + (isSaving ? 'saving' : 'save') | i18n }}
				</fab-primary-button>
			</div>
			<div class="wcd-flex-none">
				<fab-default-button contentClass="btn-secondary"
						data-track-id="Cancel"
						data-track-type="Button"
						(onClick)="cancel.emit()">
					{{'buttons.cancel' | i18n}}
				</fab-default-button>
			</div>
		</div>
	</form>
</div>
