import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@ApiCall({
	name: 'Update scheduled hunting rules',
	endpoint: 'hunting/rules',
	method: 'PATCH',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	parseQuery: (data: ScheduledHuntingUpdateStatusApiCallConfig) => {
		return {
			data: data,
		};
	},
})
export class ScheduledHuntingUpdateStatusApiCall extends ApiCallModel<
	ScheduledHuntingUpdateStatusApiCallConfig
> {}

@ApiCall({
	name: 'Update scheduled hunting rules',
	endpoint: 'rules/status',
	method: 'PATCH',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.huntingService,
	parseQuery: (data: ScheduledHuntingUpdateStatusApiCallConfig) => {
		return {
			data: data,
		};
	},
})
export class ScheduledHuntingUpdateStatusApiCallVNext extends ApiCallModel<
	ScheduledHuntingUpdateStatusApiCallConfig
	> {}

export interface ScheduledHuntingUpdateStatusApiCallConfig {
	ids: Array<number>;
	ruleContent: { isEnabled: boolean };
}
