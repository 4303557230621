import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@ApiCall({
	name: 'Submit registration request to managed threat hunting service',
	endpoint: 'SubmitBilbaoRegistration',
	method: 'POST',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.management,
})
export class BilbaoRegistrationApiCall extends ApiCallModel<void, BilbaoRegistration> {}

export interface BilbaoRegistration {
	email: string;
	name: string;
}
