var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BehaviorSubject, merge, of } from 'rxjs';
import { DataQuerySortDirection } from '@microsoft/paris';
import { catchError, finalize, map, switchMap, tap, take } from 'rxjs/operators';
import { cloneDeep, compact, escapeRegExp, isEqual, isNil, isObject, keyBy, sortBy } from 'lodash-es';
import { FiltersService } from '@wcd/ng-filters';
import { toPromise } from '../../utils/rxjs/utils';
import { CyberEventsUtilsService } from '../../@entities/cyber_events/services/cyber-events-utils.service';
var DEFAULT_PAGE_SIZE = 30;
export var AVAILABLE_PAGE_SIZES = [30, 50, 100];
var DataViewModel = /** @class */ (function () {
    function DataViewModel(config, originalConfig) {
        var _this = this;
        this.dataSet$ = new BehaviorSubject(null);
        this.error$ = new BehaviorSubject(null);
        this.page$ = new BehaviorSubject(1);
        this.pageSize$ = new BehaviorSubject(DEFAULT_PAGE_SIZE);
        this.isSearchOnly = false;
        this.searchTerm$ = new BehaviorSubject(null);
        this.sortDescending$ = new BehaviorSubject(false);
        this.sortField$ = new BehaviorSubject(null);
        this.isLoading$ = new BehaviorSubject(false);
        this.isLoadingNext$ = new BehaviorSubject(false);
        this.isLoadingPrevious$ = new BehaviorSubject(false);
        this.visibleFields$ = new BehaviorSubject([]);
        this.sortDescending = false;
        this.changeSettings$ = merge(this.page$, this.pageSize$, this.searchTerm$, this.sortDescending$, this.sortField$, this.visibleFields$);
        this._page = 1;
        this._reloadFilterValues = false;
        this.setFiltersData$ = new BehaviorSubject(null);
        this._config = config;
        this.searchParamName = config.searchParamName || 'search';
        if (!config.id)
            throw new Error("Can't create DataView, missing id");
        this.id = config.id;
        if (!config.fields)
            this.throwMissingProperty('fields');
        this._originalDataViewConfig = originalConfig || this._config;
        this.allowFilters = config.allowFilters !== false;
        this.allowPaging = config.allowPaging !== false;
        this.fields = cloneDeep(config.fields);
        this.defaultPageSize = config.defaultPageSize || DEFAULT_PAGE_SIZE;
        this._pageSize =
            config.pageSize && ~AVAILABLE_PAGE_SIZES.indexOf(config.pageSize)
                ? config.pageSize
                : this.defaultPageSize;
        if (this._pageSize !== DEFAULT_PAGE_SIZE)
            this.pageSize$.next(this._pageSize);
        this.isSearchOnly = config.searchOnly === true;
        this.defaultSortField = this.fields.find(function (field) { return field.id === config.defaultSortFieldId; });
        this.setSortField(this.defaultSortField);
        if (config.allowFilters !== false) {
            this.filterFields = this.fields
                .filter(function (field) { return !_this.disabledFilterFieldIds || !_this.disabledFilterFieldIds.has(field.id); })
                .map(function (field) { return (__assign({ id: field.id, name: field.filterName || field.name }, field.filter)); });
            if (this._config.requireFiltersData !== false) {
                if (!this._config.getFiltersData)
                    throw new Error("Can't set filters, 'getFiltersData' is missing from dataview config.");
                this.filtersData$ = this.setFiltersData$.pipe(tap(function () { return (_this.filtersError = null); }), switchMap(function () {
                    return _this._config.getFiltersData(__assign({}, _this.fixedFilterValues, (_this.filtersState && _this.filtersState.serialized)));
                }), catchError(function (error) {
                    throw new Error((_this.filtersError = error.error || error.message || error));
                }));
                this.setFiltersData$.next(null);
            }
            else
                this.filtersData$ = of({});
        }
        this._loadResults = config.loadResults;
        this._loadNextResults = config.loadNextResults;
        this._loadPreviousResults = config.loadPreviousResults;
        this._getLoadNextResultsUrl = config.getLoadNextResultsUrl;
        this._getLoadPreviousResultsUrl = config.getLoadPreviousResultsUrl;
        this._exportResults = config.exportResults;
        if (config.sortDisabledFieldIds) {
            this.sortDisabledFieldIds = new Set(config.sortDisabledFieldIds);
            this.fields.forEach(function (field) {
                if (_this.sortDisabledFieldIds.has(field.id))
                    field.sort.enabled = false;
            });
        }
        if (config.disabledVisibleFieldIds)
            this.disabledVisibleFieldIds = new Set(config.disabledVisibleFieldIds);
        if (this.fields.some(function (field) { return field.filterOnly; })) {
            if (!this.disabledVisibleFieldIds)
                this.disabledVisibleFieldIds = new Set();
            this.fields
                .filter(function (field) { return field.filterOnly; })
                .forEach(function (field) {
                _this.disabledVisibleFieldIds.add(field.id);
            });
        }
        this.dataTableFields = this.disabledVisibleFieldIds
            ? this.fields.filter(function (field) { return !_this.disabledVisibleFieldIds.has(field.id); })
            : this.fields;
        this.defaultVisibleFields = config.defaultVisibleFieldIds
            ? compact(config.defaultVisibleFieldIds.map(function (fieldId) { return _this.fieldsIndex[fieldId]; }))
            : this.fields;
        if (this.disabledVisibleFieldIds)
            this.defaultVisibleFields = this.defaultVisibleFields.filter(function (field) { return !_this.disabledVisibleFieldIds.has(field.id); });
        this.setVisibleFields(config.visibleFields || config.defaultVisibleFieldIds || config.fields.map(function (field) { return field.id; }));
        if (config.disabledFilterFieldIds) {
            this.disabledFilterFieldIds = new Set(compact(config.disabledFilterFieldIds.map(function (fieldId) { return _this.fieldsIndex[fieldId]; })).map(function (field) { return field.id; }));
        }
        this.fixedOptions = config.fixedOptions;
        this.fixedFilterValues = config.fixedFilterValues;
        this.infiniteScrolling = !!config.infiniteScrolling;
        this.forceGetDataFromApi = !!config.forceGetDataFromApi;
        if (this.isLocalData)
            this.sortableFieldIds = this.fields
                .filter(function (field) {
                return !field.sort || (field.sort.sortLocally !== false && field.sort.enabled !== false);
            })
                .map(function (field) { return field.id; });
    }
    Object.defineProperty(DataViewModel.prototype, "fieldsIndex", {
        get: function () {
            if (!this._fieldsIndex)
                this._fieldsIndex = keyBy(this.fields, 'id');
            return this._fieldsIndex;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataViewModel.prototype, "exportEnabled", {
        get: function () {
            return !!this._exportResults;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataViewModel.prototype, "currentPage", {
        get: function () {
            return this._page;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataViewModel.prototype, "isLocalData", {
        /**
         * `true` when all data for the dataview is in the front-end, and sorting / search are done locally, without backend fetches.
         * Requires that `allowPaging` is `false` and `forceGetDataFromApi` is `false` and `infiniteScrolling` isn't `true`.
         */
        get: function () {
            return this.allowPaging === false && this.forceGetDataFromApi === false && !this.infiniteScrolling;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataViewModel.prototype, "allDefaultFieldsSelected", {
        /**
         * 'true' when all default visible fields are checked.
         */
        get: function () {
            if (this._visibleFields.length === this.defaultVisibleFields.length) {
                var fieldsSetsMap_1 = this.defaultVisibleFields.reduce(function (result, field) {
                    var _a;
                    return (__assign({}, result, (_a = {}, _a[field.id] = false, _a)));
                }, {});
                this._visibleFields.forEach(function (field) { return (fieldsSetsMap_1[field.id] = true); });
                return Object.entries(fieldsSetsMap_1).reduce(function (result, item) { return (!result ? false : item[1]); }, true);
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    DataViewModel.prototype.throwMissingProperty = function (propertyName) {
        throw new Error("Can't create DataView " + this.id + ", missing " + propertyName + ".");
    };
    DataViewModel.prototype.destroy = function () {
        this._loadDataSetSubscription && this._loadDataSetSubscription.unsubscribe();
    };
    /**
     * Sets the sort field of the DataviewModel. Resets the page to 1. If the specified sortField is the same as the current one, the sortDirection is toggled.
     * @param sortField
     * @param sortDescending (optional) Can be explicitly specified to set the direction of the sort.
     */
    DataViewModel.prototype.setSortField = function (sortField, sortDescending, reloadData) {
        if (reloadData === void 0) { reloadData = false; }
        if (!sortField) {
            return this;
        }
        var isSameField = sortField && this._sortField && this._sortField.id === sortField.id;
        this._sortField = sortField;
        this.sortDescending = !isNil(sortDescending)
            ? sortDescending
            : isSameField
                ? !this.sortDescending
                : !isNil(this._sortField.sort && this._sortField.sort.sortDescendingByDefault)
                    ? this._sortField.sort.sortDescendingByDefault
                    : false;
        if (!isSameField) {
            this.sortField$.next(this._sortField);
        }
        this.sortDescending$.next(this.sortDescending);
        if (this._page !== 1)
            this.page$.next((this._page = 1));
        if (this.isLocalData) {
            if (this.dataSet$.value) {
                this.dataSet$.next(__assign({}, this.dataSet$.value, { items: this.sortItems(this.dataSet$.value.items, this._sortField, this.sortDescending) }));
            }
        }
        else if (reloadData)
            this.reloadData();
        return this;
    };
    /**
     * Sets the data locally, when `isLocalData` is `true` (and data shouldn't be reloaded from backend).
     */
    DataViewModel.prototype.setLocalData = function () {
        if (!this.lastDataSet)
            return;
        var results = this.localSearchResults(this.lastDataSet.items, this.freeTextFilter);
        results = this.sortItems(results, this._sortField, this.sortDescending);
        this.dataSet$.next(__assign({}, this.dataSet$.value, { items: results }));
    };
    DataViewModel.prototype.localSearchResults = function (items, searchTerm) {
        var _this = this;
        if (this._config.freeTextFilter) {
            return items.filter(function (item) { return _this._config.freeTextFilter(searchTerm, item); });
        }
        else {
            var termRegExp_1 = new RegExp(escapeRegExp(searchTerm), 'i');
            return items.filter(function (item) {
                return _this.fields.some(function (field) { return termRegExp_1.test(field.display(item)); });
            });
        }
    };
    /**
     * This is the local sorting method, for when isLocalData === true.
     * @param {DataviewField<TData>} sortField
     * @param {boolean} sortDescending
     */
    DataViewModel.prototype.sortItems = function (items, sortField, sortDescending) {
        if (!items) {
            return;
        }
        var sortCompareFunction = sortField.sort.getLocalSortValue ||
            (function (value) { return sortField.display(value).toString().toLowerCase(); });
        var sortedResults = sortField.sort.sortCompareFunction
            ? items.sort(sortField.sort.sortCompareFunction)
            : sortBy(items, sortCompareFunction);
        if (sortDescending) {
            return sortedResults.reverse();
        }
        return sortedResults;
    };
    /**
     * Sets the fields that should be visible in the dataview
     * @param fieldIds The Ids of the fields to display
     * @returns {boolean} true if the visible fields changed as a result of the setVisibleFields request, false otherwise
     */
    DataViewModel.prototype.setVisibleFields = function (fieldIds) {
        var _this = this;
        if (!fieldIds)
            throw new Error("Can't set visible fields, fieldsIds weren't specified.");
        if (this.disabledVisibleFieldIds)
            fieldIds = fieldIds.filter(function (fieldId) { return !_this.disabledVisibleFieldIds.has(fieldId); });
        var fieldIdsSet = new Set(fieldIds);
        var fields = this.fields.filter(function (field) { return field.alwaysDisplay || fieldIdsSet.has(field.id); });
        var fieldsChanged;
        if (!this._visibleFields)
            fieldsChanged = true;
        else {
            fieldsChanged = fields.length !== this._visibleFields.length;
            if (!fieldsChanged) {
                var fieldIds_1 = fields.map(function (field) { return field.id; }).sort(), currentVisibleFieldIds = this._visibleFields
                    .map(function (field) { return field.id; })
                    .sort();
                fieldsChanged = !isEqual(fieldIds_1, currentVisibleFieldIds);
            }
        }
        if (fieldsChanged)
            this.visibleFields$.next((this._visibleFields = fields));
        return fieldsChanged;
    };
    /**
     * Sets the visible fields to the default fields
     */
    DataViewModel.prototype.resetVisibleFields = function () {
        this.visibleFields$.next((this._visibleFields = this.defaultVisibleFields));
    };
    /**
     * Sets the current page
     * @param page {Number} positive integer
     */
    DataViewModel.prototype.setPage = function (page, updateData) {
        if (updateData === void 0) { updateData = true; }
        if (isNaN(page) || page < 1)
            throw new TypeError('Invalid page. Expected a positive integer, but got ' + page + '.');
        if (page !== this._page) {
            this.page$.next((this._page = page));
            if (updateData)
                this.reloadData();
            return true;
        }
        return false;
    };
    /**
     * Sets the current page size of the DataViewModel
     * @param pageSize {Number} positive integer
     */
    DataViewModel.prototype.setPageSize = function (pageSize, updateData) {
        if (updateData === void 0) { updateData = true; }
        if (isNaN(pageSize) || pageSize < 1)
            throw new TypeError('Invalid pageSize. Expected a positive integer, but got ' + pageSize + '.');
        if (pageSize !== this._pageSize) {
            this.page$.next((this._page = 1));
            this.pageSize$.next((this._pageSize = pageSize));
            if (updateData)
                this.reloadData();
            return true;
        }
        return false;
    };
    /**
     * Sets the filters of the DataOptions and resets the page to 1.
     * @param filters {FiltersModel}
     * @returns {DataViewModel}
     */
    DataViewModel.prototype.setFilters = function (filtersState) {
        if (this._page !== 1)
            this.page$.next((this._page = 1));
        this._newFiltersState = filtersState;
        this._reloadFilterValues = true;
        return this;
    };
    DataViewModel.prototype.setSearchTerm = function (searchTerm, updateData, setCurrentSearchTerm) {
        if (updateData === void 0) { updateData = false; }
        if (setCurrentSearchTerm === void 0) { setCurrentSearchTerm = false; }
        if (searchTerm === this.currentSearchTerm || (isNil(searchTerm) && isNil(this.currentSearchTerm)))
            return false;
        if (this.isLocalData) {
            this.setFreeTextFilter(searchTerm);
            return false;
        }
        else {
            var nextSearchTerm = void 0;
            if (setCurrentSearchTerm)
                nextSearchTerm = this.currentSearchTerm = searchTerm;
            else
                nextSearchTerm = this._newSearchTerm = searchTerm;
            this.searchTerm$.next(nextSearchTerm);
            if (updateData) {
                this.setFilters();
            }
        }
        return true;
    };
    DataViewModel.prototype.setFreeTextFilter = function (searchTerm) {
        this.freeTextFilter = searchTerm;
        this.setLocalData();
    };
    DataViewModel.prototype.reloadData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var options;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._loadDataSetSubscription && this._loadDataSetSubscription.unsubscribe();
                        return [4 /*yield*/, this.getDataSetOptions()];
                    case 1:
                        options = _a.sent();
                        if (this.isSearchOnly && !options.search) {
                            this.dataSet$.next(null);
                            return [2 /*return*/];
                        }
                        this.isLoading$.next(true);
                        this.error$.next(null);
                        this._loadDataSetSubscription = this._loadResults(options)
                            .pipe(tap(function () {
                            _this.isLoading$.next(false);
                            _this._loadDataSetSubscription = null;
                            if (_this._reloadFilterValues) {
                                _this._reloadFilterValues = false;
                                if (_this.allowFilters)
                                    _this.setFiltersData$.next(null);
                            }
                        }))
                            .subscribe(function (dataSet) {
                            _this.dataSet$.next((_this.lastDataSet = dataSet));
                        }, function (error) { return _this.error$.next(error); });
                        return [2 /*return*/];
                }
            });
        });
    };
    /*
     * Loads "next" batch of data. When user scrolls down to end of table, we get more data and concatenate it to bottom of table.
     */
    DataViewModel.prototype.loadNextData = function () {
        var _this = this;
        var url = this._getLoadNextResultsUrl();
        if (!url) {
            return;
        }
        this.isLoadingNext$.next(true);
        return this._loadNextResults(url)
            .pipe(finalize(function () { return _this.isLoadingNext$.next(false); }), map(function (dataSet) {
            _this.dataSet$.next(__assign({}, dataSet, { items: _this.dataSet$.value.items.concat(dataSet.items) }));
        }), take(1))
            .toPromise();
    };
    /*
     * Loads "previous" batch of data. When user clicks "Load newer data", we get more data and concatenate it to top of table.
     */
    DataViewModel.prototype.loadPreviousData = function () {
        var _this = this;
        var url = this._getLoadPreviousResultsUrl();
        if (url) {
            this.isLoadingPrevious$.next(true);
            return this._loadPreviousResults(url)
                .pipe(finalize(function () { return _this.isLoadingPrevious$.next(false); }), map(function (dataSet) {
                _this.dataSet$.next(__assign({}, dataSet, { items: dataSet.items.concat(_this.dataSet$.value.items) }));
            }), take(1))
                .toPromise();
        }
        else
            return Promise.reject();
    };
    DataViewModel.prototype.exportData = function (maxRowCount, format) {
        return __awaiter(this, void 0, void 0, function () {
            var options, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this._exportResults) return [3 /*break*/, 2];
                        _b = (_a = Object).assign;
                        return [4 /*yield*/, this.getDataSetOptions()];
                    case 1:
                        options = _b.apply(_a, [_c.sent(), {
                                page: 1,
                                page_size: maxRowCount,
                            }]);
                        return [2 /*return*/, this._exportResults(options, format, DataViewModel.getDataQueryFromOptions(this._originalDataViewConfig, options))];
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    DataViewModel.prototype.setOptions = function (options, allowReloadData) {
        if (allowReloadData === void 0) { allowReloadData = true; }
        var reloadData = false;
        reloadData = reloadData || this.setPage(options.page || 1, false);
        reloadData = reloadData || this.setPageSize(options.page_size || this.defaultPageSize, false);
        var visibleFieldIds = options.fields ? options.fields.split(',') : null;
        if (!visibleFieldIds) {
            var visibleFields = this.visibleFields$.value.length
                ? this.visibleFields$.value
                : this.defaultVisibleFields;
            visibleFieldIds = visibleFields.map(function (field) { return field.id; });
        }
        this.setVisibleFields(visibleFieldIds);
        var fieldAndDirection = options.ordering && options.ordering.match(/^(-?)(.+)$/);
        if (fieldAndDirection) {
            var sortField = this.fieldsIndex[fieldAndDirection[2]];
            var sortDescending = !!fieldAndDirection[1];
            if (sortField && sortField.sort && sortField.sort.flipSortDirection)
                sortDescending = !sortDescending;
            if (sortField && (sortField !== this._sortField || sortDescending !== this.sortDescending)) {
                this.setSortField(sortField, sortDescending);
                reloadData = true;
            }
        }
        else if (this.defaultSortField &&
            (this._sortField !== this.defaultSortField ||
                this.sortDescending !==
                    (this.defaultSortField.sort && this.defaultSortField.sort.sortDescendingByDefault))) {
            this.setSortField(this.defaultSortField, this.defaultSortField.sort && this.defaultSortField.sort.sortDescendingByDefault);
            reloadData = true;
        }
        if (this.allowFilters) {
            var serializedFilters = FiltersService.filtersQueryParamToSerializedFilters(options.filters);
            if ((serializedFilters && !this.filtersState) ||
                (this.filtersState && !isEqual(serializedFilters, this.filtersState.serialized))) {
                reloadData = true;
                this.filtersState = { selection: {}, serialized: serializedFilters };
            }
        }
        if (!this.isLocalData) {
            var searchChanged = this.setSearchTerm(options[this.searchParamName], false, true);
            reloadData = reloadData || searchChanged;
        }
        if (reloadData && allowReloadData) {
            this.reloadData();
        }
        return reloadData;
    };
    DataViewModel.prototype.setFields = function (fields) {
        this._fieldsIndex = null;
        if (fields && fields.length) {
            this.fields = fields;
            this.setSortField(this.defaultSortField || this.fields[0]);
            this.setVisibleFields(fields.map(function (field) { return field.id; }));
        }
    };
    DataViewModel.prototype.getDataSetOptions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var options, filterQueryOptions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        options = {};
                        if (!this.isLocalData) {
                            if (this._page > 1)
                                options.page = this._page;
                            options.page_size = this._pageSize;
                        }
                        if (this._sortField)
                            options.ordering =
                                ((this._sortField.sort && this._sortField.sort.flipSortDirection
                                    ? !this.sortDescending
                                    : this.sortDescending)
                                    ? '-'
                                    : '') + this._sortField.id;
                        if (!this.filtersState) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getFilterQueryOptions(this.filtersState.serialized)];
                    case 1:
                        filterQueryOptions = _a.sent();
                        Object.assign(options, filterQueryOptions);
                        _a.label = 2;
                    case 2:
                        if (this.currentSearchTerm)
                            options[this.searchParamName] = this.currentSearchTerm;
                        if (this.fixedOptions)
                            options = DataViewModel.mergeOptions(options, this.fixedOptions);
                        delete options.filters; // Cleaning up, this shouldn't be set, the individual filters should be in the options object.
                        return [2 /*return*/, options];
                }
            });
        });
    };
    /**
     * Once the options have been changed by the user, they are more explicit and include
     * options that might coincidentally be default. This method returns those options
     * as well.
     */
    DataViewModel.prototype.getNonDefaultDataSetOptions = function () {
        var _a;
        var visibleFieldIds = this._visibleFields.map(function (field) { return field.id; });
        var filtersState = this._newFiltersState || this.filtersState;
        var searchTerm = (isNil(this._newSearchTerm) ? this.currentSearchTerm : this._newSearchTerm) || null;
        // newFiltersState is single-use.
        this._newFiltersState = null;
        this._newSearchTerm = null;
        return _a = {
                page: !this.isLocalData && this._page > 1 ? this._page : null,
                page_size: !this.isLocalData ? this._pageSize : null,
                filters: FiltersService.getFiltersQueryParam(filtersState && filtersState.serialized)
            },
            _a[this.searchParamName] = searchTerm,
            _a.ordering = this.isLocalData
                ? null
                : this._sortField
                    ? "" + ((this._sortField.sort && this._sortField.sort.flipSortDirection
                        ? !this.sortDescending
                        : this.sortDescending)
                        ? '-'
                        : '') + this._sortField.id
                    : null,
            _a.fields = visibleFieldIds.join(','),
            _a;
    };
    DataViewModel.prototype.getFilterQueryOptions = function (serializedFilters) {
        if (this._config.getFilterQueryOptions) {
            var filterQueryOptions = this._config.getFilterQueryOptions(serializedFilters);
            return toPromise(filterQueryOptions);
        }
        return Promise.resolve(serializedFilters);
    };
    DataViewModel.mergeOptions = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var mergedOptions = {};
        args.forEach(function (options) {
            if (options && isObject(options)) {
                for (var optionName in options) {
                    if (!mergedOptions[optionName] || optionName !== 'filters')
                        mergedOptions[optionName] = options[optionName];
                }
            }
        });
        return mergedOptions;
    };
    DataViewModel.fromStore = function (store, options) {
        options = options || {};
        var fields = (store.options.dataViewOptions && store.options.dataViewOptions.fields) || options.fields;
        if (!fields)
            throw new Error("Can't create DataViewModel from store, missing fields.");
        var storeDataViewConfig = {
            id: DataViewModel.getDataViewId(store, null, options),
            allowPaging: options.allowPaging,
            allowFilters: options.allowFilters,
            defaultSortFieldId: (options && options.defaultSortFieldId) ||
                (store.options.dataViewOptions && store.options.dataViewOptions.sortField),
            defaultVisibleFieldIds: (store.options.dataViewOptions && store.options.dataViewOptions.visibleFields) ||
                (options && options.visibleFields),
            disabledVisibleFieldIds: options.disabledVisibleFieldIds,
            disabledFilterFieldIds: options.disabledFilterFieldIds,
            fields: fields,
            exportResults: store.options.dataViewOptions && store.options.dataViewOptions.exportEnabled
                ? store.exportAllResults.bind(store)
                : null,
            loadResults: store.getItemsDataSet.bind(store),
            fixedFilterValues: options.fixedOptions,
            fixedOptions: options.fixedOptions,
            pageSize: options.pageSize,
            visibleFields: options.visibleFields,
        };
        return new DataViewModel(storeDataViewConfig, options);
    };
    DataViewModel.fromRepository = function (repository, dataViewConfig, paris, featuresService, appContext) {
        var _this = this;
        var repositoryDataViewConfig = Object.assign({
            id: DataViewModel.getDataViewId(null, repository, dataViewConfig),
            loadResults: dataViewConfig.data
                ? function () { return of({ items: dataViewConfig.data }); }
                : function (options) {
                    var queryOptions = _this.getDataQueryFromOptions(dataViewConfig, options);
                    return repository.query(queryOptions);
                },
            defaultVisibleFieldIds: dataViewConfig.fields
                .filter(function (field) { return field.enabledByDefault !== false; })
                .map(function (field) { return field.id; }),
            loadNextResults: dataViewConfig.infiniteScrolling
                ? function (url) {
                    return paris.callQuery(repository.entityConstructor, {
                        endpoint: url,
                        baseUrl: repository.entityConstructor.entityConfig.baseUrl,
                        allItemsEndpointTrailingSlash: false,
                    }, CyberEventsUtilsService.shouldTimelineUseOneCyber(featuresService)
                        ? {
                            where: __assign({}, CyberEventsUtilsService.getTimelineFlagParams(featuresService, appContext)),
                        }
                        : null);
                }
                : null,
            loadPreviousResults: dataViewConfig.loadItemsOnTableTop
                ? function (url) {
                    return paris.callQuery(repository.entityConstructor, {
                        endpoint: url,
                        baseUrl: repository.entityConstructor.entityConfig.baseUrl,
                        allItemsEndpointTrailingSlash: false,
                    }, CyberEventsUtilsService.shouldTimelineUseOneCyber(featuresService)
                        ? {
                            where: __assign({}, CyberEventsUtilsService.getTimelineFlagParams(featuresService, appContext)),
                        }
                        : null);
                }
                : null,
        }, dataViewConfig);
        return new DataViewModel(repositoryDataViewConfig, dataViewConfig);
    };
    DataViewModel.getDataQueryFromOptions = function (dataViewConfig, options) {
        var pagingOptions = dataViewConfig !== false
            ? {
                page: options.page,
                pageSize: options.page_size,
            }
            : {};
        var queryOptions = Object.assign({
            where: Object.assign({}, options, dataViewConfig && dataViewConfig.fixedOptions),
        }, pagingOptions);
        if (options.ordering) {
            var sortMatch = options.ordering.match(/^(-)?(.+)$/);
            queryOptions.sortBy = [
                {
                    field: sortMatch[2],
                    direction: sortMatch[1]
                        ? DataQuerySortDirection.descending
                        : DataQuerySortDirection.ascending,
                },
            ];
        }
        return queryOptions;
    };
    DataViewModel.getDataViewId = function (store, repository, options) {
        if (!store && !repository && !options)
            return 'DEFAULT__dataview';
        return ((options && options.id) ||
            (store ? store.options.itemNamePlural : repository.modelConfig.pluralName) + "_dataview");
    };
    return DataViewModel;
}());
export { DataViewModel };
