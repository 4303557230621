export enum MdeUserRoleActionEnum {
	admin = -1,
	viewData = 1,
	alertsInvestigation = 2,
	remediationActions = 4,
	systemSettings = 8,
	securitySettings = 16,
	liveResponseBasic = 32,
	liveResponseAdvanced = 64,
	tvmViewData = 128,
	tvmRemediationHandling = 256,
	tvmExceptionHandling = 512,
	configurationManagement = 1024,
	tvmApplicationControl = 2048,
}
