import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { FeaturesService, Feature } from "@wcd/config";
import { OfficeIntegrationService } from '../../../admin/services/office-integration.service';
import { CyberEventMarkApiCall } from '@wcd/domain';
import { CsvService } from '../../../shared/services/csv.service';
import { ServiceUrlsService } from '@wcd/app-config';
import { Paris } from '@microsoft/paris';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CyberEventsActionTypesService } from './cyber-events-action-types.service';
import { I18nService } from '@wcd/i18n';
var EXPORT_TO_CSV_TOKEN_URL = 'GetAuthToken';
var EXPORT_TO_CSV_URL = 'exportEvents';
var CyberEventsUtilsService = /** @class */ (function () {
    function CyberEventsUtilsService(featuresService, officeIntegrationService, appInsightsService, csvService, serviceUrlsService, cyberEventsActionTypesService, i18nService, paris) {
        var _this = this;
        this.featuresService = featuresService;
        this.officeIntegrationService = officeIntegrationService;
        this.appInsightsService = appInsightsService;
        this.csvService = csvService;
        this.serviceUrlsService = serviceUrlsService;
        this.cyberEventsActionTypesService = cyberEventsActionTypesService;
        this.i18nService = i18nService;
        this.paris = paris;
        this.markEventOperations$ = new ReplaySubject();
        if (this.featuresService.isEnabled(Feature.OfficeAtpIntegration)) {
            this.officeIntegrationService.getOfficeTenantUrlPrefix().subscribe(function (tenantPrefix) {
                _this.officeTenantPrefix = tenantPrefix;
            });
        }
    }
    Object.defineProperty(CyberEventsUtilsService.prototype, "apiUrl", {
        get: function () {
            return this.serviceUrlsService.threatIntel;
        },
        enumerable: true,
        configurable: true
    });
    CyberEventsUtilsService.prototype.createParamsForGetEvent = function (event, options) {
        var officeParams = this.officeTenantPrefix ? { officeTenantPrefix: this.officeTenantPrefix } : {};
        if (this.featuresService.isEnabled(Feature.OfficeAtpIntegration) &&
            this.officeTenantPrefix === undefined) {
            this.appInsightsService.trackEvent('UI', {
                id: 'OfficeIntegrationOn_noOfficeParams',
                eventId: event.id,
            });
        }
        return Object.assign({}, event, options, officeParams);
    };
    CyberEventsUtilsService.prototype.downloadCsv = function (options) {
        if (this.featuresService.isEnabled(Feature.ExportTimelineUsingCyber) && options.entityType == 'machines') {
            return this.csvService.downloadCsv({
                tokenUrl: null,
                apiUrl: this.serviceUrlsService.cyberData + "/" + options.entityType + "/" + options.entityId + "/" + EXPORT_TO_CSV_URL + "/",
                params: options
            }, true);
        }
        else {
            var tokenUrl = options.entityType === 'urls' ?
                this.apiUrl + "/" + EXPORT_TO_CSV_TOKEN_URL + "?exportUrl=" + options.entityType + "/" + encodeURIComponent(options.entityId) + "/" + EXPORT_TO_CSV_URL + "/" :
                this.apiUrl + "/" + EXPORT_TO_CSV_TOKEN_URL + "?exportUrl=" + options.entityType + "/" + options.entityId + "/" + EXPORT_TO_CSV_URL + "/";
            return this.csvService.downloadCsv({
                tokenUrl: tokenUrl,
                apiUrl: this.apiUrl + "/" + options.entityType + "/" + options.entityId + "/" + EXPORT_TO_CSV_URL + "/",
                params: options,
            });
        }
    };
    CyberEventsUtilsService.prototype.markEvent = function (event, isMarked) {
        var _this = this;
        this.updateOnMarkEventOperation(event, isMarked);
        return this.paris
            .apiCall(CyberEventMarkApiCall, {
            cyberEvent: event,
            isMarked: isMarked,
        })
            .pipe(tap({
            error: function (_) { return _this.updateOnMarkEventOperation(event, !isMarked); }
        }));
    };
    CyberEventsUtilsService.prototype.getMarkEventOperationObservable = function () {
        return this.markEventOperations$;
    };
    CyberEventsUtilsService.prototype.trackCyberEventPanelEvent = function (id, type, events) {
        var _this = this;
        if (events && events.length) {
            events.forEach(function (event) {
                var properties = CyberEventsUtilsService.getCyberEventPanelTrackProperties(id, type, event);
                _this.appInsightsService.track(properties);
            });
        }
    };
    CyberEventsUtilsService.getCyberEventPanelTrackProperties = function (value, type, event) {
        var properties = {
            component: 'CyberEventEntityPanel',
            componentType: 'Side Panel',
            id: 'CyberEventPanelTrackEvent',
            type: type,
            value: value,
            correlationId: this.correlationId$.getValue(),
            deviceId: event && event.machine && event.machine.id,
            eventType: event && event.rawActionType,
            reportId: event && event.reportId,
            dataType: event && event.cyberActionType && event.cyberActionType.dataType,
            relatedObservationName: event && event.relatedObservationName
        };
        return properties;
    };
    CyberEventsUtilsService.prototype.updateOnMarkEventOperation = function (event, isMarked) {
        this.markEventOperations$.next({
            actionTime: event.actionTime,
            actionType: this.cyberEventsActionTypesService.getActionType(event),
            deviceId: event.machine && event.machine.id,
            flagTime: new Date(),
            isFlagged: isMarked,
            alertId: event.relatedAlert && event.relatedAlert.id,
            actionTimeIsoString: event.actionTimeIsoString,
            reportId: event.reportId,
        });
    };
    CyberEventsUtilsService.shouldTimelineUseOneCyber = function (featuresService) {
        return featuresService.isEnabled(Feature.NewTimeLineData) || featuresService.isEnabled(Feature.MachineTimelineFromCyber);
    };
    CyberEventsUtilsService.shouldTimeUseDataCompleteness = function (featuresService) {
        return featuresService.isEnabled(Feature.NewTimeLineData) || featuresService.isEnabled(Feature.MachineTimelineDataCompleteness);
    };
    CyberEventsUtilsService.shouldPrefetchTimeline = function (featuresService) {
        return featuresService.isEnabled(Feature.NewTimeLineData) || featuresService.isEnabled(Feature.MachineTimelineAlwaysPrefetch);
    };
    CyberEventsUtilsService.shouldWarmUpResponse = function (featuresService) {
        return featuresService.isEnabled(Feature.MachineTimelineWarmUpResponse);
    };
    CyberEventsUtilsService.getTimelineFlagParams = function (featuresService, appContext) {
        var params = { correlationId: this.correlationId$.getValue() };
        if (this.shouldTimelineUseOneCyber(featuresService))
            params['useCyberData'] = true;
        if (this.shouldTimeUseDataCompleteness(featuresService))
            params['useDataCompleteness'] = true;
        if (featuresService.isEnabled(Feature.MachineTimelineDoNotUseCache))
            params['doNotUseCache'] = true;
        if (!appContext.isAadIpActive) {
            return params;
        }
        if (featuresService.isEnabled(Feature.MachineTimelineGenerateMdiEvents))
            params['generateIdentityEvents'] = true;
        if (featuresService.isEnabled(Feature.MachineTimelineIncludeMdiEvents))
            params['includeIdentityEvents'] = true;
        return params;
    };
    CyberEventsUtilsService.correlationId$ = new BehaviorSubject('');
    return CyberEventsUtilsService;
}());
export { CyberEventsUtilsService };
var TrackingCyberEventProperties = /** @class */ (function () {
    function TrackingCyberEventProperties() {
    }
    return TrackingCyberEventProperties;
}());
