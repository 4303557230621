export enum SuppressionRuleConditionIdType {
	FileName = 0,
	FolderPath = 1,
	Sha1 = 2,
	Ip = 3,
	Url = 4,
	CommandLine = 5,
	SenseMachineId = 6,
	UserSid = 7,
	RbacGroupId = 8
}
