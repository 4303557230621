var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
import { VulnerabilityChangeEventType, SeverityValue, MachineGroup } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { MachinesService } from '../../../../machines/services/machines.service';
import { of, combineLatest } from 'rxjs';
import { tap, mergeMap } from 'rxjs/operators';
import { AuthService } from '@wcd/auth';
import { TvmNotificationsTextsService } from '../../../../../tvm/services/tvm-notifications-texts.service';
import { AppConfigService } from '@wcd/app-config';
import { FlavorService } from '@wcd/config';
import { AppFlavorConfig } from '@wcd/scc-common';
var SELECTABLE_MACHINE_GROUP_SCOPE_IDS;
(function (SELECTABLE_MACHINE_GROUP_SCOPE_IDS) {
    SELECTABLE_MACHINE_GROUP_SCOPE_IDS["all"] = "all";
    SELECTABLE_MACHINE_GROUP_SCOPE_IDS["specific"] = "specific";
})(SELECTABLE_MACHINE_GROUP_SCOPE_IDS || (SELECTABLE_MACHINE_GROUP_SCOPE_IDS = {}));
var VulnerabilityEmailNotificationSettingsStepComponent = /** @class */ (function (_super) {
    __extends(VulnerabilityEmailNotificationSettingsStepComponent, _super);
    function VulnerabilityEmailNotificationSettingsStepComponent(i18n, machinesService, changeDetectorRef, authService, paris, tvmNotificationsTextsService, appConfigService, flavorService) {
        var _this = _super.call(this) || this;
        _this.i18n = i18n;
        _this.machinesService = machinesService;
        _this.changeDetectorRef = changeDetectorRef;
        _this.authService = authService;
        _this.severityNote = '';
        _this.loadingMachineGroups = true;
        _this.currentMachineGroups = [];
        _this.availableMachineGroups = [];
        _this.changeEventTypes = [VulnerabilityChangeEventType.NewCve,
            VulnerabilityChangeEventType.CveHasVerifiedExploit,
            VulnerabilityChangeEventType.CveHasPubliclyDisclosedExploit,
            VulnerabilityChangeEventType.CveHasExploitInKit];
        _this.eventTypesCount = 0;
        _this.tenantHasMachineGroups = appConfigService.hasMachineGroups;
        _this.isDeviceScopeEnabled = flavorService.isEnabled(AppFlavorConfig.settings.deviceGroups);
        _this.severityLevels = Object.values(SeverityValue).map(function (severityLevel) { return ({
            id: severityLevel,
            name: _this.i18n.get('tvm_notifications_vulnerability_' + severityLevel),
        }); });
        _this.selectableMachineGroupScopes = Object.values(SELECTABLE_MACHINE_GROUP_SCOPE_IDS).map(function (scopeId) { return ({
            id: scopeId,
            name: _this.i18n.get('email_notification_wizard_deviceGroupScopeOption_' + scopeId),
        }); });
        _this.eventTypeLabels = tvmNotificationsTextsService.getChangeEventTypesLabels(_this.changeEventTypes);
        _this.machineGroupsRepo = paris.getRepository(MachineGroup);
        return _this;
    }
    VulnerabilityEmailNotificationSettingsStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initializeSeverityInformation();
        this.eventTypesCount = this.changeEventTypes.filter(function (e) { return _this.data.eventTypes[e]; }).length;
        this.setMachineGroups().subscribe(function () {
            _this.setCurrentMachineGroupsScope();
            _this.setCurrentMachineGroups(_this.data.machineGroups);
            _this.checkValidation();
        });
    };
    VulnerabilityEmailNotificationSettingsStepComponent.prototype.setMachineGroups = function () {
        var _this = this;
        return this.machineGroupsRepo.allItems$.pipe(tap(function (groups) {
            _this.loadingMachineGroups = false;
            _this.allMachineGroups = groups;
            _this.setAvailableScopes();
        }), mergeMap(function () { return _this.getUserExposedRbacGroups(); }));
    };
    VulnerabilityEmailNotificationSettingsStepComponent.prototype.onMachineGroupsChange = function (selectedMachineGroups) {
        var _this = this;
        if (!(selectedMachineGroups && selectedMachineGroups.length))
            this.setCurrentMachineGroups((this.data.machineGroups = []));
        else {
            of(selectedMachineGroups)
                .pipe(mergeMap(function (_selectedMachineGroups) {
                return combineLatest(_selectedMachineGroups.map(function (selectedGroup) {
                    return _this.machineGroupsRepo.getItemById(selectedGroup.id);
                }));
            }))
                .subscribe(function (machineGroups) {
                _this.setCurrentMachineGroups((_this.data.machineGroups = machineGroups));
            });
        }
    };
    VulnerabilityEmailNotificationSettingsStepComponent.prototype.onMachineGroupScopeChange = function (selectedScope) {
        var _this = this;
        this.data.allRbacGroups = selectedScope.id === 'all';
        this.checkValidation();
        setTimeout(function () { return _this.changeDetectorRef.detectChanges(); }, 0);
    };
    VulnerabilityEmailNotificationSettingsStepComponent.prototype.setAvailableScopes = function () {
        if (this.allMachineGroups.length) {
            this.allowSpecificMachineGroups = true;
            if (this.authService.currentUser.isMdeAdmin) {
                this.allowAllMachineGroups = true;
            }
        }
        else {
            this.data.allRbacGroups = true;
        }
    };
    VulnerabilityEmailNotificationSettingsStepComponent.prototype.getUserExposedRbacGroups = function () {
        var _this = this;
        return this.machinesService.getFullUserExposedMachineGroups().pipe(tap(function (userExposedMachineGroups) {
            _this.availableMachineGroups = userExposedMachineGroups.map(function (machineGroup) {
                return _this.getMachineGroupCheckListValue(machineGroup);
            });
        }));
    };
    VulnerabilityEmailNotificationSettingsStepComponent.prototype.setCurrentMachineGroupsScope = function () {
        if (this.data.allRbacGroups) {
            this.currentMachineGroupScope = this.selectableMachineGroupScopes.find(function (scope) { return scope.id === SELECTABLE_MACHINE_GROUP_SCOPE_IDS.all; });
        }
        else if (this.data.machineGroups && this.data.machineGroups.length) {
            this.currentMachineGroupScope = this.selectableMachineGroupScopes.find(function (scope) { return scope.id === SELECTABLE_MACHINE_GROUP_SCOPE_IDS.specific; });
        }
    };
    VulnerabilityEmailNotificationSettingsStepComponent.prototype.setCurrentMachineGroups = function (machineGroups) {
        var _this = this;
        this.currentMachineGroups = machineGroups.map(function (machineGroup) {
            return _this.getMachineGroupCheckListValue(machineGroup);
        });
        this.updateMachineGroupLabel(machineGroups);
        this.checkValidation();
        setTimeout(function () { return _this.changeDetectorRef.detectChanges(); }, 0);
    };
    VulnerabilityEmailNotificationSettingsStepComponent.prototype.updateMachineGroupLabel = function (machineGroups) {
        if (machineGroups.length > 3) {
            this.machineGroupLabelText = this.i18n.get('notificationRules_editRule_manyValues', {
                length: machineGroups.length,
            });
        }
        else if (machineGroups.length > 0) {
            this.machineGroupLabelText = this.currentMachineGroups.map(function (machine) { return machine.name; }).join(', ');
        }
        else {
            this.machineGroupLabelText = this.i18n.strings.notificationRules_machineGroups_selectValues;
        }
    };
    VulnerabilityEmailNotificationSettingsStepComponent.prototype.getMachineGroupCheckListValue = function (machineGroup) {
        return {
            id: machineGroup.id,
            name: machineGroup.isUnassignedMachineGroup
                ? this.i18n.strings.machineGroup_unassignedGroup_name
                : machineGroup.name,
        };
    };
    VulnerabilityEmailNotificationSettingsStepComponent.prototype.initializeSeverityInformation = function () {
        var defaultSeverity = this.data.severityLevelForNewCve || SeverityValue.Critical;
        this.currentSeverity = this.severityLevels.find(function (severity) { return severity.id === defaultSeverity; });
        this.shouldShowSeveritySelection = !!this.data.severityLevelForNewCve;
        this.shouldShowSeverityCheckBox = this.data.eventTypes[VulnerabilityChangeEventType.NewCve];
        this.setSeverityNote();
    };
    VulnerabilityEmailNotificationSettingsStepComponent.prototype.onEventTypeChange = function (eventType, checked) {
        if (checked) {
            this.eventTypesCount++;
            if (eventType == VulnerabilityChangeEventType.NewCve) {
                this.shouldShowSeverityCheckBox = true;
                this.updateSeverityLevel();
            }
        }
        else {
            this.eventTypesCount--;
            if (eventType == VulnerabilityChangeEventType.NewCve) {
                this.shouldShowSeverityCheckBox = false;
                this.data.severityLevelForNewCve = undefined;
            }
        }
        this.checkValidation();
        this.changeDetectorRef.detectChanges();
    };
    VulnerabilityEmailNotificationSettingsStepComponent.prototype.onSeverityCheckboxSelection = function () {
        this.updateSeverityLevel();
    };
    VulnerabilityEmailNotificationSettingsStepComponent.prototype.updateSeverityLevel = function () {
        this.data.severityLevelForNewCve = this.shouldShowSeveritySelection
            ? this.currentSeverity.id
            : undefined;
        this.setSeverityNote();
        this.changeDetectorRef.detectChanges();
    };
    VulnerabilityEmailNotificationSettingsStepComponent.prototype.setSeverityNote = function () {
        this.severityNote = this.i18n.get("vulnerability_notifications_severity_threshold_note_" + this.currentSeverity.id);
    };
    VulnerabilityEmailNotificationSettingsStepComponent.prototype.checkValidation = function () {
        this.setStepValidation(this.eventTypesCount > 0 && (!this.tenantHasMachineGroups || this.data.machineGroups.length > 0 || this.data.allRbacGroups));
    };
    VulnerabilityEmailNotificationSettingsStepComponent.prototype.onModelChange = function () {
        this.changeDetectorRef.detectChanges();
    };
    return VulnerabilityEmailNotificationSettingsStepComponent;
}(WizardBaseStep));
export { VulnerabilityEmailNotificationSettingsStepComponent };
