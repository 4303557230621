<ng-container *ngIf="(data$ | async) as data" >
	<div class="wcd-m365-typography wcd-full-height"
		 *ngIf="data.virusTotalReport?.total || data.showFileVerdict; else noData">
		<h4>
			<div class="wcd-flex-horizontal">
				<div class="wcd-flex-1">
					{{'file.reputation.virusTotal' | i18n}}
				</div>
				<small *ngIf="data.virusTotalReport?.total" class="wcd-flex-none wcd-margin-left">
					<strong>{{data.virusTotalReport.positives}}</strong>/{{data.virusTotalReport.total}}
				</small>
			</div>
		</h4>
		<div class="wcd-margin-small-bottom">
			<ng-container *ngIf="data.virusTotalReport?.total; else noVtData">
				<wcd-bar width="100%" [fillRatio]="data.virusTotalReport.positives / data.virusTotalReport.total"></wcd-bar>
				<a *ngIf="data.virusTotalReport.permalink" [href]="data.virusTotalReport.permalink" target="_blank">
					{{ 'file.reputation.viewInVirusTotal' | i18n }}
				</a>
			</ng-container>
			<ng-template #noVtData>
				<span class="wcd-font-size-s color-text-gray-700 wcd-font-weight-bold">
					{{ 'common.noDataAvailable' | i18n }}
				</span>
			</ng-template>
		</div>
	</div>

	<ng-template #noData>
		<h4>{{ 'common.noDataAvailable' | i18n }}</h4>
	</ng-template>
</ng-container>
