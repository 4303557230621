import {
	Component,
	ViewChild,
	ChangeDetectionStrategy,
	OnInit,
	OnDestroy,
	ChangeDetectorRef,
} from '@angular/core';
import { SpinnerSize } from 'office-ui-fabric-react';
import { AssessmentJob, TvmEndPoint, MdeUserRoleActionEnum } from '@wcd/domain';
import { DataViewConfig, DataviewField } from '@wcd/dataview';
import { TitleService } from '../../../../shared/services/title.service';
import { EntityType, EntityDataViewOptions } from '../../../../global_entities/models/entity-type.interface';
import { GlobalEntityTypesService } from '../../../../global_entities/services/global-entity-types.service';
import { DataViewComponent } from '../../../../dataviews/components/dataview.component';
import { AssessmentJobFieldsService } from '../services/assessment-job.fields.service';
import { SidePanelService } from '../../common/side-panel.service';
import { ItemActionModel } from '../../../../dataviews/models/item-action.model';
import { I18nService } from '@wcd/i18n';
import { TvmDownloadService } from '../../../../tvm/services/tvm-download.service';
import { AssessmentJobService } from '../services/assessment-job.service';
import { Repository } from '@microsoft/paris';
import { Router } from '@angular/router';
import { DataviewActionButtonConfig } from '../../../../dataviews/components/actions-components/dataview-action-button.component';
import { DataviewActionTypes } from '../../../../dataviews/components/actions-components/dataview-actions.model';
import { FabricIconNames } from '@wcd/scc-common';
import { sccHostService } from '@wcd/scc-interface';
import { AuthService } from '@wcd/auth';
import { AdvancedFeaturesService } from '../../../../admin/integration-settings/advanced-features.service';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MessageBarType } from 'office-ui-fabric-react';
import { PerformanceSccService } from '../../../../insights/services/performance.scc.service';
import { AppConfigService } from '@wcd/app-config';

@Component({
	selector: 'network-scans-dataview',
	templateUrl: './network-scans.dataview.component.html',
	styleUrls: ['./network-scans.dataview.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NetworkScansDataviewComponent implements OnDestroy, OnInit {
	@ViewChild(DataViewComponent, { static: false }) dataViewComponent: DataViewComponent;

	readonly SpinnerSize = SpinnerSize;

	private subscription: Subscription;

	repository: Repository<AssessmentJob>;
	entityType: EntityType<AssessmentJob>;
	fields: Array<DataviewField<AssessmentJob>>;
	itemActions: Array<ItemActionModel>;
	dataViewConfig: DataViewConfig;
	isScc = sccHostService.isSCC;
	dataViewOptions: EntityDataViewOptions<AssessmentJob, {}>;
	MessageBarType = MessageBarType;
	magellanOptOut: boolean;
	loaded: boolean;
	readOnlyMode: boolean;

	customButtonConfig: DataviewActionButtonConfig[] = [];

	boundSetItemActions: (assessmentJob: Array<AssessmentJob>) => Array<ItemActionModel>;

	constructor(
		private i18nService: I18nService,
		private tvmDownloadService: TvmDownloadService,
		private assessmentJobService: AssessmentJobService,
		private globalEntityTypesService: GlobalEntityTypesService,
		private router: Router,
		private authService: AuthService,
		private advancedFeaturesService: AdvancedFeaturesService,
		private changeDetectorRef: ChangeDetectorRef,
		public fieldsService: AssessmentJobFieldsService,
		public titleService: TitleService,
		public sidePanelService: SidePanelService,
		private performanceSccService: PerformanceSccService,
		private appConfigService: AppConfigService
	) {
		this.repository = this.assessmentJobService.getRepository();
		this.fields = this.fieldsService.fields;
	}

	ngOnInit() {
		this.magellanOptOut = this.appConfigService.magellanOptOut;
		this.setDataViewConfig(this.magellanOptOut);
	}

	setDataViewConfig(magellanOptOut: boolean) {
		const userHavePermissions = this.authService.currentUser.hasMdeAllowedUserRoleAction(
			MdeUserRoleActionEnum.securitySettings
		);
		this.readOnlyMode = magellanOptOut || !userHavePermissions;
		if (!this.readOnlyMode) {
			this.itemActions = [
				{
					id: 'delete',
					name: this.i18nService.get('delete'),
					icon: 'delete',
					tooltip: this.i18nService.get('deleteSelectedItems', {
						itemsName: this.repository.entity.pluralName,
					}),
					method: (items: Array<AssessmentJob>) => this.deleteItems(items),
					refreshOnResolve: true,
				},
			].map((itemActionConfig) => new ItemActionModel(itemActionConfig));

			this.customButtonConfig = [
				{
					localizedTooltip: this.i18nService.get('tvm_networkGear_dataview_downloadAgent'),
					onClickCallback: () => this.downloadAgentInstallation(),
					icon: FabricIconNames.Download,
					localizedLabel: this.i18nService.get('tvm_networkGear_dataview_downloadAgent'),
					elementId: 'download-agent-btn',
					actionType: DataviewActionTypes.Button,
				},
				{
					localizedTooltip: this.i18nService.get(
						'tvm_networkGear_dataview_addNetworkAssessmentJob'
					),
					onClickCallback: () => this.addOrEditNetworkAssessmentJob(),
					icon: FabricIconNames.Add,
					localizedLabel: this.i18nService.get('tvm_networkGear_dataview_addNetworkAssessmentJob'),
					elementId: 'add-network-assessment-job-btn',
					actionType: DataviewActionTypes.Button,
				},
			];
		}

		this.boundSetItemActions = this.setItemActions.bind(this);

		this.entityType = this.globalEntityTypesService.getEntityType(AssessmentJob);
		this.dataViewOptions = {
			...this.entityType.dataViewOptions,
		};

		this.dataViewConfig = {
			showModalOnExport: this.dataViewOptions.exportOptions.showModalOnExport,
			exportResults: (options, format, dataQuery) =>
				this.dataViewOptions.exportOptions.exportResults(options, null, null, dataQuery),
		};
		this.loaded = true;
		this.changeDetectorRef.markForCheck();
	}

	ngOnDestroy() {
		this.subscription && this.subscription.unsubscribe();
	}

	setItemActions(assessmentJobs: Array<AssessmentJob>): Array<ItemActionModel> {
		return !assessmentJobs || !assessmentJobs.length ? [] : this.itemActions;
	}

	deleteItems(items: Array<AssessmentJob>): Promise<Array<AssessmentJob>> {
		return this.assessmentJobService.deleteItems(items);
	}

	downloadAgentInstallation() {
		const url = `${TvmEndPoint.NetworkScans}/agents/setup`;
		this.tvmDownloadService.downloadFile({ url: url, fileName: 'MdatpScanAgentSetup.msi' });
	}

	navigateToDeviceInventory(): void {
		this.router.navigateByUrl('machines?category=network-devices');
	}

	addOrEditNetworkAssessmentJob(assessmentJob?: AssessmentJob) {
		if (assessmentJob) {
			this.sidePanelService.showNetworkAssessmentJobWizard(assessmentJob, this.readOnlyMode);
		} else {
			const newAssessmentJob = new AssessmentJob();
			newAssessmentJob.active = true;
			this.sidePanelService.showNetworkAssessmentJobWizard(newAssessmentJob, this.readOnlyMode);
		}
	}

	onTableRenderComplete() {
		this.performanceSccService.endNgPageLoadPerfSession('network-scans-dataview');
	}
}
