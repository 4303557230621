
		<modal
			(close)="cancel(); destroy()"
			[settings]="settings"
			ariaLabel="{{ options.ariaLabel || options.title }}"
			data-track-component="ConfirmModal"
			cdkTrapFocus
		>
			<form class="wcd-full-height wcd-flex-vertical" (submit)="confirmAndClose()">
				<div class="wcd-flex-1 wcd-flex-vertical dialog-contents ie11Patch ie11Flex">
					<header class="wcd-flex-none">
						<h1 [ngClass]="options.titleCssClass" class="wcd-text-overflow-ellipsis">
							<span class="dialog-image" *ngIf="options.icon" [ngClass]="options.iconCssClass">
								<wcd-shared-icon class="dialog-icon" [iconName]="options.icon">
								</wcd-shared-icon>
							</span>
							{{ options.title }}
						</h1>
					</header>
					<div class="wcd-flex-1 wcd-flex-vertical ie11Patch ie11Flex">
						<div class="wcd-flex-1 ie11Patch ie11Flex">
							<div class="wcd-padding-bottom">
								<markdown [data]="options.text"></markdown>
							</div>
							<div *ngIf="options.requireMessage" [ngSwitch]="options.requireMessage.type">
								<input
									*ngSwitchCase="'number'"
									type="number"
									[attr.min]="options.requireMessage.min"
									[attr.max]="options.requireMessage.max"
									[focusOn]="focus"
									name="confirm-text"
									[placeholder]="options.requireMessage.placeholder"
									[attr.aria-label]="options.requireMessage.placeholder"
									required
									[(ngModel)]="confirmText"
								/>
								<input
									*ngSwitchCase="'text'"
									type="text"
									[focusOn]="focus"
									name="confirm-text"
									[placeholder]="options.requireMessage.placeholder"
									[attr.aria-label]="options.requireMessage.placeholder"
									[(ngModel)]="confirmText"
								/>
								<textarea
									required
									*ngSwitchDefault
									rows="4"
									name="confirm-text"
									[focusOn]="focus"
									[placeholder]="options.requireMessage.placeholder"
									[attr.aria-label]="options.requireMessage.placeholder"
									class="form-control wcd-full-width wcd-no-resize"
									[(ngModel)]="confirmText"
								></textarea>
							</div>
						</div>
					</div>
				</div>
				<div class="dialog-footer wcd-flex-none right-text">
					<fab-primary-button
						type="submit"
						*ngIf="options.showConfirm !== false"
						data-test-id="confirm-confirm"
						data-track-id="Confirm"
						data-track-type="Button"
						[disabled]="(options.requireMessage && !confirmText) || confirmOngoing"
						[className]="options.confirmButtonClass + ' wcd-margin-small-right'"
						[fabButtonFocusOn]="focus"
					>
						<i *ngIf="confirmOngoing" class="loader-icon"></i>
						{{ options.confirmText || ('buttons.confirm' | i18n) }}
					</fab-primary-button>
					<fab-default-button
						(click)="cancel(); destroy()"
						data-test-id="confirm-cancel"
						data-track-id="Cancel"
						data-track-type="Button"
						contentClass="btn-secondary"
						[disabled]="confirmOngoing"
						[fabButtonFocusOn]="focus"
					>
						{{ options.cancelText || ('buttons.cancel' | i18n) }}
					</fab-default-button>
				</div>
			</form>
		</modal>
	