var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { Machine } from '../machine/machine.entity';
import { CyberEventActionType } from './cyber-event-action-type.entity';
import { LegacyUser } from '../legacy/user/legacy-user.entity';
import { NetworkEndpoint } from '../entity/network-endpoint.value-object';
import { Process } from '../entity/process.entity';
import { FileInstance } from '../file/file-instance.entity';
import { CyberEventEntity } from './cyber-event-entity.entity';
import { CyberEventAdditionalFields } from './cyber-event-additional-fields.value-object';
import { omit } from 'lodash-es';
import { Alert } from '../alert/alert.entity';
import { CyberEventEntityRelationTypeEnum } from './cyber-event-entity-relation-type-enum';
import { Severity } from '../severity/severity.entity';
import { LogonType } from '../user/logon-type.enum';
import { RegistryModificationDetails } from '../entity/registry-modification-details.value-object';
import { Email } from '../entity/email.value-object';
import { CyberEventActionTypeName } from './cyber-event-action-type-name.enum';
import { CyberEventMitreTechniqueInfo } from '../mitre/cyber-event-mitre-info.value-object';
import { parseEntityData } from './cyber-event-entity.entity';
import { FileAlertEvidence } from '../alert-evidence/file-alert-evidence.value-object';
import { ProcessAlertEvidence } from '../alert-evidence/process-alert-evidence.value-object';
var isNotAlert = function (event) {
    return event.actionType && event.actionType.id !== CyberEventActionTypeName.Alert;
};
var ɵ0 = isNotAlert;
var ɵ1 = function (config, query) {
    var _a = query.where, entityType = _a.entityType, entityId = _a.entityId;
    if (entityType === 'urls') {
        // Since url is a complex string, it wont pass as path parm in api-proxy. Only the url/events was changed by detection
        // to accpt url as query param and not a path param.
        return 'urls/events'; // The id will be pushed as query param
    }
    return entityType + "/" + entityId + "/events";
}, ɵ2 = function (dataQuery) {
    if (!dataQuery)
        return {};
    // Since URL is complex string, it wont pass as path param in api-proxy. This was changed by detection , for url only, to accept the url as query param
    // and not path param.
    var idParam = dataQuery.where['entityType'] === 'urls' ? { url: dataQuery.where['entityId'] } : {};
    return Object.assign({}, idParam, omit(dataQuery.where, [
        'page',
        'page_size',
        'ordering',
        'entityId',
        'entityType',
        'useCyberData',
    ]));
}, ɵ3 = function (itemId, entity, config, params) {
    // Remove cyber event enrichment for OneCyber event type.
    if (params &&
        params['actionType'] &&
        params['actionType']['id'] === CyberEventActionTypeName.OneCyber)
        return null;
    if (!params || !params.machine || !params.machine.id)
        throw new Error('Cyber event could not be retrieved because machine id is missing.');
    if (!params.actionTime)
        throw new Error('Cyber event could not be retrieved because event action time is missing.');
    var eventId = (params.relatedAlert && params.relatedAlert.id) || params.reportId;
    return "machines/" + params.machine.id + "/event?\n\t\t\tActionTime=" + params['actionTimeIsoString'] + "\n\t\t\t" + ((params.relatedAlert ? '&AlertId=' : '&ReportId=') + eventId) + "\n\t\t\t" + (params.officeTenantPrefix
        ? '&enableO365Enrichment=true&officeTenantPrefix=' + params.officeTenantPrefix
        : '') + "\n\t\t\t" + (params.getMinimalData ? '&getMinimalData=true' : '');
}, ɵ4 = function (rawDataSet) {
    var items = rawDataSet.Items || [];
    return {
        count: items.length,
        items: items,
        next: rawDataSet.Prev,
        previous: rawDataSet.Next,
    };
}, ɵ5 = function (config, query) {
    if (query && query.where && query.where['useCyberData'] === true) {
        return config.data.serviceUrls.cyberData;
    }
    return config.data.serviceUrls.threatIntel;
}, ɵ6 = function (rawData) {
    return (rawData.MachineId || '') + "_" + rawData.ActionType + "_" + rawData.ActionTime + "_" + (rawData.ReportId || (rawData.Alert && rawData.Alert.AlertId) || '');
}, ɵ7 = function (genericFields) {
    return genericFields ? genericFields.filter(function (d) { return !d.isHidden; }) : null;
};
var CyberEvent = /** @class */ (function (_super) {
    __extends(CyberEvent, _super);
    function CyberEvent(data) {
        var _this = _super.call(this, data) || this;
        _this.isNotAlert = isNotAlert(_this);
        _this.isUnknownOrNotAlert = !_this.actionType || isNotAlert(_this);
        return _this;
    }
    Object.defineProperty(CyberEvent.prototype, "source", {
        // default entities
        get: function () {
            return (this.initiatingProcess && {
                id: this.initiatingProcess ? this.initiatingProcess.id || '' : '',
                entityType: Process,
                item: new ProcessAlertEvidence(__assign({}, this.initiatingProcess, { additionalFields: this.additionalFields })),
                relationToPrecedingEntity: this.initiatingProcessParent
                    ? CyberEventEntityRelationTypeEnum.Child
                    : CyberEventEntityRelationTypeEnum.Empty,
                depth: this.initiatingProcessParent ? (this.initiatingProcessParentParent ? 2 : 1) : 0,
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CyberEvent.prototype, "sourceParent", {
        get: function () {
            return (this.initiatingProcessParent && {
                id: this.initiatingProcessParent ? this.initiatingProcessParent.id || '' : '',
                entityType: Process,
                item: this.initiatingProcessParent,
                relationToPrecedingEntity: this.initiatingProcessParentParent
                    ? CyberEventEntityRelationTypeEnum.Child
                    : CyberEventEntityRelationTypeEnum.Empty,
                depth: this.initiatingProcessParentParent ? 1 : 0,
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CyberEvent.prototype, "sourceParentParent", {
        get: function () {
            return (this.initiatingProcessParentParent && {
                id: this.initiatingProcessParentParent ? this.initiatingProcessParentParent.id || '' : '',
                entityType: Process,
                item: this.initiatingProcessParentParent,
                relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Empty,
                depth: 0,
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CyberEvent.prototype, "target", {
        get: function () {
            var item = (this.process && {
                id: this.process ? this.process.id || '' : '',
                entityType: Process,
                item: this.process,
            }) ||
                (this.file && {
                    id: this.file ? this.file.id || '' : '',
                    entityType: FileInstance,
                    item: this.file,
                });
            return item
                ? __assign({}, item, { relationToPrecedingEntity: this.initiatingProcess
                        ? CyberEventEntityRelationTypeEnum.Child
                        : CyberEventEntityRelationTypeEnum.Empty, depth: this.initiatingProcess
                        ? this.initiatingProcessParent
                            ? this.initiatingProcessParentParent
                                ? 3
                                : 2
                            : 1
                        : 0 }) : null;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({
            data: '__self',
            parse: ɵ6,
        }),
        __metadata("design:type", String)
    ], CyberEvent.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'ReportId' }),
        __metadata("design:type", Number)
    ], CyberEvent.prototype, "reportId", void 0);
    __decorate([
        EntityField({ data: 'ActionTime' }),
        __metadata("design:type", Date)
    ], CyberEvent.prototype, "actionTime", void 0);
    __decorate([
        EntityField({ data: 'ActionTime' }),
        __metadata("design:type", String)
    ], CyberEvent.prototype, "actionTimeIsoString", void 0);
    __decorate([
        EntityField({ data: 'InitiatingUser' }),
        __metadata("design:type", LegacyUser)
    ], CyberEvent.prototype, "initiatingUser", void 0);
    __decorate([
        EntityField({ data: 'ActionType' }),
        __metadata("design:type", CyberEventActionType)
    ], CyberEvent.prototype, "actionType", void 0);
    __decorate([
        EntityField({ data: 'ActionType' }),
        __metadata("design:type", String)
    ], CyberEvent.prototype, "rawActionType", void 0);
    __decorate([
        EntityField({ data: 'InitiatingProcess', parse: parseEntityData }),
        __metadata("design:type", ProcessAlertEvidence)
    ], CyberEvent.prototype, "initiatingProcess", void 0);
    __decorate([
        EntityField({ data: 'InitiatingProcessParent', parse: parseEntityData }),
        __metadata("design:type", ProcessAlertEvidence)
    ], CyberEvent.prototype, "initiatingProcessParent", void 0);
    __decorate([
        EntityField({ data: 'InitiatingProcessParentParent', parse: parseEntityData }),
        __metadata("design:type", ProcessAlertEvidence)
    ], CyberEvent.prototype, "initiatingProcessParentParent", void 0);
    __decorate([
        EntityField({ data: 'AppGuardContainerId' }),
        __metadata("design:type", String)
    ], CyberEvent.prototype, "containerId", void 0);
    __decorate([
        EntityField({ data: 'IsMarked' }),
        __metadata("design:type", Boolean)
    ], CyberEvent.prototype, "isMarked", void 0);
    __decorate([
        EntityField({ data: 'Machine' }),
        __metadata("design:type", Machine)
    ], CyberEvent.prototype, "machine", void 0);
    __decorate([
        EntityField({ data: 'RemoteMachine' }),
        __metadata("design:type", Machine)
    ], CyberEvent.prototype, "remoteMachine", void 0);
    __decorate([
        EntityField({ data: 'Process', parse: parseEntityData }),
        __metadata("design:type", ProcessAlertEvidence)
    ], CyberEvent.prototype, "process", void 0);
    __decorate([
        EntityField({ data: 'File', parse: parseEntityData }),
        __metadata("design:type", FileAlertEvidence)
    ], CyberEvent.prototype, "file", void 0);
    __decorate([
        EntityField({ data: 'User' }),
        __metadata("design:type", LegacyUser)
    ], CyberEvent.prototype, "user", void 0);
    __decorate([
        EntityField({ data: 'EmailEntity' }),
        __metadata("design:type", Email)
    ], CyberEvent.prototype, "email", void 0);
    __decorate([
        EntityField({ data: 'LocalEndpoint' }),
        __metadata("design:type", NetworkEndpoint)
    ], CyberEvent.prototype, "localEndpoint", void 0);
    __decorate([
        EntityField({ data: 'RemoteEndpoint' }),
        __metadata("design:type", NetworkEndpoint)
    ], CyberEvent.prototype, "remoteEndpoint", void 0);
    __decorate([
        EntityField({ data: 'FileOrigin' }),
        __metadata("design:type", NetworkEndpoint)
    ], CyberEvent.prototype, "fileOrigin", void 0);
    __decorate([
        EntityField({ data: 'FileOriginReferer' }),
        __metadata("design:type", NetworkEndpoint)
    ], CyberEvent.prototype, "fileOriginReferer", void 0);
    __decorate([
        EntityField({ data: '__self' }),
        __metadata("design:type", RegistryModificationDetails)
    ], CyberEvent.prototype, "registryModificationDetails", void 0);
    __decorate([
        EntityField({ data: 'LogonType' }),
        __metadata("design:type", String)
    ], CyberEvent.prototype, "logonType", void 0);
    __decorate([
        EntityField({ data: 'AdditionalFields' }),
        __metadata("design:type", CyberEventAdditionalFields)
    ], CyberEvent.prototype, "additionalFields", void 0);
    __decorate([
        EntityField({ data: 'AlertIds' }),
        __metadata("design:type", Array)
    ], CyberEvent.prototype, "alertIds", void 0);
    __decorate([
        EntityField({ data: 'AlertSeverity' }),
        __metadata("design:type", Severity)
    ], CyberEvent.prototype, "alertSeverity", void 0);
    __decorate([
        EntityField({ data: 'Alert' }) // relevant for "alert" rows only (shows alert details and not actual machine event)
        ,
        __metadata("design:type", Alert)
    ], CyberEvent.prototype, "relatedAlert", void 0);
    __decorate([
        EntityField({ data: 'Tags' }),
        __metadata("design:type", Array)
    ], CyberEvent.prototype, "tags", void 0);
    __decorate([
        EntityField({ data: 'CyberActionType' }),
        __metadata("design:type", CyberEventActionType)
    ], CyberEvent.prototype, "cyberActionType", void 0);
    __decorate([
        EntityField({ data: 'Description' }),
        __metadata("design:type", String)
    ], CyberEvent.prototype, "description", void 0);
    __decorate([
        EntityField({ data: 'Icon' }),
        __metadata("design:type", String)
    ], CyberEvent.prototype, "icon", void 0);
    __decorate([
        EntityField({
            data: 'Entities',
            arrayOf: CyberEventEntity,
            parse: function (entities, rawData) {
                return entities && entities.map(function (entity) { return parseEntityData(entity, rawData); });
            },
        }),
        __metadata("design:type", Array)
    ], CyberEvent.prototype, "entities", void 0);
    __decorate([
        EntityField({ data: 'MitreTechniques' }),
        __metadata("design:type", Array)
    ], CyberEvent.prototype, "mitreTechniques", void 0);
    __decorate([
        EntityField({
            data: 'MitreInfo',
            arrayOf: CyberEventMitreTechniqueInfo,
        }),
        __metadata("design:type", Array)
    ], CyberEvent.prototype, "mitreInfo", void 0);
    __decorate([
        EntityField({ data: 'IsCyberData' }),
        __metadata("design:type", Boolean)
    ], CyberEvent.prototype, "isCyberData", void 0);
    __decorate([
        EntityField({ data: 'IsBoldEvent', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], CyberEvent.prototype, "IsBoldEvent", void 0);
    __decorate([
        EntityField({
            data: 'TypedDetails',
            parse: ɵ7,
        }),
        __metadata("design:type", Array)
    ], CyberEvent.prototype, "genericFields", void 0);
    __decorate([
        EntityField({ data: 'RelatedObservationName' }),
        __metadata("design:type", String)
    ], CyberEvent.prototype, "relatedObservationName", void 0);
    CyberEvent = __decorate([
        Entity({
            singularName: 'Event',
            pluralName: 'Events',
            endpoint: ɵ1,
            parseDataQuery: ɵ2,
            parseItemQuery: ɵ3,
            parseDataSet: ɵ4,
            separateArrayParams: true,
            timeout: 5 * 60000,
            cache: {
                time: 1000 * 60,
                max: 10,
            },
            baseUrl: ɵ5,
            readonly: true,
        }),
        __metadata("design:paramtypes", [Object])
    ], CyberEvent);
    return CyberEvent;
}(EntityModelBase));
export { CyberEvent };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
