import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';

export enum SnmpAuthType {
	CommunityString = 'Community String',
	NoAuthNoPriv = 'NoAuthNoPriv',
	AuthNoPriv = 'AuthNoPriv',
	AuthPriv = 'AuthPriv',
}

export enum SnmpAuthProtocol {
	md5 = 'MD5',
	sha1 = 'SHA1',
}

export enum SnmpPrivProtocol {
	des = 'DES',
	threeDes = '3DES',
	aes = 'AES',
}

@ValueObject({
	singularName: 'SnmpAuth',
	pluralName: 'SnmpAuth',
})
export class SnmpAuth extends ModelBase {
	@EntityField()
	type: string;

	@EntityField()
	communityString?: string;

	@EntityField()
	username?: string;

	@EntityField()
	authProtocol?: string;

	@EntityField()
	authPassword?: string;

	@EntityField()
	privProtocol?: string;

	@EntityField()
	privPassword?: string;
}
