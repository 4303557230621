import { DataQuery, Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { Machine } from '../machine/machine.entity';
import { CyberEventActionType } from './cyber-event-action-type.entity';
import { LegacyUser } from '../legacy/user/legacy-user.entity';
import { NetworkEndpoint } from '../entity/network-endpoint.value-object';
import { Process } from '../entity/process.entity';
import { FileInstance } from '../file/file-instance.entity';
import { CyberEventEntity } from './cyber-event-entity.entity';
import { CyberEventAdditionalFields } from './cyber-event-additional-fields.value-object';
import { omit } from 'lodash-es';
import { Tag } from '../tag/tag.entity';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { Alert } from '../alert/alert.entity';
import { CyberEventEntityRelationTypeEnum } from './cyber-event-entity-relation-type-enum';
import { Severity } from '../severity/severity.entity';
import { LogonType } from '../user/logon-type.enum';
import { RegistryModificationDetails } from '../entity/registry-modification-details.value-object';
import { Email } from '../entity/email.value-object';
import { CyberEventActionTypeName } from './cyber-event-action-type-name.enum';
import { CyberEventMitreTechniqueInfo } from '../mitre/cyber-event-mitre-info.value-object';
import { GenericField } from '../generic-field/generic-field.value-object';
import { parseEntityData } from './cyber-event-entity.entity';
import { FileAlertEvidence } from '../alert-evidence/file-alert-evidence.value-object';
import { ProcessAlertEvidence } from '../alert-evidence/process-alert-evidence.value-object';

const isNotAlert = (event: CyberEvent): boolean => {
	return event.actionType && event.actionType.id !== CyberEventActionTypeName.Alert;
};

@Entity({
	singularName: 'Event',
	pluralName: 'Events',
	endpoint: (
		config,
		query: {
			where: {
				entityType: 'files' | 'machines' | 'urls';
				entityId: string;
				[index: string]: string | number;
			};
		}
	) => {
		const { entityType, entityId } = query.where;
		if (entityType === 'urls') {
			// Since url is a complex string, it wont pass as path parm in api-proxy. Only the url/events was changed by detection
			// to accpt url as query param and not a path param.
			return 'urls/events'; // The id will be pushed as query param
		}
		return `${entityType}/${entityId}/events`;
	},
	parseDataQuery: (dataQuery: DataQuery) => {
		if (!dataQuery) return {};

		// Since URL is complex string, it wont pass as path param in api-proxy. This was changed by detection , for url only, to accept the url as query param
		// and not path param.
		const idParam = dataQuery.where['entityType'] === 'urls' ? { url: dataQuery.where['entityId'] } : {};

		return Object.assign(
			{},
			idParam,
			omit(dataQuery.where as object, [
				'page',
				'page_size',
				'ordering',
				'entityId',
				'entityType',
				'useCyberData',
			])
		);
	},
	parseItemQuery: (itemId, entity, config, params: { [index: string]: any }) => {
		// Remove cyber event enrichment for OneCyber event type.
		if (
			params &&
			params['actionType'] &&
			params['actionType']['id'] === CyberEventActionTypeName.OneCyber
		)
			return null;

		if (!params || !params.machine || !params.machine.id)
			throw new Error('Cyber event could not be retrieved because machine id is missing.');

		if (!params.actionTime)
			throw new Error('Cyber event could not be retrieved because event action time is missing.');

		const eventId = (params.relatedAlert && params.relatedAlert.id) || params.reportId;

		return `machines/${params.machine.id}/event?
			ActionTime=${params['actionTimeIsoString']}
			${(params.relatedAlert ? '&AlertId=' : '&ReportId=') + eventId}
			${
				params.officeTenantPrefix
					? '&enableO365Enrichment=true&officeTenantPrefix=' + params.officeTenantPrefix
					: ''
			}
			${params.getMinimalData ? '&getMinimalData=true' : ''}`;
	},
	parseDataSet: (rawDataSet) => {
		const items = rawDataSet.Items || [];
		return {
			count: items.length,
			items: items,
			next: rawDataSet.Prev, // backend treats "scroll down" request as "previous data request" because data requested is older (date wise).
			previous: rawDataSet.Next,
		};
	},
	separateArrayParams: true,
	timeout: 5 * 60000,
	cache: {
		time: 1000 * 60,
		max: 10,
	},
	baseUrl: (config: WcdPortalParisConfig, query: DataQuery) => {
		if (query && query.where && query.where['useCyberData'] === true) {
			return config.data.serviceUrls.cyberData;
		}
		return config.data.serviceUrls.threatIntel;
	},
	readonly: true,
})
export class CyberEvent extends EntityModelBase<string> {
	// event identifiers

	@EntityField({
		data: '__self',
		parse: (rawData) =>
			`${rawData.MachineId || ''}_${rawData.ActionType}_${rawData.ActionTime}_${
				rawData.ReportId || (rawData.Alert && rawData.Alert.AlertId) || ''
			}`,
	})
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'ReportId' })
	reportId: number;

	// action details

	@EntityField({ data: 'ActionTime' })
	actionTime: Date;

	/** Used as work around to javascript not supporting date after the ms.
		Can be used to call the BE with exact datetime value including after the ms.*/
	@EntityField({ data: 'ActionTime' })
	actionTimeIsoString: string;

	@EntityField({ data: 'InitiatingUser' })
	initiatingUser: LegacyUser;

	@EntityField({ data: 'ActionType' })
	actionType: CyberEventActionType;

	@EntityField({ data: 'ActionType' })
	rawActionType: string;

	@EntityField({ data: 'InitiatingProcess', parse: parseEntityData })
	initiatingProcess?: ProcessAlertEvidence;

	@EntityField({ data: 'InitiatingProcessParent', parse: parseEntityData })
	initiatingProcessParent?: ProcessAlertEvidence;

	@EntityField({ data: 'InitiatingProcessParentParent', parse: parseEntityData })
	initiatingProcessParentParent?: ProcessAlertEvidence;

	@EntityField({ data: 'AppGuardContainerId' })
	containerId?: string;

	@EntityField({ data: 'IsMarked' })
	isMarked?: boolean;

	// entities

	@EntityField({ data: 'Machine' })
	machine?: Machine;

	@EntityField({ data: 'RemoteMachine' })
	remoteMachine?: Machine;

	@EntityField({ data: 'Process', parse: parseEntityData })
	process?: ProcessAlertEvidence;

	@EntityField({ data: 'File', parse: parseEntityData })
	file?: FileAlertEvidence;

	@EntityField({ data: 'User' })
	user?: LegacyUser;

	@EntityField({ data: 'EmailEntity' })
	email?: Email;

	@EntityField({ data: 'LocalEndpoint' })
	localEndpoint?: NetworkEndpoint;

	@EntityField({ data: 'RemoteEndpoint' })
	remoteEndpoint?: NetworkEndpoint;

	@EntityField({ data: 'FileOrigin' })
	fileOrigin?: NetworkEndpoint;

	@EntityField({ data: 'FileOriginReferer' })
	fileOriginReferer?: NetworkEndpoint;

	@EntityField({ data: '__self' })
	registryModificationDetails?: RegistryModificationDetails;

	@EntityField({ data: 'LogonType' })
	logonType?: LogonType;

	@EntityField({ data: 'AdditionalFields' })
	additionalFields?: CyberEventAdditionalFields;

	// alerts

	@EntityField({ data: 'AlertIds' })
	alertIds?: Array<string>;

	@EntityField({ data: 'AlertSeverity' })
	alertSeverity?: Severity;

	@EntityField({ data: 'Alert' }) // relevant for "alert" rows only (shows alert details and not actual machine event)
	relatedAlert?: Alert;

	// behaviors

	@EntityField({ data: 'Tags' })
	tags?: Array<Tag>;

	// support OneCyber data

	@EntityField({ data: 'CyberActionType' })
	cyberActionType: CyberEventActionType;

	@EntityField({ data: 'Description' })
	description?: string;

	@EntityField({ data: 'Icon' })
	icon?: string;

	@EntityField({
		data: 'Entities',
		arrayOf: CyberEventEntity,
		parse(entities, rawData) {
			return entities && entities.map((entity) => parseEntityData(entity, rawData));
		},
	})
	entities: Array<CyberEventEntity>;

	@EntityField({ data: 'MitreTechniques' })
	mitreTechniques?: Array<string>;

	@EntityField({
		data: 'MitreInfo',
		arrayOf: CyberEventMitreTechniqueInfo,
	})
	mitreInfo?: Array<CyberEventMitreTechniqueInfo>;

	@EntityField({ data: 'IsCyberData' })
	isCyberData?: boolean;

	@EntityField({ data: 'IsBoldEvent', defaultValue: false })
	IsBoldEvent?: boolean;

	@EntityField({
		data: 'TypedDetails',
		parse: (genericFields: Array<GenericField>) =>
			genericFields ? genericFields.filter((d) => !d.isHidden) : null,
	})
	genericFields?: Array<GenericField>;

	@EntityField({ data: 'RelatedObservationName' })
	relatedObservationName?: string;

	// default entities

	get source(): CyberEventEntity {
		return (
			this.initiatingProcess && {
				id: this.initiatingProcess ? this.initiatingProcess.id || '' : '',
				entityType: Process,
				item: new ProcessAlertEvidence({
					...this.initiatingProcess,
					additionalFields: this.additionalFields,
				}),
				relationToPrecedingEntity: this.initiatingProcessParent
					? CyberEventEntityRelationTypeEnum.Child
					: CyberEventEntityRelationTypeEnum.Empty,
				depth: this.initiatingProcessParent ? (this.initiatingProcessParentParent ? 2 : 1) : 0,
			}
		);
	}

	get sourceParent(): CyberEventEntity {
		return (
			this.initiatingProcessParent && {
				id: this.initiatingProcessParent ? this.initiatingProcessParent.id || '' : '',
				entityType: Process,
				item: this.initiatingProcessParent,
				relationToPrecedingEntity: this.initiatingProcessParentParent
					? CyberEventEntityRelationTypeEnum.Child
					: CyberEventEntityRelationTypeEnum.Empty,
				depth: this.initiatingProcessParentParent ? 1 : 0,
			}
		);
	}

	get sourceParentParent(): CyberEventEntity {
		return (
			this.initiatingProcessParentParent && {
				id: this.initiatingProcessParentParent ? this.initiatingProcessParentParent.id || '' : '',
				entityType: Process,
				item: this.initiatingProcessParentParent,
				relationToPrecedingEntity: CyberEventEntityRelationTypeEnum.Empty,
				depth: 0,
			}
		);
	}

	get target(): CyberEventEntity {
		const item =
			(this.process && {
				id: this.process ? this.process.id || '' : '',
				entityType: Process,
				item: this.process,
			}) ||
			(this.file && {
				id: this.file ? this.file.id || '' : '',
				entityType: FileInstance,
				item: this.file,
			});
		return item
			? {
					...item,
					relationToPrecedingEntity: this.initiatingProcess
						? CyberEventEntityRelationTypeEnum.Child
						: CyberEventEntityRelationTypeEnum.Empty,
					depth: this.initiatingProcess
						? this.initiatingProcessParent
							? this.initiatingProcessParentParent
								? 3
								: 2
							: 1
						: 0,
			  }
			: null;
	}

	// meta attributes
	isNotAlert: boolean; // true if action type is known, and it is not "alert" event row
	isUnknownOrNotAlert: boolean; // true if action type is unknown or it's known and it's not "alert" event row

	constructor(data) {
		super(data);

		this.isNotAlert = isNotAlert(this);
		this.isUnknownOrNotAlert = !this.actionType || isNotAlert(this);
	}
}
