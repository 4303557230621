<!-- tslint:disable:template-click-events-have-key-events -->
<!-- tslint:disable:template-accessibility-label-for -->
<div *ngIf="entityPageViewMode === EntityPageViewMode.Modern"
	class="wcd-font-weight-bold wcd-font-size-base wcd-padding-small-bottom wcd-padding-mediumSmall-top">
	{{ 'incident_details_pane_title' | i18n }}
</div>
<div class="wcd-phishing-and-ransomware wcd-border-all wcd-padding-all wcd-margin-bottom" *ngIf="phishingPlaybook || ransomwarePlaybook">
	<div class="wcd-margin-small-bottom">
		<div class="wcd-font-size-xs wcd-font-size-mi wcd-padding-xsmall-vertical wcd-padding-small-horizontal title">{{'incident_details_soc_playbook_recommendation' | i18n }}</div>
	</div>
	<ng-container *ngIf="phishingPlaybook">
		<div class="wcd-font-weight-bold wcd-margin-small-bottom">{{'incident_details_soc_playbook_recommendation_phishing_title' | i18n }}</div>
		<div class="wcd-margin-small-bottom">{{'incident_details_soc_playbook_recommendation_phishing_description' | i18n }}</div>
		<a (click)="openSocPlayBook('Phishing Incident Playbook')">{{'incident_details_soc_playbook_recommendation_phishing_open' | i18n}}</a>
	</ng-container>
	<ng-container *ngIf="phishingPlaybook && ransomwarePlaybook">
		<hr/>
	</ng-container>
	<ng-container *ngIf="ransomwarePlaybook">
		<div class="wcd-font-weight-bold wcd-margin-small-bottom">{{'incident_details_soc_playbook_recommendation_ransomware_title' | i18n }}</div>
		<div class="wcd-margin-small-bottom">{{'incident_details_soc_playbook_recommendation_ransomware_description' | i18n }}</div>
		<a (click)="openSocPlayBook('Ransomware Incident Playbook')">{{'incident_details_soc_playbook_recommendation_ransomware_open' | i18n }}</a>
	</ng-container>
</div>
<ng-container *ngIf="incident">
	<div
		 [ngClass]="mode === EntityDetailsMode.EntityPage ? 'wcd-full-height wcd-flex-vertical' : ''"
		 data-track-component="IncidentEntityDetails"
		 [class.modern]="entityPageViewMode === EntityPageViewMode.Modern"
	>
		<a *ngIf="incidentGraphPOCEnabled" [href]="incidentGraphLink">{{ 'incident_details_pane_incidentGraphPocLink' | i18n }}</a>
		<ng-container>
			<incident-rbac-permission-message [incident]="incident"></incident-rbac-permission-message>
		</ng-container>
		<ng-container
			*ngTemplateOutlet="
				entityPageViewMode === EntityPageViewMode.Modern ? modernDetails : defaultDetails
			"
		>
		</ng-container>
	</div>
</ng-container>

<ng-template #infoBalloon let-text="text" let-visible="visible">
	<div *ngIf="visible" class="wcd-margin-small-bottom">
		<wcd-info-box>
			<div class="wcd-flex-1">
				<markdown>{{ text }}</markdown>
			</div>
		</wcd-info-box>
	</div>
</ng-template>
<!-- TODO: the "defaultDetails" template should be removed when all rings get the "ModernEntityPage" -->
<ng-template #defaultDetails>
	<!-- Some of the following fields has duplicates in the "modernDetails", so if you update these fields display, please update the modern template as well -->
	<wcd-collapsible-section
		[noSideMargins]="true"
		[sectionId]="collapsibleID.Details"
		[label]="'entities.details.title' | i18n: { entityType: 'incidents.incident' | i18n }"
	>
	<dl role="none"
		[class.key-values]="asKeyValueList"
		[ngClass]="mode === EntityDetailsMode.EntityPage ? 'wcd-flex-1 wcd-scroll-vertical' : ''"
	>
		<dt role="none">{{ 'status' | i18n }}</dt>
		<dd role="none">
			{{ incident.status.nameI18nKey | i18n }}
		</dd>
		<dt role="none" class="info-label">{{ 'assigned.to' | i18n }}</dt>
		<dd role="none">{{ incident.assignedTo || ('assigned.unassigned' | i18n) }}</dd>
		<dt role="none">{{ 'alerts.severity' | i18n }}</dt>
		<dd role="none" class="wcd-severity" [ngClass]="'wcd-severity-' + incident.severity.type">
			{{ incident.severity.nameI18nKey | i18n }}
		</dd>
		<dt role="none">{{ 'incident.fields.id.title' | i18n }}</dt>
		<dd role="none">{{ incident.id }}</dd>
		<dt role="none">{{ 'alerts.classification' | i18n }}</dt>
		<dd role="none">
			<span>{{ getClassification(incident) }}</span>
			<div *ngIf="incident.determination">
				{{ incident.determination.nameI18nKey | i18n }}
			</div>
			<div
				*ngIf="isFullyMachineRbacExposed"
			>
				<button
					class="btn-link btn-unstyled no-padding"
					data-track-id="ShowSetClassificationPanel"
					data-track-type="Button"
					(click)="showSetClassificationPanel()"
				>
					{{ 'incident.setClassification.manageStatusAndClassificationLink' | i18n }}
				</button>
			</div>
		</dd>
		<ng-container *ngIf="incident.categories?.length">
			<dt role="none" class="info-label">
				{{ (incident.categories.length === 1 ? 'category' : 'categories') | i18n }}
			</dt>
			<dd role="none">
				<ul class="unstyled">
					<li *ngFor="let category of incident.categories">{{ getCategoryName(category) }}</li>
				</ul>
			</dd>
		</ng-container>
		<ng-container *ngIf="mode !== EntityDetailsMode.EntityPage">
			<dt role="none">{{ 'alerts.activityTime' | i18n }}</dt>
			<dd role="none">
				<ul class="unstyled" role="none">
					<li role="none">{{ 'first' | i18n }} - {{ incident.firstEventTime | date: 'medium' }}</li>
					<li role="none">{{ 'last' | i18n }} - {{ incident.lastEventTime | date: 'medium' }}</li>
				</ul>
			</dd>
		</ng-container>
	</dl>
	<div
		*ngIf="mode === EntityDetailsMode.EntityPage"
		class="wcd-flex-none wcd-border-all color-box-white wcd-padding-all wcd-margin-vertical"
	>
		<div
			class="wcd-padding-horizontal text-center text-uppercase wcd-margin-bottom"
			[ngClass]="incidentStatusColor"
		>
			{{ incident.status.nameI18nKey | i18n }}
		</div>
		<dl role="none">
			<dt role="none">{{ 'alerts.activityTime' | i18n }}</dt>
			<dd role="none" class="wcd-margin-none-bottom">
				<ul class="unstyled bold">
					<li>{{ 'first' | i18n }} - {{ incident.firstEventTime | date: 'medium' }}</li>
					<li>{{ 'last' | i18n }} - {{ incident.lastEventTime | date: 'medium' }}</li>
				</ul>
			</dd>
		</dl>
	</div>
	</wcd-collapsible-section>
</ng-template>

<ng-template #modernDetails>
	<incident-associated-incidents *ngIf="showAssociatedIncidents" [incident]="incident"></incident-associated-incidents>
	<wcd-collapsible-section
		[label]="'incident.overview.tags.summary' | i18n"
		[sectionId]="collapsibleID.Tags"
		[noSideMargins]="true">
		<incident-entity-details-tags [incident]="incident"> </incident-entity-details-tags>
	</wcd-collapsible-section>

	<wcd-collapsible-section
		[noSideMargins]="true"
		[sectionId]="collapsibleID.Details"
		[label]="'entities.details.title' | i18n: { entityType: 'incidents.incident' | i18n }"
	>
		<dl role="none">
			<dt role="none">{{ 'status' | i18n }}</dt>
			<dd role="none">
				{{ incident.status.nameI18nKey | i18n }}
			</dd>
			<dt role="none">{{ 'alerts.severity' | i18n }}</dt>
			<dd role="none" class="wcd-severity" [ngClass]="'wcd-severity-' + incident.severity.type">
				{{ incident.severity.nameI18nKey | i18n }}
			</dd>
			<dt role="none">{{ 'incident.fields.id.title' | i18n }}</dt>
			<dd role="none">{{ incident.id }}</dd>
			<dt role="none">{{ 'alerts.activity.first' | i18n }}</dt>
			<dd role="none">{{ 'first' | i18n }} - {{ incident.firstEventTime | date: 'medium' }}</dd>
			<dt role="none">{{ 'alerts.activity.last' | i18n }}</dt>
			<dd role="none">{{ 'last' | i18n }} - {{ incident.lastEventTime | date: 'medium' }}</dd>
			<dt role="none">{{ 'alerts.classification' | i18n }}</dt>
			<dd role="none">
				{{ getClassification(incident) }}
			</dd>
			<dt role="none">{{ 'incident.fields.determination.title' | i18n }}</dt>
			<dd role="none">{{ (incident.determination ? incident.determination.nameI18nKey : 'alerts.unclassified') | i18n }}</dd>
			<dt role="none">{{ 'assigned.to' | i18n }}</dt>
			<dd role="none">{{ incident.assignedTo || ('assigned.unassigned' | i18n) }}</dd>
		</dl>
	</wcd-collapsible-section>
</ng-template>
