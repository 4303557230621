import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { NotificationRuleCondition } from './notification-rule-condition.value-object';
import { MachineGroup } from '../rbac/machine-group.entity';
import { NotificationFormat } from './notification-rule-format.entity';
import { notificationFormatValues } from './notification-rule-format.values';
import { WcdPortalParisConfig } from '../paris-config.interface';

@Entity({
	singularName: 'Notification Rule',
	pluralName: 'Notification rules',
	endpoint: 'email_notifications',
	allItemsProperty: 'items',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.management,
})
export class NotificationRule extends EntityModelBase<number> {
	@EntityField({ data: 'Id' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number;

	@EntityField({ data: ['Name'] })
	name: string;

	@EntityField({ data: 'Recipients', arrayOf: String })
	recipients: Array<string>;

	@EntityField({ data: 'LastUpdated' })
	lastUpdatedOn: Date;

	@EntityField({ data: ['IsReadonly'] })
	readonly: boolean;

	@EntityField({
		data: 'Rules',
		arrayOf: NotificationRuleCondition,
	})
	conditions: Array<NotificationRuleCondition>;

	@EntityField({
		data: 'MachineGroupIds',
		arrayOf: MachineGroup,
		serialize: (items: Array<MachineGroup>) => {
			return items.map(item => item.id);
		},
	})
	machineGroups: Array<MachineGroup>;

	@EntityField({
		data: 'FormatFlavor',
		arrayOf: NotificationFormat,
		parse: (formatBitWise: number) => {
			return notificationFormatValues.reduce((formats: Array<number>, notificationFormat) => {
				return notificationFormat.id > 0 && formatBitWise & notificationFormat.id
					? [...formats, notificationFormat.id]
					: formats;
			}, []);
		},
		serialize: (notificationFormats: Array<NotificationFormat>) =>
			notificationFormats.reduce((bitwiseValue: number, format) => bitwiseValue | format.id, 0),
	})
	formatFlavor: Array<NotificationFormat>;
}
