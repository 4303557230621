var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, ComponentFactoryResolver, ElementRef, EventEmitter, NgZone, OnChanges, OnDestroy, OnInit, QueryList, Renderer2, ViewContainerRef, } from '@angular/core';
import { DataTableField } from '../models/datatable-field.model';
import { BehaviorSubject, from, fromEvent } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { I18nService } from '@wcd/i18n';
import { generateChanges, isNgChanges } from '@wcd/angular-extensions';
import { debounce } from 'lodash-es';
import { take } from 'rxjs/operators';
import { sccHostService } from '@wcd/scc-interface';
import { FabActionButtonComponent } from '@angular-react/fabric';
var BUTTON_PADDING = 16;
var SCROLL_CLASS = 'scroll';
var BODY_HAS_SCROLL = 'body-has-scroll';
var DEFAULT_FIELD_WIDTH = 200;
var MIN_FIELD_WIDTH = 40;
var LOAD_TOP_ITEMS_HEIGHT = 40;
var CELL_HORIZONTAL_PADDING = 16;
var lastId = 0;
var DataTableComponent = /** @class */ (function () {
    function DataTableComponent(elementRef, changeDetectionRef, liveAnnouncer, i18nService, resolver, zone, renderer) {
        var _this = this;
        this.elementRef = elementRef;
        this.changeDetectionRef = changeDetectionRef;
        this.liveAnnouncer = liveAnnouncer;
        this.i18nService = i18nService;
        this.resolver = resolver;
        this.zone = zone;
        this.renderer = renderer;
        /**
         * If `true`, the table will get a `small-padding` class and rows will be smaller vertically.
         */
        this.isSmallPadding = false;
        /**
         * If `true`, the table will dispatch scroll event to get more data if no data exists.
         */
        this.loadMoreOnEmpty = false;
        this.loadMoreOnEmptyCount = 0;
        this.MAX_RETIRES_ON_EMPTY_DATA = 10;
        /**
         * Whether grouping rows is allowed.
         */
        this.allowGroupItems = false;
        /**
         * Whether should load all groups items on init.
         */
        this.loadGroupItemsOnLoad = false;
        /**
         * If allowGroupItems - allow group item to be selectable without auto selection of all the nested items
         */
        this.allowParentSelectionWithoutSelectingNestedItems = false;
        /**
         * If `true`, the user is able to resize columns in the table.
         */
        this.allowResize = false;
        /**
         * Make the width of the table fixed, no mater the content size. overflow items will be hidden with elipsis.
         * Important: will not work when allowResize is set to true
         */
        this.fixedTable = false;
        /**
         * Whether to allow selecting multiple items. If `false`, the data table will deselect a selected item before selecting a new one
         * @default true
         */
        this.allowMultipleSelection = true;
        /**
         * The property in TData to use as key. Used for selection, to verify uniqueness.
         */
        this.itemUniqueKey = 'id';
        /**
         * Set `showHeaders` to `false` to not render the table's headers.
         */
        this.showHeaders = true;
        /**
         * A boolean stating whether the table should extend to the full height of the container or not
         */
        this.fullHeight = true;
        /**
         * A boolean stating whether the column headers should size dynamically.
         * Note: only works if fixedTable = true
         * @default false
         */
        this.fluidHeaderWidth = false;
        /**
         * Changing from false to true will focus the first column header in the table.
         */
        this.focusOnFirstCell = false;
        /**
         * Changing from false to true will focus the table. Ignored when "focusOnFirstCell" is set to "true"
         */
        this.focusOnTable = false;
        /**
         * using false will be translated to null which will eliminate the tabindex option
         */
        this.tabIndex = -1;
        /**
         * using 'true' will brake the headers text to multiple lines if text is longer than max with of the column
         */
        this.wrapHeader = false;
        /**
         * An event that is emitted whenever an item is selected from the data table.
         * The event sends the currently selected items, along with the previous and next items, if the selection is a single item.
         */
        this.select = new EventEmitter();
        /**
         * An event that is emitted when an item is clicked on.
         */
        this.itemClick = new EventEmitter();
        /**
         * Emitted when a user clicks on a table header with sorting enabled.
         */
        this.sortFieldChanged = new EventEmitter();
        /**
         * Emitted when a group is expanded.
         */
        this.groupExpand = new EventEmitter();
        /**
         * Emitted if `infiniteScrolling` is true and the data table is scrolled to the bottom.
         */
        this.scroll = new EventEmitter();
        /**
         * Emitted if `loadItemsOnTableTop` is true and the user clicks "Load newer results".
         */
        this.loadTopItemsClick = new EventEmitter();
        /**
         * Emitted when a user resizes a column
         */
        this.columnResize = new EventEmitter();
        /**
         * Emitted when a new data is applied ane rendered
         */
        this.onRenderComplete = new EventEmitter();
        this.someSelected = false;
        this.isTopScroll = true;
        this.isLoadingTopItems$ = new BehaviorSubject(false);
        this.selectedItemsIndex = new Set();
        this.boundTrackById = this.trackById.bind(this);
        this._isInit = false;
        this._isFirstData = true;
        this._firstScrollChecked = false;
        this._sortDescending = false;
        this._initialLoadFired = false;
        this._fireLoadedEvent = debounce(function () {
            _this._initialLoadFired = true;
            _this.onRenderComplete.emit();
        }, 10);
        this.isFocusedElementChild = false;
        this.selectAllRowFocused = false;
        this.selectAllText = i18nService.get('grid.select.all');
        this.unselectAllText = i18nService.get('grid.select.none');
        this.tableUniqueId = "datatable" + ++lastId;
    }
    Object.defineProperty(DataTableComponent.prototype, "sortableFieldIds", {
        /**
         * If specified, overrides the sort:enabled property of a field to decide whether sorting is allowed for a field.
         */
        set: function (sortableFieldIds) {
            this.sortableFieldIdsSet = sortableFieldIds ? new Set(sortableFieldIds) : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataTableComponent.prototype, "sortDescending", {
        get: function () {
            return this._sortDescending;
        },
        /**
         * Whether the current sort is done descending rather than ascending.
         */
        set: function (sortDescending) {
            this._sortDescending = sortDescending;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataTableComponent.prototype, "allItems", {
        /**
         * Returns all the items and grouped items in the data table
         */
        get: function () {
            var groupedItems = [];
            if (this.groupItems)
                this.groupItems.forEach(function (items) { return (groupedItems = groupedItems.concat(items)); });
            return this.items.concat(groupedItems);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataTableComponent.prototype, "showCheckboxField", {
        get: function () {
            return this.selectEnabled !== false && this.hasSelectableItems;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataTableComponent.prototype, "showLoadTopItemsButton", {
        get: function () {
            return (this.loadItemsOnTableTop &&
                this.tableContainer &&
                this.tableContainer.nativeElement &&
                this.tableContainer.nativeElement.scrollTop < 100);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataTableComponent.prototype, "hasVerticalScroll", {
        get: function () {
            return (this.tableContainer &&
                this.tableContainer.nativeElement &&
                this.tableContainer.nativeElement.scrollHeight > this.tableContainer.nativeElement.clientHeight);
        },
        enumerable: true,
        configurable: true
    });
    DataTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.allSelected = !!this.itemsSelected;
        this.tableBodyElement = this.tableContainer.nativeElement.querySelector('table');
        if (this.allowGroupItems)
            this.expandedGroups = new Set();
        if (this.nestedComponentType)
            this.expandedNestedContents = new Set();
        if (this.showHeaders !== false) {
            var scroll$ = fromEvent(this.tableContainer.nativeElement, 'scroll').pipe(tap(function (e) { return _this.onScrollBody(e); }));
            if (this.infiniteScrolling) {
                scroll$ = scroll$.pipe(debounceTime(300), filter(function () {
                    var _a = _this.tableContainer.nativeElement, scrollTop = _a.scrollTop, scrollHeight = _a.scrollHeight, clientHeight = _a.clientHeight;
                    return scrollTop + clientHeight + 1 >= scrollHeight;
                }), tap(function () { return _this.scroll.emit(); }));
            }
            this.scrollSubscription = scroll$.subscribe(function () { });
            this._updateHeadersSubscription = fromEvent(window, 'resize')
                .pipe(debounceTime(100))
                .subscribe(this.updateHeaderCells.bind(this));
        }
        if (this.items)
            this.onData(this.items);
        this._isInit = true;
    };
    DataTableComponent.prototype.ngOnDestroy = function () {
        this._updateHeadersSubscription && this._updateHeadersSubscription.unsubscribe();
        this.scrollSubscription && this.scrollSubscription.unsubscribe();
    };
    DataTableComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        var changed = false;
        if (this.allowGroupItems && (changes.allowGroupItems || changes.items)) {
            this.expandedGroups = new Set();
            this.expandedNestedContents = new Set();
            this.groupItems = new Map();
            changed = true;
        }
        if (!this._isInit)
            return;
        if (changes.selectEnabled) {
            this.setSelectableItems();
        }
        if (changes.itemsSelected &&
            this.itemsSelected !== undefined &&
            this.itemsSelected !== null &&
            this.itemsSelected.constructor === Boolean) {
            this.allSelected = this.itemsSelected;
            if (!this.allSelected) {
                this.selectedItemsIndex.clear();
            }
        }
        if (changes.items) {
            this.onData(this.items);
            changed = true;
            this.updateHeaderCells();
        }
        if (changes.sortField || changes.refreshOn)
            changed = true;
        if (changes.columns)
            setTimeout(function () { return _this.updateHeaderCells(); }, 100);
        else if (changes.update)
            this.updateHeaderCells();
        else if (changed)
            this.changeDetectionRef.markForCheck();
    };
    DataTableComponent.prototype.ngAfterViewChecked = function () {
        if (!this._initialLoadFired) {
            this._fireLoadedEvent();
        }
        if (!this.items)
            return;
        if (this.loadItemsOnTableTop) {
            var firstItem = this.items[0];
            if (!this._previousFirstItem) {
                this._previousFirstItem = firstItem;
                return;
            }
            // Keep the scroll position of the previous first item
            if (this._shouldKeepScrollPosition && firstItem.id !== this._previousFirstItem.id) {
                var previousItemScrollTop = this.getRowByItem(this._previousFirstItem).offsetTop;
                this.tableContainer.nativeElement.scrollTop =
                    previousItemScrollTop -
                        (this.tableOriginalHeader.nativeElement.clientHeight + LOAD_TOP_ITEMS_HEIGHT);
                this._shouldKeepScrollPosition = false;
                this._previousFirstItem = firstItem;
            }
        }
    };
    DataTableComponent.prototype.ngAfterViewInit = function () {
        this.updateHeaderCells();
    };
    DataTableComponent.prototype.onScrollBody = function (e) {
        this.headerWrapperElement.nativeElement.scrollLeft = e.target.scrollLeft;
        if (this.tableResizeWrapperElement)
            this.tableResizeWrapperElement.nativeElement.style.left = "-" + e.target.scrollLeft + "px";
        if (e.target.scrollTop) {
            if (this.isTopScroll) {
                this.headerWrapperElement.nativeElement.classList.add(SCROLL_CLASS);
                this.isTopScroll = false;
            }
        }
        else if (!this.isTopScroll) {
            this.isTopScroll = true;
            this.headerWrapperElement.nativeElement.classList.remove(SCROLL_CLASS);
        }
        if (e.target.scrollTop < 500)
            this.changeDetectionRef.detectChanges();
    };
    DataTableComponent.prototype.onScrollByKey = function (originalCell) {
        var isTableHeader = originalCell instanceof HTMLLIElement;
        var source = isTableHeader ? this.headerWrapperElement : this.tableContainer;
        var destination = isTableHeader ? this.tableContainer : this.headerWrapperElement;
        this.setScrollPosition(source, destination);
    };
    DataTableComponent.prototype.onSortChanged = function (field) {
        var _this = this;
        this.sortFieldChanged.emit({ field: field });
        setTimeout(function () {
            if (_this.liveAnnouncer) {
                _this.liveAnnouncer.announce(_this.getColumnSortedAnnouncement(_this.sortField), 'assertive', 300);
            }
        }, 200);
    };
    DataTableComponent.prototype.onItemsAddedOnTop = function () {
        this.isLoadingTopItems$.next(false);
        this._shouldKeepScrollPosition = true;
        this.changeDetectionRef.markForCheck();
    };
    DataTableComponent.prototype.loadItemsOnTop = function () {
        this.isLoadingTopItems$.next(true);
        this.loadTopItemsClick.emit();
    };
    DataTableComponent.prototype.toggleRow = function ($event, item, isHead) {
        if (isHead === void 0) { isHead = false; }
        if (this.isItemClickable && !this.isItemClickable(item))
            return;
        var target = $event.target;
        if (target.closest('.wcd-toggle') || target.closest('.checkbox-field') || target.nodeName === 'INPUT')
            return true;
        var link = target.closest('a');
        if (target.getAttribute('(click)') || (link && link.getAttribute('href')) || target.closest('button'))
            return;
        if (this.nestedComponentType && this.hasNestedContent(item))
            this.toggleNestedContent(item);
        if (isHead)
            this.toggleGroup(item);
        else
            this.triggerItemClick(item, $event);
    };
    DataTableComponent.prototype.triggerItemClick = function (item, $event) {
        if (this.isItemClickable && !this.isItemClickable(item))
            return;
        $event && this.fixTabIndexElement($event);
        this.itemClick.emit(Object.assign({
            item: item,
            mouseEvent: $event,
        }, this.getItemPreviousAndNext(item)));
    };
    DataTableComponent.prototype.toggleNestedContent = function (rootItem) {
        var _this = this;
        if (this.expandedNestedContents.has(rootItem)) {
            this.expandedNestedContents.delete(rootItem);
        }
        else {
            this.expandedNestedContents.add(rootItem);
            // adding a setTimeout to allow Angular to render the content placeholder before accessing it
            setTimeout(function () { return _this.setNestedContent(rootItem); }, 1);
        }
    };
    DataTableComponent.prototype.setNestedContent = function (rootItem) {
        var _this = this;
        // add nested component dynamically
        var factory = this.resolver.resolveComponentFactory(this.nestedComponentType.type);
        var componentPlaceHolder = this.customNestedComponents.find(function (placeholder) { return placeholder.element.nativeElement.id === "nested_" + rootItem[_this.itemUniqueKey]; });
        var nestedComponent = componentPlaceHolder.createComponent(factory);
        var props = this.nestedComponentType.getProps && this.nestedComponentType.getProps(rootItem);
        if (this.nestedComponentType.getProps)
            Object.assign(nestedComponent.instance, props);
        if (isNgChanges(nestedComponent.instance))
            nestedComponent.instance.ngOnChanges(generateChanges(props));
        this.changeDetectionRef.markForCheck();
    };
    DataTableComponent.prototype.toggleGroup = function (rootItem) {
        if (this.expandedGroups.has(rootItem)) {
            this.expandedGroups.delete(rootItem);
            this.groupItems.delete(rootItem);
        }
        else {
            var perf = sccHostService.perf.createPerformanceSession('expand-datatable-group', 'user-action');
            this.expandedGroups.add(rootItem);
            this.setGroupItems(rootItem, perf);
        }
        this.announceGroupToggle(rootItem);
    };
    DataTableComponent.prototype.setGroupItems = function (rootItem, perf) {
        var _this = this;
        if (!this.getGroupItems)
            return;
        var itemsCount = this.allItems ? this.allItems.length : -1;
        from(this.getGroupItems(rootItem)).subscribe(function (groupItems) {
            groupItems = groupItems || [];
            var isGroupSelected = !_this.allowParentSelectionWithoutSelectingNestedItems &&
                _this.selectedItemsIndex.has(rootItem);
            _this.addSelectableItems(groupItems);
            _this.groupItems.set(rootItem, groupItems);
            _this.groupExpand.emit({ group: rootItem, children: groupItems });
            if (isGroupSelected) {
                groupItems.forEach(function (item) {
                    if (_this.selectableItems.has(item))
                        _this.selectedItemsIndex.add(item);
                });
                _this.setSelectedState();
                _this.notifyOnSelectionChange();
            }
            _this.changeDetectionRef.markForCheck();
            try {
                _this.zone.onMicrotaskEmpty.asObservable().pipe(take(1))
                    .subscribe(function () { return perf.end({
                    customProps: {
                        nestedItemsCount: groupItems.length,
                        itemsCount: itemsCount,
                    },
                }); });
            }
            catch (e) {
                sccHostService.log.trackException(e);
            }
        });
    };
    // Select the given items (and de-select other items, if any).
    DataTableComponent.prototype.selectItems = function (items) {
        var _this = this;
        var newSelectedItemsIds = new Set(items.map(function (item) { return item[_this.itemUniqueKey]; }));
        var wasChanged = false;
        for (var _i = 0, _a = this.allItems; _i < _a.length; _i++) {
            var item = _a[_i];
            var itemId = item[this.itemUniqueKey];
            var newSelectionStatus = newSelectedItemsIds.has(itemId);
            var previousSelectionStatus = this.selectedItemsIndex.has(item);
            if (newSelectionStatus !== previousSelectionStatus) {
                wasChanged = true;
                if (newSelectionStatus)
                    this.selectedItemsIndex.add(item);
                else
                    this.selectedItemsIndex.delete(item);
            }
        }
        if (wasChanged) {
            this.setSelectedState();
            this.notifyOnSelectionChange();
        }
    };
    DataTableComponent.prototype.onData = function (data) {
        var _this = this;
        if (!data)
            return;
        if (this.itemsSelected !== undefined) {
            this.allSelected = this.itemsSelected;
            if (typeof this.allSelected === 'boolean') {
                this.selectedItemsIndex.clear();
                if (this.itemsSelected) {
                    data.forEach(function (item) {
                        _this.selectedItemsIndex.add(item);
                    });
                }
                else {
                    this.selectedItemsIndex.clear();
                }
            }
            else if (this.selectedItemsIndex) {
                this.selectedItemsIndex.forEach(function (item) {
                    if (!data.includes(item) || !_this.isItemSelectable(item))
                        _this.selectedItemsIndex.delete(item);
                });
            }
        }
        else {
            this.selectedItemsIndex.forEach(function (item) {
                if (!data.includes(item))
                    _this.selectedItemsIndex.delete(item);
            });
        }
        this.setSelectableItems();
        if (this.allowGroupItems && this.loadGroupItemsOnLoad) {
            data.forEach(function (item) {
                if (_this.isItemGroup(item) &&
                    _this.isGroupExpandedOnInit(item) &&
                    !_this.expandedGroups.has(item)) {
                    _this.toggleGroup(item);
                }
            });
        }
        this.notifyOnSelectionChange();
        this.updateHeaderCells();
        if (!this.infiniteScrolling) {
            this.tableContainer.nativeElement.scrollTop = 0;
            this.tableContainer.nativeElement.scrollLeft = 0;
        }
        this._initialLoadFired = false;
    };
    /**
     * To enable body-only scrolling, the header can't be a part of the table, so it should be updated when necessary.
     * This function sets the header so it looks as part of the table.
     */
    DataTableComponent.prototype.updateHeaderCells = function () {
        var _this = this;
        if (this.showHeaders === false)
            return;
        requestAnimationFrame(function () {
            var firstRow = _this.tableBodyElement.querySelector('tbody tr');
            if (!firstRow) {
                if (_this.items && _this.items.length)
                    return _this.updateHeaderCells();
                return;
            }
            _this.doUpdateHeaderCells(firstRow);
        });
    };
    DataTableComponent.prototype.doUpdateHeaderCells = function (firstRow, resetTableWidth) {
        var _this = this;
        if (this.showHeaders === false)
            return;
        var tableHeaderCells = this.headerListElement.nativeElement.querySelectorAll('li');
        if (resetTableWidth !== false)
            this.tableBodyElement.style.removeProperty('width');
        this.tableBodyElement.style.top =
            '-' + (this.tableOriginalHeader.nativeElement.clientHeight + 2) + 'px';
        if (!this.fieldWidths) {
            this.tableElement.nativeElement.style.tableLayout = 'auto';
            this.tableElement.nativeElement.style.width = '100%';
        }
        var columnWidths = this.getColumnWidths(firstRow);
        this.tableElement.nativeElement.style.removeProperty('table-layout');
        this.tableElement.nativeElement.style.removeProperty('width');
        this.fieldWidths = this.columns.reduce(function (fieldWidths, column, i) {
            if (!fieldWidths[column.id]) {
                var calculatedColumnWidth = columnWidths[_this.showCheckboxField ? i + 1 : i];
                if (column.minWidth)
                    calculatedColumnWidth = Math.max(calculatedColumnWidth, column.minWidth);
                if (column.maxWidth)
                    calculatedColumnWidth = Math.min(calculatedColumnWidth, column.maxWidth);
                fieldWidths[column.id] = calculatedColumnWidth;
            }
            return fieldWidths;
        }, this.fieldWidths || {});
        columnWidths.forEach(function (columnWidth, i) {
            var headerCell = tableHeaderCells[i];
            var firstRowCell = firstRow.querySelector("td:nth-child(" + (i + 1) + ")");
            headerCell.style.width = columnWidth + 'px';
            var headerContents = headerCell.querySelector('button') || headerCell.querySelector('span');
            if (headerContents) {
                var headerContentsWidth = headerContents.clientWidth + BUTTON_PADDING, widthOffset = headerContentsWidth - columnWidth;
                if (widthOffset > 0) {
                    firstRowCell.style.width = headerContentsWidth + 'px';
                }
            }
        });
        this.headerListElement.nativeElement.style.paddingRight = '10px';
        if (this.hasVerticalScroll) {
            this.headerWrapperElement.nativeElement.classList.add(BODY_HAS_SCROLL);
        }
        else {
            this.headerWrapperElement.nativeElement.classList.remove(BODY_HAS_SCROLL);
        }
        this.changeDetectionRef.markForCheck();
    };
    DataTableComponent.prototype.getColumnWidths = function (firstRow) {
        var cells = Array.from(firstRow.querySelectorAll('td')).filter(function (element) { return element.parentElement === firstRow; });
        return cells.map(function (cell) { return cell.clientWidth; });
    };
    DataTableComponent.prototype.setSelectableItems = function () {
        this.selectableItems = new Set();
        this.addSelectableItems(this.allItems);
    };
    DataTableComponent.prototype.addSelectableItems = function (items) {
        var _this = this;
        if (this.selectEnabled === false || !items) {
            this.hasSelectableItems = false;
            return;
        }
        var hasSelectableItems = false;
        items.forEach(function (item) {
            if (_this.isSelectable(item)) {
                _this.selectableItems.add(item);
                hasSelectableItems = true;
            }
        });
        this.hasSelectableItems = this.hasSelectableItems || hasSelectableItems;
    };
    /**
     * Deselects all items in the table and emits the 'select' event if any items were deselected.
     * @returns True if any items were deselected, false if not.
     */
    DataTableComponent.prototype.selectNone = function (notifyChange) {
        if (notifyChange === void 0) { notifyChange = true; }
        if (this.allSelected || this.someSelected) {
            this.allSelected = this.someSelected = false;
            this.selectedItemsIndex.clear();
            if (notifyChange)
                this.notifyOnSelectionChange();
            return true;
        }
        return false;
    };
    DataTableComponent.prototype.toggleAllSelection = function () {
        var _this = this;
        if (!this.selectNone(false)) {
            this.allSelected = true;
            this.someSelected = true;
            var someSelected_1 = false;
            this.allItems.forEach(function (item) {
                var isSelected = _this.isItemSelectable ? _this.isItemSelectable(item) : true;
                if (isSelected)
                    _this.selectedItemsIndex.add(item);
                else
                    _this.selectedItemsIndex.delete(item);
                if (!isSelected)
                    _this.allSelected = false;
                else
                    someSelected_1 = true;
            });
            this.someSelected = !this.allSelected && someSelected_1;
        }
        this.notifyOnSelectionChange();
    };
    DataTableComponent.prototype.keyboardToggleItemSelection = function (event, item, state) {
        event.preventDefault();
        this.toggleItemSelection(item, state);
    };
    DataTableComponent.prototype.toggleItemSelection = function (item, state) {
        var _this = this;
        var currentState = this.selectedItemsIndex.has(item);
        var changed = false;
        if (state === undefined || state !== currentState) {
            if (this._lastSelectionEvent &&
                this._lastSelectionEvent.ctrlKey &&
                !this._lastSelectionEvent.shiftKey &&
                this.selectedItemsIndex.size > 1) {
                // If ctrl is pressed, deselect all but the clicked item
                if (currentState)
                    this.selectedItemsIndex.clear();
                this.selectedItemsIndex.add(item);
                this._lastSelectedItem = item;
            }
            else if (this._lastSelectionEvent &&
                this._lastSelectionEvent.shiftKey &&
                this.selectedItemsIndex.size &&
                this._lastSelectedItem !== item) {
                if (!this._lastSelectionEvent.ctrlKey)
                    this.selectedItemsIndex.clear();
                var selectionIndexes = [
                    this.allItems.indexOf(item),
                    this.allItems.indexOf(this._lastSelectedItem),
                ];
                selectionIndexes.sort(function (a, b) { return a - b; });
                for (var itemIndex = selectionIndexes[0]; itemIndex <= selectionIndexes[1]; itemIndex++) {
                    this.selectedItemsIndex.add(this.allItems[itemIndex]);
                }
            }
            else {
                if (!this.allowMultipleSelection)
                    this.selectNone(false);
                var isSelected_1 = !currentState;
                if (isSelected_1)
                    this.selectedItemsIndex.add(item);
                else
                    this.selectedItemsIndex.delete(item);
                if (this.allowGroupItems && !this.allowParentSelectionWithoutSelectingNestedItems) {
                    if (this.isItemGroup && this.isItemGroup(item)) {
                        var groupChildren = this.groupItems.get(item);
                        if (groupChildren)
                            groupChildren.forEach(function (childItem) {
                                if (isSelected_1)
                                    _this.selectedItemsIndex.add(childItem);
                                else
                                    _this.selectedItemsIndex.delete(childItem);
                            });
                        else if (isSelected_1)
                            this.toggleGroup(item);
                    }
                    else {
                        var itemGroup = this.getItemGroup(item);
                        if (itemGroup) {
                            if (isSelected_1 && !this.selectedItemsIndex.has(itemGroup)) {
                                var allGroupItemsSelected = this.groupItems
                                    .get(itemGroup)
                                    .every(function (groupChildItem) { return _this.selectedItemsIndex.has(groupChildItem); });
                                if (allGroupItemsSelected)
                                    this.selectedItemsIndex.add(itemGroup);
                            }
                            else
                                this.selectedItemsIndex.delete(itemGroup);
                        }
                    }
                }
                this._lastSelectedItem = item;
            }
            changed = true;
        }
        if (changed) {
            this.setSelectedState();
            this.notifyOnSelectionChange();
        }
        this._lastSelectionEvent = null;
    };
    DataTableComponent.prototype.onCheckboxClick = function ($event) {
        this._lastSelectionEvent = $event;
    };
    /**
     * If allowGroupItems is true and the specified item is inside a group, returns the group.
     */
    DataTableComponent.prototype.getItemGroup = function (item) {
        if (!this.allowGroupItems || this.groupItems.has(item))
            return null;
        var groupItemKeys = this.groupItems.keys();
        var currentKey;
        while ((currentKey = groupItemKeys.next()) && !currentKey.done) {
            var groupItems = this.groupItems.get(currentKey.value);
            if (groupItems.includes(item))
                return currentKey.value;
        }
        return null;
    };
    DataTableComponent.prototype.notifyOnSelectionChange = function () {
        var _this = this;
        var selectedItems = [];
        if (this.items) {
            // The selected items to emit are those in the selectedItemsIndex that are NOT groups:
            selectedItems = this.allSelected
                ? this.allItems
                : this.allItems.filter(function (item) {
                    return _this.selectedItemsIndex.has(item) &&
                        (!_this.allowGroupItems ||
                            !_this.groupItems.has(item) ||
                            _this.allowParentSelectionWithoutSelectingNestedItems);
                });
        }
        if (this.loadMoreOnEmpty && (!this.items || !this.items.length)) {
            if (this.loadMoreOnEmptyCount < this.MAX_RETIRES_ON_EMPTY_DATA) {
                this.loadMoreOnEmptyCount++;
                this.scroll.emit();
            }
            else {
                this.loadMoreOnEmptyCount = 0;
            }
        }
        if (this._isFirstData) {
            this._isFirstData = false;
            if (this.infiniteScrolling && this.items && this.items.length)
                setTimeout(function () { return _this.checkFirstScroll(); }, 500);
        }
        else {
            this.select.emit(Object.assign({ items: selectedItems }, selectedItems.length === 1 ? this.getItemPreviousAndNext(selectedItems[0]) : null));
        }
    };
    DataTableComponent.prototype.checkFirstScroll = function () {
        if (this._firstScrollChecked)
            return;
        if (this.infiniteScrolling) {
            var _a = this.tableContainer.nativeElement, scrollHeight = _a.scrollHeight, clientHeight = _a.clientHeight;
            if (scrollHeight <= clientHeight)
                this.scroll.emit();
        }
        this._firstScrollChecked = true;
    };
    DataTableComponent.prototype.getRowByItem = function (item) {
        var rows = this.tableElement.nativeElement.querySelectorAll('tr');
        return Array.from(rows).find(function (row) { return row.dataset && row.dataset.itemId === item.id; });
    };
    DataTableComponent.prototype.getItemPreviousAndNext = function (item) {
        var itemIndex = this.items.indexOf(item), items;
        if (~itemIndex)
            items = this.items;
        else if (this.allowGroupItems) {
            var groupItemKeys = this.groupItems.keys();
            var currentKey = void 0;
            while ((currentKey = groupItemKeys.next()) && !currentKey.done && !items) {
                var groupItems = this.groupItems.get(currentKey.value), itemIndexInGroup = groupItems.indexOf(item);
                if (~itemIndexInGroup) {
                    itemIndex = itemIndexInGroup;
                    items = groupItems;
                }
            }
        }
        if (items) {
            var previousItemThatIsNotAGroup = void 0;
            if (itemIndex) {
                for (var i = itemIndex; i > 0 && !previousItemThatIsNotAGroup; i--) {
                    var previousItem = items[i - 1];
                    if (!this.isItemGroup ||
                        !this.isItemGroup(previousItem) ||
                        this.allowParentSelectionWithoutSelectingNestedItems)
                        previousItemThatIsNotAGroup = previousItem;
                }
            }
            var nextItemThatIsNotAGroup = void 0;
            if (itemIndex < items.length - 1) {
                for (var i = itemIndex; i < items.length && !nextItemThatIsNotAGroup; i++) {
                    var nextItem = items[i + 1];
                    if (!this.isItemGroup ||
                        !this.isItemGroup(nextItem) ||
                        this.allowParentSelectionWithoutSelectingNestedItems)
                        nextItemThatIsNotAGroup = nextItem;
                }
            }
            return {
                previous: previousItemThatIsNotAGroup || null,
                next: nextItemThatIsNotAGroup || null,
            };
        }
    };
    DataTableComponent.prototype.setSelectedState = function () {
        var _this = this;
        var someSelected, allSelected = true;
        if (!this.selectedItemsIndex.size)
            this.allSelected = this.someSelected = false;
        this.allItems.forEach(function (item) {
            if (_this.selectedItemsIndex.has(item))
                someSelected = true;
            else
                allSelected = false;
        });
        this.allSelected = allSelected;
        this.someSelected = someSelected && !allSelected;
    };
    DataTableComponent.prototype.isSelectable = function (item) {
        return this.isItemSelectable ? this.isItemSelectable(item) : true;
    };
    DataTableComponent.prototype.trackById = function (index, item) {
        return item[this.itemUniqueKey];
    };
    DataTableComponent.prototype.isItemHighlighted = function (item) {
        var _this = this;
        var findItem = function (highlightedItem) {
            return highlightedItem && highlightedItem[_this.itemUniqueKey] === item[_this.itemUniqueKey];
        };
        var isItemHighlightedInArray = function (items) { return items && !!items.find(findItem); };
        return (isItemHighlightedInArray(this.highlightedItems) ||
            isItemHighlightedInArray(this.permanentHighlightedItems));
    };
    DataTableComponent.prototype.isItemSelected = function (item) {
        return this.selectedItemsIndex.has(item) || this.allSelected;
    };
    DataTableComponent.prototype.isColumnSortable = function (column) {
        if (this.sortableFieldIdsSet)
            return this.sortableFieldIdsSet.has(column.id);
        return column.sort && column.sort.enabled;
    };
    DataTableComponent.prototype.getColumnSortedAnnouncement = function (column) {
        return "Sorted by " + column.name + " : " + this.getColumnAriaSort(column);
    };
    DataTableComponent.prototype.getColumnAriaSort = function (column) {
        return this.sortField && this.sortField.id === column.id
            ? this.sortDescending
                ? 'descending'
                : 'ascending'
            : 'none';
    };
    DataTableComponent.prototype.getFieldWidth = function (field) {
        return (this.fieldWidths && this.fieldWidths[field.id]) || null;
    };
    DataTableComponent.prototype.getFieldWidthPercent = function (field) {
        var _this = this;
        var fieldWidth = this.getFieldWidth(field);
        var totalWidth = this.columns.reduce(function (sum, curr) { return sum + _this.getFieldWidth(curr); }, 0);
        return (fieldWidth / totalWidth) * 100 + "%";
    };
    DataTableComponent.prototype.resizeColumn = function (xPosition) {
        this._resizeColumnEndPosition = xPosition;
    };
    DataTableComponent.prototype.applyResize = function (column, $event) {
        var sizeChange = this._resizeColumnEndPosition - this._resizeColumnStartPosition;
        var minWidth = Math.max(MIN_FIELD_WIDTH, column.minWidth);
        var newWidth = Math.max(minWidth, (this.fieldWidths[column.id] || DEFAULT_FIELD_WIDTH) + sizeChange);
        this._resizeColumnStartPosition = null;
        this._resizeColumnEndPosition = null;
        this.resizingColumn = null;
        var previousWidth = this.fieldWidths[column.id];
        this.fieldWidths[column.id] = newWidth;
        $event.source.reset();
        this.columnResize.emit({
            column: column,
            previousWidth: previousWidth,
            newWidth: newWidth,
            columnsWidths: __assign({}, this.fieldWidths),
        });
    };
    DataTableComponent.prototype.startResize = function (column, event) {
        this._resizeColumnStartPosition = event.clientX;
        this.resizingColumn = column;
    };
    DataTableComponent.prototype.autoFitColumnSize = function (column) {
        this.resizingColumn = null;
        var columnIndex = this.columns.indexOf(column) + 1 + (this.showCheckboxField ? 1 : 0);
        var columnHeaderContents = this.tableElement.nativeElement.querySelector("th:nth-child(" + columnIndex + ")").firstElementChild;
        var columnHeaderWidth = columnHeaderContents
            ? columnHeaderContents.getBoundingClientRect().width
            : 0;
        var maxSize = Math.max.apply(Math, [columnHeaderWidth].concat(Array.from(this.tableElement.nativeElement.querySelectorAll("td:nth-child(" + columnIndex + ") > wcd-datatable-field-value")).map(function (valElement) { return valElement.getBoundingClientRect().width; })));
        var previousWidth = this.fieldWidths[column.id];
        var newWidth = maxSize + CELL_HORIZONTAL_PADDING * 2;
        this.fieldWidths[column.id] = newWidth;
        this.columnResize.emit({
            column: column,
            previousWidth: previousWidth,
            newWidth: newWidth,
            columnsWidths: __assign({}, this.fieldWidths),
        });
    };
    DataTableComponent.prototype.onHeaderArrowKey = function (event, direction) {
        if (direction == 'down') {
            this.tableElement.nativeElement.querySelector('tr.datatable-row').classList.add('focus-in-row');
        }
        this.onArrowKey(event, direction, 'li', 'ul');
    };
    DataTableComponent.prototype.fixAllRowAndTabIndexElement = function (event, focus) {
        this.selectAllRowFocused = focus;
        if (focus)
            this.fixTabIndexElement(event);
    };
    // the function fixing the tab indexes of the table
    // to handle inner focusable element
    // and accessibility issue that table with scrollable element must have tabindex=0 on one of the cells
    // when focusing on the table or a cell
    // the function getting the first table cell out of the tab order
    // getting the focusedElement -
    // 		from last iteration OR getting the first header or cell if this is the first time entering the table
    // getting the current cell -
    //		header cell OR cell OR focused element (if this is the first time entering the table)
    // checking if child component is focusable
    // removing the tabindex from the current element
    // focusing on focused Element
    DataTableComponent.prototype.fixTabIndexElement = function (event, checkForChildTabindex) {
        if (checkForChildTabindex === void 0) { checkForChildTabindex = true; }
        var firstFocusable = this.tableElement.nativeElement.querySelector('tr.datatable-row td');
        var target = event.target;
        if (firstFocusable.getAttribute("tabindex") == '0' && !this.focusedElement) {
            this.tableElement.nativeElement.querySelector('tr.datatable-row td').removeAttribute("tabindex");
            target = null;
        }
        this.focusedElement =
            this.focusedElement ||
                this.headerListElement.nativeElement.querySelector('li[tabindex = "-1"]') ||
                this.tableElement.nativeElement.querySelector('td[tabindex = "-1"], td[tabindex = "0"]') ||
                firstFocusable;
        var currentCell = target &&
            (target.closest('li') ||
                target.closest('td')) ||
            this.focusedElement;
        var childTabindexElement = checkForChildTabindex && this.checkForChildTabindex(currentCell);
        !this.isFocusedElementChild
            ? this.focusedElement && this.focusedElement !== document.activeElement && this.focusedElement.removeAttribute('tabindex')
            : this.focusedElement && this.focusedElement.setAttribute('tabindex', '-1');
        this.focusedElement = childTabindexElement || currentCell || this.focusedElement;
        this.isFocusedElementChild = childTabindexElement ? true : false;
        this.focusedElement && this.focusedElement.setAttribute('tabindex', '0');
        this.focusedElement && this.focusedElement.focus();
    };
    DataTableComponent.prototype.checkForChildTabindex = function (currentCell) {
        return currentCell && currentCell.querySelector('[tabindex = "-1"]');
    };
    DataTableComponent.prototype.onItemOnTopArrowKey = function (event, direction) {
        var eventTarget = event.target, currentCell = eventTarget.closest('div'), originalTabIndexValue = eventTarget.dataset.originalTabIndex;
        var previousCellIndex = parseInt(eventTarget.dataset.previousCellIndex) || 0;
        eventTarget.dataset.previousCellIndex = null;
        var nextCell;
        if (direction === 'down')
            nextCell = this.getNthChild(this.tableElement.nativeElement.querySelector('tr.datatable-row'), previousCellIndex);
        else {
            nextCell = this.getNthChild(this.headerListElement.nativeElement, previousCellIndex, 'li');
        }
        this.setFocusOnNextCell(currentCell, nextCell, direction, originalTabIndexValue);
    };
    DataTableComponent.prototype.onArrowKey = function (event, direction, currentCellType, currentRowType) {
        if (currentCellType === void 0) { currentCellType = 'td'; }
        if (currentRowType === void 0) { currentRowType = 'tr'; }
        event.preventDefault();
        event.stopPropagation();
        var eventTarget = event.target, currentCell = eventTarget.closest(currentCellType), originalTabIndexValue = currentCell.dataset.originalTabIndex, currentRow = eventTarget.closest(currentRowType);
        var nextRow = null;
        if (direction === 'up' || direction === 'down') {
            nextRow = this.getNextRow(currentRow, eventTarget, direction);
        }
        var nextCell = this.getNextCell(currentCell, direction, nextRow);
        nextRow && this.renderer.addClass(nextRow, 'focus-in-row');
        this.setFocusOnNextCell(currentCell, nextCell, direction, originalTabIndexValue);
        if (nextRow || (currentCell instanceof HTMLTableCellElement && nextCell instanceof HTMLLIElement)) {
            currentRow.classList.remove('focus-in-row');
        }
    };
    DataTableComponent.prototype.setFocusOnNextCell = function (currentCell, nextCell, direction, originalTabIndexValue) {
        if (nextCell) {
            //checking if cell have help
            var isHelp = direction === 'right' && this.checkForHelp(event);
            if (!isHelp) {
                this.setNextCellFocus(currentCell, nextCell, originalTabIndexValue);
                if (direction === 'left' || direction === 'right')
                    this.onScrollByKey(currentCell);
            }
        }
    };
    //if help exist focus on it
    DataTableComponent.prototype.checkForHelp = function (event) {
        var help = event.target.querySelector("wcd-help span.wcd-help-icon");
        var fabHelpIcon = help && help.querySelector("fab-icon");
        if (!help && !fabHelpIcon)
            return false;
        !fabHelpIcon && help && help.focus();
        fabHelpIcon && fabHelpIcon.focus();
        return true;
    };
    DataTableComponent.prototype.isCellNavigable = function (currentCell, direction) {
        if (direction === void 0) { direction = 'right'; }
        return currentCell && !currentCell.classList.contains('nav-disabled');
    };
    DataTableComponent.prototype.getNextCell = function (currentCell, direction, nextRow) {
        var currentCellIndex = currentCell.cellIndex;
        var nextCell;
        switch (direction) {
            case 'right':
                nextCell = currentCell.nextElementSibling;
                nextCell = nextCell && (this.isCellNavigable(nextCell) ? nextCell : this.getNextCell(nextCell, 'right', nextRow));
                // Last li element in header is an empty cell in order to support resizing. We ignore it during tabbing.
                if (currentCell instanceof HTMLLIElement && !nextCell.nextElementSibling)
                    nextCell = null;
                break;
            case 'left':
                nextCell = currentCell.previousElementSibling;
                nextCell = nextCell && (this.isCellNavigable(nextCell) ? nextCell : this.getNextCell(nextCell, 'left', nextRow));
                break;
            case 'down':
                if (nextRow) {
                    nextCell = this.getNthChild(nextRow, currentCellIndex);
                    nextCell = nextCell && (this.isCellNavigable(nextCell) ? nextCell : this.getNextCell(nextCell, 'right', nextRow));
                }
                if (!nextCell) {
                    // special case: jump from header row to first table row
                    if (!nextRow && currentCell instanceof HTMLLIElement) {
                        //spacial case: when there is a Load Newer results button
                        if (this.showLoadTopItemsButton) {
                            nextCell = this.loadItemsOnTopElement.elementRef.nativeElement.querySelector("button");
                            nextCell.dataset.previousCellIndex = Array.from(this.headerListElement.nativeElement.children).indexOf(currentCell) + "";
                        }
                        else {
                            nextCell = this.getNthChild(this.tableElement.nativeElement.querySelector('tr.datatable-row'), Array.from(this.headerListElement.nativeElement.children).indexOf(currentCell));
                        }
                    }
                    // special case: when the table allows nested content, next cell might not exist, so we focus on the whole row
                    if (!nextCell && this.nestedComponentType) {
                        nextCell = this.getNthChild(nextRow, 0);
                    }
                    nextCell = nextCell && (this.isCellNavigable(nextCell) ? nextCell : this.getNextCell(nextCell, 'right', nextRow));
                }
                break;
            case 'up':
                if (nextRow) {
                    nextCell = this.getNthChild(nextRow, currentCellIndex);
                    nextCell = nextCell && (this.isCellNavigable(nextCell) ? nextCell : this.getNextCell(nextCell, 'right', nextRow));
                }
                if (!nextCell) {
                    // special case: jump from table to header row
                    if (!nextRow ||
                        (nextRow instanceof HTMLTableRowElement && nextRow.classList.contains('header-row'))) {
                        //spacial case: when there is a Load Newer results button jump from table to button
                        if (!nextRow && this.showLoadTopItemsButton) {
                            nextCell = this.loadItemsOnTopElement.elementRef.nativeElement.querySelector("button");
                            nextCell.dataset.previousCellIndex = currentCellIndex && currentCellIndex.toString();
                        }
                        else {
                            nextCell = this.getNthChild(this.headerListElement.nativeElement, currentCellIndex, 'li');
                        }
                    }
                    // special case: when the table allows nested content, next cell might not exist, so we focus on the whole row
                    if (!nextCell && this.nestedComponentType) {
                        nextCell = this.getNthChild(nextRow, 0);
                    }
                    nextCell = nextCell && this.isCellNavigable(nextCell) ? nextCell : this.getNextCell(nextCell, 'right', nextRow);
                }
                break;
        }
        return nextCell;
    };
    DataTableComponent.prototype.getNthChild = function (element, cellIndex, cellType) {
        if (cellType === void 0) { cellType = 'td'; }
        return element.querySelector(cellType + ":nth-child(" + (cellIndex + 1) + ")"); // nth-child is 1-based
    };
    DataTableComponent.prototype.getNextRow = function (currentRow, eventTarget, direction) {
        var nextRow;
        switch (direction) {
            case 'up':
                nextRow = currentRow.previousElementSibling;
                break;
            case 'down':
                nextRow = currentRow.nextElementSibling;
                break;
        }
        if (this.allowGroupItems && !nextRow) {
            // special case: when the table allows expanding rows, every expandable row is wrapped in a tbody of its own
            var currentTbody = eventTarget.closest('tbody'), nextTbody = currentTbody
                ? direction === 'up'
                    ? currentTbody.previousElementSibling
                    : currentTbody.nextElementSibling
                : null, rows = nextTbody && nextTbody.querySelectorAll('tr');
            // If going up, go to the last row of previous tbody. If going down, go to the first row of next tbody.
            nextRow = rows ? (direction === 'up' ? rows[rows.length - 1] : rows[0]) : null;
        }
        return nextRow;
    };
    DataTableComponent.prototype.setNextCellFocus = function (currentCell, nextCell, originalTabIndexValue) {
        if (originalTabIndexValue != null &&
            originalTabIndexValue !== 'null' &&
            originalTabIndexValue !== 'undefined') {
            currentCell.setAttribute('tabindex', originalTabIndexValue);
        }
        else {
            currentCell.removeAttribute('tabindex');
            delete currentCell.dataset.originalTabIndex;
        }
        nextCell.dataset.originalTabIndex = nextCell.getAttribute('tabindex');
        nextCell.setAttribute('tabindex', '-1');
        nextCell.focus();
    };
    DataTableComponent.prototype.setScrollPosition = function (source, destination) {
        var scrollLeft = source.nativeElement.scrollLeft || 0;
        destination.nativeElement.scrollLeft = scrollLeft;
    };
    DataTableComponent.prototype.getSortableFieldAriaLabel = function (field) {
        var fieldName = field.ariaLabel ? field.ariaLabel : field.name;
        if (!fieldName) {
            return;
        }
        if (this.sortField && this.sortField.id === field.id) {
            return this.i18nService.get('dataview.sort.sortedField', {
                name: fieldName,
                direction: this.sortDescending
                    ? this.i18nService.get('dataview.sort.descending')
                    : this.i18nService.get('dataview.sort.ascending'),
            });
        }
        return this.i18nService.get('dataview.sort.sortableField', {
            name: fieldName,
        });
    };
    DataTableComponent.prototype.fixId = function (id) {
        if (typeof id != 'string')
            return;
        return id.replace(/ /g, '_');
    };
    DataTableComponent.prototype.expandedTabindex = function (field, item) {
        return field.isTabbale && this.isItemGroup && this.isItemGroup(item)
            ? field.id == 'expand' &&
                [
                    field.getFieldCssClass ? field.getFieldCssClass(item) || '' : '',
                    field.className || '',
                ].includes('datatable-expand')
                ? 0
                : undefined
            : undefined;
    };
    DataTableComponent.prototype.onInitRenderComplete = function (row, column) {
        console.log('cell render', row, column);
    };
    DataTableComponent.prototype.announceGroupToggle = function (rootItem) {
        this.liveAnnouncer.announce(this.i18nService.get(this.expandedGroups.has(rootItem) ? "common.button.expanded" : "common.button.collapsed"), 'assertive', 300);
    };
    return DataTableComponent;
}());
export { DataTableComponent };
