var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { compact, lowerFirst } from 'lodash-es';
import { filter, map } from 'rxjs/operators';
import { AlertCategory, AlertClassification, AlertStatus, DetectionSource, InvestigationStatus, Machine, Outbreak, Severity, } from '@wcd/domain';
import { AuthService } from '@wcd/auth';
import { AppConfigService } from '@wcd/app-config';
import { AppContextService, Feature, FeaturesService, FlavorService } from '@wcd/config';
import { DataviewField } from '@wcd/dataview';
import { I18nService } from '@wcd/i18n';
import { RbacService } from '../../../rbac/services/rbac.service';
import { Prettify } from '../../../utils/services/prettify.service';
import { MachinesFiltersService } from '../../machines/services/machines.filters.service';
import { FilterValuesChecklistComponent } from '@wcd/ng-filters';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { FabricIconNames } from '@wcd/scc-common';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { AlertNameFieldComponent } from '../components/alert-name-field.component';
import { IconsService, WcdIconNames } from '@wcd/icons';
import { ImpactedEntitiesCounterComponent } from '../../../impacted-entities/components/impacted-entities-counter/impacted-entities-counter.component';
import { AlertReasonsFieldComponent } from '../components/alert-reasons-field.component';
import { getServiceSourceFilterConfig } from '../../common/helpers/service-source-filters.helpers';
import { TagsFieldComponent } from '../../../tags/components/tags-field/tags-field.component';
import { OVERFLOW_TAGS_CONTAINER_CLASS } from '../../../tags/components/tags-list/overflow-tags-list.component';
import { EntityTagsService } from '../../common/services/entity-tags.service';
import { AppFlavorConfig } from '@wcd/scc-common';
import { IncidentNameComponent } from '../../incidents/components/incident.name.component';
var ENTITY_PAGES_ALERTS_FIELDS = [
    'title',
    'severity',
    'status',
    'classification',
    'investigationStates',
    'category',
    'machine',
    'AssignedTo',
    'lasteventtime',
];
var AlertsFields = /** @class */ (function () {
    function AlertsFields(authService, paris, featuresService, appConfigService, machinesFiltersService, entityTagsService, i18nService, globalEntityTypes, iconsService, appContextService, flavorService, rbacService) {
        var _this = this;
        this.authService = authService;
        this.paris = paris;
        this.featuresService = featuresService;
        this.appConfigService = appConfigService;
        this.machinesFiltersService = machinesFiltersService;
        this.entityTagsService = entityTagsService;
        this.i18nService = i18nService;
        this.globalEntityTypes = globalEntityTypes;
        this.iconsService = iconsService;
        this.appContextService = appContextService;
        this._isRbacFilteringNecessary = false;
        this.alertFieldsExtended =
            flavorService.isEnabled(AppFlavorConfig.alerts.alertFieldsExtended) ||
                flavorService.isEnabled(AppFlavorConfig.incidents.alertFieldsExtended);
        this.FIELDS_DATA = this.getAllFieldsData();
        this.INVESTIGATION_STATE_EXTRA_DATA = this.getInvestigationExtraData();
        this._isAutomatedIrEnabledSubscription = this.appConfigService.isAutomatedIrEnabled$.subscribe(function () { return (_this._allFields = _this._fields = null); });
        this._isRbacFilteringNecessarySubscription = rbacService.isFilteringNecessary$
            .pipe(filter(function (value) { return _this._isRbacFilteringNecessary != value; }))
            .subscribe(function (value) {
            _this._isRbacFilteringNecessary = value;
            _this._allFields = _this._fields = null;
        });
    }
    Object.defineProperty(AlertsFields.prototype, "allFields", {
        // this fields includes also fields that are relevant in incident context only
        get: function () {
            var fieldsData = this.FIELDS_DATA;
            if (!this._allFields) {
                this._allFields = DataviewField.fromList(compact([
                    fieldsData.expand,
                    fieldsData.firstEventTime,
                    fieldsData.title,
                    this.shouldShowTagsColumn() && fieldsData.tags,
                    fieldsData.severity,
                    this.featuresService.isEnabled(Feature.Incidents) && fieldsData.incident,
                    fieldsData.incidentId,
                    fieldsData.status,
                    this.featuresService.isEnabled(Feature.Incidents) && fieldsData.linkedBy,
                    fieldsData.category,
                    this.appContextService.isMtp && fieldsData.impactedEntities,
                    fieldsData.machine,
                    fieldsData.user,
                    this.shouldShowServiceSources() && fieldsData.serviceSource,
                    fieldsData.detectionSource,
                    fieldsData.classification,
                    fieldsData.determination,
                    Object.assign(__assign({}, fieldsData.investigationStates), this.appConfigService.isAutomatedIrEnabled
                        ? this.INVESTIGATION_STATE_EXTRA_DATA.enabled
                        : this.INVESTIGATION_STATE_EXTRA_DATA.disabled),
                    fieldsData.lasteventtime,
                    fieldsData.AssignedTo,
                    fieldsData.osPlatform,
                    this._isRbacFilteringNecessary && fieldsData.rbacGroup,
                    this.featuresService.isEnabled(Feature.ThreatAnalytics2) && fieldsData.IoaDefinitionIds,
                ]));
            }
            return this._allFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertsFields.prototype, "fields", {
        get: function () {
            var _this = this;
            if (!this._fields) {
                this._fields = this.allFields.filter(function (field) {
                    return field.id !== _this.FIELDS_DATA.linkedBy.id &&
                        field.id !== _this.FIELDS_DATA.firstEventTime.id &&
                        field.id !== _this.FIELDS_DATA.impactedEntities.id;
                });
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertsFields.prototype, "mtpFields", {
        get: function () {
            if (!this._fields) {
                var fields = this.allFields.reduce(function (r, field) {
                    var _a;
                    return (__assign({}, r, (_a = {}, _a[field.id] = field, _a)));
                }, {});
                this._fields = compact([
                    fields.expand,
                    fields.title,
                    this.shouldShowTagsColumn() && fields.tags,
                    fields.severity,
                    fields.status,
                    fields.linkedBy,
                    fields.category,
                    fields.impactedEntities,
                    this.shouldShowServiceSources() && fields.serviceSource,
                    __assign({}, fields.detectionSource, { enabledByDefault: this.shouldShowServiceSources() }),
                    fields.firstEventTime,
                    __assign({}, fields.lasteventtime, { enabledByDefault: false }),
                    this.alertFieldsExtended
                        ? __assign({}, fields.investigationStates, { enabledByDefault: true }) : undefined,
                    fields.AssignedTo,
                    __assign({}, fields.classification, { enabledByDefault: false }),
                    fields.determination,
                    fields.firstSeen,
                ]).map(function (field) { return new DataviewField(field); });
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertsFields.prototype, "defaultEntityPagesFields", {
        get: function () {
            var _this = this;
            if (!this._fields) {
                // Keep original order of fields for entity pages defined by ENTITY_PAGES_ALERTS_FIELDS
                this._fields = ENTITY_PAGES_ALERTS_FIELDS.map(function (fieldId) {
                    return _this.allFields.find(function (field) { return field.id === fieldId; });
                }).filter(Boolean);
                if (!this.alertFieldsExtended) {
                    this._fields.filter(function (field) { return field.id !== 'investigationStates'; });
                }
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertsFields.prototype, "fileEntityPagesFields", {
        get: function () {
            var _this = this;
            var filePageAlertsFields = ENTITY_PAGES_ALERTS_FIELDS.slice();
            filePageAlertsFields.splice(1, 0, 'incident', 'incidentId');
            if (!this._fields) {
                // Keep original order of fields for entity pages defined by ENTITY_PAGES_ALERTS_FIELDS
                this._fields = filePageAlertsFields
                    .map(function (fieldId) { return _this.allFields.find(function (field) { return field.id === fieldId; }); })
                    .filter(Boolean);
                if (!this.alertFieldsExtended) {
                    this._fields.filter(function (field) { return field.id !== 'investigationStates'; });
                }
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    AlertsFields.prototype.ngOnDestroy = function () {
        this._isAutomatedIrEnabledSubscription && this._isAutomatedIrEnabledSubscription.unsubscribe();
        this._isRbacFilteringNecessarySubscription &&
            this._isRbacFilteringNecessarySubscription.unsubscribe();
    };
    AlertsFields.prototype.shouldShowServiceSources = function () {
        return this.appContextService.isMtp;
    };
    AlertsFields.prototype.shouldShowTagsColumn = function () {
        return this.featuresService.isEnabled(Feature.K8SMigrationIncidentQueue);
    };
    AlertsFields.prototype.getAllFieldsData = function () {
        var _this = this;
        return {
            expand: {
                id: 'expand',
                name: '',
                enabledByDefault: true,
                alwaysDisplay: true,
                allowResize: false,
                getFieldCssClass: function (alert) { return "" + (alert.isGroup ? 'datatable-expand' : ''); },
                sort: { enabled: false },
                isTabbale: true,
            },
            title: {
                id: 'title',
                name: this.i18nService.get('alerts.fields.title.title'),
                component: {
                    type: AlertNameFieldComponent,
                    getProps: function (item) { return ({ alert: item, showTags: !_this.shouldShowTagsColumn() }); },
                },
                getFieldCssClass: function () { return "datatable-group-item-indent-left title-field"; },
                getDisplay: function (alert) { return alert.name; },
            },
            tags: {
                id: 'tags',
                name: this.i18nService.strings.alerts_fields_tags_title,
                enabledByDefault: true,
                maxWidth: 272,
                minWidth: 48,
                component: {
                    type: TagsFieldComponent,
                    getProps: function (entity) { return _this.entityTagsService.getEntityTagsFieldProps(entity); },
                },
                sort: { enabled: false },
                className: 'nowrap',
                getFieldCssClass: function () { return OVERFLOW_TAGS_CONTAINER_CLASS + " wcd-text-overflow-clip"; },
                custom: {
                    allowFilterValueTracking: true,
                },
            },
            severity: {
                id: 'severity',
                name: this.i18nService.get('alerts.fields.severity.title'),
                enabledByDefault: true,
                getDisplay: function (item) { return _this.i18nService.get(item.severity.nameI18nKey); },
                getCssClass: function (alert) { return "wcd-severity wcd-severity-" + alert.severity.type; },
                custom: {
                    allowFilterValueTracking: true,
                },
                sort: {
                    sortDescendingByDefault: true,
                    getLocalSortValue: function (alert) { return alert.severity.priority * -1; },
                },
                filter: {
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (severityName) {
                                var severity = _this.paris.getValue(Severity, function (_severity) {
                                    return _severity.isSelectable &&
                                        _severity.type === severityName.toLowerCase();
                                });
                                return {
                                    id: severity.name,
                                    rawValue: severity.name,
                                    name: _this.i18nService.get(severity.nameI18nKey),
                                    priority: severity.priority,
                                    nameClass: "wcd-severity wcd-severity-" + severity.type,
                                };
                            },
                        },
                    },
                },
            },
            incident: {
                id: 'incident',
                name: this.i18nService.get('incidents.incident'),
                enabledByDefault: true,
                className: 'nowrap wcd-text-overflow-medium',
                component: {
                    type: IncidentNameComponent,
                    getProps: function (alert) {
                        return {
                            incidentId: alert.incidentId,
                            incidentName: alert.incidentName,
                        };
                    },
                },
                getDisplay: function (alert) {
                    return (alert.incidentName ||
                        (alert.incidentId && _this.i18nService.get('incidents.incidentId', alert)) ||
                        (alert.incidentCount && _this.i18nService.get('incidents.incidentCount', alert)) ||
                        '');
                },
                sort: {
                    enabled: false,
                },
            },
            incidentId: {
                id: 'incidentId',
                name: 'incidentId',
                enabledByDefault: true,
                className: 'nowrap wcd-text-overflow-medium',
                getDisplay: function (alert) { return alert.incidentId; },
                sort: {
                    enabled: false,
                },
            },
            status: {
                id: 'status',
                name: this.i18nService.get('alerts.fields.status.title'),
                getDisplay: function (item) {
                    return item.isMultipleStatuses
                        ? _this.i18nService.get('multiple')
                        : item.status && _this.i18nService.get(item.status.nameI18nKey);
                },
                className: 'nowrap',
                custom: {
                    allowFilterValueTracking: true,
                },
                filter: {
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (statusType) {
                                var status = _this.paris.getValue(AlertStatus, function (_status) { return _status.type === statusType; });
                                return {
                                    id: status.type,
                                    rawValue: status.type,
                                    name: _this.i18nService.get(status.nameI18nKey),
                                    priority: status.priority,
                                };
                            },
                        },
                    },
                },
            },
            classification: {
                id: 'classification',
                name: this.i18nService.get('alerts.fields.classification.title'),
                getDisplay: function (alert) {
                    return alert.isMultipleClassifications
                        ? _this.i18nService.get('multiple')
                        : alert.classification
                            ? _this.i18nService.get(alert.classification.nameI18nKey)
                            : _this.i18nService.get('alerts.unclassified');
                },
                className: 'nowrap',
                custom: {
                    allowFilterValueTracking: true,
                },
                filter: {
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (classificationType) {
                                var classification = _this.paris.getValue(AlertClassification, function (_classification) { return _classification.type === classificationType; });
                                return {
                                    id: classification.type,
                                    rawValue: classification.type,
                                    name: _this.i18nService.get(classification.nameI18nKey),
                                    priority: classification.priority,
                                };
                            },
                        },
                    },
                },
            },
            determination: {
                id: 'determination',
                name: this.i18nService.get('alerts.fields.determination.title'),
                getDisplay: function (alert) {
                    var alertDetermination = alert.determination;
                    return alert.isMultipleDeterminations
                        ? _this.i18nService.get('multiple')
                        : alertDetermination
                            ? _this.i18nService.get(alert.determination.nameI18nKey)
                            : _this.i18nService.get('alerts.unclassified');
                },
                className: 'nowrap',
                enabledByDefault: false,
                custom: {
                    allowFilterValueTracking: true,
                },
            },
            investigationStates: {
                id: 'investigationStates',
                name: this.i18nService.get('alerts.fields.investigationState.title'),
                description: this.appConfigService.isAutomatedIrEnabled
                    ? null
                    : this.i18nService.get('enableAirs.description'),
                headerClass: this.appConfigService.isAutomatedIrEnabled ? null : 'disabled',
                className: 'nowrap',
                custom: {
                    allowFilterValueTracking: true,
                },
                filter: {
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (statusId) {
                                var statusIdInt = parseInt(statusId, 10);
                                if (!isNaN(statusIdInt)) {
                                    var status_1 = _this.paris.getValue(InvestigationStatus, statusIdInt);
                                    if (status_1) {
                                        return {
                                            icon: status_1.iconName && {
                                                wcdIconName: _this.iconsService.getIcon(status_1.iconName).name,
                                                className: "color-text-" + status_1.className,
                                            },
                                            image: status_1.image,
                                            name: _this.i18nService.strings['investigations_status_' + status_1.type + '_title'] || status_1.name,
                                            id: status_1.id,
                                            priority: status_1.priority,
                                        };
                                    }
                                }
                                return null;
                            },
                        },
                    },
                },
            },
            linkedBy: {
                id: 'linkedBy',
                name: this.i18nService.get('alerts.fields.linkedBy.title'),
                className: 'nowrap',
                component: {
                    type: AlertReasonsFieldComponent,
                    getProps: function (alert) { return ({ alert: alert }); },
                },
                sort: { enabled: false },
            },
            category: {
                id: 'category',
                name: this.i18nService.get('alerts.fields.category.title'),
                className: 'nowrap',
                getDisplay: function (alert) {
                    if (alert.isGroup)
                        return alert.groupedBy
                            ? _this.i18nService.get('alerts.fields.category.content.grouped', {
                                category: _this.i18nService.get(alert.groupedBy.nameI18nKey),
                            })
                            : null;
                    return (alert.categories &&
                        alert.categories
                            .map(function (x) { return _this.i18nService.get('reporting.alertsByCategory.' + lowerFirst(x)); })
                            .join(', '));
                },
                filter: {
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (categoryType) {
                                var category = _this.paris.getValue(AlertCategory, function (category) { return category.type === categoryType; });
                                return {
                                    id: category.type,
                                    name: _this.i18nService.get("reporting.alertsByCategory." + category.type),
                                };
                            },
                        },
                    },
                },
            },
            impactedEntities: {
                id: 'impactedEntities',
                name: this.i18nService.get('alerts.fields.impactedEntities.title'),
                className: 'wcd-text-overflow-medium',
                component: {
                    type: ImpactedEntitiesCounterComponent,
                    getProps: function (alert) {
                        var machine = 0;
                        var aaduser = 0;
                        var mailbox = 0;
                        var app = 0;
                        if (alert.impactedEntities) {
                            machine =
                                alert.impactedEntities.machineCount ||
                                    (alert.impactedEntities.machines && alert.impactedEntities.machines.length) ||
                                    0;
                            aaduser =
                                alert.impactedEntities.userCount ||
                                    (alert.impactedEntities.users && alert.impactedEntities.users.length) ||
                                    0;
                            mailbox =
                                alert.impactedEntities.mailboxCount ||
                                    (alert.impactedEntities.mailboxes &&
                                        alert.impactedEntities.mailboxes.length) ||
                                    0;
                            app =
                                alert.impactedEntities.appCount ||
                                    (alert.impactedEntities.apps && alert.impactedEntities.apps.length) ||
                                    0;
                        }
                        return {
                            entities: alert.impactedEntities,
                            entitiesCount: { machine: machine, aaduser: aaduser, mailbox: mailbox, app: app },
                        };
                    },
                },
                sort: { enabled: false },
                enabledByDefault: true,
            },
            machine: {
                id: 'machine',
                name: this.i18nService.get('alerts.fields.device.title'),
                className: 'nowrap wcd-text-overflow-medium',
                getDisplay: function (alert) {
                    var localizedContent = alert.machineCount > 1
                        ? _this.i18nService.get('alerts.fields.device.content.plural')
                        : _this.i18nService.get('alerts.fields.device.content.singular');
                    return alert.machine
                        ? _this.globalEntityTypes.getEntityName(Machine, alert.machine).toLowerCase()
                        : alert.machineCount
                            ? alert.machineCount + " " + localizedContent
                            : '';
                },
                icon: {
                    fabricIcon: function (alert) {
                        return alert.machine || alert.machineCount
                            ? _this.globalEntityTypes.getEntityTypeIcon(Machine)
                            : null;
                    },
                },
                getTooltip: function (alert) {
                    return alert.machine
                        ? _this.globalEntityTypes.getEntityName(Machine, alert.machine).toLowerCase()
                        : null;
                },
                sort: { enabled: false },
            },
            user: {
                id: 'user',
                name: this.i18nService.get('alerts.fields.user.title'),
                className: 'nowrap wcd-text-overflow-medium',
                getDisplay: function (item) {
                    var localizedContent = item.userCount > 1
                        ? _this.i18nService.get('alerts.fields.user.content.singular')
                        : _this.i18nService.get('alerts.fields.user.content.plural');
                    return item.user
                        ? item.user.fullName.toLowerCase()
                        : item.userCount
                            ? item.userCount + " " + localizedContent
                            : '';
                },
                icon: {
                    fabricIcon: function (alert) {
                        return alert.user || alert.userCount ? FabricIconNames.Contact : null;
                    },
                },
                getTooltip: function (alert) { return (alert.user ? alert.user.fullName.toLowerCase() : null); },
                sort: { enabled: false },
                description: this.i18nService.get('alerts.offendingProcessLaunch.description'),
            },
            AssignedTo: {
                id: 'AssignedTo',
                name: this.i18nService.get('alerts.fields.assignedTo.title'),
                getDisplay: function (item) {
                    return item.assignedTo
                        ? item.assignedTo
                        : item.assignedToCount > 1
                            ? _this.i18nService.get('alerts_fields_assignedTo_multiple', {
                                count: item.assignedToCount,
                            })
                            : _this.i18nService.get('alerts.fields.assignedTo.content.unassigned');
                },
                getCssClass: function (alert) { return (alert.assignedTo ? null : 'subtle'); },
                filter: {
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (assignTo) {
                                var currentUserName = _this.authService.currentUser.username.toLowerCase();
                                var isAutomation = assignTo === 'Automation';
                                return {
                                    id: assignTo ? (isAutomation ? 'Automation' : currentUserName) : null,
                                    name: assignTo
                                        ? isAutomation
                                            ? _this.i18nService.get('alerts.fields.assignedTo.content.automation')
                                            : _this.i18nService.get('alerts.fields.assignedTo.content.me')
                                        : "(" + _this.i18nService.get('alerts.fields.assignedTo.content.other') + ")",
                                };
                            },
                        },
                    },
                },
            },
            serviceSource: {
                id: 'serviceSource',
                name: this.i18nService.get('alerts.fields.serviceSource.title'),
                className: 'nowrap',
                getDisplay: function (item) {
                    return item.serviceSource
                        ? _this.i18nService.strings["shorthand_" + item.serviceSource.nameI18nKey] ||
                            _this.i18nService.get(item.serviceSource.nameI18nKey)
                        : null;
                },
                enabledByDefault: true,
                sort: { enabled: false },
                filter: getServiceSourceFilterConfig(),
            },
            detectionSource: {
                id: 'detectionSource',
                name: this.i18nService.get('alerts.fields.detectionSource.title'),
                className: 'nowrap',
                getDisplay: function (item) {
                    return item.detectionSource
                        ? _this.i18nService.strings["shorthand_" + item.detectionSource.nameI18nKey] ||
                            _this.i18nService.get(item.detectionSource.nameI18nKey)
                        : _this.i18nService.get('notAvailable.short');
                },
                enabledByDefault: false,
                custom: {
                    allowFilterValueTracking: true,
                },
                filter: {
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (detectionSourceId) {
                                return _this.paris.getItemById(DetectionSource, detectionSourceId).pipe(map(function (detectionSource) {
                                    return {
                                        id: detectionSource.id,
                                        name: _this.i18nService.get(detectionSource.nameI18nKey),
                                        priority: detectionSource.priority,
                                    };
                                }));
                            },
                        },
                    },
                },
            },
            firstSeen: {
                id: 'firstSeen',
                name: this.i18nService.get('alerts.fields.timeInQueue.title'),
                className: 'nowrap',
                enabledByDefault: false,
                getDisplay: function (item) {
                    if (!item.firstSeen) {
                        return null;
                    }
                    var timeSeconds = (new Date().valueOf() - item.firstSeen.valueOf()) / 1000;
                    return Prettify.prettyTime(timeSeconds);
                },
                sort: {
                    flipSortDirection: true,
                    getLocalSortValue: function (alert) {
                        return alert.firstSeen ? alert.firstSeen.valueOf() * -1 : null;
                    },
                },
            },
            osPlatform: {
                id: 'osPlatform',
                name: this.i18nService.get('alerts.fields.osPlatform.title'),
                getDisplay: function (item) { return item.machine && item.machine.os && item.machine.os.platform; },
                filterOnly: true,
                custom: {
                    allowFilterValueTracking: true,
                },
                filter: {
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (category) {
                                var osPlatform = _this.machinesFiltersService.osPlatformCategoriesMap[category];
                                return {
                                    id: osPlatform.values.map(function (value) { return value.id; }).join(','),
                                    name: osPlatform.name,
                                    priority: osPlatform.priority,
                                };
                            },
                        },
                    },
                },
                sort: { enabled: false },
            },
            firstEventTime: {
                id: 'firstEventTime',
                name: this.i18nService.get('alerts.fields.firstActivity.title'),
                className: 'nowrap',
                sort: {
                    sortDescendingByDefault: true,
                },
                component: {
                    type: TzDateComponent,
                    getProps: function (alert) { return ({ date: alert.firstEventTime }); },
                },
            },
            lasteventtime: {
                id: 'lasteventtime',
                name: this.i18nService.get('alerts.fields.lastActivity.title'),
                className: 'nowrap',
                sort: {
                    sortDescendingByDefault: true,
                    getLocalSortValue: 'lasteventtime',
                },
                component: {
                    type: TzDateComponent,
                    getProps: function (item) { return ({ date: item.lastEventTime }); },
                },
            },
            rbacGroup: {
                id: 'rbacGroup',
                name: this.i18nService.get('alerts.fields.deviceGroup.title'),
                getDisplay: function (alert) {
                    var groupName = !alert.machineGroup
                        ? null
                        : alert.machineGroup.isUnassignedMachineGroup
                            ? _this.i18nService.get('alerts.fields.deviceGroup.content_unassigned')
                            : alert.machineGroup.name;
                    return (groupName ||
                        (alert.machineGroupCount
                            ? alert.machineGroupCount + " " + _this.i18nService.get(alert.machineGroupCount > 1
                                ? 'alerts.groupPlural'
                                : 'alerts.groupSingular')
                            : ''));
                },
                className: 'nowrap',
                sort: { enabled: false },
            },
            IoaDefinitionIds: {
                id: 'IoaDefinitionIds',
                name: this.i18nService.get('alerts.fields.associatedThreat.title'),
                className: 'nowrap',
                filterOnly: true,
                filter: {
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (outbreakId) {
                                return _this.paris.getItemById(Outbreak, outbreakId).pipe(map(function (outbreak) {
                                    return outbreak
                                        ? {
                                            id: outbreak.id,
                                            name: outbreak.displayName,
                                        }
                                        : null;
                                }));
                            },
                        },
                    },
                },
            },
        };
    };
    AlertsFields.prototype.alertFieldsByLinkedEntity = function () {
        var fieldsData = this.getAllFieldsData();
        return DataviewField.fromList([
            fieldsData.title,
            fieldsData.impactedEntities,
            fieldsData.lasteventtime,
        ]);
    };
    AlertsFields.prototype.getInvestigationExtraData = function () {
        var _this = this;
        return {
            enabled: {
                getImage: function (item) { return item.investigationStatus && item.investigationStatus.image; },
                icon: {
                    wcdIcon: function (alert) {
                        var iconName = alert.investigationStatus &&
                            alert.investigationStatus.investigationQueued &&
                            alert.investigationStatus.iconName;
                        return iconName && WcdIconNames[_this.iconsService.getIcon(iconName).name];
                    },
                    className: function (alert) {
                        return alert.investigationId && alert.investigationStatus
                            ? "color-text-" + alert.investigationStatus.className
                            : null;
                    },
                },
                getDisplay: function (item) {
                    return item.investigationCount > 1
                        ? item.investigationCount + " investigations"
                        : item.investigationStatus
                            ? item.investigationStatus.name
                            : _this.i18nService.get('notAvailable.short');
                },
                getLink: function (item) { return item.investigationId && "/investigation/" + item.investigationId; },
            },
            disabled: {
                getDisplay: function () { return _this.i18nService.get('notAvailable.short'); },
                tooltip: this.i18nService.get('enableAirs.description'),
                className: 'nowrap',
            },
        };
    };
    return AlertsFields;
}());
export { AlertsFields };
