/* tslint:disable:template-click-events-have-key-events */
import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { MessageBarType } from 'office-ui-fabric-react';
import { SHARED_FORM_PROVIDER, WizardBaseStep } from '@wcd/wizard';
import { SnmpAuth, GetNetworkScanAgentsList } from '@wcd/domain';
import { NgForm } from '@angular/forms';
import { RegExpService } from '@wcd/shared';
import { Paris } from '@microsoft/paris';
import { AssessmentJobModel } from '../../models/assessment-job.model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AssessmentJobService } from '../../services/assessment-job.service';

@Component({
	viewProviders: [SHARED_FORM_PROVIDER],
	templateUrl: './assessment-job-job-details-step.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentJobJobDetailsStepComponent extends WizardBaseStep<AssessmentJobModel>
	implements OnInit {
	@ViewChild('snmpAuthTypeForm', { static: false }) snmpAuthTypeForm: NgForm = new NgForm([], []);

	MessageBarType = MessageBarType;
	agentNamesList: Array<string> = [];
	agentsList: Array<{ id: string; machineName: string }> = [];
	readonly RegExpService = RegExpService;

	isSnmpEditMode = false;

	targetChanged$: Subject<void> = new Subject();

	constructor(private paris: Paris, public assessmentJobService: AssessmentJobService) {
		super();
	}

	ngOnInit(): void {
		this.setStepValidation(true);

		this.paris.apiCall(GetNetworkScanAgentsList).subscribe(agents => {
			agents.map(agent => {
				this.agentsList.push({ id: agent.id, machineName: agent.machineName });
				this.agentNamesList.push(agent.machineName);
			});
		});

		this.isSnmpEditMode = this.data.isNewDraft || this.data.overrideAuth;

		// New scan, first time arriving to this step
		if (this.data.isNewDraft && !this.data.assessmentJob.agentId) {
			this.setStepValidation(false);
			this.data.assessmentJob.snmpAuth = new SnmpAuth();
		}

		this.targetChanged$.pipe(debounceTime(50)).subscribe(() => {
			const targetAddresses = this.data.assessmentJob.originalTargetRanges.split(/,\s*|\s+/);
			this.setStepValidation(
				this.data.assessmentJob.snmpAuth.type &&
					targetAddresses.every(address => this.RegExpService.ipv4OrRangeCidr.test(address))
			);
		});
	}

	onAgentNameChange(agentName: string): void {
		this.data.assessmentJob.agentId = this.getAgentIdByAgentName(agentName);
		this.data.requiredNewScan = true;
	}

	editExistingSnmpAuth(): void {
		this.data.assessmentJob.snmpAuth = new SnmpAuth();
		this.isSnmpEditMode = true;
		this.data.overrideAuth = true;
		this.setStepValidation(false);
	}

	authTypeChanged(snmpAuth: SnmpAuth): void {
		this.data.requiredNewScan = true;
		// in case the form just init it will be marked as valid
		// validating out self that it has any value
		const formHasValues = snmpAuth.username != null || snmpAuth.communityString != null;

		this.setStepValidation(formHasValues && !this.snmpAuthTypeForm.form.invalid);
	}

	getAgentIdByAgentName(name: string): string {
		const agent = this.agentsList.filter(x => x.machineName === name);
		if (agent) {
			return agent[0].id;
		} else {
			return null;
		}
	}

	onAssessmentJobChange() {
		this.data.requiredNewScan = true;
		this.targetChanged$.next();
	}
}
