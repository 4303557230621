
    <ng-container *ngIf="!loadRuleErrorMessageKey; else errorMessage">
        <div *ngIf="!isLoading; else loader">
            <dl [class.key-values]="asKeyValueList">
                <dt>{{'scheduledHunting_fields_RuleName_title' | i18n}}</dt>
                <dd><a href="{{'/custom_detection/' + this.huntingRule.id}}">{{huntingRule.name}}</a></dd>
                
                <dt>{{'scheduledHunting_fields_isEnabled_title' | i18n}}</dt>
                <dd>{{'scheduledHunting_fields_isEnabled_Rule' + (huntingRule?.isEnabled ? 'On' : 'Off') | i18n}}</dd>

                <ng-container *ngIf="machineGroupsDisplay">
                    <dt>{{'hunting.scheduledMonitorSidePane.fields.machineGroups.sectionTitle' | i18n}}</dt>
                    <dd>{{machineGroupsDisplay}}</dd>
                </ng-container>

                <dt>{{ "scheduledHunting.fields.lastRunTime.title" | i18n}}</dt>
                <dd>{{huntingRule.lastRunTime | date:'medium'}}</dd>
                
                <ng-container *ngIf="huntingRule.lastRunStatus as lastRunStatus">
                    <dt>{{ "scheduledHunting.fields.lastRunStatus.title" | i18n}}</dt>
                    <dd>
                        <fab-icon *ngIf="lastRunStatus.iconName"
                            [iconName]="lastRunStatus.iconName"
                            [contentClass]="'field-value-icon-base' + (lastRunStatus.className ? ' color-text-' + lastRunStatus.className	: '')">
                        </fab-icon>
                        <img *ngIf="lastRunStatus.image"
                            [attr.src]="lastRunStatus.image | basePath"
                            [ngClass]="'field-value-icon-base'"
                            alt="" />
                        <span [ngClass]="(lastRunStatus.isError && lastRunStatus.className ? 'color-text-' + lastRunStatus.className : '')">
                            {{lastRunStatusText}}
                        </span>
                    </dd>
                </ng-container>
                
                <dt>{{ "scheduledHunting.fields.nextRunTime.title" | i18n}}</dt>
                <dd>{{huntingRule.nextRunTime | date:'medium'}}</dd>

            </dl>
        </div>
    </ng-container>

    <ng-template #loader>
        <span>
            <i class="loader-icon"></i>
        </span>
    </ng-template>
    
    <ng-template #errorMessage>
        <span>{{loadRuleErrorMessageKey | i18n}}</span>
    </ng-template>
    