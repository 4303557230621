/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i2 from "../../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i3 from "../../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i4 from "./machine-group-edit-enhanced.component.ngfactory";
import * as i5 from "../../services/machine-groups.service";
import * as i6 from "@angular/common/http";
import * as i7 from "../../../../../../../../projects/app-config/src/lib/service-urls/services/service-urls.service";
import * as i8 from "@microsoft/paris/dist/lib/paris";
import * as i9 from "./machine-group-edit-enhanced.component";
import * as i10 from "../../../../dialogs/services/dialogs.service";
import * as i11 from "@angular/router";
import * as i12 from "../../../../../../../../projects/auth/src/lib/services/auth.service";
import * as i13 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i14 from "../../../../../../../../projects/config/src/lib/services/features.service";
import * as i15 from "../../../../../../../../projects/config/src/lib/services/app-flavor.service";
import * as i16 from "../../../../../../../../projects/app-config/src/lib/app-config/services/app-config.service";
import * as i17 from "./machine-group-edit-enhanced.panel.component";
var styles_MachineGroupEditEnhancedPanelComponent = [];
var RenderType_MachineGroupEditEnhancedPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MachineGroupEditEnhancedPanelComponent, data: {} });
export { RenderType_MachineGroupEditEnhancedPanelComponent as RenderType_MachineGroupEditEnhancedPanelComponent };
export function View_MachineGroupEditEnhancedPanelComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { machineGroupEdit: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 7, "wcd-panel", [], null, [[null, "close"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_PanelComponent_0, i1.RenderType_PanelComponent)), i0.ɵdid(3, 114688, null, 1, i2.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.PanelService], { canDeactivate: [0, "canDeactivate"], settings: [1, "settings"] }, { close: "close" }), i0.ɵqud(603979776, 2, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, 1, 2, "machine-group-edit-enhanced", [["class", "wcd-full-height"]], null, [[null, "saveChanges"], [null, "cancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("saveChanges" === en)) {
        var pd_0 = (_co.saveChanges($event) !== false);
        ad = (pd_0 && ad);
    } if (("cancel" === en)) {
        var pd_1 = (_co.cancel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_MachineGroupEditEnhancedComponent_0, i4.RenderType_MachineGroupEditEnhancedComponent)), i0.ɵprd(512, null, i5.MachineGroupsService, i5.MachineGroupsService, [i6.HttpClient, i7.ServiceUrlsService, i8.Paris]), i0.ɵdid(8, 114688, [[1, 4]], 0, i9.MachineGroupEditEnhancedComponent, [i8.Paris, i10.DialogsService, i5.MachineGroupsService, i11.Router, i12.AuthService, i13.I18nService, i14.FeaturesService, i15.FlavorService, i16.AppConfigService], { machineGroup: [0, "machineGroup"] }, { saveChanges: "saveChanges", cancel: "cancel" }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.boundCanDeactivate; var currVal_1 = _co.settings; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.machineGroup; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_MachineGroupEditEnhancedPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "machine-group-edit-enhanced-panel", [], null, null, null, View_MachineGroupEditEnhancedPanelComponent_0, RenderType_MachineGroupEditEnhancedPanelComponent)), i0.ɵdid(1, 245760, null, 0, i17.MachineGroupEditEnhancedPanelComponent, [i11.Router, i10.DialogsService, i13.I18nService, i8.Paris], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MachineGroupEditEnhancedPanelComponentNgFactory = i0.ɵccf("machine-group-edit-enhanced-panel", i17.MachineGroupEditEnhancedPanelComponent, View_MachineGroupEditEnhancedPanelComponent_Host_0, { settings: "settings", onDone: "onDone", onCancel: "onCancel", machineGroup: "machineGroup" }, { onSaveChanges: "onSaveChanges" }, []);
export { MachineGroupEditEnhancedPanelComponentNgFactory as MachineGroupEditEnhancedPanelComponentNgFactory };
