export enum SuppressionRulePredicateValueType {
	Unknown = 'Unknown',
	FileName = 'FileName',
	FolderPath = 'FolderPath',
	Sha1 = 'Sha1',
	Ip = 'Ip',
	Url = 'Url',
	CommandLine = 'CommandLine',
	UserSid = 'UserSid',
	SenseMachineId = 'SenseMachineId',
	RbacGroupId = 'RbacGroupId'
}
