import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { FabricIconNames } from '@wcd/scc-common';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { Lazy } from '../../../utils/lazy';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AadUserEntityPanelComponent } from './components/aad-user-entity-panel.component';
import {
	AadUser,
	AadUserLegacyUserProfileRelationship,
	LegacyUserProfile,
	AirsEntityType,
	Incident,
} from '@wcd/domain';
import { AadUserEntityDetailsComponent } from '../../../global_entities/components/entity-details/aad-user/aad-user-entity-details.component';
import { AadUserStatusComponent } from './components/aad-user-entity-status.component';
import { FeaturesService, Feature } from '@wcd/config';
import { Router } from '@angular/router';
import { ItemActionModelConfig, ItemActionModel } from '../../../dataviews/models/item-action.model';
import { compact } from 'lodash-es';
import { GoHuntService } from '../../../hunting-go-hunt/services/go-hunt.service';
import { RoutesService } from '@wcd/shared';

export type AadUserEntityTypeOptions = {
	incident?: Incident;
};

@Injectable()
export class AadUserEntityTypeService implements EntityTypeService<AadUser> {
	private readonly _entityType = new Lazy<Readonly<EntityType<AadUser>>>(() => ({
		id: 'aaduser',
		entity: AadUser,
		icon: FabricIconNames.Contact,
		getPanelImage: users => {
			if (!users.length) {
				return null;
			}

			return this.paris
				.getRelatedItem<AadUser, LegacyUserProfile>(AadUserLegacyUserProfileRelationship, users[0])
				.pipe(map(userProfile => userProfile.thumbnailPhotoSrc));
		},
		getImageCssClass: users => 'wcd-circle aaduser-image',
		singleEntityPanelComponentType: AadUserEntityPanelComponent,
		entityPanelStatusComponentType: AadUserStatusComponent,
		entityDetailsComponentType: AadUserEntityDetailsComponent,
		loadFullEntityInPanel: false,
		getEntityName: user => user.displayName || user.sid || user.aadUserId,
		entityPluralNameKey: 'user_entityType_pluralName',
		entitySingularNameKey: 'user_entityType_singularName',
		getEntitiesLink: users => {
			if (!users || users.length !== 1) {
				return null;
			}

			const [user] = users;
			return user.userPageUrl;
		},
		getActions: (users: Array<AadUser>, options: AadUserEntityTypeOptions) => {
			const [user] = users;
			const actions: Array<Observable<ItemActionModelConfig>> = [];

			if (users.length == 1) {
				actions.push(this.createGoHuntFromIncidentAction(user, options));
			}

			return combineLatest(actions).pipe(
				map(actionConfigs => compact(actionConfigs)),
				map(actionsConfigs => actionsConfigs.map(actionConfig => new ItemActionModel(actionConfig)))
			);
		},
		getUseExternalRouting: (users: Array<AadUser>) => true,
		getNavigationModel: (user: AadUser) => {
			const sccRedirectFunc = (path: string) => {
				if (!this.routesService.unifiedUserPageEnabled) {
					return `/#/users/${path.split('#/users/')[1]}`;
				}
				return user.aadUserId
					? `/user/?aad=${user.aadUserId}`
					: user.sid
						? `/user/?sid=${user.sid}`
						: `/user/?accountName=${user.accountName}&accountDomain=${user.domainName}`;
			}
			return {
				externalLink: user.userPageUrl,
				sccInternalRedirectDefinition: this.isMcasInternalRoutingEnabled()
					? sccRedirectFunc
					: undefined,
			};
		},
	}));

	constructor(
		private readonly paris: Paris,
		private featuresService: FeaturesService,
		private router: Router,
		private routesService: RoutesService,
		private readonly goHuntService: GoHuntService
	) {}

	get entityType(): Readonly<EntityType<AadUser>> {
		return this._entityType.value;
	}

	isMcasInternalRoutingEnabled(): boolean {
		return (
			this.routesService.unifiedUserPageEnabled &&
			this.router.url.startsWith('/incidents')
		);
	}

	private createGoHuntFromIncidentAction(
		user: AadUser,
		options?: AadUserEntityTypeOptions
	): Observable<ItemActionModelConfig> {
		return this.goHuntService.getGoHuntActionObservable(user, AirsEntityType.User, {
			incident: options && options.incident,
		});
	}
}
