import { Routes } from '@angular/router';
import { AdminComponent } from './_base/components/admin.component';
import { getSystemExclusionPageTitleKey, systemExclusionsRoutes, } from '../@entities/system_exclusions/system-exclusions.module';
import { FolderExclusionsComponent } from './folder_exclusions/components/folder-exclusions.component';
import { AdvancedSettingsComponent } from './advanced/components/advanced-settings.component';
import { CanDeactivateForm } from '../shared/guards/can-deactivate-form.guard';
import { UserRolesDataviewComponent } from '../@entities/rbac/components/user_roles/user-roles.dataview';
import { SuppressionRulesDataviewComponent } from '../@entities/suppression_rules/components/suppression-rules.dataview';
import { EndpointManagementPageComponent } from '../endpoint_management/components/endpoint-management-page.component';
import { IntegrationSettingsComponent } from './integration-settings/integration-settings.component';
import { AirsEnabledGuard, EnableAutomatedIrComponent, Feature } from '@wcd/config';
import { NotificationRulesDataviewComponent } from './notification_rules/components/notification-rules.dataview';
import { ComponentLoading } from '../shared/main/services/main-app-state.service';
import { ScheduledHuntingDataviewComponent } from '../@entities/scheduled_hunting/components/scheduled-hunting.dataview';
import { MdeAllowedActionsGuard, AppConfigValueGuard, AuthenticatedGuard, ExposedToAllMachineGroupsGuard, FeatureEnabledGuard, IsServiceNowIntegrationEnabled, OnboardGuard, MdatpGuard, FlavorGuard, UnifiedRbacPermission, UnifiedRbacGuard, TvmLicenseMigrationFlavorGuard, } from '@wcd/auth';
import { GeneralSettingsComponent } from '../admin/general/general-settings.component';
import { getCustomTiIndicatorPageTitleKey } from '../@entities/custom_ti_indicators/custom-ti-indicators.module';
import { HuntingRule, SuppressionRule, MdeUserRoleActionEnum, MtpWorkload } from '@wcd/domain';
import { ServiceNowProvisioningComponent } from '../@entities/@tvm/remediation/itsm-provisioning/components/servicenow-provisioning.component';
import { NetworkScansDataviewComponent } from '../@entities/@tvm/network-scans/components/network-scans.dataview.component';
import { MachineGroupsRemediationLevelDataviewComponent } from '../@entities/rbac/components/machine_groups/machine-groups-remediation-level.dataview';
import { MachineGroupsDataviewComponent } from '../@entities/rbac/components/machine_groups/machine-groups.dataview';
import { AuthCodeRetrievalComponent } from './auth-code-retrieval/auth-code-retrieval.component';
import { HistorySafeRedirectComponent } from '../shared/components/history-safe-redirect.component';
import { PortalRedirectionComponent } from './portal-redirection/components/portal-redirection.component';
import { SccMdeAttachSectionComponent } from './mde-attach/components/mde-attach.component';
import { LogicalOperator } from '@wcd/shared';
import { AppFlavorConfig } from '@wcd/scc-common';
import { MixedLicensesWidgetComponent } from './smb/components/licenses-widget.component';
import { TvmLicenseType } from '@wcd/scc-common';
var adminSections = {
    api: 'settings_section_apis',
    general: 'settings_section_general',
    machineManagement: 'settings_section_deviceManagement',
    permissions: 'settings_section_permissions',
    rules: 'settings_section_rules',
    dev: 'settings_section_development',
    itsmProvisioning: 'settings_section_itServiceManagement',
    networkGear: 'settings_section_networkAssessments',
    mdeAttach: 'settings_section_mde_attach',
};
var ɵ0 = {
    pageTitleKey: 'settings_navItem_dataRetention',
    section: adminSections.general,
}, ɵ1 = {
    pageTitleKey: 'settings_navItem_licenses',
    section: adminSections.general,
    flavorConfig: AppFlavorConfig.settings.licenses,
    features: [Feature.ShowMixedLicensesStatus],
}, ɵ2 = {
    pageTitleKey: 'settings_navItem_alertNotifications',
    section: adminSections.general,
    features: [Feature.EmailNotifications],
    disableFeatures: [Feature.TvmVulnerabilityNotifications],
}, ɵ3 = {
    pageTitleKey: 'settings_navItem_emailNotifications',
    section: adminSections.general,
    features: [Feature.TvmVulnerabilityNotifications],
    loadingComponentConfig: {
        descriptionKey: 'loading_email_notifications',
        transparent: true,
    },
}, ɵ4 = {
    pageTitleKey: 'settings_navItem_vulnerabilityEmailNotifications',
    hidden: true,
}, ɵ5 = {
    pageTitleKey: 'settings_navItem_advancedFeatures',
    section: adminSections.general,
    badgeId: 'settings',
}, ɵ6 = {
    hidden: true,
}, ɵ7 = {
    pageTitleKey: 'settings_navItem_roles',
    section: adminSections.permissions,
    features: [Feature.RbacUserRoles],
    disableIfNoAccessToAllGroups: true,
    flavorConfig: AppFlavorConfig.settings.permissions,
}, ɵ8 = {
    pageTitleKey: 'settings_navItem_deviceGroups',
    section: adminSections.permissions,
    features: [Feature.RbacMachineGroups],
    disableIfNoAccessToAllGroups: true,
    flavorConfig: AppFlavorConfig.settings.deviceGroups,
    UnifiedRbacPermissionGuardConfig: {
        UnifiedRbacPermission: UnifiedRbacPermission.ConfigurationAuthorizationRead,
        Workload: MtpWorkload.Mdatp,
    },
}, ɵ9 = {
    pageTitleKey: 'settings_navItem_siem',
    section: adminSections.api,
    features: [Feature.SiemOnboardingMssp],
    loadingComponentConfig: {
        id: ComponentLoading.settings,
    },
}, ɵ10 = {
    id: 'preferences.siemconnector',
    pageTitleKey: 'settings_navItem_siem',
    isLegacy: true,
    section: adminSections.api,
    features: [Feature.SiemOnboarding],
    disableFeatures: [Feature.SiemOnboardingMssp],
}, ɵ11 = {
    entity: HuntingRule,
    breadcrumbsConfig: {
        resetListOnEnter: true,
        show: false,
    },
    pageTitleKey: 'settings_navItem_customDetections',
    section: adminSections.rules,
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
    flavorConfig: AppFlavorConfig.settings.hunting,
}, ɵ12 = {
    entity: SuppressionRule,
    breadcrumbsConfig: {
        resetListOnEnter: true,
        show: false,
    },
    pageTitleKey: 'settings_navItem_alertSuppression',
    section: adminSections.rules,
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
}, ɵ13 = {
    getPageTitleKey: getCustomTiIndicatorPageTitleKey,
    section: adminSections.rules,
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData | MdeUserRoleActionEnum.tvmViewData,
    },
    features: [Feature.TiIndicators],
}, ɵ14 = {
    getPageTitleKey: getSystemExclusionPageTitleKey,
    section: adminSections.rules,
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
    enableAirsRoute: [
        '/preferences2/enable_airs',
        { messageClass: 'relative enable-auto-ir-investigation-message' },
    ],
    disableIfNoAccessToAllGroups: true,
    flavorConfig: AppFlavorConfig.settings.autoIR,
}, ɵ15 = {
    pageTitleKey: 'settings_navItem_webContentFiltering',
    section: adminSections.rules,
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
    features: [Feature.WebContentFilteringPolicy],
}, ɵ16 = {
    pageTitleKey: 'settings_navItem_automationUploads',
    section: adminSections.rules,
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
    enableAirsRoute: [
        '/preferences2/enable_airs',
        { messageClass: 'relative enable-auto-ir-investigation-message' },
    ],
    disableIfNoAccessToAllGroups: true,
    flavorConfig: AppFlavorConfig.settings.autoIR,
}, ɵ17 = {
    pageTitleKey: 'settings_navItem_automationFolderExclusions',
    section: adminSections.rules,
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
    enableAirsRoute: [
        '/preferences2/enable_airs',
        { messageClass: 'relative enable-auto-ir-investigation-message' },
    ],
    disableIfNoAccessToAllGroups: true,
    flavorConfig: AppFlavorConfig.settings.autoIR,
}, ɵ18 = {
    pageTitleKey: 'settings_navItem_mdeAttach',
    section: adminSections.mdeAttach,
}, ɵ19 = {
    pageTitleKey: 'settings_navItem_onboarding',
    section: adminSections.machineManagement,
}, ɵ20 = {
    pageTitleKey: 'settings_navItem_offboarding',
    section: adminSections.machineManagement,
}, ɵ21 = {
    hidden: true,
}, ɵ22 = {
    pageTitleKey: 'settings_navItem_serviceNow',
    section: adminSections.itsmProvisioning,
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.tvmViewData,
    },
    appConfigValidator: IsServiceNowIntegrationEnabled,
}, ɵ23 = {
    pageTitleKey: 'settings_navItem_assessmentJobs',
    section: adminSections.networkGear,
    features: [Feature.TvmNetworkScan],
    flavorConfig: AppFlavorConfig.settings.networkAssessments,
    tvmLicenseType: TvmLicenseType.TvmBasic,
}, ɵ24 = {
    features: [Feature.MachineGroupsRemediationLevelEdit],
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.securitySettings,
    },
    pageTitleKey: 'settings_navItem_autoRemediation',
    section: adminSections.general,
    flavorConfig: AppFlavorConfig.settings.tvm,
}, ɵ25 = {
    features: [Feature.MdatpPortalRedirection, Feature.PortedSccPages],
    featuresLogicalOperator: LogicalOperator.And,
    pageTitleKey: 'settings_navItem_portalRedirection',
    section: adminSections.general,
    flavorConfig: AppFlavorConfig.settings.portalRedirect,
}, ɵ26 = {
    redirectTo: 'general',
};
var availableAdminRoutesChildren = [
    {
        path: 'general',
        component: GeneralSettingsComponent,
        data: ɵ0,
    },
    {
        path: 'licenses',
        component: MixedLicensesWidgetComponent,
        canActivate: [FlavorGuard, FeatureEnabledGuard],
        data: ɵ1,
    },
    {
        path: 'alert_notifications',
        component: NotificationRulesDataviewComponent,
        canActivate: [FeatureEnabledGuard],
        data: ɵ2,
    },
    {
        path: 'email_notifications',
        loadChildren: 'app/admin/email-notifications/email-notifications.module#EmailNotificationsModule',
        canActivate: [FeatureEnabledGuard],
        data: ɵ3,
    },
    {
        path: 'vulnerability_email_notifications',
        redirectTo: 'email_notifications/vulnerabilities',
        data: ɵ4,
    },
    // {
    // 	path: 'automation_notifications',
    // 	component: AirsNotificationRulesComponent,
    // 	canActivate: [AirsEnabledGuard],
    // 	data: {
    // 		pageTitle: 'Automation notifications',
    // 		section: adminSections.general,
    // 		features: [Feature.AutoInvestigationEnabled],
    // 		enableAirsRoute: [
    // 			'/preferences2/enable_airs',
    // 			{ messageClass: 'relative enable-auto-ir-investigation-message' },
    // 		],
    // 	},
    // },
    {
        path: 'integration',
        component: IntegrationSettingsComponent,
        data: ɵ5,
    },
    {
        path: 'auth_code_retrieval',
        component: AuthCodeRetrievalComponent,
        data: ɵ6,
    },
    {
        path: 'user_roles',
        canActivate: [ExposedToAllMachineGroupsGuard, FlavorGuard],
        component: UserRolesDataviewComponent,
        data: ɵ7,
    },
    {
        path: 'machine_groups',
        canActivate: [ExposedToAllMachineGroupsGuard, FlavorGuard, UnifiedRbacGuard],
        component: MachineGroupsDataviewComponent,
        data: ɵ8,
    },
    {
        path: 'siem',
        loadChildren: 'app/admin/connectors/siem/siem-settings.module#SiemSettingsModule',
        data: ɵ9,
    },
    {
        path: 'legacy',
        redirectTo: '/preferences/siemconnector',
        data: ɵ10,
    },
    // ToDo: remove once Angular package is deprecated.
    {
        path: 'scheduled_hunting',
        component: ScheduledHuntingDataviewComponent,
        canActivate: [AuthenticatedGuard, OnboardGuard, FlavorGuard, MdeAllowedActionsGuard],
        data: ɵ11,
    },
    {
        path: 'alert_suppression',
        component: SuppressionRulesDataviewComponent,
        canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
        data: ɵ12,
    },
    {
        path: 'custom_ti_indicators',
        loadChildren: 'app/@entities/custom_ti_indicators/custom-ti-indicators.module#CustomTiIndicatorsModule',
        canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard, MdatpGuard],
        data: ɵ13,
    },
    {
        path: 'system_exclusions',
        children: systemExclusionsRoutes,
        canActivate: [FlavorGuard, AirsEnabledGuard, ExposedToAllMachineGroupsGuard, MdeAllowedActionsGuard],
        data: ɵ14,
    },
    {
        path: 'web_content_filtering_policy',
        loadChildren: 'app/@entities/web_content_filtering/web-content-filtering-policy.module#WebContentFilteringPoliciesModule',
        canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
        data: ɵ15,
    },
    {
        path: 'advanced',
        component: AdvancedSettingsComponent,
        canActivate: [FlavorGuard, AirsEnabledGuard, ExposedToAllMachineGroupsGuard, MdeAllowedActionsGuard],
        canDeactivate: [CanDeactivateForm],
        data: ɵ16,
    },
    {
        path: 'folder_exclusions',
        component: FolderExclusionsComponent,
        canActivate: [FlavorGuard, AirsEnabledGuard, ExposedToAllMachineGroupsGuard, MdeAllowedActionsGuard],
        data: ɵ17,
    },
    {
        path: 'configuration_management2',
        component: SccMdeAttachSectionComponent,
        data: ɵ18,
    },
    {
        path: 'onboarding',
        component: EndpointManagementPageComponent,
        data: ɵ19,
    },
    {
        path: 'offboarding',
        component: EndpointManagementPageComponent,
        data: ɵ20,
    },
    {
        path: 'enable_airs',
        component: EnableAutomatedIrComponent,
        data: ɵ21,
    },
    {
        path: 'servicenow',
        component: ServiceNowProvisioningComponent,
        canActivate: [MdeAllowedActionsGuard, AppConfigValueGuard],
        data: ɵ22,
    },
    {
        path: 'network_assessments_assessment_jobs',
        canActivate: [FeatureEnabledGuard, TvmLicenseMigrationFlavorGuard],
        component: NetworkScansDataviewComponent,
        data: ɵ23,
    },
    {
        path: 'remediation_levels',
        canActivate: [FeatureEnabledGuard, FlavorGuard, MdeAllowedActionsGuard],
        component: MachineGroupsRemediationLevelDataviewComponent,
        data: ɵ24,
    },
    {
        path: 'portal_redirection',
        canActivate: [FeatureEnabledGuard, FlavorGuard],
        component: PortalRedirectionComponent,
        data: ɵ25,
    },
    {
        path: '',
        component: HistorySafeRedirectComponent,
        data: ɵ26,
        pathMatch: 'full',
    },
];
var ɵ27 = {
    pageTitleIcon: 'settings',
    pageTitle: 'Administration',
};
export var adminRoutes = [
    {
        path: '',
        component: AdminComponent,
        data: ɵ27,
        children: availableAdminRoutesChildren,
    },
];
var AdminRoutingModule = /** @class */ (function () {
    function AdminRoutingModule() {
    }
    return AdminRoutingModule;
}());
export { AdminRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27 };
