import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StoreBackendBase } from '../../../data/models/store.backend.base';
import { DownloadService } from '../../../shared/services/download.service';
import { ServiceUrlsService } from '@wcd/app-config';
import { getUserTimeZone } from '@wcd/localization';
import { Observable } from 'rxjs';

const FOLDER_EXCLUSIONS_API_ENDPOINT = '/acl';

@Injectable()
export class FolderExclusionsBackendService extends StoreBackendBase {
	protected commonParams: { [index: string]: any } = { type: 'folder' };

	constructor(
		http: HttpClient,
		downloadService: DownloadService,
		private serviceUrlsService: ServiceUrlsService
	) {
		super(http, downloadService);
	}

	get apiUrl() {
		return this.serviceUrlsService.automatedIr + FOLDER_EXCLUSIONS_API_ENDPOINT;
	}

	update(id: number | string, data: any, options?: any): Observable<any> {
		return this.http[this.updateMethod](
			`${this.itemApiUrl || this.apiUrl}/${id}?time_offset=${getUserTimeZone()}&type=folder`,
			data
		);
	}
}
