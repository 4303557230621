import { ApiCall, ApiCallModel, DataQuery } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { LiveResponseCommand } from '../entities/live-response-command.entity';

@ApiCall({
	name: 'Move Live Response Command to background',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.automatedIr,
	parseQuery: (command: LiveResponseCommand) => ({
		params: {
			commandId: command.id,
			useV2Api: command.useV2Api,
		},
	}),
	endpoint: (config, query: DataQuery) => {
		const commandId = (<{ [index: string]: string }>query.where).commandId;
		const useV2Api = (<{ [index: string]: string }>query.where).useV2Api;
		const endpoint = useV2Api
			? `v2/live_response/commands/${commandId}/background`
			: `live_response/commands/${commandId}/background`;
		delete query.where['commandId'];
		delete query.where['useV2Api'];
		return endpoint;
	},
	method: 'POST',
})
export class BackgroundCommandApiCall extends ApiCallModel<void, LiveResponseCommand> {}
