import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MessageBarType } from 'office-ui-fabric-react';
import { NextButtonModeEnum, SHARED_FORM_PROVIDER, WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
import { Paris, RelationshipRepository } from '@microsoft/paris';
import {
	EntityType,
	EntityDataViewOptions,
} from '../../../../../global_entities/models/entity-type.interface';
import {
	NetworkScanResult,
	PostNetworkScanReachabilityTest,
	GetNetworkScanReachabilityTestOutput,
	NetworkScanSessionRelationship,
	NetworkScanSession,
	AssessmentJob,
	ResolveEntityURL,
	TvmEndPoint,
	NetworkScanSessionCsvRelationship,
} from '@wcd/domain';
import { DialogsService } from '../../../../../dialogs/services/dialogs.service';
import { interval, Subscription, Observable, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { AssessmentJobService } from '../../services/assessment-job.service';
import { GlobalEntityTypesService } from '../../../../../global_entities/services/global-entity-types.service';
import { DataViewConfig } from '@wcd/dataview';
import { NetworkScanResultFieldsService } from '../../services/network-scan-result.fields.service';
import { DataViewSelectEvent } from '../../../../../dataviews/components/dataview.component';
import { AssessmentJobModel } from '../../models/assessment-job.model';
import { ScanButtonState } from './scan-button-state.enum';
import { SpinnerSize } from 'office-ui-fabric-react';
import { TvmDownloadService } from '../../../../../tvm/services/tvm-download.service';
import { ConfirmEvent } from '../../../../../dialogs/confirm/confirm.event';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
	viewProviders: [SHARED_FORM_PROVIDER],
	templateUrl: './assessment-job-scan-step.component.html',
	styleUrls: ['./assessment-job-scan-step.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentJobScanStepComponent extends WizardBaseStep<AssessmentJobModel>
	implements OnInit, OnDestroy {
	readonly dataViewOptions: EntityDataViewOptions<NetworkScanResult, {}>;
	private readonly reviewStep = 2;
	private readonly getNetworkScanReachabilityTestOutputInterval = 1500;
	private readonly scanProgressMaxWaitingPercentage = 15;
	private readonly scanProgressIncPercentage = 0.3;
	MessageBarType = MessageBarType;
	ScanButtonState = ScanButtonState;
	repository: RelationshipRepository<NetworkScanSession, NetworkScanResult>;
	entityType: EntityType<NetworkScanResult>;
	_testConnectionSubscription: Subscription;
	dataViewConfig: DataViewConfig;
	networkScanSession: NetworkScanSession;
	scanButtonState = ScanButtonState.Start;
	SpinnerSize = SpinnerSize;
	sampledScanIPSuccess: string;
	sampledScanIPFailure: string;
	isSampledScanSuccess: boolean;
	showedSampledScans: Map<string, boolean>;
	scanProgressPercentage: number;
	progressBarLabel: string;
	isWithProgressBar = true;
	isTestScanAllowed: boolean;

	shouldSelectIpAddress: (networkScanResult: NetworkScanResult) => boolean;

	private isSkipped: boolean;

	constructor(
		private i18n: I18nService,
		private paris: Paris,
		private dialogsService: DialogsService,
		private readonly changeDetection: ChangeDetectorRef,
		public assessmentJobService: AssessmentJobService,
		private globalEntityTypesService: GlobalEntityTypesService,
		public fieldsService: NetworkScanResultFieldsService,
		public tvmDownloadService: TvmDownloadService,
		private liveAnnouncer: LiveAnnouncer
	) {
		super();
		this.entityType = this.globalEntityTypesService.getEntityType(NetworkScanResult);
		this.repository = this.paris.getRelationshipRepository(NetworkScanSessionRelationship);

		this.dataViewOptions = {
			...this.entityType.dataViewOptions,
		};
	}

	ngOnInit(): void {
		this.setIsTestAllowed();
		this.setOnNext(this.onNext);
		this.shouldSelectIpAddress = networkScanResult => {
			if (this.data.selectedIps.length === 0 && networkScanResult.scanSuccess) {
				return true;
			}
			return this.data.selectedIps.includes(networkScanResult.ip);
		};

		this.setNextMode();
	}

	setIsTestAllowed(): void {
		this.isTestScanAllowed =
			this.assessmentJobService.countIpAddresses(this.data.assessmentJob.originalTargetRanges) <
			this.assessmentJobService.maxAllowedIpAddressesForScan + 1;
	}

	ngOnDestroy(): void {
		this._testConnectionSubscription && this._testConnectionSubscription.unsubscribe();
	}

	testConnection(): void {
		this.data.canShowScans = false;
		this.data.requiredNewScan = true;
		this.resetProgressBar();
		this.setNextMode();
		this.scanButtonState = ScanButtonState.Stop;
		this.data.startScanTime = new Date().toLocaleString();

		const scan = this.NetworkScanObjectPreProcessingForTestRequest();
		this.paris.apiCall(PostNetworkScanReachabilityTest, scan).subscribe(
			sessionId => {
				this.networkScanSession = new NetworkScanSession();
				this.networkScanSession.id = sessionId;
				this.testConnectionOutput(sessionId);
			},
			error => {
				this.changeDetection.markForCheck();
				this.dialogsService.showError({
					title: this.i18n.get('error.status.code', { status: error.status }),
					data: this.i18n.get('tvm.networkGear.creationPanel.testRunError'),
				});
				this.finishTestConnection();
				this.scanButtonState = ScanButtonState.Start;
			}
		);
		this.changeDetection.detectChanges();
	}

	NetworkScanObjectPreProcessingForTestRequest(): AssessmentJob {
		const scan = this.assessmentJobService.preProcessNetworkScanBeforeSendingToBackend(
			this.data.assessmentJob
		);
		// For test scan we sent all the ranges in target
		scan.target = scan.originalTargetRanges;

		return scan;
	}

	testConnectionOutput(sessionId: string) {
		this.changeDetection.markForCheck();
		this._testConnectionSubscription = interval(this.getNetworkScanReachabilityTestOutputInterval)
			.pipe(flatMap(() => this.paris.apiCall(GetNetworkScanReachabilityTestOutput, sessionId)))
			.subscribe(
				response => {
					if (response.endOfFile) {
						this.scanButtonState = ScanButtonState.Restart;
						this.repository.sourceItem = this.networkScanSession;
						this.data.requiredNewScan = false;
						this.data.canShowScans = true;
						this.setNextMode();
						this.setStepValidation(
							this.data.selectedIps.length > 0 &&
								this.data.selectedIps.join(',').indexOf('/') < 0
						);
						this.setDataViewConfig();
						this.finishTestConnection();
					} else if (response.output.length > 0) {
						//if we are getting an output
						this.refreshProgressBar(response);
					} else if (this.scanProgressPercentage < this.scanProgressMaxWaitingPercentage) {
						//while waiting for the agent to start scanning (can take around 40 seconds)
						this.scanProgressPercentage += this.scanProgressIncPercentage;
						this.changeDetection.markForCheck();
					}
				},
				error => {
					this.dialogsService.showError({
						title: this.i18n.get('error.status.code', { status: error.status }),
						data: this.i18n.get('tvm.networkGear.creationPanel.testOutputError'),
					});
					this.scanButtonState = ScanButtonState.Start;
					this.finishTestConnection();
				}
			);

		//give up after 15 minutes
		setTimeout(() => {
			this.scanButtonState = ScanButtonState.Start;
			this.finishTestConnection();
		}, 900000);
	}

	finishTestConnection() {
		this._testConnectionSubscription && this._testConnectionSubscription.unsubscribe();
		this.changeDetection.markForCheck();
	}

	onStopClick() {
		this.scanButtonState = ScanButtonState.Start;
		this.finishTestConnection();
	}

	onItemSelect($event: DataViewSelectEvent): void {
		this.data.selectedIps = $event.items.map(item => item.ip);

		this.data.assessmentJob.target = this.data.selectedIps.join(', ');

		// Prevent next on empty selection
		this.setStepValidation(this.data.selectedIps.length > 0);
	}

	setDataViewConfig() {
		this.dataViewConfig = {
			showModalOnExport: false,
			exportResults: (options, format, dataQuery) => {
				const url = ResolveEntityURL({
					endPoint: TvmEndPoint.NetworkScans,
					entityModelBaseOrRelationship: NetworkScanSessionCsvRelationship,
					id: this.networkScanSession.id,
				});

				return this.tvmDownloadService.downloadCsv({
					url: url,
					fileName: 'export-network-device-assessment',
					dataQuery: dataQuery,
					alreadyHasQueryParamInUrl: true,
				});
			},
		};
	}

	setNextMode(): void {
		if (!this.isTestScanAllowed) {
			this.setNextButtonMode(NextButtonModeEnum.Normal);
			this.setStepValidation(true);
			this.isSkipped = false;
		} else if (this.data.requiredNewScan) {
			this.setNextButtonMode(NextButtonModeEnum.Skip, 'tvm_networkGear_wizard_scan_step_skip_button');
			this.setStepValidation(true);
			this.isSkipped = true;
		} else {
			this.setNextButtonMode(NextButtonModeEnum.Normal);
			this.setStepValidation(this.data.selectedIps.length > 0);
			this.isSkipped = false;
		}
	}

	onNext = (): Observable<boolean> => {
		// User will not select ips as test scan won't run
		if (!this.isTestScanAllowed) {
			this.data.assessmentJob.target = this.data.assessmentJob.originalTargetRanges;
			return of<boolean>(true);
		}

		if (!this.isSkipped) {
			return of<boolean>(true);
		}

		this.dialogsService
			.confirm({
				title: this.i18n.get('tvm_networkGear_wizard_scan_step_skip_popup_title'),
				text: this.i18n.get('tvm_networkGear_wizard_scan_step_skip_popup_text'),
				confirmText: this.i18n.get('tvm_networkGear_wizard_scan_step_skip_popup_confirm_text'),
			})
			.then((e: ConfirmEvent) => {
				if (e.confirmed) {
					// User will not select ips as test scan won't run
					this.data.assessmentJob.target = this.data.assessmentJob.originalTargetRanges;
					this.goToStep(this.reviewStep);
				}
			});

		return of<boolean>(false);
	};

	private resetProgressBar(): void {
		this.sampledScanIPSuccess = '';
		this.sampledScanIPFailure = '';
		this.showedSampledScans = new Map<string, boolean>();
		this.scanProgressPercentage = 0;
		this.progressBarLabel = this.i18n.get('tvm_networkGear_wizard_scan_step_progress_bar_loading_text');
		this.announceAction('tvm_networkGear_wizard_scan_step_progress_bar_loading_text');
	}

	private refreshProgressBar(response) {
		if (response.totalIpAddresses !== 0) {
			this.progressBarLabel = this.i18n.get('tvm_networkGear_wizard_scan_step_progress_bar_scan_text');
			this.scanProgressPercentage = Math.max(
				(response.scannedIpAddresses * 100) / response.totalIpAddresses,
				this.scanProgressMaxWaitingPercentage
			);
			this.announceAction('tvm_networkGear_wizard_scan_step_progress_bar_scan_text');
			var failureTemp = '';
			var i = 0;
			//search successful scan
			while (i < response.output.length) {
				const scan = response.output[i];
				if (scan.scanSuccess && !this.showedSampledScans[scan.ip]) {
					this.isSampledScanSuccess = true;
					this.sampledScanIPSuccess = scan.ip;
					this.showedSampledScans[scan.ip] = true;
					break;
				} else if (failureTemp === '' && !this.showedSampledScans[scan.ip]) {
					failureTemp = scan.ip;
				}
				i++;
			}
			//no successful scan was found
			if (i === response.output.length) {
				this.isSampledScanSuccess = false;
				this.sampledScanIPFailure = failureTemp;
				this.showedSampledScans[this.sampledScanIPFailure] = true;
			}
		} else {
			this.isWithProgressBar = false;
		}

		this.changeDetection.markForCheck();
	}

	private announceAction(key: string) {
		this.liveAnnouncer.announce(this.i18n.get(key), 'assertive', 300);
	}
}
