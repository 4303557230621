var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType, DataQuerySortDirection, } from '@microsoft/paris';
import { Alert } from '../alert/alert.entity';
import { AlertsSeveritySummary } from '../alert/alerts-severity-summary.value-object';
import { AatpProfile } from '../azure-threat-analytics/aatp-profile.value-object';
import { TagsCollection } from '../tag/tag-collection';
import { MachineIdType } from './machine-id-type.enum';
import { MachineNetworkInfo } from './machine-ip-adapters';
import { MachineSecurityAnalytics } from './machine-security-analytics/machine-security-analytics.value-object';
import { MachineUserDetails } from './machine-user-details';
import { MachineUserDetailsAccount } from './machine-user-details-account.value-object';
import { getMachineRequestIds, Machine } from './machine.entity';
import { DataSensitivity } from '../file/data-sensitivity.value-object';
import { MachineIncidentCount } from './machine-incidents-count.value-object';
import { Incident } from '../incident/incident.entity';
import { CyberEventMark } from '../event/cyber-event-mark.value-object';
import { MachineExclusionDetails } from './machine-exclusion/machine-exclusion-details.value-object';
import { omit } from 'lodash-es';
import { SeenByMachines } from './machine-seen-by.value-object';
var ɵ0 = function (machine) { return getMachineRequestIds(machine); }, ɵ1 = function (_, query) {
    return query.where['isUnifiedDevicePage']
        ? 'TopUsersByIds'
        : "machines/" + query.where['machineId'] + "/TopUsers";
}, ɵ2 = function (config) { return config.data.serviceUrls.threatIntel; };
var MachineUserDetailsRelationship = /** @class */ (function () {
    function MachineUserDetailsRelationship() {
    }
    MachineUserDetailsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Machine,
            dataEntity: MachineUserDetails,
            getRelationshipData: ɵ0,
            endpoint: ɵ1,
            allItemsEndpointTrailingSlash: false,
            fixedData: { lookingBackIndays: 30 },
            allowedTypes: [RelationshipType.OneToOne],
            cache: {
                max: 10,
                time: 1000 * 60,
            },
            baseUrl: ɵ2,
        })
    ], MachineUserDetailsRelationship);
    return MachineUserDetailsRelationship;
}());
export { MachineUserDetailsRelationship };
var ɵ3 = function (machine) { return getMachineRequestIds(machine); }, ɵ4 = function (_, query) { return (query.where['isUnifiedDevicePage'] ? 'MachineLogonUsersByIds' : 'LoggedOnUsers'); }, ɵ5 = function (config) { return config.data.serviceUrls.threatIntel; };
var MachineLoggedOnUsers = /** @class */ (function () {
    function MachineLoggedOnUsers() {
    }
    MachineLoggedOnUsers = __decorate([
        EntityRelationship({
            sourceEntity: Machine,
            dataEntity: MachineUserDetailsAccount,
            getRelationshipData: ɵ3,
            endpoint: ɵ4,
            allItemsEndpointTrailingSlash: false,
            allowedTypes: [RelationshipType.OneToMany],
            fixedData: {
                logonTypes: 'Interactive,RemoteInteractive,Network,Batch,Service',
                lookingBackIndays: 30,
            },
            baseUrl: ɵ5,
        })
    ], MachineLoggedOnUsers);
    return MachineLoggedOnUsers;
}());
export { MachineLoggedOnUsers };
var ɵ6 = function (machine) { return getMachineRequestIds(machine); }, ɵ7 = function (_, query) {
    return "machines/" + query.where['machineId'] + "/eventMarks";
}, ɵ8 = function (config) { return config.data.serviceUrls.threatIntel; };
var MachineCyberEventMarksRelationship = /** @class */ (function () {
    function MachineCyberEventMarksRelationship() {
    }
    MachineCyberEventMarksRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Machine,
            dataEntity: CyberEventMark,
            getRelationshipData: ɵ6,
            endpoint: ɵ7,
            allowedTypes: [RelationshipType.OneToMany],
            baseUrl: ɵ8,
            allItemsProperty: 'Items',
        })
    ], MachineCyberEventMarksRelationship);
    return MachineCyberEventMarksRelationship;
}());
export { MachineCyberEventMarksRelationship };
var ɵ9 = function (machine) { return ({
    WcdMachineId: machine.id,
    ComputerDnsName: machine.name || '',
    MachineId: machine.senseMachineId || '',
}); }, ɵ10 = function (dataQuery) {
    var where = dataQuery && dataQuery.where ? dataQuery.where : {};
    var pageSettings = {};
    if (dataQuery.page)
        pageSettings.pageIndex = dataQuery.page;
    if (dataQuery.pageSize)
        pageSettings.pageSize = dataQuery.pageSize;
    var lookBackInDays = { lookBackInDays: 180 };
    return Object.assign({}, where, pageSettings, lookBackInDays, dataQuery.sortBy && dataQuery.sortBy.length
        ? {
            sortByField: dataQuery.sortBy[0].field,
            sortOrder: dataQuery.sortBy[0].direction === DataQuerySortDirection.ascending
                ? 'Ascending'
                : 'Descending',
        }
        : undefined);
};
var MachineAlertsRelationship = /** @class */ (function () {
    function MachineAlertsRelationship() {
    }
    MachineAlertsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Machine,
            dataEntity: Alert,
            endpoint: 'AssociatedAlerts',
            allowedTypes: [RelationshipType.OneToMany],
            allItemsEndpointTrailingSlash: false,
            allItemsProperty: 'Items',
            getRelationshipData: ɵ9,
            parseDataQuery: ɵ10,
        })
    ], MachineAlertsRelationship);
    return MachineAlertsRelationship;
}());
export { MachineAlertsRelationship };
var ɵ11 = function (dataQuery) {
    var where = dataQuery && dataQuery.where;
    var pageSettings = {};
    if (dataQuery.page)
        pageSettings.pageIndex = dataQuery.page;
    if (dataQuery.pageSize)
        pageSettings.pageSize = dataQuery.pageSize;
    var lookBackInDays = { lookBackInDays: 180 };
    return Object.assign({}, where, pageSettings, lookBackInDays, dataQuery.sortBy && dataQuery.sortBy.length
        ? {
            sortByField: dataQuery.sortBy[0].field,
            sortOrder: dataQuery.sortBy[0].direction === DataQuerySortDirection.ascending
                ? 'Ascending'
                : 'Descending',
        }
        : undefined);
};
var MachineIncidentsRelationship = /** @class */ (function () {
    function MachineIncidentsRelationship() {
    }
    MachineIncidentsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Machine,
            dataEntity: Incident,
            foreignKey: 'MachineId',
            endpoint: 'incidents/associated',
            allowedTypes: [RelationshipType.OneToMany],
            allItemsEndpointTrailingSlash: false,
            parseDataQuery: ɵ11,
        })
    ], MachineIncidentsRelationship);
    return MachineIncidentsRelationship;
}());
export { MachineIncidentsRelationship };
var ɵ12 = function (config, query) {
    if (query && query.where && query.where.migrateToVNext) {
        return "machines/machineTags";
    }
    return "MachineTags";
}, ɵ13 = function (machine) {
    return { internalMachineId: machine.internalMachineId };
}, ɵ14 = function (config, query) {
    if (query && query.where && query.where.migrateToVNext) {
        return config.data.serviceUrls.k8s;
    }
    return config.data.serviceUrls.threatIntel;
}, ɵ15 = function (dataQuery) {
    if (!dataQuery || !dataQuery.where)
        return {};
    return omit(dataQuery.where, "migrateToVNext");
};
var MachineTagsCollectionRelationship = /** @class */ (function () {
    function MachineTagsCollectionRelationship() {
    }
    MachineTagsCollectionRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Machine,
            dataEntity: TagsCollection,
            endpoint: ɵ12,
            allItemsEndpointTrailingSlash: false,
            allowedTypes: [RelationshipType.OneToOne],
            cache: {
                max: 10,
                time: 1000 * 60,
            },
            getRelationshipData: ɵ13,
            baseUrl: ɵ14,
            parseDataQuery: ɵ15
        })
    ], MachineTagsCollectionRelationship);
    return MachineTagsCollectionRelationship;
}());
export { MachineTagsCollectionRelationship };
var ɵ16 = function (machine) {
    return {
        WcdMachineId: machine.machineId || machine.name,
        ComputerDnsName: machine.name || '',
        MachineId: machine.senseMachineId || '',
    };
}, ɵ17 = function (config) { return config.data.serviceUrls.activeAlertsSummary; };
var MachineAlertsSeveritySummaryRelationship = /** @class */ (function () {
    function MachineAlertsSeveritySummaryRelationship() {
    }
    MachineAlertsSeveritySummaryRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Machine,
            dataEntity: AlertsSeveritySummary,
            allItemsEndpointTrailingSlash: false,
            allowedTypes: [RelationshipType.OneToOne],
            cache: {
                max: 10,
                time: 1000 * 60,
            },
            getRelationshipData: ɵ16,
            endpoint: 'activeAlertsSummary',
            baseUrl: ɵ17,
        })
    ], MachineAlertsSeveritySummaryRelationship);
    return MachineAlertsSeveritySummaryRelationship;
}());
export { MachineAlertsSeveritySummaryRelationship };
var ɵ18 = function (machine) { return ({
    ComputerDnsName: machine.name || '',
    MachineId: machine.senseMachineId || '',
}); }, ɵ19 = function (config) { return config.data.serviceUrls.threatIntel; };
var MachineIncidentsCountRelationship = /** @class */ (function () {
    function MachineIncidentsCountRelationship() {
    }
    MachineIncidentsCountRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Machine,
            dataEntity: MachineIncidentCount,
            endpoint: 'IncidentCount',
            allItemsEndpointTrailingSlash: false,
            allowedTypes: [RelationshipType.OneToOne],
            cache: {
                max: 10,
                time: 1000 * 60,
            },
            getRelationshipData: ɵ18,
            baseUrl: ɵ19,
        })
    ], MachineIncidentsCountRelationship);
    return MachineIncidentsCountRelationship;
}());
export { MachineIncidentsCountRelationship };
var ɵ20 = function (config) { return config.data.serviceUrls.securityAnalytics; };
var MachineSecurityAnalyticsRelationship = /** @class */ (function () {
    function MachineSecurityAnalyticsRelationship() {
    }
    MachineSecurityAnalyticsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Machine,
            dataEntity: MachineSecurityAnalytics,
            foreignKey: 'senseMachineId',
            allItemsEndpointTrailingSlash: false,
            endpoint: 'SecurityAnalyticsMachineEnricher/MachineLatestState',
            baseUrl: ɵ20,
            allowedTypes: [RelationshipType.OneToOne],
        })
    ], MachineSecurityAnalyticsRelationship);
    return MachineSecurityAnalyticsRelationship;
}());
export { MachineSecurityAnalyticsRelationship };
var ɵ21 = function (machine) {
    /**
     * @todo remove once isUnifiedDevicePage reaches GA
     * and use return this.backendService.getMachineRequestIdsAsStruct(machine);
     */
    var params = {
        machineId: machine.senseMachineId || machine.name,
        idType: machine.senseMachineId ? MachineIdType.SenseMachineId : MachineIdType.Name,
        lastSeen: machine.lastSeen && machine.lastSeen.toISOString(),
    };
    if (machine.senseMachineId) {
        Object.assign(params, { senseMachineId: machine.senseMachineId });
    }
    if (machine.name) {
        Object.assign(params, { computerDnsName: machine.name });
    }
    return params;
}, ɵ22 = function (_, query) {
    return query.where['isUnifiedDevicePage'] ? 'LatestMachineIpsByIds' : 'LatestMachineIps';
}, ɵ23 = function (config) { return config.data.serviceUrls.threatIntel; };
var MachineNetworkInfoRelationship = /** @class */ (function () {
    function MachineNetworkInfoRelationship() {
    }
    MachineNetworkInfoRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Machine,
            dataEntity: MachineNetworkInfo,
            getRelationshipData: ɵ21,
            endpoint: ɵ22,
            allItemsEndpointTrailingSlash: false,
            baseUrl: ɵ23,
        })
    ], MachineNetworkInfoRelationship);
    return MachineNetworkInfoRelationship;
}());
export { MachineNetworkInfoRelationship };
var ɵ24 = function (machine) { return ({
    computerDnsName: machine.name,
}); }, ɵ25 = function (config) { return config.data.serviceUrls.threatIntel; };
var MachineAatpProfileRelationship = /** @class */ (function () {
    function MachineAatpProfileRelationship() {
    }
    MachineAatpProfileRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Machine,
            dataEntity: AatpProfile,
            getRelationshipData: ɵ24,
            endpoint: 'ti/aatp/AtaMachineSummary',
            baseUrl: ɵ25,
        })
    ], MachineAatpProfileRelationship);
    return MachineAatpProfileRelationship;
}());
export { MachineAatpProfileRelationship };
var ɵ26 = function (machine) { return ({ id: machine.id }); }, ɵ27 = function (_, query) { return "machines/" + query.where['id'] + "/dataSensitivity"; }, ɵ28 = function (config) { return config.data.serviceUrls.threatIntel; };
var MachineDataSensitivityRelationship = /** @class */ (function () {
    function MachineDataSensitivityRelationship() {
    }
    MachineDataSensitivityRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Machine,
            dataEntity: DataSensitivity,
            getRelationshipData: ɵ26,
            endpoint: ɵ27,
            baseUrl: ɵ28,
        })
    ], MachineDataSensitivityRelationship);
    return MachineDataSensitivityRelationship;
}());
export { MachineDataSensitivityRelationship };
var ɵ29 = function (machine) { return ({ senseMachineId: machine.senseMachineId }); }, ɵ30 = function (_, query) { return "machines/" + query.where['senseMachineId'] + "/exclusionDetails"; }, ɵ31 = function (config) { return config.data.serviceUrls.k8s; };
var MachineExclusionDetailsRelationship = /** @class */ (function () {
    function MachineExclusionDetailsRelationship() {
    }
    MachineExclusionDetailsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Machine,
            dataEntity: MachineExclusionDetails,
            getRelationshipData: ɵ29,
            endpoint: ɵ30,
            baseUrl: ɵ31,
        })
    ], MachineExclusionDetailsRelationship);
    return MachineExclusionDetailsRelationship;
}());
export { MachineExclusionDetailsRelationship };
var ɵ32 = function (machine) { return ({ senseMachineId: machine.senseMachineId }); }, ɵ33 = function (_, query) { return "machine/" + query.where['senseMachineId'] + "/InterceptingMachines"; }, ɵ34 = function (config) { return config.data.serviceUrls.cyberData; };
var MachineInterceptingMachinesRelationship = /** @class */ (function () {
    function MachineInterceptingMachinesRelationship() {
    }
    MachineInterceptingMachinesRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Machine,
            dataEntity: SeenByMachines,
            getRelationshipData: ɵ32,
            endpoint: ɵ33,
            baseUrl: ɵ34,
        })
    ], MachineInterceptingMachinesRelationship);
    return MachineInterceptingMachinesRelationship;
}());
export { MachineInterceptingMachinesRelationship };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30, ɵ31, ɵ32, ɵ33, ɵ34 };
