var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Paris } from '@microsoft/paris';
import { RemediationActionTypeTypes, AlertV3ActionTypeMap } from '@wcd/domain';
import { of, Subject } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { findKey } from 'lodash-es';
import { ActionCenterService } from './action-center.service';
var MAX_ACTION_COUNT = 1000;
var ActionFinderService = /** @class */ (function () {
    function ActionFinderService(paris, actionCenterService) {
        this.paris = paris;
        this.actionCenterService = actionCenterService;
        this.actionCache = new WeakMap();
        this.destroy$ = new Subject();
        this.useActionCenterApiV2 = false;
        this.useActionCenterApiV2 = this.actionCenterService.isActionCenterApiV2Enabled();
    }
    ActionFinderService.prototype.getMoreActionsForFile = function (action, set, moreActionsTransform) {
        if (set === void 0) { set = false; }
        if (moreActionsTransform === void 0) { moreActionsTransform = null; }
        if (!set) {
            var entry = this.actionCache.get(action);
            if (entry && entry.useExtraActions) {
                return entry.actions$ || of(null);
            }
            return of(null);
        }
        var sha1 = this.getSha1(action);
        if (!sha1) {
            return of(null);
        }
        var actionType = this.useActionCenterApiV2
            ? findKey(AlertV3ActionTypeMap, function (v) { return v === RemediationActionTypeTypes.file_quarantine; })
            : RemediationActionTypeTypes.file_quarantine;
        var res$ = this.paris
            .query(Object.getPrototypeOf(action).constructor, {
            pageSize: MAX_ACTION_COUNT,
            where: __assign({ SHA1: sha1, ActionType: actionType }, this.fixedValues),
        })
            .pipe(takeUntil(this.destroy$), map(function (actions) { return actions && actions.items; }));
        if (moreActionsTransform) {
            res$ = moreActionsTransform(res$);
        }
        res$ = res$.pipe(shareReplay({ bufferSize: 1, refCount: true }));
        this.actionCache.set(action, { useExtraActions: false, actions$: res$ });
        return res$;
    };
    ActionFinderService.prototype.getSha1 = function (action) {
        if (!action.relatedEntities) {
            return null;
        }
        var sha1 = action.relatedEntities && action.relatedEntities.length && action.relatedEntities[0].sha1;
        if (!sha1 || action.relatedEntities.some(function (e) { return e.sha1 !== sha1; })) {
            return null;
        }
        return sha1;
    };
    ActionFinderService.prototype.useExtraActionsForFile = function (action, use) {
        var entry = this.actionCache.get(action);
        if (entry) {
            entry.useExtraActions = use;
        }
    };
    ActionFinderService.prototype.invalidateCache = function () {
        this.destroy$.next();
        this.actionCache = new WeakMap();
    };
    ActionFinderService.prototype.setFixedValues = function (obj) {
        this.fixedValues = obj;
    };
    return ActionFinderService;
}());
export { ActionFinderService };
