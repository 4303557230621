import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@ApiCall({
	name: 'Get cyber event go hunt encoded query',
	endpoint: (config, query) => {
		return `machines/${query.where['machineId']}/event/huntingQuery`;
	},
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	parseQuery: (query: CyberEventGoHuntQueryRequest) => ({
		params: {
			machineId: query.machineId,
			actionTime: query.actionTimeIsoString,
			actionType: query.actionType,
		},
	}),
	cache: {
		time: 1000 * 60,
		max: 10,
	},
})
export class CyberEventGoHuntQueryApiCall extends ApiCallModel<GoHuntQueryResponse> {}

export interface CyberEventGoHuntQueryRequest {
	machineId: string;
	actionType: string;
	actionTimeIsoString: string;
}

export interface GoHuntQueryResponse {
	encodedHuntingQuery: string;
}
