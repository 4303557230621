import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';

@Entity({
	singularName: 'Url',
	pluralName: 'Urls',
	endpoint: 'urls',
	allItemsProperty: 'results',
	baseUrl: config => config.data.serviceUrls.threatIntel,
	cache: {
		time: 1000 * 60 * 5,
		max: 10,
	},
})
export class Url extends EntityModelBase<string> {
	@EntityField({ data: 'Url' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	readonly id: string;

	@EntityField({ data: 'Url' })
	readonly name: string;

	@EntityField({ data: 'UrlPageUrl', required: false })
	readonly externalUrlPageUrl?: string;
}
