import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { HuntingRuleImpactedEntity, HuntingRuleImpactedEntityType } from '../hunting-rule-impacted-entity.value-object';
import { HuntingScheduledQuery } from '../hunting-scheduled-query.entity';

export interface HuntingQueryMetadataRequest{
	Id?: number;
	QueryText?: string;
	ColumnNames?: string[]
}

@ApiCall({
	name: 'Get possible selections of impacted entities',
	endpoint: 'hunting/query/entityMappings',
	baseUrl: config => config.data.serviceUrls.threatIntel,
	method: 'POST',
	parseData: ({ ImpactedEntities }) => ImpactedEntities,
	type: HuntingRuleImpactedEntity,
})
export class HuntingQuerySupportedImpactedEntitiesApiCall extends ApiCallModel<
	Array<HuntingRuleImpactedEntity>,
	HuntingScheduledQuery
	> { }

@ApiCall({
	name: 'Get possible selections of impacted entities',
	endpoint: 'rules/impactedEntities/supported',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.huntingService,
	method: 'POST',
})
export class HuntingQuerySupportedImpactedEntitiesApiCallV2 extends ApiCallModel<
	{[key in HuntingRuleImpactedEntityType]: string[]},
	HuntingQueryMetadataRequest
	> { }

