var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
var ɵ0 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ1 = function (data) {
    return {
        data: data,
    };
};
var ScheduledHuntingUpdateStatusApiCall = /** @class */ (function (_super) {
    __extends(ScheduledHuntingUpdateStatusApiCall, _super);
    function ScheduledHuntingUpdateStatusApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ScheduledHuntingUpdateStatusApiCall = __decorate([
        ApiCall({
            name: 'Update scheduled hunting rules',
            endpoint: 'hunting/rules',
            method: 'PATCH',
            baseUrl: ɵ0,
            parseQuery: ɵ1,
        })
    ], ScheduledHuntingUpdateStatusApiCall);
    return ScheduledHuntingUpdateStatusApiCall;
}(ApiCallModel));
export { ScheduledHuntingUpdateStatusApiCall };
var ɵ2 = function (config) { return config.data.serviceUrls.huntingService; }, ɵ3 = function (data) {
    return {
        data: data,
    };
};
var ScheduledHuntingUpdateStatusApiCallVNext = /** @class */ (function (_super) {
    __extends(ScheduledHuntingUpdateStatusApiCallVNext, _super);
    function ScheduledHuntingUpdateStatusApiCallVNext() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ScheduledHuntingUpdateStatusApiCallVNext = __decorate([
        ApiCall({
            name: 'Update scheduled hunting rules',
            endpoint: 'rules/status',
            method: 'PATCH',
            baseUrl: ɵ2,
            parseQuery: ɵ3,
        })
    ], ScheduledHuntingUpdateStatusApiCallVNext);
    return ScheduledHuntingUpdateStatusApiCallVNext;
}(ApiCallModel));
export { ScheduledHuntingUpdateStatusApiCallVNext };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
