import { sccHostService } from '@wcd/scc-interface';
import { AxiosResponse } from 'axios-types';
import { TenantContext } from './tenant-context.model';

export const APC_HEADER_KEY = 'apc';

//@ts-ignore
window.mdatp = window.mdatp || {};

class TenantContextCache {
	//Use app config for legacy support in MDATP, need to rename to tenantContext
	private _appConfig: TenantContext = ({} as unknown) as TenantContext;

	get apcToken() {
		return sccHostService.ajax.apcToken;
	}

	set apcToken(token: string) {
		sccHostService.ajax.apcToken = token;
	}

	// For legacy reasons we still support appConfig
	get appConfig(): TenantContext {
		return window.mdatp._appConfig || {};
	}

	get hasMtpConsent(): boolean {
		return this.appConfig.MtpConsent;
	}

	constructor() {
		// Do not initiate module in non scc (legacy MDATP)
		if (!sccHostService.isSCC) {
			return;
		}
		this.getTenantContext();
	}

	async getTenantContext(realTime: boolean = false): Promise<TenantContext> {
		if (window.mdatp._tenantContextPromise) {
			return window.mdatp._tenantContextPromise;
		}
		return this.loadTenantContext(realTime);
	}

	async nominateTenant(): Promise<void> {
		return sccHostService.ajax.post(this.getTenantNominationUrl()).then();
	}

	invalidateCache() {
		window.mdatp._tenantContextPromise = null;
	}

	private loadTenantContext(realTime: boolean = false): Promise<TenantContext> {
		return (window.mdatp._tenantContextPromise = sccHostService.ajax
			.get(this.getTenantContextURL(), { params: { realTime } })
			.catch((err) => {
				// Bypass the BE tenantContext with a stub context to allow the app to run when BE is not ready
				if (
					sccHostService.isSCC &&
					err &&
					err.response &&
					err.response.status === 400 &&
					err.response.data &&
					err.response.data.error &&
					err.response.data.error.includes('MtpIsNotEligible')
				) {
					return { data: { IsStubData: true } } as AxiosResponse;
				}
				// Retry on failed tenantContext requests
				return sccHostService.ajax.get(this.getTenantContextURL(), { params: { realTime } });
			})
			.then((res) => {
				try {
					const apc: string = res && res.headers && res.headers.get('apc');
					if (apc) this.apcToken = apc;
					return res.data;
				} catch {
					return res.data;
				}
			})
			.then((tc) => this.onTCLoaded(tc))
			.catch((err) => {
				this.invalidateCache();
				return Promise.reject(err);
			}) as Promise<TenantContext>);
	}

	private getTenantContextURL(): string {
		return sccHostService.mock.isMockMode
			? `${sccHostService.mock.mockHost}/TenantContext`
			: '<mtp>/k8s/mgmt/TenantContext';
	}

	private getTenantNominationUrl(): string {
		return sccHostService.mock.isMockMode
			? `${sccHostService.mock.mockHost}/tenantNomination`
			: '<mtp>/k8s/mtp/nomination';
	}

	private onTCLoaded(tc: TenantContext): TenantContext {
		if (tc.IsStubData) return tc;

		if (tc.AuthInfo) {
			// We shouldn't be storing access token as it gets refreshed every API call, here for MDATP legacy reasons
			tc.AuthInfo.AccessToken = '';
		} else {
			const authInfo: any = {
				UserName: sccHostService.loginUser.displayName,
				AccessToken: '',
			};
			tc.AuthInfo = authInfo;
		}
		if (tc.ServiceUrls) {
			tc.ServiceUrls = sccHostService.mock.getMockHostServiceUrls(tc.ServiceUrls);
		}
		return (window.mdatp._appConfig = tc);
	}
}

export const tenantContextCache = new TenantContextCache();
