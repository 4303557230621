import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { userRoleActionValues, deprecatedUserRoleActionValues } from './user-role-action.values';

@Entity({
	singularName: 'User Role Action',
	pluralName: 'User Role Actions',
	values: userRoleActionValues,
})
export class MdeUserRoleAction extends EntityModelBase<number> {
	@EntityField() displayNameKey: string;

	@EntityField() displayName?: string;

	@EntityField() name: string;

	@EntityField({ defaultValue: false })
	disabled?: boolean;

	@EntityField({ defaultValue: false })
	hidden?: boolean;

	@EntityField({ defaultValue: false })
	isAdmin?: boolean;

	@EntityField({ defaultValue: false })
	isLiveResponseAction?: boolean;

	// True if this action is required for creating a user, e.g  view data - A user must have at least one view data permission
	@EntityField({ defaultValue: false })
	mandatory?: boolean;

	@EntityField({ defaultValue: null })
	children?: MdeUserRoleAction[];

	// you can user + to specify several children, e.g:
	// additionalImplicitAllowedActions: MdeUserRoleActionEnum.ChildPermission1 + MdeUserRoleActionEnum.ChildPermission1
	@EntityField({ defaultValue: 0 })
	additionalImplicitAllowedActions?: number;

	@EntityField({ defaultValue: null })
	featureFlags?: string | string[];

	static flatten(userRoleActions: MdeUserRoleAction[]): MdeUserRoleAction[] {
		return userRoleActions.reduce<Array<MdeUserRoleAction>>((accumulator, currentValue) => {
			return currentValue.children
				? [...accumulator, ...currentValue.children]
				: [...accumulator, currentValue];
		}, []);
	}

	get helpKey(): string {
		return MdeUserRoleAction.getHelpKey(this);
	}
	set helpKey(value: string) {}

	// Fix for bug: children roles are not recognized as MdeUserRoleAction and therefore calling child.helpKey returns empty string
	static getHelpKey(userRoleAction: MdeUserRoleAction) {
		return `userRoles.permissions.${userRoleAction.name}`;
	}
}

// Pre-tvm roles permissions model repository
@Entity({
	singularName: 'User Role Action',
	pluralName: 'User Role Actions',
	values: deprecatedUserRoleActionValues,
})
export class DeprecatedUserRoleAction extends MdeUserRoleAction {}
