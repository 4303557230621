var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { DataQuerySortDirection, Entity, EntityField } from '@microsoft/paris';
import { AlertBase } from '../alert/alert.entity.base';
import { DetectionSource } from '../alert/sources/detection-source.entity';
import { Machine } from '../machine/machine.entity';
import { MachineGroup } from '../rbac/machine-group.entity';
import { IncidentStatus } from './incident-status/incident-status.entity';
import { alertStatusValues } from '../alert/alert-status/alert-status.entity.values';
import { severityValues } from '../severity/severity.entity.values';
import { DataSensitivity } from '../file/data-sensitivity.value-object';
import { ImpactedEntities } from '../impacted-entities/incident.impacted.entities';
import { Alert, THREAT_EXPERT_DETECTION_SOURCE_TYPE } from '../alert/alert.entity';
import { MitreCategories, OtherCategories } from './alerts-categories.enum';
import { ServiceSource } from '../alert/sources/service-source.entity';
import { castArray, flatMap, flatten } from 'lodash-es';
import { InvestigationStatusToFilterQueryString } from '../alert/alert-investiation-status-mapping.enum';
import { IncidentType } from './incident-type';
import { IncidentTags } from './incident-tags.value-object';
import { serializeIncidentAssignmentFilters } from './incident-assignment-filter.utils';
import { AlertsSeveritySummary } from '../alert/alerts-severity-summary.value-object';
// This function is used to convert query params format, note that it used by both API calls (Get and POST) see IncidentApiCall
export function convertSeverityParameter(where) {
    var severityFilterValue = where['severity'];
    if (severityFilterValue && severityFilterValue instanceof Array) {
        where['severity'] = severityValues
            .filter(function (severity) {
            return severity.isSelectable &&
                (severityFilterValue.includes(severity.id) ||
                    severityFilterValue.includes(severity.id.toString()));
        })
            .reduce(function (bitwiseSeverity, severity) { return bitwiseSeverity | severity.id; }, 0);
    }
}
// This function is used to convert query params format, note that it used by both API calls (Get and POST) see IncidentApiCall
export function convertOSPlatformParameter(where) {
    var osPlatformValue = where['osPlatform'];
    if (osPlatformValue && osPlatformValue.length > 0) {
        where['osPlatform'] = flatten(osPlatformValue.map(function (osValue) { return osValue.split(','); }));
    }
}
// This function is used to convert query params format, note that it used by both API calls (Get and POST) see IncidentApiCall
export function convertStatusParameter(where, statusKey, statusValues) {
    var statusFilterValue = where[statusKey];
    if (statusFilterValue && statusFilterValue instanceof Array) {
        where[statusKey] = statusValues
            .filter(function (status) { return statusFilterValue.includes(status.type); })
            .reduce(function (bitwiseSeverity, status) { return bitwiseSeverity | status.id; }, 0);
    }
}
// This function is used to convert query params format, note that it used by both API calls (Get and POST) see IncidentApiCall
export function convertInvestigationStatusParameter(useNameConversion, where) {
    var investigationStatesValue = where['investigationStates'];
    if (investigationStatesValue && investigationStatesValue instanceof Array) {
        var parsedValues = castArray(investigationStatesValue).map(function (val) { return parseInt(val, 10); });
        var transformedValues = flatMap(parsedValues, function (val) {
            return val === InvestigationStatusToFilterQueryString.Pending
                ? [
                    InvestigationStatusToFilterQueryString.PendingApproval,
                    InvestigationStatusToFilterQueryString.PendingResource,
                ]
                : [val];
        });
        if (useNameConversion) {
            var namedTransformedValues = transformedValues.map(function (val) {
                return InvestigationStatusToFilterQueryString[val];
            });
            where['investigationStates'] = namedTransformedValues;
        }
        else {
            where['investigationStates'] = transformedValues;
        }
    }
}
var ɵ0 = function (config, query) {
    return query.where && query.where['isMultipleIncidents'] ? 'incidents/alerts' : 'incidents';
}, ɵ1 = function (config, query) {
    return query.where && query.where['newIncidentApi']
        ? config.data.serviceUrls.incidents || config.data.serviceUrls.incidentQueue
        : config.data.serviceUrls.threatIntel;
}, ɵ2 = function (dataQuery) {
    if (!dataQuery)
        return {};
    var pageSettings = {};
    if (dataQuery.page)
        pageSettings.pageIndex = dataQuery.page;
    if (dataQuery.pageSize)
        pageSettings.pageSize = dataQuery.pageSize;
    var where = dataQuery.where;
    if (where) {
        convertSeverityParameter(where);
        convertInvestigationStatusParameter(true, where);
        convertOSPlatformParameter(where);
        convertStatusParameter(where, 'AlertStatus', alertStatusValues);
        // This serialization must happen here and not at incident.fields.ts, to maintain the checkbox selection state
        serializeIncidentAssignmentFilters(where);
        delete where['status'];
    }
    return Object.assign({}, where, pageSettings, dataQuery.sortBy && dataQuery.sortBy.length
        ? {
            sortByField: dataQuery.sortBy[0].field,
            sortOrder: dataQuery.sortBy[0].direction === DataQuerySortDirection.ascending
                ? 'Ascending'
                : 'Descending',
        }
        : undefined);
}, ɵ3 = function (categoriesHitCounts) {
    var mitreCategories = new Map();
    return Object.keys(MitreCategories).reduce(function (result, key) {
        return result.set(key, (categoriesHitCounts &&
            categoriesHitCounts.MitreCategories &&
            categoriesHitCounts.MitreCategories[key]) ||
            0);
    }, mitreCategories);
}, ɵ4 = function (categoriesHitCounts) {
    var otherCategories = new Map();
    return Object.keys(OtherCategories).reduce(function (result, key) {
        return result.set(key, (categoriesHitCounts &&
            categoriesHitCounts.OtherCategories &&
            categoriesHitCounts.OtherCategories[key]) ||
            0);
    }, otherCategories);
}, ɵ5 = function (status) {
    if (!status)
        return status;
    var statusLog = Math.log2(status);
    return statusLog === Math.floor(statusLog) ? status : null;
}, ɵ6 = ['FullyMachineRbacExposed', 'FullyRbacExposed'];
var Incident = /** @class */ (function (_super) {
    __extends(Incident, _super);
    function Incident() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getExpertDetectionSource = function () {
            return _this.detectionSources &&
                _this.detectionSources.find(function (source) { return source && source.type === THREAT_EXPERT_DETECTION_SOURCE_TYPE; });
        };
        return _this;
    }
    Object.defineProperty(Incident.prototype, "threatExpertName", {
        get: function () {
            var expertDetectionSource = this.getExpertDetectionSource();
            return expertDetectionSource && expertDetectionSource.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Incident.prototype, "threatExpertI18nNameKey", {
        // Localized version of threatExpertName that return the i18n key instead of the name.
        get: function () {
            var expertDetectionSource = this.getExpertDetectionSource();
            return expertDetectionSource && expertDetectionSource.nameI18nKey;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({ data: 'IncidentId' }),
        __metadata("design:type", String)
    ], Incident.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'TenantId' }),
        __metadata("design:type", String)
    ], Incident.prototype, "tenantId", void 0);
    __decorate([
        EntityField({ data: '__self' }),
        __metadata("design:type", Machine)
    ], Incident.prototype, "machine", void 0);
    __decorate([
        EntityField({ data: 'AlertCount' }),
        __metadata("design:type", Number)
    ], Incident.prototype, "alertCount", void 0);
    __decorate([
        EntityField({ data: 'ActiveAlertCount' }),
        __metadata("design:type", Number)
    ], Incident.prototype, "activeAlertCount", void 0);
    __decorate([
        EntityField({ data: 'AlertsSeveritiesSummary' }),
        __metadata("design:type", AlertsSeveritySummary)
    ], Incident.prototype, "alertsSeveritiesSummary", void 0);
    __decorate([
        EntityField({ data: 'Tags', required: false }),
        __metadata("design:type", Array)
    ], Incident.prototype, "tags", void 0);
    __decorate([
        EntityField({ data: 'IncidentTags', required: false }),
        __metadata("design:type", IncidentTags)
    ], Incident.prototype, "incidentTags", void 0);
    __decorate([
        EntityField({
            data: 'CategoriesHitCounts',
            required: false,
            parse: ɵ3,
        }),
        __metadata("design:type", Map)
    ], Incident.prototype, "mitreCategories", void 0);
    __decorate([
        EntityField({
            data: 'CategoriesHitCounts',
            required: false,
            parse: ɵ4,
        }),
        __metadata("design:type", Map)
    ], Incident.prototype, "otherCategories", void 0);
    __decorate([
        EntityField({ data: 'AlertCountMacthingResoureFilter' }),
        __metadata("design:type", Number)
    ], Incident.prototype, "alertCountForResource", void 0);
    __decorate([
        EntityField({ data: 'ImpactedUserCount' }),
        __metadata("design:type", Number)
    ], Incident.prototype, "aadUserCount", void 0);
    __decorate([
        EntityField({ data: 'InvestigationStatesCount' }),
        __metadata("design:type", Number)
    ], Incident.prototype, "investigationStatesCount", void 0);
    __decorate([
        EntityField({ data: 'InvestigationCount' }),
        __metadata("design:type", Number)
    ], Incident.prototype, "investigationCount", void 0);
    __decorate([
        EntityField({ data: 'InvestigationIds' }),
        __metadata("design:type", Array)
    ], Incident.prototype, "investigationIds", void 0);
    __decorate([
        EntityField({ data: '__self', require: 'SensitivityLabel' }),
        __metadata("design:type", DataSensitivity)
    ], Incident.prototype, "sensitivity", void 0);
    __decorate([
        EntityField({
            data: 'Status',
            parse: ɵ5,
        }),
        __metadata("design:type", IncidentStatus)
    ], Incident.prototype, "status", void 0);
    __decorate([
        EntityField({ data: 'ImpactedEntities' }),
        __metadata("design:type", ImpactedEntities)
    ], Incident.prototype, "impactedEntities", void 0);
    __decorate([
        EntityField({ data: 'RbacGroupIds', required: false, arrayOf: MachineGroup }),
        __metadata("design:type", Array)
    ], Incident.prototype, "machineGroups", void 0);
    __decorate([
        EntityField({ data: 'DetectionSources', required: false, arrayOf: DetectionSource }),
        __metadata("design:type", Array)
    ], Incident.prototype, "detectionSources", void 0);
    __decorate([
        EntityField({ data: 'ProductSources', required: false, arrayOf: ServiceSource }),
        __metadata("design:type", Array)
    ], Incident.prototype, "serviceSources", void 0);
    __decorate([
        EntityField({ data: 'ActorNames', required: false, arrayOf: String }),
        __metadata("design:type", Array)
    ], Incident.prototype, "actors", void 0);
    __decorate([
        EntityField({ data: 'MtpAlertsExist', required: false }),
        __metadata("design:type", Boolean)
    ], Incident.prototype, "mtpAlertsExist", void 0);
    __decorate([
        EntityField({ data: ɵ6 }),
        __metadata("design:type", Boolean)
    ], Incident.prototype, "isFullyMachineRbacExposed", void 0);
    __decorate([
        EntityField({ data: 'Alerts', arrayOf: Alert }),
        __metadata("design:type", Array)
    ], Incident.prototype, "alerts", void 0);
    __decorate([
        EntityField({ data: 'IncidentType' }),
        __metadata("design:type", Number)
    ], Incident.prototype, "incidentType", void 0);
    __decorate([
        EntityField({ data: 'RecommendedAHQs' }),
        __metadata("design:type", Array)
    ], Incident.prototype, "recommendedAHQs", void 0);
    __decorate([
        EntityField({ data: 'RecommendedActions' }),
        __metadata("design:type", String)
    ], Incident.prototype, "recommendedActions", void 0);
    __decorate([
        EntityField({ data: 'Description' }),
        __metadata("design:type", String)
    ], Incident.prototype, "description", void 0);
    Incident = __decorate([
        Entity({
            singularName: 'Incident',
            pluralName: 'Incidents',
            endpoint: ɵ0,
            allItemsProperty: 'results',
            baseUrl: ɵ1,
            // remove threatIntel and incidentQueue service urls after migration is complete.
            cache: {
                time: 1000 * 60,
                max: 10,
            },
            separateArrayParams: true,
            parseDataQuery: ɵ2,
        })
    ], Incident);
    return Incident;
}(AlertBase));
export { Incident };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
