import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceUrlsService } from '@wcd/app-config';
import { MachineGroup, MachineGroupResultsPreview } from '@wcd/domain';
import { combineLatest, Observable } from 'rxjs';
import { Paris, Repository } from '@microsoft/paris';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class MachineGroupsService {
	constructor(
		private http: HttpClient,
		private serviceUrlsService: ServiceUrlsService,
		private paris: Paris
	) {}

	getMachineGroupMachinesPreview(
		machineGroup: MachineGroup
	): Observable<Array<MachineGroupResultsPreview>> {
		const machineGroupsRepo: Repository<MachineGroup> = this.paris.getRepository(MachineGroup);

		return this.http
			.post(`${this.serviceUrlsService.threatIntel}/PreviewRbacGroups`, {
				RbacGroups: [machineGroupsRepo.serializeItem(machineGroup)],
			})
			.pipe(
				mergeMap((results: Array<any>) => {
					const machineGroupResultsPreviewRepo: Repository<
						MachineGroupResultsPreview
					> = this.paris.getRepository(MachineGroupResultsPreview);
					return combineLatest(
						results.map(result => machineGroupResultsPreviewRepo.createItem(result))
					);
				})
			);
	}
}
