import { FlavorConfig } from '@wcd/scc-common';

export interface ISettingsConfig {
	licenses: FlavorConfig;
	dataRetention: FlavorConfig;
	tvm: FlavorConfig;
	investigation: FlavorConfig;
	alertsShare: FlavorConfig;
	deviceGroups: FlavorConfig;
	deviceGroupsAutomationLevel: FlavorConfig;
	hunting: FlavorConfig;
	autoIR: FlavorConfig;
	customTI: FlavorConfig;
	autoIRMdeOnly: FlavorConfig;
	audit: FlavorConfig;
	portalRedirect: FlavorConfig;
	itpIntegration: FlavorConfig;
	indicators: FlavorConfig;
	webContentFiltering: FlavorConfig;
	mcasIntegration: FlavorConfig;
	officeIntegration: FlavorConfig;
	permissions: FlavorConfig;
	networkAssessments: FlavorConfig;
	mdeAttach: FlavorConfig;
}

export const SettingsConfig: ISettingsConfig = {
	licenses: {
		mdeFlavors: ['P2', 'P1'],
	},
	dataRetention: {
		mdeFlavors: ['P2'],
	},
	// used for auto remediation nav item
	// used for email notifications vulnerability tab
	// used for advanced features: enableWdavPassiveModeRemediation (EDR)
	tvm: {
		mdeFlavors: ['P2', 'Smb'],
	},
	networkAssessments: {
		mdeFlavors: ['P2', 'Smb'],
	},
	// used for advanced features: autoResolveInvestigatedAlerts
	investigation: {
		mdeFlavors: ['P2'],
	},
	// used for advanced features: Share endpoint alerts with microsoft compliance center
	alertsShare: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true,
	},
	// used for advanced features: Restrict correlation to within scoped device groups
	deviceGroups: {
		mdeFlavors: ['P2', 'P1'],
	},
	deviceGroupsAutomationLevel: {
		mdeFlavors: ['P2'],
	},
	// used for custom detections nav item
	hunting: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true,
	},
	// used for advanced features: enableQuarantinedFileDownload
	// used for settings navigation panel: automation uploads, automation folder exclusions, process memory indicators
	autoIR: {
		mdeFlavors: ['P2'],
	},
	customTI: {
		mdeFlavors: ['P2'],
	},
	// used for advanced features: Automated investigations, Live response, Live response for servers, Live response unsigned script exec.
	autoIRMdeOnly: {
		mdeFlavors: ['P2', 'Smb'],
	},
	audit: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true,
	},
	portalRedirect: {
		mdeFlavors: ['P2'],
	},
	itpIntegration: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true,
	},
	indicators: {
		mdeFlavors: ['P2', 'P1'],
	},
	webContentFiltering: {
		mdeFlavors: ['P2', 'P1'],
	},
	// used for advanced features: enableMcasIntegration ("Cloud App Security")
	mcasIntegration: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true,
	},
	// used for advanced features: Office 365 TI connection
	officeIntegration: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true,
	},
	// used for routing: permissions section
	permissions: {
		mdeFlavors: ['P2', 'P1'],
	},
	// used for routing: admin (configuration_management2 path)
	// used for advanced features: Endpoint Configuration Management
	mdeAttach: {
		mdeFlavors: ['Smb'],
	},
};
