import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Incident } from '@wcd/domain';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { EntityComponentBase } from '../../../global_entities/components/entity-contents.component.base';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';
import { AppContextService, Feature, FeaturesService, FlavorService } from '@wcd/config';
import { SpinnerSize } from 'office-ui-fabric-react';
import { ActivatedRoute } from '@angular/router';
import { IncidentTabLoadDataInterface } from '../incident-tab-load-data.interface';
import { I18nService } from '@wcd/i18n';
import { isUndefined } from 'lodash-es';
import { sccHostService } from '@wcd/scc-interface';
import { AppFlavorConfig } from '@wcd/scc-common';
import { EvidenceService } from '../../evidence/services/evidence.service';

enum EagerTabs {
	investigations = 'investigations',
	evidence = 'evidence',
}

@Component({
	selector: 'incident-entity',
	template: `
		<div class="wcd-full-height wcd-flex-vertical">
			<ng-container *ngIf="enableIncidentDisruptBanner">
				<incident-disrupt-banner
					[incident]="incident"
					[class.wcd-padding-none-horizontal-important]="shouldRemovePadding"
					[contentClass]="'wcd-margin-medium-vertical'"
				>
				</incident-disrupt-banner>
			</ng-container>
			<incident-rbac-permission-message
				[incident]="incident"
				[class.wcd-padding-none-horizontal-important]="shouldRemovePadding"
				[contentClass]="'wcd-margin-medium-vertical'"
			>
			</incident-rbac-permission-message>
			<tabs
				[tabsData]="tabs"
				[currentRouteIsActive]="true"
				[currentTab]="currentTabId"
				class="wcd-flex-0 wcd-padding-xxLarge-horizontal incident-entity-tabs"
				[class.wcd-padding-none-horizontal-important]="shouldRemovePadding"
			></tabs>
			<div
				class="wcd-flex-1 wcd-padding-largeMedium-top wcd-padding-xxLarge-left"
				[class.wcd-padding-none-horizontal-important]="shouldRemovePadding"
			>
				<router-outlet></router-outlet>
				<incident-evidence
					[hidden]="currentTabId !== EagerTabs.evidence"
					[isHidden]="currentTabId !== EagerTabs.evidence"
					[incident]="incident"
					(onResultsLoad)="setTabResultData(EagerTabs.evidence, $event)"
				></incident-evidence>
				<incident-investigations
					[hidden]="currentTabId !== EagerTabs.investigations"
					[isHidden]="currentTabId !== EagerTabs.investigations"
					[incident]="incident"
					(onResultsLoad)="setTabResultData(EagerTabs.investigations, $event)"
				></incident-investigations>
			</div>
		</div>
		<ng-template #loaderTab let-tabId="tabId" let-name="name">
			<ng-container *ngIf="tabLoaders[tabId]; else staticTitle">
				<fab-spinner
					[size]="SpinnerSize.xSmall"
					[label]="name"
					[labelPosition]="'left'"
					[styles]="{ label: ['color-text-gray-900', { marginRight: '4px' }] }"
				>
				</fab-spinner>
			</ng-container>
			<ng-template #staticTitle>
				<span>{{ name }} ({{ tabCounts[tabId] | prettyNumber }})</span>
			</ng-template>
		</ng-template>
		<ng-template #evidenceTab>
			<ng-container
				*ngTemplateOutlet="
					loaderTab;
					context: {
						tabId: EagerTabs.evidence,
						name: i18nService.strings.incident_overview_evidence_and_response_title
					}
				"
			></ng-container>
		</ng-template>
		<ng-template #investigationsTab>
			<ng-container
				*ngTemplateOutlet="
					loaderTab;
					context: {
						tabId: EagerTabs.investigations,
						name: i18nService.strings.incident_overview_investigations_title
					}
				"
			></ng-container>
		</ng-template>
	`,
	styles: [
		`
			.incident-entity-tabs {
				width: 85%;
			}
		`,
	],
})
export class IncidentEntityComponent extends EntityComponentBase<Incident> implements OnInit, OnDestroy {
	EntityPageViewMode = EntityPageViewMode;
	tabs: Array<TabModel> = [];
	SpinnerSize = SpinnerSize;
	EagerTabs = EagerTabs;
	loadEventFired: boolean = false;
	tabLoaders: { [key in EagerTabs]?: boolean } = {};
	tabCounts: { [key in EagerTabs]?: number } = {};
	shouldRemovePadding = sccHostService.isSCC;

	get currentTabId(): string {
		const childComponentUrl =
			this.route.snapshot.firstChild && this.route.snapshot.firstChild.url.toString();
		const currentTab =
			this.tabs.find(tab => new RegExp(`\.\/${childComponentUrl}$`).test(<string>tab.routerLink)) ||
			this.tabs[0];
		return currentTab.id;
	}

	@ViewChild('evidenceTab', { static: true, read: TemplateRef })
	evidenceTab: TemplateRef<any>;

	@ViewChild('investigationsTab', { static: true, read: TemplateRef })
	investigationsTab: TemplateRef<any>;

	enableIncidentDisruptBanner: boolean = false;

	constructor(
		private appContextService: AppContextService,
		private featuresService: FeaturesService,
		private readonly route: ActivatedRoute,
		public i18nService: I18nService,
		private flavorService: FlavorService,
		private evidenceService: EvidenceService
	) {
		super();
		this.enableIncidentDisruptBanner = this.featuresService.isEnabled(Feature.IncidentDisruptBanner);
	}

	get incident(): Incident {
		return this.entity;
	}

	ngOnInit() {
		sccHostService.isSCC && sccHostService.log.trackEvent("incident-page-view", {
			id: this.entity.id,
			serviceSources: this.entity.serviceSources.map(s => s.name)
		});
		const tabs = this.getTabs(this.incident);
		this.tabs = this.appContextService.isMtp
			? tabs.filter(tab => tab.id !== 'graph' || this.featuresService.isEnabled(Feature.IncidentGraph))
			: tabs.filter(tab => tab.id !== 'users' && tab.id !== 'mailboxes' && tab.id !== 'apps');
	}

	ngOnDestroy() {
		this.evidenceService.destroyEvidence();
	}

	private getTabs(incident: Incident): Array<TabModel> {
		return [
			this.featuresService.isEnabled(Feature.IncidentV2Tab)
				? {
					id: 'attack-story',
					name: this.i18nService.get('incident_tabs_attack_story'),
					routerLink: './overview',
				}
				: undefined,
			!this.featuresService.isEnabled(Feature.IncidentV2Tab) &&
			(this.featuresService.isEnabled(Feature.IncidentOverviewMtp) ||
				this.featuresService.isEnabled(Feature.IncidentOverview))
				? {
					id: 'overview',
					name: this.i18nService.get('incident_tabs_summary'),
					routerLink: './overview',
				}
				: undefined,
			{
				id: 'alerts',
				name: `${this.i18nService.strings.incident_tabs_alerts} (${incident.alertCount})`,
				routerLink: './alerts',
			},
			this.featuresService.isEnabled(Feature.IncidentTimeline)
				? {
						id: 'timeline',
						name: this.i18nService.get('incident_tabs_timeline'),
						routerLink: './timeline',
				  }
				: undefined,
			{
				id: 'machines',
				name: `${this.i18nService.strings.incident_tabs_devices} (${(incident.impactedEntities && incident.impactedEntities.machineCount) || 0})`,
				routerLink: './machines',
			},
			{
				id: 'users',
				name: `${this.i18nService.strings.incident_tabs_users} (${(incident.impactedEntities && incident.impactedEntities.userCount) || 0})`,
				routerLink: './users',
			},
			...(this.flavorService.isEnabled(AppFlavorConfig.incidents.mailboxes)
				? [
						{
							id: 'mailboxes',
							name: `${this.i18nService.strings.incident_tabs_mailboxes} (${
								(incident.impactedEntities && incident.impactedEntities.mailboxCount) || 0
							})`,
							routerLink: './mailboxes',
						},
				  ]
				: []),
			...(this.featuresService.isEnabled(Feature.ConsiderApplicationAsAsset)
				? [
						{
							id: 'apps',
							name: `${
								this.i18nService.strings.incident_tabs_apps
							} (${(incident.impactedEntities && incident.impactedEntities.appCount) || 0})`,
							routerLink: './apps',
						},
				  ]
				: []),
			...(this.flavorService.isEnabled(AppFlavorConfig.incidents.autoIr)
				? [
						{
							id: EagerTabs.investigations,
							routerLink: './investigations',
							templateRef: this.investigationsTab,
						},
				  ]
				: []),
			{
				id: EagerTabs.evidence,
				routerLink: './evidence',
				templateRef: this.evidenceTab,
			},
			this.featuresService.isEnabled(Feature.IncidentV2Tab) &&
			(this.featuresService.isEnabled(Feature.IncidentOverviewMtp) ||
				this.featuresService.isEnabled(Feature.IncidentOverview))
				? {
					id: 'summary',
					name: this.i18nService.get('incident_tabs_summary'),
					routerLink: './summary',
				}
				: undefined,
			!this.featuresService.isEnabled(Feature.IncidentV2Tab) && {
				id: 'graph',
				name: this.i18nService.get('incident_tabs_graph'),
				routerLink: './graph',
				badgeClassName: 'wcd-badge wcd-badge-raised wcd-badge--textual color-box-gray-700',
				badge: sccHostService.isSCC ? null : this.i18nService.get('incident_tabs_alertsGraphBeta'),
			},
			!this.featuresService.isEnabled(Feature.IncidentV2Tab) &&
			this.featuresService.isEnabled(Feature.IncidentV2)
				? {
						id: 'incident-graph',
						name: this.i18nService.get('incident_tabs_incident_graph'),
						routerLink: './incident-graph',
				  }
				: undefined,
			this.featuresService.isEnabled(Feature.IncidentAlertsGraph)
				? {
						id: 'alertsGraph',
						name: this.i18nService.get('incident_tabs_alertsGraph'),
						routerLink: './alertsGraph',
				  }
				: undefined,
		]
			.filter(Boolean)
			.map(tab => new TabModel(tab));
	}

	setTabResultData(key: EagerTabs, event: IncidentTabLoadDataInterface) {
		const { isLoading, count } = event;
		this.tabLoaders[key] = isLoading;
		if (!this.tabLoaders[key]) {
			this.tabCounts[key] = count || 0;
		}
		if (!this.loadEventFired && !isLoading && !isUndefined(count)) {
			this.loadEventFired = true;
		}
	}
}
