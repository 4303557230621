import { I18nService } from '@wcd/i18n';
import { RemediationType, ExceptionJustification, RemediationTicketStateValue, ProductivityImpactRemediationType, RemediationTaskStateValue, ExceptionType, RecommendationCategory, operatingSystemPlatformValues, VulnerabilityChangeEventType, RecommendationContextType, EolWindows, RemediationCategory, EolState, MicrosoftProductIdsWithLegalNote, VulnerabilityType, RemediationTaskCompletionMethod, } from '@wcd/domain';
import { PrettyNumberService } from '@wcd/prettify';
import { Feature, FeaturesService } from '@wcd/config';
import { TzDateService } from '@wcd/localization';
import { baselineBaseBenchmarkValues } from '../../../../../../packages/@wcd/domain/src/tvm/baseline-compliance/baseline-profile-creation/baseline-base-benchmark.values';
var GREEN_COLOR = 'ms-color-green';
export var InaccuracyContext;
(function (InaccuracyContext) {
    InaccuracyContext["MachineScaRecommendation"] = "Device SCA Recommendation";
    InaccuracyContext["MachineVaRecommendation"] = "Device VA Recommendation";
    InaccuracyContext["MachineInventory"] = "Device Inventory";
    InaccuracyContext["MachineVulnerabilities"] = "Device Vulnerabilities";
    InaccuracyContext["OrgVaRecommendation"] = "Org VA Recommendation";
    InaccuracyContext["OrgScaRecommendation"] = "Org SCA Recommendation";
    InaccuracyContext["OrgInventory"] = "Org Inventory";
    InaccuracyContext["OrgVulnerabilities"] = "Org Vulnerabilities";
})(InaccuracyContext || (InaccuracyContext = {}));
export var TextToken;
(function (TextToken) {
    TextToken[TextToken["SecurityRecommendationTitle"] = 0] = "SecurityRecommendationTitle";
    TextToken[TextToken["SecurityRecommendationRelatedComponent"] = 1] = "SecurityRecommendationRelatedComponent";
    TextToken[TextToken["SecurityRecommendationDescription"] = 2] = "SecurityRecommendationDescription";
    TextToken[TextToken["SecurityRecommendationSCAProperlyConfiguredInsight"] = 3] = "SecurityRecommendationSCAProperlyConfiguredInsight";
    TextToken[TextToken["SecurityRecommendationSCARecommendedBenchmarksInsight"] = 4] = "SecurityRecommendationSCARecommendedBenchmarksInsight";
    TextToken[TextToken["SecurityRecommendationAttentionExplanation"] = 5] = "SecurityRecommendationAttentionExplanation";
    TextToken[TextToken["SecurityRecommendationJustificationNotification"] = 6] = "SecurityRecommendationJustificationNotification";
    TextToken[TextToken["OrgRecommendationProductivityImpact"] = 7] = "OrgRecommendationProductivityImpact";
    TextToken[TextToken["OrgRecommendationProductivityImpactTooltip"] = 8] = "OrgRecommendationProductivityImpactTooltip";
    TextToken[TextToken["AssetRecommendationProductivityImpactTooltip"] = 9] = "AssetRecommendationProductivityImpactTooltip";
    TextToken[TextToken["AssetRecommendationProductivityImpact"] = 10] = "AssetRecommendationProductivityImpact";
    TextToken[TextToken["SecurityAssessmentsSecurityRecommendations"] = 11] = "SecurityAssessmentsSecurityRecommendations";
    TextToken[TextToken["SecurityAssessmentsDiscoveredVulnerabilities"] = 12] = "SecurityAssessmentsDiscoveredVulnerabilities";
    TextToken[TextToken["ExploitIconToolTip"] = 13] = "ExploitIconToolTip";
    TextToken[TextToken["ActiveThreatIconTooltip"] = 14] = "ActiveThreatIconTooltip";
    TextToken[TextToken["RemediationTaskStatusDescription"] = 15] = "RemediationTaskStatusDescription";
    TextToken[TextToken["RemediationTaskSafeMachines"] = 16] = "RemediationTaskSafeMachines";
    TextToken[TextToken["RemediationRelatedComponent"] = 17] = "RemediationRelatedComponent";
    TextToken[TextToken["ExposureScoreWidgetInfo"] = 18] = "ExposureScoreWidgetInfo";
    TextToken[TextToken["ExposureScoreWidgetInfoAriaLabel"] = 19] = "ExposureScoreWidgetInfoAriaLabel";
    TextToken[TextToken["ConfigurationScoreWidgetInfo"] = 20] = "ConfigurationScoreWidgetInfo";
    TextToken[TextToken["ScoreImpactInfo"] = 21] = "ScoreImpactInfo";
    TextToken[TextToken["ExposureScoreTooltip"] = 22] = "ExposureScoreTooltip";
    TextToken[TextToken["ConfigurationScoreTooltip"] = 23] = "ConfigurationScoreTooltip";
    TextToken[TextToken["ExposureScoreForSoftware"] = 24] = "ExposureScoreForSoftware";
    TextToken[TextToken["EntityName"] = 25] = "EntityName";
    TextToken[TextToken["EntityFullName"] = 26] = "EntityFullName";
    TextToken[TextToken["AffectedProduct"] = 27] = "AffectedProduct";
    TextToken[TextToken["VulnerabilityInstalledAffectedProduct"] = 28] = "VulnerabilityInstalledAffectedProduct";
    TextToken[TextToken["VulnerabilityOsFeature"] = 29] = "VulnerabilityOsFeature";
    TextToken[TextToken["OsFeatureTooltip"] = 30] = "OsFeatureTooltip";
    TextToken[TextToken["RelatedSoftware"] = 31] = "RelatedSoftware";
    TextToken[TextToken["RelatedSoftwareTooltip"] = 32] = "RelatedSoftwareTooltip";
    TextToken[TextToken["VulnerabilitiesCount"] = 33] = "VulnerabilitiesCount";
    TextToken[TextToken["MisconfigurationsCount"] = 34] = "MisconfigurationsCount";
    TextToken[TextToken["ScoreWidgetNoData"] = 35] = "ScoreWidgetNoData";
    TextToken[TextToken["NoDataForWidget"] = 36] = "NoDataForWidget";
    TextToken[TextToken["ReportInaccuracyReasonTitle"] = 37] = "ReportInaccuracyReasonTitle";
    TextToken[TextToken["VulnerabilityAge"] = 38] = "VulnerabilityAge";
    TextToken[TextToken["RemediationTicketStatusDescription"] = 39] = "RemediationTicketStatusDescription";
    TextToken[TextToken["RemediationTicketStatus"] = 40] = "RemediationTicketStatus";
    TextToken[TextToken["InstallationAggVersionsTooltip"] = 41] = "InstallationAggVersionsTooltip";
    TextToken[TextToken["InstallationAggVersions"] = 42] = "InstallationAggVersions";
    TextToken[TextToken["SoftwareVersionsLinkText"] = 43] = "SoftwareVersionsLinkText";
    TextToken[TextToken["SoftwareVersionsName"] = 44] = "SoftwareVersionsName";
    TextToken[TextToken["SoftwareVersionsVendorNameAndVersion"] = 45] = "SoftwareVersionsVendorNameAndVersion";
    TextToken[TextToken["ExceptionRemediationType"] = 46] = "ExceptionRemediationType";
    TextToken[TextToken["ChangeEventActivity"] = 47] = "ChangeEventActivity";
    TextToken[TextToken["SoftwareEolOsLegalNote"] = 48] = "SoftwareEolOsLegalNote";
    TextToken[TextToken["SoftwareEolOsLegalNoteRecommendation"] = 49] = "SoftwareEolOsLegalNoteRecommendation";
    TextToken[TextToken["RecommendationExceptionRelatedComponent"] = 50] = "RecommendationExceptionRelatedComponent";
    TextToken[TextToken["HasEolVersionsMicrosoftLegalNote"] = 51] = "HasEolVersionsMicrosoftLegalNote";
    TextToken[TextToken["HasEolVersionsMicrosoftLegalNoteRecommendation"] = 52] = "HasEolVersionsMicrosoftLegalNoteRecommendation";
    TextToken[TextToken["HasEolVersionsText"] = 53] = "HasEolVersionsText";
    TextToken[TextToken["HasUpcomingEolVersionsText"] = 54] = "HasUpcomingEolVersionsText";
    TextToken[TextToken["EolVersionKbLegalNote"] = 55] = "EolVersionKbLegalNote";
    TextToken[TextToken["UpcomingEolVersionKbLegalNote"] = 56] = "UpcomingEolVersionKbLegalNote";
    TextToken[TextToken["CombinedEolVersionsText"] = 57] = "CombinedEolVersionsText";
    TextToken[TextToken["UpgradeLinkText"] = 58] = "UpgradeLinkText";
    TextToken[TextToken["EolSoftwareText"] = 59] = "EolSoftwareText";
    TextToken[TextToken["UpcomingEolSoftwareText"] = 60] = "UpcomingEolSoftwareText";
    TextToken[TextToken["AggregatedExceptionRequester"] = 61] = "AggregatedExceptionRequester";
    TextToken[TextToken["AggregatedExceptionScope"] = 62] = "AggregatedExceptionScope";
    TextToken[TextToken["RemediationBlockTitle"] = 63] = "RemediationBlockTitle";
    TextToken[TextToken["RemediationBlockSoftwareTitle"] = 64] = "RemediationBlockSoftwareTitle";
    TextToken[TextToken["SoftwareNameFromId"] = 65] = "SoftwareNameFromId";
    TextToken[TextToken["MitigationDescription"] = 66] = "MitigationDescription";
    TextToken[TextToken["HighValueAssetDescription"] = 67] = "HighValueAssetDescription";
    TextToken[TextToken["LegalNoteLinkText"] = 68] = "LegalNoteLinkText";
    TextToken[TextToken["RemediationTaskCompletedBy"] = 69] = "RemediationTaskCompletedBy";
    TextToken[TextToken["RemediationBlockStatus"] = 70] = "RemediationBlockStatus";
    TextToken[TextToken["RedHatLegalNotice"] = 71] = "RedHatLegalNotice";
})(TextToken || (TextToken = {}));
/**
 *    The service offers a way to avoid display-text manipulations in domain entities, and provides a DRY solution to complex text manipulation logic.
 * 	  It doesn't replaces the i18nService, rather, "extends" it when more complex logic is needed.
 *    Use it by adding TextTokens and implement a cb function that accepts any data bag and returns the text. the cb function should use the i18nService for translations.
 */
var TvmTextsService = /** @class */ (function () {
    function TvmTextsService(i18nService, prettyNumberService, featuresService, tzDateService) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        this.i18nService = i18nService;
        this.prettyNumberService = prettyNumberService;
        this.featuresService = featuresService;
        this.tzDateService = tzDateService;
        this._noDataAvailableText = this.i18nService.get('common.noDataAvailable');
        this.textsMap = (_a = {},
            _a[TextToken.VulnerabilitiesCount] = function (vulnerabilitiesCount) {
                return vulnerabilitiesCount === 1
                    ? _this.i18nService.get('tvm.softwarePage.report.widgets.vulnerabilities.title.data.singular')
                    : _this.i18nService.get('tvm.softwarePage.report.widgets.vulnerabilities.title.data.plural', {
                        count: _this.prettyNumberService.prettyNumber(vulnerabilitiesCount),
                    });
            },
            _a[TextToken.MisconfigurationsCount] = function (misconfigurationsCount) {
                return misconfigurationsCount === 1
                    ? _this.i18nService.get('tvm.softwarePage.report.widgets.misconfigurations.title.data.singular')
                    : _this.i18nService.get('tvm.softwarePage.report.widgets.misconfigurations.title.data.plural', {
                        count: _this.prettyNumberService.prettyNumber(misconfigurationsCount),
                    });
            },
            _a[TextToken.SecurityAssessmentsSecurityRecommendations] = function (recommendationsCount) {
                return recommendationsCount === 1
                    ? _this.i18nService.get('machines.report.widgets.securityAssessments.singleSecurityRecommendations', { count: '1' })
                    : _this.i18nService.get('machines.report.widgets.securityAssessments.pluralSecurityRecommendations', { count: recommendationsCount });
            },
            _a[TextToken.SecurityAssessmentsDiscoveredVulnerabilities] = function (vulnerabilitiesCount) {
                return vulnerabilitiesCount === 1
                    ? _this.i18nService.get('machines.report.widgets.securityAssessments.singleDiscoveredVulnerabilities', { count: '1' })
                    : _this.i18nService.get('machines.report.widgets.securityAssessments.pluralDiscoveredVulnerabilities', { count: vulnerabilitiesCount });
            },
            _a[TextToken.SecurityRecommendationTitle] = function (securityRecommendation) {
                if (securityRecommendation.title)
                    return securityRecommendation.title;
                if (securityRecommendation.remediationType === RemediationType.ConfigurationChange)
                    return ''; //we expect SCA to bring recomm' title from the BE.
                var software = _this.parseFullProductName(securityRecommendation.productName, securityRecommendation.vendor);
                var isZeroDayRecommendation = _this.featuresService.isEnabled(Feature.TvmZeroDay) &&
                    securityRecommendation.mostSevereVulnerabilityType === VulnerabilityType.ZeroDay &&
                    !securityRecommendation.patchReleaseDate;
                if (isZeroDayRecommendation) {
                    return _this.i18nService.strings.tvm_securityRecommendation_zeroDay_title + " " + software;
                }
                var eolPostfix = securityRecommendation.isEOL
                    ? " (" + _this.i18nService.get('tvm.securityRecommendation.isEol') + ")"
                    : '';
                var versionToken = securityRecommendation.recommendedVersion
                    ? " " + _this.i18nService.get('tvm.securityRecommendation.descriptionTemplates.toVersion') + " " + securityRecommendation.recommendedVersion
                    : '';
                switch (securityRecommendation.remediationType) {
                    case RemediationType.Upgrade:
                        var upgradeToken = _this.i18nService.get('tvm.securityRecommendation.descriptionTemplates.upgradeSoftware', { software: software });
                        var upgradeTo = _this.getUpgradeTo(securityRecommendation);
                        var upgradePostfix = upgradeTo !== null
                            ? _this.i18nService.get('tvm.securityRecommendation.descriptionTemplates.upgradeTo', {
                                product: upgradeTo,
                            })
                            : '';
                        return "" + upgradeToken + upgradePostfix + versionToken;
                    case RemediationType.Update:
                        var updateToken = _this.i18nService.get('tvm.securityRecommendation.descriptionTemplates.updateSoftware', { software: software });
                        var windowsOsProductIds = Object.values(_this.osNameToProductId).filter(function (val) {
                            return val.startsWith('microsoft');
                        }); // Only microsoft OS
                        var windowsPostfix = windowsOsProductIds.includes(securityRecommendation.productId)
                            ? _this.i18nService.get('tvm.securityRecommendation.descriptionTemplates.windowsPostfix')
                            : '';
                        return "" + updateToken + versionToken + windowsPostfix;
                    case RemediationType.Uninstall:
                        return "" + _this.i18nService.get('tvm.securityRecommendation.descriptionTemplates.uninstallSoftware', { software: software }) + eolPostfix;
                    case RemediationType.AttentionRequired:
                        return _this.i18nService.get('tvm.securityRecommendation.descriptionTemplates.attnSoftware', { software: software });
                }
            },
            _a[TextToken.SoftwareVersionsLinkText] = function (productName) {
                return _this.i18nService.get('tvm.securityRecommendation.remediationTaskCreation.openSoftwareVersions', {
                    software: _this.parseEntityName(productName),
                });
            },
            _a[TextToken.SoftwareVersionsName] = function (softwareVersion) {
                return _this.i18nService.get('tvm_common_softwareVersionName', {
                    softwareName: softwareVersion.name,
                });
            },
            _a[TextToken.SoftwareVersionsVendorNameAndVersion] = function (softwareVersion) {
                return _this.i18nService.get('tvm_common_softwareVersionFileName', {
                    softwareName: softwareVersion.name,
                    softwareVendor: softwareVersion.vendor,
                    softwareVersion: softwareVersion.version,
                });
            },
            _a[TextToken.SecurityRecommendationRelatedComponent] = function (securityRecommendation) {
                return _this.getRelatedComponentName(securityRecommendation.category, securityRecommendation.remediationType, securityRecommendation.productName, securityRecommendation.vendor, securityRecommendation.subCategory);
            },
            _a[TextToken.SecurityRecommendationDescription] = function (params) {
                var securityRecommendation = params.recommendation;
                var noHtmlTags = params.noHtmlTags;
                if (securityRecommendation.description)
                    return securityRecommendation.description;
                if (securityRecommendation.remediationType === RemediationType.ConfigurationChange)
                    return ''; //we expect SCA to bring recomm' description from the BE.
                var software = _this.parseEntityName(securityRecommendation.productName);
                var isZeroDayRecommendation = _this.featuresService.isEnabled(Feature.TvmZeroDay) &&
                    securityRecommendation.mostSevereVulnerabilityType === VulnerabilityType.ZeroDay &&
                    !securityRecommendation.patchReleaseDate;
                var isEol = securityRecommendation.eolSoftwareState === EolState.AlreadyEOL;
                var isUpcomingEol = securityRecommendation.eolSoftwareState === EolState.UpcomingEOL;
                var vulnerable = securityRecommendation.weaknessesCount > 0;
                var hasEolVersions = securityRecommendation.hasEolVersions;
                var hasUpcomingEolVersions = securityRecommendation.hasUpcomingEolVersions;
                if (isZeroDayRecommendation) {
                    return _this.getRecommendationZeroDayDescription(securityRecommendation, software, isEol, hasEolVersions, hasUpcomingEolVersions, noHtmlTags);
                }
                var supportNoAvailable = isEol
                    ? _this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_softwareEOL', {
                        software: software,
                    })
                    : '';
                var eolPostfix = isEol
                    ? _this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_softwareEOLPostfix')
                    : '';
                var upcomingEolNote = isUpcomingEol
                    ? _this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_upcomingEOLWithDate', {
                        softwareName: software,
                        eolSinceDate: _this.tzDateService.format(securityRecommendation.eolSoftwareSinceDate, 'shortDate'),
                    })
                    : '';
                var mitigateToken = vulnerable
                    ? _this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_toMitigateKnown', {
                        vulnerabilitiesCount: securityRecommendation.vulnerabilitiesCount,
                    })
                    : '';
                var vulnerabilitiesToken = vulnerable
                    ? securityRecommendation.vulnerabilitiesCount === 1
                        ? _this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_vulnerability')
                        : _this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_vulnerabilities')
                    : '';
                var affectingYourDevicesToken = vulnerable
                    ? _this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_affectingYourDevices')
                    : '';
                var hasEolVersionsString = hasEolVersions && hasUpcomingEolVersions
                    ? _this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_combinedEolVersions')
                    : hasEolVersions
                        ? _this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_hasEolVersions')
                        : hasUpcomingEolVersions
                            ? _this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_hasUpcomingEolVersions')
                            : '';
                switch (securityRecommendation.remediationType) {
                    case RemediationType.Upgrade:
                        if (EolWindows.includes(securityRecommendation.productId)) {
                            var prefix = _this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_osEolPrefix', { software: software });
                            var upgradeTo = _this.getUpgradeTo(securityRecommendation);
                            var newProductSuggestion = upgradeTo !== null
                                ? _this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_osEolSuggestion', { product: upgradeTo })
                                : '';
                            var postfix = _this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_osEolPostfix');
                            return "" + prefix + newProductSuggestion + " " + postfix;
                        }
                    case RemediationType.Update:
                        var updateToken = _this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_updateSoftware', { software: software });
                        var versionToken = _this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_toTheLatestVersion');
                        var windowsPostfix = software === 'Windows 10'
                            ? _this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_windowsDescriptionPostfix')
                            : '';
                        return updateToken + " " + versionToken + " " + (securityRecommendation.recommendedVersion ||
                            '') + " " + mitigateToken + " " + vulnerabilitiesToken + " " + affectingYourDevicesToken + " " + hasEolVersionsString + " " + windowsPostfix + " " + upcomingEolNote;
                    case RemediationType.Uninstall:
                        var uninstallToken = _this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_uninstallSoftware', { software: software });
                        var helpLessenToken = vulnerable
                            ? _this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_helpLessenVulnerable')
                            : _this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_helpLessenNotVulnerable');
                        return supportNoAvailable + " " + uninstallToken + " " + helpLessenToken + " " + eolPostfix;
                }
            },
            _a[TextToken.SecurityRecommendationSCAProperlyConfiguredInsight] = function (securityRecommendation) {
                var properlyConfiguredDevices = 0;
                if (securityRecommendation.assetsStatistics) {
                    var stats = securityRecommendation.assetsStatistics;
                    properlyConfiguredDevices = stats.totalAssetCount - stats.assetsAtRiskCount;
                    if (properlyConfiguredDevices < 0) {
                        // TODO: evhvoste raise alert if the value is negative (problem with data)
                        properlyConfiguredDevices = 0;
                    }
                }
                if (properlyConfiguredDevices === 0) {
                    return _this.i18nService.get('tvm.securityRecommendation.insights.noConfiguredDevices');
                }
                var properlyConfiguredDevicesPretty = _this.prettyNumberService.prettyNumber(properlyConfiguredDevices);
                var configuredPercentage = 0;
                var configuredPercentageToken = '';
                var totalAssetCount = securityRecommendation.assetsStatistics.totalAssetCount;
                if (totalAssetCount > 0) {
                    configuredPercentage = Math.round((properlyConfiguredDevices / totalAssetCount) * 100);
                    if (configuredPercentage > 0)
                        configuredPercentageToken = " (" + configuredPercentage + "%)";
                }
                var configuredDevicesToken = properlyConfiguredDevices === 1
                    ? _this.i18nService.get('tvm.securityRecommendation.insights.configuredDevicesSingular')
                    : _this.i18nService.get('tvm.securityRecommendation.insights.configuredDevices');
                return "" + properlyConfiguredDevicesPretty + configuredPercentageToken + " " + configuredDevicesToken;
            },
            _a[TextToken.SecurityRecommendationSCARecommendedBenchmarksInsight] = function (securityRecommendation) {
                if (securityRecommendation.benchmarks && securityRecommendation.benchmarks.length) {
                    var recommendedBenchmarkToken = _this.i18nService.get('tvm.securityRecommendation.insights.recommendedBenchmarks');
                    var supportedBenchmarksToken = '';
                    if (securityRecommendation.benchmarks) {
                        supportedBenchmarksToken = securityRecommendation.benchmarks.join(', ');
                    }
                    return recommendedBenchmarkToken + ": " + supportedBenchmarksToken;
                }
                else {
                    return '';
                }
            },
            _a[TextToken.SecurityRecommendationAttentionExplanation] = function (securityRecommendation) {
                var software = _this.parseEntityName(securityRecommendation.productName);
                return "" + _this.i18nService.get('tvm.securityRecommendation.requiredAttentionExplanation', {
                    software: software,
                    version: securityRecommendation.recommendedVersion,
                });
            },
            _a[TextToken.RemediationTaskStatusDescription] = function (remediationTask) {
                return _this.i18nService.get("tvm.remediationTask.status." + remediationTask.status.value);
            },
            _a[TextToken.RemediationTaskCompletedBy] = function (remediationTask) {
                switch (remediationTask.completionMethod) {
                    case RemediationTaskCompletionMethod.Manual:
                        return remediationTask.completer.email;
                    case RemediationTaskCompletionMethod.Automatic:
                        return _this.i18nService.strings.tvm_completedRemediation_automatic;
                    default:
                        return _this.i18nService.strings.notAvailable_short;
                }
            },
            _a[TextToken.RemediationTicketStatusDescription] = function (remediationTicket) {
                var ticketStatus = remediationTicket.status || RemediationTicketStateValue.Unknown;
                var ticketStatusValue = remediationTicket.rawStatus
                    ? remediationTicket.rawStatus
                    : _this.i18nService.get("tvm.remediationTask.ticket.status." + ticketStatus, null, true);
                if (ticketStatusValue === '') {
                    ticketStatusValue = ticketStatus;
                }
                return ticketStatusValue.concat(" (" + remediationTicket.itsmTool + ")");
            },
            _a[TextToken.VulnerabilityInstalledAffectedProduct] = function (product) {
                try {
                    return _this.generateProductString(product.vendor, product.productName, product.productVersion, true);
                }
                catch (error) {
                    _this.i18nService.get('common.noDataAvailable');
                }
            },
            _a[TextToken.OsFeatureTooltip] = function (vulnerability) {
                if (!vulnerability.osFeatures || vulnerability.osFeatures.length < 1) {
                    return _this.i18nService.get('common.noDataAvailable');
                }
                return "" + vulnerability.osFeatures.join("<br>");
            },
            _a[TextToken.RelatedSoftwareTooltip] = function (vulnerability) {
                return _this.getFormattedRelatedSoftwareTooltip(vulnerability.productIds);
            },
            _a[TextToken.InstallationAggVersionsTooltip] = function (softwareInstallation) {
                try {
                    return "<div>\n\t\t\t\t\t" + softwareInstallation.installedVersions.reduce(function (prev, curr) { return prev + "<div>" + curr + "</div>"; }, '') + "\n\t\t\t\t</div>\n\t\t\t";
                }
                catch (error) {
                    return _this.i18nService.get('common.noDataAvailable');
                }
            },
            _a[TextToken.InstallationAggVersions] = function (softwareInstallation) {
                try {
                    var firstVersionName = softwareInstallation.installedVersions[0];
                    var versionsCount = softwareInstallation.installedVersions.length;
                    return versionsCount > 1
                        ? firstVersionName + " (+" + (versionsCount - 1) + " more)"
                        : firstVersionName;
                }
                catch (error) {
                    _this.i18nService.get('common.noDataAvailable');
                }
            },
            _a[TextToken.AffectedProduct] = function (affectedProduct) {
                try {
                    var array = [
                        _this.generateProductString(affectedProduct.vendor, affectedProduct.product, null, true),
                    ];
                    if (affectedProduct.productVersion && affectedProduct.productVersion.length) {
                        array.push(_this.i18nService.get('tvm.detectionLogic.version'), affectedProduct.productVersion);
                        return array.join(' ');
                    }
                    var minVersion = false;
                    if (affectedProduct.productMinVersion && affectedProduct.productMinVersion.length) {
                        if (affectedProduct.productMinVersion === affectedProduct.productMaxVersion) {
                            // this is actually an exact version
                            array.push(_this.i18nService.get('tvm.detectionLogic.version'), affectedProduct.productMinVersion);
                            return array.join(' ');
                        }
                        minVersion = true;
                        var applyToVersion = affectedProduct.productMinVersionIsIncluded
                            ? _this.i18nService.get('tvm.detectionLogic.includingVersion')
                            : _this.i18nService.get('tvm.detectionLogic.excludingVersion');
                        array.push(_this.i18nService.get('tvm.detectionLogic.versions'), affectedProduct.productMinVersion, '(' + applyToVersion + ')');
                    }
                    if (affectedProduct.productMaxVersion && affectedProduct.productMaxVersion.length) {
                        var applyToVersion = affectedProduct.productMaxVersionIsIncluded
                            ? _this.i18nService.get('tvm.detectionLogic.includingVersion')
                            : _this.i18nService.get('tvm.detectionLogic.excludingVersion');
                        if (minVersion) {
                            array.push(_this.i18nService.get('tvm.detectionLogic.upToVersion'), affectedProduct.productMaxVersion, '(' + applyToVersion + ')');
                        }
                        else {
                            array.push(affectedProduct.productMaxVersion, '(' + applyToVersion + ')', _this.i18nService.get('tvm.detectionLogic.noLeftBoundary'));
                        }
                        return array.join(' ');
                    }
                    return '';
                }
                catch (error) {
                    _this.i18nService.get('common.noDataAvailable');
                }
            },
            _a[TextToken.VulnerabilityOsFeature] = function (vulnerability) {
                if (!vulnerability.osFeatures || vulnerability.osFeatures.length < 1) {
                    return _this.i18nService.get('common.noDataAvailable');
                }
                var firstFeature = vulnerability.osFeatures[0];
                return vulnerability.osFeatures.length > 1
                    ? firstFeature + " (" + _this.i18nService.get('tvm.common.plusMore', {
                        amount: vulnerability.osFeatures.length - 1,
                    }) + ")"
                    : firstFeature;
            },
            _a[TextToken.RelatedSoftware] = function (vulnerability) {
                return _this.getFormattedRelatedSoftware(vulnerability.productIds);
            },
            _a[TextToken.VulnerabilityAge] = function (vulnerability) {
                return moment.duration(moment(new Date()).diff(moment(vulnerability.published))).humanize();
            },
            _a[TextToken.ExposureScoreWidgetInfo] = function () {
                return "<p>" + _this.i18nService.get('tvm.exposureScoreWidget.info.pre') + "</p>" + _this.generateList('tvm.exposureScoreWidget.info.items') + "<p>" + _this.i18nService.get('tvm.exposureScoreWidget.info.post') + "</p>";
            },
            _a[TextToken.ExposureScoreWidgetInfoAriaLabel] = function () {
                return _this.i18nService.get('tvm.exposureScoreWidget.info.pre') + " " + _this.generateAriaLabelList('tvm.exposureScoreWidget.info.items') + " " + _this.i18nService.get('tvm.exposureScoreWidget.info.post');
            },
            _a[TextToken.ConfigurationScoreWidgetInfo] = function () { return _this.splitAndJoin('tvm.secureScoreWidget.info'); },
            _a[TextToken.ScoreImpactInfo] = function () { return _this.splitAndJoin('tvm.securityRecommendation.impactTooltip'); },
            _a[TextToken.ExposureScoreTooltip] = function () {
                return _this.splitAndJoin('tvm.securityRecommendation.exposureScoreTooltip');
            },
            _a[TextToken.ConfigurationScoreTooltip] = function () {
                return _this.splitAndJoin('tvm.securityRecommendation.configurationScoreTooltip');
            },
            _a[TextToken.ExposureScoreForSoftware] = function (data) {
                return _this.i18nService.get('tvm.securityRecommendation.exposureScoreSoftwareTooltip', {
                    product: data,
                });
            },
            _a[TextToken.EntityName] = function (rawName) { return _this.parseEntityName(rawName); },
            _a[TextToken.EntityFullName] = function (_a) {
                var productName = _a[0], vendorName = _a[1];
                return _this.parseFullProductName(productName, vendorName);
            },
            _a[TextToken.ExploitIconToolTip] = function (toolTipInfo) {
                var hasExploitToolTipKey = "tvm." + toolTipInfo.entityType + ".exploitAvailableTooltip";
                var hasDetailedExploitToolTip = "tvm." + toolTipInfo.entityType + ".exploitToolTip";
                var noExploitToolTipKey = "tvm." + toolTipInfo.entityType + ".noExploitAvailableTooltip";
                var exploitLinkedToThreatKey = "tvm." + toolTipInfo.entityType + ".exploitLinkedToThreat";
                var inExploitKitToolTipKey = "tvm.common.exploitInExploitKit";
                var threatInfo = toolTipInfo.threatInfo;
                var isNewExploitExperienceEnabled = _this.featuresService.isEnabled(Feature.TvmExploits);
                return "\n\t\t\t<div style=\"max-width:350px;\">\n\t\t\t\t<div class=\"wcd-tooltip__title\">Threat insights</div>\n\t\t\t\t<ul>\n\t\t\t\t" + _this.GetExploitToolTip(threatInfo.hasExploit && isNewExploitExperienceEnabled, threatInfo.isExploitVerified, threatInfo.exploitTypes, hasExploitToolTipKey, hasDetailedExploitToolTip) + "\n\t\t\t\t" + _this.GetNoExploitText(!threatInfo.hasExploit && !threatInfo.isExploitLinkedToThreat, noExploitToolTipKey) + "\n\t\t\t\t" + _this.GetOldExploit(threatInfo.hasExploit && !isNewExploitExperienceEnabled, hasExploitToolTipKey // delete this if after TvmExploit feature removal.
                ) + "\n\t\t\t\t" + _this.GetExploitKit(threatInfo.isInExploitKit && isNewExploitExperienceEnabled, inExploitKitToolTipKey) + "\n\t\t\t\t" + _this.GetThreatDetails(threatInfo.isExploitLinkedToThreat, toolTipInfo.threats, exploitLinkedToThreatKey) + "\n\t\t\t\t</ul></div>\n\t\t\t\t";
            },
            _a[TextToken.ActiveThreatIconTooltip] = function (toolTipInfo) {
                var activeThreatBullet = '';
                if (toolTipInfo.isThreatActive) {
                    // Show all alerts related to the threats over the past week, with any status.
                    // Note that the parameter name is IoaDefinitionIds, but it actually refers to threatId/outbreakId (synonyms)
                    var link = '';
                    if (toolTipInfo.threats) {
                        var threatIds = toolTipInfo.threats.map(function (t) { return t.threatId; }).join('|');
                        var filters = "IoaDefinitionIds=" + encodeURI(threatIds);
                        link = "alertsQueue?filters=" + filters + "&range=week";
                        activeThreatBullet = _this.i18nService.get("tvm." + toolTipInfo.entityType + ".threatActiveTooltip", {
                            link: link,
                        });
                    }
                    else {
                        activeThreatBullet = _this.i18nService.get("tvm." + toolTipInfo.entityType + ".threatActiveTooltipNoLink");
                    }
                }
                else {
                    activeThreatBullet = _this.i18nService.get("tvm." + toolTipInfo.entityType + ".noThreatActiveTooltip");
                }
                var tooltipHtml = "\n\t\t\t<div style=\"max-width:350px;\">\n\t\t\t\t<div class=\"wcd-tooltip__title\">Breach insights</div>\n\t\t\t\t<ul>\n\t\t\t\t\t<li>" + activeThreatBullet + "</li>\n\t\t\t\t</ul>\n\t\t\t</div>";
                return tooltipHtml;
            },
            _a[TextToken.SecurityRecommendationJustificationNotification] = function (exceptionJustification) {
                var isScoringRelevant = exceptionJustification === ExceptionJustification.ThirdPartyControl ||
                    exceptionJustification === ExceptionJustification.AlternateMitigation;
                return isScoringRelevant
                    ? _this.i18nService.get('tvm.securityRecommendation.recommendationExceptionCreation.justificationScore')
                    : undefined;
            },
            _a[TextToken.NoDataForWidget] = function (params) {
                var defaultNoData = _this.i18nService.get(params.noDataKey);
                if (params.isGroupSelected) {
                    defaultNoData += _this.i18nService.get('tvm.common.selectedGroups');
                }
                return defaultNoData;
            },
            _a[TextToken.ScoreWidgetNoData] = function (isGroupSelected) {
                return _this.textsMap[TextToken.NoDataForWidget]({
                    noDataKey: 'tvm.common.noOnboardedMachines',
                    isGroupSelected: isGroupSelected,
                });
            },
            _a[TextToken.ReportInaccuracyReasonTitle] = function (context) {
                return _this.i18nService.get('tvm.reportInaccuracy.inaccuracyReason', {
                    context: _this.i18nService.get(_this.contextToKey[context]),
                });
            },
            _a[TextToken.OrgRecommendationProductivityImpact] = function (stats) {
                var countHtml = _this.countAndPercentBoldHtml(stats);
                if (countHtml === _this._noDataAvailableText) {
                    return _this._noDataAvailableText;
                }
                var noImpactStr = _this.i18nService.strings.tvm_securityRecommendation_userImpact_noImpactOrg;
                var noImpactHtml = _this.addBoldHtml(noImpactStr, GREEN_COLOR) + '.';
                var descriptionWithCounter = _this.i18nService.get('tvm_securityRecommendation_userImpact_safeMachinesDescription', {
                    counters: countHtml,
                });
                return descriptionWithCounter + " " + noImpactHtml;
            },
            _a[TextToken.OrgRecommendationProductivityImpactTooltip] = function (stats) {
                var impactDescription = _this.getText(TextToken.OrgRecommendationProductivityImpact, stats);
                return _this.createProductivityImpactIconTooltip(impactDescription);
            },
            _a[TextToken.AssetRecommendationProductivityImpactTooltip] = function () {
                var impactDescription = _this.getText(TextToken.AssetRecommendationProductivityImpact, false);
                return _this.createProductivityImpactIconTooltip(impactDescription);
            },
            _a[TextToken.AssetRecommendationProductivityImpact] = function (isProductivityImpacted) {
                if (isProductivityImpacted === null) {
                    _this.i18nService.get('common.noDataAvailable');
                }
                var baseKey = 'tvm.securityRecommendation.userImpact';
                var htmlRisk, riskKey;
                if (isProductivityImpacted) {
                    var suffix = _this.i18nService.get(baseKey + ".potentialRisk");
                    htmlRisk = _this.addBoldHtml(suffix);
                    riskKey = 'assetWithRisk';
                }
                else {
                    var suffix = _this.i18nService.get(baseKey + ".noImpactAsset");
                    htmlRisk = _this.addBoldHtml(suffix, GREEN_COLOR);
                    riskKey = 'assetWithoutRisk';
                }
                return _this.i18nService.get(baseKey + "." + riskKey, { risk: htmlRisk }) + '.';
            },
            _a[TextToken.RemediationTaskSafeMachines] = function (stats) {
                return _this.countAndPercentBoldHtml(stats);
            },
            _a[TextToken.RemediationTicketStatus] = function (remediationDescriptor) {
                if (remediationDescriptor.status in (RemediationTicketStateValue || RemediationTaskStateValue)) {
                    return _this.i18nService.get("tvm.securityRecommendation.relatedRemediation.remediationStatus." + remediationDescriptor.status);
                }
                return remediationDescriptor.status;
            },
            _a[TextToken.ExceptionRemediationType] = function (recommendationException) {
                var type = recommendationException.exceptionArgs.type;
                var remediationType = type === ExceptionType.ConfigurationChange
                    ? type
                    : recommendationException.exceptionArgs.vaRecommendationArgs.recommendationType;
                return _this.i18nService.get("tvm.remediationTask.type." + remediationType);
            },
            _a[TextToken.AggregatedExceptionRequester] = function (recommendationExceptionAggregated) {
                return recommendationExceptionAggregated.requesterCount > 1
                    ? recommendationExceptionAggregated.requesterCount + " users"
                    : recommendationExceptionAggregated.requester
                        ? recommendationExceptionAggregated.requester.email
                        : _this.i18nService.get('notAvailable.short');
            },
            _a[TextToken.AggregatedExceptionScope] = function (recommendationExceptionAggregated) {
                return recommendationExceptionAggregated.isGlobalException
                    ? 'Global'
                    : recommendationExceptionAggregated.rbacGroupIdsCount + " device groups";
            },
            _a[TextToken.ChangeEventActivity] = function (changeEvent) {
                return _this.generateChangeEventActivity(changeEvent);
            },
            _a[TextToken.SoftwareEolOsLegalNote] = function (productId) {
                return _this.getSoftwareEolOsLegalNoteText(productId, _this.i18nService.strings.tvm_securityRecommendation_notice);
            },
            _a[TextToken.SoftwareEolOsLegalNoteRecommendation] = function (productId) {
                return _this.getSoftwareEolOsLegalNoteText(productId, _this.i18nService.strings.tvm_securityRecommendation_securityRecommendation);
            },
            _a[TextToken.HasEolVersionsText] = function (productName) {
                return _this.i18nService.get('tvm.securityRecommendation.hasEolVersionsText', {
                    product: _this.parseEntityName(productName),
                });
            },
            _a[TextToken.HasUpcomingEolVersionsText] = function (productName) {
                return _this.i18nService.get('tvm.securityRecommendation.hasUpcomingEolVersionsText', {
                    product: _this.parseEntityName(productName),
                });
            },
            _a[TextToken.CombinedEolVersionsText] = function (productName) {
                return _this.i18nService.get('tvm.securityRecommendation.combinedEolVersionsText', {
                    product: _this.parseEntityName(productName),
                });
            },
            _a[TextToken.HasEolVersionsMicrosoftLegalNote] = function (productId) {
                return _this.getHasEolVersionsMicrosoftLegalNoteText(productId, _this.i18nService.get('tvm.securityRecommendation.notice'));
            },
            _a[TextToken.HasEolVersionsMicrosoftLegalNoteRecommendation] = function (productId) {
                return _this.getHasEolVersionsMicrosoftLegalNoteText(productId, _this.i18nService.strings.tvm_securityRecommendation_securityRecommendation);
            },
            _a[TextToken.LegalNoteLinkText] = function (productId) {
                return productId === MicrosoftProductIdsWithLegalNote.SqlServer2012 ||
                    productId === MicrosoftProductIdsWithLegalNote.SqlServer2014 ||
                    productId === MicrosoftProductIdsWithLegalNote.SqlServer2016
                    ? _this.i18nService.get('tvm.securityRecommendation.legalNoteLinkTextSqlServer')
                    : productId === MicrosoftProductIdsWithLegalNote.Windows10
                        ? _this.i18nService.get('tvm.securityRecommendation.legalNoteLinkTextWindows10')
                        : '';
            },
            _a[TextToken.EolVersionKbLegalNote] = function (productId) {
                return _this.i18nService.get('tvm.softwarePage.missingKb.eolText', {
                    product: _this.parseEntityName(productId),
                });
            },
            _a[TextToken.UpcomingEolVersionKbLegalNote] = function (productId) {
                return _this.i18nService.get('tvm.softwarePage.missingKb.upcomingEolText', {
                    product: _this.parseEntityName(productId),
                });
            },
            _a[TextToken.RemediationRelatedComponent] = function (remediationTask) {
                return remediationTask.taskArgs.category === RemediationCategory.SecurityConfiguration
                    ? remediationTask.relatedComponent
                    : _this.parseFullProductName(remediationTask.taskArgs.softwareArgs.nameId, remediationTask.taskArgs.softwareArgs.vendorId);
            },
            _a[TextToken.RecommendationExceptionRelatedComponent] = function (recommendationException) {
                return recommendationException.exceptionArgs.type === ExceptionType.SoftwarePatch
                    ? _this.parseFullProductNameFromId(recommendationException.exceptionArgs.vaRecommendationArgs.productId)
                    : '';
            },
            _a[TextToken.UpgradeLinkText] = function (SecurityRecommendation) {
                var upgradeTo = _this.getUpgradeTo(SecurityRecommendation);
                return upgradeTo !== null
                    ? _this.i18nService.get('tvm.securityRecommendation.descriptionTemplates.upgradeSoftwareToLatestVersionOf', { software: upgradeTo })
                    : _this.i18nService.get('tvm.securityRecommendation.descriptionTemplates.readMore');
            },
            _a[TextToken.EolSoftwareText] = function (eolSoftwareSinceDate) {
                return eolSoftwareSinceDate
                    ? _this.i18nService.get('tvm.securityRecommendation.eolTextWithDate', {
                        date: _this.tzDateService.format(eolSoftwareSinceDate, 'shortDate'),
                    })
                    : _this.i18nService.strings.tvm_securityRecommendation_eolText;
            },
            _a[TextToken.UpcomingEolSoftwareText] = function (eolSoftwareSinceDate) {
                return eolSoftwareSinceDate
                    ? _this.i18nService.get('tvm.securityRecommendation.upcomingEolTextWithDate', {
                        date: _this.tzDateService.format(eolSoftwareSinceDate, 'shortDate'),
                    })
                    : _this.i18nService.strings.tvm_securityRecommendation_upcomingEolText;
            },
            _a[TextToken.RemediationBlockTitle] = function (remediationBlock) {
                var software = _this.parseFullProductNameFromId(remediationBlock.id);
                var blockedSoftwareText = _this.i18nService.get('tvm.blockedApps.descriptionTemplates.blockSoftware', { software: software });
                return blockedSoftwareText;
            },
            _a[TextToken.RemediationBlockStatus] = function (remediationBlock) {
                return _this.i18nService.get("tvm_blockedApps_status_" + remediationBlock.status);
            },
            _a[TextToken.RemediationBlockSoftwareTitle] = function (remediationBlock) {
                return _this.parseFullProductNameFromId(remediationBlock.id);
            },
            _a[TextToken.SoftwareNameFromId] = function (softwareId) {
                return _this.getProductNameAndVendorFromId(softwareId)[1];
            },
            _a[TextToken.MitigationDescription] = function (blockedSince) {
                return _this.i18nService.get('tvm.blockedApps.mitigationDescription', {
                    time: _this.tzDateService.format(blockedSince, 'shortDate'),
                });
            },
            _a[TextToken.HighValueAssetDescription] = function () {
                var description = _this.i18nService.get('machines.entityDetails.actions.machineValue.description');
                var examplesTitle = _this.i18nService.get('machines.entityDetails.actions.machineValue.description.examples.title');
                var examples = _this.generateList('machines.entityDetails.actions.machineValue.description.examples');
                return "<p>" + description + "</p><br><p>" + examplesTitle + examples + "</p>";
            },
            _a[TextToken.RedHatLegalNotice] = function () {
                var aTagFirst = "<a target=\"_blank\" rel=\"noopener noreferrer\"\n\t\t\t\thref=\"https://access.redhat.com/documentation/en-us/red_hat_security_data_api/1.0/html-single/red_hat_security_data_api/index#idm140581130148368\">";
                var aTagSecond = '<a target="_blank" rel="noopener noreferrer" href="https://creativecommons.org/licenses/by/4.0/">';
                return "<span>" + _this.i18nService.strings.common_legal_notice_red_hat_first_link_prefix + "\n\t\t\t" + aTagFirst + _this.i18nService.strings.common_legal_notice_red_hat_first_link_text + "</a>\n\t\t\t" + _this.i18nService.strings.common_legal_notice_red_hat_second_link_prefix + "\n\t\t\t" + aTagSecond + _this.i18nService.strings.common_legal_notice_red_hat_second_link_text + "</a>\n\t\t\t" + _this.i18nService.strings.common_legal_notice_red_hat_second_link_suffix + "</span>";
            },
            _a);
        // TODO: ahsoboh - refactor all of the recommendation context maps to another one map with interface. VSTS 25149723
        this.recommendationContextToCountHeader = (_b = {},
            _b[RecommendationContextType.ContextWithServiceName] = this.i18nService.get('tvm.securityRecommendation.recommendationContext.misconfiguredServicesHeader'),
            _b[RecommendationContextType.ContextWithServiceNameAndServicePath] = this.i18nService.get('tvm.securityRecommendation.recommendationContext.misconfiguredServicesHeader'),
            _b[RecommendationContextType.ContextWithUserName] = this.i18nService.get('tvm.securityRecommendation.recommendationContext.misconfiguredUsersHeader'),
            _b[RecommendationContextType.ContextWithShareNameAndSharePath] = this.i18nService.get('tvm.securityRecommendation.recommendationContext.misconfiguredSharesHeader'),
            _b[RecommendationContextType.Unknown] = this._noDataAvailableText,
            _b);
        this.recommendationContextToExposedSectionTitle = (_c = {},
            _c[RecommendationContextType.ContextWithServiceName] = this.i18nService.get('tvm.securityRecommendation.recommendationContext.exposedServices.title'),
            _c[RecommendationContextType.ContextWithServiceNameAndServicePath] = this.i18nService.get('tvm.securityRecommendation.recommendationContext.exposedServices.title'),
            _c[RecommendationContextType.ContextWithUserName] = this.i18nService.get('tvm.securityRecommendation.recommendationContext.exposedUsers.title'),
            _c[RecommendationContextType.ContextWithShareNameAndSharePath] = this.i18nService.get('tvm.securityRecommendation.recommendationContext.exposedShares.title'),
            _c[RecommendationContextType.Unknown] = this._noDataAvailableText,
            _c);
        this.recommendationContextToExposedSectionLoadingTitle = (_d = {},
            _d[RecommendationContextType.ContextWithServiceName] = this.i18nService.get('tvm.securityRecommendation.recommendationContext.exposedServices.loadingTitle'),
            _d[RecommendationContextType.ContextWithServiceNameAndServicePath] = this.i18nService.get('tvm.securityRecommendation.recommendationContext.exposedServices.loadingTitle'),
            _d[RecommendationContextType.ContextWithUserName] = this.i18nService.get('tvm.securityRecommendation.recommendationContext.exposedUsers.loadingTitle'),
            _d[RecommendationContextType.ContextWithShareNameAndSharePath] = this.i18nService.get('tvm.securityRecommendation.recommendationContext.exposedShares.loadingTitle'),
            _d[RecommendationContextType.Unknown] = this._noDataAvailableText,
            _d);
        this.recommendationContextToContextKeyPaneLoadingTitle = (_e = {},
            _e[RecommendationContextType.ContextWithServiceName] = this.i18nService.get('tvm.securityRecommendation.recommendationContext.servicePane.loadingTitle'),
            _e[RecommendationContextType.ContextWithServiceNameAndServicePath] = this.i18nService.get('tvm.securityRecommendation.recommendationContext.servicePane.loadingTitle'),
            _e[RecommendationContextType.ContextWithUserName] = this.i18nService.get('tvm.securityRecommendation.recommendationContext.userPane.loadingTitle'),
            _e[RecommendationContextType.ContextWithShareNameAndSharePath] = this.i18nService.get('tvm.securityRecommendation.recommendationContext.sharePane.loadingTitle'),
            _e[RecommendationContextType.Unknown] = this._noDataAvailableText,
            _e);
        this.contextToKey = (_f = {},
            _f[InaccuracyContext.MachineScaRecommendation] = 'tvm.reportInaccuracy.contexts.recommendations',
            _f[InaccuracyContext.MachineVaRecommendation] = 'tvm.reportInaccuracy.contexts.recommendations',
            _f[InaccuracyContext.OrgScaRecommendation] = 'tvm.reportInaccuracy.contexts.recommendations',
            _f[InaccuracyContext.OrgVaRecommendation] = 'tvm.reportInaccuracy.contexts.recommendations',
            _f[InaccuracyContext.MachineInventory] = 'tvm.reportInaccuracy.contexts.inventory',
            _f[InaccuracyContext.OrgInventory] = 'tvm.reportInaccuracy.contexts.inventory',
            _f[InaccuracyContext.MachineVulnerabilities] = 'tvm.reportInaccuracy.contexts.vulnerabilities',
            _f[InaccuracyContext.OrgVulnerabilities] = 'tvm.reportInaccuracy.contexts.vulnerabilities',
            _f);
        this.exploitTypesToText = {
            Remote: this.i18nService.get('tvm.common.remoteExploit'),
            WebApps: this.i18nService.get('tvm.common.webappExploit'),
            Local: this.i18nService.get('tvm.common.localExploit'),
            Dos: this.i18nService.get('tvm.common.dosExploit'),
        };
        this.productivityImpactToText = (_g = {},
            _g[ProductivityImpactRemediationType.NonImpactedAssets] = this.i18nService.get('tvm.securityRecommendation.remediationTaskCreation.productivityImpactSelection.safeAssets'),
            _g[ProductivityImpactRemediationType.AllExposedAssets] = this.i18nService.get('tvm.securityRecommendation.remediationTaskCreation.productivityImpactSelection.allAssets'),
            _g);
        this.getMostSevereExploitType = function (types) {
            if (types.includes('Remote')) {
                return _this.exploitTypesToText['Remote'];
            }
            else if (types.includes('WebApps')) {
                return _this.exploitTypesToText['WebApps'];
            }
            else if (types.includes('Local')) {
                return _this.exploitTypesToText['Local'];
            }
            else if (types.includes('Dos')) {
                return _this.exploitTypesToText['Dos'];
            }
            else {
                return '';
            }
        };
        this.generateProductString = function (vendor, productName, version, handleVendorProductDuplication) {
            var array = [_this.parseEntityName(vendor)];
            // In cases like python:python, we don't want the double name
            if (handleVendorProductDuplication && vendor !== productName) {
                array.push(_this.parseEntityName(productName));
            }
            if (version) {
                array.push(version);
            }
            return array.join(' ');
        };
        /**
         * replacer for the domain's deprecated parseEntityName
         */
        // TODO: consider using 'parseFullProductName' when using this to parse product names
        this.parseEntityName = function (rawName, separator) {
            if (separator === void 0) { separator = '_'; }
            return rawName
                .split(separator)
                .map(function (s) { return s.charAt(0).toUpperCase() + s.substring(1); })
                .join(' ')
                .replace(' For Mac', ' for Mac')
                .replace(' For Linux', ' for Linux'); // TODO: clean this up once we have OS Kind column (Task: 26044745)
        };
        this.parseFullProductName = function (rawProductName, rawVendorName) {
            var productName = _this.parseEntityName(rawProductName);
            var regex = new RegExp('_|-', 'g');
            var noSpacesProdName = rawProductName.split(regex).join('');
            var noSpacesVendorName = rawVendorName.split(regex).join('');
            if (rawProductName === rawVendorName ||
                _this.vendorAndProductNamesBlacklist[rawVendorName] === rawProductName ||
                noSpacesProdName.startsWith(noSpacesVendorName) ||
                noSpacesVendorName.startsWith(noSpacesProdName)) {
                return productName;
            }
            return _this.parseEntityName(rawVendorName) + " " + productName;
        };
        this.parseFullProductNameFromId = function (productId) {
            var _a = _this.getProductNameAndVendorFromId(productId), vendor = _a[0], name = _a[1];
            return _this.parseFullProductName(name, vendor);
        };
        this.splitAndJoin = function (token) {
            return _this.i18nService
                .get(token)
                .split('\n')
                .join('<br/>');
        };
        this.generateList = function (itemsToken) {
            var items = _this.i18nService.get(itemsToken).split('<item>');
            items.shift();
            return _this.generateListFromArray(items);
        };
        this.generateListFromArray = function (items) {
            if (!items.length)
                return '';
            return "<ul>" + items.reduce(function (acc, curr) { return acc + "<li>" + curr + "</li>"; }, '') + "</ul>";
        };
        this.generateAriaLabelList = function (itemsToken) {
            var items = _this.i18nService.get(itemsToken).split('<item>');
            return items.shift().toString();
        };
        this.osNameToProductId = {
            Windows10: 'microsoft-_-windows_10',
            WindowsServer2019: 'microsoft-_-windows_server_2019',
            WindowsServer2016: 'microsoft-_-windows_server_2016',
            WindowsServer2012R2: 'microsoft-_-windows_server_2012_r2',
            WindowsServer2008R2: 'microsoft-_-windows_server_2008_r2',
            macOS: 'apple-_-mac_os',
        };
        // if a pair is in this blacklist, we display only the product name
        this.vendorAndProductNamesBlacklist = {
            coupons: 'coupon_printer_for_windows',
            notepad_plus_plus: 'notepad++',
        };
        this.getProductNameAndVendorFromId = function (productId) {
            var productIdSplit = productId.split('-_-');
            return [productIdSplit[0], productIdSplit[1]];
        };
        this._otherInaccuracyReason = this.i18nService.get('tvm.reportInaccuracy.reasons.otherReason');
        this._yesLabel = this.i18nService.get('common.yes');
        this._noLabel = this.i18nService.get('common.no');
        this._unknownLabel = this.i18nService.get('common.unknown');
        this.setContextToReasonsMap();
    }
    TvmTextsService.prototype.getProductivityImpactedLabel = function (productivityImpacted) {
        if (productivityImpacted === null) {
            return this._unknownLabel;
        }
        return productivityImpacted ? this._yesLabel : this._noLabel;
    };
    Object.defineProperty(TvmTextsService.prototype, "otherInaccuracyReason", {
        get: function () {
            return this._otherInaccuracyReason;
        },
        enumerable: true,
        configurable: true
    });
    TvmTextsService.prototype.countAndPercentBoldHtml = function (stats) {
        var nonImpactedAssets = stats.nonProductivityImpactedAssets;
        if (!nonImpactedAssets || nonImpactedAssets > stats.assetsAtRiskCount) {
            return this._noDataAvailableText;
        }
        var prettyCounter = this.prettyNumberService.prettyNumber(nonImpactedAssets);
        var percentage = Math.round((nonImpactedAssets / stats.assetsAtRiskCount) * 100);
        var rawCounters = prettyCounter + " (" + percentage + "%)";
        return this.addBoldHtml(rawCounters, GREEN_COLOR);
    };
    TvmTextsService.prototype.addBoldHtml = function (s, color) {
        if (color === void 0) { color = 'ms-color-black'; }
        return "<span class=\"wcd-font-weight-semibold " + color + "\">" + s + "</span>";
    };
    TvmTextsService.prototype.createProductivityImpactIconTooltip = function (description) {
        if (description === this._noDataAvailableText) {
            return this._noDataAvailableText;
        }
        var title = this.i18nService.get('tvm.securityRecommendation.configurationChange.userImpact');
        return "<h5 class=wcd-font-weight-semibold>" + title + "</h5>" + description;
    };
    TvmTextsService.prototype.setContextToReasonsMap = function () {
        var _a;
        var recommendationsBaseList = [
            this.i18nService.get('tvm.reportInaccuracy.reasons.recommendations.alreadyFixed'),
            this.i18nService.get('tvm.reportInaccuracy.reasons.recommendations.missingActions'),
            this.i18nService.get('tvm.reportInaccuracy.reasons.recommendations.wrongCallForAction'),
            this.i18nService.get('tvm.reportInaccuracy.reasons.recommendations.details'),
            this._otherInaccuracyReason,
        ];
        var vaRecommendationsList = [
            this.i18nService.get('tvm.reportInaccuracy.reasons.recommendations.wrongVersion')
        ].concat(recommendationsBaseList);
        var vulnerabilitiesBaseList = [
            this.i18nService.get('tvm.reportInaccuracy.reasons.vulnerabilities.details'),
            this.i18nService.get('tvm.reportInaccuracy.reasons.vulnerabilities.missingExploit'),
            this._otherInaccuracyReason,
        ];
        var orgVulnerabilitiesList = [
            this.i18nService.get('tvm.reportInaccuracy.reasons.vulnerabilities.noAffectOrg')
        ].concat(vulnerabilitiesBaseList);
        var machineVulnerabilitiesList = [
            this.i18nService.get('tvm.reportInaccuracy.reasons.vulnerabilities.noAffectMachine')
        ].concat(vulnerabilitiesBaseList);
        var inventoryBaseList = [
            this.i18nService.get('tvm.reportInaccuracy.reasons.inventory.details'),
            this._otherInaccuracyReason,
        ];
        var orgInventoryList = [
            this.i18nService.get('tvm.reportInaccuracy.reasons.inventory.doesNotExistInOrg')
        ].concat(inventoryBaseList);
        var machineInventoryList = [
            this.i18nService.get('tvm.reportInaccuracy.reasons.inventory.wrongVersion'),
            this.i18nService.get('tvm.reportInaccuracy.reasons.inventory.doesNotExist')
        ].concat(inventoryBaseList);
        this.contextToReasons = (_a = {},
            _a[InaccuracyContext.MachineScaRecommendation] = recommendationsBaseList,
            _a[InaccuracyContext.MachineVaRecommendation] = vaRecommendationsList,
            _a[InaccuracyContext.MachineInventory] = machineInventoryList,
            _a[InaccuracyContext.MachineVulnerabilities] = machineVulnerabilitiesList,
            _a[InaccuracyContext.OrgScaRecommendation] = recommendationsBaseList,
            _a[InaccuracyContext.OrgVaRecommendation] = vaRecommendationsList,
            _a[InaccuracyContext.OrgInventory] = orgInventoryList,
            _a[InaccuracyContext.OrgVulnerabilities] = orgVulnerabilitiesList,
            _a);
    };
    TvmTextsService.prototype.getFormattedRelatedSoftware = function (products) {
        try {
            var firstProductName = this.parseEntityName(products[0].split('-_-')[1]);
            return products.length > 1
                ? firstProductName + " (+" + (products.length - 1) + " more)"
                : firstProductName;
        }
        catch (error) {
            this.i18nService.get('common.noDataAvailable');
        }
    };
    TvmTextsService.prototype.getFormattedRelatedSoftwareTooltip = function (products) {
        var _this = this;
        try {
            return "<div>\n\t\t\t\t" + products
                .map(function (id) { return _this.parseEntityName(id.split('-_-')[1]); })
                .reduce(function (prev, curr) { return prev + "<div>" + curr + "</div>"; }, '') + "\n\t\t\t</div>\n\t\t";
        }
        catch (error) {
            return this.i18nService.get('common.noDataAvailable');
        }
    };
    TvmTextsService.prototype.getSelectedProductivityRemediation = function (impactType) {
        var impactStr = this.productivityImpactToText[impactType];
        return impactType === ProductivityImpactRemediationType.AllExposedAssets
            ? this.addBoldHtml(impactStr)
            : this.addBoldHtml(impactStr, GREEN_COLOR);
    };
    TvmTextsService.prototype.getOsPlatformLabel = function (platformId) {
        if (!platformId)
            return '';
        return operatingSystemPlatformValues.find(function (os) { return os.id === platformId; }).name;
    };
    TvmTextsService.prototype.hasOsPlatformLabel = function (platformId) {
        return operatingSystemPlatformValues.map(function (_a) {
            var id = _a.id;
            return id;
        }).includes(platformId);
    };
    TvmTextsService.prototype.getBaselineBenchmarkLabel = function (benchmarkId) {
        if (!benchmarkId)
            return '';
        var benchmark = baselineBaseBenchmarkValues.find(function (benchmark) { return benchmark.id === benchmarkId; });
        return benchmark ? benchmark.name : undefined;
    };
    TvmTextsService.prototype.getOsProductName = function (platformId) {
        return this.osNameToProductId[platformId];
    };
    TvmTextsService.prototype.getCountedProductivityImpactRemediations = function (stats) {
        var _a;
        var nonImpactedAssets = stats.nonProductivityImpactedAssets;
        if (!stats || !nonImpactedAssets || nonImpactedAssets > stats.assetsAtRiskCount) {
            return null;
        }
        var safeCount = " (" + this.prettyNumberService.prettyNumber(stats.nonProductivityImpactedAssets) + ")";
        var allExposedCount = " (" + this.prettyNumberService.prettyNumber(stats.assetsAtRiskCount) + ")";
        var safeTxt = this.productivityImpactToText[ProductivityImpactRemediationType.NonImpactedAssets];
        var allText = this.productivityImpactToText[ProductivityImpactRemediationType.AllExposedAssets];
        return _a = {},
            _a[ProductivityImpactRemediationType.NonImpactedAssets] = safeTxt + safeCount,
            _a[ProductivityImpactRemediationType.AllExposedAssets] = allText + allExposedCount,
            _a;
    };
    TvmTextsService.prototype.generateChangeEventActivity = function (changeEvent) {
        if (!changeEvent) {
            return this._noDataAvailableText;
        }
        var isSingular = changeEvent.cvesList.length === 1;
        var wasValue = isSingular ? 'was' : 'were';
        var vulnerabilityValue = isSingular ? 'vulnerability' : 'vulnerabilities';
        var eventHasAssetsCount = changeEvent.assetsCount !== null && changeEvent.assetsCount !== undefined;
        var currentlyToken = eventHasAssetsCount ? '' : ' currently';
        var machineCount = this.prettyNumberService.prettyNumber(eventHasAssetsCount ? changeEvent.assetsCount : changeEvent.currentAssetsCount);
        var machine = changeEvent.assetsCount === 1 ? 'device' : 'devices';
        var cveNum = changeEvent.cvesList.length;
        var aValue = cveNum === 1 ? 'a' : cveNum.toString();
        var productName = this.parseFullProductName(changeEvent.productName, changeEvent.vendor);
        var exploit = isSingular ? 'An exploit' : 'Exploits';
        switch (changeEvent.eventType) {
            case VulnerabilityChangeEventType.NewCve:
                return productName + " has " + aValue + " new " + vulnerabilityValue + "," + currentlyToken + " impacting " + machineCount + " " + machine;
            case VulnerabilityChangeEventType.CveHasPubliclyDisclosedExploit:
                return cveNum + " " + vulnerabilityValue + " in " + productName + " became exploitable," + currentlyToken + " impacting " + machineCount + " " + machine;
            case VulnerabilityChangeEventType.CveHasVerifiedExploit:
                return exploit + " for " + cveNum + " " + vulnerabilityValue + " in " + productName + " became verified," + currentlyToken + " impacting " + machineCount + " " + machine;
            case VulnerabilityChangeEventType.CveHasExploitInKit:
                return exploit + " for " + cveNum + " " + vulnerabilityValue + " in " + productName + " " + wasValue + " added to an exploit kit," + currentlyToken + " impacting " + machineCount + " " + machine;
            default:
                return this._noDataAvailableText;
        }
    };
    TvmTextsService.prototype.GetExploitToolTip = function (shouldDisplayExploitExperience, isExploitVerified, exploitTypes, hasExploitToolTipKey, hasDetailedExploitToolTip) {
        if (!shouldDisplayExploitExperience) {
            return '';
        }
        var exploitToolTipText = !isExploitVerified && exploitTypes.length === 0 // should check if toolTipInfo.exploitTypes exists?
            ? this.i18nService.get(hasExploitToolTipKey)
            : this.i18nService.get(hasDetailedExploitToolTip, {
                verified: isExploitVerified ? 'verified' : '',
                exploitType: this.getMostSevereExploitType(exploitTypes).toLocaleLowerCase(),
            });
        return "<li>" + exploitToolTipText + "</li>";
    };
    TvmTextsService.prototype.GetNoExploitText = function (shouldDisplayNoExploitText, noExploitToolTipKey) {
        if (!shouldDisplayNoExploitText) {
            return '';
        }
        return "<li>" + this.i18nService.get(noExploitToolTipKey) + "</li>";
    };
    TvmTextsService.prototype.GetOldExploit = function (shouldDisplayExploit, hasExploitToolTipKey) {
        // delete this if after TvmExploit feature removal.
        if (!shouldDisplayExploit) {
            return '';
        }
        return "<li>" + this.i18nService.get(hasExploitToolTipKey) + "</li>";
    };
    TvmTextsService.prototype.GetExploitKit = function (isInExploitKit, inExploitKitToolTipKey) {
        if (!isInExploitKit) {
            return '';
        }
        return "<li>" + this.i18nService.get(inExploitKitToolTipKey) + "</li>";
    };
    TvmTextsService.prototype.GetThreatDetails = function (isExploitLinkedToThreat, threats, exploitLinkedToThreatKey) {
        if (!isExploitLinkedToThreat) {
            return '';
        }
        var exploit = "<li>" + this.i18nService.get(exploitLinkedToThreatKey) + (threats ? ':' : '.') + "</li>";
        if (threats) {
            // Create an bullet item for each threat
            var threatLinks = threats.map(function (t) { return "<li><a href=\"threatanalytics3/" + t.threatId + "\">" + t.threatName + "</a></li></li>"; });
            exploit += "<ul style=\"list-style: none; margin: 0; padding: 0;\">" + threatLinks.join('') + "</ul>";
        }
        return exploit;
    };
    TvmTextsService.prototype.getText = function (textToken, data) {
        return this.textsMap[textToken](data) || '';
    };
    // note: this functionality is being used by Threat Analytics as well (for SCID's integration).
    TvmTextsService.prototype.getRelatedComponentName = function (category, remediationType, productName, vendor, subCategory) {
        if (category === RecommendationCategory.NetworkGear) {
            return this.i18nService.get('tvm.common.networkAssessment');
        }
        // TODO: Remove parse function when related software creation logic moves to backend
        if (remediationType !== RemediationType.ConfigurationChange) {
            return this.parseFullProductName(productName, vendor);
        }
        var subCategoryVal = subCategory ? ' (' + this.parseEntityName(subCategory) + ')' : '';
        var categoryVal = category.toString();
        if (categoryVal == 'OS') {
            categoryVal = this.i18nService.get('tvm.common.operatingSystem');
        }
        return "" + categoryVal + subCategoryVal;
    };
    TvmTextsService.prototype.getInaccuracyReasons = function (context) {
        return this.contextToReasons[context] || [''];
    };
    TvmTextsService.prototype.getSoftwareEolOsLegalNoteText = function (productId, recommendationOrNotice) {
        return productId === MicrosoftProductIdsWithLegalNote.Windows7
            ? this.i18nService.get('tvm.securityRecommendation.eolLegalNote.windows7', {
                recommendationOrNotice: recommendationOrNotice,
            })
            : productId === MicrosoftProductIdsWithLegalNote.WindowsServer2008
                ? this.i18nService.get('tvm.securityRecommendation.eolLegalNote.windowsServer2008', {
                    recommendationOrNotice: recommendationOrNotice,
                })
                : '';
    };
    TvmTextsService.prototype.getHasEolVersionsMicrosoftLegalNoteText = function (productId, recommendationOrNotice) {
        return productId === MicrosoftProductIdsWithLegalNote.Windows10
            ? this.i18nService.get('tvm.securityRecommendation.hasEolVersionsLegalNote.windows10', {
                recommendationOrNotice: recommendationOrNotice,
            })
            : productId === MicrosoftProductIdsWithLegalNote.SqlServer2012 ||
                productId === MicrosoftProductIdsWithLegalNote.SqlServer2014 ||
                productId === MicrosoftProductIdsWithLegalNote.SqlServer2016
                ? this.i18nService.get('tvm.securityRecommendation.hasEolVersionsLegalNote.sqlServer', {
                    recommendationOrNotice: recommendationOrNotice,
                })
                : '';
    };
    TvmTextsService.prototype.getUpgradeTo = function (securityRecommendation) {
        if (securityRecommendation.recommendedVendor && securityRecommendation.recommendedProgram) {
            return this.parseFullProductName(securityRecommendation.recommendedProgram, securityRecommendation.recommendedVendor);
        }
        return null;
    };
    Object.defineProperty(TvmTextsService.prototype, "noDataAvailableToken", {
        get: function () {
            return this._noDataAvailableText;
        },
        enumerable: true,
        configurable: true
    });
    TvmTextsService.prototype.getRecommendationZeroDayDescription = function (securityRecommendation, softwareName, isEol, hasEolVersions, hasUpcomingEolVersions, noHtmlTags) {
        var isOnlyZeroDay = securityRecommendation.weaknessesCount === 1;
        var otherVulnerabilitiesCount = securityRecommendation.weaknessesCount - 1;
        var breakLineTag = noHtmlTags ? '' : '<br><br>';
        var zeroDayDescriptionFirstPart = isOnlyZeroDay
            ? this.i18nService.strings
                .tvm_securityRecommendation_descriptionTemplates_zeroDay_single_vulnerability
            : this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_zeroDay', {
                otherVulnerabilitiesCount: otherVulnerabilitiesCount,
            });
        var zeroDayDescriptionSecondPart = noHtmlTags
            ? this.i18nService.strings
                .tvm_securityRecommendation_descriptionTemplates_zeroDay_secondPart_without_link
            : this.i18nService.strings
                .tvm_securityRecommendation_descriptionTemplates_zeroDay_secondPart_with_link;
        var eolNote;
        var link = noHtmlTags
            ? ''
            : "<a href=\"/vulnerabilities?search=zero%20day\">" + this.i18nService.strings.tvm_securityRecommendation_descriptionTemplates_zeroDay_linkText + "</a>";
        if (isEol) {
            link = '';
            zeroDayDescriptionSecondPart = '';
            var recommendationType = this.i18nService.get("tvm_securityRecommendation_descriptionTemplates_zeroDay_eos_software_recommendationType_" + securityRecommendation.remediationType);
            zeroDayDescriptionFirstPart = isOnlyZeroDay
                ? this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_zeroDay_eos_software_firstPart_single_vulnerability', {
                    recommendationType: recommendationType,
                    softwareName: softwareName,
                })
                : this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_zeroDay_eos_software_firstPart', {
                    recommendationType: recommendationType,
                    softwareName: softwareName,
                    otherVulnerabilitiesCount: otherVulnerabilitiesCount,
                });
            eolNote = this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_zeroDay_eos_software_note', {
                recommendationType: recommendationType,
            });
        }
        else if (hasEolVersions && hasUpcomingEolVersions) {
            eolNote = this.i18nService.strings
                .tvm_securityRecommendation_descriptionTemplates_zeroDay_eos_version_upcoming_eos_version_note;
        }
        else if (hasEolVersions) {
            eolNote = this.i18nService.strings
                .tvm_securityRecommendation_descriptionTemplates_zeroDay_eos_version_note;
        }
        else if (hasUpcomingEolVersions) {
            eolNote = this.i18nService.strings
                .tvm_securityRecommendation_descriptionTemplates_zeroDay_upcoming_eos_version_note;
        }
        eolNote = eolNote ? "" + breakLineTag + eolNote : '';
        return "" + zeroDayDescriptionFirstPart + zeroDayDescriptionSecondPart + " " + link + eolNote;
    };
    return TvmTextsService;
}());
export { TvmTextsService };
