/* tslint:disable:template-accessibility-alt-text */
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	ChangeDetectorRef,
	OnInit, ViewChild, ElementRef, ViewChildren, QueryList, OnChanges, SimpleChanges,
} from '@angular/core';
import { TabModel } from './tab.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IIconStyles } from 'office-ui-fabric-react';
import { debounce } from 'lodash-es';

const NEVER_SELECTED_INDEX = -1;
enum TabStop {
	Skippable = -1,
	Stoppable = 0,
}
const MORE_ICON_STYLE: IIconStyles = {
	root: {
		fontSize: '16px'
	},
};
const MENU_LEFT_MARGIN = 20;
// need to add the tabs elements horizontal padding to its width in order to calculate how many tab can be entered in available width.
const TABS_HORIZONTAL_PADDING = 30;

let lastId = 0;

@Component({
	selector: 'tabs',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './tabs.components.html',
	styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit, OnChanges {
	@ViewChild('showMoreBtn', { static: false }) showMoreBtnRef: ElementRef;
	@ViewChild('primaryUl', { static: false }) primarySectionRef: ElementRef;
	@ViewChild('menu', { static: true }) menu: ElementRef;
	@ViewChildren('primaryItem') primaryItemsRef: QueryList<ElementRef>;
	@Input() tabsData: Array<TabModel>;
	@Input() currentTab: string;
	@Input() focusedTabIndex: number = NEVER_SELECTED_INDEX;
	@Input() tabsClass: string;
	@Input() justifyTabs: boolean = false;
	@Input() currentRouteIsActive: boolean = false;
	@Input() row: boolean = true;
	@Input() tabsKind: 'regular' | 'large' = 'regular';
	@Input() activeRouteById: boolean = false;

	@Output() select: EventEmitter<{ tab: TabModel }> = new EventEmitter<{ tab: TabModel }>(false);
	currentTabId: string;
	tabPanelId = 'tabPanel-'+lastId++;
	boundMouseDown: (e: Event) => boolean;
	private primaryItemsWidth: Array<number>;
	selectedTabIndex: number = 0;
	isOverflowMenuOpen = false;
	hasOverflowTabs = true;
	hiddenTabsByIndex = [];
	moreIconStyles = MORE_ICON_STYLE;
	previousUrl = this.router.url;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private readonly activatedRoute: ActivatedRoute,
		private readonly router: Router
	) {
		this.calculateVisibleTabs = debounce(this.calculateVisibleTabs.bind(this), 50);
		this.router.events.subscribe((val) => {
			if (val instanceof NavigationEnd && !this.isSameTab(this.previousUrl, val.url)) {
				this.previousUrl = val.url;
				const selectedTab = this.tabsData && this.tabsData.find(tab => this.isTabSelected(tab));
				selectedTab && this.selectTab(selectedTab);
			}
		})
	}

	ngOnInit(): void {
		const selectedTab = this.tabsData && this.tabsData.find(tab => this.isTabSelected(tab));
		selectedTab && this.selectTab(selectedTab);
		this.boundMouseDown = this.onMouseDown.bind(this);
	}

	ngAfterViewInit() {
		this.primaryItemsWidth = this.primaryItemsRef && this.primaryItemsRef.toArray()
			.map(item => item.nativeElement.offsetWidth + TABS_HORIZONTAL_PADDING);
		this.calculateVisibleTabs();
		if (this.currentTab) {
			const currentTabData = this.tabsData.find(tab => tab.id === this.currentTab)
			this.selectTab(currentTabData);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.currentTab){
			const currentTabData = this.tabsData.find(tab => tab.id === changes.currentTab.currentValue)
			this.selectTab(currentTabData);
		}
	}


	isSameTab(prevUrl: string, newUrl: string){
		return prevUrl.split('?')[0] === newUrl.split('?')[0]
	}

	onResize() {
		this.calculateVisibleTabs();
	}

	onShowMore(e) {
		e.preventDefault();
		this.isOverflowMenuOpen = !this.isOverflowMenuOpen;
		if (this.isOverflowMenuOpen){
			document.body.addEventListener('mousedown', this.boundMouseDown);
			setTimeout(()=>{
				this.focusedTabIndex = this.hiddenTabsByIndex[0];
				this.changeDetectorRef.detectChanges();
				const menuEl = this.menu.nativeElement.getBoundingClientRect();
				if (menuEl.left < MENU_LEFT_MARGIN){
					const showMoreBtn = this.showMoreBtnRef.nativeElement.getBoundingClientRect();
					this.menu.nativeElement.style.left=`${-showMoreBtn.left + MENU_LEFT_MARGIN}px`;
					this.menu.nativeElement.style.right="unset";
					this.menu.nativeElement.style.width="fit-content";
				}
			})
		}
		else{
			this.onMenuClose();
			this.focusedTabIndex = this.tabsData.length;
			this.changeDetectorRef.detectChanges();
		}
	}

	onMenuClose(){
		this.menu.nativeElement.style.left="unset"
		this.menu.nativeElement.style.width="null"
		this.menu.nativeElement.style.right="0";
		document.body.removeEventListener('mousedown', this.boundMouseDown);
	}

	/**
	 * Calculating the width of Tab elements in order to get how many tabs can fit in the available width
	 * and displaying the tabs that fit, and adding the tabs that doesnt to a dropdown menu .
	 */
	calculateVisibleTabs() {
		//Disabling the responsive functionality when in vertical layout
		if (!this.row){
			this.hasOverflowTabs = false;
			this.changeDetectorRef.markForCheck();
			return;
		}
		this.hiddenTabsByIndex = [];
		let breakForEach = false;
		const primarySectionWidth = this.primarySectionRef && this.primarySectionRef.nativeElement.offsetWidth;
		let visibleTabsWidth = this.showMoreBtnRef ? this.showMoreBtnRef.nativeElement.offsetWidth : 0;
		if (this.selectedTabIndex >= 0) {
			//adding the width of the selected tab, since it should always be visible.
			visibleTabsWidth += this.primaryItemsWidth[this.selectedTabIndex];
		}
		// iterating over the tabs refs to calculate how many tabs can fit in the available width.
		this.primaryItemsWidth.forEach((itemWidth, i) => {
			// excluding the selected tab since its already calculated.
			if(this.selectedTabIndex === i) return;
			if (!breakForEach && primarySectionWidth >= visibleTabsWidth + itemWidth) {
				visibleTabsWidth += itemWidth;
			} else {
				// storing the tabs indexes that should be hidden in an array.
				this.hiddenTabsByIndex.push(i);
				breakForEach = true;
			}
		});
		//setting the hasOverflowTabs var to true so the overflow section would be displayed.
		this.hasOverflowTabs = !!this.hiddenTabsByIndex.length;
		this.changeDetectorRef.markForCheck();
	}

	isTabHidden(index: number) {
		return this.hiddenTabsByIndex.includes(index);
	}

	selectTab(tab: TabModel, $event?): boolean {
		if (!tab || tab.disabled) {
			return true;
		}
		this.currentTabId = tab.id;
		this.select.emit({ tab: tab });
		this.changeDetectorRef.markForCheck();

		this.selectedTabIndex = this.tabsData.indexOf(tab);
		this.focusedTabIndex = this.selectedTabIndex;
		if (this.hiddenTabsByIndex.includes(this.selectedTabIndex)) {
			this.calculateVisibleTabs();
		}
		else {
			this.changeDetectorRef.markForCheck();
		}
		setTimeout(()=>{
			this.isOverflowMenuOpen = false;
			this.onMenuClose();
		})


		if (tab.routerLink) {
			return true;
		}

		$event && $event.preventDefault();
		return false;
	}

	isTabSelected(tab: TabModel): boolean {
		const byRoute =
			!this.activeRouteById &&
			(tab.shouldForceActive && tab.shouldForceActive() ||
				this.isActivatedRoute(tab));
		const byId = this.activeRouteById && this.isActivatedById(tab);
		return byRoute || byId;
	}

	getTabIndexAttribute(selected: boolean, currentIndex: number): number {
		if (this.focusedTabIndex === NEVER_SELECTED_INDEX) {
			// no keyboard navigation ever occurred - set selected tab to be focusable
			return selected ? TabStop.Stoppable : TabStop.Skippable;
		}

		return this.focusedTabIndex === currentIndex ? TabStop.Stoppable : TabStop.Skippable;
	}

	prevTab(){
		this.focusedTabIndex = this.tabNav(this.getCurrentFocusTabIndex(), "prev");
	}

	nextTab(){
		this.focusedTabIndex = this.tabNav(this.getCurrentFocusTabIndex(), "next");
	}

	tabNav(index, direction : "prev" | "next"){
		const dir = direction == "prev" ? -1 : 1;
		const newIndex = index + dir;
		if (newIndex < 0) {
			return this.focusedTabIndex;
		}
		if (newIndex >= this.tabsData.length) {
			//if there is hidden tabs, focus on show more
			if (this.hiddenTabsByIndex.length){
				return this.tabsData.length
			}
			return this.focusedTabIndex;
		}

		return this.isTabHidden(newIndex) ? this.tabNav(newIndex, direction) : newIndex;
	}

	showMoreNextTab($event, loop=true){
		$event && $event.preventDefault();
		$event && $event.stopPropagation();
		$event && $event.stopImmediatePropagation();

		let index = this.hiddenTabsByIndex.indexOf(this.focusedTabIndex) + 1;
		index = index === this.hiddenTabsByIndex.length ? loop ? 0 : index-1 : index;
		this.focusedTabIndex = this.hiddenTabsByIndex[index];
	}

	showMorePrevTab($event, loop=true){
		$event && $event.preventDefault();
		$event && $event.stopPropagation();
		$event && $event.stopImmediatePropagation();

		let index = this.hiddenTabsByIndex.indexOf(this.focusedTabIndex) - 1;
		index = index < 0 ? loop ? this.hiddenTabsByIndex.length -1 : 0 : index;
		this.focusedTabIndex = this.hiddenTabsByIndex[index];
	}

	onMouseDown(e): boolean {
		if(!this.isMenuParentOfTarget(e)){
			this.onShowMore(e)
		}
		return true;
	}


	isMenuParentOfTarget(e){
		let el = e.target;
		do {
			if (el === document.documentElement) return false;

			if (el === this.menu.nativeElement) return true;
		} while ((el = el.parentNode));
		return true;
	}


	getCurrentFocusTabIndex(){
		return this.focusedTabIndex === NEVER_SELECTED_INDEX ? this.selectedTabIndex : this.focusedTabIndex
	}




	focusTab(index: number) {
		if (index < 0 || index >= this.tabsData.length) {
			return;
		}

		this.focusedTabIndex = index;
	}

	getTabClassName(tab): string {
		let className = tab.disabled ? 'disabled' : '';

		if (tab.className) className += ` ${tab.className}`;

		return className;
	}

	private isActivatedRoute(tab: TabModel): boolean {
		if (!tab || !tab.routerLink) {
			return false;
		}

		const routerLink = typeof tab.routerLink === 'string' ? [tab.routerLink] : tab.routerLink;
		const urlTree = this.router.createUrlTree(routerLink, { relativeTo: this.activatedRoute });
		return this.router.isActive(urlTree, false);
	}

	//This is temp solution until the machine list will change url, for machine list we decide which route is active by the id
	private isActivatedById(tab: TabModel): boolean {
		if (!tab || !tab.id) {
			return false;
		}
		const urlString = this.router.url.split("?")[0];
		return tab.id === urlString;
	}
}
