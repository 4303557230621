/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i2 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i5 from "../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i6 from "../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i7 from "../../../dataviews/components/dataview.component.ngfactory";
import * as i8 from "../../../dataviews/components/dataview.component";
import * as i9 from "../../../../../../../projects/shared/src/lib/services/wicd-sanitizer.service";
import * as i10 from "../../../dialogs/services/dialogs.service";
import * as i11 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i12 from "../../../../../../../projects/config/src/lib/services/preferences.service";
import * as i13 from "@angular/router";
import * as i14 from "../../../dataviews/services/dataview-repository.service";
import * as i15 from "../../../insights/services/app-insights.service";
import * as i16 from "../../../dialogs/services/errors-dialog.service";
import * as i17 from "../../services/entity-panels.service";
import * as i18 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i19 from "../../../../../../../projects/config/src/lib/services/app-context.service";
import * as i20 from "@microsoft/paris/dist/lib/paris";
import * as i21 from "@angular/cdk/a11y";
import * as i22 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i23 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i24 from "../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i25 from "../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i26 from "@angular/common";
import * as i27 from "./related-cves.panel.component";
import * as i28 from "../../services/global-entity-types.service";
import * as i29 from "../../../@entities/@tvm/vulnerabilities/services/vulnerabilities.fields.service";
import * as i30 from "../../../tvm/services/tvm-download.service";
import * as i31 from "../../../@entities/@tvm/vulnerabilities/services/vulnerability.service";
var styles_RelatedCvesPanelComponent = [];
var RenderType_RelatedCvesPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RelatedCvesPanelComponent, data: {} });
export { RenderType_RelatedCvesPanelComponent as RenderType_RelatedCvesPanelComponent };
function View_RelatedCvesPanelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "button", [["class", "tvm-show-more-csv-export-button"]], null, [[null, "click"], [null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.exportCveData() !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 147456, null, 0, i1.TooltipDirective, [i0.ElementRef, i2.TooltipsService, i3.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "wcd-shared-icon", [["iconName", "Download"]], null, null, null, i4.View_IconComponent_0, i4.RenderType_IconComponent)), i0.ɵdid(4, 573440, null, 0, i5.IconComponent, [i6.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), i0.ɵppd(8, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.exportTooltipText || "Export data to CSV"); _ck(_v, 1, 0, currVal_0); var currVal_1 = "Download"; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i0.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i0.ɵnov(_v.parent, 0), "export")); _ck(_v, 7, 0, currVal_2); }); }
function View_RelatedCvesPanelComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "dataview", [["class", "wcd-flex-1"], ["id", "related-vulnerabilities-dataview"]], null, [[null, "onItemClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onItemClick" === en)) {
        var pd_0 = (_co.openVulnerabilityPanel($event.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_DataViewComponent_0, i7.RenderType_DataViewComponent)), i0.ɵdid(1, 245760, null, 0, i8.DataViewComponent, [i9.WicdSanitizerService, i10.DialogsService, i11.PanelService, i12.PreferencesService, i13.ActivatedRoute, i14.DataviewRepositoryService, i13.Router, i0.ChangeDetectorRef, i15.AppInsightsService, i16.ErrorsDialogService, i17.EntityPanelsService, i18.FeaturesService, i19.AppContextService, i20.Paris, i21.LiveAnnouncer, i22.I18nService], { allowFilters: [0, "allowFilters"], allowPaging: [1, "allowPaging"], defaultSortFieldId: [2, "defaultSortFieldId"], disableSelection: [3, "disableSelection"], navigateOnChange: [4, "navigateOnChange"], id: [5, "id"], searchEnabled: [6, "searchEnabled"], allowColumnCustomization: [7, "allowColumnCustomization"], label: [8, "label"], ignoreQueryParams: [9, "ignoreQueryParams"], fields: [10, "fields"], repository: [11, "repository"] }, { onItemClick: "onItemClick" }), i0.ɵppd(2, 1), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = true; var currVal_2 = "severity"; var currVal_3 = true; var currVal_4 = false; var currVal_5 = "related-vulnerabilities-dataview"; var currVal_6 = true; var currVal_7 = false; var currVal_8 = i0.ɵunv(_v, 1, 8, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), "tvm.common.relatedCVE.title")); var currVal_9 = true; var currVal_10 = _co.fields; var currVal_11 = _co._repository; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
export function View_RelatedCvesPanelComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i23.I18nPipe, [i22.I18nService]), (_l()(), i0.ɵeld(1, 0, null, null, 16, "wcd-panel", [["class", "wcd-full-height wcd-flex-vertical wcd-flex-1"], ["data-track-component", "missing-kb-related-cves-panel"], ["data-track-component-type", "Side Panel"]], null, [[null, "close"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i24.View_PanelComponent_0, i24.RenderType_PanelComponent)), i0.ɵdid(2, 114688, null, 1, i25.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i11.PanelService], { settings: [0, "settings"] }, { close: "close" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t"])), (_l()(), i0.ɵeld(5, 0, null, 1, 8, "div", [["class", "wcd-flex-horizontal wcd-padding-large-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "h4", [["class", "wcd-flex-1 wcd-padding-bottom side-panel-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["\n\t\t\t", "\n\t\t"])), i0.ɵppd(9, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RelatedCvesPanelComponent_1)), i0.ɵdid(12, 16384, null, 0, i26.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, 1, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_RelatedCvesPanelComponent_2)), i0.ɵdid(16, 16384, null, 0, i26.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, 1, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.isExportEnabled; _ck(_v, 12, 0, currVal_2); var currVal_3 = _co._repository; _ck(_v, 16, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = i0.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i0.ɵnov(_v, 0), "tvm.common.relatedCVE.title")); _ck(_v, 8, 0, currVal_1); }); }
export function View_RelatedCvesPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "related-cves-panel", [], null, null, null, View_RelatedCvesPanelComponent_0, RenderType_RelatedCvesPanelComponent)), i0.ɵdid(1, 245760, null, 0, i27.RelatedCvesPanelComponent, [i13.Router, i28.GlobalEntityTypesService, i18.FeaturesService, i29.VulnerabilityFieldsService, i30.TvmDownloadService, i31.VulnerabilityService, i22.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RelatedCvesPanelComponentNgFactory = i0.ɵccf("related-cves-panel", i27.RelatedCvesPanelComponent, View_RelatedCvesPanelComponent_Host_0, { settings: "settings", sourceValue: "sourceValue", relatedCvesTitle: "relatedCvesTitle", repository: "repository", includeExposedMachinesComponent: "includeExposedMachinesComponent", isNetworkGearCve: "isNetworkGearCve" }, {}, []);
export { RelatedCvesPanelComponentNgFactory as RelatedCvesPanelComponentNgFactory };
