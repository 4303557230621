import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { UserRoleEditComponent } from './user-role-edit.component';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { UserRole } from '@wcd/domain';
import { ConfirmEvent } from '../../../../dialogs/confirm/confirm.event';
import { I18nService } from '@wcd/i18n';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Paris, Repository } from '@microsoft/paris';

@Component({
	selector: 'user-role-edit-panel',
	template: `
		<wcd-panel (close)="destroy()" [settings]="settings" [canDeactivate]="boundCanDeactivate">
			<user-role-edit
				[userRole]="userRole"
				class="wcd-full-height"
				(save)="onDone($event)"
				(cancel)="cancel()"
			></user-role-edit>
		</wcd-panel>
	`,
})
export class UserRoleEditPanelComponent extends PanelContainer implements OnInit {
	@Input() onDone: Function;
	@Input() onCancel: Function;
	@Input() userRole: UserRole;

	@ViewChild(UserRoleEditComponent, { static: false }) userRoleEdit: UserRoleEditComponent;

	boundCanDeactivate: () => Observable<boolean>;

	userRolesRepo: Repository<UserRole>;

	constructor(
		protected router: Router,
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		private paris: Paris
	) {
		super(router);
		this.boundCanDeactivate = this.canDeactivate.bind(this);
		this.userRolesRepo = this.paris.getRepository(UserRole);
	}

	save($event) {
		this.onDone($event);
		this.startClose();
	}

	canDeactivate(): Observable<boolean> {
		if (this.userRoleEdit.isDirty) {
			return from(
				this.dialogsService.confirm({
					title: this.i18nService.get('discardChanges'),
					text: this.i18nService.get('discardMessage.singular', {
						itemName: this.userRolesRepo.entity.singularName.toLowerCase(),
					}),
					confirmText: this.i18nService.get('buttons.discard'),
				})
			).pipe(map((e: ConfirmEvent) => e.confirmed));
		} else {
			return of(true);
		}
	}

	cancel() {
		this.canDeactivate().subscribe((_canDeactivate: boolean) => {
			if (_canDeactivate) this.closePanel();
		});
	}

	closePanel() {
		this.startClose();
		setTimeout(() => this.destroy(), 200);
	}
}
