import { ComponentFactoryResolver, ComponentRef, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription, Subject } from 'rxjs';
import { cloneDeep, get } from 'lodash-es';
import {
	ActionHistory,
	EvaluationIncidents,
	EvaluationInvestigations,
	EvaluationLab,
	EvaluationMachine,
	EvaluationMachineOperatingSystem,
	EvaluationMachineStatusType,
	EvaluationPassword,
	EvaluationSimulation,
	EvaluationSimulationCatalog,
	EvaluationSimulatorAgent,
	EvaluationSimulatorStatusType,
	EvaluationStep,
	EvaluationStepType,
	GetEvaluationIncidentsApiCall,
	GetEvaluationInvestigationsApiCall,
	GetEvaluationStepsApiCall,
	Machine,
	PatchEvaluationStatusApiCall,
	PostEvaluationStatusApiCall,
	SimulatorAdditionalSettingsData,
	SimulatorsSettingsData,
	UpdateSimulatorsSettings,
} from '@wcd/domain';
import { EvaluationWizardSteps } from '../models/evaluation.wizard.steps';
import { EvaluationWizardStepModel } from '../models/evaluation-wizard-step.model';
import { Paris, Repository } from '@microsoft/paris';
import {
	catchError,
	distinctUntilChanged,
	filter,
	finalize,
	map,
	mergeMap,
	switchMap,
	take,
	tap,
} from 'rxjs/operators';
import { KnownColorsService } from '@wcd/shared';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { TrackingEventType } from '@wcd/telemetry';
import { EvaluationPasswordDialogComponent } from '../components/evaluation-password-dialog.component';
import { StretchedDonutBarItem } from '@wcd/charts';
import { Feature, FeaturesService } from '@wcd/config';
import { PanelType } from '@wcd/panels';
import { EvaluationAddMachinePanelComponent } from '../components/evaluation-add-machine-panel.component';
import { EvaluationLabSetupPanelComponent } from '../components/evaluation-lab-setup-panel.component';
import { PollingService } from '@wcd/config';
import { EvaluationSetupPanelComponent } from '../components/setup-wizard/evaluation-setup-panel.component';
import { EvaluationCreateSimulationPanelComponent } from '../components/evaluation-create-simulation-panel.component';
import { EditSimulatorAgentsPanelComponent } from '../components/setup-wizard/edit-simulator-agents-panel.component';
import { SimulatorAgentsConsentFormComponent } from '../components/setup-wizard/simulator-agents-consent-form.component';
import { SimulationDescriptionPanelComponent } from '../components/simulation-description-panel.component';

const EVALUATION_MACHINE_TAG = 'evaluation';
const EVALUATION_MACHINE_USER_NAME = 'Administrator1';
const LOWEST_EXPOSURE_AND_RISK_SCORES_PRIORITY = 100;
declare const moment: typeof import('moment');

@Injectable()
export class EvaluationService implements OnDestroy {
	// private members

	private simulationsRepository: Repository<EvaluationSimulation>;
	private repository: Repository<EvaluationMachine>;
	private machineSubscription: Subscription;

	private evaluationIncidentsSubject$: BehaviorSubject<EvaluationIncidents> = new BehaviorSubject<
		EvaluationIncidents
	>(new EvaluationIncidents());
	private evaluationMachinesSubject$: BehaviorSubject<Array<EvaluationMachine>> = new BehaviorSubject<
		Array<EvaluationMachine>
	>(null);
	private machinesMapSubject$: BehaviorSubject<{ [index: number]: Machine }> = new BehaviorSubject<{
		[index: number]: Machine;
	}>(null);
	private evaluationSimulationsSubject$: BehaviorSubject<Array<EvaluationSimulation>> = new BehaviorSubject<
		Array<EvaluationSimulation>
	>(null);

	// public observables
	evaluationMachineAlerts$: Observable<{
		[index: number]: number;
	}> = this.evaluationIncidentsSubject$.asObservable().pipe(
		distinctUntilChanged(),
		map(report => {
			return report ? report.machineAlertCounts : null;
		})
	);
	evalMachineToMachineMap$ = this.machinesMapSubject$.asObservable();
	evaluationMachines$: Observable<
		Array<EvaluationMachine>
	> = this.evaluationMachinesSubject$.asObservable().pipe(filter(machines => !!machines));
	addMachineApiInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	evaluationLabCreated$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	labReady$ = new BehaviorSubject<boolean>(false);
	labUpdated$ = new Subject<void>();

	// public properties
	lab: EvaluationLab;

	steps = EvaluationWizardSteps.map(step => {
		if (this.featuresService.isEnabled(Feature.WindowsDefenderAtpEvaluationSummary)) {
			return new EvaluationWizardStepModel(step);
		} else {
			// Remove 'view report summary' step is summary feature is not enabled
			step.subSteps = step.subSteps.filter(
				subStep => subStep.name !== EvaluationStepType.EvaluationEndSummary
			);
			return new EvaluationWizardStepModel(step);
		}
	}); // all wizard steps

	currentSteps: Array<EvaluationStep>; //customer steps progress

	constructor(
		private paris: Paris,
		private knownColorsService: KnownColorsService,
		private appInsightsService: AppInsightsService,
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		private featuresService: FeaturesService,
		private pollingService: PollingService
	) {
		this.repository = paris.getRepository<EvaluationMachine>(EvaluationMachine);
		this.simulationsRepository = this.paris.getRepository<EvaluationSimulation>(EvaluationSimulation);

		this.machineSubscription = this.pollingService
			.poll(100, 60000) // update risk and exposure scores every minute
			.pipe(
				switchMap(() =>
					paris.getRepository<Machine>(Machine).query({
						where: {
							machineGroups: EVALUATION_MACHINE_TAG,
							lookingBackInDays: 180,
							machinesApiServiceMigration: this.featuresService.isEnabled(Feature.MachinesApiServiceMigration),
							useTvmMachinesAvStatus: this.featuresService.isEnabled(Feature.UseTvmMachinesAvStatus)
						},
					})
				),
				map(result => result.items),
				map(machines =>
					machines.reduce<{ [index: number]: Machine }>(
						(dict, obj) => ({ ...dict, [obj.id]: obj }),
						{}
					)
				)
			)
			.subscribe(this.machinesMapSubject$);

		this.getSimulationsCatalog().subscribe();
	}

	ngOnDestroy(): void {
		this.machineSubscription && this.machineSubscription.unsubscribe();
	}

	// lab operations
	getEvaluationLab(): Observable<EvaluationLab> {
		if (this.lab) {
			return of(this.lab);
		}

		return this.paris.getItemById(EvaluationLab, 1).pipe(
			catchError((error: any) => {
				if (error && error.status === 404) {
					return of(null);
				}
				throw error;
			}),
			tap((lab: EvaluationLab) => (this.lab = lab))
		);
	}

	createEvaluationLab(evaluationLab?: Partial<EvaluationLab>): Observable<Array<EvaluationStep>> {
		const repo = this.paris.getRepository<EvaluationLab>(EvaluationLab);
		return repo
			.save(
				evaluationLab
					? {
							maxNumberOfMachines: evaluationLab.maxNumberOfMachines,
							machineExpirationInHours: evaluationLab.machineExpirationInHours,
					  }
					: repo.createNewItem()
			)
			.pipe(
				tap((lab: EvaluationLab) => {
					this.lab = lab;
					this.evaluationLabCreated$.next(true);
				}),
				switchMap(() => this.getEvaluationSteps()),
				tap((steps: Array<EvaluationStep>) => (this.currentSteps = steps))
			);
	}

	notifyLabReady() {
		this.labReady$.next(true);
	}

	setSimulatorsConfigurations(
		additionalData: SimulatorAdditionalSettingsData,
		safebreachEnabled: boolean,
		aiqEnabled: boolean
	): Observable<EvaluationLab> {
		return this.getEvaluationLab().pipe(
			map(lab => {
				let updateRequired = false;

				const simulatorsSettings: SimulatorsSettingsData = cloneDeep(
					get(this, 'lab.simulatorsSettings', {})
				);
				if (safebreachEnabled && !simulatorsSettings.SafeBreach.isEnabled) {
					simulatorsSettings.SafeBreach = {
						type: EvaluationSimulatorAgent.SafeBreach,
						isEnabled: true,
						additionalInfo: { ...additionalData },
					};
					updateRequired = true;
				}
				if (aiqEnabled && !simulatorsSettings.AttackIQ.isEnabled) {
					simulatorsSettings.AttackIQ = {
						type: EvaluationSimulatorAgent.AttackIQ,
						isEnabled: true,
						additionalInfo: { ...additionalData },
					};
					updateRequired = true;
				}
				return { lab, updateRequired, simulatorsSettings };
			}),
			switchMap(({ lab, updateRequired, simulatorsSettings }) => {
				if (!updateRequired) {
					return of(lab);
				}
				return this.paris.apiCall(UpdateSimulatorsSettings, { simulatorsSettings });
			}),
			tap(lab => {
				this.lab = lab;
				this.labUpdated$.next();
			})
		);
	}

	showLabSetupPanel(
		componentFactoryResolver: ComponentFactoryResolver
	): Observable<ComponentRef<EvaluationLabSetupPanelComponent>> {
		return this.dialogsService.showPanel(
			EvaluationLabSetupPanelComponent,
			{
				id: 'lab-setup',
				type: PanelType.large,
				noBodyPadding: false,
				persistOnNavigate: false,
				disableOverlayClick: true,
				showOverlay: true,
			},
			{},
			componentFactoryResolver
		);
	}

	showLabSetupWizard(
		componentFactoryResolver: ComponentFactoryResolver
	): Observable<ComponentRef<EvaluationSetupPanelComponent>> {
		return this.dialogsService.showPanel(
			EvaluationSetupPanelComponent,
			{
				id: 'lab-setup-wizard',
				type: PanelType.wizard,
				showOverlay: false,
				hasCloseButton: false,
				noBodyPadding: true,
				disableOverlayClick: true,
			},
			{},
			componentFactoryResolver
		);
	}

	showEditSimulatorAgentsPanel(
		componentFactoryResolver: ComponentFactoryResolver
	): Observable<ComponentRef<EditSimulatorAgentsPanelComponent>> {
		return this.dialogsService.showPanel(
			EditSimulatorAgentsPanelComponent,
			{
				id: 'simulator-agent-setup-panel',
				type: PanelType.large,
				showOverlay: true,
				hasCloseButton: true,
			},
			{},
			componentFactoryResolver
		);
	}

	showConsentFormPanel(
		componentFactoryResolver: ComponentFactoryResolver,
		consentType: ConsentType
	): Observable<ComponentRef<SimulatorAgentsConsentFormComponent>> {
		return this.dialogsService.showPanel(
			SimulatorAgentsConsentFormComponent,
			{
				id: 'simulator-agent-consent-form',
				type: PanelType.large,
				showOverlay: true,
				hasCloseButton: true,
			},
			{
				consentType: consentType,
			},
			componentFactoryResolver
		);
	}

	// machine operations
	refreshEvaluationMachines(forceRefresh: boolean = false): Observable<Array<EvaluationMachine>> {
		if (this.evaluationMachinesSubject$.value || forceRefresh) {
			// if there is already a value in the observable, update it and return the original observable
			return this.repository.allItems$.pipe(
				tap(
					(machines: Array<EvaluationMachine>) => {
						this.evaluationMachinesSubject$.next(machines);
						//machine(s) have been (just) created and setup is done, updating the UX steps
						//TODO: refactor a bit to avoid nested subscribe
						if (
							this.evaluationMachinesSubject$.value.find(
								m => m.status.id === EvaluationMachineStatusType.Created
							)
						) {
							this.getEvaluationSteps().subscribe();
						}
					},
					(error: any) => {
						this.appInsightsService.trackEvent('Evaluation', {
							type: TrackingEventType.Action,
							id: 'getEvaluationMachines',
							error: error,
						});
					}
				),
				mergeMap(() => this.evaluationMachinesSubject$.asObservable())
			);
		} else {
			return this.evaluationMachinesSubject$.asObservable();
		}
	}

	getTotalMachines(): number {
		return this.evaluationMachinesSubject$ && this.evaluationMachinesSubject$.value
			? this.evaluationMachinesSubject$.value.length
			: 0;
	}

	getActiveMachines(): Array<EvaluationMachine> {
		return this.filterAndSortMachines(
			this.machineHasNoStatus([
				EvaluationMachineStatusType.PendingCreation,
				EvaluationMachineStatusType.Creating,
				EvaluationMachineStatusType.ProvisionFailure,
				EvaluationMachineStatusType.PendingDeletion,
				EvaluationMachineStatusType.Deleting,
				EvaluationMachineStatusType.Deleted,
			])
		);
	}

	getSetupInProgressMachines(): Array<EvaluationMachine> {
		return this.filterAndSortMachines(
			this.machineHasStatus([
				EvaluationMachineStatusType.PendingCreation,
				EvaluationMachineStatusType.Creating,
			])
		);
	}

	getProvisionedMachines(): Array<EvaluationMachine> {
		return this.filterAndSortMachines(
			this.machineHasNoStatus(EvaluationMachineStatusType.ProvisionFailure)
		);
	}

	getMachineUserName(): string {
		return EVALUATION_MACHINE_USER_NAME;
	}

	private machineHasStatus(
		statuses: EvaluationMachineStatusType | Array<EvaluationMachineStatusType>
	): (machine: EvaluationMachine) => boolean {
		if (!(statuses instanceof Array)) {
			statuses = [statuses];
		}

		return (machine: EvaluationMachine) => statuses.includes(machine.status.type);
	}

	private machineHasNoStatus(
		statuses: EvaluationMachineStatusType | Array<EvaluationMachineStatusType>
	): (machine: EvaluationMachine) => boolean {
		if (!(statuses instanceof Array)) {
			statuses = [statuses];
		}

		return (machine: EvaluationMachine) => !statuses.includes(machine.status.type);
	}

	private filterAndSortMachines(filterFunc: (m: EvaluationMachine) => boolean): Array<EvaluationMachine> {
		if (!this.evaluationMachinesSubject$ || !this.evaluationMachinesSubject$.value) return [];

		return this.evaluationMachinesSubject$.value
			.filter(m => filterFunc(m))
			.sort((m1, m2) => (m1.name > m2.name ? 1 : -1));
	}

	setEvaluationMachines(machines: Array<EvaluationMachine>) {
		this.evaluationMachinesSubject$.next(machines);
	}

	setAddMachineApiStatus(inProgress: boolean) {
		this.addMachineApiInProgress$.next(inProgress);
	}

	addEvaluationMachine(componentFactoryResolver: ComponentFactoryResolver) {
		this.setAddMachineApiStatus(true);

		this.repository
			.save({ index: this.getTotalMachines() + 1 })
			.pipe(
				mergeMap((evaluationMachine: EvaluationPassword) =>
					this.showMachinePasswordDialog(evaluationMachine, componentFactoryResolver)
				),
				finalize(() => this.setAddMachineApiStatus(false)),
				mergeMap(() => this.refreshEvaluationMachines())
			)
			.subscribe(
				() => {},
				error => {
					this.dialogsService.showError({
						title: this.i18nService.get(
							'evaluation.dashboard.dataView.commandBar.addMachine.error'
						),
						data: error,
					});
				}
			);
	}

	addEvaluationMachineByOs(
		machineOs: EvaluationMachineOperatingSystem,
		machineIndex: number
	): Observable<EvaluationPassword> {
		this.setAddMachineApiStatus(true);

		return this.repository
			.save({ index: (machineIndex || this.getTotalMachines()) + 1, machineOs: machineOs })
			.pipe(
				finalize(() => this.setAddMachineApiStatus(false)),
				tap(() => this.refreshEvaluationMachines().subscribe())
			);
	}

	showAddMachinePanel(
		componentFactoryResolver: ComponentFactoryResolver
	): Observable<ComponentRef<EvaluationAddMachinePanelComponent>> {
		return this.dialogsService.showPanel(
			EvaluationAddMachinePanelComponent,
			{
				id: 'add-machine',
				type: PanelType.medium,
				noBodyPadding: true,
				persistOnNavigate: false,
				disableOverlayClick: true,
				showOverlay: true,
			},
			{
				lab: this.lab,
				provisionedMachines: this.getProvisionedMachines().length,
				totalMachines: this.getTotalMachines(),
			},
			componentFactoryResolver
		);
	}

	showMachinePasswordDialog(
		evaluationPassword: EvaluationPassword,
		componentFactoryResolver: ComponentFactoryResolver,
		showDisclaimer: boolean = true
	): Observable<any> {
		return this.dialogsService.showModal(
			EvaluationPasswordDialogComponent,
			{
				id: 'evaluation-password-dialog',
				title: this.i18nService.get(
					'evaluation.dashboard.dataView.commandBar.addMachine.passwordModalTitle'
				),
				enableOverlay: false,
			},
			{
				password: evaluationPassword.password,
				machineName: evaluationPassword.name,
				lab: showDisclaimer ? this.lab : null,
				provisionedMachines: showDisclaimer ? this.getProvisionedMachines().length : null,
			},
			componentFactoryResolver
		);
	}

	// simulations operations
	showCreateSimulationPanel(
		componentFactoryResolver: ComponentFactoryResolver,
		simulationId: number = null
	): Observable<ComponentRef<EvaluationCreateSimulationPanelComponent>> {
		return this.dialogsService.showPanel(
			EvaluationCreateSimulationPanelComponent,
			{
				id: 'create-simulation',
				type: PanelType.medium,
				noBodyPadding: true,
				persistOnNavigate: false,
				disableOverlayClick: true,
				showOverlay: true,
			},
			{
				simulationId: simulationId,
				machinesWithSimulatorsReady: this.getMachinesWithSimulatorsReady(),
			},
			componentFactoryResolver
		);
	}

	showSimulationDescriptionPanel(
		componentFactoryResolver: ComponentFactoryResolver,
		simulationId: number
	): Observable<ComponentRef<SimulationDescriptionPanelComponent>> {
		return this.dialogsService.showPanel(
			SimulationDescriptionPanelComponent,
			{
				id: 'simulation-learn-more',
				type: PanelType.large,
				noBodyPadding: true,
				persistOnNavigate: false,
				showOverlay: false,
				isBlocking: false,
			},
			{ simulationId },
			componentFactoryResolver
		);
	}

	getMachinesWithSimulatorsReady() {
		return this.getActiveMachines().filter(m => {
			return (
				(m.simulatorsStatus.attackIQ &&
					m.simulatorsStatus.attackIQ.type === EvaluationSimulatorStatusType.Completed) ||
				(m.simulatorsStatus.safeBreach &&
					m.simulatorsStatus.safeBreach.type === EvaluationSimulatorStatusType.Completed)
			);
		});
	}

	private getAllSimulationsCatalog(): Observable<Array<EvaluationSimulationCatalog>> {
		return this.paris.getRepository(EvaluationSimulationCatalog).allItems$.pipe(
			map(simulations =>
				simulations.map(simulation => {
					simulation.name = this.i18nService.get(
						'evaluation.simulationCatalog.' +
							simulation.simulator +
							'.' +
							simulation.simulationId +
							'.name',
						null,
						true
					);
					simulation.description = this.i18nService.get(
						'evaluation.simulationCatalog.' +
							simulation.simulator +
							'.' +
							simulation.simulationId +
							'.description',
						null,
						true
					);
					return simulation;
				})
			),
			map(simulations =>
				simulations.filter(simulation => simulation.name !== '' && simulation.description !== '')
			)
		);
	}

	getSimulationCatalogById(simulationId: number): Observable<EvaluationSimulationCatalog> {
		return this.getAllSimulationsCatalog().pipe(
			map(catalog => catalog.find(simulation => simulation.simulationId === simulationId))
		);
	}
	getSimulationsCatalog(): Observable<Array<EvaluationSimulationCatalog>> {
		return this.getAllSimulationsCatalog().pipe(
			map(catalog => catalog.filter(simulation => !simulation.isDeprecated))
		);
	}

	createSimulation(simulationId: number, machineIndex: number) {
		return this.simulationsRepository
			.save({
				simulationId: simulationId,
				machineIndex: machineIndex,
			})
			.pipe(switchMap(() => this.refreshSimulations()));
	}

	refreshSimulations(): Observable<Array<EvaluationSimulation>> {
		return this.paris.getRepository(EvaluationSimulation).allItems$.pipe(
			tap(
				(simulations: Array<EvaluationSimulation>) => {
					// Do not publish new identical data
					if (
						JSON.stringify(this.evaluationSimulationsSubject$.value) !==
						JSON.stringify(simulations)
					) {
						this.evaluationSimulationsSubject$.next(simulations);
					}
				},
				(error: any) => {
					this.appInsightsService.trackEvent('Evaluation', {
						type: TrackingEventType.Action,
						id: 'refreshSimulations',
						error: error,
					});
				}
			),
			mergeMap(() => this.evaluationSimulationsSubject$.asObservable())
		);
	}

	// step operations
	getEvaluationSteps(): Observable<Array<EvaluationStep>> {
		return this.paris
			.apiCall(GetEvaluationStepsApiCall)
			.pipe(tap((steps: Array<EvaluationStep>) => (this.currentSteps = steps)));
	}

	patchEvaluationStatus(step: EvaluationStep): Observable<void> {
		return this.paris.apiCall(PatchEvaluationStatusApiCall, step);
	}

	postEvaluationStatus(step: EvaluationStep): Observable<void> {
		return this.paris
			.apiCall(PostEvaluationStatusApiCall, step)
			.pipe(tap(() => this.currentSteps.push(step)));
	}

	// report operations
	getIncidentsReport(): Observable<EvaluationIncidents> {
		return this.paris
			.apiCall(GetEvaluationIncidentsApiCall)
			.pipe(tap(val => this.evaluationIncidentsSubject$.next(val)));
	}

	getInvestigationsReport(): Observable<EvaluationInvestigations> {
		return this.paris.apiCall(GetEvaluationInvestigationsApiCall);
	}

	getResponseActions(): Observable<ActionHistory[]> {
		return this.evaluationMachines$.pipe(
			take(1),
			mergeMap(machines => {
				if (!machines || machines.every(m => !m.senseMachineId)) {
					return of(new Array<ActionHistory>());
				}

				const minFromDate = moment().subtract(180, 'days');

				return this.paris
					.getRepository(ActionHistory)
					.query({
						where: {
							MachineId: machines.filter(m => m.senseMachineId).map(m => m.senseMachineId),
							fromDate: machines
								.filter(m => m.provisioningTime)
								.map(m => m.provisioningTime)
								.reduce((d1, d2) => {
									const minProvisioningTime = d1 < d2 ? d1 : d2;
									return minFromDate.isAfter(minProvisioningTime)
										? minFromDate.toDate()
										: minProvisioningTime;
								}, new Date())
								.toJSON(),
							toDate: new Date().toJSON(),
						},
					})
					.pipe(map(dataset => dataset.items));
			})
		);
	}

	// dataview operations
	getMachinesByEvaluationMachines(evaluationMachines: Array<EvaluationMachine>): Array<Machine> {
		return Object.entries(this.machinesMapSubject$.value)
			.map(([key, value]) => (evaluationMachines.find(m => m.senseMachineId === key) ? value : null))
			.filter(m => m);
	}

	getMachineAlertCount(machineId: string): number {
		return this.evaluationIncidentsSubject$.value &&
			this.evaluationIncidentsSubject$.value.machineAlertCounts[machineId]
			? this.evaluationIncidentsSubject$.value.machineAlertCounts[machineId]
			: 0;
	}

	getMachineRiskLevel(machine: EvaluationMachine): number {
		return this.machinesMapSubject$.value && this.machinesMapSubject$.value[machine.senseMachineId]
			? this.machinesMapSubject$.value[machine.senseMachineId].riskScore.priority
			: LOWEST_EXPOSURE_AND_RISK_SCORES_PRIORITY; // this value represents low priority when there is no risk score
	}

	getMachineExposureLevel(machine: EvaluationMachine): number {
		return this.machinesMapSubject$.value && this.machinesMapSubject$.value[machine.senseMachineId]
			? this.machinesMapSubject$.value[machine.senseMachineId].exposureScore.priority
			: LOWEST_EXPOSURE_AND_RISK_SCORES_PRIORITY; // this value represents low priority when there is no exposure level
	}

	getMachineUpTimeInHours(machine: EvaluationMachine): number {
		const timeDiff = new Date().getTime() - machine.provisioningTime.getTime();
		const diffMin = Math.ceil(timeDiff / (1000 * 60 * 60));
		return Math.min(this.lab.machineExpirationInHours, diffMin);
	}

	// machine status tile operations
	getMachinesBarsData(): Array<StretchedDonutBarItem> {
		return this.getActiveMachines().map(m => ({
			id: m.senseMachineId,
			title: m.name,
			value: this.getMachineTimeLeftInHours(m),
			total: this.lab.machineExpirationInHours,
			unit: 'h',
			valueColor: this.knownColorsService.knownColorsMap['greenLight'],
			totalColor: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'],
			width: '100%',
			height: 8,
			disableClick: !m.senseMachineId,
		}));
	}

	getMachineTimeLeftInHours(machine: EvaluationMachine): number {
		if (!machine.provisioningTime) {
			return 0;
		}
		return this.lab.machineExpirationInHours - this.getMachineUpTimeInHours(machine);
	}
}

export const enum ColumnNumber {
	First,
	Second,
}

export enum ConsentType {
	MsInformation,
	MsInformationSharing,
	AttackIQ,
}
