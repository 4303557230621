import { Injectable } from '@angular/core';
import { Feature, FeaturesService } from '@wcd/config';
import { AppConfigService } from '@wcd/app-config';

const CUS3_DATA_CENTER = 'cus3';

@Injectable()
export class ActionCenterService {
	constructor(private featuresService: FeaturesService, private appConfigService: AppConfigService) {}

	/* TODO: disable 'actionCenterApiV2' flag for CUS-3 data center is a temp solution for the new action
	     center service (it's only for Ring0).
	     Need to be removed for the next rings! (Task: https://microsoft.visualstudio.com/OS/_workitems/edit/37853162)
	*/
	isActionCenterApiV2Enabled(): boolean {
		const dataCenter = this.getDataCenterFromUrl();
		return this.featuresService.isEnabled(Feature.ActionCenterApiV2) && dataCenter !== CUS3_DATA_CENTER;
	}

	private getDataCenterFromUrl(): string {
		const serviceUrls = this.appConfigService.originalServiceUrls;
		const dataCenterMatch =
			serviceUrls && serviceUrls.automatedIr && serviceUrls.automatedIr.match(/-(.{4})/);

		return dataCenterMatch && dataCenterMatch[1];
	}
}
