import { DataQuery, DataQuerySortDirection, Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { isNumber, omit } from 'lodash-es';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { MachineGroup } from '../rbac/machine-group.entity';
import { DefenderRunningMode } from './defender-running-mode.enum';
import { MachineHealthStatus } from './machine-health-status.entity';
import { getMachineIdType } from './machine-id-type.enum';
import { MachineRiskScore } from './machine-risk-score/machine-risk-score.entity';
import { OperatingSystem } from './os.value-object';
import { MachineExposureScore } from './machine-exposure-score/machine-exposure-score.entity';
import { DataSensitivity } from '../file/data-sensitivity.value-object';
import { DeviceResource } from './machine-itp-related-data/machine-resource.value-object';
import { DeviceGroupMembership } from './machine-itp-related-data/machine-group-membership.value-object';
import { DeviceUserAccountControlFlags } from './machine-itp-related-data/machine-uac-flags.value-object';
import { MachineExclusionState } from './machine-exclusion/machine-exclusion-state.enum';
import { MachineTags } from './machine-tags.value-object';
import { MachineValue } from './machine-value.enum';
import { DeviceType } from './device-type.enum';
import { DeviceCategory } from './device-category.enum';
import { OnboardingStatus } from './device-onboarding-status.enum';
import { riskScoreNumberToEntity } from './machine-risk-score/machine-risk-score.values';
import { exposureScoreNumberToEntity } from './machine-exposure-score/machine-exposure-score.values';
import { MachineDeviceManager } from './machine-mde-attach/machine-managed-by/machine-managed-by.entity';
import { MemEnrollmentStatus } from './machine-mde-attach/machine-mem-enrollment/machine-mem-enrollment.models';
import { MachineManagedByStatus } from './machine-mde-attach/machine-managed-by-status/machine-managed-by-status.entity';

const fieldIdOrderMap = {
	riskScores: 'riskscore',
	exposureScores: 'exposurescore',
	healthStatuses: 'healthstatus',
	osPlatforms: 'osplatform',
	deviceTypes: 'deviceType',
};

const securityPropertyToAntiVirusStatusConvertion = {
	AntiVirusNotReporting: 'Unknown',
	AntiVirusEnabled: 'Disabled',
	AntiVirusSignatureVersion: 'NotUpdated',
}

export interface MachinesDataQuery extends DataQuery {
	where: MachinesWhereQuery;
}

export interface MachinesWhereQuery {
	machinesApiServiceMigration: boolean;
	useTvmMachinesAvStatus: boolean;
}

@Entity({
	singularName: 'Device',
	pluralName: 'Devices',
	endpoint: (config: WcdPortalParisConfig, query: MachinesDataQuery) => {
		if(query && query.where && query.where.machinesApiServiceMigration)
		{
			return 'machines';
		}

		return 'MachinesQueue';
	},
	allItemsEndpointTrailingSlash: false,
	allItemsProperty: 'data',
	parseItemQuery: itemId => {
		return `machines?machineId=${itemId}&idType=${getMachineIdType(
			String(itemId)
		)}&lookingBackIndays=180`;
	},
	baseUrl: (config: WcdPortalParisConfig, query: MachinesDataQuery) => {
		if(query && query.where && query.where.machinesApiServiceMigration)
		{
			return config.data.serviceUrls.k8s;
		}

		return config.data.serviceUrls.threatIntel;
	},
	parseDataQuery: (dataQuery: DataQuery) => {

		if (!dataQuery) return {};

		const useTvmMachinesAvStatus = (dataQuery.where && dataQuery.where['useTvmMachinesAvStatus']);

		const dataQueryOptions = Object.assign(
			{},
			omit(dataQuery.where as object, ['page', 'page_size', 'ordering', 'machinesApiServiceMigration', 'useTvmMachinesAvStatus']),
			{
				pageIndex: dataQuery.page || 1,
				pageSize: dataQuery.pageSize,
			},
			// todo: remove this default sort once the backend api is fixed (has 'Alerts' instead of 'alerts')
			{
				sortByField: 'alerts',
			},
			dataQuery.sortBy && dataQuery.sortBy.length
				? {
						sortByField: fieldIdOrderMap[dataQuery.sortBy[0].field] || dataQuery.sortBy[0].field,
						sortOrder:
							dataQuery.sortBy[0].direction === DataQuerySortDirection.ascending
								? 'Ascending'
								: 'Descending',
				  }
				: undefined
		);

		// We're transitioning to TVM AV Status, so if the feature is on we'll use TVM AV Status, if not we'll do it the old way
		// For TVM Av Status we introduced a new query parameter - avStatuses, we'll set it up and clear the current one - securityPropertiesRequiringAttention
		if (dataQueryOptions.securityPropertiesRequiringAttention && useTvmMachinesAvStatus) {
			dataQueryOptions.avStatuses = dataQueryOptions.securityPropertiesRequiringAttention.map(property => securityPropertyToAntiVirusStatusConvertion[property]);
			delete dataQueryOptions.securityPropertiesRequiringAttention;
		}

		const hasOutbreakId = !!(dataQuery.where && dataQuery.where['outbreakId']);
		const hasMitigationTypes = !!(dataQuery.where && dataQuery.where['mitigationTypes']);

		if (hasOutbreakId !== hasMitigationTypes) {
			delete dataQueryOptions.outbreakId;
			delete dataQueryOptions.mitigationTypes;
		}

		return dataQueryOptions;
	},
	cache: {
		time: 1000 * 60,
		max: 10,
	},
})
export class Machine extends EntityModelBase {
	@EntityField({ data: 'SenseMachineId' })
	senseMachineId?: string;

	@EntityField({ data: 'SenseMachineId', parse: senseMachineId => !!senseMachineId })
	isMdatp: boolean;

	@EntityField({ data: 'MachineId' })
	machineId?: string;

	@EntityField({ data: 'MachineGuid' })
	machineGuid?: string;

	@EntityField({
		data: ['Id', 'MachineId', 'SenseMachineId', 'MachineGuid', 'ComputerDnsName'],
	})
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: ['ComputerDnsName', 'Name'] })
	name: string;

	@EntityField({ data: 'IpAddress' })
	ipAddress: string;

	@EntityField({ data: '__self', require: 'OsPlatform' })
	os?: OperatingSystem;

	/** This os property is a string sent by ITP constructed from
	 * os platform, build and version together.
	 * Currently prioritizing original os property if exists until ITP
	 * sends all the os relevant properties by its own (in the os property)
	 */
	@EntityField({ data: 'OsVersion' })
	itpOsVersion?: string;

	@EntityField({ data: 'HealthStatus' })
	status?: MachineHealthStatus;

	/**
	 * Returns only from device inventory call.
	 * In device page / side pane call this data returns as part of ExtendedMachineTags property
	 */
	@EntityField({ data: 'MachineGroup' })
	builtInTag?: string;

	@EntityField({ data: 'RbacGroupId' })
	group?: MachineGroup;

	@EntityField({ data: 'Domain' })
	domain?: string;

	@EntityField({ data: 'LastIpAddress' })
	lastIp?: string;

	@EntityField({ data: 'LastExternalIpAddress' })
	lastExternalIp?: string;

	@EntityField({ data: ['LastIpAddress', 'LastExternalIpAddress'] })
	lastCalculatedIp?: string;

	@EntityField({ data: 'FirstSeen' })
	firstSeen?: Date;

	@EntityField({ data: 'LastSeen' })
	lastSeen?: Date;

	@EntityField({ data: 'FirstEventTime' })
	firstEventTime?: Date;

	@EntityField({ data: 'LastEventTime' })
	lastEventTime?: Date;

	@EntityField({ data: ['LastEventTime', 'LastSeen'] })
	lastSeenId?: string;

	@EntityField({ data: 'DefenderRunningMode' })
	defenderRunningMode?: DefenderRunningMode;

	@EntityField({ data: 'InternalMachineId' })
	internalMachineId?: string;

	@EntityField({ data: 'SenseClientVersion' })
	senseClientVersion?: string;

	@EntityField({ data: 'ThreatsCount' })
	threatsCount?: number;

	@EntityField({ data: 'RiskScore', parse: riskScore => isNumber(riskScore) ? riskScoreNumberToEntity[riskScore] : riskScore })
	riskScore?: MachineRiskScore;

	@EntityField({ data: 'DeviceType' })
	deviceType: DeviceType;

	@EntityField({ data: 'DeviceCategory' })
	deviceCategory: DeviceCategory;

	@EntityField({ data: 'SystemManufacturer' })
	vendor?: string;

	@EntityField({ data: 'SystemProductName' })
	model?: string;

	@EntityField({ data: 'IsRiskScoreImpactedByNonMdatpAlerts' })
	isRiskScoreImpactedByNonMdatpAlerts?: boolean;

	@EntityField({ data: 'ExposureScore', parse: exposureScore => (isNumber(exposureScore) ? exposureScoreNumberToEntity[exposureScore] : exposureScore) || 'None' })
	exposureScore?: MachineExposureScore;

	@EntityField({ data: 'AssetValue', parse: assetValue => assetValue || 'Normal' })
	assetValue?: MachineValue;

	@EntityField({ data: 'ExclusionState', parse: exclusionState => exclusionState || MachineExclusionState.Included })
	exclusionState?: MachineExclusionState;

	/**
	 * Returns only from device inventory call.
	 * In device page / side pane call this data returns as part of ExtendedMachineTags property
	 */
	@EntityField({
		data: 'MachineTags',
		parse: tags => {
			if (typeof tags === 'string') {
				return tags.split(',');
			} else return null;
		},
	})
	userDefinedTags?: Array<string>;

	@EntityField({ data: 'DataSensitivity' })
	readonly sensitivity?: DataSensitivity;

	/**
	 * Relevant only for device inventory call.
	 * In device page / side pane call this data returns in ExtendedMachineTags property
	 */
	get tags(): Array<string> {
		return this.builtInTag ? [this.builtInTag, ...(this.userDefinedTags || [])] : this.userDefinedTags;
	}

	/**
	 * Returns only from device page / side pane call.
	 * In device inventory call this data returns in MachineTags and MachineGroup properties
	 */
	@EntityField({ data: 'ExtendedMachineTags' })
	extendedTags: MachineTags;

	@EntityField({ data: 'TotalAlerts' })
	totalAlerts?: number;

	@EntityField({ data: 'ActiveAlerts' })
	activeAlerts?: number;

	@EntityField({ data: 'ResourceAccesses', arrayOf: DeviceResource })
	resources?: Array<DeviceResource>;

	@EntityField({ data: 'Spns' })
	spns?: Array<string>;

	@EntityField({ data: 'ParentGroups', arrayOf: DeviceGroupMembership })
	groupMembership?: Array<DeviceGroupMembership>;

	@EntityField({ data: 'UserAccountControlFlags' })
	uacFlags?: DeviceUserAccountControlFlags;

	@EntityField({ data: 'SamName' })
	samName?: string;

	@EntityField({ data: 'CanonicalName' })
	canonicalName: string;

	@EntityField({ data: 'DistinguishedName' })
	distinguishedName: string;

	@EntityField({ data: 'Sid' })
	sid: string;

	@EntityField({ data: 'CreationTime' })
	creationTime?: Date;

	@EntityField({ data: 'IsManagedByMdatp' })
	isManagedByMdatp?: boolean;

	@EntityField({ data: 'MemEnrollmentStatus' })
	memEnrollmentStatus?: MemEnrollmentStatus;

	@EntityField({ data: 'OnboardingStatus' })
	onboardingStatus?: OnboardingStatus;

	@EntityField({ data: 'LastIpV6Address' })
	lastIpV6Address?: string;

	@EntityField({ data: 'LastMacAddress' })
	lastMacAddress?: string;

	@EntityField({ data: 'AadDeviceId' })
	aadDeviceId?: string;

	@EntityField({ data: 'DeviceOwner' })
	deviceOwner?: string;

	@EntityField({ data: 'IsDeleted' })
	isDeleted?: boolean;

	@EntityField({ data: 'MergedIntoMachineId' })
	mergedIntoMachineId?: string;

	@EntityField({ data: 'ManagedBy' })
	managedBy?: MachineDeviceManager;

	@EntityField({ data: 'ManagedByStatus' })
	managedByStatus?: MachineManagedByStatus;

	private _shortName: string;

	get shortName(): string {
		if (this._shortName === undefined) {
			if (!this.name) this._shortName = '';
			else {
				const shortNameMatch: RegExpMatchArray = this.name.match(/^([^\.]+)/);
				this._shortName = shortNameMatch ? shortNameMatch[1] : '';
			}
		}

		return this._shortName;
	}
}

export interface MachineRequestIds {
	machineId: string;
	senseMachineId?: string;
	computerDnsName?: string;
}

// since sometimes we have a partial machine entity we used an external function
// instead of putting this function inside of the entity as a getter.
export function getMachineRequestIds(machine: Machine): MachineRequestIds {
	return {
		machineId: machine.id,
		senseMachineId: machine.senseMachineId || '',
		computerDnsName: machine.name || '',
	};
}
