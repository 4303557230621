import { EntityRelationship, EntityRelationshipRepositoryType, RelationshipType } from '@microsoft/paris';
import { File } from '../file.entity';
import { MailStats } from '../mail-stats.value-object';

@EntityRelationship({
	sourceEntity: File,
	dataEntity: MailStats,
	allowedTypes: [RelationshipType.OneToOne],
	baseUrl: config => config.data.serviceUrls.threatIntel,
	endpoint: 'MailStats',

	getRelationshipData: (file: File) => ({
		sha1: file.sha1,
		sha256: file.sha256,
	}),
	fixedData: { lookingBackIndays: 30 },
	cache: {
		max: 5,
		time: 5 * 60 * 1000,
	},
})
export class FileMailStatsRelationship implements EntityRelationshipRepositoryType<File, MailStats> {}
