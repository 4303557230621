import { DataviewField } from '@wcd/dataview';
import { I18nService } from '@wcd/i18n';
import { TvmColorService, TvmColor } from '../../../../tvm/services/tvm-color.service';
import { FabricIconNames } from '@wcd/scc-common';
var NetworkScanResultFieldsService = /** @class */ (function () {
    function NetworkScanResultFieldsService(i18nService, tvmColorService) {
        this.i18nService = i18nService;
        this.tvmColorService = tvmColorService;
    }
    Object.defineProperty(NetworkScanResultFieldsService.prototype, "fields", {
        get: function () {
            var _this = this;
            if (!this._fields) {
                // We set always display to all of them as we didn't allow columns customization
                this._fields = DataviewField.fromList([
                    {
                        id: 'ip',
                        name: this.i18nService.get('tvm_networkGear_wizard_fields_device_ip'),
                        alwaysDisplay: true,
                    },
                    {
                        id: 'scanSuccess',
                        name: this.i18nService.get('tvm_networkGear_wizard_fields_status'),
                        getDisplay: function (networkDevice) {
                            if (networkDevice.scanSuccess) {
                                return _this.i18nService.get('tvm_networkGear_wizard_fields_status_success');
                            }
                            return _this.i18nService.get('tvm_networkGear_wizard_fields_status_fail');
                        },
                        icon: {
                            fabricIcon: FabricIconNames.StatusCircleInner,
                            className: function (networkDevice) { return "ms-fontColor-" + (networkDevice.scanSuccess ? _this.tvmColorService.colorsMap.get(TvmColor.Green).name : _this.tvmColorService.colorsMap.get(TvmColor.HighRisk).name); },
                        },
                        alwaysDisplay: true,
                    },
                    {
                        id: 'errorMessage',
                        name: this.i18nService.get('tvm_networkGear_wizard_fields_failure_reason'),
                        alwaysDisplay: true,
                    },
                    {
                        id: 'hostName',
                        name: this.i18nService.get('tvm_networkGear_wizard_fields_device_name'),
                        getDisplay: function (networkScan) { return networkScan.hostName || _this.i18nService.get('tvm_network_devices_unknown_device_name'); },
                        alwaysDisplay: true,
                    },
                    {
                        id: 'description',
                        name: this.i18nService.get('tvm_networkGear_wizard_fields_device_description'),
                        alwaysDisplay: true,
                    },
                ]);
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    return NetworkScanResultFieldsService;
}());
export { NetworkScanResultFieldsService };
