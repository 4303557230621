var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { LiveResponseCommandOutput } from './live-response-command-output.value-object';
import { LiveResponseCommandProgress } from './live-response-command-progress.value-object';
import { isNil } from 'lodash-es';
import { LiveResponseCommandStatus } from './live-response-command-status.entity';
var ɵ0 = function (config) { return config.data.serviceUrls.automatedIr; }, ɵ1 = function (config, query) {
    return query && query.where && query.where['useV2Api']
        ? 'v2/live_response/commands'
        : 'live_response/commands';
}, ɵ2 = function (item) {
    return item.useV2Api ? 'v2/live_response/create_command' : 'live_response/create_command';
}, ɵ3 = function (command) { return ({
    session_id: command.sessionId,
    command_definition_id: command.commandTypeId,
    params: command.params &&
        command.params.map(function (p) { return ({
            param_id: p.paramId,
            value: p.value,
        }); }),
    flags: command.flags,
    raw_command_line: command.rawCommand,
    current_directory: command.currentDirectory,
    background_mode: command.runInBackground,
}); }, ɵ4 = function (outputs, itemData) {
    if (outputs) {
        return outputs;
    }
    var output = itemData['output'];
    if (output) {
        return [{ data_type: 'object', data: output }];
    }
}, ɵ5 = function (progress) { return (typeof progress === 'number' ? undefined : progress); }, ɵ6 = function (progress) { return (typeof progress === 'number' ? progress : undefined); }, ɵ7 = ['output.current_directory', 'context.current_directory'], ɵ8 = ['output.download_token', 'context.download_token'], ɵ9 = ['output.download_file_name', 'context.download_file_name'], ɵ10 = function (fieldData) { return String(fieldData).startsWith('LR'); };
var LiveResponseCommand = /** @class */ (function (_super) {
    __extends(LiveResponseCommand, _super);
    function LiveResponseCommand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(LiveResponseCommand.prototype, "duration", {
        get: function () {
            return this._duration || ((this.endTime || new Date()).valueOf() - this.startTime.valueOf()) / 1000;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LiveResponseCommand.prototype, "currentProgress", {
        get: function () {
            return (this.progress && this.progress.length && this.progress[this.progress.length - 1]) || null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LiveResponseCommand.prototype, "progressPercent", {
        get: function () {
            return !isNil(this._progressPercent)
                ? this._progressPercent
                : this.currentProgress && !isNil(this.currentProgress.progressPercent)
                    ? this.currentProgress.progressPercent
                    : null;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({ data: 'command_id' }),
        __metadata("design:type", Object)
    ], LiveResponseCommand.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'created_time' }),
        __metadata("design:type", Date)
    ], LiveResponseCommand.prototype, "startTime", void 0);
    __decorate([
        EntityField({ data: 'completed_on' }),
        __metadata("design:type", Date)
    ], LiveResponseCommand.prototype, "endTime", void 0);
    __decorate([
        EntityField({ data: 'duration_seconds' }),
        __metadata("design:type", Number)
    ], LiveResponseCommand.prototype, "_duration", void 0);
    __decorate([
        EntityField({ data: 'entities_count' }),
        __metadata("design:type", Number)
    ], LiveResponseCommand.prototype, "entityCount", void 0);
    __decorate([
        EntityField({ data: 'session_id' }),
        __metadata("design:type", Number)
    ], LiveResponseCommand.prototype, "sessionId", void 0);
    __decorate([
        EntityField({ data: 'command_definition_id' }),
        __metadata("design:type", String)
    ], LiveResponseCommand.prototype, "commandTypeId", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", LiveResponseCommandStatus)
    ], LiveResponseCommand.prototype, "status", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], LiveResponseCommand.prototype, "flags", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], LiveResponseCommand.prototype, "params", void 0);
    __decorate([
        EntityField({ data: 'background_mode' }),
        __metadata("design:type", Boolean)
    ], LiveResponseCommand.prototype, "runInBackground", void 0);
    __decorate([
        EntityField({ data: 'raw_command_line' }),
        __metadata("design:type", String)
    ], LiveResponseCommand.prototype, "rawCommand", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], LiveResponseCommand.prototype, "errors", void 0);
    __decorate([
        EntityField({
            parse: ɵ4,
            arrayOf: LiveResponseCommandOutput,
        }),
        __metadata("design:type", Array)
    ], LiveResponseCommand.prototype, "outputs", void 0);
    __decorate([
        EntityField({
            parse: ɵ5,
            arrayOf: LiveResponseCommandProgress,
        }),
        __metadata("design:type", Array)
    ], LiveResponseCommand.prototype, "progress", void 0);
    __decorate([
        EntityField({
            data: 'progress',
            parse: ɵ6,
        }),
        __metadata("design:type", Number)
    ], LiveResponseCommand.prototype, "_progressPercent", void 0);
    __decorate([
        EntityField({ data: 'current_directory' }),
        __metadata("design:type", String)
    ], LiveResponseCommand.prototype, "currentDirectory", void 0);
    __decorate([
        EntityField({ data: ɵ7 }),
        __metadata("design:type", String)
    ], LiveResponseCommand.prototype, "newCurrentDirectory", void 0);
    __decorate([
        EntityField({ data: ɵ8 }),
        __metadata("design:type", String)
    ], LiveResponseCommand.prototype, "downloadToken", void 0);
    __decorate([
        EntityField({ data: 'context.download_url' }),
        __metadata("design:type", String)
    ], LiveResponseCommand.prototype, "downloadUrl", void 0);
    __decorate([
        EntityField({ data: ɵ9 }),
        __metadata("design:type", String)
    ], LiveResponseCommand.prototype, "downloadFileName", void 0);
    __decorate([
        EntityField({ data: 'powershell_transcript' }),
        __metadata("design:type", String)
    ], LiveResponseCommand.prototype, "powershellTranscript", void 0);
    __decorate([
        EntityField({ data: 'session_id', parse: ɵ10 }),
        __metadata("design:type", Boolean)
    ], LiveResponseCommand.prototype, "useV2Api", void 0);
    LiveResponseCommand = __decorate([
        Entity({
            singularName: 'Command',
            pluralName: 'Commands',
            baseUrl: ɵ0,
            endpoint: ɵ1,
            parseSaveQuery: ɵ2,
            serializeItem: ɵ3,
        })
    ], LiveResponseCommand);
    return LiveResponseCommand;
}(EntityModelBase));
export { LiveResponseCommand };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10 };
