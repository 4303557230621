import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Injector,
	ViewChild,
	OnInit,
	Input,
} from '@angular/core';
import { SuppressionRule, SuppressionRuleHistoryItem, SuppressionRulePredicateValueType, SuppressionRuleType } from '@wcd/domain';
import { EntityDetailsComponentBase } from '../../../global_entities/components/entity-details/entity-details.component.base';
import { NavItemModel } from '@wcd/shared';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { I18nService } from '@wcd/i18n';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';
import { RbacControlSettings } from '../../../rbac/models/rbac-control-settings.model';
import { SuppressionRulesBackendService } from '../services/suppression-rules.backend.service';
import { NgForm } from '@angular/forms';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { DataSet, Paris, Repository } from '@microsoft/paris';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { map } from 'rxjs/operators';
import { CommentModel } from '../../../comments/models/comment.model';
import { TryItUrls } from '../../../mtp-promotion/enums/try-it-urls';
import { MtpPromotionService } from '../../../mtp-promotion/services/mtp-promotion.service';

enum CollapsibleID {
	Details = 'suppression-rule-entity-details',
}

@Component({
	selector: 'suppression-rule-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './suppression-rule.entity-details.component.html',
})
export class SuppressionRuleEntityDetailsComponent
	extends EntityDetailsComponentBase<SuppressionRule> implements OnInit {
	@Input() isSuppressionRuleEntityPanel = false;
	machineLink: Partial<NavItemModel>;
	machineName: string;

	readonly urls = TryItUrls;
	readonly collapsibleID = CollapsibleID;
	readonly suppressionRuleType = SuppressionRuleType;

	history: Array<any>;
	rbacSettingsDisabled: RbacControlSettings;
	isSavingComment: boolean = false;
	rulesRepo: Repository<SuppressionRule>;
	ruleHistoryItemsRepo: Repository<SuppressionRuleHistoryItem>;
	@ViewChild('suppressionRuleForm', { static: false }) suppressionRuleForm: NgForm;

	constructor(
		injector: Injector,
		private paris: Paris,
		private changeDetectorRef: ChangeDetectorRef,
		public globalEntityTypesService: GlobalEntityTypesService,
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		private suppressionRulesBackendService: SuppressionRulesBackendService,
		private appInsightsService: AppInsightsService,
		public mtpPromotionService: MtpPromotionService,
	) {
		super(injector);
		this.rulesRepo = paris.getRepository(SuppressionRule);
		this.ruleHistoryItemsRepo = paris.getRepository(SuppressionRuleHistoryItem);
	}

	ngOnInit() {
		this.setCommentsHistory();
	}

	get rule(): SuppressionRule {
		return this.entity;
	}

	setMachineDetails() {
		this.machineLink = this.globalEntityTypesService.getRouteLinkModel('machine', this.rule.machine);
		this.machineName = this.globalEntityTypesService.getEntityName('machine', this.rule.machine);
	}

	conditionalScopeText() {
		let machinesCount = 0;
		let usersCount = 0;
		let machineGroupCount = 0;
		this.rule.scopeConditions && this.rule.scopeConditions.forEach(group => group.forEach(condition => {
			switch (condition.valueType) {
				case SuppressionRulePredicateValueType.SenseMachineId:
					machinesCount += condition.values.length;
					break;
				case SuppressionRulePredicateValueType.UserSid:
					usersCount += condition.values.length;
					break;
				case SuppressionRulePredicateValueType.RbacGroupId:
					machineGroupCount += condition.values.length;
					break;
			}
		}));

		const text: string[] = [];
		machinesCount && text.push(this.scopeText(machinesCount, 'suppressionRules.scopeType.machineGroups.single', 'suppressionRules.scopeType.machineGroups'));
		machineGroupCount && text.push(this.scopeText(machineGroupCount, 'suppressionRules.scopeType.machineGroups.single', 'suppressionRules.scopeType.machineGroups'));
		usersCount && text.push(this.scopeText(usersCount, 'suppressionRules.scopeType.machineGroups.single', 'suppressionRules.scopeType.machineGroups'));
		return text.join();
	}

	scopeText(numGroups: number, singularText: string, pluralText: string) {
		return numGroups ?
			numGroups != 1
				? this.i18nService.get(pluralText, { numGroups })
				: this.i18nService.get(singularText)
			: '';
	}

	setEntity(entity: SuppressionRule) {
		super.setEntity(entity);
		this.setMachineDetails();
		this.changeDetectorRef.markForCheck();
	}

	addCommentToRule(comment: string): void {
		this.isSavingComment = true;
		const commentForSave = this.getCommentBackendData(comment);

		this.suppressionRulesBackendService.addCommentToRule(commentForSave).subscribe(
			() => {
				this.isSavingComment = false;
				this.suppressionRuleForm.form.markAsPristine();
				this.appInsightsService.track({
					type: TrackingEventType.EntitySave,
					id: this.rulesRepo.entity.singularName,
				});
				this.dialogsService.showSuccessSnackbar({
					text: this.i18nService.get('suppressionRules.comment.saved'),
				});

				this.setCommentsHistory();
			},
			error => {
				this.isSavingComment = false;
			}
		);
	}

	private getCommentBackendData(comment: string): any {
		return {
			ContextObjId: this.rule.id,
			NewValue: comment,
		};
	}

	setCommentsHistory() {
		const ruleHistoryItemSubscriber = this.ruleHistoryItemsRepo
			.query({ where: { id: this.rule.id } })
			.pipe(map((dataSet: DataSet<SuppressionRuleHistoryItem>) => dataSet.items))
			.subscribe(items => {
				this.history = items.map((ruleHistoryItem: SuppressionRuleHistoryItem) => {
					return new CommentModel({
						id: ruleHistoryItem.id,
						timestamp: ruleHistoryItem.timestamp,
						user: ruleHistoryItem.userName,
						message: ruleHistoryItem.message,
						icon: ruleHistoryItem.type ? ruleHistoryItem.type.icon : 'Comment',
					});
				});
				this.changeDetectorRef.detectChanges();
				ruleHistoryItemSubscriber.unsubscribe();
			});
	}
}
