var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
var ɵ0 = function (config, query) {
    return query && query.where && query.where['useV2Api']
        ? 'v2/live_response/download_file'
        : 'live_response/download_file';
}, ɵ1 = function (config) { return config.data.serviceUrls.automatedIr; }, ɵ2 = function (command) {
    return {
        params: {
            token: command.downloadToken,
            session_id: command.sessionId,
            useV2Api: command.useV2Api,
        },
    };
}, ɵ3 = function (file) { return new Blob([file], { type: 'application/octet-stream' }); };
var DownloadLiveResponseFileApiCall = /** @class */ (function (_super) {
    __extends(DownloadLiveResponseFileApiCall, _super);
    function DownloadLiveResponseFileApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DownloadLiveResponseFileApiCall = __decorate([
        ApiCall({
            name: 'Download file',
            endpoint: ɵ0,
            baseUrl: ɵ1,
            responseType: 'blob',
            parseQuery: ɵ2,
            // create a new blob to force default blob type
            parseData: ɵ3,
        })
    ], DownloadLiveResponseFileApiCall);
    return DownloadLiveResponseFileApiCall;
}(ApiCallModel));
export { DownloadLiveResponseFileApiCall };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
