import { DataQuery, DataQuerySortDirection, Entity, EntityField } from '@microsoft/paris';
import { AlertBase } from '../alert/alert.entity.base';
import { DetectionSource } from '../alert/sources/detection-source.entity';
import { Machine } from '../machine/machine.entity';
import { MachineGroup } from '../rbac/machine-group.entity';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { IncidentStatus } from './incident-status/incident-status.entity';
import { alertStatusValues } from '../alert/alert-status/alert-status.entity.values';
import { severityValues } from '../severity/severity.entity.values';
import { DataSensitivity } from '../file/data-sensitivity.value-object';
import { ImpactedEntities } from '../impacted-entities/incident.impacted.entities';
import { Alert, THREAT_EXPERT_DETECTION_SOURCE_TYPE } from '../alert/alert.entity';
import { MitreCategories, OtherCategories } from './alerts-categories.enum';
import { ServiceSource } from '../alert/sources/service-source.entity';
import { IStatus } from '../status.interface';
import { castArray, flatMap, flatten } from 'lodash-es';
import { InvestigationStatusToFilterQueryString } from '../alert/alert-investiation-status-mapping.enum';
import { IncidentType } from './incident-type';
import { IncidentTags } from './incident-tags.value-object';
import { serializeIncidentAssignmentFilters } from './incident-assignment-filter.utils';
import { AlertsSeveritySummary } from '../alert/alerts-severity-summary.value-object';

// This function is used to convert query params format, note that it used by both API calls (Get and POST) see IncidentApiCall
export function convertSeverityParameter(where: { [p: string]: any } | string) {
	const severityFilterValue = where['severity'];

	if (severityFilterValue && severityFilterValue instanceof Array) {
		where['severity'] = severityValues
			.filter(
				(severity) =>
					severity.isSelectable &&
					(severityFilterValue.includes(severity.id) ||
						severityFilterValue.includes(severity.id.toString()))
			)
			.reduce((bitwiseSeverity, severity) => bitwiseSeverity | severity.id, 0);
	}
}

// This function is used to convert query params format, note that it used by both API calls (Get and POST) see IncidentApiCall
export function convertOSPlatformParameter(where: { [p: string]: any } | string) {
	const osPlatformValue = where['osPlatform'];

	if (osPlatformValue && osPlatformValue.length > 0) {
		where['osPlatform'] = flatten(osPlatformValue.map((osValue) => osValue.split(',')));
	}
}

// This function is used to convert query params format, note that it used by both API calls (Get and POST) see IncidentApiCall
export function convertStatusParameter(
	where: { [p: string]: any } | string,
	statusKey: string,
	statusValues: IStatus[]
) {
	const statusFilterValue = where[statusKey];

	if (statusFilterValue && statusFilterValue instanceof Array) {
		where[statusKey] = statusValues
			.filter((status) => statusFilterValue.includes(status.type))
			.reduce((bitwiseSeverity, status) => bitwiseSeverity | (status.id as number), 0);
	}
}

// This function is used to convert query params format, note that it used by both API calls (Get and POST) see IncidentApiCall
export function convertInvestigationStatusParameter(
	useNameConversion: boolean,
	where: { [p: string]: any } | string
) {
	const investigationStatesValue = where['investigationStates'];
	if (investigationStatesValue && investigationStatesValue instanceof Array) {
		const parsedValues = castArray(investigationStatesValue).map((val) => parseInt(val, 10));
		const transformedValues: InvestigationStatusToFilterQueryString[] = flatMap(parsedValues, (val) =>
			val === InvestigationStatusToFilterQueryString.Pending
				? [
						InvestigationStatusToFilterQueryString.PendingApproval,
						InvestigationStatusToFilterQueryString.PendingResource,
				  ]
				: [val]
		);
		if (useNameConversion) {
			const namedTransformedValues = transformedValues.map(
				(val) =>
					InvestigationStatusToFilterQueryString[
						val
					] as keyof typeof InvestigationStatusToFilterQueryString
			);
			where['investigationStates'] = namedTransformedValues;
		} else {
			where['investigationStates'] = transformedValues;
		}
	}
}

@Entity({
	singularName: 'Incident',
	pluralName: 'Incidents',
	endpoint: (config, query) =>
		query.where && query.where['isMultipleIncidents'] ? 'incidents/alerts' : 'incidents',
	allItemsProperty: 'results',
	baseUrl: (config: WcdPortalParisConfig, query: DataQuery) =>
		query.where && query.where['newIncidentApi']
			? config.data.serviceUrls.incidents || config.data.serviceUrls.incidentQueue
			: config.data.serviceUrls.threatIntel,
	// remove threatIntel and incidentQueue service urls after migration is complete.
	cache: {
		time: 1000 * 60,
		max: 10,
	},
	separateArrayParams: true,
	parseDataQuery: (dataQuery: DataQuery) => {
		if (!dataQuery) return {};

		const pageSettings: { pageIndex?: number; pageSize?: number } = {};
		if (dataQuery.page) pageSettings.pageIndex = dataQuery.page;

		if (dataQuery.pageSize) pageSettings.pageSize = dataQuery.pageSize;

		const where = dataQuery.where;
		if (where) {
			convertSeverityParameter(where);
			convertInvestigationStatusParameter(true, where);
			convertOSPlatformParameter(where);
			convertStatusParameter(where, 'AlertStatus', alertStatusValues);

			// This serialization must happen here and not at incident.fields.ts, to maintain the checkbox selection state
			serializeIncidentAssignmentFilters(where);
			delete where['status'];
		}

		return Object.assign(
			{},
			where,
			pageSettings,
			dataQuery.sortBy && dataQuery.sortBy.length
				? {
						sortByField: dataQuery.sortBy[0].field,
						sortOrder:
							dataQuery.sortBy[0].direction === DataQuerySortDirection.ascending
								? 'Ascending'
								: 'Descending',
				  }
				: undefined
		);
	},
})
export class Incident extends AlertBase {
	@EntityField({ data: 'IncidentId' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'TenantId' })
	tenantId?: string;

	@EntityField({ data: '__self' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	machine: Machine;

	@EntityField({ data: 'AlertCount' })
	alertCount: number;

	@EntityField({ data: 'ActiveAlertCount' })
	activeAlertCount: number;

	@EntityField({ data: 'AlertsSeveritiesSummary' })
	alertsSeveritiesSummary?: AlertsSeveritySummary;

	@EntityField({ data: 'Tags', required: false })
	tags?: Array<string>;

	@EntityField({ data: 'IncidentTags', required: false })
	incidentTags?: IncidentTags;

	@EntityField({
		data: 'CategoriesHitCounts',
		required: false,
		parse: (categoriesHitCounts) => {
			const mitreCategories: Map<string, number> = new Map<string, number>();
			return Object.keys(MitreCategories).reduce(
				(result, key) =>
					result.set(
						key,
						(categoriesHitCounts &&
							categoriesHitCounts.MitreCategories &&
							categoriesHitCounts.MitreCategories[key]) ||
							0
					),
				mitreCategories
			);
		},
	})
	mitreCategories?: Map<string, number>;

	@EntityField({
		data: 'CategoriesHitCounts',
		required: false,
		parse: (categoriesHitCounts) => {
			const otherCategories: Map<string, number> = new Map<string, number>();
			return Object.keys(OtherCategories).reduce(
				(result, key) =>
					result.set(
						key,
						(categoriesHitCounts &&
							categoriesHitCounts.OtherCategories &&
							categoriesHitCounts.OtherCategories[key]) ||
							0
					),
				otherCategories
			);
		},
	})
	otherCategories?: Map<string, number>;

	@EntityField({ data: 'AlertCountMacthingResoureFilter' })
	alertCountForResource: number;

	@EntityField({ data: 'ImpactedUserCount' })
	aadUserCount: number;

	@EntityField({ data: 'InvestigationStatesCount' })
	investigationStatesCount: number;

	@EntityField({ data: 'InvestigationCount' })
	investigationCount: number;

	@EntityField({ data: 'InvestigationIds' })
	investigationIds: Array<number>;

	@EntityField({ data: '__self', require: 'SensitivityLabel' })
	sensitivity: DataSensitivity;

	@EntityField({
		data: 'Status',
		parse: (status) => {
			if (!status) return status;

			const statusLog = Math.log2(status);
			return statusLog === Math.floor(statusLog) ? status : null;
		},
	})
	status: IncidentStatus;

	@EntityField({ data: 'ImpactedEntities' })
	impactedEntities?: ImpactedEntities;

	@EntityField({ data: 'RbacGroupIds', required: false, arrayOf: MachineGroup })
	machineGroups?: MachineGroup[];

	@EntityField({ data: 'DetectionSources', required: false, arrayOf: DetectionSource })
	detectionSources?: DetectionSource[];

	@EntityField({ data: 'ProductSources', required: false, arrayOf: ServiceSource })
	serviceSources?: ServiceSource[];

	@EntityField({ data: 'ActorNames', required: false, arrayOf: String })
	actors?: string[];

	@EntityField({ data: 'MtpAlertsExist', required: false })
	mtpAlertsExist?: boolean;

	@EntityField({ data: ['FullyMachineRbacExposed', 'FullyRbacExposed'] })
	isFullyMachineRbacExposed: boolean;

	@EntityField({ data: 'Alerts', arrayOf: Alert })
	alerts?: Alert[];

	@EntityField({ data: 'IncidentType' })
	incidentType?: IncidentType;

	@EntityField({ data: 'RecommendedAHQs' })
	recommendedAHQs?: string[];

	@EntityField({ data: 'RecommendedActions' })
	recommendedActions?: string;

	@EntityField({ data: 'Description' })
	description?: string;

	private getExpertDetectionSource = () =>
		this.detectionSources &&
		this.detectionSources.find((source) => source && source.type === THREAT_EXPERT_DETECTION_SOURCE_TYPE);

	get threatExpertName(): string | null {
		const expertDetectionSource = this.getExpertDetectionSource();
		return expertDetectionSource && expertDetectionSource.name;
	}

	// Localized version of threatExpertName that return the i18n key instead of the name.
	get threatExpertI18nNameKey(): string | null {
		const expertDetectionSource = this.getExpertDetectionSource();
		return expertDetectionSource && expertDetectionSource.nameI18nKey;
	}
}
