import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormComponent } from '../../../../shared/models/form.component';
import { TabModel } from '../../../../shared/components/tabs/tab.model';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import {
	AadGroup,
	UserRole,
	MdeUserRoleAction,
	DeprecatedUserRoleAction,
	MdeUserRoleActionEnum,
} from '@wcd/domain';
import { DataSet, Paris, Repository } from '@microsoft/paris';
import { ConfirmEvent } from '../../../../dialogs/confirm/confirm.event';
import { AuthService, UserAuthEnforcementMode } from '@wcd/auth';
import { UserRoleActionsService } from '../../services/user-role-actions.service';
import { find } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { FeaturesService, Feature } from '@wcd/config';
import { TrackingEventType } from '@wcd/telemetry';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';
import { RbacComponentType } from '../shared/aad-groups-assignment-tab';
import { MessageBarType } from 'office-ui-fabric-react';
import { AppConfigService } from '@wcd/app-config';
import { sccHostService } from '@wcd/scc-interface';

@Component({
	selector: 'user-role-edit',
	templateUrl: './user-role-edit.component.html',
	styles: [
		`
			.user-role-edit-live-response-checkbox-disabled {
				opacity: 0.54;
			}
		`,
	],
})
export class UserRoleEditComponent implements OnInit, FormComponent {
	@Input() userRole: UserRole;

	@Output() save: EventEmitter<UserRole> = new EventEmitter<UserRole>();
	@Output() cancel: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('userRoleForm', { static: false }) userRoleForm: NgForm;

	isScc = sccHostService.isSCC;
	RbacComponentType = RbacComponentType;

	repository: Repository<UserRole>;
	aadGroupsRepository: Repository<AadGroup>;
	userRoleActionsRepository: Repository<MdeUserRoleAction>;
	displayedUserRoleActions: Array<MdeUserRoleAction>;
	displayedUserRoleActionsForGlobalAdmin: Array<MdeUserRoleAction>;
	liveResponseUserRoleActions: Array<MdeUserRoleAction>;
	availableAadGroups: Array<AadGroup>;
	selectedAadGroups: Array<AadGroup>;
	selectedAssignedAadGroups: Array<AadGroup>;
	assignedAadGroups: Array<AadGroup>;

	selectedLiveResponsePermission: MdeUserRoleAction = null;
	liveResponseFeatureName: string = Feature.LiveResponse;

	isLoadingAadGroups: boolean = false;
	isAadGroupsError: boolean = false;

	RbacMachineGroupsForMtpEnabled = false;

	editedUserRole: UserRole;
	isSaving: boolean = false;
	isError: boolean;
	readonly MessageBarType = MessageBarType;
	isUrbacEnabled: boolean;

	tabs: Array<TabModel> = [
		{
			id: 'details',
			name: this.i18nService.get('userRoles.panel.tab.general'),
		},
		{
			id: 'assignment',
			name: this.i18nService.get('userRoles.panel.tab.assigned'),
		},
	].map(tab => new TabModel(tab));

	currentTab = this.tabs[0];

	private aadGroupsFilterResults: Array<AadGroup>;

	get isDirty(): boolean {
		return this.userRoleForm.form.dirty;
	}

	get confirmDeactivateText(): string {
		return `Are you sure you wish to discard ${this.userRole.id ? 'changes to ' : ''
			}this ${this.repository.entity.singularName.toLowerCase()}?`;
	}

	constructor(
		private paris: Paris,
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		public authService: AuthService,
		public userRoleActionsService: UserRoleActionsService,
		public featuresService: FeaturesService,
		private readonly appInsightsService: AppInsightsService,
		appConfigService: AppConfigService,
	) {
		this.repository = paris.getRepository(UserRole);
		this.aadGroupsRepository = paris.getRepository(AadGroup);
		this.userRoleActionsRepository = paris.getRepository(
			this.featuresService.isEnabled(Feature.TvmRoles) ? MdeUserRoleAction : DeprecatedUserRoleAction
		);

		this.isUrbacEnabled = appConfigService.userAuthEnforcementMode === UserAuthEnforcementMode.UnifiedRbac;

		this.RbacMachineGroupsForMtpEnabled = this.featuresService.isEnabled(Feature.RbacMachineGroupsForMtp);

		// filter out roles by features
		const userRoleActions = this.userRoleActionsRepository.entity.values.filter(userRoleAction => {
			const shouldKeep =
				!userRoleAction.featureFlags || this.featuresService.isEnabled(userRoleAction.featureFlags);
			if (shouldKeep && userRoleAction.children) {
				const childrenToFilter = userRoleAction.children.filter(
					child => child.featureFlags && !this.featuresService.isEnabled(child.featureFlags)
				);

				childrenToFilter.forEach(child => {
					const index = userRoleAction.children.indexOf(child, 0);
					if (index > -1) {
						userRoleAction.children.splice(index, 1);
					}
				});
			}
			return shouldKeep;
		});

		this.displayedUserRoleActions = userRoleActions.filter(
			(userRoleAction: MdeUserRoleAction) => !userRoleAction.isLiveResponseAction && !userRoleAction.hidden
		);
		this.displayedUserRoleActionsForGlobalAdmin = userRoleActions.filter(
			(userRoleAction: MdeUserRoleAction) =>
				!userRoleAction.isLiveResponseAction && !userRoleAction.isAdmin
		);
		this.liveResponseUserRoleActions = userRoleActions
			.filter((userRoleAction: MdeUserRoleAction) => userRoleAction.isLiveResponseAction)
			.map(
				(userRoleAction: MdeUserRoleAction) =>
					new MdeUserRoleAction({
						...userRoleAction,
						displayName: this.i18nService.get(userRoleAction.displayNameKey),
					})
			);
	}

	ngOnInit() {
		this.editedUserRole = this.userRole ? new UserRole(this.userRole) : this.repository.createNewItem();
		const allAssignedAadGroups = this.editedUserRole.assignments.map(assignment => assignment.aadGroup);
		const groupsDontExist = allAssignedAadGroups.filter(aadGroup => !aadGroup.name);
		this.assignedAadGroups = allAssignedAadGroups.filter(aadGroup => aadGroup.name);
		const newGroupsDontExist = groupsDontExist.map(group => {
			return {
				id: group.id,
				name: this.i18nService.get('userRoles.groupDoesNotExist'),
				helpText: this.i18nService.get('userRoles.groupDoesNotExistHelp'),
			};
		});

		this.assignedAadGroups = [...this.assignedAadGroups, ...newGroupsDontExist];

		if (!this.userRole)
			this.editedUserRole.allowedActions = this.featuresService.isEnabled(Feature.TvmRoles)
				? [this.displayedUserRoleActions[0], ...this.displayedUserRoleActions[0].children]
				: [this.displayedUserRoleActions[0]];

		if (this.editedUserRole.isGlobalAdmin) {
			this.editedUserRole.name = this.i18nService.get('userRoles.globalAdminName');
			this.editedUserRole.description = this.i18nService.get('userRoles.globalAdminDescription');
		}

		// look for LiveResponseAdvanced permission and fallback to LiveResponseBasic or null
		const roleLiveResponsePermissions = this.editedUserRole.allowedActions.filter(
			action => action.isLiveResponseAction
		);
		const selectedLiveResponsePermissionId =
			this.userRole && roleLiveResponsePermissions.length > 0
				? (
					roleLiveResponsePermissions.find(
						action => action.id === MdeUserRoleActionEnum.liveResponseAdvanced
					) || roleLiveResponsePermissions.pop()
				).id
				: 0;
		this.selectedLiveResponsePermission = this.liveResponseUserRoleActions.find(
			role => role.id === selectedLiveResponsePermissionId
		);

		this.setAvailableAadGroups();
	}

	saveUserRole() {
		if (!this.editedUserRole.assignments.length) {
			this.dialogsService
				.confirm({
					title: this.i18nService.get('userRoles_saveWarningDialog_title'),
					text: this.i18nService.get('userRoles_saveWarningDialog_text'),
					confirmText: this.i18nService.get('common_saveAndClose'),
					cancelText: this.i18nService.get('buttons_cancel'),
				})
				.then((e: ConfirmEvent) => e.confirmed && this.doSave());
		} else this.doSave();
	}

	private doSave() {
		this.isSaving = true;

		// first remove previous live response permission selection and then add the new one if any
		this.editedUserRole.allowedActions = this.editedUserRole.allowedActions.filter(
			a => !a.isLiveResponseAction
		);
		if (this.selectedLiveResponsePermission) {
			this.editedUserRole.allowedActions.push(this.selectedLiveResponsePermission);
		}

		this.editedUserRole.oldAllowedActions = this.editedUserRole.allowedActions;

		this.appInsightsService.trackEvent('RolesCreation', {
			type: TrackingEventType.Save,
			id: 'RolesCreation',
			allowedActions: this.editedUserRole.allowedActions.map(allowedAction => allowedAction.id),
		});

		this.repository.save(this.editedUserRole).subscribe(
			(savedUserRole: UserRole) => {
				this.isSaving = false;
				this.userRoleForm.form.markAsPristine();
				this.save.emit(savedUserRole);
			},
			error => {
				this.isSaving = false;
				this.isError = true;
			}
		);
	}

	addSelectedGroups() {
		this.assignedAadGroups = this.assignedAadGroups.concat(this.selectedAadGroups);
		this.setAvailableAadGroupsWithoutAssigned();
		this.userRoleForm.form.markAsDirty();
		this.selectedAadGroups = [];
		this.assignSelectedGroupsToUserRole();
	}

	removeSelectedGroups() {
		this.assignedAadGroups = this.assignedAadGroups.filter(
			assignedAadGroup => !~this.selectedAssignedAadGroups.indexOf(assignedAadGroup)
		);
		this.selectedAssignedAadGroups = [];
		this.setAvailableAadGroupsWithoutAssigned();
		this.userRoleForm.form.markAsDirty();
		this.assignSelectedGroupsToUserRole();
	}

	// called when URBAC feature is enabled
	applySelectedAadGroups(aadGroups: Array<AadGroup>) {
		this.assignedAadGroups = aadGroups;
		this.userRoleForm.form.markAsDirty();
		this.assignSelectedGroupsToUserRole();
	}

	setAvailableAadGroups(filter: string = '') {
		this.isLoadingAadGroups = true;
		this.isAadGroupsError = false;

		this.aadGroupsRepository.query({ where: { filter } }).subscribe(
			(aadGroups: DataSet<AadGroup>) => {
				this.aadGroupsFilterResults = aadGroups.items;
				this.setAvailableAadGroupsWithoutAssigned();
				this.isLoadingAadGroups = false;
			},
			error => {
				this.isLoadingAadGroups = false;
				this.isAadGroupsError = true;
			}
		);
	}

	private setAvailableAadGroupsWithoutAssigned() {
		this.availableAadGroups = this.aadGroupsFilterResults.filter(
			aadGroup => !find(this.assignedAadGroups, assignedAadGroup => aadGroup.id === assignedAadGroup.id)
		);
	}

	private assignSelectedGroupsToUserRole() {
		this.editedUserRole.setGroups(this.assignedAadGroups);
	}
}
