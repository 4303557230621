import { Injectable, OnDestroy } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { compact, lowerFirst } from 'lodash-es';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
	Alert,
	AlertCategory,
	AlertClassification,
	AlertStatus,
	DetectionSource,
	InvestigationStatus,
	Machine,
	Outbreak,
	Severity,
} from '@wcd/domain';
import { AuthService } from '@wcd/auth';
import { AppConfigService } from '@wcd/app-config';
import { AppContextService, Feature, FeaturesService, FlavorService } from '@wcd/config';
import { DataviewField, DataviewFieldConfig } from '@wcd/dataview';
import { I18nService } from '@wcd/i18n';
import { RbacService } from '../../../rbac/services/rbac.service';
import { Prettify } from '../../../utils/services/prettify.service';
import { MachinesFiltersService } from '../../machines/services/machines.filters.service';
import { FilterValuesChecklistComponent } from '@wcd/ng-filters';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { FabricIconNames } from '@wcd/scc-common';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { AlertNameFieldComponent } from '../components/alert-name-field.component';
import { IconsService, WcdIconNames } from '@wcd/icons';
import { ImpactedEntitiesCounterComponent } from '../../../impacted-entities/components/impacted-entities-counter/impacted-entities-counter.component';
import { AlertReasonsFieldComponent } from '../components/alert-reasons-field.component';
import { getServiceSourceFilterConfig } from '../../common/helpers/service-source-filters.helpers';
import { TagsFieldComponent } from '../../../tags/components/tags-field/tags-field.component';
import { OVERFLOW_TAGS_CONTAINER_CLASS } from '../../../tags/components/tags-list/overflow-tags-list.component';
import { EntityTagsService } from '../../common/services/entity-tags.service';
import { AppFlavorConfig } from '@wcd/scc-common';
import { IncidentNameComponent } from '../../incidents/components/incident.name.component';

type PartialDataviewFiled = Partial<DataviewField>;

interface FieldsData {
	expand: PartialDataviewFiled;
	title: PartialDataviewFiled;
	tags: PartialDataviewFiled;
	severity: PartialDataviewFiled;
	incident: PartialDataviewFiled;
	incidentId: PartialDataviewFiled;
	status: PartialDataviewFiled;
	classification: PartialDataviewFiled;
	determination: PartialDataviewFiled;
	investigationStates: PartialDataviewFiled;
	linkedBy: PartialDataviewFiled;
	category: PartialDataviewFiled;
	impactedEntities: PartialDataviewFiled;
	machine: PartialDataviewFiled;
	user: PartialDataviewFiled;
	AssignedTo: PartialDataviewFiled;
	serviceSource: PartialDataviewFiled;
	detectionSource: PartialDataviewFiled;
	firstSeen: PartialDataviewFiled;
	osPlatform: PartialDataviewFiled;
	firstEventTime: PartialDataviewFiled;
	lasteventtime: PartialDataviewFiled;
	rbacGroup: PartialDataviewFiled;
	IoaDefinitionIds: PartialDataviewFiled;
}

interface InvestigationExtraData {
	enabled: PartialDataviewFiled;
	disabled: PartialDataviewFiled;
}

const ENTITY_PAGES_ALERTS_FIELDS = [
	'title',
	'severity',
	'status',
	'classification',
	'investigationStates',
	'category',
	'machine',
	'AssignedTo',
	'lasteventtime',
];

@Injectable()
export class AlertsFields implements OnDestroy {
	readonly FIELDS_DATA: FieldsData;
	readonly INVESTIGATION_STATE_EXTRA_DATA: InvestigationExtraData;

	private _allFields: Array<DataviewField>;
	private _fields: Array<DataviewField>;

	private _isRbacFilteringNecessary: boolean = false;
	private _isAutomatedIrEnabledSubscription: Subscription;
	private _isRbacFilteringNecessarySubscription: Subscription;

	alertFieldsExtended: boolean;

	constructor(
		private authService: AuthService,
		private paris: Paris,
		private featuresService: FeaturesService,
		private appConfigService: AppConfigService,
		private machinesFiltersService: MachinesFiltersService,
		private entityTagsService: EntityTagsService,
		private i18nService: I18nService,
		private globalEntityTypes: GlobalEntityTypesService,
		private iconsService: IconsService,
		private appContextService: AppContextService,
		flavorService: FlavorService,
		rbacService: RbacService
	) {
		this.alertFieldsExtended =
			flavorService.isEnabled(AppFlavorConfig.alerts.alertFieldsExtended) ||
			flavorService.isEnabled(AppFlavorConfig.incidents.alertFieldsExtended);

		this.FIELDS_DATA = this.getAllFieldsData();
		this.INVESTIGATION_STATE_EXTRA_DATA = this.getInvestigationExtraData();

		this._isAutomatedIrEnabledSubscription = this.appConfigService.isAutomatedIrEnabled$.subscribe(
			() => (this._allFields = this._fields = null)
		);
		this._isRbacFilteringNecessarySubscription = rbacService.isFilteringNecessary$
			.pipe(filter((value: boolean) => this._isRbacFilteringNecessary != value))
			.subscribe((value: boolean) => {
				this._isRbacFilteringNecessary = value;
				this._allFields = this._fields = null;
			});
	}

	// this fields includes also fields that are relevant in incident context only
	get allFields(): Array<DataviewField<Alert>> {
		const fieldsData = this.FIELDS_DATA;
		if (!this._allFields) {
			this._allFields = DataviewField.fromList<Alert>(
				compact(<Array<DataviewFieldConfig>>[
					fieldsData.expand,
					fieldsData.firstEventTime,
					fieldsData.title,
					this.shouldShowTagsColumn() && fieldsData.tags,
					fieldsData.severity,
					this.featuresService.isEnabled(Feature.Incidents) && fieldsData.incident,
					fieldsData.incidentId,
					fieldsData.status,
					this.featuresService.isEnabled(Feature.Incidents) && fieldsData.linkedBy,
					fieldsData.category,
					this.appContextService.isMtp && fieldsData.impactedEntities,
					fieldsData.machine,
					fieldsData.user,
					this.shouldShowServiceSources() && fieldsData.serviceSource,
					fieldsData.detectionSource,
					fieldsData.classification,
					fieldsData.determination,
					Object.assign(
						{ ...fieldsData.investigationStates },
						this.appConfigService.isAutomatedIrEnabled
							? this.INVESTIGATION_STATE_EXTRA_DATA.enabled
							: this.INVESTIGATION_STATE_EXTRA_DATA.disabled
					),
					fieldsData.lasteventtime,
					fieldsData.AssignedTo,
					fieldsData.osPlatform,
					this._isRbacFilteringNecessary && fieldsData.rbacGroup,
					this.featuresService.isEnabled(Feature.ThreatAnalytics2) && fieldsData.IoaDefinitionIds,
				])
			);
		}

		return this._allFields;
	}

	get fields(): Array<DataviewField<Alert>> {
		if (!this._fields) {
			this._fields = this.allFields.filter(
				(field) =>
					field.id !== this.FIELDS_DATA.linkedBy.id &&
					field.id !== this.FIELDS_DATA.firstEventTime.id &&
					field.id !== this.FIELDS_DATA.impactedEntities.id
			);
		}
		return this._fields;
	}

	get mtpFields(): Array<DataviewField<Alert>> {
		if (!this._fields) {
			const fields: { [key: string]: DataviewFieldConfig } = this.allFields.reduce(
				(r, field) => ({ ...r, [field.id]: field }),
				{}
			);

			this._fields = compact([
				fields.expand,
				fields.title,
				this.shouldShowTagsColumn() && fields.tags,
				fields.severity,
				fields.status,
				fields.linkedBy,
				fields.category,
				fields.impactedEntities,
				this.shouldShowServiceSources() && fields.serviceSource,
				{ ...fields.detectionSource, enabledByDefault: this.shouldShowServiceSources() },
				fields.firstEventTime,
				{ ...fields.lasteventtime, enabledByDefault: false },
				this.alertFieldsExtended
					? { ...fields.investigationStates, enabledByDefault: true }
					: undefined,
				fields.AssignedTo,
				{ ...fields.classification, enabledByDefault: false },
				fields.determination,
				fields.firstSeen,
			]).map((field) => new DataviewField<Alert>(field));
		}

		return this._fields;
	}

	get defaultEntityPagesFields(): Array<DataviewField<Alert>> {
		if (!this._fields) {
			// Keep original order of fields for entity pages defined by ENTITY_PAGES_ALERTS_FIELDS
			this._fields = ENTITY_PAGES_ALERTS_FIELDS.map((fieldId) =>
				this.allFields.find((field) => field.id === fieldId)
			).filter(Boolean);
			if (!this.alertFieldsExtended) {
				this._fields.filter((field) => field.id !== 'investigationStates');
			}
		}
		return this._fields;
	}

	get fileEntityPagesFields(): Array<DataviewField<Alert>> {
		const filePageAlertsFields = [...ENTITY_PAGES_ALERTS_FIELDS];
		filePageAlertsFields.splice(1, 0, 'incident', 'incidentId');
		if (!this._fields) {
			// Keep original order of fields for entity pages defined by ENTITY_PAGES_ALERTS_FIELDS
			this._fields = filePageAlertsFields
				.map((fieldId) => this.allFields.find((field) => field.id === fieldId))
				.filter(Boolean);
			if (!this.alertFieldsExtended) {
				this._fields.filter((field) => field.id !== 'investigationStates');
			}
		}
		return this._fields;
	}

	ngOnDestroy() {
		this._isAutomatedIrEnabledSubscription && this._isAutomatedIrEnabledSubscription.unsubscribe();
		this._isRbacFilteringNecessarySubscription &&
			this._isRbacFilteringNecessarySubscription.unsubscribe();
	}

	private shouldShowServiceSources() {
		return this.appContextService.isMtp;
	}

	private shouldShowTagsColumn() {
		return this.featuresService.isEnabled(Feature.K8SMigrationIncidentQueue);
	}

	private getAllFieldsData(): FieldsData {
		return {
			expand: {
				id: 'expand',
				name: '',
				enabledByDefault: true,
				alwaysDisplay: true,
				allowResize: false,
				getFieldCssClass: (alert: Alert) => `${alert.isGroup ? 'datatable-expand' : ''}`,
				sort: { enabled: false },
				isTabbale: true,
			},
			title: {
				id: 'title',
				name: this.i18nService.get('alerts.fields.title.title'),
				component: {
					type: AlertNameFieldComponent,
					getProps: (item: Alert) => ({ alert: item, showTags: !this.shouldShowTagsColumn() }),
				},
				getFieldCssClass: () => `datatable-group-item-indent-left title-field`,
				getDisplay: (alert) => alert.name,
			},
			tags: {
				id: 'tags',
				name: this.i18nService.strings.alerts_fields_tags_title,
				enabledByDefault: true,
				maxWidth: 272,
				minWidth: 48,
				component: {
					type: TagsFieldComponent,
					getProps: (entity) => this.entityTagsService.getEntityTagsFieldProps(entity),
				},
				sort: { enabled: false },
				className: 'nowrap',
				getFieldCssClass: () => `${OVERFLOW_TAGS_CONTAINER_CLASS} wcd-text-overflow-clip`,
				custom: {
					allowFilterValueTracking: true,
				},
			},
			severity: {
				id: 'severity',
				name: this.i18nService.get('alerts.fields.severity.title'),
				enabledByDefault: true,
				getDisplay: (item: Alert) => this.i18nService.get(item.severity.nameI18nKey),
				getCssClass: (alert: Alert) => `wcd-severity wcd-severity-${alert.severity.type}`,
				custom: {
					allowFilterValueTracking: true,
				},
				sort: {
					sortDescendingByDefault: true,
					getLocalSortValue: (alert: Alert) => alert.severity.priority * -1,
				},
				filter: {
					component: {
						type: FilterValuesChecklistComponent,
						config: {
							mapFilterValue: (severityName: string) => {
								const severity: Severity = this.paris.getValue(
									Severity,
									(_severity: Severity) =>
										_severity.isSelectable &&
										_severity.type === severityName.toLowerCase()
								);
								return {
									id: severity.name,
									rawValue: severity.name,
									name: this.i18nService.get(severity.nameI18nKey),
									priority: severity.priority,
									nameClass: `wcd-severity wcd-severity-${severity.type}`,
								};
							},
						},
					},
				},
			},
			incident: {
				id: 'incident',
				name: this.i18nService.get('incidents.incident'),
				enabledByDefault: true,
				className: 'nowrap wcd-text-overflow-medium',
				component: {
					type: IncidentNameComponent,
					getProps: (alert: Alert) => {
						return {
							incidentId: alert.incidentId,
							incidentName: alert.incidentName,
						};
					},
				},
				getDisplay: (alert: Alert) => {
					return (
						alert.incidentName ||
						(alert.incidentId && this.i18nService.get('incidents.incidentId', alert)) ||
						(alert.incidentCount && this.i18nService.get('incidents.incidentCount', alert)) ||
						''
					);
				},
				sort: {
					enabled: false,
				},
			},
			incidentId: {
				id: 'incidentId',
				name: 'incidentId',
				enabledByDefault: true,
				className: 'nowrap wcd-text-overflow-medium',
				getDisplay: (alert: Alert) => alert.incidentId,
				sort: {
					enabled: false,
				},
			},
			status: {
				id: 'status',
				name: this.i18nService.get('alerts.fields.status.title'),
				getDisplay: (item: Alert) =>
					item.isMultipleStatuses
						? this.i18nService.get('multiple')
						: item.status && this.i18nService.get(item.status.nameI18nKey),
				className: 'nowrap',
				custom: {
					allowFilterValueTracking: true,
				},
				filter: {
					component: {
						type: FilterValuesChecklistComponent,
						config: {
							mapFilterValue: (statusType: string) => {
								const status: AlertStatus = this.paris.getValue(
									AlertStatus,
									(_status) => _status.type === statusType
								);

								return {
									id: status.type,
									rawValue: status.type,
									name: this.i18nService.get(status.nameI18nKey),
									priority: status.priority,
								};
							},
						},
					},
				},
			},
			classification: {
				id: 'classification',
				name: this.i18nService.get('alerts.fields.classification.title'),
				getDisplay: (alert: Alert) =>
					alert.isMultipleClassifications
						? this.i18nService.get('multiple')
						: alert.classification
						? this.i18nService.get(alert.classification.nameI18nKey)
						: this.i18nService.get('alerts.unclassified'),
				className: 'nowrap',
				custom: {
					allowFilterValueTracking: true,
				},
				filter: {
					component: {
						type: FilterValuesChecklistComponent,
						config: {
							mapFilterValue: (classificationType: string) => {
								const classification: AlertClassification = this.paris.getValue(
									AlertClassification,
									(_classification) => _classification.type === classificationType
								);

								return {
									id: classification.type,
									rawValue: classification.type,
									name: this.i18nService.get(classification.nameI18nKey),
									priority: classification.priority,
								};
							},
						},
					},
				},
			},
			determination: {
				id: 'determination',
				name: this.i18nService.get('alerts.fields.determination.title'),
				getDisplay: (alert: Alert) => {
					const alertDetermination = alert.determination;
					return alert.isMultipleDeterminations
						? this.i18nService.get('multiple')
						: alertDetermination
						? this.i18nService.get(alert.determination.nameI18nKey)
						: this.i18nService.get('alerts.unclassified');
				},
				className: 'nowrap',
				enabledByDefault: false,
				custom: {
					allowFilterValueTracking: true,
				},
			},
			investigationStates: {
				id: 'investigationStates',
				name: this.i18nService.get('alerts.fields.investigationState.title'),
				description: this.appConfigService.isAutomatedIrEnabled
					? null
					: this.i18nService.get('enableAirs.description'),
				headerClass: this.appConfigService.isAutomatedIrEnabled ? null : 'disabled',
				className: 'nowrap',
				custom: {
					allowFilterValueTracking: true,
				},
				filter: {
					component: {
						type: FilterValuesChecklistComponent,
						config: {
							mapFilterValue: (statusId) => {
								const statusIdInt = parseInt(statusId, 10);
								if (!isNaN(statusIdInt)) {
									const status: InvestigationStatus = this.paris.getValue(
										InvestigationStatus,
										statusIdInt
									);
									if (status) {
										return {
											icon: status.iconName && {
												wcdIconName: this.iconsService.getIcon(status.iconName).name,
												className: `color-text-${status.className}`,
											},
											image: status.image,
											name:
												this.i18nService.strings[
													'investigations_status_' + status.type + '_title'
												] || status.name,
											id: status.id,
											priority: status.priority,
										};
									}
								}

								return null;
							},
						},
					},
				},
			},
			linkedBy: {
				id: 'linkedBy',
				name: this.i18nService.get('alerts.fields.linkedBy.title'),
				className: 'nowrap',
				component: {
					type: AlertReasonsFieldComponent,
					getProps: (alert) => ({ alert }),
				},
				sort: { enabled: false },
			},
			category: {
				id: 'category',
				name: this.i18nService.get('alerts.fields.category.title'),
				className: 'nowrap',
				getDisplay: (alert: Alert) => {
					if (alert.isGroup)
						return alert.groupedBy
							? this.i18nService.get('alerts.fields.category.content.grouped', {
									category: this.i18nService.get(alert.groupedBy.nameI18nKey),
							  })
							: null;

					return (
						alert.categories &&
						alert.categories
							.map((x) => this.i18nService.get('reporting.alertsByCategory.' + lowerFirst(x)))
							.join(', ')
					);
				},
				filter: {
					component: {
						type: FilterValuesChecklistComponent,
						config: {
							mapFilterValue: (categoryType) => {
								const category = this.paris.getValue(
									AlertCategory,
									(category) => category.type === categoryType
								);
								return {
									id: category.type,
									name: this.i18nService.get(`reporting.alertsByCategory.${category.type}`),
								};
							},
						},
					},
				},
			},
			impactedEntities: {
				id: 'impactedEntities',
				name: this.i18nService.get('alerts.fields.impactedEntities.title'),
				className: 'wcd-text-overflow-medium',
				component: {
					type: ImpactedEntitiesCounterComponent,
					getProps: (alert: Alert) => {
						let machine = 0;
						let aaduser = 0;
						let mailbox = 0;
						let app = 0;
						if (alert.impactedEntities) {
							machine =
								alert.impactedEntities.machineCount ||
								(alert.impactedEntities.machines && alert.impactedEntities.machines.length) ||
								0;
							aaduser =
								alert.impactedEntities.userCount ||
								(alert.impactedEntities.users && alert.impactedEntities.users.length) ||
								0;
							mailbox =
								alert.impactedEntities.mailboxCount ||
								(alert.impactedEntities.mailboxes &&
									alert.impactedEntities.mailboxes.length) ||
								0;
							app =
								alert.impactedEntities.appCount ||
								(alert.impactedEntities.apps && alert.impactedEntities.apps.length) ||
								0;
						}

						return {
							entities: alert.impactedEntities,
							entitiesCount: { machine, aaduser, mailbox, app },
						};
					},
				},
				sort: { enabled: false },
				enabledByDefault: true,
			},
			machine: {
				id: 'machine',
				name: this.i18nService.get('alerts.fields.device.title'),
				className: 'nowrap wcd-text-overflow-medium',
				getDisplay: (alert: Alert) => {
					const localizedContent =
						alert.machineCount > 1
							? this.i18nService.get('alerts.fields.device.content.plural')
							: this.i18nService.get('alerts.fields.device.content.singular');
					return alert.machine
						? this.globalEntityTypes.getEntityName(Machine, alert.machine).toLowerCase()
						: alert.machineCount
						? `${alert.machineCount} ${localizedContent}`
						: '';
				},
				icon: {
					fabricIcon: (alert: Alert) =>
						alert.machine || alert.machineCount
							? this.globalEntityTypes.getEntityTypeIcon(Machine)
							: null,
				},
				getTooltip: (alert: Alert) =>
					alert.machine
						? this.globalEntityTypes.getEntityName(Machine, alert.machine).toLowerCase()
						: null,
				sort: { enabled: false },
			},
			user: {
				id: 'user',
				name: this.i18nService.get('alerts.fields.user.title'),
				className: 'nowrap wcd-text-overflow-medium',
				getDisplay: (item: Alert) => {
					const localizedContent =
						item.userCount > 1
							? this.i18nService.get('alerts.fields.user.content.singular')
							: this.i18nService.get('alerts.fields.user.content.plural');
					return item.user
						? item.user.fullName.toLowerCase()
						: item.userCount
						? `${item.userCount} ${localizedContent}`
						: '';
				},
				icon: {
					fabricIcon: (alert: Alert) =>
						alert.user || alert.userCount ? FabricIconNames.Contact : null,
				},
				getTooltip: (alert: Alert) => (alert.user ? alert.user.fullName.toLowerCase() : null),
				sort: { enabled: false },
				description: this.i18nService.get('alerts.offendingProcessLaunch.description'),
			},
			AssignedTo: {
				id: 'AssignedTo',
				name: this.i18nService.get('alerts.fields.assignedTo.title'),
				getDisplay: (item: Alert) =>
					item.assignedTo
						? item.assignedTo
						: item.assignedToCount > 1
						? this.i18nService.get('alerts_fields_assignedTo_multiple', {
								count: item.assignedToCount,
						  })
						: this.i18nService.get('alerts.fields.assignedTo.content.unassigned'),
				getCssClass: (alert: Alert) => (alert.assignedTo ? null : 'subtle'),
				filter: {
					component: {
						type: FilterValuesChecklistComponent,
						config: {
							mapFilterValue: (assignTo: string) => {
								const currentUserName: string = this.authService.currentUser.username.toLowerCase();
								const isAutomation: boolean = assignTo === 'Automation';

								return {
									id: assignTo ? (isAutomation ? 'Automation' : currentUserName) : null,
									name: assignTo
										? isAutomation
											? this.i18nService.get(
													'alerts.fields.assignedTo.content.automation'
											  )
											: this.i18nService.get('alerts.fields.assignedTo.content.me')
										: `(${this.i18nService.get(
												'alerts.fields.assignedTo.content.other'
										  )})`,
								};
							},
						},
					},
				},
			},
			serviceSource: {
				id: 'serviceSource',
				name: this.i18nService.get('alerts.fields.serviceSource.title'),
				className: 'nowrap',
				getDisplay: (item: Alert) =>
					item.serviceSource
						? this.i18nService.strings[`shorthand_${item.serviceSource.nameI18nKey}`] ||
						  this.i18nService.get(item.serviceSource.nameI18nKey)
						: null,
				enabledByDefault: true,
				sort: { enabled: false },
				filter: getServiceSourceFilterConfig(),
			},
			detectionSource: {
				id: 'detectionSource',
				name: this.i18nService.get('alerts.fields.detectionSource.title'),
				className: 'nowrap',
				getDisplay: (item: Alert) =>
					item.detectionSource
						? this.i18nService.strings[`shorthand_${item.detectionSource.nameI18nKey}`] ||
						  this.i18nService.get(item.detectionSource.nameI18nKey)
						: this.i18nService.get('notAvailable.short'),
				enabledByDefault: false,
				custom: {
					allowFilterValueTracking: true,
				},
				filter: {
					component: {
						type: FilterValuesChecklistComponent,
						config: {
							mapFilterValue: (detectionSourceId) => {
								return this.paris.getItemById(DetectionSource, detectionSourceId).pipe(
									map((detectionSource: DetectionSource) => {
										return {
											id: detectionSource.id,
											name: this.i18nService.get(detectionSource.nameI18nKey),
											priority: detectionSource.priority,
										};
									})
								);
							},
						},
					},
				},
			},
			firstSeen: {
				id: 'firstSeen',
				name: this.i18nService.get('alerts.fields.timeInQueue.title'),
				className: 'nowrap',
				enabledByDefault: false,
				getDisplay: (item: Alert) => {
					if (!item.firstSeen) {
						return null;
					}
					const timeSeconds: number = (new Date().valueOf() - item.firstSeen.valueOf()) / 1000;
					return Prettify.prettyTime(timeSeconds);
				},
				sort: {
					flipSortDirection: true,
					getLocalSortValue: (alert: Alert) =>
						alert.firstSeen ? alert.firstSeen.valueOf() * -1 : null,
				},
			},
			osPlatform: {
				id: 'osPlatform',
				name: this.i18nService.get('alerts.fields.osPlatform.title'),
				getDisplay: (item: Alert) => item.machine && item.machine.os && item.machine.os.platform,
				filterOnly: true,
				custom: {
					allowFilterValueTracking: true,
				},
				filter: {
					component: {
						type: FilterValuesChecklistComponent,
						config: {
							mapFilterValue: (category: string) => {
								const osPlatform = this.machinesFiltersService.osPlatformCategoriesMap[
									category
								];
								return {
									id: osPlatform.values.map((value) => value.id).join(','),
									name: osPlatform.name,
									priority: osPlatform.priority,
								};
							},
						},
					},
				},
				sort: { enabled: false },
			},
			firstEventTime: {
				id: 'firstEventTime',
				name: this.i18nService.get('alerts.fields.firstActivity.title'),
				className: 'nowrap',
				sort: {
					sortDescendingByDefault: true,
				},
				component: {
					type: TzDateComponent,
					getProps: (alert: Alert) => ({ date: alert.firstEventTime }),
				},
			},
			lasteventtime: {
				id: 'lasteventtime',
				name: this.i18nService.get('alerts.fields.lastActivity.title'),
				className: 'nowrap',
				sort: {
					sortDescendingByDefault: true,
					getLocalSortValue: 'lasteventtime',
				},
				component: {
					type: TzDateComponent,
					getProps: (item: Alert) => ({ date: item.lastEventTime }),
				},
			},
			rbacGroup: {
				id: 'rbacGroup',
				name: this.i18nService.get('alerts.fields.deviceGroup.title'),
				getDisplay: (alert: Alert) => {
					const groupName = !alert.machineGroup
						? null
						: alert.machineGroup.isUnassignedMachineGroup
						? this.i18nService.get('alerts.fields.deviceGroup.content_unassigned')
						: alert.machineGroup.name;
					return (
						groupName ||
						(alert.machineGroupCount
							? `${alert.machineGroupCount} ${this.i18nService.get(
									alert.machineGroupCount > 1
										? 'alerts.groupPlural'
										: 'alerts.groupSingular'
							  )}`
							: '')
					);
				},
				className: 'nowrap',
				sort: { enabled: false },
			},
			IoaDefinitionIds: {
				id: 'IoaDefinitionIds',
				name: this.i18nService.get('alerts.fields.associatedThreat.title'),
				className: 'nowrap',
				filterOnly: true,
				filter: {
					component: {
						type: FilterValuesChecklistComponent,
						config: {
							mapFilterValue: (outbreakId: string) => {
								return this.paris.getItemById(Outbreak, outbreakId).pipe(
									map((outbreak) => {
										return outbreak
											? {
													id: outbreak.id,
													name: outbreak.displayName,
											  }
											: null;
									})
								);
							},
						},
					},
				},
			},
		};
	}

	public alertFieldsByLinkedEntity(): Array<DataviewField<Alert>> {
		const fieldsData = this.getAllFieldsData();
		return DataviewField.fromList<Alert>(<Array<DataviewFieldConfig>>[
			fieldsData.title,
			fieldsData.impactedEntities,
			fieldsData.lasteventtime,
		]);
	}

	private getInvestigationExtraData(): InvestigationExtraData {
		return {
			enabled: {
				getImage: (item: Alert) => item.investigationStatus && item.investigationStatus.image,
				icon: {
					wcdIcon: (alert: Alert) => {
						const iconName =
							alert.investigationStatus &&
							alert.investigationStatus.investigationQueued &&
							alert.investigationStatus.iconName;

						return iconName && WcdIconNames[this.iconsService.getIcon(iconName).name];
					},
					className: (alert: Alert) =>
						alert.investigationId && alert.investigationStatus
							? `color-text-${alert.investigationStatus.className}`
							: null,
				},
				getDisplay: (item: Alert) =>
					item.investigationCount > 1
						? `${item.investigationCount} investigations`
						: item.investigationStatus
						? item.investigationStatus.name
						: this.i18nService.get('notAvailable.short'),

				getLink: (item: Alert) => item.investigationId && `/investigation/${item.investigationId}`,
			},
			disabled: {
				getDisplay: () => this.i18nService.get('notAvailable.short'),
				tooltip: this.i18nService.get('enableAirs.description'),
				className: 'nowrap',
			},
		};
	}
}
