var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { HuntingRuleImpactedEntity } from '../hunting-rule-impacted-entity.value-object';
var ɵ0 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ1 = function (_a) {
    var ImpactedEntities = _a.ImpactedEntities;
    return ImpactedEntities;
};
var HuntingQuerySupportedImpactedEntitiesApiCall = /** @class */ (function (_super) {
    __extends(HuntingQuerySupportedImpactedEntitiesApiCall, _super);
    function HuntingQuerySupportedImpactedEntitiesApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HuntingQuerySupportedImpactedEntitiesApiCall = __decorate([
        ApiCall({
            name: 'Get possible selections of impacted entities',
            endpoint: 'hunting/query/entityMappings',
            baseUrl: ɵ0,
            method: 'POST',
            parseData: ɵ1,
            type: HuntingRuleImpactedEntity,
        })
    ], HuntingQuerySupportedImpactedEntitiesApiCall);
    return HuntingQuerySupportedImpactedEntitiesApiCall;
}(ApiCallModel));
export { HuntingQuerySupportedImpactedEntitiesApiCall };
var ɵ2 = function (config) { return config.data.serviceUrls.huntingService; };
var HuntingQuerySupportedImpactedEntitiesApiCallV2 = /** @class */ (function (_super) {
    __extends(HuntingQuerySupportedImpactedEntitiesApiCallV2, _super);
    function HuntingQuerySupportedImpactedEntitiesApiCallV2() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HuntingQuerySupportedImpactedEntitiesApiCallV2 = __decorate([
        ApiCall({
            name: 'Get possible selections of impacted entities',
            endpoint: 'rules/impactedEntities/supported',
            baseUrl: ɵ2,
            method: 'POST',
        })
    ], HuntingQuerySupportedImpactedEntitiesApiCallV2);
    return HuntingQuerySupportedImpactedEntitiesApiCallV2;
}(ApiCallModel));
export { HuntingQuerySupportedImpactedEntitiesApiCallV2 };
export { ɵ0, ɵ1, ɵ2 };
