var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType, } from '@microsoft/paris';
import { File } from '../file.entity';
import { EntityStatistics } from '../../entity/entity-statistics.value-object';
var ɵ0 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ1 = function (_, query) { return "files/" + query.where['id'] + "/prevalence"; }, ɵ2 = function (file) { return ({ id: file.sha1 || file.sha256 || file.id }); };
var FilePrevalenceRelationship = /** @class */ (function () {
    function FilePrevalenceRelationship() {
    }
    FilePrevalenceRelationship = __decorate([
        EntityRelationship({
            sourceEntity: File,
            dataEntity: EntityStatistics,
            allowedTypes: [RelationshipType.OneToOne],
            baseUrl: ɵ0,
            endpoint: ɵ1,
            getRelationshipData: ɵ2,
            cache: {
                max: 5,
                time: 5 * 60 * 1000,
            },
            timeout: 2 * 60 * 1000,
        })
    ], FilePrevalenceRelationship);
    return FilePrevalenceRelationship;
}());
export { FilePrevalenceRelationship };
export { ɵ0, ɵ1, ɵ2 };
