/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i2 from "../../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i3 from "../../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i4 from "./assessment-job-edit.component.ngfactory";
import * as i5 from "./assessment-job-edit.component";
import * as i6 from "./assessment-job-edit-panel.component";
import * as i7 from "@angular/router";
var styles_AssessmentJobEditPanelComponent = [];
var RenderType_AssessmentJobEditPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AssessmentJobEditPanelComponent, data: {} });
export { RenderType_AssessmentJobEditPanelComponent as RenderType_AssessmentJobEditPanelComponent };
export function View_AssessmentJobEditPanelComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 7, "wcd-panel", [["class", "wcd-full-height"]], null, [[null, "close"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_PanelComponent_0, i1.RenderType_PanelComponent)), i0.ɵdid(2, 114688, null, 1, i2.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.PanelService], { settings: [0, "settings"] }, { close: "close" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, 1, 2, "assessment-job-edit", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.closePanel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_AssessmentJobEditComponent_0, i4.RenderType_AssessmentJobEditComponent)), i0.ɵdid(6, 114688, null, 0, i5.AssessmentJobEditComponent, [], { assessmentJob: [0, "assessmentJob"], readOnly: [1, "readOnly"] }, { close: "close" }), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.assessmentJob; var currVal_2 = _co.readOnly; _ck(_v, 6, 0, currVal_1, currVal_2); }, null); }
export function View_AssessmentJobEditPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "assessment-job-edit-panel", [], null, null, null, View_AssessmentJobEditPanelComponent_0, RenderType_AssessmentJobEditPanelComponent)), i0.ɵdid(1, 245760, null, 0, i6.AssessmentJobEditPanelComponent, [i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AssessmentJobEditPanelComponentNgFactory = i0.ɵccf("assessment-job-edit-panel", i6.AssessmentJobEditPanelComponent, View_AssessmentJobEditPanelComponent_Host_0, { settings: "settings", assessmentJob: "assessmentJob", readOnly: "readOnly" }, {}, []);
export { AssessmentJobEditPanelComponentNgFactory as AssessmentJobEditPanelComponentNgFactory };
