import {
	DataQuery,
	DataQuerySortDirection,
	EntityRelationship,
	EntityModelBase,
	EntityRelationshipRepositoryType,
	RelationshipType,
	EntityField,
	ValueObject,
} from '@microsoft/paris';
import { AirsEntityTypeResults } from '../airs_entity/airs-entity-type-results.value-object';
import { airsEntityRemediationStatuses } from '../airs_entity/airs-entity-remediation-status.entity';
import { Alert } from '../alert/alert.entity';
import { Investigation } from '../investigation/investigation.entity';
import { LegacyUser } from '../legacy/user/legacy-user.entity';
import { Machine } from '../machine/machine.entity';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { IncidentGraph } from './graph/incident-graph.value-object';
import { Incident, convertInvestigationStatusParameter } from './incident.entity';
import { AadUser } from '../AAD/user/aad.user.entity';
import { Mailbox } from '../mailbox/mailbox.entity';
import { isNil, keyBy, omit } from 'lodash-es';
import { EvidenceEntity } from '../evidence/evidence.entity';
import {IncidentImpactedEntities} from "./incident.impacted-entities";
import { Application } from '../application/application-entity';

export const DEFAULT_LOOKBACK_IN_DAYS_VALUE = 180;

@EntityRelationship({
	sourceEntity: Incident,
	dataEntity: IncidentImpactedEntities,
	endpoint: (_, query) => `incidents/${query.where['id']}/impactedEntitiesView`,
	foreignKey: 'id',
	allowedTypes: [RelationshipType.OneToOne],
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	cache: {
		time: 1000 * 60,
		max: 10,
	}
})
export class IncidentImpactedEntitiesRelationship implements EntityRelationshipRepositoryType<Incident, IncidentImpactedEntities> {}

@EntityRelationship({
	sourceEntity: Incident,
	dataEntity: Machine,
	endpoint: (_, query) => `incidents/${query.where['IncidentId']}/devices`,
	foreignKey: 'IncidentId',
	allowedTypes: [RelationshipType.OneToMany],
})
export class IncidentMachinesRelationship implements EntityRelationshipRepositoryType<Incident, Machine> {}

const parseDataViewQuery = (dataQuery: DataQuery) => {
	const where = (dataQuery && dataQuery.where ? dataQuery.where : {}) as object;
	const lookBackInDays: { lookBackInDays?: number } = { lookBackInDays: DEFAULT_LOOKBACK_IN_DAYS_VALUE };

	const pageSettings: { pageIndex?: number; pageSize?: number } = {};
	if (dataQuery.page) pageSettings.pageIndex = dataQuery.page;
	if (dataQuery.pageSize) pageSettings.pageSize = dataQuery.pageSize;

	if (where) {
		convertInvestigationStatusParameter(true, where);
	}

	return Object.assign(
		{},
		omit(where, ['ordering', 'page_size', 'page']),
		lookBackInDays,
		pageSettings,
		dataQuery.sortBy && dataQuery.sortBy.length
			? {
					sortByField: dataQuery.sortBy[0].field,
					sortOrder:
						dataQuery.sortBy[0].direction === DataQuerySortDirection.ascending
							? 'Ascending'
							: 'Descending',
			  }
			: undefined
	);
};

@EntityRelationship({
	sourceEntity: Incident,
	dataEntity: Alert,
	endpoint: 'AssociatedAlerts',
	parseDataQuery: (dataView: DataQuery) => {
		const query = parseDataViewQuery(dataView);
		return {
			...query,
			groupType: 'GroupHash',
		};
	},
	foreignKey: 'IncidentId',
	allowedTypes: [RelationshipType.OneToMany],
	parseData: rawData => ({
		items: rawData.Items,
		count: rawData.TotalResults,
	}),
})
export class IncidentAlertsRelationship implements EntityRelationshipRepositoryType<Incident, Alert> {}

@EntityRelationship({
	sourceEntity: Incident,
	dataEntity: LegacyUser,
	endpoint: 'AssociatedUsers',
	foreignKey: 'IncidentId',
	allowedTypes: [RelationshipType.OneToMany],
})
export class IncidentUsersRelationship implements EntityRelationshipRepositoryType<Incident, LegacyUser> {}

@EntityRelationship({
	sourceEntity: Incident,
	dataEntity: AadUser,
	foreignKey: 'id',
	endpoint: (_, query) => `incidents/${query.where['id']}/users`,
	parseDataQuery: parseDataViewQuery,
	allowedTypes: [RelationshipType.OneToMany],
})
export class IncidentAadUsersRelationship implements EntityRelationshipRepositoryType<Incident, AadUser> {}

@ValueObject({
	singularName: 'Users summary',
	pluralName: 'Users summaries',
})
export class UsersSummary extends EntityModelBase {
	@EntityField({ data: 'users', arrayOf: AadUser })
	readonly users: Array<AadUser>;

	@EntityField({ data: 'userGroups' })
	readonly userGroups: string[];
}

@EntityRelationship({
	sourceEntity: Incident,
	dataEntity: UsersSummary,
	foreignKey: 'id',
	endpoint: (_, query) => `incidents/${query.where['id']}/userSummary`,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	allItemsEndpointTrailingSlash: false,
	allowedTypes: [RelationshipType.OneToOne],
})
export class IncidentUsersSummaryRelationship
	implements EntityRelationshipRepositoryType<Incident, UsersSummary> {}

@EntityRelationship({
	sourceEntity: Incident,
	dataEntity: Mailbox,
	foreignKey: 'id',
	endpoint: (_, query) => `incidents/${query.where['id']}/mailboxes`,
	allowedTypes: [RelationshipType.OneToMany],
})
export class IncidentMailboxesRelationship implements EntityRelationshipRepositoryType<Incident, Mailbox> {}

@EntityRelationship({
	sourceEntity: Incident,
	dataEntity: Investigation,
	endpoint: (_, query) => {
		if (query && query.where && query.where['useMtpApi']) {
			return `incidents/${query.where['IncidentId']}`;
		}

		return 'AssociatedInvestigations';
	},
	foreignKey: 'IncidentId',
	allowedTypes: [RelationshipType.OneToMany],
	baseUrl: (config: WcdPortalParisConfig, query: DataQuery) => {
		if (query && query.where && query.where['useMtpApi']) {
			return config.data.serviceUrls.automatedIr;
		}
		return config.data.serviceUrls.threatIntel;
	},
})
export class IncidentInvestigationsRelationship
	implements EntityRelationshipRepositoryType<Incident, Investigation> {}

@EntityRelationship({
	sourceEntity: Incident,
	dataEntity: AirsEntityTypeResults,
	allowedTypes: [RelationshipType.OneToMany],
	endpoint: (config, query) => {
		return `entities/evidence/${query.where['incident_id']}/results`;
	},
	allItemsProperty: 'data',
	separateArrayParams: true,
	getRelationshipData: (incident: Incident) => ({
		incident_id: incident.id,
		investigation_id: incident.investigationIds,
	}),
	parseDataQuery: dataQuery => {
		return omit(dataQuery.where as object, ['investigation_id', 'allowed_statuses']);
	},
	parseData: (data, config, query) => {
		if (query.where['RemediationStatusSplit']) {
			return { data: castStatusToRemediationStatus(data.data) };
		}
		return data;
	},
	allItemsEndpointTrailingSlash: false,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.automatedIr,
})
export class IncidentEntityResultsRelationship
	implements EntityRelationshipRepositoryType<Incident, AirsEntityTypeResults> {}

@EntityRelationship({
	sourceEntity: Incident,
	dataEntity: EvidenceEntity,
	foreignKey: 'incident_id',
	allowedTypes: [RelationshipType.OneToMany],
})
export class IncidentEntityRelationship
	implements EntityRelationshipRepositoryType<Incident, EvidenceEntity> {}

@EntityRelationship({
	sourceEntity: Incident,
	dataEntity: IncidentGraph,
	allowedTypes: [RelationshipType.OneToOne],
	foreignKey: 'id',
	endpoint: (_, query) => `incidents/${query.where['id']}/graph`,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	timeout: 240000, // 4 minutes allowed here
})
export class IncidentGraphRelationship implements EntityRelationshipRepositoryType<Incident, IncidentGraph> {}

const airsEntityRemediationStatusesMap = keyBy(airsEntityRemediationStatuses, 'id');

// remap legacy statuses to new remediation status
function castStatusToRemediationStatus(
	entityResults: Array<{ data: Array<{ count: number; result: number }>; type_id: number }>
): Array<{ data: Array<{ count: number; remediation_status: number }>; type_id: number }> {
	return entityResults.map(item =>
		Object.assign(
			{},
			item,
			item.data && {
				data: item.data.map(r =>
					Object.assign({}, r, {
						remediation_status: isNil(r.result)
							? r.result
							: airsEntityRemediationStatusesMap[r.result] && r.result,
					})
				),
			}
		)
	);
}

@EntityRelationship({
	sourceEntity: Incident,
	dataEntity: Application,
	foreignKey: 'id',
	endpoint: (_, query) => `incidents/${query.where['id']}/apps`,
	allowedTypes: [RelationshipType.OneToMany],
})
export class IncidentAppsRelationship implements EntityRelationshipRepositoryType<Incident, Application> {}
