var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Paris } from '@microsoft/paris';
import { find, keyBy } from 'lodash-es';
import { map } from 'rxjs/operators';
import { MachineRiskScore, Outbreak, OutbreakMitigationType, MachineExposureScore, MachineVulnerabilitySeverityLevel, MachineVulnerabilityAgeLevel, MachineExploitLevel, onboardingStatusesMap, OnboardingStatus, firstSeenValuesMap, machineExclusionStateMap, MachineExclusionState, machineManagedByValuesMap, } from '@wcd/domain';
import { DataviewField } from '@wcd/dataview';
import { I18nService } from '@wcd/i18n';
import { MachinesFiltersService, } from './machines.filters.service';
import { FilterValuesChecklistComponent, FilterValuesDropdownComboComponent, } from '@wcd/ng-filters';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { FabricIconNames } from '@wcd/scc-common';
import { FeaturesService, Feature, FlavorService, TvmLicensesAngularService } from '@wcd/config';
import { EntityNameComponent } from '../../../global_entities/components/entity-name/entity-name.component';
import { MachinesDataviewTagsComponent } from '../components/machines-dataview-tags/machines-dataview-tags.component';
import { AppFlavorConfig } from '@wcd/scc-common';
import { TvmLicenseType } from '@wcd/scc-common';
var MachinesFieldsService = /** @class */ (function () {
    function MachinesFieldsService(machinesFiltersService, i18nService, featuresService, paris, flavorService, tvmLicensesService) {
        this.machinesFiltersService = machinesFiltersService;
        this.i18nService = i18nService;
        this.featuresService = featuresService;
        this.paris = paris;
        this.flavorService = flavorService;
        this.tvmLicensesService = tvmLicensesService;
        this.shouldDisplayNotManagedDevices =
            (this.featuresService.isEnabled(Feature.DeviceInventoeryDisplayNotManagedDevices) ||
                this.featuresService.isEnabled(Feature.DevicesListIotV1)) &&
                this.flavorService.isEnabled(AppFlavorConfig.devices.onboardingStatus);
    }
    Object.defineProperty(MachinesFieldsService.prototype, "fields", {
        get: function () {
            this._fields = this._fields || DataviewField.fromList(this.getFields());
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    MachinesFieldsService.prototype.getFields = function () {
        var _this = this;
        return [
            {
                id: 'machinename',
                name: this.i18nService.strings.machines_dataView_fields_deviceName,
                enabledByDefault: true,
                className: EntityNameComponent.entityNameDefaultCssClass,
                component: {
                    type: EntityNameComponent,
                    getProps: function (machine) {
                        return {
                            entity: machine,
                            entityTypeId: 'machine',
                            hideIcon: true,
                        };
                    },
                },
            },
            {
                id: 'domain',
                name: this.i18nService.strings.machines_dataView_fields_domain,
                getDisplay: function (machine) { return machine.domain; },
                getTooltip: function (machine) { return machine.domain; },
                sort: { enabled: false },
            },
            {
                id: 'riskScores',
                name: this.i18nService.get('machines.dataView.fields.riskLevel'),
                description: this.i18nService.get('machines.riskLevel'),
                getDisplay: function (machine) {
                    return machine.riskScore && _this.i18nService.get(machine.riskScore.nameI18nKey);
                },
                className: 'nowrap',
                getCssClass: function (machine) {
                    return machine.riskScore
                        ? "wcd-severity wcd-severity-" + machine.riskScore.id.toLocaleLowerCase()
                        : 'disabled';
                },
                sort: {
                    sortDescendingByDefault: true,
                },
                filter: {
                    priority: 0,
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (riskScoreId) {
                                var riskScore = keyBy(MachineRiskScore.entityConfig.values, 'id')[riskScoreId];
                                return {
                                    id: riskScore.id,
                                    rawValue: riskScore.id,
                                    name: _this.i18nService.get(riskScore.nameI18nKey),
                                    nameClass: "wcd-severity wcd-severity-" + riskScore.id.toLowerCase(),
                                    priority: riskScore.priority,
                                };
                            },
                        },
                    },
                },
            },
            !this.shouldDisplayTvmBasicFields()
                ? null
                : {
                    id: 'exposureScores',
                    name: this.i18nService.get('machines.dataView.fields.exposureLevel'),
                    description: this.i18nService.get('machines.exposureLevel'),
                    getDisplay: function (machine) {
                        return machine.exposureScore &&
                            _this.i18nService.get('machineExposureScoreType_' + machine.exposureScore.id);
                    },
                    icon: {
                        fabricIcon: function (machine) {
                            return machine.exposureScore && machine.exposureScore.priority < 4
                                ? FabricIconNames.Warning
                                : null;
                        },
                        className: function (machine) {
                            return machine.exposureScore
                                ? 'ms-fontColor-' + machine.exposureScore.className
                                : null;
                        },
                    },
                    className: 'nowrap',
                    getCssClass: function (machine) { return (machine.exposureScore ? null : 'disabled'); },
                    filter: {
                        priority: 0,
                        component: {
                            type: FilterValuesChecklistComponent,
                            config: {
                                mapFilterValue: function (exposureScoreId) {
                                    var exposureScore = keyBy(MachineExposureScore.entityConfig.values, 'id')[exposureScoreId];
                                    return {
                                        id: exposureScore.id,
                                        rawValue: exposureScore.id,
                                        name: _this.i18nService.get('machineExposureScoreType_' + exposureScore.id),
                                        icon: {
                                            iconName: exposureScore.icon,
                                            className: 'color-text-' + exposureScore.className,
                                        },
                                        priority: exposureScore.priority,
                                    };
                                },
                            },
                        },
                    },
                },
            {
                id: 'osPlatforms',
                name: this.i18nService.get('machines.dataView.fields.osPlatform'),
                getDisplay: function (machine) {
                    return machine.os
                        ? machine.os.osPlatform
                            ? machine.os.osPlatform.name
                            : machine.os.osPlatformString
                        : '';
                },
                filter: {
                    priority: 1,
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (category) {
                                var osPlatform = _this.machinesFiltersService.osPlatformCategoriesMap[category];
                                return {
                                    id: osPlatform.values.map(function (value) { return value.id; }).join(','),
                                    rawValue: osPlatform.id,
                                    name: osPlatform.name,
                                    priority: osPlatform.priority,
                                };
                            },
                        },
                    },
                },
                sort: { enabled: true },
            },
            {
                id: 'releaseVersion',
                name: this.i18nService.get('machines.dataView.fields.osVersion'),
                getDisplay: function (machine) {
                    return machine.os && machine.os.releaseVersion ? machine.os.releaseVersion.name : '';
                },
            },
            {
                id: 'healthStatuses',
                name: this.i18nService.get('machines.dataView.fields.healthState'),
                className: 'nowrap wcd-text-overflow-medium',
                getDisplay: function (machine) { return _this.i18nService.get(machine.status.nameI18nKey); },
                filter: {
                    priority: 2,
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (status) {
                                var healthStatus = _this.machinesFiltersService
                                    .machineHealthStatusCategoriesMap[status];
                                if (!healthStatus) {
                                    healthStatus = find(_this.machinesFiltersService.machineHealthStatusCategoriesMap, function (category) {
                                        return category.children
                                            .map(function (child) { return child.id; })
                                            .includes(status);
                                    });
                                }
                                if (!healthStatus)
                                    return null;
                                return {
                                    id: healthStatus.id,
                                    name: healthStatus.name,
                                    children: healthStatus.children &&
                                        healthStatus.children.map(function (child) {
                                            return {
                                                id: child.id,
                                                name: _this.i18nService.get(child.nameI18nKey),
                                            };
                                        }),
                                };
                            },
                        },
                    },
                    serializeFilterValues: function (filterSelection, defaultSerialized) {
                        if (!filterSelection || !filterSelection.length)
                            return {};
                        return {
                            healthStatuses: filterSelection.reduce(function (serializedValues, healthStatusId) {
                                var healthStatusCategory = _this
                                    .machinesFiltersService.machineHealthStatusCategoriesMap[healthStatusId];
                                if (healthStatusCategory)
                                    return serializedValues.concat(healthStatusCategory.values.map(function (value) { return value.id; }));
                                else
                                    return serializedValues.concat([healthStatusId]);
                            }, []),
                        };
                    },
                    deserializeFilterValues: function (_a) {
                        var healthStatuses = _a.healthStatuses;
                        if (!healthStatuses)
                            return [];
                        var selectedValues = healthStatuses instanceof Array ? healthStatuses : [healthStatuses];
                        return selectedValues.reduce(function (deserializedValues, selectedValue) {
                            var healthStatusCategory = _this.machinesFiltersService
                                .machineHealthStatusCategoriesMap[selectedValue];
                            if (!healthStatusCategory) {
                                var category = Object.entries(_this.machinesFiltersService.machineHealthStatusCategoriesMap).find(function (_a) {
                                    var categoryId = _a[0], healthStatusCategoryFilter = _a[1];
                                    return healthStatusCategoryFilter.values
                                        .map(function (value) { return value.id; })
                                        .includes(selectedValue);
                                });
                                if (category)
                                    healthStatusCategory = category[1];
                            }
                            return deserializedValues.concat([
                                healthStatusCategory ? healthStatusCategory.id : selectedValue
                            ], ((healthStatusCategory &&
                                healthStatusCategory.children &&
                                healthStatusCategory.children.map(function (child) { return child.id; })) ||
                                []));
                        }, []);
                    },
                },
            }
        ].concat((this.shouldDisplayNotManagedDevices
            ? [
                {
                    id: 'onBoardingStatuses',
                    name: this.i18nService.strings
                        .machines_dataView_fields_onboardingState_field_name,
                    enabledByDefault: true,
                    getDisplay: function (machine) {
                        var i18nNameKey = (onboardingStatusesMap[machine.onboardingStatus] ||
                            onboardingStatusesMap[OnboardingStatus.InsufficientInfo]).i18nNameKey;
                        return _this.i18nService.get(i18nNameKey);
                    },
                    filter: {
                        priority: 2.5,
                        component: {
                            type: FilterValuesChecklistComponent,
                            config: {
                                mapFilterValue: function (onBoardingId) {
                                    var _a = onboardingStatusesMap[onBoardingId], id = _a.id, i18nNameKey = _a.i18nNameKey, priority = _a.priority;
                                    return {
                                        id: id,
                                        priority: priority,
                                        rawValue: id,
                                        name: _this.i18nService.get(i18nNameKey),
                                    };
                                },
                            },
                        },
                    },
                    sort: { enabled: false },
                },
            ]
            : []), [
            {
                id: 'lastseen',
                name: this.i18nService.get('machines.dataView.fields.lastSeen'),
                description: this.i18nService.get('machines_lastSeen_description'),
                component: {
                    type: TzDateComponent,
                    getProps: function (machine) { return ({ date: machine.lastSeen }); },
                },
            },
            {
                id: 'firstseen',
                name: this.i18nService.strings.machines_entityDetails_fields_firstSeen_title,
                enabledByDefault: false,
                component: {
                    type: TzDateComponent,
                    getProps: function (machine) { return ({ date: machine.firstSeen }); },
                },
                sort: { enabled: true },
                filter: {
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (range) {
                                var _a = firstSeenValuesMap[range], id = _a.id, priority = _a.priority, i18nNameKey = _a.i18nNameKey;
                                return {
                                    id: id,
                                    priority: priority,
                                    rawValue: id,
                                    name: _this.i18nService.get(i18nNameKey),
                                };
                            },
                        },
                    },
                },
            },
            !(this.featuresService.isEnabled(Feature.EndpointConfigManagementFe) &&
                this.featuresService.isEnabled(Feature.EndpointConfigManagement)) && !this.flavorService.isEnabled(AppFlavorConfig.settings.mdeAttach)
                ? null
                : {
                    id: 'managedByList',
                    name: this.i18nService.get('machines.dataView.fields.managedBy'),
                    description: this.i18nService.get('machines.dataView.fields.managedBy.field.tooltip'),
                    getDisplay: function (machine) {
                        return machine.managedBy && _this.i18nService.get(machine.managedBy.nameI18nKey);
                    },
                    sort: { enabled: false },
                    filter: {
                        component: {
                            type: FilterValuesChecklistComponent,
                            config: {
                                mapFilterValue: function (range) {
                                    var _a = machineManagedByValuesMap[range], id = _a.id, priority = _a.priority, nameI18nKey = _a.nameI18nKey;
                                    return {
                                        id: id,
                                        priority: priority,
                                        rawValue: id,
                                        name: _this.i18nService.get(nameI18nKey),
                                    };
                                },
                            },
                        },
                    },
                }
        ], (this.featuresService.isEnabled(Feature.ExcludedDevices)
            ? [
                {
                    id: 'exclusionStates',
                    name: this.i18nService.strings.machines_dataView_fields_exclusionState_field_name,
                    description: this.i18nService.strings
                        .machines_dataView_fields_exclusionState_field_tooltip,
                    enabledByDefault: true,
                    getDisplay: function (machine) {
                        var i18nNameKey = (machineExclusionStateMap[machine.exclusionState] ||
                            machineExclusionStateMap[MachineExclusionState.Included]).i18nNameKey;
                        return _this.i18nService.get(i18nNameKey);
                    },
                    filter: {
                        helpText: this.i18nService.strings
                            .machines_dataView_fields_exclusionState_field_tooltip,
                        priority: 4,
                        component: {
                            type: FilterValuesChecklistComponent,
                            config: {
                                mapFilterValue: function (exclusionState) {
                                    var _a = machineExclusionStateMap[exclusionState], id = _a.id, i18nNameKey = _a.i18nNameKey, priority = _a.priority;
                                    return {
                                        id: id,
                                        priority: priority,
                                        rawValue: id,
                                        name: _this.i18nService.get(i18nNameKey),
                                    };
                                },
                            },
                        },
                    },
                    sort: { enabled: false },
                },
            ]
            : []), [
            {
                id: 'tags',
                name: this.i18nService.get('machines.dataView.fields.tags'),
                minWidth: 300,
                component: {
                    type: MachinesDataviewTagsComponent,
                    getProps: function (machine) { return ({ machine: machine }); },
                },
                sort: { enabled: false },
                filter: {
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (tag, value) { return ({
                                id: tag,
                                nameClass: tag === null ? 'subtle' : 'tag',
                                name: tag || _this.i18nService.strings.machines_dataView_filter_untagged,
                                priority: tag ? 2 : 1,
                                data: value.custom,
                            }); },
                        },
                    },
                    serializeFilterValues: function (filterSelection, defaultSerialized, listValues) {
                        return _this.machinesFiltersService.serializeFilterValues(filterSelection, listValues);
                    },
                    deserializeFilterValues: function (serializedValues) {
                        return _this.machinesFiltersService.deserializeFilterValues(serializedValues);
                    },
                },
            },
            {
                id: 'securityPropertiesRequiringAttention',
                name: this.i18nService.get('machines.dataView.fields.antivirusStatus'),
                filter: {
                    helpText: this.featuresService.isEnabled(Feature.UseTvmMachinesAvStatus)
                        ? this.i18nService.get('machines.dataView.antivirusStatusHelp.SCA')
                        : this.i18nService.get('machines.dataView.antivirusStatusHelp'),
                    priority: 3,
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (status, data) {
                                return {
                                    id: data.value,
                                    rawValue: data.value,
                                    name: data.name,
                                };
                            },
                        },
                    },
                },
            },
            {
                id: 'threatCategory',
                name: this.i18nService.get('machines.dataView.fields.malwareCategoryAlerts'),
            },
            { id: 'rbacGroupIds', name: this.i18nService.get('machines.dataView.fields.rbacGroupId') },
            {
                id: 'vulnerabilitySeverityLevels',
                filterOnly: true,
                name: this.i18nService.get('machines.dataView.fields.vulnerabilitySeverityLevel'),
                filter: {
                    priority: 6,
                    isHidden: true,
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (vulnerabilitySeverityLevelId) {
                                var vulnerabilitySeverityLevel = keyBy(MachineVulnerabilitySeverityLevel.entityConfig.values, 'id')[vulnerabilitySeverityLevelId];
                                return {
                                    id: vulnerabilitySeverityLevel.id,
                                    rawValue: vulnerabilitySeverityLevel.id,
                                    name: _this.i18nService.get(vulnerabilitySeverityLevel.nameI18nKey),
                                    priority: vulnerabilitySeverityLevel.priority,
                                };
                            },
                        },
                    },
                },
            },
            {
                id: 'vulnerabilityAgeLevels',
                filterOnly: true,
                name: this.i18nService.get('machines.dataView.fields.vulnerabilityAgeLevel'),
                filter: {
                    priority: 7,
                    isHidden: true,
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (vulnerabilityAgeLevelId) {
                                var vulnerabilityAgeLevel = keyBy(MachineVulnerabilityAgeLevel.entityConfig.values, 'id')[vulnerabilityAgeLevelId];
                                return {
                                    id: vulnerabilityAgeLevel.id,
                                    rawValue: vulnerabilityAgeLevel.id,
                                    name: _this.i18nService.get(vulnerabilityAgeLevel.nameI18nKey),
                                    priority: vulnerabilityAgeLevel.priority,
                                };
                            },
                        },
                    },
                },
            },
            {
                id: 'exploitLevels',
                filterOnly: true,
                name: this.i18nService.get('machines.dataView.fields.exploitLevel'),
                filter: {
                    priority: 8,
                    isHidden: true,
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (exploitLevelId) {
                                var exploitLevel = keyBy(MachineExploitLevel.entityConfig.values, 'id')[exploitLevelId];
                                return {
                                    id: exploitLevel.id,
                                    rawValue: exploitLevel.id,
                                    name: _this.i18nService.get(exploitLevel.nameI18nKey),
                                    priority: exploitLevel.priority,
                                };
                            },
                        },
                    },
                },
            }
        ], (!this.featuresService.isEnabled(Feature.ThreatAnalyticsTimnaMitigations)
            ? [
                {
                    id: 'mitigationTypes',
                    name: this.i18nService.get('machines.dataView.fields.threatMitigationStatus'),
                    filterOnly: true,
                    filter: {
                        priority: 4,
                        requiresData: false,
                        component: {
                            type: FilterValuesDropdownComboComponent,
                            config: {
                                dropdown: {
                                    placeholder: this.i18nService.get('machines.outbreakFilterPlaceholder'),
                                    label: 'displayName',
                                    getValues: function () {
                                        return _this.paris
                                            .getRepository(Outbreak)
                                            .allItems$.pipe(map(function (allOutbreaks) {
                                            return allOutbreaks.filter(function (o) {
                                                return o &&
                                                    o.mitigationTypes &&
                                                    o.mitigationTypes.length > 0;
                                            });
                                        }));
                                    },
                                },
                                getValues: function (outbreak) { return [
                                    {
                                        values: outbreak.mitigationTypes.concat([
                                            OutbreakMitigationType[OutbreakMitigationType.Protected],
                                        ]).map(function (mitigationType) { return ({
                                            value: OutbreakMitigationType[mitigationType].toString(),
                                            rawValue: mitigationType,
                                            name: _this.i18nService.get("machines.mitigationFilters." + mitigationType),
                                            priority: OutbreakMitigationType[mitigationType],
                                        }); }),
                                    },
                                ]; },
                            },
                        },
                        serializeFilterValues: function (selectedValues, defaultSerialized) {
                            var serializedValues = selectedValues.dropdown
                                ? __assign({}, defaultSerialized, { outbreakId: selectedValues.dropdown.id }) : null;
                            return serializedValues;
                        },
                        deserializeFilterValues: function (serializedValues) {
                            if (serializedValues) {
                                var mitigationTypes_1 = serializedValues.mitigationTypes, outbreakId = serializedValues.outbreakId;
                                if (mitigationTypes_1 && outbreakId) {
                                    return _this.paris.getItemById(Outbreak, outbreakId).pipe(map(function (outbreak) {
                                        return {
                                            dropdown: outbreak,
                                            checklist: mitigationTypes_1 instanceof Array
                                                ? mitigationTypes_1
                                                : [mitigationTypes_1],
                                        };
                                    }));
                                }
                            }
                            return null;
                        },
                    },
                },
            ]
            : []));
    };
    MachinesFieldsService.prototype.shouldDisplayTvmBasicFields = function () {
        var tvmLicensesEnabled = this.featuresService.isEnabled(Feature.TvmPremium);
        var isEnabledByFlavor = this.flavorService.isEnabled(AppFlavorConfig.devices.exposureLevel);
        var isEnabledByTvmLicenses = this.tvmLicensesService.isEnabled(TvmLicenseType.TvmBasic);
        return tvmLicensesEnabled ? isEnabledByTvmLicenses : isEnabledByFlavor;
    };
    return MachinesFieldsService;
}());
export { MachinesFieldsService };
