var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { EntityComponentBase } from '../../../global_entities/components/entity-contents.component.base';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';
import { AppContextService, Feature, FeaturesService, FlavorService } from '@wcd/config';
import { SpinnerSize } from 'office-ui-fabric-react';
import { ActivatedRoute } from '@angular/router';
import { I18nService } from '@wcd/i18n';
import { isUndefined } from 'lodash-es';
import { sccHostService } from '@wcd/scc-interface';
import { AppFlavorConfig } from '@wcd/scc-common';
import { EvidenceService } from '../../evidence/services/evidence.service';
var EagerTabs;
(function (EagerTabs) {
    EagerTabs["investigations"] = "investigations";
    EagerTabs["evidence"] = "evidence";
})(EagerTabs || (EagerTabs = {}));
var IncidentEntityComponent = /** @class */ (function (_super) {
    __extends(IncidentEntityComponent, _super);
    function IncidentEntityComponent(appContextService, featuresService, route, i18nService, flavorService, evidenceService) {
        var _this = _super.call(this) || this;
        _this.appContextService = appContextService;
        _this.featuresService = featuresService;
        _this.route = route;
        _this.i18nService = i18nService;
        _this.flavorService = flavorService;
        _this.evidenceService = evidenceService;
        _this.EntityPageViewMode = EntityPageViewMode;
        _this.tabs = [];
        _this.SpinnerSize = SpinnerSize;
        _this.EagerTabs = EagerTabs;
        _this.loadEventFired = false;
        _this.tabLoaders = {};
        _this.tabCounts = {};
        _this.shouldRemovePadding = sccHostService.isSCC;
        _this.enableIncidentDisruptBanner = false;
        _this.enableIncidentDisruptBanner = _this.featuresService.isEnabled(Feature.IncidentDisruptBanner);
        return _this;
    }
    Object.defineProperty(IncidentEntityComponent.prototype, "currentTabId", {
        get: function () {
            var childComponentUrl = this.route.snapshot.firstChild && this.route.snapshot.firstChild.url.toString();
            var currentTab = this.tabs.find(function (tab) { return new RegExp("./" + childComponentUrl + "$").test(tab.routerLink); }) ||
                this.tabs[0];
            return currentTab.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IncidentEntityComponent.prototype, "incident", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    IncidentEntityComponent.prototype.ngOnInit = function () {
        var _this = this;
        sccHostService.isSCC && sccHostService.log.trackEvent("incident-page-view", {
            id: this.entity.id,
            serviceSources: this.entity.serviceSources.map(function (s) { return s.name; })
        });
        var tabs = this.getTabs(this.incident);
        this.tabs = this.appContextService.isMtp
            ? tabs.filter(function (tab) { return tab.id !== 'graph' || _this.featuresService.isEnabled(Feature.IncidentGraph); })
            : tabs.filter(function (tab) { return tab.id !== 'users' && tab.id !== 'mailboxes' && tab.id !== 'apps'; });
    };
    IncidentEntityComponent.prototype.ngOnDestroy = function () {
        this.evidenceService.destroyEvidence();
    };
    IncidentEntityComponent.prototype.getTabs = function (incident) {
        return [
            this.featuresService.isEnabled(Feature.IncidentV2Tab)
                ? {
                    id: 'attack-story',
                    name: this.i18nService.get('incident_tabs_attack_story'),
                    routerLink: './overview',
                }
                : undefined,
            !this.featuresService.isEnabled(Feature.IncidentV2Tab) &&
                (this.featuresService.isEnabled(Feature.IncidentOverviewMtp) ||
                    this.featuresService.isEnabled(Feature.IncidentOverview))
                ? {
                    id: 'overview',
                    name: this.i18nService.get('incident_tabs_summary'),
                    routerLink: './overview',
                }
                : undefined,
            {
                id: 'alerts',
                name: this.i18nService.strings.incident_tabs_alerts + " (" + incident.alertCount + ")",
                routerLink: './alerts',
            },
            this.featuresService.isEnabled(Feature.IncidentTimeline)
                ? {
                    id: 'timeline',
                    name: this.i18nService.get('incident_tabs_timeline'),
                    routerLink: './timeline',
                }
                : undefined,
            {
                id: 'machines',
                name: this.i18nService.strings.incident_tabs_devices + " (" + ((incident.impactedEntities && incident.impactedEntities.machineCount) || 0) + ")",
                routerLink: './machines',
            },
            {
                id: 'users',
                name: this.i18nService.strings.incident_tabs_users + " (" + ((incident.impactedEntities && incident.impactedEntities.userCount) || 0) + ")",
                routerLink: './users',
            }
        ].concat((this.flavorService.isEnabled(AppFlavorConfig.incidents.mailboxes)
            ? [
                {
                    id: 'mailboxes',
                    name: this.i18nService.strings.incident_tabs_mailboxes + " (" + ((incident.impactedEntities && incident.impactedEntities.mailboxCount) || 0) + ")",
                    routerLink: './mailboxes',
                },
            ]
            : []), (this.featuresService.isEnabled(Feature.ConsiderApplicationAsAsset)
            ? [
                {
                    id: 'apps',
                    name: this.i18nService.strings.incident_tabs_apps + " (" + ((incident.impactedEntities && incident.impactedEntities.appCount) || 0) + ")",
                    routerLink: './apps',
                },
            ]
            : []), (this.flavorService.isEnabled(AppFlavorConfig.incidents.autoIr)
            ? [
                {
                    id: EagerTabs.investigations,
                    routerLink: './investigations',
                    templateRef: this.investigationsTab,
                },
            ]
            : []), [
            {
                id: EagerTabs.evidence,
                routerLink: './evidence',
                templateRef: this.evidenceTab,
            },
            this.featuresService.isEnabled(Feature.IncidentV2Tab) &&
                (this.featuresService.isEnabled(Feature.IncidentOverviewMtp) ||
                    this.featuresService.isEnabled(Feature.IncidentOverview))
                ? {
                    id: 'summary',
                    name: this.i18nService.get('incident_tabs_summary'),
                    routerLink: './summary',
                }
                : undefined,
            !this.featuresService.isEnabled(Feature.IncidentV2Tab) && {
                id: 'graph',
                name: this.i18nService.get('incident_tabs_graph'),
                routerLink: './graph',
                badgeClassName: 'wcd-badge wcd-badge-raised wcd-badge--textual color-box-gray-700',
                badge: sccHostService.isSCC ? null : this.i18nService.get('incident_tabs_alertsGraphBeta'),
            },
            !this.featuresService.isEnabled(Feature.IncidentV2Tab) &&
                this.featuresService.isEnabled(Feature.IncidentV2)
                ? {
                    id: 'incident-graph',
                    name: this.i18nService.get('incident_tabs_incident_graph'),
                    routerLink: './incident-graph',
                }
                : undefined,
            this.featuresService.isEnabled(Feature.IncidentAlertsGraph)
                ? {
                    id: 'alertsGraph',
                    name: this.i18nService.get('incident_tabs_alertsGraph'),
                    routerLink: './alertsGraph',
                }
                : undefined,
        ]).filter(Boolean)
            .map(function (tab) { return new TabModel(tab); });
    };
    IncidentEntityComponent.prototype.setTabResultData = function (key, event) {
        var isLoading = event.isLoading, count = event.count;
        this.tabLoaders[key] = isLoading;
        if (!this.tabLoaders[key]) {
            this.tabCounts[key] = count || 0;
        }
        if (!this.loadEventFired && !isLoading && !isUndefined(count)) {
            this.loadEventFired = true;
        }
    };
    return IncidentEntityComponent;
}(EntityComponentBase));
export { IncidentEntityComponent };
