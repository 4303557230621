import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { NotificationRule } from './notification-rule.entity';
import { NotificationRuleCondition } from './notification-rule-condition.value-object';
import {
	NotificationRuleSeverityConditionValue,
	NotificationRuleSeverityConditionValues,
} from './notification-rule-condition-value';
import { NotificationFormatEnum } from './notification-rule-format.values';

const defaultValues = Object.assign(
	NotificationRuleSeverityConditionValues.reduce((accumulator: any, value: NotificationRuleSeverityConditionValue) => {
		accumulator[`${value.id}Severity`] = false;
		return accumulator;
	}, {}),
	{
		MailEnabled: true,
	}
);

@ApiCall({
	name: 'Send test email for alert notification',
	endpoint: 'SendTestEmail',
	method: 'POST',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.management,
	parseQuery: (rule: NotificationRule) => {
		const payload = Object.assign(
			{
				IncludeOrganization: rule.formatFlavor.some(
					f => f.id === NotificationFormatEnum.customerName
				),
				MailAddresses: rule.recipients,
			},
			defaultValues
		);

		rule.conditions.forEach((condition: NotificationRuleCondition) => {
			if (condition.type.id === 1) {
				condition.values.forEach((value: NotificationRuleSeverityConditionValue) => {
					payload[`${value.id}Severity`] = true;
				});
				return true;
			}
		});

		return {
			data: payload,
		};
	},
})
export class SendTestEmailApiCall extends ApiCallModel<void, NotificationRule> {}
