import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Threat expert request',
	pluralName: 'Threat expert requests',
})
export class ThreatExpertRequest extends ModelBase {
	@EntityField({ data: 'QuestionArea' })
	questionArea: string;

	@EntityField({ data: 'QuestionBody' })
	questionBody: string;

	@EntityField({ data: 'SourceUrl' })
	sourceUrl: string;

	@EntityField({ data: 'UserEmail' })
	userEmail: string;

	@EntityField({ data: 'Language' })
	language: string;

	@EntityField({ data: 'RequestId' })
	requestId: string;
}
