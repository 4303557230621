import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { PanelContainer } from '@wcd/panels';
import { ChecklistValue } from '@wcd/forms';
import { SearchResult, ISearchSettings } from '@wcd/forms';
import { Observable, from, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { Alert, AlertLinksApiCall, IncidentsSearchApiCall, IncidentsSearchResult, Tag } from '@wcd/domain';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { ConfirmEvent } from '../../../dialogs/confirm/confirm.event';
import { MtpPermission } from '@wcd/auth';
import { RbacControlSettings, RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { flatMap, uniq } from 'lodash-es';

const SUGGESTIONS_COUNT = 6;

@Component({
	selector: 'alert-link-to-incident-panel',
	templateUrl: './alert-link-to-incident-panel.component.html',
})
export class AlertLinkToIncidentPanelComponent extends PanelContainer {
	@Input() alerts: Array<Alert>;

	@ViewChild(NgForm, { static: false }) linkForm: NgForm;

	areLinkedToSameIncident: boolean;
	currentLinkedMessage: string;
	isSaving: boolean = false;
	comment: string;
	selectableActions: Array<ChecklistValue> = [
		{ id: 'new', name: this.i18nService.get('alerts.linkToIncident.actions.new') },
		{ id: 'existing', name: this.i18nService.get('alerts.linkToIncident.actions.existing') },
	];
	action: ChecklistValue = this.selectableActions[0];
	selectedIncidentId: string;
	searchSettings: ISearchSettings<Tag> = {
		showSuggestions: true,
		showValueOnSelect: true,
		highlightTermInResults: true,
	};
	getSearchIncidentsMethod: () => Observable<Array<SearchResult>>;
	rbacSettings: RbacControlSettings;

	private currentIncidentId: string;

	get isValid(): boolean {
		return (
			this.isNewAlert() ||
			(this.selectedIncidentId && !this.isCurrentIncidentSelected() && Boolean(this.comment))
		);
	}

	constructor(
		protected router: Router,
		private i18nService: I18nService,
		private dialogsService: DialogsService,
		private paris: Paris
	) {
		super(router);
		this.canDeactivate = this.canDeactivate.bind(this);
		this.getSearchIncidentsMethod = this.searchIncidents.bind(this);
	}

	ngOnInit() {
		super.ngOnInit();
		if (this.alerts && this.alerts[0] && this.alerts[0].incidentId) {
			this.currentIncidentId = this.alerts[0].incidentId;
			this.areLinkedToSameIncident = this.alerts.every(
				alert => alert.incidentId === this.currentIncidentId
			);
		} else this.areLinkedToSameIncident = false;
		this.currentLinkedMessage = this.i18nService.get(
			this.alerts && this.alerts.length > 1
				? 'alerts.linkToIncident.currentLinkPlural'
				: 'alerts.linkToIncident.currentLinkSingle'
		);

		const workloads = uniq(flatMap(this.alerts.map(alert => alert.mtpWorkloads)));

		this.rbacSettings = {
			mtpPermissions: [MtpPermission.SecurityData_Manage],
			mtpWorkloads: workloads,
			requireAllPermissions: true,
			state: RbacControlState.disabled,
		};
	}

	setIncident(event: SearchResult) {
		this.selectedIncidentId = event.value;
	}

	clearIncident() {
		this.selectedIncidentId = null;
	}

	searchIncidents(term: string): Observable<Array<SearchResult>> {
		return this.paris.apiCall(IncidentsSearchApiCall, { term: term, pageSize: SUGGESTIONS_COUNT }).pipe(
			map(
				(data: Array<IncidentsSearchResult>) =>
					data &&
					data
						.filter(incident => incident.incidentId !== this.currentIncidentId)
						.map(incident => {
							return {
								value: incident.incidentId,
								label: incident.name,
							};
						})
			)
		);
	}

	submit() {
		this.isSaving = true;
		const isNew = this.isNewAlert();
		this.paris
			.apiCall(AlertLinksApiCall, {
				alertIds: this.alerts.map(alert => alert.id),
				incidentId: isNew ? null : this.selectedIncidentId,
				comment: this.comment,
			})
			.subscribe(
				() => {
					this.isSaving = false;
					this.destroy();
					this.dialogsService.showSuccessSnackbar({
						text: this.i18nService.get(
							isNew
								? 'alerts.linkToIncident.results.new'
								: 'alerts.linkToIncident.results.existing'
						),
					});
				},
				error => {
					this.isSaving = false;
					this.dialogsService.showError({
						title: this.i18nService.get('alerts.linkToIncident.failure'),
						data: error,
					});
				}
			);
	}

	canDeactivate(): Observable<boolean> {
		if (this.linkForm.form.dirty) {
			return from(
				this.dialogsService.confirm({
					title: this.i18nService.get('discardChanges'),
					text: this.i18nService.get('discardMessage.singular', {
						itemName: 'alert',
					}),
					confirmText: this.i18nService.get('buttons.discard'),
				})
			).pipe(map((e: ConfirmEvent) => e.confirmed));
		} else {
			return of(true);
		}
	}

	private isNewAlert(): boolean {
		return this.action.id === 'new';
	}

	private isCurrentIncidentSelected(): boolean {
		return this.selectedIncidentId && this.currentIncidentId === this.selectedIncidentId;
	}
}
