import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { AssessmentJob } from '@wcd/domain';
import { TvmNetworkScanSharedEntityConfigurations } from '../networkScanEndPointUtil';

@ApiCall({
	...TvmNetworkScanSharedEntityConfigurations,
	name: 'GET agents list',
	endpoint: 'agents',
	method: 'GET',
	cache: false,
})
export class GetNetworkScanAgentsList extends ApiCallModel<any, void> {}

@ApiCall({
	...TvmNetworkScanSharedEntityConfigurations,
	name: 'Delete network scan',
	endpoint: 'scanDefinition',
	method: 'DELETE',
})
export class DeleteNetworkScan extends ApiCallModel<any, Array<AssessmentJob>> {}
