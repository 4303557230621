
		<div class="relative wcd-flex-vertical" [attr.aria-label]="description">
			<div class="wcd-flex-horizontal">
				<ng-container *ngIf="entity.senseMachineId && entity.riskScore">
					<div class="wcd-flex-horizontal wcd-padding-medium-right machine-risk">
						<span
							class="wcd-severity"
							[ngClass]="'wcd-severity-' + entity.riskScore.id.toLocaleLowerCase()">
							{{ 'machineRiskScore.' + entity.riskScore.id | i18n }}
						</span>
					</div>
				</ng-container>
				<ng-container *ngIf="isActive && hasStatus">
					<div
						class="machine-status-active"
						[attr.aria-label]="statusDescription"
						[wcdTooltip]="statusDescription"
					></div>
					<span class="machine-status">{{ statusDescription }}</span>
				</ng-container>
			</div>
		</div>
	