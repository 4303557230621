import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DownloadService } from './download.service';
import { toPairs } from 'lodash-es';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';

interface CsvTokenBackendData {
	readonly ctx: string;
	readonly token: string;
	url: string;
}

interface CsvExportOptions {
	readonly tokenUrl: string;
	readonly apiUrl: string;
	readonly params?: Record<string, string>;
}

@Injectable()
export class CsvService {
	constructor(
		private readonly httpClient: HttpClient,
		private readonly downloadService: DownloadService,
		private dialogsService: DialogsService,
		private i18nService: I18nService
	) {}

	async downloadCsv(
		{ tokenUrl, apiUrl, params }: CsvExportOptions,
		downloadFromCyber: boolean = false,
		duplicateKeyForArrayValue: boolean = false
	) {
		this.dialogsService.showSnackbar({
			text: this.i18nService.get('grid.commands.exportToExcel.waitForDownload'),
			icon: 'Info',
		});
		let requestParams: Record<string, string>;
		if (downloadFromCyber) {
			requestParams = Object.assign({}, params);
			return this.downloadService.downloadFromFromResponseUri(
				this.buildUrlWithParams(apiUrl, requestParams, duplicateKeyForArrayValue)
			);
		} else {
			const token = await this.getCsvToken(tokenUrl);
			if (token.url) {
				apiUrl = token.url;
				delete token.url;
			}
			requestParams = Object.assign({}, token, params);

			return this.downloadService.downloadFromUrl(
				this.buildUrlWithParams(apiUrl, requestParams, duplicateKeyForArrayValue),
				{ isAuthenticated: true }
			);
		}
	}

	private async getCsvToken(tokenUrl: string): Promise<CsvTokenBackendData> {
		return await this.httpClient.get<CsvTokenBackendData>(tokenUrl).toPromise();
	}

	buildUrlWithParams(
		baseUri: string,
		params: Record<string, string>,
		duplicateKeyForArrayValue: boolean = false
	): string {
		const urlParams = toPairs(params)
			.map(([key, value]: [string, string]) => {
				if (Array.isArray(value)) {
					if (duplicateKeyForArrayValue) {
						// in this case - the value of the key is an array and we want to split it to - key=array[0]&key=array[1]...
						const paramsArray = value.map(val => `${key}=${encodeURIComponent(String(val))}`);
						return paramsArray.join('&');
					}
					// in this case - the value of the key is an array and we will NOT split it - key=array[0],array[1]...
					return `${key}=${value.map(val => encodeURIComponent(val)).join(',')}`;
				} else {
					return `${key}=${encodeURIComponent(value)}`;
				}
			})
			.join('&');

		return `${baseUri}?${urlParams}`;
	}
}
