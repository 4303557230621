import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { AssessmentJob } from '@wcd/domain';
import { TvmNetworkScanSharedEntityConfigurations } from '../networkScanEndPointUtil';

@ApiCall({
	...TvmNetworkScanSharedEntityConfigurations,
	name: 'POST reachability test',
	endpoint: 'scanDefinition/run',
	method: 'POST',
	responseType: 'text',
	cache: false,
})
export class PostNetworkScanReachabilityTest extends ApiCallModel<string, AssessmentJob> {}

@ApiCall({
	...TvmNetworkScanSharedEntityConfigurations,
	name: 'GET reachability test output',
	endpoint: 'commands/liveResults',
	method: 'GET',
	parseQuery: (sessionId: string) => {
		return {
			params: { sessionId: sessionId, fromTimestamp: 0 },
		};
	},
	cache: false,
})
export class GetNetworkScanReachabilityTestOutput extends ApiCallModel<any, string> {}
