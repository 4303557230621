import { Component, OnInit } from '@angular/core';
import { ModelBase, Paris, RelationshipRepository } from '@microsoft/paris';
import { Alert, SuppressionRule, SuppressionRuleAlertRelationship, SuppressionRuleType } from '@wcd/domain';
import { filter } from 'rxjs/operators';
import { ActivatedEntity } from '../../../global_entities/services/activated-entity.service';
import { DataTableField } from '@wcd/datatable';
import { I18nService } from '@wcd/i18n';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';
import { MtpPromotionService } from '../../../mtp-promotion/services/mtp-promotion.service';

interface DatatableItems {
	condition: string,
	operator: string,
	value: string;
}

@Component({
	selector: 'suppression-rule-conditions',
	template: `
		<div class="wcd-padding-all wcd-padding-large-horizontal">
			<div *ngIf="associatedAlertsRepo?.sourceItem.iocConditionsNotEmpty; else noIocUseLabel">
				<div *ngIf="associatedAlertsRepo?.sourceItem.ruleType === suppressionRuleType.MDE; else defaultRuleConditions">
					<mde-suppression-rule-ioc-conditions [mdeConditions]="associatedAlertsRepo.sourceItem.complexConditions"></mde-suppression-rule-ioc-conditions>
				</div>
				<ng-template #defaultRuleConditions>
					<div *ngIf="associatedAlertsRepo?.sourceItem.ioaDefinitionId; else noIocUseLabel">
						<wcd-datatable [items]="tableItems" [columns]="tableFields" [selectEnabled]="false"></wcd-datatable>
					</div>
				</ng-template>
			</div>
			<ng-template #noIocUseLabel>
				<div>
					{{'suppressionRules.edit.noIocUse' | i18n}}
				</div>
			</ng-template>
		</div>
	`,
})
export class SuppressionRuleConditionsComponent implements OnInit{
	rule: SuppressionRule;
	associatedAlertsRepo: RelationshipRepository<SuppressionRule, Alert>;
	tableItems: Array<DatatableItems>;

	readonly suppressionRuleType = SuppressionRuleType;

	readonly tableFields = DataTableField.fromList<any>([
		{
			id: 'condition',
			name: this.i18nService.get(
				'suppressionRules.page.conditionTable.condition'
			),
			sort: { enabled: false },
		},
		{
			id: 'operator',
			name: this.i18nService.get(
				'suppressionRules.page.conditionTable.operator'
			),
			sort: { enabled: false },
		},
		{
			id: 'value',
			name: this.i18nService.get(
				'suppressionRules.page.conditionTable.value'
			),
			sort: { enabled: false },
		},
	]);

	constructor(
		private paris: Paris,
		private activatedEntity: ActivatedEntity,
		private i18nService: I18nService,
		private performanceSccService: PerformanceSccService,
		public mtpPromotionService: MtpPromotionService,
	) {
		this.associatedAlertsRepo = this.paris.getRelationshipRepository(SuppressionRuleAlertRelationship);
	}

	ngOnInit() {
		if (this.rule) {
			this.associatedAlertsRepo.sourceItem = this.rule;
		}
		this.activatedEntity.currentEntity$
			.pipe(filter((entity: ModelBase) => entity instanceof SuppressionRule))
			.subscribe((rule: SuppressionRule) => {
				this.associatedAlertsRepo.sourceItem = rule;
				this.performanceSccService.endNgPageLoadPerfSession('conditions');
			});

		this.tableItems = this.setDataItems();
	}

	setDataItems(): Array<DatatableItems> {
		return this.associatedAlertsRepo.sourceItem.conditions.map(condition => {
			return {
				condition: condition.type.name,
				operator: this.i18nService.get('suppressionRules.page.conditionTable.equals'),
				value: condition.value
			}
		})
	}
}
