import { AppInsightsService } from './../../../../insights/services/app-insights.service';
import { SevilleModule } from '../../seville/seville.module';
import { userAccountService } from '../../seville/services/seville.userAccounts';
import { RegExpService } from '@wcd/shared';
import '../services/seville.threatintel.investigationPackageService';
import { machinesLogicService } from '../../seville/services/seville.machinesLogic';
import { MACHINE_TEMPLATE } from '../actioncenter/seville.actioncenter.machine';
import { MachinesService } from '../../../../@entities/machines/services/machines.service';
import { MachineModel } from '../../../../@entities/machines/models/machine.model';
import { RiskScoreService } from '../services/seville.threatintel.machine.riskscore.service';
import { Feature } from '@wcd/config';
import { RbacMdeAllowedActions } from '../../../../rbac/enums/mde-allowed-actions.enum';
import { MachineSecurityAnalytics, CyberEventActionTypeName } from '@wcd/domain';
import { mapNetworkEventTypeToMessage } from './common/timelineeventcommon';
import { ResponseActionPermissionResult } from './common/machine.action.permissions.enum';
import { isEmpty, cloneDeep, isArray } from 'lodash-es';
import { LogonTypePipe } from '../../../../@entities/machines/pipes/logon-type.pipe';
import { breadcrumbsStateService } from '@wcd/shared';
import { filter } from 'rxjs/operators';
import { TrackingEventType } from '../../../../insights/models/tracking-event-type.enum';

declare let angular: angular.IAngularStatic;

SevilleModule.controller('seville.threatintel.machine', machineController);

SevilleModule.config([
	'$stateProvider',
	'$injector',
	'searchProvider',
	function($stateProvider, $injector, searchProvider) {
		$stateProvider.state('machine', {
			url: '/_machine/:id/:time?alert',
			params: {
				time: {
					value: null,
					squash: true,
				},
			},
			showBreadcrumbs: true,
			featureFlagToggleFeatureId: Feature.UpgradeMachinePage,
			showToggleFeatureId: Feature.ShowUpgradeMachinePageToggle,
			resolve: {
				IsUserExposedToMachine: isUserExposedToMachine,
			},
			title: 'Device',
			data: {
				entity: null,
				queryIdType: null,
				activeAlertId: null,
				activeAlertSeverity: null,
				activeAlertTime: null,
			},
			views: {
				main: {
					template: `
			<div ng-if="machine.main" data-track-component="MachinePage">
				<div ng-if="machine.showDeprecatedMessage"
					class="wcd-padding-horizontal wcd-flex-center-vertical wcd-flex-horizontal ms-background-color-themeLight"
					style="height:50px; margin: 0 -12px;">
					<i class="icon icon-Info wcd-margin-small-right"></i>
					<div class="message-bar-text wcd-flex-1">
						<span>Please note that this view will be unavailable starting July 3, 2019. </span>
						<a ng-click="machine.turnOnNewMachinePage()">Take me to the new device page!</a>
					</div>
				</div>
                <div class="row entity">
                    <!--Entity metadata-->
					<div class="col-sm-12 col-md-12 entity-cube"
						ng-class="::{'col-lg-4': !machine.machineRiskScoreEnabled, 'col-lg-3': machine.machineRiskScoreEnabled}">
                    <div class="border-default inner-box machine-header-cube-height">
                        <div class="machine-title-container">
                            <table>
                                <tr>
                                    <td rowspan="2">
									<i class="icon machine-main-title-icon icon-DeviceLaptopNoPic"></i>
                                    </td>
                                    <td ng-class="{'machine-main-title-aligned-center': machine.isRbacEnabled && machine.RbacGroup && machine.RbacGroup.isUnassignedGroup}">
										<div class="machine-main-title overflow overflow-all"
											ng-class="{'machine-main-title-padding' : !machine.machineIsUnhealthy}">
                                            <span ng-if="::machine.main.ComputerDnsName">{{::machine.main.ComputerDnsName | parts:1}}</span>
                                            <span ng-if="::!machine.main.ComputerDnsName">{{::machine.main.MachineGuid }}</span>
										</div>
                                        <div ng-if="machine.isRbacEnabled && machine.RbacGroup && !machine.RbacGroup.isUnassignedGroup">{{machine.RbacGroup.label}}</div>
                                        <div ng-if="!machine.isRbacEnabled && machine.main.MachineGroup">{{machine.main.MachineGroup}}</div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div ng-if="!machine.tagsLoaded" class="machine-header-content-scroll text-center logged-on-users-loading">
                    <img src="/assets/images/circle-loading.gif" />
                        </div>
                  <div ng-if="machine.tagsLoaded">
                    <div class="machine-actions" >

                      <div class="btn-group" uib-dropdown>
                        <button id="single-button" type="button" class="btn btn-primary dropdown-toggle secondary-button" uib-dropdown-toggle>
                          Actions <span class="icon icon-ChevronDown machine-actions-icon"></span>
                        </button>

                        <ul class="dropdown-menu machine-actions-list" uib-dropdown-menu role="menu"
                        	aria-labelledby="single-button" data-track-component="Machine Actions" data-track-component-type="Dropdown">
                          <li role="menuitem">
                            <div rbacjs state="'disabled'" permissions="['alertsInvestigation']" >
                              <button ng-disabled="!machine.machineTagsEnabled"
                              data-track-id="machineTagsAction" data-track-type="Action"
                              		ng-click="machine.openMachineTagsPane()">
                                                    <span>
                                                        Manage tags
                                                    </span>
                              </button>
                            </div>
                          </li>
                          <!--./Manage Tags-->

                          <li rbacjs state="'disabled'" permissions="['alertsInvestigation']"
                          		ng-if="machine.main.isResponseSupported && machine.main.responseFeauresStatus.forensic"
                          		role="menuitem" ng-switch="machine.main.ForensicStatus">
                            <div ng-switch-when="RequestPending" uib-popover="{{::machine.popOverTexts.RequestPending}}"
                            	popover-trigger="'mouseenter'"
                            	popover-class="threatintel-popover response-action-pending-action-popover-content"
                            	popover-placement="right">
                              <button ng-disabled="true" class="response-pending-dropdown-menu-item"
                              	data-track-id="machineCollectInvestigationPackageAction" data-track-type="Action">
                                <span class="response-pending-dropdown-menu-item">Collect investigation package</span>
                              </button>
                            </div>
                            <div ng-switch-when="undefined" uib-popover="{{::machine.popOverTexts.technicalErr}}"
                            	popover-trigger="'mouseenter'" popover-class="threatintel-popover forensic-submit-action-popover-content"
                            	popover-placement="right"> <!--in case get request failed-->
                              <button ng-disabled="true" data-track-id="machineCollectInvestigationPackageAction"
                              	data-track-type="Action"><span>Collect investigation package</span></button>
                            </div>
                            <div ng-switch-default uib-popover="Gather forensic information about device into a downloadable package"
                            	popover-trigger="'mouseenter'" popover-class="threatintel-popover forensic-submit-action-popover-content"
                            	popover-placement="right">
                              <button ng-click="machine.forensic()" data-track-id="machineCollectInvestigationPackageAction"
                              	data-track-type="Action"><span>Collect investigation package</span></button>
                            </div>
                          </li>
                          <!--./Collect Investigation package-->

                          <li rbacjs state="'disabled'" permissions="['alertsInvestigation']"
                          		ng-if="machine.main.isResponseSupported && machine.main.responseFeauresStatus.scan"
                          		role="menuitem" ng-switch="machine.main.ScanState">
                            <div ng-switch-when="RequestPending" uib-popover="{{::machine.popOverTexts.RequestPending}}"
                            	popover-trigger="'mouseenter'" popover-class="threatintel-popover response-action-pending-action-popover-content"
                            	popover-placement="right">
                              <button ng-disabled="true" class="response-pending-dropdown-menu-item"
                              	data-track-id="machineAntivirusScanAction" data-track-type="Action">
                                <span class="response-pending-dropdown-menu-item">Run antivirus scan</span>
                              </button>
                            </div>

                            <div ng-switch-when="undefined" uib-popover="{{::machine.popOverTexts.technicalErr}}"
                            	popover-trigger="'mouseenter'" popover-class="threatintel-popover forensic-submit-action-popover-content"
                            	popover-placement="right"> <!--in case get request failed-->
                              <button ng-disabled="true" data-track-id="machineAntivirusScanAction" data-track-type="Action"><span>Run antivirus scan</span></button>
                            </div>

							<div ng-switch-when="NotSupported" uib-popover="{{::machine.popOverTexts.NotSupported.scan}}"
								popover-trigger="'mouseenter'" popover-class="threatintel-popover response-action-avscan-notsupported-action-popover-content"
								popover-placement="right">
                              <button ng-disabled="true">
                                <span class="response-pending-dropdown-menu-item" data-track-id="machineAntivirusScanAction"
                                data-track-type="Action">Run antivirus scan</span>
                              </button>
							</div>

                            <div ng-switch-default uib-popover="Scan machine for malware" popover-trigger="'mouseenter'"
                            popover-class="threatintel-popover avscan-submit-action-popover-content" popover-placement="right">
                              <button data-track-id="machineAntivirusScanAction" data-track-type="Action" ng-click="machine.avscan()">
                                Run antivirus scan
                              </button>
                            </div>
                          </li>
                          <!--./run antivirus scan-->

                          <!-- Commented out for rs3 - not implemeted yet on server side -->
                          <!--<li ng-if="machine.isAutoIRFeatureEnabled && machine.main.isResponseSupported &&
                          	machine.main.OsPlatform.toLowerCase() === 'windows10' && machine.main.isRs4" role="menuitem">
                            <div uib-popover="Run an automated investigation on the machine" popover-trigger="'mouseenter'"
                            	popover-class="threatintel-popover forensic-submit-action-popover-content" popover-placement="right">
                              <button href="{{machine.navigateToInitateInvestigationLink}}" target="AIRS">
                                <span class="">Initiate Investigation</span>
                              </button>
                            </div>
                          </li> -->
                          <!-- ./Initiate investigation -->

                          <li rbacjs state="'disabled'" permissions="['remediationActions']"
                          	ng-if="machine.main.isResponseSupported && machine.main.responseFeauresStatus.executionPolicy" role="menuitem"
                          	ng-switch="machine.main.ExecutionPolicyState">

                            <div ng-switch-when="RequestCompleted" uib-popover="Remove execution restrictions on apps that are not signed by Microsoft"
                        	    popover-trigger="'mouseenter'" popover-class="threatintel-popover unrestrict-submit-action-popover-content"
                         	   popover-placement="right">
                              <button ng-click="machine.applyExecutionPolicy()" data-track-id="machineRemoveAppRestrictionsAction"
                              	data-track-type="Action">Remove app restrictions</button>
                            </div>

                            <div ng-switch-when="ReverseRequestPending" uib-popover="{{::machine.popOverTexts.RequestPending}}"
                            popover-trigger="'mouseenter'" popover-class="threatintel-popover response-action-pending-action-popover-content"
                            popover-placement="right">
                              <button ng-disabled="true" class="response-pending-dropdown-menu-item">
                                <span class="response-pending-dropdown-menu-item" data-track-id="machineRemoveAppRestrictionsAction"
                                data-track-type="Action">Remove app restrictions</span>
                              </button>
                            </div>

                            <div ng-switch-when="RequestPending" uib-popover="{{::machine.popOverTexts.RequestPending}}"
                             popover-trigger="'mouseenter'" popover-class="threatintel-popover response-action-pending-action-popover-content"
                             popover-placement="right">
                              <button ng-disabled="true" class="response-pending-dropdown-menu-item">
                                <span class="response-pending-dropdown-menu-item" data-track-id="machineAppRestrictionsAction"
                                 data-track-type="Action">Restrict app execution</span>
                              </button>
                            </div>

                            <div ng-switch-when="NotSupported" uib-popover="{{::machine.popOverTexts.NotSupported.restrictExecution}}"
                            popover-trigger="'mouseenter'" popover-class="threatintel-popover response-action-avscan-notsupported-action-popover-content"
                             popover-placement="right">
                              <button ng-disabled="true">
                                <span class="response-pending-dropdown-menu-item" data-track-id="machineAppRestrictionsAction"
                                data-track-type="Action">Restrict app execution</span>
                              </button>
                            </div>

                            <div ng-switch-when="undefined" uib-popover="{{::machine.popOverTexts.technicalErr}}"
                            popover-trigger="'mouseenter'" popover-class="threatintel-popover forensic-submit-action-popover-content"
                            popover-placement="right"> <!--in case get request failed-->
                              <button ng-disabled="true" data-track-id="machineAppRestrictionsAction"
                               data-track-type="Action"><span>Restrict app execution</span></button>
                            </div>

                            <div ng-switch-when="ReverseRequestCompleted" uib-popover="Prevent applications that are not signed by Microsoft from running"
                             popover-trigger="'mouseenter'" popover-class="threatintel-popover machine-submit-action-popover-content"
                             popover-placement="right">
                              <button ng-click="machine.applyExecutionPolicy()" data-track-id="machineAppRestrictionsAction"
                              data-track-type="Action">Restrict app execution</button>
							</div>
                          </li>
                          <!--./restrict app execution-->

                          <li rbacjs state="'disabled'" permissions="['remediationActions']"
                          ng-if="machine.main.isResponseSupported && machine.main.responseFeauresStatus.isolation" role="menuitem"
                          ng-switch="machine.main.IsolationStatus">
                            <div ng-switch-when="RequestCompleted" uib-popover="Restore connection to the network"
                            popover-trigger="'mouseenter'" popover-class="threatintel-popover unisolate-submit-action-popover-content"
                            popover-placement="right">
                              <button ng-click="machine.isolate()" data-track-id="machineReleaseFromIsolationAction"
                              data-track-type="Action">Release from isolation</button>
                            </div>

                            <div ng-switch-when="ReverseRequestPending" uib-popover="{{::machine.popOverTexts.RequestPending}}"
                             popover-trigger="'mouseenter'" popover-class="threatintel-popover response-action-pending-action-popover-content"
                              popover-placement="right">
                              <button disabled class="response-pending-dropdown-menu-item"
                              data-track-id="machineReleaseFromIsolationAction"
                              data-track-type="Action">
                                <span class="response-pending-dropdown-menu-item">Release from isolation</span>
                              </button>
                            </div>

                            <div ng-switch-when="RequestPending" uib-popover="{{::machine.popOverTexts.RequestPending}}"
                            popover-trigger="'mouseenter'" popover-class="threatintel-popover response-action-pending-action-popover-content"
                             popover-placement="right">
                              <button disabled class="response-pending-dropdown-menu-item" data-track-id="machineIsolateAction"
                              data-track-type="Action">
                                <span class="response-pending-dropdown-menu-item">Isolate device</span>
                              </button>
                            </div>

                            <div ng-switch-when="undefined" uib-popover="{{::machine.popOverTexts.technicalErr}}"
                            popover-trigger="'mouseenter'" popover-class="threatintel-popover forensic-submit-action-popover-content"
                             popover-placement="right"> <!--in case get request failed-->
                              <button disabled data-track-id="machineIsolateAction" data-track-type="Action">Isolate device</button>
                            </div>

                            <div ng-switch-when="ReverseRequestCompleted"
                            uib-popover="Disconnect device from network while retaining connection to Microsoft Defender for Endpoint service"
                            popover-trigger="'mouseenter'" popover-class="threatintel-popover isolate-submit-action-popover-content"
                             popover-placement="right">
                              <button ng-click="machine.isolate()" data-track-id="machineIsolateAction" data-track-type="Action">Isolate device</button>
							</div>
                          </li>
						  <!--./isolate machine-->

						   <li rbacjs state="'disabled'" permissions="['alertsInvestigation']" ng-if="machine.huntingEnabled" role="menuitem">
                            <div uib-popover="Query raw event data using Advanced hunting" popover-trigger="'mouseenter'"
                            popover-class="threatintel-popover action-center-hunting" popover-placement="right">
                              <button router-link="['/hunting']">
                                <span class="">Go to Advanced hunting</span>
                              </button>
                            </div>
                          </li>
                          <!-- ./Hunting -->

                          <li class="divider"></li>
                          <li role="menuitem" rbacjs state="'disabled'" permissions="['remediationActions', 'alertsInvestigation']">
                            <div>
                            <button ng-disabled="!machine.main.isResponseSupported"
							ng-click="machine.openHover()"
                            uib-popover="View the status of submitted actions"
                            popover-trigger="'mouseenter'"
                            popover-class="threatintel-popover response-action-action-center-action-popover-content"
                            popover-placement="right"
                             data-track-id="machineOpenActionCenter" data-track-type="Action"
                            href="#">
								<span>
									Action center
									<span ng-if="machine.main.ResponseActionExists" class="icon action-center-response-action-circle"></span>
									<span ng-if="machine.main.ResponseActionExists" class="action-center-response-action-exclamation-mark"></span>
								</span>
                              </button>
                            </div>
                          </li>
                          <!--./action center-->

                        </ul>

                      </div>

                      <span ng-if="machine.main.ResponseActionExists" class="icon response-action-circle"></span>
                      <span ng-if="machine.main.ResponseActionExists" class="response-action-exclamation-mark"></span>
                    </div>

                    <div class="entity-meta-main-content machine-header-content-scroll">
                      <div ng-if="::machine.main.ComputerDnsName" tooltip-show-on-overflow-only tooltips
                      tooltip-title="{{::machine.main.Domain }}" class="overflow overflow-all ellipsis">Domain: {{::machine.main.Domain }}</div>
					  <div ng-if="::machine.main.OsPlatform || machine.main.OsProcessor">
					  	OS: <span class="machine-os-text">{{::machine.main.OsPlatform}} {{::machine.main.OsProcessor | processor}}</span>
						  <span ng-if="::machine.main.OsBuild || machine.main.ReleaseVersion">
						  	 {{machine.osDetailsString}}
						  </span>
                      </div>
                      <div ng-if="::machine.isServer && machine.main.serverRoles">Server Role: {{::machine.main.serverRoles}}</div>
                      <div ng-if="::machine.main.LastUpdateTimeUtc" class="response-package-collection-latest">
                        <span class="response-forensics-text">Investigation package: Available ({{::machine.main.LastUpdateTimeUtc | sevilleDate : 'date'}})</span>
                        <a ng-if="machine.main.packageUri" href="#" ng-click="machine.refreshUriAndDownloadPackage(true)">
                          <i class="icon machine-timeline-icon icon-Download"></i>
                        </a>
                      </div>
                      <div ng-if="(machine.machineTagsEnabled && machine.userDefinedTags.length > 0) || (machine.isRbacEnabled && machine.main.MachineGroup)"
                      class="machine-header-tags-button overflow overflow-all ellipsis">
						Tags:
						<!-- Endpoint tag (aka machine group) -->
						<span ng-if="machine.isRbacEnabled && machine.main.MachineGroup">
						<span class="machine-tag-icon">
                                        <i class="icon icon-Tag"></i>
                                        <span>{{machine.main.MachineGroup}}</span>
						</span>
						</span>
						<!-- User defined tags -->
						<span class="machine-tag-icon" ng-repeat="tag in machine.userDefinedTags track by $index">
										<span ng-if="$first && machine.isRbacEnabled && machine.main.MachineGroup">,</span>
                                        <i class="icon icon-Tag"></i>
                                        <span>{{tag}}</span>
                                        <span ng-if="!$last">,</span>
                                    </span>
					  </div>
					  <!-- ./tags -->
					  <div class="machine-reporting-ip">
					     <button class="btn btn-link nopadding"
					      ng-click="machine.showMachineIpsPanel()">Machine IP addresses <i class="icon icon-ChevronRightSm"></i></button>
					  </div>
                    </div>
                  </div>
                  <!--./ machine actions dropdown container-->
                    </div>
                </div>

                    <!-- Logged On Users -->
                    <div class="col-sm-12 col-md-12 entity-cube"
                    ng-class="::{'col-lg-4': !machine.machineRiskScoreEnabled, 'col-lg-3': machine.machineRiskScoreEnabled}">
                        <div class="border-default inner-box-scroll machine-header-cube-height">
                            <div class="logged-on-user-title">
                                <div class="entity-meta-main-title">
                                    <i class="icon icon-Contact"></i>
                                    <span>Logged on users</span>
                                    <span class="logged-on-users-time-title">(last 30 days)</span>
                                </div>
                            </div>
                            <div ng-if="machine.loadingUserDetails" class="machine-header-content-scroll text-center logged-on-users-loading">
                                <img src="/assets/images/circle-loading.gif" />
                            </div>
                            <div ng-if="!machine.loadingUserDetails" class="machine-header-content-scroll">
                                <div class="machine-header-parent machine-header-tile-padding machine-header-padding logged-on-users-padding-machine-header"
                                ng-if="machine.userDetails">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <button class="btn machine-profile-logged-users-button" data-toggle="dropdown"
                                            aria-expanded="true" type="button" ng-click="machine.openLoggedOnUsers()"
											ng-class="{'hero-button': machine.userDetails.LoggedOnusers > 0, 'machine-profile-logged-users-disabled': machine.userDetails.LoggedOnusers == 0}">
                                                <span class="machine-profile-logged-users">
                                                    {{::machine.userDetails.LoggedOnusers}}
                                                </span>
                                                <i ng-if="::machine.userDetails.LoggedOnusers > 0"
                                                class="icon icon-ChevronRightSm machine-profile-logged-users-arrow hero-button"></i>
                                            </button>
                                        </div>
                                        <div class="col-sm-6 logged-on-users-logontype">
                                            <div>
                                                <span>
                                                    Interactive
                                                </span>
                                                <i class="icon icon-Info logged-on-users-info  hero-button"
                                                uib-popover="User physically logged on to the device" popover-append-to-body="true"
                                                popover-trigger="'mouseenter'" popover-class="threatintel-popover machine-interactive-icon-popover-content"
                                                 popover-placement="right"></i>
                                                <span>
                                                    [{{machine.userDetails.InteractiveLogonCount}}]
                                                </span>
                                            </div>
                                            <div>
                                                <div>
                                                    <span>
                                                        RemoteInteractive
                                                    </span>
                                                    <i class="icon icon-Info logged-on-users-info  hero-button"
                                                    uib-popover="User logged on remotely via: Terminal Services, Remote Desktop, or Remote Assistance"
                                                    popover-append-to-body="true" popover-trigger="'mouseenter'"
                                                    popover-class="threatintel-popover machine-remote-interactive-icon-popover-content" popover-placement="left"></i>
                                                    <span>
                                                        [{{machine.userDetails.RemoteInteractiveLogonCount}}]
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="logged-on-users-padding">
                                                <div>
                                                    <span>
                                                        Other
                                                    </span>
                                                    <i class="icon icon-Info logged-on-users-info  hero-button" popover-append-to-body="true"
                                                    popover-trigger="'mouseenter'" popover-class="threatintel-popover machine-other-users-icon-popover-content"
                                                     popover-placement="left"
                                                     uib-popover-html="'<span><b>Network: </b>User connected via remote process (e.g. psexec) or connected to a shared resource (folder or printer).
                                                        <br />
                                                        User account connecting to shared resources may not appear in the Device Timeline.
                                                    <br/>
                                                        <b>Batch: </b>Logon session opened by a scheduled task.
                                                        <br />
                                                        <b>Service: </b>Logon session created by a Service starting</span>'"></i>
                                                    <span>
                                                        [{{machine.userDetails.OtherLogonCount}}]
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="machine-header-padding">
                                    <div class="overflow white-space" ng-if="machine.userDetails">
                                        <div class="inline"
                                        ng-if="::machine.userDetails.MostPrevalentAccountName && machine.userDetails.LeastPrevalentAccountName !=machine.userDetails.MostPrevalentAccountName">
                                            <div class="ellipsis">
                                                <span>Most frequent:</span>
                                                <i class="icon machine-timeline-icon icon-Contact"></i>
                                                <a ng-if="machine.isUserLinkAllowed"
                                                 ui-sref="user(::{ userdomain: machine.userDetails.MostPrevalentAccountDomainName, id: machine.userDetails.MostPrevalentAccountName, sid:machine.userDetails.MostPrevalentAccountSid })">
                                                    <span ng-if="::machine.mostPrevalentUserDisplay.length < 34">{{:: machine.mostPrevalentUserDisplay}}</span>
                                                    <span ng-if="::machine.mostPrevalentUserDisplay.length >= 34"
                                                          tooltips tooltip-side="left" tooltip-title="{{:: machine.mostPrevalentUserDisplay}}">
                                                          {{:: machine.mostPrevalentUserDisplay}}</span>
												</a>
												<span ng-if="!machine.isUserLinkAllowed">
													<span ng-if="::machine.mostPrevalentUserDisplay.length < 34">{{:: machine.mostPrevalentUserDisplay}}}</span>
													<span ng-if="::machine.mostPrevalentUserDisplay.length >= 34"
													  	tooltips tooltip-side="left" tooltip-title="{{:: machine.mostPrevalentUserDisplay}}">
													  	{{:: machine.mostPrevalentUserDisplay}}</span>
												</span>
                                            </div>
                                            <span class="user-profile-disabled" ng-if="::machine.userDetails.IsLocalAdminMostPrevalent">(Local admin)</span>
                                            <span class="user-profile-disabled" ng-if="::!machine.userDetails.IsLocalAdminMostPrevalent">(Standard user)</span>
                                        </div>
                                    </div>
                                    <div class="overflow white-space" ng-if="machine.userDetails">
                                        <div class="inline"
                                         ng-if="::machine.userDetails.LeastPrevalentAccountName && machine.userDetails.LeastPrevalentAccountName !=machine.userDetails.MostPrevalentAccountName">
                                            <div class="ellipsis">
                                                <span>Least frequent:</span>
                                                <i class="icon machine-timeline-icon icon-Contact"></i>
                                                <a ng-if="machine.isUserLinkAllowed"
                                                ui-sref="user(::{ userdomain: machine.userDetails.LeastPrevalentAccountDomainName, id: machine.userDetails.LeastPrevalentAccountName, sid: machine.userDetails.LeastPrevalentAccountSid })">
                                                    <span ng-if="::machine.leastPrevalentUserDisplay.length < 34">{{:: machine.leastPrevalentUserDisplay}}</span>
                                                    <span ng-if="::machine.leastPrevalentUserDisplay.length >= 34"
                                                          tooltips tooltip-side="left" tooltip-title="{{:: machine.leastPrevalentUserDisplay}}">
                                                          {{:: machine.leastPrevalentUserDisplay}}</span>
												</a>
												<span ng-if="!machine.isUserLinkAllowed">
													<span ng-if="::machine.leastPrevalentUserDisplay.length < 34">{{:: machine.leastPrevalentUserDisplay}}</span>
													<span ng-if="::machine.leastPrevalentUserDisplay.length >= 34"
														  tooltips tooltip-side="left" tooltip-title="{{::machine.leastPrevalentUserDisplay}}">
														  {{::machine.leastPrevalentUserDisplay}}</span>
												</span>
                                            </div>

                                            <span class="user-profile-disabled" ng-if=":: machine.userDetails.IsLocalAdminLeastPrevalent">(Local admin)</span>
                                            <span class="user-profile-disabled" ng-if="::!machine.userDetails.IsLocalAdminLeastPrevalent">(Standard user)</span>
                                        </div>
                                    </div>
                                    <!-- if we have only 1 user-->
                                    <div class="overflow white-space" ng-if="machine.userDetails">
                                        <div class="inline"
                                        ng-if="::machine.userDetails.MostPrevalentAccountName && machine.userDetails.LeastPrevalentAccountName == machine.userDetails.MostPrevalentAccountName &&  machine.userDetails.MostPrevalentAccountName != null">
                                            <div class="ellipsis">
                                                <i class="icon machine-timeline-icon icon-Contact"></i>
                                                <a ng-if="machine.isUserLinkAllowed"
                                                ui-sref="user(::{ userdomain: machine.userDetails.MostPrevalentAccountDomainName, id: machine.userDetails.MostPrevalentAccountName, sid:machine.userDetails.MostPrevalentAccountSid })">
                                                    <span ng-if="::machine.mostPrevalentUserDisplay.length < 29">
                                                    {{::machine.mostPrevalentUserDisplay}}</span>
                                                    <span ng-if="::machine.mostPrevalentUserDisplay.length >= 29"
                                                          tooltips tooltip-title="{{:: machine.mostPrevalentUserDisplay}}">
                                                          {{:: machine.mostPrevalentUserDisplay}}</span>
												</a>
												<span ng-if="!machine.isUserLinkAllowed">
													<span ng-if="::machine.mostPrevalentUserDisplay.length < 29">{{::machine.mostPrevalentUserDisplay}}</span>
													<span ng-if="::machine.mostPrevalentUserDisplay.length >= 29"
														tooltips tooltip-title="{{:: machine.mostPrevalentUserDisplay}}">{{:: machine.mostPrevalentUserDisplay}}</span>
												</span>
                                            </div>
                                            <span class="user-profile-disabled" ng-if=":: event.IsLocalAdminMostPrevalent">(Local admin)</span>
                                        </div>
                                    </div>
                                    <!-- if we have don't have interactive or remote interactive user-->
                                    <div class="overflow white-space" ng-if="machine.userDetails">
                                        <div class="inline"
                                        ng-if="::machine.userDetails.LeastPrevalentAccountName == machine.userDetails.MostPrevalentAccountName &&  machine.userDetails.MostPrevalentAccountName == null">
                                            <div class="overflow logged-on-users-no-user">
                                                No Interactive or RemoteInteractive Logon Types observed on device.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

					<!--Risk score  -->
					<div ng-if="machine.machineRiskScoreEnabled" class="col-sm-12 col-md-12 col-lg-3 entity-cube">
                        <div class="border-default inner-box-scroll machine-header-cube-height">
                            <div ng-if="!machine.loadingAtaDetails && !machine.loadingRiskScoreDetails" class="logged-on-user-title">
								<div class="entity-meta-main-title">
								   <span class="wcd-severity" ng-class="::machine.main.riskScoreDetails.className"></span>
								   <span ng-if="::machine.main.riskScoreDetails.text"
								   class="machine-risk-score-text">{{::machine.main.riskScoreDetails.text}} Risk</span>
								   <span ng-if="::!machine.main.riskScoreDetails.text">No known risk</span>
								   <i class="icon icon-Info logged-on-users-info hero-button"
								   popover-trigger="'mouseenter'"
								   popover-class="threatintel-popover machine-risk-icon-popover-content"
								   popover-placement="right"
								   uib-popover-html="'The risk level reflects the overall risk assessment of the device based on combination of factors, including the types and severity of active alerts on the device. Resolving active alerts, approving remediation activities, and suppressing subsequent alerts can lower the risk level.
								   <br /> <br />
								   The risk level can influence enforcement of conditional access and other security policies on Microsoft Intune and other connected solutions.'" popover-append-to-body="true"></i>
                                </div>
							</div>
							<div ng-if="machine.loadingAtaDetails || machine.loadingRiskScoreDetails"
							class="machine-header-content-scroll text-center logged-on-users-loading">
                                <img src="/assets/images/circle-loading.gif" />
                            </div>
                            <div ng-if="!machine.loadingAtaDetails && !machine.loadingRiskScoreDetails" class="machine-header-content-scroll">
                                <div class="machine-active-alerts-header machine-header-padding">
									Active alerts:
									<i class="icon icon-Info logged-on-users-info  hero-button"
									  uib-popover="Alerts that have not been resolved manually by users or by automated investigations"
								      popover-trigger="'mouseenter'"
								      popover-class="threatintel-popover"
									  popover-placement="right" popover-append-to-body="true">
									</i>
								</div>

								<div class="overflow machine-active-alerts-desc machine-header-padding">
				                    <span class="machine-attack-sev sev-high overflow machine-queue-active-alerts-single" tooltips
					                      tooltip-size="medium" tooltip-title="High severity" tooltip-side="left"
										  ng-class="{ 'machine-queue-alert-results-double' : machine.main.alertsSummary.HighSevAlerts >= 10, 'machine-queue-alert-results-single' : machine.main.alertsSummary.HighSevAlerts < 10}">
										    <div class="machine-queue-active-alert-amount">{{::machine.main.alertsSummary.HighSevAlerts}}</div>
										    <div class="machine-queue-active-alert-sev">High</div>
										  </span>
				                    <span class="machine-attack-sev sev-medium overflow machine-queue-active-alerts-single" tooltips
					                      tooltip-size="medium" tooltip-title="Medium severity" tooltip-side="left"
										  ng-class="{ 'machine-queue-alert-results-double' : machine.main.alertsSummary.MediumSevAlerts >= 10, 'machine-queue-alert-results-single' : machine.main.alertsSummary.MediumSevAlerts < 10}">
										    <div class="machine-queue-active-alert-amount">{{::machine.main.alertsSummary.MediumSevAlerts}}</div>
										    <div class="machine-queue-active-alert-sev">Medium</div>
										  </span>
				                    <span class="machine-attack-sev sev-low overflow machine-queue-active-alerts-single" tooltips
					                      tooltip-size="medium" tooltip-title="Low severity" tooltip-side="left"
										  ng-class="{ 'machine-queue-alert-results-double' : machine.main.alertsSummary.LowSevAlerts >= 10, 'machine-queue-alert-results-single': machine.main.alertsSummary.LowSevAlerts < 10}">
										    <div class="machine-queue-active-alert-amount">{{::machine.main.alertsSummary.LowSevAlerts}}</div>
										    <div class="machine-queue-active-alert-sev machine-queue-active-alert-sev-low">Low</div>
										  </span>
				                    <span class="machine-attack-sev sev-informational overflow machine-queue-active-alerts-single"
					                      tooltips tooltip-size="medium" tooltip-title="Informational severity" tooltip-side="left"
										  ng-class="{ 'machine-queue-alert-results-double' : machine.main.alertsSummary.InformationalSevAlerts >= 10, 'machine-queue-alert-results-single': machine.main.alertsSummary.InformationalSevAlerts < 10}">
										    <div class="machine-queue-active-alert-amount">{{::machine.main.alertsSummary.InformationalSevAlerts}}</div>
											<div class="machine-queue-active-alert-sev machine-queue-active-alert-sev-informational">Informational</div>
										  </span>
								</div>

								<div class="machine-header-padding">
								   <div ng-if="machine.ataProfile">
								       <a class="machine-ata-profiler-url" ng-if="machine.ataProfile.Url" target="_blank"
								       href="{{machine.ataProfile.Url}}" ng-click="machine.trackNavigationToAzureAtp()">
								         <span class="prevalence-counter">{{::machine.ataProfile.TotalAlerts | megaNumber}}</span>
									     <span class="machine-noatp-profile-title entity-meta-main-title">MDI {{::machine.ataProfile.alertDisplayString}}</span>
									   </a>
									   <span ng-if="!machine.ataProfile.Url">
									      <span class="prevalence-counter">{{::machine.ataProfile.TotalAlerts | megaNumber}}</span>
									      <span class="machine-noatp-profile-title">MDI {{::machine.ataProfile.alertDisplayString}}</span>
									   </span>
									</div>
									<div ng-if="machine.ataAdvancedSettingEnabled && !machine.ataProfile" class="machine-noatp-profile">
										<div class="overflow-all ellipsis"> Device not found in MDI </div>
									</div>
									<div ng-if="!machine.ataAdvancedSettingEnabled" class="machine-noatp-profile">
										<div class="overflow-all ellipsis">
											<a href="/preferences2/integration"> Check MDI Integration </a>
										</div>
									</div>
								</div>

							</div>
                        </div>
                    </div>

                    <!-- Report On Machine with user details enabled-->
					<div class="col-sm-12 col-md-12 entity-cube"
					ng-class="::{'col-lg-4': !machine.machineRiskScoreEnabled, 'col-lg-3': machine.machineRiskScoreEnabled}">
						<div class="border-default inner-box machine-header-cube-height logged-on-users-loading">
                            <div class="logged-on-user-title">
                                <span class="entity-meta-main-title">
								<span ng-if="::!machine.machineIsUnhealthy">
								<i class="icon machine-reporting-user-icon icon-DeviceLaptopNoPic"></i>
									<i class="icon icon-SignalBars5"
									ng-class="::{'machine-reporting-overlay-user-icon': !machine.isServer, 'server-reporting-overlay-user-icon': machine.isServer}"></i>
									<span>Device reporting</span>
								</span>
								<span ng-if="::machine.machineIsUnhealthy">
								<span class="machine-health-status-icon-container"
								ng-class="{'machine-misconfigured-icon': machine.machineIsMisconfigured,
								'machine-inactive-icon': machine.machineIsInactive}">
								<i class="icon machine-health-status-icon icon-DeviceLaptopNoPic"></i>
								<i class="icon machine-health-overlay machine-health-status-overlay"
									ng-class="::{'icon-Health machine-health-overlay-misconfigured': machine.machineIsMisconfigured,
												'icon-Blocked machine-health-overlay-inactive': machine.machineIsInactive}"></i>
							</span>
						   <span>Device requires attention</span>
									</span>
								</span>
							</div>
							<div ng-if="::machine.machineIsUnhealthy" class="machine-health-summary-container">
							<span>{{::machine.healthStatusSummary}}<span ng-if="::machine.machineIsInactive"> device</span></span>
							<button uib-popover-template="'machineHealthPopover.html'" popover-trigger="'outsideClick'"
							popover-placement="bottom" type="button" class="btn btn-link dropdown-toggle nopadding pointer">
								<i class="icon icon-Info machine-health-info-icon hero-button"></i>
							</button>
						</div>
							<div ng-if="machine.loadingMachineLatestState" class="machine-header-content-scroll text-center logged-on-users-loading">
								<img src="/assets/images/circle-loading.gif" />
							</div>
							   <div ng-if="!machine.loadingMachineLatestState && !machine.machineSecurityStateLoadFailed">
								<div class="machine-health-tile-header"
								ng-class="{'machine-header-parent': machine.isMachineSecurityScoreEnabled,
								'machine-header-parent-top': !machine.isMachineSecurityScoreEnabled}">
									<div>First seen: <span class="prevalence-date" time-ago utc="{{::machine.main.FirstSeen}}"></span></div>
									<div>Last seen: <span class="prevalence-date" time-ago utc="{{::machine.main.LastSeen}}"></span></div>
									</div>
								<div ng-if="machine.isMachineSecurityScoreEnabled">
								<div ng-if="!machine.machineIsInactive" class="machine-health-tile-score">
									{{machine.Score}}/{{machine.MaxScore}}
									Windows secure score
											<button class="btn btn-link nopadding" ng-click="machine.showMachineScorePanel()">
												<span ng-if="machine.Score < machine.MaxScore">
													<i class="icon icon-sm icon-Warning color-text-warning-dark"></i>
													Device security state requires attention
												</span>
												<span ng-if="machine.Score == machine.MaxScore">
													<i class="color-text-success-light icon-CompletedSolid icon icon-sm"></i>
													Device security state is well configured
												</span>
												<i class="icon icon-ChevronRightSm"></i>
											</button>
										 </div>
									</div>
								</div>
							</div>
							<div ng-if="!machine.loadingMachineLatestState && machine.machineSecurityStateLoadFailed">
								<!-- <i class="icon icon-Error"></i> -->
							</div>
                    </div>
                </div>

                <!-- Associated Alerts -->
                <associated-ioas hide-machine-names="true" entity="'machine'" machine-id="machine.main.MachineId"
                 refresh-investigation-states="true" data-track-component="MachineAlerts"></associated-ioas>

                <!-- Events Timeline -->
                <events-timeline events="machine.events" loading="machine.loading" type="'MachineTimeline'"
                                 load-events="machine.loadEvents" start-date="machine.telemetryToDate"
                                 fidelity="machine.fidelity" usercontext="machine.usercontext"
                                 data-track-component="Machine Events"
                                 sense-machine-id="machine.main.SenseMachineId" event-types-filter="machine.eventTypesFilter"
								 computer-dns-name="machine.main.ComputerDnsName" error="machine.error"
								 is-user-link-allowed="machine.isUserLinkAllowed"></events-timeline>
            </div>

            <script type="text/ng-template" id="machineHealthPopover.html">
                <div>
                    <div class="machine-health-status-popover-description">
                        {{machine.healthStatusPopoverDescription}}
                    </div>
                    <div class="machine-health-status-popover-link">
                        <a href="https://go.microsoft.com/fwlink/p/?linkid=844784" target="_blank">Open docs for more info</a>
                    </div>
                </div>
            </script>
						`,
					controller: 'seville.threatintel.machine',
					controllerAs: 'machine',
				},
			},
		});

		searchProvider.registerEntitySearchProvider(
			'Machine',
			function(searchTerm) {
				return false;
			},
			function(searchTerm, $state) {
				$state.go('search.machine', { id: searchTerm }, { reload: true });
				return;
			}
		);

		/**
		 * This method acts as a route guard - each time this route is trying to be accessed, this function will run,
		 * contacting the api to check if user is authorized to view the machine
		 * if the route is written manually - once the modal closes the user will be redirected into the dashboard
		 **/
		isUserExposedToMachine.$inject = [
			'$q',
			'$http',
			'$stateParams',
			'urlMapping',
			'featuresService',
			'appInsights',
			'rbacMachineGroupService',
		];
		function isUserExposedToMachine(
			$q,
			$http,
			$stateParams,
			urlMapping,
			featuresService,
			appInsights: AppInsightsService,
			rbacMachineGroupService
		) {
			const dfd = $q.defer();
			if (featuresService.isEnabled(Feature.RbacMachineGroups)) {
				// Flighting feature validation
				rbacMachineGroupService.isUserExposedToMachine($stateParams.id).then(response => {
					if (response.status === 200 && response.data === true) {
						dfd.resolve(response.data);
					} else if (response.status === 404) {
						dfd.resolve();
					} else {
						dfd.reject(false);
						appInsights.trackException(
							new Error(
								'device page: isUserAllowed: User is not authorized to view a device, status code: ' +
									response.status +
									' alertID: ' +
									$stateParams.id
							)
						);
					}
				});
			} else {
				dfd.resolve(true);
			}
			return dfd.promise;
		}
	},
]);

machineController.$inject = [
	'$rootScope',
	'$log',
	'$q',
	'$scope',
	'$filter',
	'$http',
	'$state',
	'$stateParams',
	'$localStorage',
	'appConfig',
	'urlMapping',
	'investigationService',
	'alertFilterService',
	'demoTenantResolverService',
	'$uibModal',
	'$interval',
	'hoverPaneService',
	'coldStorage',
	'coldStorageConsts',
	'authenticationService',
	'featuresService',
	'$window',
	'investigationPackageService',
	'genericEtwService',
	'filtersLocalStorage',
	'machineClassificationService',
	'machinesService',
	'ng2router',
	'riskScoreService',
	'rbacMachineGroupService',
	'logonTypePipe',
	'appInsights',
];

function machineController(
	$rootScope,
	$log,
	$q,
	$scope,
	$filter,
	$http,
	$state,
	$stateParams,
	$localStorage,
	appConfig,
	urlMapping,
	investigation,
	alertFilterService,
	demoTenantResolverService,
	$modal,
	$interval,
	hoverPaneService,
	coldStorage,
	coldStorageConsts,
	authenticationService,
	featuresService,
	$window,
	investigationPackageService,
	genericEtwService,
	filtersLocalStorage,
	machineClassificationService,
	machinesService: MachinesService,
	ng2router,
	riskScoreService: RiskScoreService,
	rbacMachineGroupService,
	logonTypePipe: LogonTypePipe,
	appInsights: AppInsightsService
) {
	var vm = this;

	angular.extend(vm, {
		loadEvents: loadEvents,
		refreshUriAndDownloadPackage: refreshUriAndDownloadPackage,
		alertFromData: alertFromData,
		openHover: openHover,
		openLoggedOnUsers: openLoggedOnUsers,
		openMachineTagsPane: openMachineTagsPane,
		isolate: isolate,
	});

	// handle old links: if url is of old machine page but "new machine page" flag is on, redirect to new machine page
	if (featuresService.isEnabled(Feature.UpgradeMachinePage)) {
		goToNewMachinePage();
		return;
	} else {
		if (
			featuresService._defaultFeatures.has(Feature.UpgradeMachinePage) &&
			!featuresService._defaultFeatures.has(Feature.ShowUpgradeMachinePageToggle) &&
			!featuresService.getLocalFeatureValue(Feature.UpgradeMachinePage)
		) {
			featuresService.clearLocalFeatureValue(Feature.ShowUpgradeMachinePageToggle);
			featuresService.clearLocalFeatureValue(Feature.UpgradeMachinePage);
			goToNewMachinePage();
			return;
		}
	}

	vm.loading = 0;
	vm.tagsLoaded = false;
	vm.events = [];
	vm.query = '';
	vm.telemetryPageSize = 300;
	vm.showUserDetails = false;

	vm.signer = 'All';
	vm.isDemoTenant = demoTenantResolverService.isDemoTenant();
	vm.alertsInvestigationAllowed = authenticationService.isUserActionAllowed([
		RbacMdeAllowedActions.alertsInvestigation,
	]);
	vm.activeRemediationAllowed = authenticationService.isUserActionAllowed([
		RbacMdeAllowedActions.remediationActions,
	]);

	vm.showDeprecatedMessage = featuresService.isEnabled(Feature.ShowDeprecatedMachinePageMessage);
	vm.machineTagsEnabled = true;
	vm.huntingEnabled =
		featuresService.isEnabled(Feature.Hunting) && appConfig.isExposedToAllMachineGroups && false; // disabled for now
	vm.machineRiskScoreEnabled = featuresService.isEnabled(Feature.MachineRiskScore);
	vm.isRbacEnabled = featuresService.isEnabled(Feature.RbacMachineGroups);
	vm.isAutoIRFeatureEnabled = appConfig.isAutomatedIrEnabled;
	vm.navigateToInitateInvestigationLink = urlMapping.investigationsUrls.base.concat(
		urlMapping.investigationsUrls.relatives.create
	);

	vm.machineSecurityStateLoadFailed = false;
	vm.popOverTexts = {
		technicalErr: 'Action is not available. Try refreshing the page.',
		readOnly: 'Action unavailable to read-only accounts',
		RequestPending: 'Action is pending completion...',
		NotSupported: {
			restrictExecution: 'Option unavailable on machines with third-party antivirus software',
			scan: 'Option unavailable for current AV definitions',
		},
	};
	vm.isUserLinkAllowed = false;

	if ($state.params.alert) {
		$state.current.data = $state.current.data || {};
		Object.assign($state.current.data, {
			activeAlertTime: $state.params.time,
			activeAlertId: $state.params.alert || null,
			slider: new Date($state.params.time),
		});
	}

	vm.osDetailsString = '';

	if ($state.current.data && $state.current.data.activeAlertId && $state.current.data.activeAlertTime) {
		$log.debug('Got new alert filter');
		var activeAlertId = $state.current.data.activeAlertId;
		delete $state.current.data.activeAlertId;
		var activeAlertTime = $state.current.data.activeAlertTime;
		delete $state.current.data.activeAlertTime;

		alertFilterService.toggleAlertMode(activeAlertId, activeAlertTime, true);
	}

	showActionCenter();
	loadEntity();

	$rootScope.$on('machineTagsChanged', function(event, data) {
		Array.isArray(data) && !isEmpty(data) && (vm.userDefinedTags = cloneDeep(data)); //check type and that arr is not empty, only then assign new referenced data (clonedeep)
	});

	// listen to "OptIn" feature toggle and navigate to new machine page
	vm.featuresChangeSubsctiption = featuresService.featureChanged$
		.pipe(filter(({ featureId }) => featureId === Feature.UpgradeMachinePage))
		.subscribe((featureId: string) => {
			if (featuresService.isEnabled(Feature.UpgradeMachinePage)) {
				goToNewMachinePage();
			}
		});

	$scope.$on('$destroy', () => {
		vm.featuresChangeSubsctiption && vm.featuresChangeSubsctiption.unsubscribe();
	});

	// VM Methods

	vm.turnOnNewMachinePage = function() {
		appInsights.trackEvent('UI', {
			type: TrackingEventType.Action,
			id: 'TurnOnNewMachinePageFromDeprecatedPageMessage',
			component: 'OldMachinePage',
		});
		featuresService.setLocalFeatureValue(Feature.UpgradeMachinePage, true);
	};

	function goToNewMachinePage() {
		const alertTime = $state.params.time && new Date($state.params.time);
		const alertId = $state.params.alert;

		ng2router.navigate(
			[
				machinesService.getMachineLink(
					(vm.main && vm.main.MachineId) || $state.params.id,
					false,
					alertTime,
					alertId
				),
			],
			{
				queryParams: machinesService.getMachineWithAlertParams({
					lastEventTime: alertTime || new Date(),
					id: alertId,
				}),
			}
		);
	}

	function loadMachineSecurityAnalyticsState(senseMachineId: string) {
		vm.machineSecurityStateLoadFailed = false;
		vm.loadingMachineLatestState = false;
		if (vm.machineIsInactive) {
			$log.debug('skipping secure score, inactive machine');

			return;
		}

		vm.isMachineSecurityScoreEnabled = featuresService.isEnabled(Feature.MachineSecurityScore);
		if (!vm.isMachineSecurityScoreEnabled) {
			return;
		}

		vm.loadingMachineLatestState = true;

		machinesService.getMachineLatestSecurityState(senseMachineId).subscribe(
			(machineSecurityAnalytics: MachineSecurityAnalytics) => {
				vm.machineSecurityAnalytics = machineSecurityAnalytics;
				vm.Score = machineSecurityAnalytics.securityScore;
				vm.MaxScore = machineSecurityAnalytics.maxPotentialSecurityScore;
				vm.loadingMachineLatestState = false;
			},
			error => {
				vm.loadingMachineLatestState = false;
				vm.machineSecurityStateLoadFailed = true;
			}
		);
	}

	function loadEvents(date, sort, searchCriterias) {
		return $q(function(resolve, reject) {
			if (!vm.main) {
				return reject();
			}

			if (sort != 'desc' && sort != 'asc') {
				throw new Error('loading timeline direction argument is invalid');
			}

			$log.debug('loading events timeline with MachineGuid=' + vm.main.WcdMachineId);

			vm.loading++;
			const lookback = vm.isDemoTenant
				? demoTenantResolverService.demoTenantLookback
				: coldStorageConsts.MACHINE_TIMELINE_LOOKBACK;
			let startDate = new Date(date.getTime());
			let endDate = new Date(date.getTime());
			let sortOrder = 'Descending';
			if (sort == 'desc') {
				startDate = coldStorage.overrideStartDateIfBeyondHotData(date, lookback);
				endDate = overrideDateByUrl(endDate);
			} else {
				sortOrder = 'Ascending';
				endDate = coldStorage.overrideEndDateIfBeyondHotData(date, lookback);
				startDate = overrideDateByUrl(startDate);
			}

			const url = urlMapping.getThreatIntelUrl() + '/MachineTimeline';
			const newEvents = [];

			// when loading the page in the first time, search the filters defined in local storage or use the default filter
			if (!vm.fidelity) {
				vm.fidelity = $localStorage.MachineTimelineFidelityFilter || 'All';
			}
			if (!vm.usercontext) {
				vm.usercontext = filtersLocalStorage.getUserContextSavedOrDefault();
			}

			if (!vm.eventTypesFilter) {
				vm.eventTypesFilter = filtersLocalStorage.getEventTypeFilterSavedOrDefault();
			}

			$http
				.post(url, {
					computerDnsName: vm.main.ComputerDnsName,
					pageIndex: 1,
					pageSize: vm.telemetryPageSize,
					fromDate: startDate,
					toDate: endDate,
					sortOrder: sortOrder,
					fidelity: vm.fidelity,
					signerFilter: vm.signer,
					senseMachineId: vm.main.SenseMachineId,
					eventTypesFilter: vm.eventTypesFilter,
					userContextFilter: vm.usercontext,
					clientVersion: vm.main.SenseClientVersion,
					searchCriterias: searchCriterias,
				})
				.then(
					function(response) {
						if (response.status == 200) {
							$log.debug(
								'events timeline for machine with guid=' +
									vm.main.WcdMachineId +
									' loaded successfully'
							);
							const data = response.data;
							if (data.Items) {
								for (let i = 0; i < data.Items.length; i++) {
									const record = data.Items[i];
									if (!record.Sha1 || !RegExpService.sha1.test(record.Sha1)) {
										record.Sha1 = 'Hash could not be retrieved';
										record.invalidHash = true;
									}

									const item = convertRecordToTimelineEvent(record);

									if (item) {
										newEvents.push(item);
									} else {
										logRecordConvertionError(record);
									}
								}
							}

							resolve(newEvents);
						} else if (response.status == 500) {
							vm.error = true;
							reject();
						} else {
							reject();
						}

						vm.loading--;
					},
					function(response) {
						$log.error('Error occur while loading events timeline');
						vm.loading--;
						reject();
					}
				);
		});
	}

	function refreshUriAndDownloadPackage(downloadPackage) {
		investigationPackageService
			.loadInvestigationPackageUri(vm.investigation.RequestGuid, vm.machine.ComputerDnsName)
			.then(
				function(packageUri) {
					vm.main.packageUri = packageUri;

					if (downloadPackage == true && vm.main.packageUri) {
						$log.debug('Opening a new tab to start download a package...');
						$window.open(vm.main.packageUri, '_blank');
					}
				},
				function(packageUri) {
					delete vm.main.packageUri;
				}
			);
	}

	function alertFromData(record) {
		const files: any = {
			Items: [],
		};

		const associatedFiles = [];
		if (record.MonitorContextList && record.MonitorContextList.length > 0) {
			// retrieve all the records with Sha1 and insert them to associatedFiles if their file path is valid.
			const recordsWithSha1 = record.MonitorContextList.filter(function(item) {
				return item.Sha1;
			});
			for (let j = 0; j < recordsWithSha1.length; j++) {
				const mc = recordsWithSha1[j];

				let valid = true;
				if (!mc.Sha1 || !RegExpService.sha1.test(mc.Sha1)) {
					mc.Sha1 = 'Hash could not be retrieved';
					valid = false;
				}

				if (mc.Sha1) {
					if (mc.FileName || mc.FilePath) {
						associatedFiles.push({
							Sha1: mc.Sha1,
							Path: mc.Path || mc.FilePath,
							Name: mc.FileName,
							Valid: valid,
						});
					}
				}
			}

			files.Items = associatedFiles;
			// if there are no valid file paths at all - insert only one sha1 and add file path massage
			if (associatedFiles.length === 0 && recordsWithSha1.length > 0) {
				const associatedFile = recordsWithSha1[0];
				let valid = true;
				if (!associatedFile.Sha1 || !RegExpService.sha1.test(associatedFile.Sha1)) {
					associatedFile.Sha1 = 'Hash could not be retrieved';
					valid = false;
				}

				files.Items.push({
					Sha1: associatedFile.Sha1,
					Path: 'File path could not be retrieved',
					Name: associatedFile.FileName,
					Valid: valid,
				});
			}
		}

		files.TotalResults = files.Items.length;
		const event = {
			time: record.LastEventTime || record.LastSeen,
			type: 'Alert',
			desc: record.Title,
			subtitle: record.Title,
			details: record.FormattedUxDescription,
			id: record.AlertId,
			files: files,
			severity: record.Severity,
			ioaDefinitionId: record.IoaDefinitionId,
			status: record.Status,
		};
		return event;
	}

	function openHover() {
		hoverPaneService.open({
			template: MACHINE_TEMPLATE,
			controller: 'seville.actioncenter.machine',
			controllerAs: 'actioncentermachine',
			title: 'Action center',
			icon: 'F12DevTools',
			size: 'xl',
			resolve: {
				entity: function() {
					return vm.main;
				},
			},
		});
	}

	function openLoggedOnUsers() {
		hoverPaneService.open({
			template: `
			<users-by-logontype loading="users.loading" results="users.loggedOnUsers"></users-by-logontype>
			`,
			controller: 'seville.threatintel.user.loggedonusers',
			controllerAs: 'users',
			title: 'Logged on users (last 30 days)',
			icon: 'Contact',
			size: 'xl',
			resolve: {
				entity: function() {
					return vm.main;
				},
				isUserLinkAllowed: () => {
					return vm.isUserLinkAllowed;
				},
			},
		});
	}

	function openMachineTagsPane() {
		const machineParams = {
			computerDnsName: vm.main.ComputerDnsName,
			internalId: vm.main.InternalMachineId,
			senseMachineId: vm.main.SenseMachineId,
			machineGroup: vm.main.MachineGroup,
		};

		machineClassificationService.openMachineClassficationHoverPane(machineParams);
	}

	function isolate() {
		$modal.open({
			animation: $scope.animationsEnabled,
			template: `
			<div class="modal-header response-confirmation-title">
          <span class="response-confirmation-title-text">
              <span ng-if="machine.IsolationStatus == 'ReverseRequestCompleted'">
              Isolate {{machine.displaystate}} from the <span ng-if="machine.displaystate.length >17"><br /></span>network?</span>
              <span ng-if="machine.IsolationStatus == 'RequestCompleted'">
              Release {{machine.displaystate}} from <span ng-if="machine.displaystate.length >17"><br /></span>isolation?</span>
              <i class="icon icon-Cancel pointer response-confirmation-dismiss-btn" ng-click="close()" tabindex="0"></i>
          </span>
      </div>
      <div class="container">
          <div class="row">
              <div ng-if="machine.IsolationStatus == 'ReverseRequestCompleted'" class="col-md-12 response-confirmation-text response-machine-confirmation-text">
                  This action will isolate the device from the network.<br />
                  It will remain connected to the Microsoft Defender for Endpoint service.<br />
              </div>
              <div ng-if="machine.IsolationStatus == 'RequestCompleted'" class="col-md-12 response-confirmation-text response-machine-confirmation-text">
                  This action will restore this device's connection to the network.
              </div>
          </div>
          <div class="row" ng-if="selectiveIsolationEnabled">
              <div ng-if="machine.IsolationStatus == 'ReverseRequestCompleted'"
              class="col-md-12 response-confirmation-text response-machine-confirmation-text reponse-confirmation-isolation-checkbox-section">
                  <table>
                      <tr>
                          <td class="response-isolation-checkbox-column">
                              <i ng-if="machine.isSelectiveIsolation" ng-click="setIsolationType()"
                              class="icon icon-CheckboxCompositeReversed response-confirmation-checkbox pointer"></i>
                              <i ng-if="!machine.isSelectiveIsolation" ng-click="setIsolationType()"
                               class="icon icon-Checkbox response-confirmation-checkbox-disabled pointer"></i>
                          </td>
                          <td>
                              <div>Allow Outlook and Skype for Business communication while device is isolated</div>
                          </td>
                      </tr>
                  </table>
              </div>
          </div>
          <div class="row">
              <div class="col-sm-12">
                  <span class="response-confirmation-comments-text">Comment: <span
                  class="response-confirmation-asterisk"> *</span></span>
                  <textarea placeholder="Specify why you want to perform this action"
                  class="form-control response-confirmation-comments-box response-confirmation-comments-box-isolate" maxlength="1000"
                  autofocus ng-model="machine.commentText"></textarea>
              </div>
          </div>
          <div class="row">
              <div class="col-sm-12 response-confirmation-info">
                  <span ng-if="machine.IsolationStatus == 'ReverseRequestCompleted'">
                      <i class="icon action-center-pre-comment-icon icon-Error"></i>
                      You can undo device isolation through the Actions menu.
                  </span>
              </div>
          </div>
          <div class="row">
              <div class="col-sm-12 pull-right response-confirmation-buttons">
                  <button ng-if="machine.IsolationStatus == 'ReverseRequestCompleted'"
                  class="btn btn-primary primary-button response-machine-confirmation-btn"
                  ng-disabled="machine.commentText=='' || machine.commentText==undefined" ng-click="isolate()">Yes, isolate device</button>
                  <button ng-if="machine.IsolationStatus == 'RequestCompleted'"
                  class="btn btn-primary primary-button response-machine-confirmation-btn"
                  ng-disabled="machine.commentText=='' || machine.commentText==undefined" ng-click="isolate()">Yes, release device</button>
                  <button class="btn btn-primary normal-button normal-primary-buttons-margin" ng-click="close()">No</button>
              </div>
          </div>
      </div>
			`,
			windowClass: 'response-confirmation-modal',
			controller: 'seville.threatintel.machine.isolate',
			backdrop: false,
			resolve: {
				machine: function() {
					return vm.main;
				},
			},
		});
	}

	vm.applyExecutionPolicy = function() {
		$modal.open({
			animation: $scope.animationsEnabled,
			template: `
			<div class="modal-header response-confirmation-title">
          <span class="response-confirmation-title-text">
              <span ng-if="machine.ExecutionPolicyState == 'ReverseRequestCompleted'">
              Restrict app execution on <span ng-if="machine.displaystate.length >9"><br /></span>{{machine.displaystate}}?</span>
              <span ng-if="machine.ExecutionPolicyState == 'RequestCompleted'">
              Remove app restriction on <span ng-if="machine.displaystate.length >9"><br/></span>{{machine.displaystate}}?</span>
              <i class="icon icon-Cancel pointer response-confirmation-dismiss-btn" ng-click="close()" tabindex="0"></i>
          </span>
      </div>
      <div class="container">
          <div class="row">
              <div ng-if="machine.ExecutionPolicyState == 'ReverseRequestCompleted'" class="col-md-12 response-confirmation-text response-machine-confirmation-text">
                  This action will prevent applications that are not signed by <br />
                  Microsoft from running.
              </div>
              <div ng-if="machine.ExecutionPolicyState == 'RequestCompleted'" class="col-md-12 response-confirmation-text response-machine-confirmation-text">
                  This action will remove execution restrictions on apps that are<br />
                  not signed by Microsoft.
              </div>
          </div>
          <div class="row">
              <div class="col-sm-12">
                  <span class="response-confirmation-comments-text">Comment: <span class="response-confirmation-asterisk"> *</span></span>
                  <textarea placeholder="Specify why you want to perform this action"
                  class="form-control response-confirmation-comments-box response-confirmation-comments-executionpolicy"
                  maxlength="1000" autofocus ng-model="machine.commentText"></textarea>
              </div>
          </div>
          <div class="row">
              <div class="col-sm-12 pull-right response-confirmation-buttons-execution">
                  <button ng-if="machine.ExecutionPolicyState == 'ReverseRequestCompleted'"
                  class="btn btn-primary wcd-width-small-medium" ng-disabled="machine.commentText=='' || machine.commentText==undefined"
                  ng-click="applyExecutionPolicy()">Yes, restrict app execution</button>
                  <button ng-if="machine.ExecutionPolicyState == 'RequestCompleted'"
                  class="btn btn-primary wcd-width-small-medium" ng-disabled="machine.commentText=='' || machine.commentText==undefined"
                  ng-click="applyExecutionPolicy()">Yes, remove restriction</button>
                  <button class="btn btn-primary normal-button normal-primary-buttons-margin" ng-click="close()">No</button>
              </div>
          </div>
      </div>
			`,
			windowClass: 'response-confirmation-modal',
			controller: 'seville.threatintel.machine.executionpolicy',
			backdrop: false,
			resolve: {
				machine: function() {
					return vm.main;
				},
			},
		});
	};

	vm.forensic = function() {
		$modal.open({
			animation: $scope.animationsEnabled,
			template: `
			<div class="response-confirmation-title">
          <div class="response-confirmation-title-text">
              <span>Collect investigation package from <span ng-if="machine.displaystate.length >8"><br /></span>{{machine.displaystate}}?</span>
              <i class="icon icon-Cancel pointer response-confirmation-dismiss-btn" ng-click="close()" tabindex="0"></i>
          </div>
      </div>
        <div class="row">
            <div class="col-md-12 response-confirmation-text response-machine-confirmation-text">
                This action will gather information about the device. Once completed, you can download and view the package.
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <span class="response-confirmation-comments-text">Comment: <span class="response-confirmation-asterisk"> *</span></span>
                <textarea placeholder="Specify why you want to perform this action"
                class="form-control response-confirmation-comments-box response-confirmation-comments-box-forensic"
                maxlength="1000" autofocus ng-model="machine.commentText"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="response-confirmation-buttons-forensic">
                <button class="btn btn-primary primary-button response-machine-confirmation-btn"
                ng-disabled="machine.commentText=='' || machine.commentText==undefined" ng-click="collect()">Yes, collect package</button>
                <button class="btn btn-primary normal-button normal-primary-buttons-margin" ng-click="close()">No</button>
            </div>
        </div>
			`,
			windowClass: 'response-confirmation-modal-forensic',
			controller: 'seville.threatintel.machine.forensic',
			backdrop: false,
			resolve: {
				machine: function() {
					return vm.main;
				},
			},
		});
	};

	vm.avscan = function() {
		$modal.open({
			animation: $scope.animationsEnabled,
			template: `
			<div class="modal-header response-confirmation-title">
          <span class="response-confirmation-title-text">
              <span>Run antivirus scan on <span ng-if="machine.displaystate.length >16"><br /></span>{{machine.displaystate}}?</span>
              <i class="icon icon-Cancel pointer response-confirmation-dismiss-btn" ng-click="close()" tabindex="0"></i>
          </span>
      </div>
      <div class="container">
          <div class="row">
              <div class="col-md-12 response-confirmation-text response-machine-confirmation-text">
				  This action will update
				  <span ng-if="isSenseAvailable">Windows Defender Antivirus definitions<br /></span>
				  <span ng-if="!isSenseAvailable">Antivirus definitions</span>
				  and scan the device.
              </div>
          </div>
          <div ng-if="::isSenseAvailable" class="row">
              <div class="col-sm-12 ">
                  <span class="response-confirmation-table-header-text">Select scan type</span><br />
                  <div class="response-confirmation-avscan-type-section">
                      <div class="response-confirmation-avscan-type">
                          <input type="radio" ng-model="machine.scanType" value="Quick">
                          <span class="response-confirmation-radio-label">Quick scan</span>
                      </div>
                      <div class="response-confirmation-avscan-type">
                          <input type="radio" ng-model="machine.scanType" value="Full">
                          <span class="response-confirmation-radio-label">Full scan</span>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row">
              <div class="col-sm-12">
                  <span class="response-confirmation-comments-text">Comment: <span class="response-confirmation-asterisk"> *</span></span>
                  <textarea placeholder="Specify why you want to perform this action"
                  class="form-control response-confirmation-comments-box response-confirmation-comments-box-avscan"
                  maxlength="1000" autofocus ng-model="machine.commentText"></textarea>
              </div>
          </div>
          <div class="row">
              <div class="col-sm-12 pull-right response-confirmation-buttons-avscan">
                  <button class="btn btn-primary primary-button response-machine-avscan-confirmation-btn"
                  ng-disabled="machine.commentText=='' || !machine.commentText || !machine.scanType ||  vm.machine.scanType == ''"
                  ng-click="avscan()">Yes, run scan</button>
                  <button class="btn btn-primary normal-button normal-primary-buttons-margin" ng-click="close()">No</button>
              </div>
          </div>
      </div>
			`,
			windowClass: 'response-confirmation-modal',
			controller: 'seville.threatintel.machine.avscan',
			backdrop: false,
			resolve: {
				machine: function() {
					return vm.main;
				},
			},
		});
	};

	vm.showMachineIpsPanel = function() {
		machinesService.showMachineIpsPanel(new MachineModel(vm.main));
	};

	vm.showMachineScorePanel = function() {
		machinesService.showMachineScores(vm.machineSecurityAnalytics);
	};

	vm.trackNavigationToAzureAtp = () => {
		appInsights.trackEvent('UsageTrack', {
			ButtonType: 'AzureAtpLink',
			Page: 'Device',
			Component: 'AzureAtpAlerts',
		});
	};

	function loadEntity() {
		let machineIdType = 'Id';

		if (vm.isRbacEnabled) {
			rbacMachineGroupService.getUserExposedRbacGroups().then(response => {
				vm.userExposedRbacGroups = response.data;
			});
		}

		if (
			$state.current.data &&
			$state.current.data.queryIdType == 'Guid' &&
			RegExpService.guid.test($stateParams.id)
		) {
			machineIdType = 'Guid';
			delete $state.current.data.queryIdType;
		} else if (RegExpService.senseMachineIdRegex.test($stateParams.id)) {
			machineIdType = 'SenseMachineId';
		}
		if ($state.current.data && $state.current.data.entity) {
			$log.debug('Entity is already loaded in memory');
			const data = $state.current.data.entity;
			vm.main = data;

			if (vm.isRbacEnabled) {
				// get the rbac group object constructed from name (label) and id from the user exposed groups data.
				// if entity is loaded in memory, then we should have the RbacGroupIds prop inside $state.current.data.entity.
				vm.RbacGroup = rbacMachineGroupService.getRbacGroupObject(
					vm.userExposedRbacGroups.data,
					data.RbacGroupId
				);
			}

			vm.isServer = machinesLogicService.IsServerOS(vm.main.OsPlatform);
			updateInvestigationFlow(data);
			setOsDetailsString();
			setHealthStatusDetails();
			loadMachineTags(vm.main.InternalMachineId);
			loadUserDetailsOnMachine(vm.main.MachineId);
			loadMachineSecurityAnalyticsState(vm.main.MachineId);
			loadMachineAtaProfile(vm.main.ComputerDnsName);
			loadRiskScoreTileDetails();
			delete $state.current.data.entity;
		} else {
			$log.debug('loading machine with id: ' + $stateParams.id);
			const url = urlMapping.getThreatIntelUrl() + '/machines';

			$http
				.get(url, {
					params: {
						machineId: $stateParams.id,
						lookingBackIndays: 180,
						idType: machineIdType,
					},
				})
				.then(
					function(response) {
						if (response.status == 404) {
							ng2router.navigate(['/errors/MachineNotFound'], {
								queryParams: { itemId: $stateParams.id },
							});
						} else if (response.status == 200) {
							vm.main = response.data;
							if (vm.isRbacEnabled && vm.userExposedRbacGroups) {
								// match between /machine response groupId and the userexposed groupIds
								vm.RbacGroup = rbacMachineGroupService.getRbacGroupObject(
									vm.userExposedRbacGroups.data,
									response.data.RbacGroupId
								);
							}
							vm.isServer = machinesLogicService.IsServerOS(vm.main.OsPlatform);
							if (vm.isServer && vm.main.serverRoles) {
								isArray(vm.main.serverRoles) &&
									(vm.main.serverRoles = vm.main.serverRoles.join(', '));
							}
							setHealthStatusDetails();
							setOsDetailsString();
							handleResponseFlow();
							loadMachineTags(vm.main.InternalMachineId);
							loadUserDetailsOnMachine(vm.main.MachineId);
							loadMachineSecurityAnalyticsState(vm.main.MachineId);
							loadMachineAtaProfile(vm.main.ComputerDnsName);
							loadRiskScoreTileDetails();
							updateInvestigationFlow(response.data);
						} else if (response.status != -1) {
							ng2router.navigate(['/errors']);
						}
					},
					function(response) {
						$log.debug('entity loading failed');
						ng2router.navigate(['/errors']);
					}
				);
		}
	}

	function logRecordConvertionError(record) {
		if (
			record &&
			record.EtwEventType &&
			!genericEtwService.isEtwSupported(genericEtwService.getEtwEventType(record))
		) {
			$log.debug('unsupported etw type = ' + record.EtwEventType);
		} else {
			$log.error('failed converting record to timeline event');
		}
	}

	function convertRecordToTimelineEvent(record) {
		const item: any = {
			time: record.Timestamp,
			desc: record.Description,
			sha1: record.Sha1,
			filename: record.FileName,
			path: record.RealPath,
			valid: !record.invalidHash,
			signer: record.Signer,
			isValidCertificate: record.IsValidCertificate,
			associatedAlertIds: record.AssociatedAlertIds,
			isParentProcessAbsent: record.IsParentProcessAbsent,
			machineId: record.SenseMachineId,
			containerId: record.ContainerId,
			json: record,
		};

		item.files = {
			TotalResults: 1,
			Items: [
				{
					Sha1: record.Sha1,
					Path: record.RealPath,
					Name: record.FileName,
					Valid: !record.invalidHash,
					Signer: record.Signer,
				},
			],
		};

		switch (record.Type) {
			case 'FileEvent':
				item.link = record.LinkToMicrosoftEncyclopedia;
				item.persistedParentFileFullPath = record.PersistedParentFileFullPath;
				item.computerDnsName = record.ComputerDnsName;
				item.wcdMachineId = record.WcdMachineId;
				(item.senseMachineId = record.SenseMachindId),
					(item.MachineId = record.MachineId),
					(item.isDetection = record.IsDetection),
					(item.isActive = record.WasExecutingWhileDetected),
					(item.isRemediated = record.WasRemediated),
					(item.isBlockAction = record.IsBlockAction),
					(item.type = 'File');
				break;
			case 'IpEvent':
				item.ipsCount = record.IpsCount;
				item.moreIPs = record.MoreIpsExist;
				item.ips = record.DestinationIps;
				item.type = 'Network';
				break;
			case 'FileCreateEvent':
				item.processSha1Valid = true;
				if (!record.ProcessSha1 || !RegExpService.sha1.test(record.ProcessSha1)) {
					record.ProcessSha1 = 'Hash could not be retrieved';
					item.processSha1Valid = false;
				}

				item.parentProcessName = record.ParentProcessName;
				item.processName = record.ProcessName;
				item.processSha1 = record.ProcessSha1;
				item.processCommand = record.ProcessCommandLine;
				item.processCreationTime = record.ProcessCreationTime;
				item.processPath = record.ProcessPath;
				item.associatedSha1AndFileNames = record.AssociatedSha1AndFileNames;
				item.associatedSha1AndFileNamesCount = Object.keys(record.AssociatedSha1AndFileNames).length;
				item.processId = record.ProcessId;
				item.accountName = userAccountService.convertAccountNameBySid(
					record.ProcessAccountName,
					record.ProcessAccountSid
				);
				item.accountDomainName = record.ProcessAccountDomainName;
				item.accountSid = record.ProcessAccountSid;
				item.type = 'FileCreate';
				break;
			case 'NetworkConnectionsEvent':
				item.processSha1Valid = true;
				if (!record.ProcessSha1 || !RegExpService.sha1.test(record.ProcessSha1)) {
					record.ProcessSha1 = 'Hash could not be retrieved';
					item.processSha1Valid = false;
				}
				item.parentProcessName = record.ParentProcessName;
				item.processName = record.ProcessName;
				item.processSha1 = record.ProcessSha1;
				item.processCommand = record.ProcessCommandLine;
				item.processCreationTime = record.ProcessCreationTime;
				item.processPath = record.ProcessPath;
				item.destinationIps = record.DestinationIpsList;
				item.destinationIpsCount = record.DestinationIpsList.length;
				item.destinationIp = record.DestinationIpsList[0];
				item.processId = record.ProcessId;
				item.accountName = userAccountService.convertAccountNameBySid(
					record.ProcessAccountName,
					record.ProcessAccountSid
				);
				item.accountDomainName = record.ProcessAccountDomainName;
				item.accountSid = record.ProcessAccountSid;
				item.destinationDnsRecordNames = record.DestinationDnsRecordNameList;
				item.protocols = record.ProtocolsList;
				item.destinationPorts = record.DestinationPortsList;
				item.destinationDnsRecordName = record.DestinationDnsRecordNameList[0];
				item.protocol = record.ProtocolsList[0];
				item.destinationPort = record.DestinationPortsList[0];
				item.networkEventType = record.NetworkEventType;
				item.description = mapNetworkEventTypeToMessage(record.NetworkEventType);
				item.type = 'NetworkEvent';
				// Adding lable to process tree only for inbound network connection
				item.notDirectChild = record.NetworkEventType === 'InboundTcpConnectionAccepted';
				break;
			case 'RegistryEvent':
				item.processSha1Valid = true;
				if (!record.ProcessSha1 || !RegExpService.sha1.test(record.ProcessSha1)) {
					record.ProcessSha1 = 'Hash could not be retrieved';
					item.processSha1Valid = false;
				}

				if (!record.ParentProcessSha1 || !RegExpService.sha1.test(record.ParentProcessSha1)) {
					record.ParentProcessSha1 = 'Hash could not be retrieved';
					item.parentProcessSha1Valid = false;
				}
				item.parentProcessName = record.ParentProcessName;
				item.processName = record.ProcessName;
				item.processSha1 = record.ProcessSha1;
				item.processCommand = record.ProcessCommandLine;
				item.processCreationTime = record.ProcessCreationTime;
				item.processPath = record.ProcessPath;
				item.registryEvents = record.RegistryEventsList;
				item.registryEventsCount = record.RegistryEventsList.length;
				item.registryEvent = record.RegistryEventsList[0];
				item.registryFullKey = record.RegistryNewValue_FullRegistryKey;
				item.processId = record.ProcessId;
				item.accountName = userAccountService.convertAccountNameBySid(
					record.ProcessAccountName,
					record.ProcessAccountSid
				);
				item.accountDomainName = record.ProcessAccountDomainName;
				item.accountSid = record.ProcessAccountSid;
				item.type = 'RegistryEvent';
				break;
			case 'BehaviorEvent':
				item.processSha1Valid = true;
				if (!record.CreatorProcessSha1 || !RegExpService.sha1.test(record.CreatorProcessSha1)) {
					record.CreatorProcessSha1 = 'Hash could not be retrieved';
					item.processSha1Valid = false;
				}
				item.parentProcessName = record.CreatorProcessParentProcessName;
				item.processName = record.CreatorProcessName;
				item.processSha1 = record.CreatorProcessSha1;
				item.processCreationTime = record.CreatorProcessCreationTime;
				item.accountDomainName = record.CreatorProcessAccountDomainName;
				item.accountName = userAccountService.convertAccountNameBySid(
					record.CreatorProcessAccountName,
					record.CreatorProcessAccountSid
				);
				item.accountSid = record.CreatorProcessAccountSid;
				item.singularFormat = record.SingularFormat;
				item.pluralFormat = record.PluralFormat;
				item.tableName = record.TableName;
				item.reportIndexCount = record.ReportIndexCount;
				item.reportIndexList = record.ReportIndexList;
				item.processId = record.CreatorProcessProcessId;
				item.machineId = record.MachineId;
				item.senseMachineId = record.SenseMachineId;
				item.wcdMachineId = record.WcdMachineId;
				item.binCreationTime = record.BinCreationTime;
				item.enableO365Enrichment = record.EnableO365Enrichment;
				item.sha256 = record.FileSha256;
				item.detectionRelatedBehavior = record.DetectionRelatedBehavior;
				item.minReportEventTime = record.MinReportEventTime;
				item.maxReportEventTime = record.MaxReportEventTime;
				item.isActive = record.WasExecutingWhileDetected;
				item.type = 'BehaviorEvent';
				if (record.TableName == 'ProcessCreateBehaviors') {
					item.createdEntityDesc = item.reportIndexCount > 1 ? 'processes' : 'process';
				} else if (record.TableName == 'FileCreateBehaviors') {
					item.createdEntityDesc = item.reportIndexCount > 1 ? 'files' : 'file';
				} else if (record.TableName == 'NetworkConnectionBehaviors') {
					item.createdEntityDesc = item.reportIndexCount > 1 ? 'IPs' : 'IP';
				}
				if (record.DetectionRelatedBehavior) {
					item.isRemediated = false;
				}
				break;
			case 'ProcessCreateEvent':
				item.processSha1Valid = true;
				if (!record.ProcessSha1 || !RegExpService.sha1.test(record.ProcessSha1)) {
					record.ProcessSha1 = 'Hash could not be retrieved';
					item.processSha1Valid = false;
				}

				item.parentProcessName = record.ParentProcessName;
				item.processName = record.ProcessName;
				item.processSha1 = record.ProcessSha1;
				item.processCommand = record.CreatorCommandLine;
				item.processCreationTime = record.ProcessCreationTime;
				item.processPath = record.ProcessPath;
				item.processId = record.CreatorProcessId;
				item.createdProcessId = record.ProcessId;
				item.createdProcessCommand = record.ProcessCommandLine;
				item.accountName = userAccountService.convertAccountNameBySid(
					record.ProcessAccountName,
					record.ProcessAccountSid
				);
				item.accountDomainName = record.ProcessAccountDomainName;
				item.accountSid = record.ProcessAccountSid;
				item.integrityLevel = record.CreatedProcessIntegrityLevel;
				item.tokenElevationType = record.CreatedProcessTokenElevationType;
				item.type = 'ProcessCreate';
				break;
			case 'EtwGenericEvent':
				item.etwType = genericEtwService.getEtwEventType(record);
				if (!genericEtwService.isEtwSupported(item.etwType)) {
					return null;
				}
				item.fullEtwType = record.EtwEventType;
				item.etwJsonProperties = record.EventPropertiesAsJson ? record.EventPropertiesAsJson : {};
				item.type = 'EtwGenericEvent';
				item.processSha1Valid = true;

				genericEtwService.setEtwEventPropertiesFromRecord(item, record);

				if (!item.processSha1 || !RegExpService.sha1.test(item.processSha1)) {
					item.processSha1 = 'Hash could not be retrieved';
					item.processSha1Valid = false;
				}
				item.accountName = userAccountService.convertAccountNameBySid(
					record.InitiatingProcess_Account_Name,
					record.InitiatingProcess_Account_Sid
				);
				item.accountDomainName = record.InitiatingProcess_Account_DomainName;
				item.accountSid = record.InitiatingProcess_Account_Sid;
				item.Ip = item.etwJsonProperties.RemoteAddress;
				item.Port = item.etwJsonProperties.RemotePort;

				item.TargetProcess_Account_Name = record.TargetProcess_Account_Name;
				item.TargetProcess_Account_DomainName = record.TargetProcess_Account_DomainName;
				item.Uri = record.Uri || item.etwJsonProperties.Uri;
				// note that the below method calls require the properties to be defined above
				item.desc = genericEtwService.getEtwMachineTimelineDescription(item);
				item.descriptionPrefix = genericEtwService.getEtwMachineTimelineDescriptionPrefix(item);
				item.descriptionSuffix = genericEtwService.getEtwMachineTimelineDescriptionSuffix(item);
				item.notDirectChild =
					genericEtwService.getEtwMachineProcessTreeUndirectChildRemoteText(item) != null;
				item.createdProcessCommand =
					item.etwJsonProperties.TargetProcess && item.etwJsonProperties.TargetProcess.CommandLine
						? item.etwJsonProperties.TargetProcess.CommandLine
						: null;
				item.icon = genericEtwService.getEtwIcons(item.etwType).timelineRowIcon;
				item.hideInitiatingProcessNameInDescription = genericEtwService.getEtwMachineTimelineHideInitiatingProcessInDescription(
					item
				);
				item.hideUriInDescription = genericEtwService.getEtwMachineTimelineHideUriInDescription(
					item.etwType
				);
				item.isRemediated = item.etwJsonProperties ? item.etwJsonProperties.WasRemediated : null;
				item.isActive = item.etwJsonProperties
					? item.etwJsonProperties.WasExecutingWhileDetected
					: null;
				item.isProcessTreeDisabled = genericEtwService.isProcessTreeDisabled(item);
				item.elementsToDisplayWhenProcessTreeDisabled = genericEtwService.getEtwNoProcessTreeElements(
					item
				);
				item.detailsColumnData = genericEtwService.getEtwDetailsColumnData(item);
				item.detailsColumnSha = genericEtwService.getEtwMachineProcessTreeDetailsColumnDataSha(item);

				break;
			case 'LoadImageEvent':
				item.processSha1Valid = true;
				if (!record.ProcessSha1 || !RegExpService.sha1.test(record.ProcessSha1)) {
					record.ProcessSha1 = 'Hash could not be retrieved';
					item.processSha1Valid = false;
				}

				item.parentProcessName = record.ParentProcessName;
				item.processName = record.ProcessName;
				item.processSha1 = record.ProcessSha1;
				item.processCreationTime = record.ProcessCreationTime;
				item.processPath = record.ProcessPath;
				item.processId = record.ProcessId;
				item.accountName = userAccountService.convertAccountNameBySid(
					record.ProcessAccountName,
					record.ProcessAccountSid
				);
				item.accountDomainName = record.ProcessAccountDomainName;
				item.accountSid = record.ProcessAccountSid;
				item.type = 'LoadImage';
				break;

			case 'Response':
				item.requestorName = record.RequestorName;
				item.requestorComment = record.RequestorComment;
				item.status = record.RequestStatus;
				item.responseType = record.ResponseType;
				item.responseText = resolveResponseText(record);
				(item.ErrorCode = record.ErrorCode),
					(item.displayErrorCode = shouldErrorCodeDisplayed(record)),
					(item.submittedBy = record.SubmittedBy);
				item.type = record.Type;
				item.sha256 = record.Sha256;
				item.remediationInstances = record.RemediationInstances;
				item.requestGuid = record.RequestGuid;
				item.displayAttentionIcon = isAttentionIconEnabled(record);
				break;

			case 'ScanEvent':
				item.processSha1Valid = true;
				if (!record.Sha1 || !RegExpService.sha1.test(record.Sha1)) {
					record.Sha1 = 'Hash could not be retrieved';
					item.processSha1Valid = false;
				}

				item.fileName = record.FileName;
				item.sha1 = record.Sha1;
				item.realPath = record.RealPath;
				item.Sha1AndFileNamesCount = 1;
				item.type = 'ScanEvent';
				item.detectionType = record.DetectionType;
				item.icon = item.detectionType === 'Threat' ? 'icon-Bug' : 'icon-Page';
				item.detectedOrObservedOnMachine =
					(item.detectionType === 'Threat' ? 'detected' : 'observed') + ' on device';
				break;

			case 'LogonEvent':
				item.processName =
					record.InitiatingProcess_ProcessName || record.InitiatingProcess_File_FileName;
				item.processId = record.InitiatingProcess_ProcessId;
				item.processCreationTime = record.InitiatingProcess_CreationTime;
				item.processCommand = record.InitiatingProcess_CommandLine;
				item.processPath = record.InitiatingProcess_File_FilePath;
				item.parentProcessName = record.InitiatingProcess_ParentProcessName;
				item.processSha1 = record.InitiatingProcess_File_Sha1;
				item.processSha1Valid = true;
				if (!item.processSha1 || !RegExpService.sha1.test(item.processSha1)) {
					item.processSha1 = 'Hash could not be retrieved';
					item.processSha1Valid = false;
				}
				item.accountName = userAccountService.convertAccountNameBySid(
					record.AccountName,
					record.AccountSid
				);
				item.accountDomainName = record.AccountDomainName;
				item.accountSid = record.AccountSid;
				item.remoteComputerName = record.RemoteComputerName;
				item.logonType = record.LogonType;
				item.logonId = record.LogonId;
				item.actionType = record.EtwEventType;
				item.remoteIp = record.RemoteIp;
				item.remotePort = record.RemotePort;
				item.isLocalLogon = record.IsLocalLogon;
				item.logonTypeDisplayName = logonTypePipe.transform(item.logonType, true);
				item.type = 'LogonEvent';
				break;

			default:
				if (record.AlertId) {
					return vm.alertFromData(record);
				} else {
					item.type = 'BUGGGG';
				}
		}

		const allowUserLinkByOsPlatform =
			!vm.alert.OsPlatform ||
			(vm.alert.OsPlatform.toLowerCase() !== 'linux' && vm.alert.OsPlatform.toLowerCase() !== 'macos');

		item.isAllowedAccount =
			allowUserLinkByOsPlatform &&
			userAccountService.isAllowedAccount(
				item.accountName,
				item.accountDomainName,
				featuresService.isEnabled('User-Links')
			);

		if (item.type === 'LogonEvent' && item.actionType === CyberEventActionTypeName.LogonFailed) {
			item.isAllowedAccount = false;
		}

		item.fullAccountNameToDisplay = item.accountName;
		if (item.containerId) {
			item.fullAccountNameToDisplay =
				item.containerId + (item.accountName ? '\\' + item.accountName : '');
		}

		return item;
	}

	function loadMachineTags(machineId) {
		if (!vm.machineTagsEnabled) {
			vm.userDefinedTags = null;
			vm.tagsLoaded = true;
			return;
		}

		machineClassificationService.getTagsForMachine(machineId).then(
			function(result) {
				vm.userDefinedTags = result.UserDefinedTags;
				vm.tagsLoaded = true;
				return;
			},
			function() {
				vm.userDefinedTags = null;
				vm.tagsLoaded = true;
				return;
			}
		);
	}

	function resolveResponseText(record) {
		let responseText = '';
		let action = '';
		let policy = '';
		const requestStatus = record.RequestStatus.toLowerCase();

		switch (record.ResponseType) {
			case 'Forensic':
				if (requestStatus == 'succeeded') {
					responseText = 'Investigation package collected';
				} else {
					responseText = 'Investigation package collection ' + requestStatus;
				}
				break;
			case 'Scan':
				const scanType = record.AvScanType ? record.AvScanType + ' ' : '';

				if (requestStatus == 'succeeded') {
					responseText = scanType + 'antivirus scan submitted. Check malware alerts for detections';
				} else {
					responseText = scanType + 'antivirus scan ' + requestStatus;
				}
				break;
			case 'Isolation':
				action = record.IsolationAction.toLowerCase();

				if (requestStatus == 'succeeded' && action == 'unisolate') {
					responseText = 'Release from isolation submitted. Network connection will be restored';
				} else if (requestStatus == 'succeeded' && action == 'isolate') {
					responseText = 'Machine isolation submitted. ';

					if (record.IsolationType.toLocaleLowerCase() == 'selective') {
						responseText += 'Microsoft Defender for Endpoint, Skype and Outlook connectivity maintained';
					} else {
						responseText += 'Connection to Microsoft Defender for Endpoint service maintained';
					}
				} else {
					if (action == 'isolate') {
						responseText =
							'Machine isolation' + record.IsolationType.toLocaleLowerCase() == 'selective'
								? ' with Skype and Outlook exceptions '
								: ' ';
					} else {
						responseText = 'Release from isolation ';
					}

					responseText += requestStatus;
				}
				break;
			case 'Remediation':
				if (requestStatus == 'succeeded') {
					responseText = 'quarantined';
				} else {
					responseText = 'quarantine ' + requestStatus;
				}
				break;
			case 'ExecutionPolicy':
				policy = record.ExecutionPolicyType.toString().toLowerCase();

				if (policy == 'restrict') {
					if (requestStatus == 'succeeded') {
						responseText =
							'App restriction submitted. Applications that are not signed by Microsoft will be prevented from running';
					} else {
						responseText = 'App restriction ' + requestStatus;
					}
				} else {
					if (requestStatus == 'succeeded') {
						responseText =
							'App restriction removal submitted. App restrictions will no longer be enforced';
					} else {
						responseText = 'App restriction removal ' + requestStatus;
					}
				}
				break;
			default:
				responseText = record.ResponseType + ' ' + record.RequestStatus.toLowerCase();
		}

		return responseText;
	}

	function isAttentionIconEnabled(record) {
		return (
			record.IsolationAction == 'Isolate' &&
			record.RequestStatus == 'Succeeded' &&
			record.IsolationType == 'Selective'
		);
	}

	function shouldErrorCodeDisplayed(record) {
		// Display errorCode in case that:
		return (
			record.ErrorCode &&
			(record.RequestStatus === 'Failed' || // request failed
				(record.ResponseType === 'Scan' &&
					(record.ErrorCode === 4317 || record.ErrorCode === -2147020579)))
		); // or AV Scan was triggered once previous scan is still running
	}

	function loadUserDetailsOnMachine(machineId) {
		$log.debug('loading user data for machine with id: ' + machineId);
		const url = urlMapping.getThreatIntelUrl() + '/UserDetailsOnMachine';
		vm.loadingUserDetails = true;

		// links to user page are not available on windows 7 machines.
		vm.isUserLinkAllowed = vm.main.OsPlatform ? vm.main.OsPlatform.toLowerCase() !== 'windows7' : true;

		$http
			.get(url, {
				params: {
					senseMachineId: machineId,
					lookingBackIndays: 30,
				},
			})
			.then(
				function(response) {
					if (response.status == 200) {
						vm.userDetails = response.data;
						vm.showUserDetails = true;
						vm.loadingUserDetails = false;
					} else if (response.status == 404) {
						$log.debug('user details were not found on the machine');
						vm.showUserDetails = false;
						vm.loadingUserDetails = false;
					} else if (response.status == 500) {
						$log.debug(' internal server error - user details on the machine');
						vm.showUserDetails = false;
						vm.loadingUserDetails = false;
					}

					vm.mostPrevalentUserDisplay = `${vm.userDetails.MostPrevalentAccountDomainName}\\${
						vm.userDetails.MostPrevalentAccountName
					}`;
					vm.leastPrevalentUserDisplay = `${vm.userDetails.LeastPrevalentAccountDomainName}\\${
						vm.userDetails.LeastPrevalentAccountName
					}`;
				},
				function(response) {
					$log.debug('error loading user details on machine');
					vm.showUserDetails = false;
					vm.loadingUserDetails = false;
				}
			);
	}

	function loadMachineAtaProfile(computerName) {
		const url = urlMapping.getThreatIntelUrl() + '/ti/aatp/AtaMachineSummary';
		vm.loadingAtaDetails = true;

		const getAtaAdvancedStatusPromise = updateIsAtaSettingEnabled();
		getAtaAdvancedStatusPromise.then(function() {
			if (!vm.ataAdvancedSettingEnabled) {
				vm.loadingAtaDetails = false;
				return;
			}

			$http
				.get(url, {
					params: {
						computerDnsName: computerName,
					},
				})
				.then(
					function(response) {
						if (response.status == 200) {
							vm.ataProfile = response.data;
							if (vm.ataProfile.SecurityAlertSummary) {
								vm.ataProfile.TotalAlerts =
									vm.ataProfile.SecurityAlertSummary.HighCount +
									vm.ataProfile.SecurityAlertSummary.MediumCount +
									vm.ataProfile.SecurityAlertSummary.LowCount;
								vm.ataProfile.alertDisplayString =
									vm.ataProfile.TotalAlerts === 1 ? 'Alert' : 'Alerts';
							} else {
								vm.ataProfile.TotalAlerts = 0;
							}
							vm.loadingAtaDetails = false;
						} else if (response.status == 404) {
							$log.debug('ATA details were not found for the machine');
							vm.loadingAtaDetails = false;
						} else if (response.status == 500) {
							$log.debug('internal server error response from ATA for the machine');
							vm.loadingAtaDetails = false;
						} else {
							$log.debug(
								'error while getting ATA for the machine, status =  ' + response.status
							);
							vm.loadingAtaDetails = false;
						}
					},
					function(response) {
						$log.debug('error loading ATA details for the machine');
						vm.loadingAtaDetails = false;
					}
				);
		});
	}

	function loadRiskScoreTileDetails() {
		vm.loadingRiskScoreDetails = true;
		vm.main.riskScoreDetails = riskScoreService.getRiskScoreDetails(vm.main.RiskScore);

		loadActiveAlertsSummaryForMachine();
	}

	function loadActiveAlertsSummaryForMachine() {
		$log.debug('loading alerts, for machine with machine id:' + vm.main.MachineId);

		const alertsUrl = appConfig.serviceUrls.activeAlertsSummary + '/activeAlertsSummary';
		$http
			.get(alertsUrl, {
				params: {
					WcdMachineId: vm.main.MachineId,
					lookingBackInDays: 180,
					status: 'New,InProgress',
				},
			})
			.then(
				function(response) {
					if (response.status == 200) {
						vm.main.alertsSummary = {
							HighSevAlerts: response.data.HighSevAlerts,
							MediumSevAlerts: response.data.MediumSevAlerts,
							LowSevAlerts: response.data.LowSevAlerts,
							InformationalSevAlerts: response.data.InformationalSevAlerts,
						};
					} else {
						$log.error('ActiveAlertsSummary could not be retrieved. Status =' + response.status);
					}

					vm.loadingRiskScoreDetails = false;
				},
				function(response) {
					vm.loadingRiskScoreDetails = false;
					$log.error('ActiveAlertsSummary could not be retrieved.');
				}
			);
	}

	function handleResponseFlow() {
		vm.main.isResponseSupported = isResponseFeatureSupported();

		if (!vm.main.isResponseSupported) {
			return;
		}

		isResponseFeatureEnabled().then(
			function(isEnabled) {
				if (!isEnabled) {
					$log.debug('Response features are not enabled');
					vm.main.isResponseSupported = false;
					return;
				}

				enableResponseFeature();
			},
			function(response) {
				$log.error('Error occur while permissions');
				vm.main.isResponseSupported = false;
				return;
			}
		);
	}

	function getPermission(permissions, actionName, currentStatus) {
		const permission = getResponseByType(permissions, actionName);
		if (permission) {
			if (permission.Result == ResponseActionPermissionResult.Unknown) {
				return currentStatus;
			}
			return currentStatus && permission.Result == ResponseActionPermissionResult.Allowed;
		} else {
			return false;
		}
	}

	function isResponseFeatureEnabled() {
		const deferred = $q.defer();

		vm.main.responseFeauresStatus = {
			isolation: featuresService.isEnabled('Response-Isolation'),
			forensic: featuresService.isEnabled('Response-Forensic'),
			scan: featuresService.isEnabled('Response-Scan'),
			executionPolicy: featuresService.isEnabled('Response-ExecutionPolicy'),
		};

		const responseFeatureEnabled = isAllFeaturesEnabled();

		if (!responseFeatureEnabled) {
			deferred.resolve(responseFeatureEnabled);
		}

		$http
			.post(appConfig.serviceUrls.userRequests + '/requests/permissions', {
				machineId: vm.main.SenseMachineId,
				osPlatform: vm.main.OsPlatform,
				senseClientVersion: vm.main.SenseClientVersion,
			})
			.then(
				function(response) {
					if (response.status == 200 && response.data && response.data.Permissions) {
						vm.main.responseFeauresStatus.isolation = getPermission(
							response.data.Permissions,
							'IsolationRequest',
							vm.main.responseFeauresStatus.isolation
						);
						vm.main.responseFeauresStatus.scan = getPermission(
							response.data.Permissions,
							'ScanRequest',
							vm.main.responseFeauresStatus.scan
						);
						vm.main.responseFeauresStatus.executionPolicy = getPermission(
							response.data.Permissions,
							'RestrictExecutionRequest',
							vm.main.responseFeauresStatus.executionPolicy
						);
						vm.main.responseFeauresStatus.forensic = getPermission(
							response.data.Permissions,
							'ForensicsRequest',
							vm.main.responseFeauresStatus.forensic
						);

						deferred.resolve(isAllFeaturesEnabled());
						return;
					}

					deferred.resolve(false);
				},
				function(response) {
					$log.error('Error occur while loading events timeline');
					deferred.reject(response);
				}
			);

		return deferred.promise;
	}

	function isResponseFeatureSupported() {
		if (!vm.main || !vm.main.OsPlatform || vm.main.OsPlatform === null) {
			$log.debug('Response feature is not enabled because the OS platform is not available');
			return false;
		}

		const osPlatform = vm.main.OsPlatform.toLowerCase();
		vm.main.isRs4 = RegExpService.rs4.test(vm.main.SenseClientVersion);

		// Servers - RS4 and on
		if (
			osPlatform === 'windows2016' ||
			osPlatform === 'windowsserver2016' ||
			osPlatform === 'windowsserver2019'
		) {
			// Response action is supported for version 10.4000.0.0 and above
			return isValidVersion(10, 4000);
		}

		if (osPlatform === 'windows10') {
			// Response action is supported for version 10.2930.0.0 and above
			return isValidVersion(10, 2930);
		}

		if (osPlatform === 'linux' || osPlatform === 'macos') {
			return true;
		}

		$log.debug(
			'Response feature is not enabled because the OS platform does not support this feature. OS platform: ' +
				osPlatform
		);
		return false;
	}

	function isValidVersion(major: number, minor: number) {
		if (!vm.main.SenseClientVersion) {
			$log.debug('Response feature is not enabled since machine has no sense client version');
			return false;
		}

		const parts = vm.main.SenseClientVersion.split('.');
		if (parts.length !== 4) {
			$log.debug(
				'Response feature is not enabled since sense client version is invalid. sense client version: ' +
					vm.main.SenseClientVersion
			);
			return false;
		}

		if (parts[0] > major || (parts[0] == major && parts[1] >= minor)) {
			return true;
		}

		$log.debug(
			'Response feature is not enabled since sense client version is below 10.2930.0.0. sense client version: ' +
				vm.main.SenseClientVersion
		);
		return false;
	}

	function isAllFeaturesEnabled() {
		return (
			vm.main.responseFeauresStatus.isolation ||
			vm.main.responseFeauresStatus.forensic ||
			vm.main.responseFeauresStatus.scan ||
			vm.main.responseFeauresStatus.executionPolicy
		);
	}

	function enableResponseFeature() {
		$log.debug('Response feature is enabled with sense client version ' + vm.main.SenseClientVersion);

		// get the latest forensics collection and display the download link if exists
		if (vm.main.responseFeauresStatus.forensic) {
			loadInvestigationStatus();
		}

		// Check if any response action was ever preformed on the machine
		validateResponseActionExists();

		const versionElements = vm.main.SenseClientVersion.split('.');

		if (versionElements[1] < 3630) {
			vm.main.responseFeauresStatus.executionPolicy = false;
			$log.debug(
				'Execution retriction feature is not enabled since sense client version is below 10.3630.0.0. sense client version: ' +
					vm.main.SenseClientVersion
			);
		}

		loadMachineResponseActionStatus();

		// reload machine isloation status every 1 minutes
		const intervalId = $interval(loadMachineResponseActionStatus, 1 * 60000);

		$scope.$on('$destroy', function() {
			$log.debug('Destory loading machine response status task');
			// Make sure that the interval is destroyed too
			$interval.cancel(intervalId);
		});
	}

	function loadInvestigationStatus() {
		const url = appConfig.serviceUrls.userRequests + '/requests/latest';
		$http
			.get(url, {
				params: {
					machineId: vm.main.SenseMachineId,
					onlySuccessful: true,
				},
			})
			.then(
				function(response) {
					if (response.status == 200) {
						const investigationData = getResponseByType(response.data, 'ForensicsResponse');
						if (investigationData) {
							vm.investigation = investigationData;

							if (vm.investigation.RequestStatus == 'Succeeded') {
								vm.refreshUriAndDownloadPackage(false);

								vm.main.LastUpdateTimeUtc = vm.investigation.LastUpdateTimeUtc;
							}
							if (
								vm.investigation.RequestStatus == 'Succeeded' &&
								!vm.investigation.packageUri
							) {
							}
						}
					} else if (response.status == 404) {
						$log.debug('investigation collection was not found');
					} else {
						$log.debug('investigation collection loading failed');
					}
				},
				function(response) {
					$log.debug('investigation collection loading failed');
				}
			);
	}

	function getResponseByType(data, responseType) {
		if (Array.isArray(data)) {
			return data.filter(function(r) {
				return r.Type === responseType;
			})[0];
		}

		return data.Type == responseType ? data : null;
	}

	function updateInvestigationFlow(data) {
		if (!data || data.length <= 1) {
			data = {};
		}

		data.Id = data.MachineId;
		data.icon = 'icon-DeviceLaptopNoPic';
		data.displaystate = data.ComputerDnsName ? $filter('parts')(data.ComputerDnsName, 1) : 'Device';
		data.state = 'machine';
		investigation.setActive(data);

		if (!breadcrumbsStateService.breadcrumbs$.value.length) {
			breadcrumbsStateService.add({
				id: `machines`,
				label: 'Machines',
				url: '/machines',
				queryParams: {},
			});
		}
		breadcrumbsStateService.add({
			id: `machine_${data.MachineId}`,
			label: data.displaystate,
			url: $state.href($state.current),
			queryParams: $state.params,
		});
	}

	function validateResponseActionExists() {
		// Check if any response action was ever preformed on the machine
		$http
			.get(appConfig.serviceUrls.userRequests + '/requests/machine/any', {
				params: {
					machineId: vm.main.SenseMachineId,
				},
			})
			.then(function(response) {
				if (response.status == 200) {
					vm.main.ResponseActionExists = response.data;
				}
			});
	}

	function loadMachineResponseActionStatus() {
		const machineStatePromise = $http.get(appConfig.serviceUrls.userRequests + '/requests/machinestate', {
			params: {
				machineId: vm.main.SenseMachineId,
			},
		});

		machineStatePromise.then(
			function(response) {
				if (response.status == 200) {
					if (vm.main.responseFeauresStatus.isolation) {
						// Check machine isolation status if it wasn't queried yet or status is pending
						if (
							!vm.main.IsolationStatus ||
							vm.main.IsolationStatus === 'ReverseRequestPending' ||
							vm.main.IsolationStatus === 'RequestPending'
						) {
							$log.debug('Check machine isolation status');

							vm.main.IsolationStatus = response.data.States.IsolationRequest;
						}

						// This happens only when we don't get a state from a backend, means that this action was never triggered before
						if (!vm.main.IsolationStatus) {
							vm.main.IsolationStatus = 'ReverseRequestCompleted';
						}
					}

					if (vm.main.responseFeauresStatus.forensic) {
						// Check machine forensic status if it wasn't queried yet or status is pending
						if (!vm.main.ForensicStatus || vm.main.ForensicStatus == 'RequestPending') {
							$log.debug('Check machine forensic status');

							if (response.data) {
								vm.main.ForensicStatus = response.data.States.ForensicsRequest;
							}
						}

						// This happens only when we don't get a state from a backend, means that this action was never triggered before
						if (!vm.main.ForensicStatus) {
							vm.main.ForensicStatus = 'None';
						}
					}

					if (vm.main.responseFeauresStatus.scan) {
						const osPlatform = vm.main.OsPlatform.toLowerCase();
						if (
							osPlatform !== 'linux' &&
							osPlatform !== 'macos' &&
							(!vm.main.DefenderRunningMode ||
								vm.main.DefenderRunningMode === 'NotRunning' ||
								vm.main.DefenderRunningMode === 'FailedGettingStatus')
						) {
							vm.main.ScanState = 'NotSupported';
						} else {
							if (!vm.main.ScanState || vm.main.ScanState == 'RequestPending') {
								$log.debug('Check machine scan status');

								if (response.data) {
									vm.main.ScanState = response.data.States.ScanRequest;
								}
							}

							// This happens only when we don't get a state from a backend, means that this action was never triggered before
							if (!vm.main.ScanState) {
								vm.main.ScanState = 'None';
							}
						}
					}

					if (vm.main.responseFeauresStatus.executionPolicy) {
						if (
							!vm.main.ExecutionPolicyState ||
							vm.main.ExecutionPolicyState === 'ReverseRequestPending' ||
							vm.main.ExecutionPolicyState === 'RequestPending'
						) {
							$log.debug('Check machine execution policy state');

							vm.main.ExecutionPolicyState = response.data.States.RestrictExecutionRequest;
						}

						// This happens only when we don't get a state from a backend, means that this action was never triggered before
						if (!vm.main.ExecutionPolicyState) {
							vm.main.ExecutionPolicyState = 'ReverseRequestCompleted';
						}

						// Don't support restrict execution in case that defender running mode is unknown or not normal, however allow unrestrict in case that already restricted
						if (
							vm.main.ExecutionPolicyState === 'ReverseRequestCompleted' &&
							vm.main.DefenderRunningMode !== 'Normal'
						) {
							vm.main.ExecutionPolicyState = 'NotSupported';
						}
					}
				}
			},
			function(response) {
				$log.error('Error occur while machine response states');
			}
		);
	}

	function showActionCenter() {
		if (vm.showActionCenter) {
			delete vm.showActionCenter;
		}

		$log.debug('Checking if action center is enabled');

		vm.showActionCenter =
			featuresService.isEnabled('Response-Isolation') ||
			featuresService.isEnabled('Response-Forensic') ||
			featuresService.isEnabled('Response-Scan') ||
			featuresService.isEnabled('Response-ExecutionPolicy');

		$log.debug('Action center feature enabling status is ' + vm.showActionCenter);
	}

	function setHealthStatusDetails() {
		vm.healthStatusCategory = $filter('healthStatusCategory')(vm.main.HealthStatus);
		vm.healthStatusSummary = $filter('healthStatusText')(vm.main, 'summary');
		vm.healthStatusPopoverDescription = $filter('healthStatusText')(vm.main, 'description');
		vm.machineIsMisconfigured = vm.healthStatusCategory === 'Misconfigured';
		vm.machineIsInactive = vm.healthStatusCategory === 'Inactive';
		vm.machineIsUnhealthy = vm.healthStatusCategory !== 'Active';
	}

	function setOsDetailsString() {
		if (vm.main.OsBuild || vm.main.ReleaseVersion) {
			const releaseVersionString = vm.main.ReleaseVersion ? `version ${vm.main.ReleaseVersion}` : '';
			const buildVersionString = vm.main.OsBuild ? `build ${vm.main.OsBuild}` : '';
			vm.osDetailsString =
				'(' +
				releaseVersionString +
				(releaseVersionString && buildVersionString ? ' ' : '') +
				buildVersionString +
				')';
		}
	}

	function updateIsAtaSettingEnabled() {
		const advancedFeatureUrl = appConfig.serviceUrls.management + '/GetAdvancedFeaturesSetting';
		return $http
			.get(advancedFeatureUrl, {
				timeout: 60000,
			})
			.then(function(response) {
				if (response.status === 200 && response.data != null) {
					vm.ataAdvancedSettingEnabled =
						response.data.AatpIntegrationEnabled && response.data.AatpWorkspaceExists;
					return $q.resolve();
				} else {
					$log.error('could not get ata advanced setting status');
					vm.ataAdvancedSettingEnabled = false;
					return $q.resolve();
				}
			});
	}

	function overrideDateByUrl(date: Date) {
		const timeParam = $state.params.time;
		const timeParamDate = new Date(timeParam);
		if (timeParam && timeParamDate && date.getTime() === timeParamDate.getTime()) {
			return timeParam;
		}
		return date;
	}
}
