import { sccHostService } from '@wcd/scc-interface';
import { tenantContextCache } from './tenant-context.cache.svc';
import { TenantContext } from './tenant-context.model';

export function HandleTenantStatusRedirection(
	tenantContext: TenantContext,
	redirectTo?: string,
	allowMdeOptedOutOfMtp: boolean = true,
	allowOptedOutOfMtp: boolean = false
) {
	if (!tenantContext.IsMtpEligible) {
		return null;
	}

	const onboardPath = tenantContext.Features.TvmFullStandAlone ? 'onboard' : 'onboard_mtp';
	if (!tenantContext.IsOnboardingComplete) {
		sccHostService.state.go(onboardPath, { redirectTo });
		return false;
	}

	if (
		!tenantContextCache.hasMtpConsent &&
		!allowOptedOutOfMtp &&
		!allowOptedOutMdeTenant(
			tenantContext.IsMdatpActive,
			allowMdeOptedOutOfMtp,
			tenantContext.Features.MtpForOptedOut
		)
	) {
		sccHostService.state.go(onboardPath, { redirectTo });
		return false;
	}

	if (tenantContext.MdeFlavor === 'Smb') {
		return HandleSmbTenantOnboardingWizardRedirection(tenantContext, redirectTo);
	}

	return true;
}

/**
 * This function determines whether a MTP tenant in an OPTED-OUT state should be allowed to visit MTP experiences.
 * Allow if both conditions are met:
 * 1. The tenant has MDATP workload active
 * 2. The page itself allows opted-out tenants (defined in app-routing-scc.module)
 * for rollout purposes, this is currently controlled by a feature
 */
function allowOptedOutMdeTenant(
	isMdatpActive: boolean,
	allowOptedOutInPage: boolean,
	isMtpForOptedOutFeatureEnabled: boolean
) {
	return isMtpForOptedOutFeatureEnabled && isMdatpActive && allowOptedOutInPage;
}

// This function is being called for SMB tenants only (tenantContext.mdeFlavor === 'Smb') and it redirect relevant tenants
// to the smb onboarding wizard.
// It checks whether the feature is enabled && the tenant hasn't finished the wizard.
// If this if condition is met, we do GET request in order to get the most up to date data and see whether the property of hasFinished
// is false in the tenant store also. if this property is false, then we navigate the user to the onboarding wizard.
// the GET request is because of cached data in the tenant context.
export function HandleSmbTenantOnboardingWizardRedirection(
	tenantContext: TenantContext,
	redirectTo?: string
) {
	const featureEnabled = tenantContext.Features.SmbPostOnboardingWizard;
	const hasFinishedWizard = tenantContext.HasFinishedPostOnboardingForSmb;
	if (featureEnabled && !hasFinishedWizard && window.location.pathname !== '/smb-onboarding') {
		return sccHostService.ajax
			.get(`<mtp>/k8s/mde/smb/additionalProvisioning`)
			.then((res) => {
				// if the tenant actually finished the wizard, then no redirection is needed.
				if (res.data.hasFinishedOnboardingWizard === true) {
					return true;
				}

				// else, we need to redirect the user to the smb onboarding wizard page.
				sccHostService.state.go('smb-onboarding', { redirectTo });
				return false;
			})
			.catch((e) => {
				sccHostService.log.trackException(e, {
					errorMessage: 'Failed to get smb additional provisioning data',
				});
				sccHostService.state.go('home');
				return false;
			});
	}

	return true;
}
