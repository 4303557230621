import {
	AfterViewChecked,
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	ElementRef,
	EventEmitter,
	Input, NgZone,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	Renderer2,
	Type,
	ViewChild,
	ViewChildren,
	ViewContainerRef,
} from '@angular/core';
import { DataTableField, DataTableFieldComponent } from '../models/datatable-field.model';
import { BehaviorSubject, from, fromEvent, Observable, Subscription } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { I18nService } from '@wcd/i18n';
import { generateChanges, isNgChanges } from '@wcd/angular-extensions';
import { debounce } from 'lodash-es';
import { take } from 'rxjs/operators';
import { IPerfSession, sccHostService } from '@wcd/scc-interface';
import { FabActionButtonComponent } from '@angular-react/fabric';

type Direction = 'up' | 'down' | 'right' | 'left';
type TabbaleCell = 'td' | 'li';
type TabbaleRow = 'tr' | 'ul';

const BUTTON_PADDING = 16;
const SCROLL_CLASS = 'scroll';
const BODY_HAS_SCROLL = 'body-has-scroll';
const DEFAULT_FIELD_WIDTH = 200;
const MIN_FIELD_WIDTH = 40;
const LOAD_TOP_ITEMS_HEIGHT = 40;
const CELL_HORIZONTAL_PADDING = 16;

let lastId = 0;

@Component({
	selector: 'wcd-datatable',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './datatable.component.html',
	styleUrls: ['./datatable.component.scss'],
})
export class DataTableComponent<TData extends { id?: any } = any>
	implements OnInit, OnDestroy, OnChanges, AfterViewChecked, AfterViewInit {
	/**
	 * The array of data items to display as rows in the table.
	 */
	@Input() items: Array<TData>;

	/**
	 * In case we're displaying grouped rows, the groups to display as rows.
	 */
	@Input() groups: Array<TData>;

	/**
	 * Whether all items are selected when the table initially renders.
	 * @default false
	 */
	@Input() itemsSelected: boolean;

	/**
	 * Whether to render a checkbox at the start of each row, which selects the row.
	 * @default true
	 */
	@Input() selectEnabled: boolean;

	/**
	 * If this function is specified, it's used to determine whether an item can be selected. If it can't, it won't have a checkbox when selectEnabled === true.
	 */
	@Input() isItemSelectable: (item: TData) => boolean;

	/**
	 * If this function is specified, it's used to determine whether an item can be clicked. If it can't, and the field has an onClick method, the onClick method won't be called when clicking on the row.
	 */
	@Input() isItemClickable: (item: TData) => boolean;

	/**
	 * Determines whether the item is a group, when allowGroupItems === true.
	 */
	@Input() isItemGroup: (item: TData) => boolean;

	/**
	 * Component class to render on nested content expand.
	 */
	@Input() nestedComponentType: DataTableFieldComponent<TData, any>;

	/**
	 * Determines whether the item row should render a nested component, if nestedComponentType was defined.
	 */
	@Input() hasNestedContent: (item: TData) => boolean;

	/**
	 * If `true`, the table will get a `small-padding` class and rows will be smaller vertically.
	 */
	@Input() isSmallPadding = false;

	/**
	 * If `true`, the table will dispatch scroll event to get more data if no data exists.
	 */
	@Input() loadMoreOnEmpty = false;
	private loadMoreOnEmptyCount = 0;
	private readonly MAX_RETIRES_ON_EMPTY_DATA = 10;

	/**
	 * If `update` changes, the table will re-render (updating header sizes, etc).
	 */
	@Input() update: any;

	/**
	 * The field that's currently used for sorting the data table. Should be one of the columns in `columns`.
	 */
	@Input() sortField: DataTableField;

	/**
	 * The configuration for each column in the data table.
	 */
	@Input() columns: Array<DataTableField>;

	sortableFieldIdsSet: Set<string>;

	/**
	 * If specified, overrides the sort:enabled property of a field to decide whether sorting is allowed for a field.
	 */
	@Input()
	set sortableFieldIds(sortableFieldIds: Array<string>) {
		this.sortableFieldIdsSet = sortableFieldIds ? new Set(sortableFieldIds) : null;
	}

	/**
	 * Whether the current sort is done descending rather than ascending.
	 */
	@Input()
	set sortDescending(sortDescending: boolean) {
		this._sortDescending = sortDescending;
	}

	get sortDescending(): boolean {
		return this._sortDescending;
	}

	/**
	 * Whether grouping rows is allowed.
	 */
	@Input() allowGroupItems = false;

	/**
	 * Whether should load all groups items on init.
	 */
	@Input() loadGroupItemsOnLoad = false;

	/**
	 * A filter which will be used to decide which groups are expanded on init.
	 */
	@Input() isGroupExpandedOnInit: (item: TData) => boolean;

	/**
	 * If allowGroupItems - allow group item to be selectable without auto selection of all the nested items
	 */
	@Input() allowParentSelectionWithoutSelectingNestedItems = false;

	/**
	 * Function for fetching group items, when clicking on a group row
	 */
	@Input() getGroupItems: (group: TData) => Promise<Array<TData>> | Observable<Array<TData>>;

	/**
	 * Items that should be highlighted (a class is added to a highlighted item's row).
	 */
	@Input() highlightedItems: Array<TData>;

	/**
	 * Items that are always highlights, no matter what.
	 */
	@Input() permanentHighlightedItems: Array<TData>;

	/**
	 * If `refreshOn` changes, changeDetection in the data table will be marked for check.
	 */
	@Input() refreshOn: any;

	/**
	 * If `true`, the user is able to resize columns in the table.
	 */
	@Input() allowResize = false;

	/**
	 * Enable or disable selection of all visible item on the table. disabled by default
	 */
	@Input() selectAllEnabled: boolean;

	/**
	 * Make the width of the table fixed, no mater the content size. overflow items will be hidden with elipsis.
	 * Important: will not work when allowResize is set to true
	 */
	@Input() fixedTable = false;

	/**
	 * Whether to allow selecting multiple items. If `false`, the data table will deselect a selected item before selecting a new one
	 * @default true
	 */
	@Input() allowMultipleSelection: boolean = true;

	/**
	 * The property in TData to use as key. Used for selection, to verify uniqueness.
	 */
	@Input() itemUniqueKey: keyof TData = 'id';

	/**
	 * If `true`, the data table will emit a scroll event when scrolling to the bottom. It'll also emit this event on init, if there's no scroll required.
	 */
	@Input() infiniteScrolling: boolean;

	/**
	 * If `true`, the data table will allow loading items and adding them on the top of the previous items, while keeping the previous scroll position
	 */
	@Input() loadItemsOnTableTop: boolean;

	/**
	 * Set `showHeaders` to `false` to not render the table's headers.
	 */
	@Input() showHeaders: boolean = true;

	/**
	 * An object whose keys are field IDs and values are widths (in pixels) of columns.
	 * If not specified, column widths are calculated dynamically.
	 */
	@Input() fieldWidths: Record<string, number>;
	/**
	 * A boolean stating whether the table should extend to the full height of the container or not
	 */
	@Input() fullHeight: boolean = true;

	/**
	 * A boolean stating whether the column headers should size dynamically.
	 * Note: only works if fixedTable = true
	 * @default false
	 */
	@Input() fluidHeaderWidth: boolean = false;

	/**
	 * Label to be announced by the narrator when reaching the table.
	 */
	@Input() label: '';

	/**
	 * Changing from false to true will focus the first column header in the table.
	 */
	@Input() focusOnFirstCell: boolean = false;

	/**
	 * Changing from false to true will focus the table. Ignored when "focusOnFirstCell" is set to "true"
	 */
	@Input() focusOnTable: boolean = false;

	/**
	 * using false will be translated to null which will eliminate the tabindex option
	 */
	@Input() tabIndex: 0 | -1 | false = -1;

	/**
	 * using 'true' will brake the headers text to multiple lines if text is longer than max with of the column
	 */
	@Input() wrapHeader: boolean = false;

	/**
	 * An event that is emitted whenever an item is selected from the data table.
	 * The event sends the currently selected items, along with the previous and next items, if the selection is a single item.
	 */
	@Output()
	select: EventEmitter<{ items: Array<TData>; previous: TData; next: TData }> = new EventEmitter<{
		items: Array<TData>;
		previous: TData;
		next: TData;
	}>();

	/**
	 * An event that is emitted when an item is clicked on.
	 */
	@Output() itemClick: EventEmitter<DataTableClickEvent> = new EventEmitter<DataTableClickEvent>();

	/**
	 * Emitted when a user clicks on a table header with sorting enabled.
	 */
	@Output()
	sortFieldChanged: EventEmitter<{ field: DataTableField }> = new EventEmitter<{ field: DataTableField }>();

	/**
	 * Emitted when a group is expanded.
	 */
	@Output() groupExpand: EventEmitter<{ group: TData; children: Array<TData> }> = new EventEmitter();

	/**
	 * Emitted if `infiniteScrolling` is true and the data table is scrolled to the bottom.
	 */
	@Output() scroll: EventEmitter<void> = new EventEmitter();

	/**
	 * Emitted if `loadItemsOnTableTop` is true and the user clicks "Load newer results".
	 */
	@Output() loadTopItemsClick: EventEmitter<void> = new EventEmitter();

	/**
	 * Emitted when a user resizes a column
	 */
	@Output() columnResize: EventEmitter<DataTableColumnResizeEvent> = new EventEmitter();

	/**
	 * Emitted when a new data is applied ane rendered
	 */
	@Output() onRenderComplete: EventEmitter<void> = new EventEmitter();

	private scrollSubscription: Subscription;

	@ViewChild('tableContainer', { static: true }) tableContainer: ElementRef<HTMLElement>;
	@ViewChild('listHeader', { static: false }) headerListElement: ElementRef<HTMLUListElement>;
	@ViewChild('listHeaderWrapper', { static: false }) headerWrapperElement: ElementRef<HTMLElement>;
	@ViewChild('tableResizeWrapper', { static: false }) tableResizeWrapperElement: ElementRef<HTMLElement>;
	@ViewChild('tableHeader', { static: false }) tableOriginalHeader: ElementRef<HTMLElement>;
	@ViewChild('table', { static: false }) tableElement: ElementRef<HTMLTableElement>;
	@ViewChild( 'loadItemsOnTopElement', {static: true}) loadItemsOnTopElement: FabActionButtonComponent;
	@ViewChildren('customNestedComponent', { read: ViewContainerRef })
	customNestedComponents: QueryList<ViewContainerRef>;

	allSelected: boolean;
	someSelected: boolean = false;
	isTopScroll: boolean = true;
	isLoadingTopItems$ = new BehaviorSubject(false);
	hasSelectableItems: boolean;
	selectableItems: Set<TData>;
	tableBodyElement: HTMLElement;
	selectedItemsIndex: Set<TData> = new Set();
	boundTrackById = this.trackById.bind(this);
	resizingColumn: DataTableField;
	expandedGroups: Set<TData>;
	expandedNestedContents: Set<TData>;
	groupItems: Map<TData, Array<TData>>;
	readonly tableUniqueId: string;

	private _isInit: boolean = false;
	private _updateHeadersSubscription: Subscription;
	private _isFirstData: boolean = true;
	private _firstScrollChecked: boolean = false;
	private _lastSelectionEvent: MouseEvent;
	private _lastSelectedItem: TData;
	private _previousFirstItem: TData;
	private _shouldKeepScrollPosition: boolean;
	private _sortDescending = false;

	/**
	 * Returns all the items and grouped items in the data table
	 */
	get allItems(): Array<TData> {
		let groupedItems: Array<TData> = [];
		if (this.groupItems)
			this.groupItems.forEach((items: Array<TData>) => (groupedItems = groupedItems.concat(items)));

		return this.items.concat(groupedItems);
	}

	get showCheckboxField(): boolean {
		return this.selectEnabled !== false && this.hasSelectableItems;
	}

	get showLoadTopItemsButton(): boolean {
		return (
			this.loadItemsOnTableTop &&
			this.tableContainer &&
			this.tableContainer.nativeElement &&
			this.tableContainer.nativeElement.scrollTop < 100
		);
	}

	get hasVerticalScroll(): boolean {
		return (
			this.tableContainer &&
			this.tableContainer.nativeElement &&
			this.tableContainer.nativeElement.scrollHeight > this.tableContainer.nativeElement.clientHeight
		);
	}

	private _resizeColumnStartPosition: number;
	private _resizeColumnEndPosition: number;

	readonly selectAllText: string;
	readonly unselectAllText: string;
	private _initialLoadFired: boolean = false;
	private _fireLoadedEvent = debounce(() => {
		this._initialLoadFired = true;
		this.onRenderComplete.emit();
	}, 10);

	private focusedElement: HTMLElement | HTMLLIElement | HTMLTableCellElement;
	private isFocusedElementChild = false;
	selectAllRowFocused= false;

	constructor(
		private elementRef: ElementRef,
		private changeDetectionRef: ChangeDetectorRef,
		private liveAnnouncer: LiveAnnouncer,
		private i18nService: I18nService,
		private readonly resolver: ComponentFactoryResolver,
		private zone: NgZone,
		private renderer: Renderer2
	) {
		this.selectAllText = i18nService.get('grid.select.all');
		this.unselectAllText = i18nService.get('grid.select.none');
		this.tableUniqueId = `datatable${++lastId}`;
	}

	ngOnInit() {
		this.allSelected = !!this.itemsSelected;
		this.tableBodyElement = this.tableContainer.nativeElement.querySelector('table');
		if (this.allowGroupItems) this.expandedGroups = new Set();
		if (this.nestedComponentType) this.expandedNestedContents = new Set();

		if (this.showHeaders !== false) {
			let scroll$: Observable<Event> = fromEvent(this.tableContainer.nativeElement, 'scroll').pipe(
				tap(e => this.onScrollBody(e))
			);

			if (this.infiniteScrolling) {
				scroll$ = scroll$.pipe(
					debounceTime(300),
					filter(() => {
						const { scrollTop, scrollHeight, clientHeight } = this.tableContainer.nativeElement;
						return scrollTop + clientHeight + 1 >= scrollHeight;
					}),
					tap(() => this.scroll.emit())
				);
			}

			this.scrollSubscription = scroll$.subscribe(() => {});
			this._updateHeadersSubscription = fromEvent(window, 'resize')
				.pipe(debounceTime(100))
				.subscribe(this.updateHeaderCells.bind(this));
		}

		if (this.items) this.onData(this.items);

		this._isInit = true;
	}

	ngOnDestroy() {
		this._updateHeadersSubscription && this._updateHeadersSubscription.unsubscribe();
		this.scrollSubscription && this.scrollSubscription.unsubscribe();
	}

	ngOnChanges(changes) {
		let changed = false;

		if (this.allowGroupItems && (changes.allowGroupItems || changes.items)) {
			this.expandedGroups = new Set();
			this.expandedNestedContents = new Set();
			this.groupItems = new Map();
			changed = true;
		}

		if (!this._isInit) return;

		if (changes.selectEnabled) {
			this.setSelectableItems();
		}

		if (
			changes.itemsSelected &&
			this.itemsSelected !== undefined &&
			this.itemsSelected !== null &&
			this.itemsSelected.constructor === Boolean
		) {
			this.allSelected = this.itemsSelected;
			if (!this.allSelected) {
				this.selectedItemsIndex.clear();
			}
		}

		if (changes.items) {
			this.onData(this.items);
			changed = true;
			this.updateHeaderCells();
		}

		if (changes.sortField || changes.refreshOn) changed = true;

		if (changes.columns) setTimeout(() => this.updateHeaderCells(), 100);
		else if (changes.update) this.updateHeaderCells();
		else if (changed) this.changeDetectionRef.markForCheck();
	}

	ngAfterViewChecked(): void {
		if (!this._initialLoadFired) {
			this._fireLoadedEvent();
		}

		if (!this.items) return;
		if (this.loadItemsOnTableTop) {
			const firstItem = this.items[0];
			if (!this._previousFirstItem) {
				this._previousFirstItem = firstItem;
				return;
			}

			// Keep the scroll position of the previous first item
			if (this._shouldKeepScrollPosition && firstItem.id !== this._previousFirstItem.id) {
				const previousItemScrollTop = this.getRowByItem(this._previousFirstItem).offsetTop;
				this.tableContainer.nativeElement.scrollTop =
					previousItemScrollTop -
					(this.tableOriginalHeader.nativeElement.clientHeight + LOAD_TOP_ITEMS_HEIGHT);
				this._shouldKeepScrollPosition = false;
				this._previousFirstItem = firstItem;
			}
		}
	}

	ngAfterViewInit(): void {
		this.updateHeaderCells();
	}

	onScrollBody(e) {
		this.headerWrapperElement.nativeElement.scrollLeft = e.target.scrollLeft;
		if (this.tableResizeWrapperElement)
			this.tableResizeWrapperElement.nativeElement.style.left = `-${e.target.scrollLeft}px`;

		if (e.target.scrollTop) {
			if (this.isTopScroll) {
				this.headerWrapperElement.nativeElement.classList.add(SCROLL_CLASS);
				this.isTopScroll = false;
			}
		} else if (!this.isTopScroll) {
			this.isTopScroll = true;
			this.headerWrapperElement.nativeElement.classList.remove(SCROLL_CLASS);
		}

		if (e.target.scrollTop < 500) this.changeDetectionRef.detectChanges();
	}

	onScrollByKey(originalCell: HTMLTableCellElement | HTMLLIElement | HTMLDivElement): void {
		const isTableHeader = originalCell instanceof HTMLLIElement;
		const source = isTableHeader ? this.headerWrapperElement : this.tableContainer;
		const destination = isTableHeader ? this.tableContainer : this.headerWrapperElement;

		this.setScrollPosition(source, destination);
	}

	onSortChanged(field: DataTableField) {

		this.sortFieldChanged.emit({ field: field });
		setTimeout(() => {
			if (this.liveAnnouncer) {
				this.liveAnnouncer.announce(
					this.getColumnSortedAnnouncement(this.sortField),
					'assertive',
					300
				);
			}
		}, 200);
	}

	onItemsAddedOnTop() {
		this.isLoadingTopItems$.next(false);
		this._shouldKeepScrollPosition = true;
		this.changeDetectionRef.markForCheck();
	}

	loadItemsOnTop() {
		this.isLoadingTopItems$.next(true);
		this.loadTopItemsClick.emit();
	}

	toggleRow($event: MouseEvent, item: TData, isHead: boolean = false) {
		if (this.isItemClickable && !this.isItemClickable(item)) return;

		const target: HTMLElement = <HTMLElement>$event.target;
		if (target.closest('.wcd-toggle') || target.closest('.checkbox-field') || target.nodeName === 'INPUT')
			return true;

		const link = target.closest('a');

		if (target.getAttribute('(click)') || (link && link.getAttribute('href')) || target.closest('button'))
			return;

		if (this.nestedComponentType && this.hasNestedContent(item)) this.toggleNestedContent(item);

		if (isHead) this.toggleGroup(item);
		else this.triggerItemClick(item, $event);
	}

	triggerItemClick(item: TData, $event?: MouseEvent): void {
		if (this.isItemClickable && !this.isItemClickable(item)) return;

		$event && this.fixTabIndexElement($event);
		this.itemClick.emit(
			Object.assign(
				{
					item: item,
					mouseEvent: $event,
				},
				this.getItemPreviousAndNext(item)
			)
		);
	}

	private toggleNestedContent(rootItem: TData): void {
		if (this.expandedNestedContents.has(rootItem)) {
			this.expandedNestedContents.delete(rootItem);
		} else {
			this.expandedNestedContents.add(rootItem);
			// adding a setTimeout to allow Angular to render the content placeholder before accessing it
			setTimeout(() => this.setNestedContent(rootItem), 1);
		}
	}

	private setNestedContent(rootItem: TData): void {
		// add nested component dynamically
		const factory = this.resolver.resolveComponentFactory(this.nestedComponentType.type);
		const componentPlaceHolder = this.customNestedComponents.find(
			placeholder => placeholder.element.nativeElement.id === `nested_${rootItem[this.itemUniqueKey]}`
		);
		const nestedComponent = componentPlaceHolder.createComponent(factory);
		const props = this.nestedComponentType.getProps && this.nestedComponentType.getProps(rootItem);
		if (this.nestedComponentType.getProps) Object.assign(nestedComponent.instance, props);
		if (isNgChanges(nestedComponent.instance))
			nestedComponent.instance.ngOnChanges(generateChanges(props));
		this.changeDetectionRef.markForCheck();
	}

	private toggleGroup(rootItem: TData) {
		if (this.expandedGroups.has(rootItem)) {
			this.expandedGroups.delete(rootItem);
			this.groupItems.delete(rootItem);
		} else {
			const perf = sccHostService.perf.createPerformanceSession('expand-datatable-group', 'user-action');
			this.expandedGroups.add(rootItem);
			this.setGroupItems(rootItem, perf);
		}
		this.announceGroupToggle(rootItem);
	}

	private setGroupItems(rootItem: TData, perf: IPerfSession) {
		if (!this.getGroupItems) return;
		const itemsCount = this.allItems ? this.allItems.length : -1;
		from(this.getGroupItems(rootItem)).subscribe((groupItems: Array<TData>) => {
			groupItems = groupItems || [];

			const isGroupSelected: boolean =
				!this.allowParentSelectionWithoutSelectingNestedItems &&
				this.selectedItemsIndex.has(rootItem);

			this.addSelectableItems(groupItems);
			this.groupItems.set(rootItem, groupItems);
			this.groupExpand.emit({ group: rootItem, children: groupItems });
			if (isGroupSelected) {
				groupItems.forEach(item => {
					if (this.selectableItems.has(item)) this.selectedItemsIndex.add(item);
				});

				this.setSelectedState();
				this.notifyOnSelectionChange();
			}
			this.changeDetectionRef.markForCheck();
			try {
				this.zone.onMicrotaskEmpty.asObservable().pipe(take(1))
					.subscribe(() => perf.end({
						customProps: {
							nestedItemsCount: groupItems.length,
							itemsCount: itemsCount,
						},
					}));
			}
			catch (e){
				sccHostService.log.trackException(e);
			}
		});
	}

	// Select the given items (and de-select other items, if any).
	selectItems(items: Array<TData>): void {
		const newSelectedItemsIds: Set<any> = new Set<any>(items.map(item => item[this.itemUniqueKey]));
		let wasChanged = false;

		for (const item of this.allItems) {
			const itemId: any = item[this.itemUniqueKey];
			const newSelectionStatus: boolean = newSelectedItemsIds.has(itemId);
			const previousSelectionStatus: boolean = this.selectedItemsIndex.has(item);
			if (newSelectionStatus !== previousSelectionStatus) {
				wasChanged = true;
				if (newSelectionStatus) this.selectedItemsIndex.add(item);
				else this.selectedItemsIndex.delete(item);
			}
		}

		if (wasChanged) {
			this.setSelectedState();
			this.notifyOnSelectionChange();
		}
	}

	onData(data: Array<TData>): void {
		if (!data) return;

		if (this.itemsSelected !== undefined) {
			this.allSelected = this.itemsSelected;

			if (typeof this.allSelected === 'boolean') {
				this.selectedItemsIndex.clear();
				if (this.itemsSelected) {
					data.forEach(item => {
						this.selectedItemsIndex.add(item);
					});
				} else {
					this.selectedItemsIndex.clear();
				}
			} else if (this.selectedItemsIndex) {
				this.selectedItemsIndex.forEach(item => {
					if (!data.includes(item) || !this.isItemSelectable(item))
						this.selectedItemsIndex.delete(item);
				});
			}
		} else {
			this.selectedItemsIndex.forEach(item => {
				if (!data.includes(item)) this.selectedItemsIndex.delete(item);
			});
		}

		this.setSelectableItems();

		if (this.allowGroupItems && this.loadGroupItemsOnLoad) {
			data.forEach(item => {
				if (
					this.isItemGroup(item) &&
					this.isGroupExpandedOnInit(item) &&
					!this.expandedGroups.has(item)
				) {
					this.toggleGroup(item);
				}
			});
		}

		this.notifyOnSelectionChange();
		this.updateHeaderCells();

		if (!this.infiniteScrolling) {
			this.tableContainer.nativeElement.scrollTop = 0;
			this.tableContainer.nativeElement.scrollLeft = 0;
		}
		this._initialLoadFired = false;
	}

	/**
	 * To enable body-only scrolling, the header can't be a part of the table, so it should be updated when necessary.
	 * This function sets the header so it looks as part of the table.
	 */
	updateHeaderCells() {
		if (this.showHeaders === false) return;

		requestAnimationFrame(() => {
			const firstRow = <HTMLTableRowElement>this.tableBodyElement.querySelector('tbody tr');

			if (!firstRow) {
				if (this.items && this.items.length) return this.updateHeaderCells();

				return;
			}

			this.doUpdateHeaderCells(firstRow);
		});
	}

	doUpdateHeaderCells(firstRow: HTMLTableRowElement, resetTableWidth?: boolean) {
		if (this.showHeaders === false) return;

		const tableHeaderCells = this.headerListElement.nativeElement.querySelectorAll('li');

		if (resetTableWidth !== false) this.tableBodyElement.style.removeProperty('width');

		this.tableBodyElement.style.top =
			'-' + (this.tableOriginalHeader.nativeElement.clientHeight + 2) + 'px';

		if (!this.fieldWidths) {
			this.tableElement.nativeElement.style.tableLayout = 'auto';
			this.tableElement.nativeElement.style.width = '100%';
		}

		const columnWidths = this.getColumnWidths(firstRow);
		this.tableElement.nativeElement.style.removeProperty('table-layout');
		this.tableElement.nativeElement.style.removeProperty('width');

		this.fieldWidths = this.columns.reduce((fieldWidths, column, i) => {
			if (!fieldWidths[column.id]) {
				let calculatedColumnWidth = columnWidths[this.showCheckboxField ? i + 1 : i];
				if (column.minWidth) calculatedColumnWidth = Math.max(calculatedColumnWidth, column.minWidth);

				if (column.maxWidth) calculatedColumnWidth = Math.min(calculatedColumnWidth, column.maxWidth);

				fieldWidths[column.id] = calculatedColumnWidth;
			}

			return fieldWidths;
		}, this.fieldWidths || {});

		columnWidths.forEach((columnWidth, i) => {
			const headerCell = tableHeaderCells[i];
			const firstRowCell = <HTMLTableCellElement>firstRow.querySelector(`td:nth-child(${i + 1})`);

			headerCell.style.width = columnWidth + 'px';

			const headerContents = headerCell.querySelector('button') || headerCell.querySelector('span');

			if (headerContents) {
				const headerContentsWidth = headerContents.clientWidth + BUTTON_PADDING,
					widthOffset = headerContentsWidth - columnWidth;

				if (widthOffset > 0) {
					firstRowCell.style.width = headerContentsWidth + 'px';
				}
			}
		});

		this.headerListElement.nativeElement.style.paddingRight = '10px';
		if (this.hasVerticalScroll) {
			this.headerWrapperElement.nativeElement.classList.add(BODY_HAS_SCROLL);
		} else {
			this.headerWrapperElement.nativeElement.classList.remove(BODY_HAS_SCROLL);
		}

		this.changeDetectionRef.markForCheck();
	}

	private getColumnWidths(firstRow: HTMLTableRowElement): Array<number> {
		const cells = Array.from(firstRow.querySelectorAll('td')).filter(
			element => element.parentElement === firstRow
		);

		return cells.map(cell => cell.clientWidth);
	}

	private setSelectableItems(): void {
		this.selectableItems = new Set();
		this.addSelectableItems(this.allItems);
	}

	private addSelectableItems(items: Array<TData>): void {
		if (this.selectEnabled === false || !items) {
			this.hasSelectableItems = false;
			return;
		}

		let hasSelectableItems = false;
		items.forEach(item => {
			if (this.isSelectable(item)) {
				this.selectableItems.add(item);
				hasSelectableItems = true;
			}
		});

		this.hasSelectableItems = this.hasSelectableItems || hasSelectableItems;
	}

	/**
	 * Deselects all items in the table and emits the 'select' event if any items were deselected.
	 * @returns True if any items were deselected, false if not.
	 */
	selectNone(notifyChange: boolean = true): boolean {
		if (this.allSelected || this.someSelected) {
			this.allSelected = this.someSelected = false;
			this.selectedItemsIndex.clear();

			if (notifyChange) this.notifyOnSelectionChange();
			return true;
		}

		return false;
	}

	toggleAllSelection() {
		if (!this.selectNone(false)) {
			this.allSelected = true;
			this.someSelected = true;
			let someSelected = false;
			this.allItems.forEach((item: TData) => {
				const isSelected = this.isItemSelectable ? this.isItemSelectable(item) : true;
				if (isSelected) this.selectedItemsIndex.add(item);
				else this.selectedItemsIndex.delete(item);

				if (!isSelected) this.allSelected = false;
				else someSelected = true;
			});

			this.someSelected = !this.allSelected && someSelected;
		}

		this.notifyOnSelectionChange();
	}

	keyboardToggleItemSelection(event: KeyboardEvent, item: TData, state?: boolean) {
		event.preventDefault();
		this.toggleItemSelection(item, state);
	}

	toggleItemSelection(item: TData, state?: boolean) {
		const currentState: boolean = this.selectedItemsIndex.has(item);
		let changed = false;

		if (state === undefined || state !== currentState) {
			if (
				this._lastSelectionEvent &&
				this._lastSelectionEvent.ctrlKey &&
				!this._lastSelectionEvent.shiftKey &&
				this.selectedItemsIndex.size > 1
			) {
				// If ctrl is pressed, deselect all but the clicked item
				if (currentState) this.selectedItemsIndex.clear();

				this.selectedItemsIndex.add(item);
				this._lastSelectedItem = item;
			} else if (
				this._lastSelectionEvent &&
				this._lastSelectionEvent.shiftKey &&
				this.selectedItemsIndex.size &&
				this._lastSelectedItem !== item
			) {
				if (!this._lastSelectionEvent.ctrlKey) this.selectedItemsIndex.clear();

				const selectionIndexes: [number, number] = [
					this.allItems.indexOf(item),
					this.allItems.indexOf(this._lastSelectedItem),
				];

				selectionIndexes.sort((a, b) => a - b);

				for (let itemIndex = selectionIndexes[0]; itemIndex <= selectionIndexes[1]; itemIndex++) {
					this.selectedItemsIndex.add(this.allItems[itemIndex]);
				}
			} else {
				if (!this.allowMultipleSelection) this.selectNone(false);

				const isSelected: boolean = !currentState;
				if (isSelected) this.selectedItemsIndex.add(item);
				else this.selectedItemsIndex.delete(item);

				if (this.allowGroupItems && !this.allowParentSelectionWithoutSelectingNestedItems) {
					if (this.isItemGroup && this.isItemGroup(item)) {
						const groupChildren: Array<TData> = this.groupItems.get(item);
						if (groupChildren)
							groupChildren.forEach(childItem => {
								if (isSelected) this.selectedItemsIndex.add(childItem);
								else this.selectedItemsIndex.delete(childItem);
							});
						else if (isSelected) this.toggleGroup(item);
					} else {
						const itemGroup: TData = this.getItemGroup(item);
						if (itemGroup) {
							if (isSelected && !this.selectedItemsIndex.has(itemGroup)) {
								const allGroupItemsSelected: boolean = this.groupItems
									.get(itemGroup)
									.every(groupChildItem => this.selectedItemsIndex.has(groupChildItem));
								if (allGroupItemsSelected) this.selectedItemsIndex.add(itemGroup);
							} else this.selectedItemsIndex.delete(itemGroup);
						}
					}
				}
				this._lastSelectedItem = item;
			}

			changed = true;
		}

		if (changed) {
			this.setSelectedState();
			this.notifyOnSelectionChange();
		}

		this._lastSelectionEvent = null;
	}

	onCheckboxClick($event: MouseEvent) {
		this._lastSelectionEvent = $event;
	}

	/**
	 * If allowGroupItems is true and the specified item is inside a group, returns the group.
	 */
	getItemGroup(item: TData): TData {
		if (!this.allowGroupItems || this.groupItems.has(item)) return null;

		const groupItemKeys: IterableIterator<TData> = this.groupItems.keys();

		let currentKey: IteratorResult<TData>;
		while ((currentKey = groupItemKeys.next()) && !currentKey.done) {
			const groupItems: Array<TData> = this.groupItems.get(currentKey.value);
			if (groupItems.includes(item)) return currentKey.value;
		}

		return null;
	}

	notifyOnSelectionChange() {
		let selectedItems = [];

		if (this.items) {
			// The selected items to emit are those in the selectedItemsIndex that are NOT groups:
			selectedItems = this.allSelected
				? this.allItems
				: this.allItems.filter(
						item =>
							this.selectedItemsIndex.has(item) &&
							(!this.allowGroupItems ||
								!this.groupItems.has(item) ||
								this.allowParentSelectionWithoutSelectingNestedItems)
				  );
		}

		if (this.loadMoreOnEmpty && (!this.items || !this.items.length)) {
			if (this.loadMoreOnEmptyCount < this.MAX_RETIRES_ON_EMPTY_DATA) {
				this.loadMoreOnEmptyCount++;
				this.scroll.emit();
			} else {
				this.loadMoreOnEmptyCount = 0;
			}
		}

		if (this._isFirstData) {
			this._isFirstData = false;

			if (this.infiniteScrolling && this.items && this.items.length)
				setTimeout(() => this.checkFirstScroll(), 500);
		} else {
			this.select.emit(
				Object.assign(
					{ items: selectedItems },
					selectedItems.length === 1 ? this.getItemPreviousAndNext(selectedItems[0]) : null
				)
			);
		}
	}

	checkFirstScroll() {
		if (this._firstScrollChecked) return;

		if (this.infiniteScrolling) {
			const { scrollHeight, clientHeight } = this.tableContainer.nativeElement;
			if (scrollHeight <= clientHeight) this.scroll.emit();
		}

		this._firstScrollChecked = true;
	}

	getRowByItem(item: TData) {
		const rows = this.tableElement.nativeElement.querySelectorAll('tr');

		return Array.from(rows).find(row => row.dataset && row.dataset.itemId === item.id);
	}

	getItemPreviousAndNext(item: TData): { previous: TData; next: TData } {
		let itemIndex: number = this.items.indexOf(item),
			items: Array<TData>;

		if (~itemIndex) items = this.items;
		else if (this.allowGroupItems) {
			const groupItemKeys: IterableIterator<TData> = this.groupItems.keys();

			let currentKey: IteratorResult<TData>;
			while ((currentKey = groupItemKeys.next()) && !currentKey.done && !items) {
				const groupItems: Array<TData> = this.groupItems.get(currentKey.value),
					itemIndexInGroup: number = groupItems.indexOf(item);

				if (~itemIndexInGroup) {
					itemIndex = itemIndexInGroup;
					items = groupItems;
				}
			}
		}

		if (items) {
			let previousItemThatIsNotAGroup: TData;
			if (itemIndex) {
				for (let i = itemIndex; i > 0 && !previousItemThatIsNotAGroup; i--) {
					const previousItem: TData = items[i - 1];
					if (
						!this.isItemGroup ||
						!this.isItemGroup(previousItem) ||
						this.allowParentSelectionWithoutSelectingNestedItems
					)
						previousItemThatIsNotAGroup = previousItem;
				}
			}

			let nextItemThatIsNotAGroup: TData;
			if (itemIndex < items.length - 1) {
				for (let i = itemIndex; i < items.length && !nextItemThatIsNotAGroup; i++) {
					const nextItem: TData = items[i + 1];
					if (
						!this.isItemGroup ||
						!this.isItemGroup(nextItem) ||
						this.allowParentSelectionWithoutSelectingNestedItems
					)
						nextItemThatIsNotAGroup = nextItem;
				}
			}

			return {
				previous: previousItemThatIsNotAGroup || null,
				next: nextItemThatIsNotAGroup || null,
			};
		}
	}

	setSelectedState() {
		let someSelected,
			allSelected = true;

		if (!this.selectedItemsIndex.size) this.allSelected = this.someSelected = false;

		this.allItems.forEach(item => {
			if (this.selectedItemsIndex.has(item)) someSelected = true;
			else allSelected = false;
		});

		this.allSelected = allSelected;
		this.someSelected = someSelected && !allSelected;
	}

	isSelectable(item: TData): boolean {
		return this.isItemSelectable ? this.isItemSelectable(item) : true;
	}

	private trackById(index, item) {
		return item[this.itemUniqueKey];
	}

	isItemHighlighted(item: TData): boolean {
		const findItem = highlightedItem =>
			highlightedItem && highlightedItem[this.itemUniqueKey] === item[this.itemUniqueKey];
		const isItemHighlightedInArray = items => items && !!items.find(findItem);

		return (
			isItemHighlightedInArray(this.highlightedItems) ||
			isItemHighlightedInArray(this.permanentHighlightedItems)
		);
	}

	isItemSelected(item: TData): boolean {
		return this.selectedItemsIndex.has(item) || this.allSelected;
	}

	isColumnSortable(column: DataTableField): boolean {
		if (this.sortableFieldIdsSet) return this.sortableFieldIdsSet.has(column.id);

		return column.sort && column.sort.enabled;
	}

	getColumnSortedAnnouncement(column: DataTableField): string {
		return `Sorted by ${column.name} : ${this.getColumnAriaSort(column)}`;
	}

	getColumnAriaSort(column: DataTableField): 'ascending' | 'descending' | 'none' {
		return this.sortField && this.sortField.id === column.id
			? this.sortDescending
				? 'descending'
				: 'ascending'
			: 'none';
	}

	getFieldWidth(field: DataTableField): number {
		return (this.fieldWidths && this.fieldWidths[field.id]) || null;
	}

	getFieldWidthPercent(field: DataTableField): string {
		const fieldWidth = this.getFieldWidth(field);
		const totalWidth = this.columns.reduce((sum, curr) => sum + this.getFieldWidth(curr), 0);
		return `${(fieldWidth / totalWidth) * 100}%`;
	}

	resizeColumn(xPosition: number) {
		this._resizeColumnEndPosition = xPosition;
	}

	applyResize(column: DataTableField, $event: CdkDragEnd) {
		const sizeChange = this._resizeColumnEndPosition - this._resizeColumnStartPosition;
		const minWidth = Math.max(MIN_FIELD_WIDTH, column.minWidth);
		const newWidth = Math.max(
			minWidth,
			(this.fieldWidths[column.id] || DEFAULT_FIELD_WIDTH) + sizeChange
		);
		this._resizeColumnStartPosition = null;
		this._resizeColumnEndPosition = null;

		this.resizingColumn = null;
		const previousWidth = this.fieldWidths[column.id];

		this.fieldWidths[column.id] = newWidth;

		$event.source.reset();

		this.columnResize.emit({
			column,
			previousWidth,
			newWidth,
			columnsWidths: { ...this.fieldWidths },
		});
	}

	startResize(column: DataTableField, event: MouseEvent) {
		this._resizeColumnStartPosition = event.clientX;
		this.resizingColumn = column;
	}

	autoFitColumnSize(column: DataTableField) {
		this.resizingColumn = null;
		const columnIndex = this.columns.indexOf(column) + 1 + (this.showCheckboxField ? 1 : 0);

		const columnHeaderContents = this.tableElement.nativeElement.querySelector(
			`th:nth-child(${columnIndex})`
		).firstElementChild;
		const columnHeaderWidth = columnHeaderContents
			? columnHeaderContents.getBoundingClientRect().width
			: 0;

		const maxSize = Math.max(
			columnHeaderWidth,
			...Array.from(
				this.tableElement.nativeElement.querySelectorAll(
					`td:nth-child(${columnIndex}) > wcd-datatable-field-value`
				)
			).map(valElement => valElement.getBoundingClientRect().width)
		);

		const previousWidth = this.fieldWidths[column.id];
		const newWidth = maxSize + CELL_HORIZONTAL_PADDING * 2;

		this.fieldWidths[column.id] = newWidth;

		this.columnResize.emit({
			column,
			previousWidth,
			newWidth,
			columnsWidths: { ...this.fieldWidths },
		});
	}

	onHeaderArrowKey(event: KeyboardEvent, direction: Direction): void {
		if (direction == 'down'){
			this.tableElement.nativeElement.querySelector('tr.datatable-row').classList.add('focus-in-row');
		}
		this.onArrowKey(event, direction, 'li', 'ul');
	}

	fixAllRowAndTabIndexElement(event, focus){
		this.selectAllRowFocused = focus;
		if(focus)
			this.fixTabIndexElement(event);
	}


	// the function fixing the tab indexes of the table
	// to handle inner focusable element
	// and accessibility issue that table with scrollable element must have tabindex=0 on one of the cells
	// when focusing on the table or a cell
	// the function getting the first table cell out of the tab order
	// getting the focusedElement -
	// 		from last iteration OR getting the first header or cell if this is the first time entering the table
	// getting the current cell -
	//		header cell OR cell OR focused element (if this is the first time entering the table)
	// checking if child component is focusable
	// removing the tabindex from the current element
	// focusing on focused Element
	fixTabIndexElement(event, checkForChildTabindex = true) {
		const firstFocusable = this.tableElement.nativeElement.querySelector('tr.datatable-row td');
		let target = event.target;
		if (firstFocusable.getAttribute("tabindex") == '0' && !this.focusedElement){
			this.tableElement.nativeElement.querySelector('tr.datatable-row td').removeAttribute("tabindex");
			target = null;
		}
		this.focusedElement =
			this.focusedElement ||
			<HTMLLIElement>this.headerListElement.nativeElement.querySelector('li[tabindex = "-1"]') ||
			<HTMLTableCellElement>this.tableElement.nativeElement.querySelector('td[tabindex = "-1"], td[tabindex = "0"]') ||
			<HTMLTableCellElement>firstFocusable;

		const currentCell = target &&
			((<HTMLLIElement>target).closest('li') ||
			(<HTMLTableCellElement>target).closest('td')) ||
			<HTMLLIElement | HTMLTableCellElement>this.focusedElement;

		const childTabindexElement = checkForChildTabindex && this.checkForChildTabindex(currentCell);
		!this.isFocusedElementChild
			? this.focusedElement && this.focusedElement !== document.activeElement && this.focusedElement.removeAttribute('tabindex')
			: this.focusedElement && this.focusedElement.setAttribute('tabindex', '-1');

		this.focusedElement = childTabindexElement || currentCell || this.focusedElement;
		this.isFocusedElementChild = childTabindexElement ? true : false;
		this.focusedElement && this.focusedElement.setAttribute('tabindex', '0');
		this.focusedElement && this.focusedElement.focus();
	}



	checkForChildTabindex(currentCell: HTMLLIElement | HTMLTableCellElement) {
		return currentCell && <HTMLElement>currentCell.querySelector('[tabindex = "-1"]');
	}

	onItemOnTopArrowKey(event: KeyboardEvent, direction: Direction): void{
		const eventTarget = <HTMLButtonElement>event.target,
			currentCell = <HTMLDivElement>eventTarget.closest('div'),
			originalTabIndexValue = eventTarget.dataset.originalTabIndex;


		const previousCellIndex = parseInt(eventTarget.dataset.previousCellIndex) || 0;
		eventTarget.dataset.previousCellIndex = null;

		let nextCell;
		if (direction === 'down')
			nextCell = this.getNthChild(
				this.tableElement.nativeElement.querySelector('tr.datatable-row'),
				previousCellIndex
			);
		else{
			nextCell = this.getNthChild(
				this.headerListElement.nativeElement,
				previousCellIndex,
				'li'
			);
		}

		this.setFocusOnNextCell(currentCell, nextCell, direction, originalTabIndexValue);

	}


	onArrowKey(
		event: KeyboardEvent,
		direction: Direction,
		currentCellType: TabbaleCell = 'td',
		currentRowType: TabbaleRow = 'tr'
	): void {
		event.preventDefault();
		event.stopPropagation();

		const eventTarget = <HTMLTableCellElement | HTMLLIElement>event.target,
			currentCell = <HTMLTableCellElement | HTMLLIElement>eventTarget.closest(currentCellType),
			originalTabIndexValue = currentCell.dataset.originalTabIndex,
			currentRow = eventTarget.closest(currentRowType);
		let nextRow = null;

		if (direction === 'up' || direction === 'down'){
			nextRow = this.getNextRow(currentRow, eventTarget, direction)
		}
		const nextCell = this.getNextCell(currentCell, direction, nextRow);

		nextRow && this.renderer.addClass(nextRow,'focus-in-row')
		this.setFocusOnNextCell(currentCell, nextCell, direction, originalTabIndexValue)
		if (nextRow || (currentCell instanceof HTMLTableCellElement && nextCell instanceof HTMLLIElement)){
			currentRow.classList.remove('focus-in-row');
		}
	}

	setFocusOnNextCell(
		currentCell: HTMLTableCellElement | HTMLLIElement | HTMLDivElement,
		nextCell: HTMLTableCellElement | HTMLLIElement,
		direction: Direction,
		originalTabIndexValue: string
	) {
		if (nextCell) {
			//checking if cell have help
			const isHelp = direction === 'right' && this.checkForHelp(event);
			if (!isHelp){
				this.setNextCellFocus(currentCell, nextCell, originalTabIndexValue);
				if (direction === 'left' || direction === 'right') this.onScrollByKey(currentCell);
			}

		}
	}

	//if help exist focus on it
	checkForHelp(event){
		const help = event.target.querySelector("wcd-help span.wcd-help-icon")
		const fabHelpIcon = help && help.querySelector("fab-icon")
		if (!help && !fabHelpIcon)
			return false;

		!fabHelpIcon && help && help.focus()
		fabHelpIcon && fabHelpIcon.focus();
		return true;


	}

	private isCellNavigable(currentCell: HTMLTableCellElement | HTMLLIElement,direction: Direction = 'right') {
		return currentCell && !currentCell.classList.contains('nav-disabled');
	}

	private getNextCell(
		currentCell: HTMLTableCellElement | HTMLLIElement,
		direction: Direction,
		nextRow: HTMLTableRowElement | HTMLUListElement
	): HTMLTableCellElement | HTMLLIElement {
		const currentCellIndex = (<HTMLTableCellElement>currentCell).cellIndex;
		let nextCell;

		switch (direction) {
			case 'right':
				nextCell = currentCell.nextElementSibling;
				nextCell = nextCell && (this.isCellNavigable(nextCell) ? nextCell : this.getNextCell(nextCell, 'right', nextRow));
				// Last li element in header is an empty cell in order to support resizing. We ignore it during tabbing.
				if (currentCell instanceof HTMLLIElement && !nextCell.nextElementSibling) nextCell = null;
				break;
			case 'left':
				nextCell = currentCell.previousElementSibling;
				nextCell = nextCell && (this.isCellNavigable(nextCell) ? nextCell : this.getNextCell(nextCell, 'left', nextRow));
				break;
			case 'down':
				if (nextRow) {
					nextCell = this.getNthChild(nextRow, currentCellIndex);
					nextCell = nextCell && (this.isCellNavigable(nextCell) ? nextCell : this.getNextCell(nextCell, 'right', nextRow));
				}
				if (!nextCell) {
					// special case: jump from header row to first table row
					if (!nextRow && currentCell instanceof HTMLLIElement) {
						//spacial case: when there is a Load Newer results button

						if (this.showLoadTopItemsButton){
							nextCell = this.loadItemsOnTopElement.elementRef.nativeElement.querySelector("button");
							nextCell.dataset.previousCellIndex = Array.from(this.headerListElement.nativeElement.children).indexOf(currentCell) + "";

						}
						else{
							nextCell = this.getNthChild(
								this.tableElement.nativeElement.querySelector('tr.datatable-row'),
								Array.from(this.headerListElement.nativeElement.children).indexOf(currentCell)
							);
						}

					}

					// special case: when the table allows nested content, next cell might not exist, so we focus on the whole row
					if (!nextCell && this.nestedComponentType) {
						nextCell = this.getNthChild(nextRow, 0);
					}
					nextCell = nextCell && (this.isCellNavigable(nextCell) ? nextCell : this.getNextCell(nextCell, 'right', nextRow));
				}
				break;
			case 'up':
				if (nextRow) {
					nextCell = this.getNthChild(nextRow, currentCellIndex);
					nextCell = nextCell && (this.isCellNavigable(nextCell) ? nextCell : this.getNextCell(nextCell, 'right', nextRow));
				}
				if (!nextCell) {
					// special case: jump from table to header row
					if (
						!nextRow ||
						(nextRow instanceof HTMLTableRowElement && nextRow.classList.contains('header-row'))
					) {
						//spacial case: when there is a Load Newer results button jump from table to button
						if (!nextRow && this.showLoadTopItemsButton){
							nextCell = this.loadItemsOnTopElement.elementRef.nativeElement.querySelector("button")
							nextCell.dataset.previousCellIndex = currentCellIndex && currentCellIndex.toString();
						}
						else{
							nextCell = this.getNthChild(
								this.headerListElement.nativeElement,
								currentCellIndex,
								'li'
							);
						}
					}

					// special case: when the table allows nested content, next cell might not exist, so we focus on the whole row
					if (!nextCell && this.nestedComponentType) {
						nextCell = this.getNthChild(nextRow, 0);
					}
					nextCell = nextCell && this.isCellNavigable(nextCell) ? nextCell : this.getNextCell(nextCell, 'right', nextRow);
				}
				break;
		}

		return nextCell;
	}

	private getNthChild(
		element: HTMLTableRowElement | HTMLUListElement,
		cellIndex: number,
		cellType: TabbaleCell = 'td'
	): HTMLTableCellElement | HTMLLIElement {
		return element.querySelector(`${cellType}:nth-child(${cellIndex + 1})`); // nth-child is 1-based
	}

	private getNextRow(
		currentRow: HTMLTableRowElement | HTMLUListElement,
		eventTarget: HTMLTableCellElement | HTMLLIElement,
		direction: Direction
	): HTMLTableRowElement | HTMLUListElement {
		let nextRow;
		switch (direction) {
			case 'up':
				nextRow = currentRow.previousElementSibling;
				break;
			case 'down':
				nextRow = currentRow.nextElementSibling;
				break;
		}

		if (this.allowGroupItems && !nextRow) {
			// special case: when the table allows expanding rows, every expandable row is wrapped in a tbody of its own
			const currentTbody = eventTarget.closest('tbody'),
				nextTbody = currentTbody
					? direction === 'up'
						? currentTbody.previousElementSibling
						: currentTbody.nextElementSibling
					: null,
				rows = nextTbody && nextTbody.querySelectorAll('tr');

			// If going up, go to the last row of previous tbody. If going down, go to the first row of next tbody.
			nextRow = rows ? (direction === 'up' ? rows[rows.length - 1] : rows[0]) : null;
		}

		return nextRow;
	}

	private setNextCellFocus(
		currentCell: HTMLTableCellElement | HTMLLIElement | HTMLDivElement,
		nextCell: HTMLTableCellElement | HTMLLIElement,
		originalTabIndexValue: string
	): void {
		if (
			originalTabIndexValue != null &&
			originalTabIndexValue !== 'null' &&
			originalTabIndexValue !== 'undefined'
		) {
			currentCell.setAttribute('tabindex', originalTabIndexValue);
		} else {
			currentCell.removeAttribute('tabindex');
			delete currentCell.dataset.originalTabIndex;
		}
		nextCell.dataset.originalTabIndex = nextCell.getAttribute('tabindex');
		nextCell.setAttribute('tabindex', '-1');
		nextCell.focus();
	}

	private setScrollPosition(source: ElementRef, destination: ElementRef): void {
		const scrollLeft: number = source.nativeElement.scrollLeft || 0;
		destination.nativeElement.scrollLeft = scrollLeft;
	}

	getSortableFieldAriaLabel(field: DataTableField) {
		const fieldName = field.ariaLabel ? field.ariaLabel : field.name;
		if(!fieldName){
			return;
		}
		if (this.sortField && this.sortField.id === field.id) {
			return this.i18nService.get('dataview.sort.sortedField', {
				name: fieldName,
				direction: this.sortDescending
					? this.i18nService.get('dataview.sort.descending')
					: this.i18nService.get('dataview.sort.ascending'),
			});
		}

		return this.i18nService.get('dataview.sort.sortableField', {
			name: fieldName,
		});
	}

	fixId(id: string) {
		if (typeof id != 'string') return;
		return id.replace(/ /g, '_');
	}

	expandedTabindex(field: DataTableField<any, any>, item: TData) {
		return field.isTabbale && this.isItemGroup && this.isItemGroup(item)
			? field.id == 'expand' &&
			  [
					field.getFieldCssClass ? field.getFieldCssClass(item) || '' : '',
					field.className || '',
			  ].includes('datatable-expand')
				? 0
				: undefined
			: undefined;
	}

	onInitRenderComplete(row, column){
		console.log('cell render', row, column)
	}

	private announceGroupToggle(rootItem: TData){
		this.liveAnnouncer.announce(
			this.i18nService.get(this.expandedGroups.has(rootItem) ? "common.button.expanded" : "common.button.collapsed"),
			'assertive',
			300
		);
	}
}

export interface DataTableClickEvent<TData = any> {
	item: TData;
	previous: TData;
	next: TData;
	mouseEvent: MouseEvent;
}

export interface DataTableColumnResizeEvent {
	column: DataTableField;
	previousWidth: number;
	newWidth: number;
	columnsWidths: Record<string, number>;
}
