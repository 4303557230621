import { Injectable } from '@angular/core';
import { isEmpty, uniqBy } from 'lodash-es';
import {
	AadUser,
	Alert,
	AlertUser,
	IncidentLinkedBy,
	IncidentLinkedByAlert,
	IncidentLinkedByCategory,
	IncidentLinkedByEntity,
	Machine,
	Mailbox,
	ServiceSourceType,
	AggregatedIncidentLinkedBy,
} from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';

export interface TextStyles {
	asArray?: boolean;
	truncateTexts?: boolean;
	showLabels?: boolean;
	slice?: boolean;
}

export interface CategorizedIncidentLinkedByEntity {
	readonly category: IncidentLinkedByCategory;
	readonly entity?: IncidentLinkedByEntity;
	readonly targetAlert?: IncidentLinkedByAlert;
}

export enum LinkedByEntityTypes {
	Mailbox = 'Mailbox',
	MailCluster = 'MailCluster',
	MailMessage = 'MailMessage',
	Url = 'Url',
	File = 'File',
	Machine = 'Machine',
	User = 'User',
	Ip = 'Ip',
	CommandLine = 'CommandLine',
	RegistryKey = 'RegistryKey',
	RegistryValue = 'RegistryValue'
}

@Injectable()
export class AlertIncidentService {
	private readonly linkedByTranslationKeys: Partial<Record<IncidentLinkedByCategory, string>> = {
		FileSimilarity: 'alerts.linkedByIncident.categories.fileSimilarity',
		FileExactMatch: 'alerts.linkedByIncident.categories.fileExactMatch',
		UrlSimilarity: 'alerts.linkedByIncident.categories.urlSimilarity',
		UrlExactMatch: 'alerts.linkedByIncident.categories.urlExactMatch',
		TimeSeries: 'alerts.linkedByIncident.categories.timeSeries',
		User: 'alerts.linkedByIncident.categories.user',
		Automation: 'alerts.linkedByIncident.categories.automation',
		AutomatedInvestigation: 'alerts.linkedByIncident.categories.automatedInvestigation',
		ThreatFamily: 'alerts.linkedByIncident.categories.threatFamily',
		SameUserCredentials: 'alerts.linkedByIncident.categories.sameUserCredentials',
		SameIpInRecentTime: 'alerts.linkedByIncident.categories.sameIpInRecentTime',
		RelatedAlertTypeAndEntity: 'alerts.linkedByIncident.categories.relatedAlertTypeAndEntity',
		IPSimilarity: 'alerts.linkedByIncident.categories.iPSimilarity',
		DomainSimilarity: 'alerts.linkedByIncident.categories.domainSimilarity',
		EmailSubjectSimilarity: 'alerts.linkedByIncident.categories.emailSubjectSimilarity',
		MessageIdSimilarity: 'alerts.linkedByIncident.categories.messageIdSimilarity',
		ContentSimilarity: 'alerts.linkedByIncident.categories.contentSimilarity',
		CommandLineExactMatch: 'alerts.linkedByIncident.categories.commandLineExactMatch',
		CommandLineSimilarity: 'alerts.linkedByIncident.categories.commandLineSimilarity',
		MessageIdExactMatch: 'alerts_linkedByIncident_categories_MessageIdExactMatch',
		LateralMovement: 'alerts_linkedByIncident_categories_LateralMovement',
		SimilarRegistry: 'alerts_linkedByIncident_categories_SimilarRegistry',
		SameMailbox: 'alerts_linkedByIncident_categories_SameMailbox',
		SimilarSuspiciousActivity: 'alerts_linkedByIncident_categories_SuspiciousActivity',
		SameApplicationId: 'alerts_linkedByIncident_categories_SameApplicationId'
	};

	constructor(
		private readonly i18nService: I18nService,
		private globalEntityTypesService: GlobalEntityTypesService
	) {}

	/**
	 * Get the display text of the category, if one exists.
	 *
	 * @returns The display text of the category, or `null` if not mapped.
	 */
	getCategoryDisplayText(category: IncidentLinkedByCategory): string | null {
		if (category in this.linkedByTranslationKeys) {
			return this.i18nService.get(this.linkedByTranslationKeys[category]);
		}

		return null;
	}

	/**
	 * Gets the link by entity if one exists, according to the available data.
	 */
	getLinkByEntity(incidentLinkedBy: IncidentLinkedBy): IncidentLinkedByEntity | null {
		if (!incidentLinkedBy.targetAlert) {
			return null;
		}

		return incidentLinkedBy.targetAlert.sourceEntity;
	}

	/**
	 * Aggregates `IncidentLinkedBy` items by category+entity.
	 *
	 * Note: Per spec, the linked by reasons should be counted according to the above aggregation, this
	 */
	aggregateUniqueLinkReasonsByCategoryEntity(
		linkedByCategories: ReadonlyArray<IncidentLinkedBy>
	): ReadonlyArray<CategorizedIncidentLinkedByEntity> {
		const linkedByEntities = linkedByCategories.map<CategorizedIncidentLinkedByEntity>(linkedBy => ({
			category: linkedBy.category,
			entity: this.getLinkByEntity(linkedBy),
			targetAlert: linkedBy.targetAlert,
		}));

		// Constructing some unique string so lodash can compare by automatically.
		// This string is not used later.
		const uniqueReasonsByCategory = uniqBy(linkedByEntities, categorizedEntity => {
			if (!categorizedEntity.entity || !categorizedEntity.entity.id) {
				return categorizedEntity.category;
			}

			return `${categorizedEntity.category}--${categorizedEntity.entity.id}`;
		});

		return uniqueReasonsByCategory;
	}

	getAlertMachines(alert: Alert): ReadonlyArray<Machine> {
		if (alert.impactedEntities && alert.impactedEntities.machines) return alert.impactedEntities.machines;
		else if (alert.serviceSource && alert.serviceSource.id === ServiceSourceType.Wdatp && alert.machine)
			return [alert.machine];
		return null;
	}

	getAlertUsers(alert: Alert): ReadonlyArray<AadUser> | ReadonlyArray<AlertUser> {
		if (alert.impactedEntities && alert.impactedEntities.users) return alert.impactedEntities.users;
		else if (alert.serviceSource && alert.serviceSource.id === ServiceSourceType.Wdatp && alert.user)
			return [alert.user];
		return null;
	}

	getAlertMailboxes(alert: Alert): ReadonlyArray<Mailbox> {
		return alert.impactedEntities && alert.impactedEntities.mailboxes;
	}

	getReasonsTextByAlert(alert: Alert, config: TextStyles, reasonsCount?: number): string | Array<string> {
		const reasons = alert.aggregatedIncidentLinkedBy;
		return this.getReasonsText(reasons, config, reasonsCount);
	}

	getReasonsText(
		reasons: ReadonlyArray<AggregatedIncidentLinkedBy>,
		config: TextStyles,
		maxReasonsCount: number,
		maxItemTextLength?: number,
		maxReasonTextLength?: number
	): string | Array<string> {
		if (!reasons) {
			return '';
		}
		const displayedReasonsCount = config.slice ? maxReasonsCount : reasons.length;
		const reasonsTexts = reasons
			.slice(0, displayedReasonsCount)
			.map(
				reason =>
					`${
						config.showLabels
							? (this.getReasonCategory(reason) ||
									this.i18nService.get('alerts.detailsSummary.entities.entity')) + ': '
							: ''
					} ${this.getReasonValueText(
						reason,
						config.truncateTexts,
						reasons.length,
						maxItemTextLength,
						maxReasonTextLength
					)}`
			);

		if (config.asArray) {
			return reasonsTexts;
		} else
			return reasonsTexts
				.join(', ')
				.concat(
					config.slice && displayedReasonsCount < reasons.length
						? this.i18nService.get('alerts.detailsSummary.entities.andMore')
						: ''
				);
	}

	getTitle(alert: Alert, config: TextStyles, maxItemTextLength: number): string {
		const detailsText = this.getMetaDetailsText(alert, config, maxItemTextLength);
		return detailsText ? `${alert.name} ${detailsText}` : alert.name;
	}

	getReasonValueText(
		reason: CategorizedIncidentLinkedByEntity,
		truncateTexts?: boolean,
		reasonsCount?: number,
		maxItemTextLength?: number,
		maxReasonTextLength?: number
	): string {
		if (!reason || !reason.entity) return '';
		const text = reason.entity.name;
		const maxLength = reasonsCount === 1 ? maxItemTextLength : maxReasonTextLength;
		return !truncateTexts || text.length < maxLength ? text : `${text.slice(0, maxLength)}...`;
	}

	getReasonCategory(reason: CategorizedIncidentLinkedByEntity): string {
		return reason ? this.getCategoryDisplayText(reason.category) : '';
	}

	getMetaDetailsText(alert: Alert, config: TextStyles, maxItemTextLength: number): string {
		const product = (alert.serviceSource && alert.serviceSource.id) || ServiceSourceType.Wdatp;
		switch (product) {
			case ServiceSourceType.Wdatp:
			case ServiceSourceType.Mcas:
			case ServiceSourceType.Aatp:
				const machineText = this.getMachinesText(
					this.getAlertMachines(alert),
					config,
					maxItemTextLength
				);
				const userText = this.getUserText(this.getAlertUsers(alert), config, maxItemTextLength);
				return `${machineText || ''} ${userText || ''}`;
			case ServiceSourceType.Oatp:
				return this.getMailboxesText(this.getAlertMailboxes(alert), config, maxItemTextLength);
		}
	}

	getMachinesText(
		machines?: ReadonlyArray<Machine>,
		config?: TextStyles,
		maxItemTextLength?: number
	): string {
		if (isEmpty(machines)) return '';
		const name = this.i18nService.get('alerts.detailsSummary.entities.onMachine', {
			name: this.globalEntityTypesService.getEntityName(Machine, machines[0]),
		});
		return this.truncateAndEllipsis(name, config, maxItemTextLength);
	}

	getUserText(
		users?: ReadonlyArray<AlertUser> | ReadonlyArray<AadUser>,
		config?: TextStyles,
		maxItemTextLength?: number
	): string {
		if (isEmpty(users)) return '';
		const username =
			users[0] instanceof AadUser
				? this.globalEntityTypesService.getEntityName(AadUser, users[0])
				: (<AlertUser>users[0]).name;
		const usernameText = this.i18nService.get('alerts.detailsSummary.entities.byUser', {
			name: username,
		});
		return this.truncateAndEllipsis(usernameText, config, maxItemTextLength);
	}

	getMailboxesText(
		mailboxes?: ReadonlyArray<Mailbox>,
		config?: TextStyles,
		maxItemTextLength?: number
	): string {
		if (isEmpty(mailboxes)) return '';
		const mailboxesText =
			mailboxes && mailboxes.length > 1
				? this.i18nService.get('alerts.detailsSummary.entities.inMailboxes', {
						count: mailboxes.length,
				  })
				: this.i18nService.get('alerts.detailsSummary.entities.inMailbox', {
						name: this.globalEntityTypesService.getEntityName(Mailbox, mailboxes[0]),
				  });

		return this.truncateAndEllipsis(mailboxesText, config, maxItemTextLength);
	}

	private truncateAndEllipsis(text: string, config: TextStyles, maxItemTextLength: number): string {
		return config.truncateTexts && text.length > maxItemTextLength
			? text.slice(0, maxItemTextLength) + '...'
			: text;
	}
}
