<wcd-dl
	*ngIf="profile && rbacGroupNames" class="tvm wcd-scroll-vertical"
	[ngClass]="{ 'wcd-margin-medium-top': mode !== 'EntityPage'}"
	[display]="dlDisplayMode">

	<dl class="span-2">
		<dt>{{ 'common_description' | i18n }}</dt>
		<dd>{{ profile.description || ('tvm_baseline_none' | i18n) }}</dd>
	</dl>

	<dl>
		<dt>{{ 'tvm_baseline_profiles_benchmark_title' | i18n }}</dt>
		<dd>{{ profile.benchmark || ('notAvailable_short' | i18n) }}</dd>
	</dl>

	<dl>
		<dt>{{ 'tvm_baseline_profiles_software_title' | i18n }}</dt>
		<dd>{{ osPlatform }}</dd>
	</dl>

	<dl>
		<dt>{{ 'tvm_baseline_profiles_version_title' | i18n }}</dt>
		<dd>{{ profile.version || ('notAvailable_short' | i18n) }}</dd>
	</dl>

	<dl>
		<dt>{{ 'tvm_baseline_profiles_settings_sidePanel' | i18n }}</dt>
		<dd>{{ profile.settingsNumber || ('notAvailable_short' | i18n) }}</dd>
	</dl>

	<dl>
		<dt>{{ 'tvm_baseline_profiles_compliantDevices_sidePanel' | i18n }}</dt>
		<dd>{{ profile.complianceLevel || ('notAvailable_short' | i18n) }}</dd>
	</dl>

	<dl>
		<dt>{{ 'tvm_baseline_profiles_deviceGroups_sidePanel' | i18n }}</dt>
		<dd>{{ rbacGroupNames || ('notAvailable_short' | i18n) }}</dd>
	</dl>

	<dl>
		<dt>{{ 'tvm_baseline_profiles_createdBy_sidePanel' | i18n }}</dt>
		<dd>{{ profile.createdBy || ('notAvailable_short' | i18n) }}</dd>
	</dl>

	<dl>
		<dt>{{ 'tvm_baseline_profiles_createdOn_sidePanel' | i18n }}</dt>
		<dd>{{ (profile.createdOnTimestampUTC | date:'shortDate') || ('notAvailable_short' | i18n) }}</dd>
	</dl>

	<dl>
		<dt>{{ 'tvm_baseline_profiles_lastUpdatedBy_sidePanel' | i18n }}</dt>
		<dd>{{ profile.lastUpdatedBy || ('tvm_baseline_noUpdates' | i18n) }}</dd>
	</dl>

	<dl>
		<dt>{{ 'tvm_baseline_profiles_lastUpdated_sidePanel' | i18n }}</dt>
		<dd>{{ profile.lastUpdatedBy && profile.lastUpdateTimestampUTC ? (profile.lastUpdateTimestampUTC | date:'shortDate') : ('tvm_baseline_noUpdates' | i18n) }}</dd>
	</dl>
</wcd-dl>
