/* tslint:disable:template-accessibility-alt-text */
import { Component, OnInit } from '@angular/core';
import { AdvancedFeaturesService } from './advanced-features.service';
import { AdvancedFeatureConfig, AdvancedFeatureSection } from './advanced-feature-config.interface';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { tap } from 'rxjs/operators';
import { FeaturesService, FlavorService, Feature } from '@wcd/config';
import { clone, flatten, isEqual, isUndefined, sortBy } from 'lodash-es';
import { BilbaoRegistrationComponent } from './bilbao-registration.component';
import { DimensionsModel } from '../../dialogs/models/dimensions.model';
import { AdvancedFeatures } from '@wcd/domain';
import { MessageBarType } from 'office-ui-fabric-react';
import { AppFlavorConfig } from '@wcd/scc-common';
import { sccHostService } from '@wcd/scc-interface';
import { ConfirmEvent } from '../../dialogs/confirm/confirm.event';
import { AppContextService } from '@wcd/config';

@Component({
	selector: 'integration-settings',
	templateUrl: './integration-settings.component.html',
})
export class IntegrationSettingsComponent implements OnInit {
	advancedFeatureValues: Map<AdvancedFeatureSection, any> = new Map();
	advancedFeatureOriginalValues: Map<AdvancedFeatureSection, any> = new Map();
	disabledSettings: Set<AdvancedFeatureConfig> = new Set();
	warnings: Map<string, string> = new Map();
	badges: Map<string, number> = new Map();
	loadedSections: Set<AdvancedFeatureSection> = new Set();
	sectionLoadErrors: Set<AdvancedFeatureSection> = new Set();
	changedSections = new Set<AdvancedFeatureSection>();
	messageBarType = MessageBarType;
	isSaving: boolean = false;
	sortedSectionSettings$: Subject<Array<SectionWithSettings>> = new Subject<Array<SectionWithSettings>>();
	private _sortedSectionSettings: Array<SectionWithSettings>;
	isScc = sccHostService.isSCC;

	constructor(
		public advancedFeaturesService: AdvancedFeaturesService,
		public i18nService: I18nService,
		private dialogsService: DialogsService,
		public featuresService: FeaturesService,
		private appContextService: AppContextService,
		private flavorService: FlavorService
	) {
		this._sortedSectionSettings = sortBy(
			flatten(
				this.advancedFeaturesService.advancedFeatureSections.map((section: AdvancedFeatureSection) =>
					section.settings
						.filter(
							(setting: AdvancedFeatureConfig) =>
								!setting.features || this.featuresService.isAnyEnabled(setting.features)
						)
						.map((setting: AdvancedFeatureConfig) => {
							return { section: section, settings: setting };
						})
				)
			),
			value => value.settings.priority
		);
	}

	ngOnInit() {
		this.init();
	}

	init() {
		this.changedSections.clear();
		this.advancedFeaturesService.advancedFeatureSections.forEach((section: AdvancedFeatureSection) => {
			section
				.getValues()
				.pipe(
					tap(() => {
						this.loadedSections.add(section);
						this.sortedSectionSettings$.next(
							this._sortedSectionSettings.filter((sectionSettings: SectionWithSettings) =>
								this.loadedSections.has(sectionSettings.section)
							)
						);
					})
				)
				.subscribe(
					featureValues => {
						this.advancedFeatureValues.set(section, Object.assign({}, featureValues));
						this.advancedFeatureOriginalValues.set(section, Object.assign({}, featureValues));

						section.settings.forEach((setting: AdvancedFeatureConfig) => {
							this.setSettingWarning(section, setting);

							if (setting.badge) {
								setting
									.badge(featureValues[setting.id], featureValues)
									.subscribe((featureBadge: number) => {
										featureBadge && this.badges.set(setting.id, featureBadge);
									});
							}

							this.setSettingDisabledState(section, setting);
						});
					},
					error => {
						this.sectionLoadErrors.add(section);
					}
				);
		});
	}

	showBilbaoRegistrationSection(): boolean {
		const sectionValues: any = this.advancedFeatureValues.get(
			this.advancedFeaturesService.advancedFeatureSections.find(
				section => section.name === 'Advanced features settings'
			)
		) as AdvancedFeatures;

		return (
			sectionValues &&
			!sectionValues.managedThreatHuntingApproved &&
			this.flavorService.isEnabled(AppFlavorConfig.threatExpert.mde)
		);
	}

	isSettingVisible(sectionWithSettings: SectionWithSettings): boolean {
		if (!sectionWithSettings.settings.isVisible) return true;

		const sectionValues: any = this.advancedFeatureValues.get(sectionWithSettings.section);
		return sectionWithSettings.settings.isVisible(sectionValues);
	}

	isSettingDisabled(section: AdvancedFeatureSection, setting: AdvancedFeatureConfig): Observable<boolean> {
		const sectionValues: any = this.advancedFeatureValues.get(section);

		if (setting.isDisabled) return setting.isDisabled(sectionValues[setting.id], sectionValues);

		return of(
			(setting.requireLicense && !sectionValues.licenseEnabled) ||
				(this.warnings.has(setting.id) && setting.disableOnWarning === true)
		);
	}

	onChangeSetting(section: AdvancedFeatureSection, setting: AdvancedFeatureConfig) {
		if (!section.reevaluateOnSettingChange) {
			this.setChangedSections(section);
			this.setSettingWarning(section, setting);
			this.setSettingDisabledState(section, setting);
		} else {
			section
				.getValues({
					newValues: this.advancedFeatureValues.get(section),
					originalValues: this.advancedFeatureOriginalValues.get(section),
					settingId: setting.id,
					settingValue: this.advancedFeatureValues.get(section)[setting.id],
				})
				.subscribe(newValues => {
					this.advancedFeatureValues.set(section, Object.assign({}, newValues));
					this.setChangedSections(section);
					section.settings.forEach(_setting => {
						this.setSettingWarning(section, _setting);
						this.setSettingDisabledState(section, _setting);

						if (_setting.badge) {
							_setting
								.badge(newValues[_setting.id], newValues)
								.subscribe((featureBadge: number) => {
									featureBadge && this.badges.set(_setting.id, featureBadge);
								});
						}
					});
				});
		}
	}

	private setChangedSections(section: AdvancedFeatureSection) {
		const requiresUpdate = !isEqual(
			this.advancedFeatureOriginalValues.get(section),
			this.advancedFeatureValues.get(section)
		);
		if (requiresUpdate) {
			this.changedSections.add(section);
		} else {
			this.changedSections.delete(section);
		}
	}

	private setSettingWarning(section: AdvancedFeatureSection, setting: AdvancedFeatureConfig) {
		const featureValues: any = this.advancedFeatureValues.get(section);

		if (setting.warning) {
			setting.warning(featureValues[setting.id], featureValues).subscribe((featureWarning: string) => {
				if (featureWarning) this.warnings.set(setting.id, featureWarning);
				else this.warnings.delete(setting.id);
			});
		}
	}

	private setSettingDisabledState(section: AdvancedFeatureSection, setting: AdvancedFeatureConfig) {
		this.isSettingDisabled(section, setting).subscribe((isDisabled: boolean) => {
			if (isDisabled) this.disabledSettings.add(setting);
			else this.disabledSettings.delete(setting);
		});
	}

	openBilbaoRegistrationModal() {
		this.dialogsService
			.showModal(BilbaoRegistrationComponent, {
				id: 'bilbao-registration',
				title: this.i18nService.strings
					.settings_advancedFeatures_features_managedThreatHuntingRegistration_title,
				dimensions: new DimensionsModel(400, 0),
			})
			.subscribe();
	}

	openConfirmationModal() {
		this.dialogsService
			.confirm({
				title: this.i18nService.strings
					.settings_advancedFeatures_opt_out_magellan_confirmation_modal_title,
				text: this.i18nService.strings
					.settings_advancedFeatures_opt_out_magellan_confirmation_modal_text,
				ariaLabel: this.i18nService.strings
					.settings_advancedFeatures_opt_out_magellan_confirmation_modal_text,
				confirmText: this.appContextService.isSCC
					? this.i18nService.strings
							.settings_advancedFeatures_opt_out_magellan_confirmation_modal_confirm_and_give_feedback
					: this.i18nService.strings
							.settings_advancedFeatures_opt_out_magellan_confirmation_modal_confirm,
				cancelText: this.i18nService.strings
					.settings_advancedFeatures_opt_out_magellan_confirmation_modal_discard,
			})
			.then(({ confirmed }: ConfirmEvent) => {
				if (confirmed) {
					this.saveAdvancedFeatures();
					if (this.appContextService.isSCC) {
						window.SenseiFeedback.ShowUnsolicitedFeedback();
					}
				} else {
					this.init();
				}
			});
	}

	getConfig(advancedFeatures: Map<AdvancedFeatureSection, any>, id: string): boolean {
		return Array.from(advancedFeatures.values())
			.map(section => section[id])
			.find(value => !isUndefined(value));
	}

	submitAdvancedFeatures() {
		if (this.featuresService.isEnabled(Feature.MagellanOptOutConfirmationModal) && this.isDeviceDiscoveryTurnOff()) {
			this.openConfirmationModal();
		} else {
			this.saveAdvancedFeatures();
		}
	}

	private isDeviceDiscoveryTurnOff() {
		const deviceDiscoveryInitialValue = this.getConfig(
			this.advancedFeatureOriginalValues,
			'deviceDiscoveryEnabled'
		);
		const deviceDiscovery = this.getConfig(this.advancedFeatureValues, 'deviceDiscoveryEnabled');
		return !deviceDiscovery && deviceDiscoveryInitialValue;
	}

	saveAdvancedFeatures() {
		const saveObservables: Array<Observable<any>> = [],
			currentValues = clone(this.advancedFeatureValues);

		this.changedSections.forEach((section: AdvancedFeatureSection) => {
			saveObservables.push(
				section.saveValues(
					this.advancedFeatureValues.get(section),
					this.advancedFeatureOriginalValues.get(section)
				)
			);
		});

		this.isSaving = true;

		combineLatest(saveObservables).subscribe(
			() => {
				this.advancedFeatureOriginalValues = currentValues;
				this.isSaving = false;
				this.changedSections.clear();
				this.dialogsService.showSuccessSnackbar({
					text: this.i18nService.strings.settings_advancedFeatures_saveSuccessMessage,
				});
			},
			error => {
				this.isSaving = false;
				this.dialogsService.showError({
					title: this.i18nService.strings.settings_advancedFeatures_error_failedSaveSettings,
					data: error,
				});
			}
		);
	}
}

interface SectionWithSettings {
	section: AdvancedFeatureSection;
	settings: AdvancedFeatureConfig;
}
