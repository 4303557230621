<div class="wcd-full-height large-form">
    <form (submit)="savePolicy()" (keydown.enter)="$event.preventDefault()" class="wcd-full-height wcd-flex-vertical">
        <header class="wcd-flex-none wcd-padding-all">
            <div class="wcd-flex-center-vertical wcd-flex-flex-horizontal">
                <div class="wcd-flex-1">
                    <h3 class="side-panel-title">{{ "webContentFilteringPolicy.dataview.actions.new.title" | i18n}}</h3>
                </div>
            </div>
            <div class="wcd-margin-top">
                <tabs tabsKind="large"
                        [tabsData]="tabs"
                        [currentTab]="currentTab.id"
                        (select)="onTabChanged($event.tab)"></tabs>
            </div>
        </header>
        <div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-all">
            <div [hidden]="currentTab.id !== Tabs.details">
                <section class="wcd-padding-bottom">
                    <h4 class="definition-list-title">
                         {{ "webContentFilteringPolicy.sidePane.sections.details.title" | i18n}}
                    </h4>
                    <div class="dialog-control form-group wcd-flex-horizontal">
                        <p class="dialog-control-description subtle">
                            {{ "webContentFilteringPolicy.sidePane.sections.details.helpKey" | i18n }}
                        </p>
                    </div>
                    <div class="form-group">
                        <fab-text-field
                            [required]="true"
                            [label]="'webContentFilteringPolicy.dataview.entity.names.singularName' | i18n"
                            [(value)]="policy.policyName"
                            [maxLength]="100"
                            [resizable]="false">
                        </fab-text-field>
                    </div>
                </section>
            </div>
            <div [hidden]="currentTab.id !== Tabs.action">
                <section class="wcd-padding-bottom">
                    <div class="dialog-control form-group wcd-flex-horizontal">
                        <p class="dialog-control-description subtle">
                            {{ "webContentFilteringPolicy.sidePane.sections.action.helpKey" | i18n}}
                        </p>
					</div>
					<wcd-filter-values-checklist
						[config]="categoryChecklistConfig"
						[fieldId]="'web-content-filtering-category-checklist'"
						[disableSelectAll]="true"
						(filterValuesChange)="onBlockedCategoriesChange($event)"
						[elementVisible]="currentTab.id == Tabs.action">
					</wcd-filter-values-checklist>
                </section>
            </div>
            <div [hidden]="currentTab.id !== Tabs.groups">
                <section class="form-section">
                    <h4 class="definition-list-title">
                            {{ "webContentFilteringPolicy.sidePane.sections.organizationalscope.machinegroups.title" | i18n}}
                    </h4>
                    <div class="dialog-control form-group wcd-flex-horizontal">
                        <p class="dialog-control-description subtle">
                            {{ "webContentFilteringPolicy.sidePane.sections.organizationalscope.machinegroups.helpKey" | i18n}}
                        </p>
                        </div>
                        <ng-container *ngIf="loadingMachineGroups; else machineGroupsFields">
                            <fab-icon iconName="ProgressRingDots" class="wcd-margin-right"></fab-icon>
                            {{'webContentFilteringPolicy.sidePane.sections.organizationalscope.groups.loading' | i18n}}
                        </ng-container>

                        <ng-template #machineGroupsFields>
                            <ng-container *ngIf="allowAllMachineGroups">
                                <wcd-radiolist [values]="selectableMachineGroupScopes"
                                    name="web-content-filtering-policy-machine-group-options"
                                    data-field-label="Machine groups"
                                    (ngModelChange)="onMachineGroupChange($event)"
                                    [(ngModel)]="selectedMachineGroupScope"
                                    [ariaLabel]="'webContentFilteringPolicy.sidePane.sections.organizationalscope.machinegroups.helpKey' | i18n">
                                </wcd-radiolist>
                            </ng-container>
                            <ng-container *ngIf="allowSpecificMachineGroups && selectedMachineGroupScope.id === ScopeTypesEnum.Specific">
                                <wcd-checklist-dropdown
                                    [id]="machineGroupsFieldId"
                                    [buttonText]="getLabelText(machineGroupsFieldId)"
                                    name="web-content-filtering-policy-machine-groups"
                                    [buttonTooltip]="getLabelText(machineGroupsFieldId)"
                                    [selectById]="false"
                                    [ngModel]="selectedMachineGroups"
                                    [isFullWidth]="true"
                                    (ngModelChange)="onMachineGroupsChange($event)"
                                    [values]="availableMachineGroups">
                                </wcd-checklist-dropdown>
                            </ng-container>
                        </ng-template>
                </section>
            </div>
            <div [hidden]="currentTab.id !== Tabs.review">
                <section class="wcd-padding-bottom" *ngIf="isValid()">
                    <h4 class="definition-list-title">
                        {{ "webContentFilteringPolicy.sidePane.tabs.details.title" | i18n}}
                    </h4>
                    <dl [class.key-values]="true" role="none">
                        <dt>{{ "webContentFilteringPolicy.dataview.entity.fields.policyName" | i18n}}</dt>
                        <dd><span>{{policy.policyName}}</span></dd>
                    </dl>
                </section>
                <section class="wcd-padding-bottom">
                    <h4 class="definition-list-title">
                        {{ "webContentFilteringPolicy.sidePane.tabs.action.title" | i18n}}
                    </h4>
                    <dl [class.key-values]="true" role="none">
                        <dt>{{ "webContentFilteringPolicy.sidePane.sections.action.description.selected" | i18n}}</dt>
                        <dd>
                            <ng-container *ngIf="currentBlockedCategories && currentBlockedCategories.length; else noBlockedCategoies">
                                <div class="wcd-margin-small-bottom" *ngFor="let categoryName of currentBlockedCategories">{{categoryName}}</div>
                            </ng-container>

                            <ng-template #noBlockedCategoies>
                                <span>{{ "webContentFilteringPolicy.sidePane.sections.action.description.noBlockedCategories" | i18n}}</span>
                            </ng-template>
                        </dd>
                    </dl>
                </section>
                <section class="wcd-padding-bottom" *ngIf='isScopeVisible'>
                    <h4 class="wcd-margin-small-bottom">
                        {{ "webContentFilteringPolicy.sidePane.tabs.scope.title" | i18n}}
                    </h4>
                    <dl [class.key-values]="true" role="none">
                        <dt>{{ "webContentFilteringPolicy.sidePane.sections.organizationalscope.description.selected" | i18n}}</dt>
                        <dd>
                            <div *ngIf="selectedMachineGroups && selectedMachineGroups.length; else selectedMachineGroupsReview">
                                <div *ngFor="let group of selectedMachineGroups" class="wcd-margin-small-bottom">{{group.name}}</div>
                            </div>
                            <ng-template #selectedMachineGroupsReview>
                                {{ 'webContentFilteringPolicy.sidePane.sections.organizationalscope.description.organization' | i18n}}
                            </ng-template>
                        </dd>
                    </dl>
                </section>
            </div>
        </div>

        <div class="wcd-flex-none wcd-padding-all wcd-border-top wcd-flex-horizontal">
            <div class="wcd-flex-1 wcd-margin-right">
                <button
                    type="button"
                    class="btn btn-primary"
                    (click)="onNavButtonPressed(false)"
                    [disabled]="!currentTabIndex || isSaving"
                    data-track-type="Button">
                    {{'buttons.previous' | i18n}}
                </button>
                <button
                    type="button"
                    class="btn btn-primary color-box-primary"
                    *ngIf="currentTabIndex !== tabs.length-1"
                    (click)="onNavButtonPressed(true)"
                    [disabled]="currentTabIndex === tabs.length-1 || !isValid() || isSaving"
                    data-track-type="Button">
                    {{'buttons.next' | i18n}}
                </button>
                <button
                    class="btn btn-primary color-box-primary"
                    *ngIf="currentTabIndex === tabs.length-1"
                    data-track-id="CreateWebContentFilteringPolicy"
                    data-track-type="Button"
                    data-track-component="WebContentFilteringPolicyNew"
                    [disabled]="currentTabIndex !== tabs.length-1"
                    type="submit">
                    <fab-spinner *ngIf="isSaving" [size]="SpinnerSize.xSmall" className="wcd-margin-xsmall-horizontal"></fab-spinner>
                    {{isSaving ? '' : 'buttons.save' | i18n}}
                </button>
            </div>
            <div class="wcd-flex-none">
                <button
                    class="btn btn-cancel"
                    (click)="cancel.emit()"
                    data-track-type="Button">
                    {{'buttons.cancel' | i18n}}
                </button>
            </div>
        </div>
    </form>
</div>
