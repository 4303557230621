import {DataQuery, Entity, EntityField, EntityModelBase} from '@microsoft/paris';
import { CertificateInfo } from '../entity/certificate-info.value-object';
import { FileAppType } from './file-app-type.entity';
import { fileAppTypesIndex } from './file-app-type.values';
import { FileId, Md5, Sha1, Sha256 } from './file-id.type';
import { FileDeterminationType } from './file.determination-type.enum';
import { MatchType } from './file.match-type.enum';
import { FileType } from './file.type.enum';
import { DataSensitivity } from './data-sensitivity.value-object';
import { WcdPortalParisConfig } from '../paris-config.interface';

export enum FileSearchCriterion {
	Sha1 = 'Sha1',
	TopFileNames = 'TopFileNames',
	Md5 = 'Md5',
	Sha256 = 'Sha256',
}

export enum CertificateStatus {
	Unknown = 0,
	Valid = 1,
	Expired = 2,
	Revoked = 3
}

// There is a bug in minifying decorators and static properties, moving the function out of the decorator solves this
export const parseFileItemQuery = (itemId, entity, config, params: { [index: string]: any }) => {
	const searchTerm = String(itemId).split(';name=')[0];
	const searchByParam: FileSearchCriterion =
		!params ||
		!params[File.fileSearchByParamName] ||
		!(params[File.fileSearchByParamName] in FileSearchCriterion)
			? FileSearchCriterion.Sha1
			: (params[File.fileSearchByParamName] as FileSearchCriterion);

	return params['newFilesApi'] ? `profiles/files/${searchTerm}` : `files?searchBy=${searchByParam}&searchTerm=${searchTerm}`;
};

@Entity({
	singularName: 'File',
	pluralName: 'Files',
	cache: {
		time: 1000 * 60, // 1 minute
		max: 10,
	},
	readonly: true,
	endpoint: `files`,
	parseItemQuery: parseFileItemQuery,
	baseUrl: (config: WcdPortalParisConfig, query: DataQuery) =>
		query.where && query.where['newFilesApi']
			? config.data.serviceUrls.cyberProfileService
			: config.data.serviceUrls.threatIntel, // remove threatIntel after migration to VNext is completed.
})
export class File extends EntityModelBase<FileId> {
	static fileSearchByParamName = 'searchBy';

	@EntityField({ data: ['Sha1', 'Sha256', 'id'] })
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	readonly id: FileId;

	@EntityField({
		data: ['FileName', 'Name', 'name'],
	})
	fileName?: string;

	@EntityField({ data: 'MatchType' })
	readonly matchType?: MatchType;

	@EntityField({
		data: 'Md5',
		parse: value => value || null,
	})
	readonly md5: Md5 | null;

	@EntityField({ data: 'Sha1' })
	readonly sha1: Sha1;

	@EntityField({
		data: 'Sha256',
		parse: value => value || null,
	})
	readonly sha256: Sha256 | null;

	@EntityField({
		data: ['MaxTimestamp', 'LastObserved', 'LastSeen'],
	})
	readonly lastSeen: Date;

	@EntityField({
		data: ['MinTimestamp', 'FirstObserved'],
	})
	readonly firstSeen: Date;

	/**
	 * The **estimated** prevalence of this file in the world.
	 *
	 * Note that this field is coming from an **estimation**
	 * If you display this data together with an organization-level
	 * prevalence - using `FileStats` - you should use the worldwide prevalence
	 * field from it, and not this one
	 */
	@EntityField({ data: 'TelemetryPrevalence' })
	readonly estimatedWorldwidePrevalence?: number;

	@EntityField({
		data: ['PrevalentThreatName', 'Threat', 'ThreatName'],
		parse: value => value || null,
	})
	readonly prevalentThreatName?: string | null;

	@EntityField({ data: 'Size' })
	readonly size?: number;

	@EntityField({ data: 'FileType' })
	readonly fileType?: FileType;

	@EntityField({ data: 'IsPeFile' })
	readonly isPeFile?: boolean;

	/**
	 * The certificate info of the file, if exists.
	 * If nil, the file's certificate info is **unknown**.
	 */
	@EntityField({ data: 'CertificateInfo' })
	readonly certificateInfo?: CertificateInfo;

	/**
	 * @deprecated Use `certificateInfo` when working with new 19H1 api.
	 */
	@EntityField({ data: 'Signer' })
	readonly signer?: string;

	/**
	 * @deprecated Use `certificateInfo` when working with new 19H1 api.
	 */
	@EntityField({ data: 'Issuer' })
	readonly issuer?: string;

	/**
	 * @deprecated Use `certificateInfo` when working with new 19H1 api.
	 */
	@EntityField({ data: 'SignerHash' })
	readonly signerHash?: string;

	/**
	 * @deprecated Use `certificateInfo` when working with new 19H1 api.
	 */
	@EntityField({ data: 'IsValidCertificate' })
	readonly isValidCertificate?: boolean;

	/**
	 * @deprecated Use `certificateInfo` when working with new 19H1 api.
	 */
	@EntityField({ data: 'IsCertificateDataPopulated' })
	readonly isCertificateDataPopulated?: boolean;

	@EntityField({ data: 'IsMicrosoftRoot' })
	readonly isMicrosoftRoot?: boolean;

	@EntityField({ data: 'DeterminationValue' })
	readonly determinationValue?: string;

	@EntityField({ data: 'DeterminationType' })
	readonly determinationType?: FileDeterminationType;

	@EntityField({ data: 'EntityName' })
	readonly entityName?: 'FileEsEntity';

	@EntityField({ data: 'FileDescription' })
	readonly fileDescription?: string;

	@EntityField({
		data: ['FileName'],
		parse: fileName => {
			if (!fileName) return null;

			const fileExtension = fileName.match(/\.(\w+)$/);
			const appType = fileExtension && fileAppTypesIndex[fileExtension[1].toLowerCase()];
			return appType && appType.id;
		},
	})
	appType: FileAppType;

	@EntityField({ data: 'Sensitivity' })
	readonly sensitivity?: DataSensitivity;

	@EntityField({ data: 'IsWindowsInfoProtectionApplied' })
	readonly isWindowsInfoProtectionApplied?: boolean;

	@EntityField({ data: 'BlacklistStatus' })
	readonly blacklistStatus?: 'Readonly' | 'Unblocked' | 'Blocked' | 'NotAvailable' | 'NonPeFile';

	@EntityField({ data: 'FilePageUrl', required: false })
	readonly externalFilePageUrl?: string;

	@EntityField({ data: 'FileOriginalName', required: false })
	readonly originalName?: string;

	@EntityField({ data: 'FilePublisher', required: false })
	readonly publisher?: string;

	@EntityField({ data: 'CompanyName', required: false })
	readonly companyName?: string;

	@EntityField({ data: 'FileProductName', required: false })
	readonly productName?: string;

	get name(): string {
		return this.fileName;
	}

	set name(name) {
		this.fileName = name;
	}

	@EntityField({ data: 'CertificateStatus'})
	readonly certificateStatus?: CertificateStatus;

	@EntityField({
		data: "__self",
		parse: rawData => {
			return `${rawData.Sha1 || rawData.Sha256 || rawData.id}-${rawData.FileName || rawData.Name || rawData.name}`
		},
	})
	searchUniqueId?: string;

	get fileId() {
		return this.id;
	}
}
