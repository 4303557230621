import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { AirsMailbox } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { ThreatsDisplayNameService } from '../../../mtp_investigations/services/threats-display-name-field.service';
import { sccHostService, SccRoles } from '@wcd/scc-interface';

@Component({
	selector: 'airs-mailbox-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<dl class="key-values clearfix" role="none">
			<ng-container *ngIf="entity.displayName">
				<dt role="none">{{ i18nService.strings.airsEntities_mailbox_fields_displayName }}</dt>
				<dd role="none">{{ entity.displayName }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.primaryMailAddress">
				<dt role="none">
					{{ i18nService.strings.airsEntities_mailbox_fields_primaryEmailAddress }}
				</dt>
				<dd role="none">{{ entity.primaryMailAddress }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.upn">
				<dt role="none">{{ i18nService.strings.airsEntities_mailbox_fields_upn }}</dt>
				<dd role="none">{{ entity.upn }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.aadId">
				<dt role="none">{{ i18nService.strings.airsEntities_mailbox_fields_objectId }}</dt>
				<dd role="none">{{ entity.aadId }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.fwRuleName">
				<dt role="none">
					{{ i18nService.strings.airsEntities_mailbox_fields_forwardingRuleName }}
				</dt>
				<dd role="none">{{ entity.fwRuleName }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.fwRuleCreationTime">
				<dt role="none">
					{{ i18nService.strings.airsEntities_mailbox_fields_forwardingRuleCreationTime }}
				</dt>
				<dd role="none">{{ entity.fwRuleCreationTime | date: 'short' }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.fwRuleCreatorAlias">
				<dt role="none">
					{{ i18nService.strings.airsEntities_mailbox_fields_forwardingRuleCreatorAlias }}
				</dt>
				<dd role="none">{{ entity.fwRuleCreatorAlias }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.fwRuleForwardingSMTPAddress">
				<dt role="none">
					{{ i18nService.strings.airsEntities_mailbox_fields_forwardingRuleSmtpAddress }}
				</dt>
				<dd role="none">{{ entity.fwRuleForwardingSMTPAddress }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.riskLevel">
				<dt role="none">
					{{ i18nService.strings.airsEntities_mailbox_fields_riskLevel }}
				</dt>
				<dd role="none">{{ entity.riskLevel }}</dd>
			</ng-container>
			<dt role="none">
				{{ i18nService.strings.airsEntities_mailbox_fields_risk }}
			</dt>
			<dd role="none">
				{{
					threatsDisplayNameService.getThreatsDisplayName(entity.risk) ||
						i18nService.strings.evidence_threats_field_displayName_noThreatsFound
				}}
			</dd>
			<dt role="none">
				{{ i18nService.strings.airsEntities_mailbox_fields_riskyActivities }}
			</dt>
			<dd role="none">{{ entity.riskyActivities || 0 }}</dd>
		</dl>
		<div *ngIf="entity.aadId && (showUserLinkDetails$ | async)" class="wcd-padding-vertical">
			<a
				[href]="userDetailsLink"
				target="_blank"
				[wcdTooltip]="i18nService.strings.airsEntities_mailbox_fields_userDetailsLink"
			>
				{{ i18nService.strings.airsEntities_mailbox_fields_userDetailsLink }}
				<fab-icon iconName="OpenInNewWindow" className="small-icon"></fab-icon>
			</a>
		</div>
	`,
})
export class AirsMailboxDetailsComponent implements OnChanges {
	@Input() entity: AirsMailbox;

	showUserLinkDetails$: Promise<boolean>;
	userDetailsLink: string;

	constructor(
		public i18nService: I18nService,
		public threatsDisplayNameService: ThreatsDisplayNameService
	) {
		this.showUserLinkDetails$ = sccHostService.inSccRoleAsync(SccRoles.hasUnifiedUserPage);
	}

	ngOnChanges() {
		this.userDetailsLink = this.entity.aadId
			? sccHostService.state.href('user', { aad: this.entity.aadId, path: '' })
			: '';
	}
}
