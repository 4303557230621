import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { SccAppPaths, SccAppRoutingModule } from './app-routing.scc.module';
import { UrlHandlingStrategy } from '@angular/router';
import { APC_HEADER_KEY, AuthService, tenantContextCache } from '@wcd/auth';
import { registerLocales } from './app.locales';
import { AppSccComponent } from './app.scc.component';
import { UrlHandlerScc } from './url-handler.scc';
import { AppModuleCommons, imports, providers } from './app.module.commons';
import { getPortalLanguage, I18nService } from '@wcd/i18n';
import { CodeEditorSettings, EDITOR_SETTINGS } from '@wcd/code-editor';
import { SccRoutesService, WcdSharedModule } from '@wcd/shared';
import { AppInsightsService } from './insights/services/app-insights.service';
import { PreferencesSCCService, PreferencesService, TvmLicensesAngularService } from '@wcd/config';
import { Paris } from '@microsoft/paris';
import { ajax, AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import { defer, from, of } from 'rxjs';
import { FabProgressIndicatorModule } from '@angular-react/fabric';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { Http429Interceptor } from './shared/interceptors/http429.interceptor';
import { sccHostService } from '@wcd/scc-interface';
import { createTheme, IRawStyle } from '@uifabric/styling';
import { loadTheme } from 'office-ui-fabric-react';
import { OutbreakService } from './dashboards/threat-analytics3/services/outbreak.service';
import { NotificationsService } from './notifications/services/notifications.service';
import { AxiosRequestConfig } from 'axios-types';
import { IncidentEmailNotificationEntityTypeModule } from './scc-settings/incident-email-notification/incident-email-notification-entity-type.module';
import { ReactComponentsModule } from './react-componets-module/react-components';
import { HttpClientProxy, LegacyHttpClient, SccProxyUtils } from '@wcd/app-config';
import { preFetch } from './app.scc.prefetch.svc';
import { finalize } from 'rxjs/operators';

const xLargePlusFont: IRawStyle = {
	fontFamily:
		'\'Segoe UI\', \'Segoe UI Web (West European)\', \'Segoe UI\', -apple-system, BlinkMacSystemFont, \'Roboto\', \'Helvetica Neue\', sans-serif',
	MozOsxFontSmoothing: 'grayscale',
	WebkitFontSmoothing: 'antialiased',
	fontSize: '24px',
	fontWeight: 100,
};


registerLocales();

const pari = new Paris({
	apiRoot: '/api',
	intercept: (req: AjaxRequest) => {
		if (!sccHostService.isSCC) {
			return of(req);
		}

		const overrideHeaders = {
			headers: {
				...req.headers,
				'Tenant-Id': sccHostService.loginUser.tenantId,
				[APC_HEADER_KEY]: tenantContextCache.apcToken,
				'Accept-Language': getPortalLanguage(),
			},
		};

		if (SccProxyUtils.urlMatchSccProxyPattern(req.url)) {
			return of(Object.assign(req, overrideHeaders));
		}

		return from(
			sccHostService.auth.getToken().then(token => {
				(<any>overrideHeaders.headers).authorization = `Bearer ${token}`;

				return Object.assign(req, overrideHeaders);
			}),
		);
	},
	http: {
		withCredentials: true,
	},
	//@ts-ignore
	ajaxService: (config: string | AjaxRequest) => {
		const url = typeof config === 'string' ? config : config.url;
		// only urls matching <whatever> will go to SCC's ajax service
		if (SccProxyUtils.urlMatchSccProxyPattern(url)) {
			//support comparability with axios
			//@ts-ignore
			const source = window.axios.CancelToken.source();
			//@ts-ignore
			config = Object.assign({}, config, {data: config.body, cancelToken: source.token});
			return defer(
				() => sccHostService.ajax.request(config as AxiosRequestConfig).then(res => {
					// We convert AxiosResponse to AjaxReponse as best we can which is what paris expects
					return ({ response: res.data, ...res } as unknown) as AjaxResponse;
				}).catch(err => {
					// Convert the AxiosError to a subtype that looks like AjaxError for Paris consumers
					// https://github.com/ReactiveX/rxjs/blob/6.4.0/src/internal/observable/dom/AjaxObservable.ts#L468-L483
					err.xhr = err.response;
					err.status = err.request.status;
					err.responseType = err.request.responseType
					err.response = Object.assign({}, err.response, err.request.response);
					err.request.url = typeof config === 'string' ? config : config.url;
					err.request.async = true;
					err.request.body = typeof config === 'string' ? null : config.body;
					err.request.timeout = typeof config === 'string' ? 0 : config.timeout;
					err.request.headers = typeof config === 'string' ? {} : config.headers;
					throw err;
				})).pipe(finalize(() => source.cancel('axios cancel request'))
			);
		}
		return ajax(config);
	},
});

const parisInit = () => {
	const features = tenantContextCache.appConfig.Features || {};
	const serviceUrls = SccProxyUtils.getServiceUrls(tenantContextCache.appConfig.ServiceUrls, features);
	pari.setConfig(
		Object.assign({}, pari.config, {
			data: {
				serviceUrls,
			},
		}),
	);
	preFetch(pari, tenantContextCache.appConfig);
	return pari;
};

@NgModule({
	imports: [
		...imports,
		AppModuleCommons,
		SccAppRoutingModule,
		WcdSharedModule.forRoot(SccAppPaths),
		FabProgressIndicatorModule,
		IncidentEmailNotificationEntityTypeModule,
		ReactComponentsModule.forRoot(),
	],
	providers: [
		// Next 2 lines are needed in order to override Angular's HttpClient with our own HttpClientProxy so
		// all calls in SCC will go thru SCC proxy (while HttpClientProxy still uses Angular's HttpClient)
		{ provide: LegacyHttpClient, useClass: HttpClient },
		{ provide: HttpClient, useExisting: HttpClientProxy },
		...providers,
		OutbreakService,
		{ provide: UrlHandlingStrategy, useClass: UrlHandlerScc },

		{
			provide: APP_INITIALIZER,
			useFactory: (injector: Injector, i18n: I18nService, auth: AuthService, preferences: PreferencesService) => async () => {

				await Promise.all([auth.initSCC(), i18n.init().toPromise(), preferences.init()]);
				// injected here in order to prevent circular dependency
				const appInsights: AppInsightsService = injector.get(AppInsightsService);
				const notifications: NotificationsService = injector.get(NotificationsService);
				const tvmLicenses: TvmLicensesAngularService = injector.get(TvmLicensesAngularService);
				return Promise.all([
					appInsights.init().toPromise(),
					notifications.init().toPromise(),
					tvmLicenses.init(),
				]).then(res => {
					!sccHostService.appLoadedOnce && sccHostService.log.trackSccScriptLoadDurationsByPackage('wdatp');
					sccHostService.appLoadedOnce = true;
					return res;
				});
			},
			deps: [Injector, I18nService, AuthService, PreferencesService],
			multi: true,
		},
		{
			provide: EDITOR_SETTINGS,
			deps: [],
			useFactory: () => {
				return {
					baseUrl: sccHostService.resource.getUrl('vs').replace('/vs', ''),
					preload: true,
				} as CodeEditorSettings;
			},
		},
		{
			provide: Paris,
			useFactory: parisInit,
			deps: [],
		},
		{ provide: SccRoutesService, useValue: SccAppPaths },
		{ provide: HTTP_INTERCEPTORS, useClass: Http429Interceptor, multi: true },
		{ provide: PreferencesService, useClass: PreferencesSCCService },
	],
	declarations: [AppSccComponent],
	bootstrap: [AppSccComponent],
})
export class AppSCCModule {
	constructor() {
		const theme = createTheme({
			fonts: {
				xLargePlus: xLargePlusFont,
			},
		});
		//@ts-ignore
		loadTheme(theme);

		fixAngularBreakingPromiseErrors();

		function fixAngularBreakingPromiseErrors() {
			setTimeout(() => { // defer a microtask to run after the bootstrap is done
				//@ts-ignore
				const oldHandler = Zone.__zone_symbol__unhandledPromiseRejectionHandler;
				//@ts-ignore
				if (Zone.__wcdAppFixedUnhandledRejection) {
					return; // the app was bootstrapped again but with the same Zone.
				}
				//@ts-ignore
				Zone.__wcdAppFixedUnhandledRejection = true;
				//@ts-ignore
				Zone.__zone_symbol__unhandledPromiseRejectionHandler = function(e) {
				  const ev = new PromiseRejectionEvent('unhandledrejection', e);
				  // see if it already fired
				  let fired = false;
				  const listener = (e) => {
					fired = true;
				  };
				  window.addEventListener('unhandledrejection', listener, { once: true });
				  // HostPromiseRejectionTracker heuristic - emulate chromium and
				  // wait a microtask
				  setTimeout(() => {
					if (fired) {
						return;
					}
					window.removeEventListener('unhandledrejection', listener);
					window.dispatchEvent(ev);
				  });
				  oldHandler.call(this, e);
				};
			})
		}
	}
}
