import { DataEntityType, EntityField, EntityModelBase, ModelBase, ValueObject } from '@microsoft/paris';
import { GenericField } from '../generic-field/generic-field.value-object';
import { CyberEventEntityRelationTypeEnum } from './cyber-event-entity-relation-type-enum';

export const parseEntityData = (entity, rawData) => {
	entity && (entity["deviceName"] = rawData.deviceName ||  (rawData.Machine && rawData.Machine.Name));
	entity && (entity["deviceId"] = rawData.deviceId || (rawData.Machine && rawData.Machine.MachineId));
	entity && (entity["relatedAlertIds"] = rawData.alertIds || rawData.relatedAlertIds);
	return entity;
}

@ValueObject({
	singularName: 'Cyber event entity',
	pluralName: 'Cyber event entities',
	readonly: true,
})
export class CyberEventEntity extends EntityModelBase<number | string> {
	@EntityField({defaultValue: ''})
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number | string;

	/**
	 * Actual item entity
	 */
	@EntityField({data: 'Item', parse: parseEntityData})
	item: EntityModelBase<string | number>;

	/**
	 * Item's entity type (for example, "File" or "User")
	 */
	@EntityField({data: 'EntityType' })
	entityType?: DataEntityType<EntityModelBase<string | number>>;

	/**
	 * Relation between the item and its preceding entity (for example, "InitiatingProcess" is a "Child" of "InitiatingProcessParent")
	 */
	@EntityField({data: 'RelationToPrecedingEntity'})
	relationToPrecedingEntity?: CyberEventEntityRelationTypeEnum;

	/**
	 * Description of the relation to preceding element
	 */
	@EntityField({data: 'RelationDescription'})
	relationDescription?: string

	/**
	 * Depth level of item in the event's entities chain (for example, if "InitiatingProcessParentParent" exists, its depth is level 0, and if not, "InitiatingProcessParent" is level 0)
	 */
	@EntityField({data: 'Depth'})
	depth?: number;

	/**
	 * Determines if the entity should be collapsed or show additional details as part of the graph presented in the side panel
	 */
	@EntityField({data: 'IsCollapsed'})
	isCollapsed?: boolean;

	@EntityField({ data: 'TypedDetails', parse: (genericFields: Array<GenericField>) => genericFields ? genericFields.filter((d) => !d.isHidden) : null })
	genericFields?: Array<GenericField>;
}
