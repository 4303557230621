import { Entity, EntityField, EntityModelBase, ParisConfig } from '@microsoft/paris';
import { Machine } from '../../machine/machine.entity';
import { InvestigationStatus } from '../../investigation/investigation-status.entity';

@Entity({
	singularName: 'Session',
	pluralName: 'Sessions',
	baseUrl: (config: ParisConfig) => config.data.serviceUrls.automatedIr,
	endpoint: 'NOOP',
	parseItemQuery: itemId =>
		(String(itemId).startsWith('LR') ? 'v2/live_response/sessions' : 'live_response/sessions') +
		`/${itemId}`,
	parseSaveQuery: (session: LiveResponseSession) =>
		session.useV2Api ? 'v2/live_response/create_session' : 'live_response/create_session',
	serializeItem: (session: LiveResponseSession) => ({
		end_date: session.endDate || null,
		machine_last_seen: session.machine.lastSeen || null,
		machine_id: session.machine.id,
		session_status: session.status || null,
		start_date: session.startDate || null,
	}),
})
export class LiveResponseSession extends EntityModelBase<number | string> {
	@EntityField({ data: 'session_id' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number | string;

	@EntityField({
		data: 'machine_id',
		parse: (machineId, rawData) => {
			const machineInfo = rawData['machine_info'];
			if (machineInfo) {
				return machineInfo.machine_id;
			}
			return machineId;
		},
	})
	machine: Machine;

	@EntityField({ data: 'session_status' })
	status: InvestigationStatus;

	@EntityField({ data: 'user' })
	initiatingUser: string;

	@EntityField({ data: 'start_date' })
	startDate: Date;

	@EntityField({ data: 'end_date' })
	endDate: Date;

	@EntityField({ data: 'session_id', parse: fieldData => String(fieldData).startsWith('LR') })
	useV2Api: boolean;
}
